// This is called when the admin user is creating the volunteer schedule and the user
// is adding a shift to the schedule to be saved to the database.
// Parameters:
// shift :the index of shiftsAvailable that the user has selected to create.
import firebase from "../../../../components/Firebase.js";

export default async function AddVolunteerShiftToDatabase(
  startDate,
  endDate,
  shift,
  positionSelected,
  volunteerSchedule,
  setVolunteerSchedule,
) {
  // The starting month when the shift will start
  const startingMonth = startDate.getMonth();
  // The dictionary of the the shift with all its information.
  const shiftDict = positionSelected.shiftsAvailable[shift];
  // If we are creating a new volunteerSchedule document that doesn't exist yet.
  let newDocument = true;

  const database = firebase.firestore();
  const batch = database.batch();

  // Set the default quarter to Oct-Dec
  let quarter = "Q4";
  // Jan-Mar 0-2
  if (startingMonth >= 0 && startingMonth < 3) {
    quarter = "Q1";
  }
  // Apr-Jun 3-5
  else if (startingMonth > 2 && startingMonth < 6) {
    quarter = "Q2";
  }
  // Jul-Sept 6-8
  else if (startingMonth > 5 && startingMonth < 9) {
    quarter = "Q3";
  }

  // A list of all the dates that this shift will occur on over the specified date range.
  const datesToAdd = [];
  // The date to add to the list above.  We will start with the starting date
  // and then increment based off the frequency of the shift.
  let dateToAdd = startDate;
  // If the shift occurs weekly or biweekly we will add all the dates otherwise
  // we will just have to let it be a single date and if it's monthly then the user
  // will have to add it one by one.
  if (shiftDict.Frequency === "Weekly" || shiftDict.Frequency === "Biweekly") {
    // If the frequency is weekly then we have to increment the days by 7.
    let numberOfDaysToAdd = 7;
    // If the frequency is biweekly then we have to increment the days by 7.
    if (shiftDict.Frequency === "Biweekly") {
      numberOfDaysToAdd = 14;
    }
    // while the date we are adding is less than the end date then we will keep going.
    while (dateToAdd <= endDate) {
      // Add the date in the format MMYY.
      datesToAdd.push(
        [
          ("0" + dateToAdd.getMonth()).slice(-2),
          ("0" + dateToAdd.getDate()).slice(-2),
        ].join(""),
      );
      // get the next date to add.
      dateToAdd = new Date(
        dateToAdd.setDate(dateToAdd.getDate() + numberOfDaysToAdd),
      );
    }
  } else {
    // If the shift isn't reoccurring weekly or biweekly then just add the start date.
    datesToAdd.push(
      [
        ("0" + dateToAdd.getMonth()).slice(-2),
        ("0" + dateToAdd.getDate()).slice(-2),
      ].join(""),
    );
  }
  // Get the year so that we can use it to find the document to add the schedule to.
  const year = startDate.getFullYear();

  // The document name that we are saving in the database to.
  const documentName = [year, quarter].join("-");
  // The temp volunteer schedule that we will update with the new shift.
  const volunteerScheduleTemp = [...volunteerSchedule];

  // If the time period that this shift is occuring in doesn't match the volunteer
  // schedules time period then we conside it a new document vs. updating an existing one.
  // We need to find if there is an exisiting time period already.
  const indexOfQuarter = volunteerSchedule.findIndex(
    (quarterExisting) =>
      quarterExisting.timePeriod === [year, quarter].join("-"),
  );

  // Volunteer schedule is going in a new document and so we will create a new
  // volunteer schedule.
  let volunteerScheduleQuarter = {};
  // Set the time period this schedule is good for.
  volunteerScheduleQuarter.timePeriod = [year, quarter].join("-");

  // If the quarter exists then it will have an index and we can set the quarter
  // to the existing one and set new document to false.
  if (indexOfQuarter !== -1) {
    volunteerScheduleQuarter = { ...volunteerScheduleTemp[indexOfQuarter] };
    newDocument = false;
  }

  // Cycle through the dates to add to this schedule.
  datesToAdd.forEach((date) => {
    // The month of this date MM.
    const month = date.slice(0, 1);
    // The day of this date DD.
    const day = date.slice(2, 3);
    // If the volunteer schedule date hasn't been created yet then initialize it
    // otherwise we are adding to this existing date.
    if (volunteerScheduleQuarter[date] === undefined) {
      volunteerScheduleQuarter[date] = {};
    }
    // If this position doesn't exist yet on this date then initialize it
    // otherwise we are adding to this existing date and position.
    if (volunteerScheduleQuarter[date][positionSelected.title] === undefined) {
      volunteerScheduleQuarter[date][positionSelected.title] = {};
    }
    // Add the location that this shift is occuring at.
    volunteerScheduleQuarter[date][positionSelected.title].location =
      positionSelected.location;
    // Add the credit value of this shift.
    volunteerScheduleQuarter[date][positionSelected.title].creditValue =
      positionSelected.creditValue;
    // The key for the shift in the format of weekday start time and end time WeekDayHHMMHHMM
    const shiftKey = [
      shiftDict.DayOfTheWeek,
      [shiftDict.StartTime.slice(0, 2), shiftDict.StartTime.slice(3)].join(""),
      [shiftDict.EndTime.slice(0, 2), shiftDict.EndTime.slice(3)].join(""),
    ].join("");
    // The code for the credits.
    let code = new Date();
    // The code is the year, month, day and the milliseconds this is created at so
    // it is unique.
    code = [year, month, day, code.getMilliseconds()].join("");
    // initialize the shift value
    volunteerScheduleQuarter[date][positionSelected.title][shiftKey] = {};
    // Set the code of this shift.
    volunteerScheduleQuarter[date][positionSelected.title][shiftKey].code =
      code;
    // Add an empty array that will contain the volunteers.
    volunteerScheduleQuarter[date][positionSelected.title][
      shiftKey
    ].volunteers = [];
  });

  // Save this volunteer schedule to the document in the database.
  const docRef = database.collection("VolunteerSchedule").doc(documentName);

  // If it's a new document then we have to create it.
  if (newDocument) {
    // Load the document with the quarter in the time period.  If there
    // is no document then proceed with creating it otherwise notify the user
    // that this volunteer schedule already exists and they need to load it.
    try {
      const docSnapshot = await docRef.get();
      if (docSnapshot.exists) {
        alert(
          "A volunteer schedule for the period you are updating exists, but has not been loaded.  Please load this schedule first before updating.",
        );
      } else {
        try {
          await docRef.set(volunteerScheduleQuarter);
          console.log("Document successfully written!");
          volunteerScheduleTemp.push({ ...volunteerScheduleQuarter });
          setVolunteerSchedule([...volunteerScheduleTemp]);
        } catch (error) {
          console.error("Error writing document: ", error);
        }
      }
    } catch (error) {
      console.error("Error getting document: ", error);
    }
  }
  // Otherwise we are just updating it.
  else {
    try {
      batch.update(docRef, volunteerScheduleQuarter);
      await batch.commit();
      volunteerScheduleTemp[indexOfQuarter] = { ...volunteerScheduleQuarter };
      setVolunteerSchedule([...volunteerScheduleTemp]);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }
}
