import React from "react";

// The column formatting for the farm's contact info to be displayed
export default function ContactInfo(props) {
  let contactInfoCSS;
  if (props.userType === "individual") {
    contactInfoCSS = "Farm-Contact-Info";
  } else if (props.userType === "restaurant") {
    contactInfoCSS = "Restaurant-Farm-Contact-Info";
  } else {
    contactInfoCSS = "Distribution-Farm-Contact-Info";
  }
  return (
    <div className={contactInfoCSS}>
      <div>Email: {props.email}</div>
      <div>Phone: {props.number}</div>
    </div>
  );
}
