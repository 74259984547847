// This is to Remove a food Item from a distribution Location's order
import UpdatePriceAndCommunity from "../../pages/MyAccountPage/Functions/SyncFoodDistributionCommunityOrders/UpdatePriceAndCommunity.js";
import firebase from "../../components/Firebase.js";

// props comtains:
// userInfo : The distribution location's userInfo
// order : communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
// foodItemUpdate : foodItem to update quantity, price, distributionQuantity, etc.
// orderLog contains: [array with each orderLoaded for the user]
// updateOrderLog contains: a function to update OrderLog
// orderIndex contains: the index of the current order in the orderLog array
// ordersToChange : Array with firstName-lastName-userId-orderDate of all communityOrders to be changed
// importedOrdersToChange : Array with firstName-lastName-userId-orderDate of all importedOrder to be changed
// updateOrders : Flag that determines whether individual orders need to be updated
export default async function RemoveFoodItem(
  userInfo,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  indexOfItem,
  foodItemUpdate,
  ordersToChange,
  importedOrdersToChange,
  updateOrders,
  updatePaymentHistory,
  pantry,
  fullRemovalFlag,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  // This is to be updated so that the array that holds all the orders from myAccount
  // is correct with the changes.
  const orderLogTemp = [...orderLog];

  // This is used to store the food items
  let foodListTemp = [...order.foodList];
  // This is saying that the foodItem that is being removed is from the foodList
  let fieldToUpdate = "foodList";

  // When an item is removed from a distribution location's order it will cycle through
  // all the items being removed including both the foodList and the pantryList.  If pantry
  // is 1 then that means it's now going through the pantryFoodItems and seeing if anything from
  // the pantry is being removed.
  if (pantry === 1) {
    foodListTemp = [...order.pantryFoodItems];
    fieldToUpdate = "pantryFoodItems";
  }

  const contactMethodsTemp = { ...order.contactMethods };

  // This contains dictionaries of the farms in an array.
  const farmerListTemp = [...order.farmerList];

  // If the item is to be removed from the distribution's location food list.
  if (fullRemovalFlag) {
    // Remove the foodItem from the distribution location's order
    foodListTemp.splice(indexOfItem, 1);
    // If we're removing from the foodList we need to check that the farmer of the item
    // being removed is still delivering another item or if that farm needs to be removed.
    if (pantry !== 1) {
      // Check to make sure that the farm still exists after the food Item was removed
      // if it doesn't then remove it as well and remove it from the contactMethods
      // dictionary
      farmerListTemp.forEach((farm, indexOfFarm) => {
        const indexExists = foodListTemp.findIndex((foodItem) => {
          return foodItem.farmName === farm.farmName;
        });
        if (indexExists === -1) {
          farmerListTemp.splice(indexOfFarm, 1);
          delete contactMethodsTemp[farm.farmName];
        }
      });
    }
  }

  // Check and remove the item from the distributedFoodItems
  const distributedFoodItemsTemp = { ...order.distributedFoodItems };

  // Cycle through the distribution locations in the distributed food items dictionary.
  Object.keys(distributedFoodItemsTemp).forEach((distributionLocation) => {
    const index = distributedFoodItemsTemp[distributionLocation].findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName &&
        !!element.limit === !!foodItemUpdate.limit,
    );

    // If the item doesn't exist then we don't want to splice anything out.
    if (index !== -1) {
      // Remove the item.
      distributedFoodItemsTemp[distributionLocation].splice(index, 1);
    }
  });

  // If the distribution location has selected to have community orders updated as well.
  if (updateOrders) {
    await UpdatePriceAndCommunity(
      userInfo,
      order,
      ordersToChange,
      importedOrdersToChange,
      updateOrderLog,
      updatePaymentHistory,
      orderIndex,
      foodListTemp,
      fieldToUpdate,
      foodItemUpdate,
      orderLogTemp,
      true,
      "removeItem",
      null,
      true,
      null,
      null,
      contactMethodsTemp,
      farmerListTemp,
      distributedFoodItemsTemp,
      communityOrderChunks,
      setCommunityOrderChunks,
    );
  }
  // We just need to update the distribution location's order.
  else {
    // Set up the database
    const database = firebase.firestore();
    const batch = database.batch();
    // Finds when then user ordered to help find the distribution location's order in the database
    const orderDate = order.orderDate;

    // References the document in the database that saves the distribution location's order history
    const docRef = database
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Orders")
      .doc(orderDate);

    // Users -> distributionLocationUserId -> Orders -> date
    batch.update(docRef, {
      [fieldToUpdate]: [...foodListTemp],
      contactMethods: { ...contactMethodsTemp },
      farmerList: [...farmerListTemp],
      distributedFoodItems: { ...distributedFoodItemsTemp },
    });

    orderLogTemp[orderIndex].contactMethods = { ...contactMethodsTemp };
    orderLogTemp[orderIndex].farmerList = [...farmerListTemp];
    orderLogTemp[orderIndex].distributedFoodItems = {
      ...distributedFoodItemsTemp,
    };

    // This is to make sure the page rerenders these items so updates either the foodList
    // or the pantryFoodItems.
    orderLogTemp[orderIndex][fieldToUpdate] = [...foodListTemp];

    // As there may be multiple items being deleted at once we had to update the order
    // variable in order to have an accurate value as the state couldn't update fast enough.
    // This way we have the correct values for the order to be updated further.
    order[fieldToUpdate] = [...foodListTemp];
    order.contactMethods = { ...contactMethodsTemp };
    order.farmerList = [...farmerListTemp];
    order.distributedFoodItems = { ...distributedFoodItemsTemp };

    batch.commit();
    // Updates the orderLog to make sure the screen shows the correct information
    updateOrderLog([...orderLogTemp]);
  }
}
