// The homepage header that has a link to the marketplace and the sign up page if
// the user isn't signed in yet.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function HomePageHeader({ currentUser }) {
  return (
    <header
      className="w3-display-container w3-content w3-wide"
      style={{ maxWidth: "100vw", minWidth: "500px" }}
      id="home"
    >
      <img
        className="w3-image"
        referrerPolicy="no-referrer"
        style={{
          objectFit: "cover",
          width: "100%",
          maxHeight: "calc(100vh - 50px)",
        }}
        alt="Vegetable Board"
        src="https://lh3.googleusercontent.com/pw/AM-JKLX9R3PirLtTHwOF3XeC06VSb-5mrTxYgcNfh_jQZ8qlXK8AJ1Pnqa8uVDQnOEpP7r0eYFK8qhTOZIrPd3QVDglHsa9CnnHZn99KoPQJIpREEoSuNtxseEaJb76wVKVssqtc3wfrq7PqiIlium2o_suH=w1186-h789-no?authuser=1"
      />
      <div className="w3-display-right w3-padding-large Title-Page-Text">
        <h1 className="Title-Dom-Text">Farm-to-Plate Marketplace</h1>
        <h5 className="Title-Sub-Text">
          A Community Run Virtual Pay-What-You-Can Farmers Market
        </h5>
        <div className="Title-Buttons">
          <Link style={{ textDecoration: "none" }} to="/Marketplace">
            <Button
              style={{ background: "#45AA45", color: "white" }}
              size="large"
              variant="contained"
            >
              VISIT MARKETPLACE
            </Button>
          </Link>
          {!currentUser && (
            <Link style={{ textDecoration: "none" }} to="/SignUp">
              <Button
                style={{ background: "white", color: "#45AA45" }}
                size="large"
                variant="outlined"
              >
                JOIN NOW
              </Button>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
