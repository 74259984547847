// This is used to ask the user how much of the food item was distributed to which
// locations.
import React, { useState, useEffect, useContext } from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SaveDistributedFoodItems from "./../Functions/SaveDistributedFoodItems.js";
import UpdatePantry from "./../Functions/UpdatePantry.js";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import SyncDisabledRoundedIcon from "@material-ui/icons/SyncDisabledRounded";
import { ChangeOrderLogContext } from "../../../pages/MyAccount.js";
import CreateLocationAmounts from "./../Functions/CreateLocationAmounts.js";

export default function SetDistributedFood({
  distributedAmount,
  foodItem,
  userInfo,
  orderLog,
  orderIndex,
  updateOrderLog,
  distributionAmountsChange,
}) {
  const [open, setOpen] = useState(false);

  // location's Participating in this order.
  const [locationsParticipating, setLocationsParticipating] = useState([]);

  // Location amounts distributed at each location. {location : XX}
  const [locationAmounts, setLocationAmounts] = useState({});

  // a checkbox to say whether or not to update the pantry and remove or add
  // the changes of the distributed amounts.
  const [updatePantry, setUpdatePantry] = useState(false);

  // These are the leftovers in the distributionLocation's pantry
  // eslint-disable-next-line no-unused-vars
  const { blank1, blank2, blank3, leftovers, setLeftovers } = useContext(
    ChangeOrderLogContext,
  );

  useEffect(() => {
    // Cycle through the location's Participating with this order and see if
    // any of them are distributing this food already.
    CreateLocationAmounts(
      orderLog,
      orderIndex,
      userInfo,
      foodItem,
      distributedAmount,
      setLocationAmounts,
      setLocationsParticipating,
    );
  }, [distributedAmount]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (cancelled) => {
    // If it is cancelled then reset the values to their original values before
    // the user changed them.
    if (cancelled === "Cancelled") {
      CreateLocationAmounts(
        orderLog,
        orderIndex,
        userInfo,
        foodItem,
        distributedAmount,
        setLocationAmounts,
        setLocationsParticipating,
      );
    }

    setOpen(false);
  };

  // If the user changes any of the amounts distributed update them unless they
  // are less than 0.
  const handleChange = (e, location) => {
    if (e.target.value >= 0) {
      const locationAmountsTemp = { ...locationAmounts };
      locationAmountsTemp[location] = parseFloat(e.target.value);
      setLocationAmounts({ ...locationAmountsTemp });
    }
  };

  // Update the checkbox whether the user will remove or add the items from the pantry.
  // eslint-disable-next-line no-unused-vars
  const handleCheckBoxChange = (e, updatePantry) => {
    setUpdatePantry(e.target.checked);
  };

  // Save the changes.
  const handleSave = (e) => {
    e.preventDefault();
    // Save the distributed amounts to the database.
    SaveDistributedFoodItems(
      foodItem,
      orderLog,
      updateOrderLog,
      orderIndex,
      locationAmounts,
      updatePantry,
    );
    // Update the pantry if the user wanted to.
    if (updatePantry) {
      UpdatePantry(
        userInfo,
        foodItem,
        distributionAmountsChange,
        leftovers,
        setLeftovers,
      );
    }

    setOpen(false);
  };

  // Checks if the user can click save.  They can only save if the amount distributed
  // at each location totals what they have set using the slider.
  let canSave = false;
  // Add up all the assigned distributed amounts and use this to check if it equals
  // the total distributedAmount.
  let allAssigned = 0;

  // Cycle through the locationAmounts to add them all up.
  Object.keys(locationAmounts).forEach((location) => {
    allAssigned += locationAmounts[location];
  });

  // Check to make sure that allAssigned equals the total distributed.
  if (allAssigned === distributedAmount) {
    // Set this to true so the user can save
    canSave = true;
  }

  // Depending if the user has reduced or increased the distributed amount then they
  // may be adding items back to the pantry or removing them.
  let checkBoxText = `Remove ${Math.abs(distributionAmountsChange)}x ${
    foodItem.item
  } from the pantry`;

  // If they are removing distributed items then they can reshelve them.
  if (distributionAmountsChange < 0) {
    checkBoxText = `Reshelve ${Math.abs(distributionAmountsChange)}x ${
      foodItem.item
    } to the pantry.`;
  }

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        SET AMOUNT
      </Button>
      {distributionAmountsChange === 0 ? (
        <SyncRoundedIcon style={{ fill: "green" }} />
      ) : (
        <SyncDisabledRoundedIcon style={{ fill: "red" }} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Where was the {distributedAmount} x{foodItem.individualDescription} of{" "}
          {foodItem.item} distributed?
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave}>
            <span>
              {locationsParticipating.map((location, i) => (
                <TextField
                  key={i}
                  margin="dense"
                  label={location}
                  name={location}
                  value={locationAmounts[location]}
                  onChange={(e) => handleChange(e, location)}
                  fullWidth
                  type="number"
                  required
                />
              ))}
              {distributionAmountsChange !== 0 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="updatePantry"
                      checked={updatePantry}
                      onChange={(e) => setUpdatePantry(e.target.checked)}
                    />
                  }
                  label={checkBoxText}
                />
              )}
            </span>
            <DialogActions>
              <Button onClick={() => handleClose("Cancelled")} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={!canSave}>
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
