// When a distribution location adds to an existing order then there may be customer
// orders that take items from the pantry that shouldn't actually be taken from the pantry
// but taken from the current order.  This function will go through the current customer orders
// and remove any limits that are more then what the user needs.  If a user has ordered pantry items and
// current items this function may not be able to divide them up if they're all in one order.
// combinedPantryFoodList : The pantryList of the current order combined with the pantry list of the old order
// after it has been updated and items have been removed that no longer are pantry.
// newPantryFoodList : The list of items that have a limit attached to them from the customer lists.
// newCommunityOrders : community orders
// newImportedOrder : imported community orders.
export default function UpdateCustomerOrdersToRemoveLimits(
  combinedPantryFoodList,
  newPantryFoodList,
  newCommunityOrders,
  newImportedOrder,
) {
  // The pantry foodList of the old and new order combined and corrected to what
  // the actual amounts will be.
  combinedPantryFoodList.forEach((pantryFoodItem) => {
    // Find the index of this foodItem in the customer's pantry food List
    const indexOfItem = newPantryFoodList.findIndex((customerPantryItem) => {
      return (
        pantryFoodItem.item === customerPantryItem.item &&
        pantryFoodItem.individualDescription ===
          customerPantryItem.individualDescription &&
        pantryFoodItem.farmName === customerPantryItem.farmName
      );
    });
    // If the item exists then we will find the difference between the corrected quantity
    // of foodItems taken from the pantry vs. what the customer's ordered.
    if (indexOfItem !== -1) {
      // the difference between the customers pantry and the right amount.  Customer's
      // quantity will always be bigger than or equal to the correct amount.
      const diff =
        newPantryFoodList[indexOfItem].quantity - pantryFoodItem.quantity;
      // If it is greater than or equal to 0 then we will add diff to the customer pantry
      // list.  We will use this number to remove as many limits as possible.
      if (diff >= 0) {
        newPantryFoodList[indexOfItem].diff = diff;
      }
    }
  });

  // Cycle through the new pantry foodList made up of the customers' orders
  newPantryFoodList.forEach((customerPantryItem) => {
    // If the item has a diff or doesn't exist then we know we have to change the limit
    // on customer orders.  If it doesn't exist then it means that there shouldn't be any limits.
    if (customerPantryItem.diff > 0 || customerPantryItem.diff === undefined) {
      // Set the number of limits we need to remove to the total quantity of the item that was ordered
      // by all customers from the pantry.  This will be used when there is no diff which means that there
      // was no pantry item being ordered anymore so it was removed and so all limits must be removed.
      let limitsToRemove = customerPantryItem.quantity;
      // If the there is a diff then we know some limits will remain.
      if (customerPantryItem.diff !== undefined) {
        // set the limits to the difference which is how many limits still need to be removed.
        limitsToRemove = customerPantryItem.diff;
      }

      // Cycle through each community member's order.
      Object.keys(newCommunityOrders).forEach((customerOrder) => {
        // Find the index of the foodItem currently being addressed from the accumulative customer pantry food list.
        const indexOfItem = newCommunityOrders[
          customerOrder
        ].foodList.findIndex((customerFoodItem) => {
          return (
            customerPantryItem.item === customerFoodItem.item &&
            customerPantryItem.individualDescription ===
              customerFoodItem.individualDescription &&
            customerPantryItem.farmName === customerFoodItem.farmName
          );
        });
        // The user has this foodItem and the index exists.
        if (indexOfItem !== -1) {
          // If the limit exists on this item then it was included in the newPantryFoodList quantity.
          if (newCommunityOrders[customerOrder].foodList[indexOfItem].limit) {
            // Subtract the quantity of this customer's order from the total amount of limited items still
            // needing to be removed.
            const updatedDiff =
              limitsToRemove -
              newCommunityOrders[customerOrder].foodList[indexOfItem].quantity;
            // Check that the new difference is still greater than or equal to 0 otherwise we'll just leave it as
            // some of these items are taken from the pantry.
            if (updatedDiff >= 0) {
              // Remove the limit on this foodItem.
              delete newCommunityOrders[customerOrder].foodList[indexOfItem]
                .limit;
              // Check to see if the diff is not undefined which we'll now update to the
              // new amount of items that need to be removed.
              if (customerPantryItem.diff !== undefined) {
                // Update the diff.
                limitsToRemove = updatedDiff;
              }
              // Otherwise we're just removing all the limits and so we will just
              // keep removing the quantity of customer pantry items.
              else {
                limitsToRemove = updatedDiff;
              }
              // If the diff is 0 then we've removed all the limits we need to.
              // eslint-disable-next-line no-empty
              if (updatedDiff === 0) {
              }
            }
          }
        }
      });

      // Cycle through the imported community orders.
      if (Object.keys(newImportedOrder).length !== 0) {
        // cycle through each location.
        Object.keys(newImportedOrder).forEach((location) => {
          // Cycle through each customer's order.
          Object.keys(newImportedOrder[location].communityOrders).forEach(
            (customerOrder) => {
              // Find the index of the foodItem that we're currently checking for in this customer's
              // order foodList.
              const indexOfItem = newImportedOrder[location].communityOrders[
                customerOrder
              ].foodList.findIndex((customerFoodItem) => {
                return (
                  customerPantryItem.item === customerFoodItem.item &&
                  customerPantryItem.individualDescription ===
                    customerFoodItem.individualDescription &&
                  customerPantryItem.farmName === customerFoodItem.farmName
                );
              });
              // Check to see if the customer has this item.
              if (indexOfItem !== -1) {
                // Check to see if this customer ordered from the pantry.
                if (
                  newImportedOrder[location].communityOrders[customerOrder]
                    .foodList[indexOfItem].limit
                ) {
                  // Subtract the quantity of this customer's order from the total amount of limited items still
                  // needing to be removed.
                  const updatedDiff =
                    limitsToRemove -
                    newImportedOrder[location].communityOrders[customerOrder]
                      .foodList[indexOfItem].quantity;
                  // Check that the new difference is still greater than or equal to 0 otherwise we'll just leave it as
                  // some of these items are taken from the pantry.
                  if (updatedDiff >= 0) {
                    // Remove the limit on this foodItem.
                    delete newImportedOrder[location].communityOrders[
                      customerOrder
                    ].foodList[indexOfItem].limit;
                    // Check to see if the diff is not undefined which we'll now update to the
                    // new amount of items that need to be removed.
                    if (customerPantryItem.diff !== undefined) {
                      // Update the diff.
                      limitsToRemove = updatedDiff;
                    }
                    // Otherwise we're just removing all the limits and so we will just
                    // keep removing the quantity of customer pantry items.
                    else {
                      limitsToRemove = updatedDiff;
                    }
                    // If the diff is 0 then we've removed all the limits we need to.
                    // eslint-disable-next-line no-empty
                    if (updatedDiff === 0) {
                    }
                  }
                }
              }
            },
          );
        });
      }
    }
  });
}
