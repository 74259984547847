// This dialog appears when the admin user is looking at the volunteer schedule
// and wants to check the volunteer's information.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";

// This is the dialog box that opens when a user clicks the add button
function VolunteerInfoBox({ open, onClose, volunteer }) {
  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        {volunteer.firstName} {volunteer.lastName} Information <InfoIcon />
      </DialogTitle>
      <DialogContent>
        Email : {volunteer.email} <br />
        Phone Number : {volunteer.phoneNumber}
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

VolunteerInfoBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function VolunteerInfoDialog({ volunteer }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        • {volunteer.firstName} {volunteer.lastName}
      </Button>

      <VolunteerInfoBox
        open={open}
        onClose={handleClose}
        volunteer={volunteer}
      />
    </div>
  );
}
