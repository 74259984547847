// This is the board that users can view to vote on improvements and read more about
// new issues.
import React, { useEffect, useState, useContext } from "react";
import LikeCounter from "../../../components/userInputs/LikeCounter.js";
import { Link } from "react-router-dom";
import firebase from "../../../components/Firebase.js";
import { AuthContext } from "../../../components/authentication/Auth.js";

// Each individual issue with the rank, title, and number of likes.  The title is
// The title of the section it is in either The Top 10 Issues or the Your Voted
// Issues.  This is to check if the rank should be shown.  props.title is the title
// of the individual issue.
function Issue(props, index, handleVoteChange, votesUsed, title) {
  return (
    <div key={title + index} className="Issue-Box">
      {title === "Top 3 Improvements" ? (
        <h4 className="Rank-Title Header-4">
          {" "}
          {index + 1} {props.title}{" "}
        </h4>
      ) : (
        <h4 className="Rank-Title Header-4"> {props.title} </h4>
      )}
      <div className="Like-Count-Row">
        <LikeCounter
          numOfLikes={props.numOfLikes}
          title={props.title}
          handleVoteChange={handleVoteChange}
          votesUsed={votesUsed}
        />
      </div>
    </div>
  );
}

// Formats the entire top ten issues list
function IssuesBox(issueList, handleVoteChange, title, loggedIn, votesUsed) {
  return (
    <div className="Top-10-Issues-Box">
      {loggedIn ? (
        <div className="Title-Row">
          <p className="Hidden-Element"> Votes Used: {votesUsed}/10 </p>
          <h3 className="Issue-Box-Title"> {title} </h3>
          <p> Votes Used: {votesUsed}/10 </p>
        </div>
      ) : (
        <div className="Issue-Box-Title">
          <h3> {title} </h3>
        </div>
      )}
      {issueList == null || issueList.length === 0 ? (
        // eslint-disable-next-line react/no-unescaped-entities
        <h3>You haven't voted for anything yet!</h3>
      ) : (
        <div className="Top-10-Issue-List">
          {issueList.map((issueItem, index) =>
            Issue(issueItem, index, handleVoteChange, votesUsed, title),
          )}
        </div>
      )}
    </div>
  );
}

export default function ImprovementBoard() {
  useEffect(() => {
    // This is to find all the active issues to display in the top 3
    const issuesDocRef = database
      .collection("Issues")
      .where("status", "==", "active")
      .orderBy("numOfLikes", "desc")
      .limit(3);
    const issuesDataTransfer = [];
    issuesDocRef.get().then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          issuesDataTransfer.push(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
      setIssueList(issuesDataTransfer);
      return issuesDataTransfer;
    });
  }, []);

  const [issueList, setIssueList] = useState([]);

  const database = firebase.firestore();

  const { currentUser, userInfo, handleUserInfoChange } =
    useContext(AuthContext);

  let votesUsed = 0;
  if (userInfo.votedIssues != null) {
    userInfo.votedIssues.sort(compare);
    userInfo.votedIssues.forEach((issue) => {
      votesUsed += issue.votes;
    });
  }

  // This fucntions compares the number of likes of the items to ensure they
  // stay ranked in the correct order
  function compare(a, b) {
    if (a.numOfLikes < b.numOfLikes) {
      return 1;
    }
    if (a.numOfLikes > b.numOfLikes) {
      return -1;
    }
    return 0;
  }

  // This function will handle when a user upvotes or downvotes an issue.  It updates
  // the hooks that are currently holding the issue list in the top 3 issues box
  // as well as the user's own issue lists of issues they've voted for themselves.
  // It will check that the user has votes left and will make sure that user can
  // only downvote an item when they can.  It then updates the database to reflect
  // these changes
  function handleVoteChange(title, votesUsed, upVote) {
    // The maximum allowed votes from the user
    const maxVote = 10;
    // This will be the issue list that contains all the changes from the user
    const updatedIssueList = [...issueList];
    // This will be the user's own issue list that contains all the changes from
    // the users
    let updatedUsersLikedIssues = [];

    // This is to check if the user has liked anything yet and if so will set our
    // list equal their current list.
    if (userInfo.votedIssues.length !== 0) {
      updatedUsersLikedIssues = [...userInfo.votedIssues];
    }
    const issuesDocRef = database.collection("Issues").doc(title);
    const usersDocRef = database.collection("Users").doc(currentUser.uid);
    const batch = database.batch();
    let change = null;

    // Finds the index of the selected item in the top 10 issues box
    const indexIssueList = updatedIssueList.findIndex((list) => {
      return list.title === title;
    });

    // Finds the index of the selected item in the users issue list
    const indexUsersLikedIssues = updatedUsersLikedIssues.findIndex((list) => {
      return list.title === title;
    });

    // If the user upvoted and they haven't reached they max number of votes yet
    if (upVote && votesUsed < maxVote) {
      if (indexIssueList !== -1) {
        // Updates the issue list to include their like
        updatedIssueList[indexIssueList].numOfLikes += 1;
      }

      // Checks if the user has already liked this issue and if they haven't then
      // it will push this issue into their issue list and give it one vote.
      // This was difficult because it is an array of dictionaires so you have to
      // make sure you're adding the values of the dictionary and not a reference
      // to the dictionary itself.
      if (indexUsersLikedIssues === -1) {
        updatedUsersLikedIssues.push({ ...updatedIssueList[indexIssueList] });
        updatedUsersLikedIssues[updatedUsersLikedIssues.length - 1].votes = 1;
      }
      // If the user has liked this issue before then it will just add 1 more vote
      // to this issue in their user list.  It will also add to the numOfLikes
      else {
        updatedUsersLikedIssues[indexUsersLikedIssues].votes += 1;
        updatedUsersLikedIssues[indexUsersLikedIssues].numOfLikes += 1;
      }

      // The change variable is set to increment 1 value
      change = firebase.firestore.FieldValue.increment(1);
    }

    // If the user down voted and they selected an item that is in their issue list
    else if (!upVote && indexUsersLikedIssues !== -1) {
      if (indexIssueList !== -1) {
        // Updates the issue list to include their like removal
        updatedIssueList[indexIssueList].numOfLikes -= 1;
      }

      // Checks if the user has only one like left for this issue and if it does
      // removes this issue for thier list
      if (updatedUsersLikedIssues[indexUsersLikedIssues].votes === 1) {
        updatedUsersLikedIssues.splice(indexUsersLikedIssues, 1);
      }
      // If the user has more than one like for this issue then it just subtracts
      // one from their total
      else {
        updatedUsersLikedIssues[indexUsersLikedIssues].votes -= 1;
        updatedUsersLikedIssues[indexUsersLikedIssues].numOfLikes -= 1;
      }
      change = firebase.firestore.FieldValue.increment(-1);
    }

    // If there was a valid change sent through then before the update to the
    // the issueList, the user's issue list.  The userList must be sent through
    // a function to the Auth.js and the issue list is a hook and needs to be updated.

    if (change !== null) {
      batch.update(issuesDocRef, { numOfLikes: change });
      batch.set(
        usersDocRef,
        { votedIssues: updatedUsersLikedIssues },
        { merge: true },
      );
      batch.commit();

      updatedIssueList.sort(compare);
      updatedUsersLikedIssues.sort(compare);
      handleUserInfoChange(updatedUsersLikedIssues);
      setIssueList(updatedIssueList);
    }
  }

  return (
    <div>
      <span className="Issue-List-Link">
        <Link className="Issue-List-Button" to="/ListOfIssues">
          Improvement List
        </Link>
      </span>
      {IssuesBox(
        issueList.slice(0, 3),
        handleVoteChange,
        "Top 3 Improvements",
        !!currentUser,
        votesUsed,
      )}
      {!!currentUser && (
        <span>
          {IssuesBox(
            userInfo.votedIssues,
            handleVoteChange,
            "Your Voted Improvements",
            false,
            votesUsed,
          )}
        </span>
      )}
      <p>
        *The Improvement List is a way for the users of the platform to voice
        their opinions and vote up ideas to be chosen as the platform's focus.
      </p>
    </div>
  );
}
