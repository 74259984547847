// This is old code that was used before the imported orders was changed and multiple
// orders can be imported.  June 17 2021.

// This is to add a new foodItem to a community order either imported or not from
// a request on the myAccount Page
import firebase from "./../Firebase.js";

// props comtains:
// userInfo : The distribution location's userInfo
// order : communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
// foodItemUpdate : foodItem to update quantity, price, distributionQuantity, etc.
// userOrder contains: customerContribution: "donation", farmDict: {}, farmerList: [{…}, {…}], foodDict: {}, foodList: [{…}, {…}], orderDate: 202012101607...
// orderLog contains: [array with each orderLoaded for the user]
// updateOrderLog contains: a function to update OrderLog
// orderIndex contains: the index of the current order in the orderLog array
// ordersToChange : Array with firstName-lastName-userId-orderDate of all communityOrders to be changed
// importedOrdersToChange : Array with firstName-lastName-userId-orderDate of all importedOrder to be changed
// newPrice : new price of the food
export default function UpdatePriceCommunityOrdersOld(
  props,
  ordersToChange,
  importedOrdersToChange,
  newPrice,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  const userInfo = props.userInfo;
  const order = props.order;
  const foodItemUpdate = props.foodItemUpdate;
  const updateOrderLog = props.updateOrderLog;
  const orderIndex = props.orderIndex;
  // Finds when then user ordered to help find the individual's order in the database
  const orderDate = order.orderDate;

  // Find the index of the foodItem in the overall order for the distribution location
  const foodListIndex = order.foodList.findIndex(
    (element) =>
      element.item === foodItemUpdate.item &&
      element.description === foodItemUpdate.description &&
      element.farmName === foodItemUpdate.farmName,
  );

  // Create a temporary foodList to modify
  const foodListTemp = [...order.foodList];

  // Set the price of the foodItem of the foodList order
  foodListTemp[foodListIndex].price = newPrice;

  // Create a temporary communityOrders list
  const communityOrdersTemp = { ...order.communityOrders };

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(orderDate);

  // Cycle through the list of orders that need to be modified.  In the communityOrders
  ordersToChange.forEach((user) => {
    // This is used to find the indivdual's order in the database
    const userId = user.split("-");

    // The indivdual's order also needs to be updated.  This is the reference to it
    // in firebase.
    const userDocRef = database
      .collection("Users")
      .doc(userId[2])
      .collection("Orders")
      .doc(userId[3]);

    // This is used to update the document in the DistributionLocations collection
    let communityOrdersDocRef = null;

    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);

    // Finds the index of the item to be updated in the individual customer's orders
    const index = communityOrdersTemp[user].foodList.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName,
    );

    // Updates the indivdual order in the communityOrders dictionary
    communityOrdersTemp[user].foodList[index].price = parseFloat(newPrice);

    // Check that if the user is using a subsidy it is not more than what they’re using
    if (
      communityOrdersTemp[user].customerContribution === "subsidy" &&
      communityOrdersTemp[user].donationSubsidy > 0
    ) {
      // The total of this order
      let orderTotal = 0;
      // Cycle through the user's order and add up the total price of the order
      communityOrdersTemp[user].foodList.forEach((foodItem) => {
        orderTotal +=
          foodItem.quantity *
          ((foodItem.price / foodItem.distributionQuantity) *
            foodItem.individualQuantity);
      });
      // Check to see if the subsidy used is larger than the orderTotal and if it is
      // then the reset the orderTotal.
      if (communityOrdersTemp[user].donationSubsidy > orderTotal) {
        communityOrdersTemp[user].donationSubsidy = orderTotal;
      }
    }

    // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
    // FirstName-LastName-userId-date
    batch.update(userDocRef, {
      foodList: [...communityOrdersTemp[user].foodList],
      donationSubsidy: communityOrdersTemp[user].donationSubsidy,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(communityOrdersDocRef, {
      [user.concat(".foodList")]: [...communityOrdersTemp[user].foodList],
      [user.concat(".donationSubsidy")]:
        communityOrdersTemp[user].donationSubsidy,
    });
  });

  // The imported order set a temporary dictionary to modify
  const importedOrdersTemp = { ...order.importedOrder };

  // The orders imported that need to be modified.
  importedOrdersToChange.forEach((user) => {
    // This is used to find the indivdual's order in the database
    const userId = user.split("-");

    // The indivdual's order also needs to be updated.  This is the reference to it
    // in firebase.
    const importedUserDocRef = database
      .collection("Users")
      .doc(userId[2])
      .collection("Orders")
      .doc(userId[3]);

    // This is used to update the document in the DistributionLocations collection
    let importedCommunityOrdersDocRef = null;

    // Update the current distribution locations orders in the DistributionLocations
    // collection
    importedCommunityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(order.importedDistributionLocation)
      .collection("CommunityOrders")
      .doc(order.importedDistributionDate);

    // Find the index of the foodItem that needs to be changed in the individual orders
    const index = importedOrdersTemp[user].foodList.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName,
    );

    // Updates the indivdual order in the importedOrder dictionary
    importedOrdersTemp[user].foodList[index].price = parseFloat(newPrice);

    // Check that if the user is using a subsidy it is not more than what they’re using
    if (
      importedOrdersTemp[user].customerContribution === "subsidy" &&
      importedOrdersTemp[user].donationSubsidy > 0
    ) {
      // The total of this order
      let orderTotal = 0;
      // Cycle through the user's order and add up the total price of the order
      importedOrdersTemp[user].foodList.forEach((foodItem) => {
        orderTotal +=
          foodItem.quantity *
          ((foodItem.price / foodItem.distributionQuantity) *
            foodItem.individualQuantity);
      });
      // Check to see if the subsidy used is larger than the orderTotal and if it is
      // then the reset the orderTotal.
      if (importedOrdersTemp[user].donationSubsidy > orderTotal) {
        importedOrdersTemp[user].donationSubsidy = orderTotal;
      }
    }

    // Updates Users -> userId -> Orders -> date;
    batch.update(importedUserDocRef, {
      foodList: [...importedOrdersTemp[user].foodList],
      donationSubsidy: importedOrdersTemp[user].donationSubsidy,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(importedCommunityOrdersDocRef, {
      [user.concat(".foodList")]: [...importedOrdersTemp[user].foodList],
      [user.concat(".donationSubsidy")]:
        importedOrdersTemp[user].donationSubsidy,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(docRef, {
      importedOrder: { ...importedOrdersTemp },
    });
  });

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...props.orderLog];

  // Users -> userId -> Orders -> date
  batch.update(docRef, {
    communityOrders: { ...communityOrdersTemp },
    foodList: [...foodListTemp],
  });

  // This is to make sure the page rerenders these items correctly for importedOrders
  if (importedOrdersToChange.length !== 0) {
    orderLogTemp[orderIndex].importedOrder = { ...importedOrdersTemp };
  }

  // This updates the communityOrders and the foodList
  orderLogTemp[orderIndex].communityOrders = { ...communityOrdersTemp };
  orderLogTemp[orderIndex].foodList = [...foodListTemp];

  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);
}
