// Once a user has entered a secret code then the database has to be updated in
// some different spots to allow for them to access these spots.
// eslint-disable-next-line no-unused-vars
import { useContext } from "react";
import firebase from "../../../components/Firebase.js";

export default function AutomaticallyAddToCommunityLocation(
  secretLocation,
  userInfo,
  handleUserInfoChange,
  communityDistributionLocations,
  privateCommunityDistributionLocations,
  setCommunityDistributionLocations,
  setPrivateCommunityDistributionLocations,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // To find the location in the database CommunityLocations this is the name of the community
  // location document name.
  const documentName = [
    secretLocation.firstName,
    secretLocation.lastName,
    secretLocation.userId,
  ].join("");

  // This is the list of approvedCommunityMembers.
  const approvedCommunityMembers = secretLocation.approvedCommunityMembers;

  // Add the current user to the approved list as they are being approved.
  approvedCommunityMembers.push(userInfo.userId);

  // Reference the document in the database of communityLocation.
  const docRef = database.collection("CommunityLocations").doc(documentName);

  // Update the document in the community hub to include the approvedCommunityMembers.
  batch.update(docRef, { approvedCommunityMembers });

  // Find the document of the community hosts accepted community hub users.
  const communityUserDocRef = database
    .collection("Users")
    .doc(secretLocation.userId)
    .collection("CommunityHosts")
    .doc("CommunityMembers");

  // The key that has the current users name in the CommunityMembers document
  const keyName = [userInfo.firstName, userInfo.lastName, userInfo.userId].join(
    "-",
  );

  // Set the user to true.
  batch.update(communityUserDocRef, { [keyName]: true });

  // Update the current user's userInfo so that it has that they've been approved.
  // eslint-disable-next-line no-unused-vars
  const currentUserDocRef = database.collection("Users").doc(userInfo.userId);

  // The key to update the current user's userInfo to include the new accepted community
  // hub location.
  const communityLocationKey = [
    secretLocation.firstName,
    secretLocation.lastName,
    secretLocation.userId,
  ].join("-");

  batch.commit();

  // We also have to update the userInfo of the current session.
  const userInfoTemp = { ...userInfo };

  // Checks if the user has CommunityHostAccess in their userInfo.
  if (userInfoTemp.CommunityHostAccess === undefined) {
    // Adds CommunityHostAccess if it doesn't exist.
    userInfoTemp.CommunityHostAccess = {};
  }
  // Add the following to the userInfo.CommunityHostAccess[distributionLocation]
  userInfoTemp.CommunityHostAccess[communityLocationKey] = {
    locationName: secretLocation.locationName,
    status: "approved",
  };
  handleUserInfoChange({ ...userInfoTemp }, true);

  // We have to move the secret location for this user from the privateCommunityDistributionLocations
  // to the normal communityLocations so that the user can actually see the location.
  const indexOfPrivate = privateCommunityDistributionLocations.findIndex(
    (communityLocation) => {
      return communityLocation.secretCode === secretLocation.secretCode;
    },
  );

  // If the index exists then update the communityDistributionLocations to include
  // this no longer secret location and remove it from the privateCommunityDistributionLocations.
  if (indexOfPrivate !== -1) {
    const communityDistributionLocationsTemp = [
      ...communityDistributionLocations,
    ];
    communityDistributionLocationsTemp.push(
      privateCommunityDistributionLocations[indexOfPrivate],
    );
    const privateCommunityDistributionLocationsTemp = [
      ...privateCommunityDistributionLocations,
    ];
    privateCommunityDistributionLocationsTemp.splice(indexOfPrivate, 1);
    setCommunityDistributionLocations(communityDistributionLocationsTemp);
    setPrivateCommunityDistributionLocations(
      privateCommunityDistributionLocationsTemp,
    );
  }
}
