// When a distribution location checks if there are any new orders placed after
// they've placed orders for the week this function will re-calcualate the payments
// owed by each indivdual who placed an order and update it in the database to be tracked.
import firebase from "../../../components/Firebase.js";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";

export default function UpdatePaymentHistory(
  selectedDate,
  imported,
  location,
  newOrders,
  orderDate,
  userInfo,
  updatePaymentHistory,
) {
  //  selectedDate : MM-DD-YYYY
  //   importedOrder :
  // 	  DistributionLocation :
  // 		 Location : XXXX
  // 	   date : MM-DD-YYYY
  // 	   communityOrders :
  //      firstName-lastName-userId-date :
  //      deposits: { timestamp : $XX.XX, timestamp : $XX.XX,
  //                            timestamp : $XX.XX}
  //      totalOwed : $XX.XX
  //      DistributionLocation : XXXX
  //      Owed History : [timestamp : $XX.XX, timestamp : $XX.XX, timestamp : $XX.XX]
  //      OrderNotes : XXXXXX
  //      orderRefunds :{ timestamp : $XX.XX, timestamp : $XX.XX,
  //                      timestamp : $XX.XX}
  // 	 communityOrders
  // 		firstName-lastName-userId-date :
  //      deposits: { timestamp : $XX.XX, timestamp : $XX.XX,
  //                            timestamp : $XX.XX}
  //      totalOwed : $XX.XX
  //      DistributionLocation : XXXX
  //      Owed History : [timestamp : $XX.XX, timestamp : $XX.XX, timestamp : $XX.XX]
  //      OrderNotes : XXXXXX
  //      orderRefunds :{ timestamp : $XX.XX, timestamp : $XX.XX,
  //                      timestamp : $XX.XX}
  let orderDatePaymentHistory = {};
  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // set up the datebase.
  const database = firebase.firestore();
  // eslint-disable-next-line no-unused-vars
  const batch = database.batch();

  // This dictionary is used to store the data in the database including.
  const paymentHistoryDocRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);
  // Update the Payment History to store information to help track payments.
  paymentHistoryDocRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since we'll be adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database.runTransaction((transaction) => {
            // Store the document's reference.
            const paymentHistoryDocRef = database
              .collection("PaymentHistory")
              .doc(documentName);

            // This code may get re-run multiple times if there are conflicts.
            return transaction
              .get(paymentHistoryDocRef)
              .then((paymentHistoryDoc) => {
                if (!paymentHistoryDoc.exists) {
                  // eslint-disable-next-line no-throw-literal
                  throw "Document does not exist!";
                }

                // Load the full document of this order Date.
                const documentData = paymentHistoryDoc.data();

                const index = Object.keys(doc.data()).indexOf(orderDate);
                // The order exists in this document.
                if (index !== -1) {
                  // Store the current orderDate.
                  orderDatePaymentHistory = doc.data()[orderDate];

                  // Store the communityOrders of this date.
                  let communityOrdersPaymentHistory = {};

                  if (!imported) {
                    // The dictionary that saves the community orders payment history.  This is used
                    // later and added into the larger payment history that is saved to the database.  This
                    // is specifically for the community location's orders not their imported orders.
                    communityOrdersPaymentHistory =
                      orderDatePaymentHistory.communityOrders;
                  } else {
                    // Store the importedOrder of this date.
                    communityOrdersPaymentHistory =
                      orderDatePaymentHistory.importedOrder[location]
                        .communityOrders;
                  }

                  // Since we want to order the user's order history from oldest to newest we have to
                  // sort the order.
                  const userOrders = Object.keys(newOrders).slice();
                  userOrders.sort();

                  // Cycle through all the communityOrders
                  userOrders.forEach((userOrder) => {
                    // Total up the cost of the order using PriceTotals which separates out the total
                    // owed to each farm.
                    const orderFarmsCost = PriceTotals(
                      newOrders[userOrder].foodList,
                      true,
                    );
                    // Total up the cost of packaging fees using PackageTotals which separates out the total
                    // packaging for each farm.
                    const packageTotals = PackageTotals(
                      newOrders[userOrder].foodList,
                    );

                    // set the order cost to 0.
                    let orderCost = 0;
                    // Cycle through the totals owed to each farm and add them up.
                    Object.keys(orderFarmsCost).forEach((farmName) => {
                      orderCost += orderFarmsCost[farmName];
                      if (packageTotals[farmName] > 0) {
                        orderCost += packageTotals[farmName];
                      }
                    });
                    // Add or subtract the subsidy and donations to the total order cost.
                    if (
                      newOrders[userOrder].customerContribution === "donation"
                    ) {
                      orderCost += newOrders[userOrder].donationSubsidy;
                    } else {
                      orderCost -= newOrders[userOrder].donationSubsidy;
                    }
                    // If the new orders has a cash portion for the volunteer fee
                    // then we want to add that to the order cost.
                    if (newOrders[userOrder].cashPortion) {
                      orderCost += parseFloat(newOrders[userOrder].cashPortion);
                    }

                    // Split the user's name up to remove the date of the order as that is stored in
                    // the orderHistory key.
                    const user = [
                      userOrder.split("-")[0],
                      userOrder.split("-")[1],
                      userOrder.split("-")[2],
                    ].join("-");
                    // Store the date of the user's order separately to be used later.
                    const userOrderTime = userOrder.split("-")[3];

                    // If the user has already placed an order then we are just updating their order
                    // otherwise create a new order history for them.
                    if (communityOrdersPaymentHistory[user]) {
                      // Add the new orders amount to their old amount.
                      communityOrdersPaymentHistory[user].totalOwed +=
                        orderCost;
                      // Add this new order's total and date to the orderHistory.
                      communityOrdersPaymentHistory[user].orderHistory[
                        userOrderTime
                      ] = communityOrdersPaymentHistory[user].totalOwed;
                      // Update the notes to inlcude the additional order added.
                      communityOrdersPaymentHistory[user].orderNotes =
                        communityOrdersPaymentHistory[user].orderNotes.concat(
                          `Distribution Location updated order to include order placed ${userOrderTime}. `,
                        );
                    } else {
                      // Create a blank dictionary to store all the information of the user's order.
                      communityOrdersPaymentHistory[user] = {};
                      // Create an empty deposit dictionary for when the user eventually pays.
                      communityOrdersPaymentHistory[user].deposits = {};
                      // The total amount the user owes for their order.
                      communityOrdersPaymentHistory[user].totalOwed = orderCost;
                      // Include the distribution location's name their ordering from.
                      communityOrdersPaymentHistory[user].distributionLocation =
                        userInfo.organisationName;
                      // Add to the order history to include the timestamp of the order and the cost.
                      communityOrdersPaymentHistory[user].orderHistory = {
                        [userOrderTime]: orderCost,
                      };
                      // Create a blank order notes.
                      communityOrdersPaymentHistory[user].orderNotes = "";
                      // Create a dictionary for if the user needs refunds in the future.
                      communityOrdersPaymentHistory[user].orderRefunds = {};
                    }
                  });

                  if (!imported) {
                    // Update the payment history to include the community orders.
                    orderDatePaymentHistory.communityOrders =
                      communityOrdersPaymentHistory;
                  } else {
                    // Update the payment history to include the community orders from all the imported
                    // locations.
                    orderDatePaymentHistory.importedOrder[
                      location
                    ].communityOrders = communityOrdersPaymentHistory;
                  }
                }
                // This order doesn't exist in this document so you can't update it.
                else {
                  console.log("This order cannot be updated.");
                }

                // Update the full documentName to include the changes for this date.
                documentData[orderDate] = orderDatePaymentHistory;

                // Complete the transaction.  If the document has been changed then
                // this document has to go again.
                transaction.update(paymentHistoryDocRef, documentData);
                // Update the paymentHistory dictionary
                updatePaymentHistory(documentData, orderDate);
              });
          });
        } else {
          console.log("UNDEFINED");
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
