// This button is used to export the individual volunteer schedule into a ics file
// this can then be uploaded to a user's calendar by importing it or by opening it
// on their device using their default calendar app.
import React, { useContext } from "react";
import { AuthContext } from "../../../../components/authentication/Auth.js";
import Button from "@material-ui/core/Button";
import { saveAs } from "file-saver";
// eslint-disable-next-line no-unused-vars
import { createEvents, ics } from "ics";

export default function ExportToCalendarButton({ volunteerSchedule }) {
  // We need userInfo and so a and b are just placeholders for the other variables
  // that we won't use.
  // eslint-disable-next-line no-unused-vars
  const { userInfo, a, b } = useContext(AuthContext);
  const CALENDAR_TITLE = "F2PM Volunteer Schedule";

  // timePeriod is in format 2023-Q1, so we need to split it up and make it an int
  const YEAR = volunteerSchedule.timePeriod
    ? parseInt(volunteerSchedule.timePeriod.split("-")[0])
    : null;

  // Creates the event array that holds this users volunteer shifts.
  const getEventsForCurrentUser = () => {
    const events = [];
    // Cycle through the volunteerSchedule which starts with the dates.
    for (const date in volunteerSchedule) {
      // TimePeriod is a key that we want to ignore.
      if (date !== "timePeriod") {
        // While there are positions to cycle through for a date
        for (const positionTitle in volunteerSchedule[date]) {
          // Cycle through the timeslots that exist for this volunteer position.
          for (const timeSlot in volunteerSchedule[date][positionTitle]) {
            // There are the location and credit value keys which we want to ignore.
            if (timeSlot !== "location" && timeSlot !== "creditValue") {
              // Set the volunteer position time slot.
              const slot = volunteerSchedule[date][positionTitle][timeSlot];
              // Check that this user is a volunteer for this position.
              if (slot.volunteers.some((v) => v.userId === userInfo.userId)) {
                // Add this volunteer timeslot to the events array.
                events.push({
                  date,
                  positionTitle,
                  timeSlot,
                  location: volunteerSchedule[date][positionTitle].location,
                  ...slot,
                });
              }
            }
          }
        }
      }
    }
    return events;
  };

  // This is called when the  user clicks the button to export the ics file.
  const exportToCalendar = () => {
    // Create the events array.
    const events = getEventsForCurrentUser();
    // Cycles through all the events and returns the volunteer shifts in the format
    // for the ics file.  This is then used to create the events in the file.
    const calendarEvents = events.map((event) => {
      // format is 0013 for jan 13
      let [month, day] = event.date.match(/.{1,2}/g).map(Number);
      month = month + 1;
      // format for Monday 19:00 to 21:00 is Monday19002100
      const timeSlot = event.timeSlot.replace(/[a-zA-Z]/g, "");
      const [startHour, startMinute, endHour, endMinute] = timeSlot
        .match(/.{1,2}/g)
        .map(Number);

      return {
        start: [YEAR, month, day, startHour, startMinute],
        end: [YEAR, month, day, endHour, endMinute],
        title: event.positionTitle,
        description: `${event.positionTitle} for Farm To Table Marketplace at ${event.location}`,
        location: event.location,
      };
    });
    // Creates the ics file.
    const { error, value } = createEvents(calendarEvents);

    // If there is an error let the user know.
    if (error) {
      console.error("Error creating .ics file:", error);
      return;
    }

    // Creates a blob which will be the file ics type.
    const blob = new Blob([value], { type: "text/calendar;charset=utf-8" });
    // Provide the user with the ics file.
    saveAs(blob, `${CALENDAR_TITLE}.ics`);
  };

  return (
    <>
      <Button
        onClick={exportToCalendar}
        className="export-to-calendar-button"
        color="primary"
      >
        {"EXPORT CALENDAR"}
      </Button>
    </>
  );
}
