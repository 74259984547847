// Takes in the list of pantry foodItems that were previously ordered in the exisiting
// order and the list of pantry foodItems being ordered currently.  It combines these
// two list to make one complete list of all pantry foodItems.
export default function AddPantryFoodToExisting(
  existingPantryFoodList,
  newPantryFoodList,
) {
  // checks if existingPantryFoodList and if not the set it to nothing
  if (!existingPantryFoodList) {
    existingPantryFoodList = [];
  }
  // add the Pantry food Items from the previous order to the combined pantry foodList
  const combinedFoodList = [...existingPantryFoodList];

  // Cycles through all the pantry foodItems that are currently being ordered and adds
  // them to the old orders.  If the item already exists it updates the item
  // otherwise push the foodItem to the list.
  newPantryFoodList.forEach((foodItem) => {
    // First look to see if the index exists where this foodItem already exists
    // in the combinedFoodList.
    const index = combinedFoodList.findIndex(
      (item) =>
        item.item === foodItem.item &&
        item.description === foodItem.description &&
        item.farmName === foodItem.farmName,
    );

    // If the item exists already than just add the quantity to it otherwise push
    // the whole foodItem.
    if (index !== -1) {
      combinedFoodList[index].quantity += foodItem.quantity;
      combinedFoodList[index].limit = foodItem.limit;
    } else {
      combinedFoodList.push({ ...foodItem });
    }
  });

  // Return the foodList
  return combinedFoodList;
}
