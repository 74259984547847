import React from "react";
import OrganisationInfoForm from "../../../components/userForms/OrganisationInfoForm.js";

// This is the Personal Info Tab which contains the DistributionLocation's Name, the owner's
// first name, last name, email, phone number, order cutoff, pickup day, and pick up time
export default function OrganisationInfo(props) {
  const userInfo = props.userInfo;
  // This is the subsidy amount of the distribution location
  let subsidyAmount = userInfo.subsidyAmount;

  // Of often the subsidy will renew.
  let freqOfFreeSubsidy = userInfo.freqOfFreeSubsidy;

  // In case the subsidyAmount isn't defined or if the subsidy amount isn't a number
  // set it equal to 0
  if (subsidyAmount === undefined || typeof subsidyAmount !== "number") {
    subsidyAmount = 0;
  }

  // In case the freqOfFreeSubsidy isn't defined or if the subsidy amount isn't a number
  // set it equal to 1
  if (
    freqOfFreeSubsidy === undefined ||
    typeof freqOfFreeSubsidy !== "number"
  ) {
    freqOfFreeSubsidy = 1;
  }

  return (
    <div className="Tab-Box">
      <div className="Tab-Item-Row">
        <div>
          <h5 className="Row-Title"> Organisation Name: </h5>
        </div>
        <p className="My-Account-Item"> {userInfo.organisationName} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Contact Name: </h5>
        <p className="My-Account-Item">
          {" "}
          {userInfo.firstName} {userInfo.lastName}{" "}
        </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Phone Number: </h5>
        <p className="My-Account-Item"> {userInfo.phoneNumber} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Email: </h5>
        <p className="My-Account-Item"> {userInfo.email} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Order Cutoff Day: </h5>
        <p className="My-Account-Item"> {userInfo.orderCutoff} </p>
      </div>
      {/* NEEDS WORK
        <div className = "Tab-Item-Row" >
          <h5 className = "Row-Title"> Order Cutoff Time: </h5>
          <p className = "My-Account-Item" > {userInfo.orderCutoffTime} </p>
        </div>
      */}
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Pickup Day: </h5>
        <p className="My-Account-Item"> {userInfo.pickupDay} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Pickup Time: </h5>
        <p className="My-Account-Item"> {userInfo.pickupTime} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Subsidy Amount: </h5>
        <p className="My-Account-Item"> ${subsidyAmount.toFixed(2)} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Free Subsidy Frequency: </h5>
        <p className="My-Account-Item"> Every {freqOfFreeSubsidy} weeks</p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Empty Marketplace Message: </h5>
        <p className="My-Account-Item"> {userInfo.emptyMarketplaceMessage} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> E-transfer Deposit Type: </h5>
        <p className="My-Account-Item"> {userInfo.etransferType} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> E-transfer Email: </h5>
        <p className="My-Account-Item"> {userInfo.etransferEmail} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Base Delivery Fee: </h5>
        <p className="My-Account-Item"> ${userInfo.baseDeliveryFee} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Volunteer Percentage: </h5>
        <p className="My-Account-Item"> {userInfo.volunteerPercentage}% </p>
      </div>
      <OrganisationInfoForm
        info={props}
        handleInfoUpdate={props.handleInfoUpdate}
        userInfo={userInfo}
        currentUser={props.currentUser}
      />
    </div>
  );
}
