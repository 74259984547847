// This page renders all the farmers and the food vendors.  It calls FarmerWriteUp
// which renders the description, image and details of the farmers.
import React, { useEffect, useState } from "react";
import "../App.css";
import "../styles/OurFarmers.css";
import Menu from "../components/Menu.js";
import firebase from "../components/Firebase.js";
import LoadingContent from "../components/LoadingContent.jsx";
import Footer from "../components/Footer.jsx";
import FarmerWriteUp from "./FarmersPage/FarmerWriteUp.jsx";

export default function OurFarmers() {
  const database = firebase.firestore();

  // The hook where the data downloaded from the database is stored.
  const [farmers, setFarmers] = useState("0");
  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const farmerDocRef = database
      .collection("Farmers")
      .where("status", "==", "active")
      .orderBy("farmName");
    const farmerDataTransfer = [];
    farmerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            farmerDataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setFarmers(farmerDataTransfer);
        setLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    // scroll to the top of the page.
    window.scrollTo(0, 0);
  }, []);

  // Checks if the data has been loaded yet
  if (loading) {
    return (
      <div id="home" style={{ background: "rgb(244, 249, 224)" }}>
        <Menu />
        <h1 className="Farmer-Page-Title">Our Farmers and Vendors</h1>
        <div className="Farmers-Page">
          {farmers.map((farmer, index) => (
            <>
              <FarmerWriteUp farmer={farmer} key={index} />
              <hr
                style={{ marginBottom: "50px" }}
                className="Farmers-Line-Divider"
              />
            </>
          ))}
          <p className="Farmer-Join-Text" style={{ padding: "0px 50px" }}>
            New farmers and food vendors are welcome. We recommend you check out{" "}
            <a
              className="Farmer-Join-Text"
              style={{ marginBottom: "-17px" }}
              href="/CommunityEngagement"
            >
              {" "}
              the Order Details Page{" "}
            </a>
            to gain insight on the volumes of food our community members buy and
            current availability and pricing.
            <br />
            <br />
            If you have any questions or would like to join please contact us by
            phone at 778-896-6754 or email anthony@farmtoplatemarketplace.com
          </p>
        </div>

        <Footer />
      </div>
    );
  } else {
    return <LoadingContent />;
  }
}
