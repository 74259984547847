// This component is to create show accordians that show the distribution locations
// they explain the different icons and what they mean.  There are the distribution
// locations, the community hubs, the private community hubs.
import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    background: "rgb(228 255 153)",
  },
})(MuiAccordion);

// These are three different food hubs with all their information.
const legendDescriptions = [
  {
    locationTitle: "Distribution Location",
    locationIcon:
      "https://lh3.googleusercontent.com/pw/AL9nZEUljxHnDQdsxOw2TA_Wzt2ElhM1474eE7aPi0p4AmcFRrbqZ-drCWtYiA5KqD0YaaU5YCg1Wdg9sqAz59Bf2U3dqGAFvnsoC5CiVZ9IpkiyhmxqKSGpjZ4vuB21Fs3lt8VnINRf50fCnGxt2GtSSuNt=s732-no?authuser=1",
    locationDescription:
      "The Distribution Location is where all the food is delivered from our farmers and vendors.  Here the food is sorted, weighed and packaged to be distributed out to all of the community locations.  This location is free to pick up from because it is the only location where food doesn't need to be transported.  This site may also have storage capacity to store items that are shelf stable.  This site has open access so there is no approval required.",
  },
  {
    locationTitle: "Public Community Hub",
    locationIcon:
      "https://lh3.googleusercontent.com/pw/ACtC-3e_PLev5mqx2dUnS4dZ-qCMzRigr-IjKPmbJP6sITCtoWw16UroJgV6tH_PvGHK4Ff_w0dXLJChQvWB1OWp_mFMAPKAOvZ2URz5ZXn7GHV1RsQ6Qe4lzzA8L0zC-FZxT_3_0wpRMseKkjWuWlG2SeSl=w237-h244-no?authuser=2",
    locationDescription:
      "Public Community Hubs are spaces that are accessible to everyone without requiring approval. Their purpose is to provide more access beyond the distribution locations. These locations are normally public spaces, such as, neighbourhood houses, businesses, community centres, etc.  They require a community member or an organisation to help manage the space. A delivery fee is charged to cover the cost of delivery and is divided equally amongst all community members using this space.  If you know of a space that would be a good fit and you would like to become a community host please sign up above!",
  },
  {
    locationTitle: "Private Community Hub",
    locationIcon:
      "https://lh3.googleusercontent.com/pw/ACtC-3f98F9NH8MxMg3EQvamd0DU0hOH4YLKfNkprRp_sfAREyKh7QAhdDepl6s2pD6_4FtFuZNwUjuLlAObxdeasbJSjSnnhHdhAKPXFGu_C5CfKUMhKIb3zcquktVP9off3e9s9wziJrcRpZeNNFqaUg4e=w240-h239-no?authuser=2",
    locationDescription:
      "Private Community Hubs are spaces that require approval from the community host. Their purpose is to bring food deliveries within a few blocks of community members. These locations are private spaces that community members have offered to share with the community, such as, front and back yards, porches, apartment lobbies, etc.  If you would like the convenience of having your food delivered directly to you and you would like to engage with your neighbours more then becoming a community host might be for you! A delivery fee is charged to cover the cost of delivery and is divided equally amongst all community members using this space.",
  },
];

export default function DeliveryMapLegend() {
  return (
    <Grid container spacing={3}>
      {legendDescriptions.map((locationType) => (
        <Grid item xs key={locationType}>
          <Accordion className="Legend-Accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "5px", width: "50px", height: "50px" }}
                  src={locationType.locationIcon}
                  alt={locationType.locationTitle}
                />
                <Typography style={{ color: "#000" }}>
                  {locationType.locationTitle}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: "#000" }}>
                {locationType.locationDescription}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
}
