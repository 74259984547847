import React from "react";

// This is used to display the total amount owed to each farm.  It is called for
// each total that exists and will print out the "FarmName" : "Total".  It will
// also check the minimumOrder of the farm and if it has been reached.  If not
// then it will display the total in red indicating it's insuffienient.
export default function FarmTotalLine(
  farmTotals,
  farmTotal,
  farmerInfo,
  userType,
) {
  // farmTotal is a dictionary that has the "farmName" : "$$$" so first you have
  // to find the info on the farm currently being used
  const index = farmerInfo.findIndex((farm) => {
    return farm.farmName === farmTotal;
  });
  if (index === -1) {
    return;
  }
  // Assign the minimumOrder of this farm to a variable
  const minimumOrder = farmerInfo[index].minimumOrder;

  return (
    <div key={farmerInfo[index].farmName + index}>
      {farmTotals[farmTotal] < minimumOrder && userType !== "individual" ? (
        <p style={{ color: "red" }}>
          <b>Paid to {farmTotal} </b>: ${farmTotals[farmTotal].toFixed(2)}{" "}
        </p>
      ) : (
        <p>
          <b>Paid to {farmTotal} </b>: ${farmTotals[farmTotal].toFixed(2)}{" "}
        </p>
      )}
    </div>
  );
}
