// The popup for a distribution location on the distribution location selection screen.
import React from "react";
import { Link } from "react-router-dom";
import ColourButton from "../../../../components/userInputs/ColourButton.js";

export default function DistributionLocationPopUp({
  activeIcon,
  previousPage,
  handleClickOpen,
}) {
  return (
    <div>
      <h2>{activeIcon.name}</h2>
      <p>
        <strong>Address:</strong> {activeIcon.address}
      </p>
      <p>
        <strong>Pickup Day:</strong> {activeIcon.pickupDay}
      </p>
      <p>
        <strong>Pickup Time:</strong> {activeIcon.pickupTime}
      </p>
      {/* NEEDS WORK
        <p><strong>Order Cutoff:</strong> {activeIcon.orderCutoff} at {activeIcon.orderCutoffTime}</p>
      */}
      <p>
        <strong>Order Cutoff:</strong> {activeIcon.orderCutoff}
      </p>

      <Link
        style={{ textDecoration: "none" }}
        to={{ pathname: previousPage, query: activeIcon }}
      >
        <ColourButton
          hoverColour="#1c8c1c"
          colour="#9FBF59"
          title="SELECT"
          onClick={() => {
            handleClickOpen(activeIcon);
          }}
        />
      </Link>
    </div>
  );
}
