// This is used to display the row on the basket page of a foodItem that was added
// by the community orders.
import React from "react";
import firebase from "../../../../components/Firebase.js";
import IncrCounter from "../../../../components/userInputs/IncrementalCounter.js";
import { Button } from "@material-ui/core";

// This is to display all the community ordered items for the distribution location
// It will render the accumulated individual orders of the community and display
// those values in light blue and then show the distribution quanities that the
// distribution location has to buy from the farm.
export default class CommunityBasketFoodRow extends React.Component {
  constructor(props) {
    super(props);
    this.database = firebase.firestore();
  }

  // Removes the basketRow Item and sets the quantity to 0 by updating the database
  // It will also call handleRefresh and remove the farm from the list of providing
  // farms
  handleRemove = () => {
    const itemToBeRemoved = (
      this.props.row.item +
      this.props.row.description +
      this.props.row.farmName
    ).replace(/\s+/g, "");

    this.props.communityFoodRemove(itemToBeRemoved);

    console.log("Removed from Basket", itemToBeRemoved);
  };

  render() {
    const row = this.props.row;
    // The price of the item for a distribution location.
    let price = 0;
    // The price for an individual customer.
    let individualPrice = 0;
    // The description of the quantity for the distribution location.
    let description = "";
    // The description of the individual quantity.
    let individualDescription = "";

    // This is the amount of an item ordered from the farm in the distribution amounts
    let distributionQuantity = 0;

    // This is the multiplier of indivdiual distribution amounts that is leftover
    // food that is distributed by the distribution location.
    let distributedFood = 0;

    // This will happen for orders that were before the change to the database to
    // include the individual and distribution quantities in one order
    if (row.distributionQuantity === undefined) {
      price = parseFloat(row.price).toFixed(2);
      description = row.description;
      distributionQuantity = 0;
    } else {
      // This is saved as the distribution location's price
      price = parseFloat(row.price).toFixed(2);

      // This is saved as the individual's price
      individualPrice = parseFloat(
        row.price / (row.distributionQuantity / row.individualQuantity),
      ).toFixed(2);

      // This is saved as the individual's quantity
      individualDescription = row.individualDescription;

      // This is saved as the distribution location's quantity
      description = row.description;

      // if suggestedPrice is true then we want to set the price to 0
      if (row.suggestedPrice) {
        // First add to the description the suggested Donation.
        description = description.concat(" - Suggested Donation of $", price);
        // Add to the individual description the suggested Donation.
        individualDescription = individualDescription.concat(
          " - Suggested Donation of $",
          individualPrice,
        );
        // Then reset the prices to 0.
        price = "0.00";
        individualPrice = "0.00";
      }
      // Checks if there is a minimum quantity needed for this item.  If there is then we need
      // to meet that condition.
      if (parseInt(row.minimumPurchase) > 1) {
        // If the order amount is less than the minimum purchase then we need set the distribution
        // quantity to the minimum purchase.
        if (row.individualQuantity < parseInt(row.minimumPurchase)) {
          distributionQuantity = parseInt(row.minimumPurchase);
        }
        // Otherwise the distribution quantity will just be the individual quantity.
        else {
          distributionQuantity = parseInt(row.individualQuantity);
        }
      }
      // If there is no minimum quantity then we have to still check if the farm sells the product
      // in a certain amount.
      else {
        // This is saved as the quantity needed for the distribution location from the
        // farmer in the bulk quantities
        distributionQuantity = parseInt(
          Math.ceil(
            (row.quantity * row.individualQuantity) / row.distributionQuantity,
          ),
        );
      }

      // This is used to display how much food is left over
      distributedFood = parseFloat(
        (distributionQuantity * row.distributionQuantity -
          row.quantity * row.individualQuantity) /
          row.individualQuantity,
      );

      // This fixes the number of decimals to 2 in case the number is not an integer
      if (distributedFood - parseInt(distributedFood) > 0) {
        distributedFood = distributedFood.toFixed(2);
      } else if (distributedFood < 0) {
        distributedFood = 0;
      }
    }

    return (
      <div id={row.item + "-" + this.props.idx} className="Food-Table">
        <div className="Basket-Cell">{row.item}</div>
        <div className="Basket-Cell">{individualDescription}</div>
        <div className="Basket-Cell">{row.farmName}</div>
        <div className="Basket-Cell">${individualPrice}</div>
        <div className="Basket-Cell">
          <IncrCounter
            quantity={row.quantity}
            incrementItem={this.incrementItem}
            decreaseItem={this.decreaseItem}
            hideButtons={true}
          />
        </div>
        <div className="Basket-Cell">
          <Button onClick={this.handleRemove} color="secondary">
            Remove
          </Button>
        </div>
        <div style={{ background: "#47DCFF" }} className="Basket-Cell">
          {row.item}
        </div>
        <div style={{ background: "#47DCFF" }} className="Basket-Cell">
          {description}
        </div>
        <div style={{ background: "#47DCFF" }} className="Basket-Cell">
          {row.farmName}
        </div>
        <div style={{ background: "#47DCFF" }} className="Basket-Cell">
          ${parseFloat(price).toFixed(2)}
        </div>
        <div style={{ background: "#47DCFF" }} className="Basket-Cell">
          <IncrCounter
            quantity={distributionQuantity}
            incrementItem={this.incrementItem}
            decreaseItem={this.decreaseItem}
            hideButtons={true}
          />
        </div>
        <div style={{ background: "#47DCFF" }} className="Basket-Cell">
          <span style={{ margin: "5px" }}>
            <p style={{ margin: "0px" }}>Added to Pantry</p>
            <p style={{ margin: "0px" }}>
              {row.individualDescription} x {distributedFood}
            </p>
          </span>
        </div>
      </div>
    );
  }
}
