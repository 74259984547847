// This the counter for the marketplace that allows users to add to their basket.
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Subtract an element from the counter.
function MinusButton({ decreaseItem }) {
  return (
    <div className="Minus-Button">
      <IconButton onClick={decreaseItem}>
        <RemoveCircleOutlineIcon fontSize="small" style={{ color: "white" }} />
      </IconButton>
    </div>
  );
}

// Add an element from the counter.
function PlusButton({ incrementItem }) {
  return (
    <div className="Plus-Button">
      <IconButton onClick={incrementItem}>
        <AddCircleOutlineOutlinedIcon
          fontSize="small"
          style={{ color: "white" }}
        />
      </IconButton>
    </div>
  );
}

export default function MarketplaceIncrCounter({
  quantity,
  incrementItem,
  decreaseItem,
  hideButtons,
}) {
  return (
    <div className="Incremental-Counter">
      {!hideButtons && <MinusButton decreaseItem={decreaseItem} />}
      <p className="Increment-Number">{quantity}</p>
      {!hideButtons && <PlusButton incrementItem={incrementItem} />}
    </div>
  );
}
