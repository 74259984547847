// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
import "./../../App.css";
import "./../../styles/LikeCounter.css";
// eslint-disable-next-line no-unused-vars
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// eslint-disable-next-line no-unused-vars
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
// eslint-disable-next-line no-unused-vars
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
// eslint-disable-next-line no-unused-vars
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import { AuthContext } from "./../authentication/Auth.js";

export default function LikeCounter(props) {
  const { currentUser, userInfo } = useContext(AuthContext);

  // This checks if the user has liked this issue at all.  If they have then it
  // will add a subscript and change the outline of the like.
  let userVotes = -1;
  if (userInfo.votedIssues != null) {
    userVotes = userInfo.votedIssues.findIndex((item) => {
      return item.title === props.title;
    });
  }
  return (
    <div className="Flex-Row-Stretch">
      <div className="Like-Num">
        {currentUser ? (
          <div className="Up-Down-Vote">
            <button
              onClick={() => {
                props.handleVoteChange(props.title, props.votesUsed, true);
              }}
              className="Like-Button"
            >
              {userVotes !== -1 ? (
                <ThumbUpIcon
                  style={{ color: "green" }}
                  fontSize="small"
                  color="primary"
                />
              ) : (
                <ThumbUpOutlinedIcon
                  style={{ color: "green" }}
                  fontSize="small"
                  color="primary"
                />
              )}
            </button>
            <button
              onClick={() => {
                props.handleVoteChange(props.title, props.votesUsed);
              }}
              className="Like-Button"
            >
              {userVotes !== -1 ? (
                <ThumbDownOutlinedIcon
                  style={{ color: "red" }}
                  fontSize="small"
                  color="primary"
                />
              ) : (
                <ThumbDownIcon
                  style={{ color: "red" }}
                  fontSize="small"
                  color="primary"
                />
              )}
            </button>
          </div>
        ) : (
          <ThumbUpIcon
            style={{ color: "green", margin: "5px" }}
            fontSize="small"
            color="primary"
          />
        )}
        <p style={{ margin: "0px" }}>{props.numOfLikes}</p>
      </div>
      {userVotes !== -1 && !!currentUser && (
        <sup className="Personal-Vote-Super-Script">
          +{userInfo.votedIssues[userVotes].votes}
        </sup>
      )}
    </div>
  );
}
