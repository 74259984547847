// This happens when a new item has been added or removed from the marketplace
// list.  The fourth props tells this whether it was an addition or a removal.
// If it's an addition it first checks if the item exists already and then
// adds it.  If it is a removal it removes it from the list.
export default function HandleRefresh(
  foodItems,
  setFoodItems,
  item,
  add,
  update,
  index,
) {
  // Sets a temp array of the all the foodItems
  const arrFood = [...foodItems];
  // If the user has added a foodItem
  if (add) {
    // checks if the foodItem already exists if it doesn't then add it.
    if (
      !arrFood.some((e) => {
        return (
          e.description === item.description &&
          e.farmName === item.farmName &&
          e.item === item.item
        );
      })
    ) {
      // Adds the foodItem.
      arrFood.push(item);
      // Gives the foodItem a quantity field with a value of 0
      arrFood[arrFood.length - 1].quantity = 0;
      setFoodItems(arrFood);
    }
  }
  // Checks if the user is just updating an item instead of removing or adding one.
  else if (update) {
    // Updates the item.
    arrFood[index] = item;
    // Gives the foodItem a quantity field with a value of 0
    arrFood[index].quantity = 0;
    setFoodItems(arrFood);
  }
  // The user removed an item
  else {
    // Finds the index of the removed item.
    const index1 = arrFood.findIndex(
      (element) =>
        item ===
        (element.item + element.description + element.farmName).replace(
          /\s+/g,
          "",
        ),
    );
    // If the item exists than remove it and update the foodItems hook.
    if (index1 !== -1) {
      arrFood.splice(index1, 1);
      setFoodItems(arrFood);
    }
  }

  return arrFood;
}
