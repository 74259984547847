// Takes in  the community orders of a location and then cycles through them combining
// any orders that a user has placed into one order.
// This function compares the names of the users and sorts them alphabetically.
function SortOrdersAlphabetically(user1, user2) {
  const userName1 = user1.toUpperCase();
  const userName2 = user2.toUpperCase();

  if (userName1 < userName2) {
    return -1;
  }
  if (userName1 > userName2) {
    return 1;
  }
  return 0;
}

// This function takes community orders which is a dictionary of each order placed
// for this distribution location as the argument.  It combines multiple
// orders that were placed by the same user and creates a new dictionary that
// copies the data from the community orders and adds a foodDict and farmDict for
// easier use when trying to print out the message.
export default function OrganizeCommunityOrders(communityOrders) {
  // Takes the order key "firstName-lastName-userId-date" and breaks it into an
  // array [firstName, lastName, userId, date]
  let orderInfo = "";
  // The modified community order dictionary
  const communityOrdersDict = {};

  if (communityOrders === undefined) {
    return [null, []];
  }

  // This array is used to order the users alphabetically.
  const usersOrdersArray = [];
  // Sort through community orders and add each user to an array.
  Object.keys(communityOrders).forEach((user) => {
    usersOrdersArray.push(user);
  });

  // Sort the userOrders by alphabetically.
  usersOrdersArray.sort((user1, user2) => {
    return SortOrdersAlphabetically(user1, user2);
  });

  // Cycles through all the orders.
  usersOrdersArray.forEach((user) => {
    // splits the order key into an array
    orderInfo = user.split("-");
    // creates the new order key which is the "firstName-lastName-userId".
    const orderKey = orderInfo[0] + "-" + orderInfo[1] + "-" + orderInfo[2];
    // Checks to see if the orderKey has already been created which means the user
    // has placed an order for this date already.  If it has then we have to combine
    // it with the new order.
    if (orderKey in communityOrdersDict) {
      // Checks if the past order was a donation or a subsidy.
      if (communityOrdersDict[orderKey].customerContribution === "donation") {
        // Checks if the current order is a donation or a subsidy
        if (communityOrders[user].customerContribution === "donation") {
          // Sums up the the past donation with the new donation
          communityOrdersDict[orderKey].donationSubsidy +=
            communityOrders[user].donationSubsidy;
        } else {
          // Sums up the the past donation with the new subsidy
          communityOrdersDict[orderKey].donationSubsidy -=
            communityOrders[user].donationSubsidy;
        }
      } else {
        // Checks if the current order was a donation or a subsidy.
        if (communityOrders[user].customerContribution === "donation") {
          // Subtracts the previous user's subsidy from their current donation.
          // The result will be positive or negative.
          communityOrdersDict[orderKey].donationSubsidy =
            communityOrders[user].donationSubsidy -
            communityOrdersDict[orderKey].donationSubsidy;
        } else {
          // If the previous and current orders are subsidies then we can just add
          // them together and then make them negative number.
          communityOrdersDict[orderKey].donationSubsidy +=
            communityOrders[user].donationSubsidy;
          communityOrdersDict[orderKey].donationSubsidy =
            communityOrdersDict[orderKey].donationSubsidy * -1;
        }
      }
      // Check to see if the number is negative.  If it is then the user has overall
      // been given a subsidy.  Otherwise they're giving a donation.
      if (communityOrdersDict[orderKey].donationSubsidy < 0) {
        communityOrdersDict[orderKey].donationSubsidy =
          communityOrdersDict[orderKey].donationSubsidy * -1;
        communityOrdersDict[orderKey].customerContribution = "subsidy";
      } else {
        communityOrdersDict[orderKey].customerContribution = "donation";
      }

      // Add the delivery fee to the order if there is one.  If the delivery fee isn't
      // defined then the deliveryFee will just be whatever the new order has it as and overwrite it.
      if (
        communityOrdersDict[orderKey].deliveryFee !== undefined &&
        communityOrders[user].deliveryFee !== undefined
      ) {
        communityOrdersDict[orderKey].deliveryFee +=
          communityOrders[user].deliveryFee;
      }
      // If it hasn't been added yet that means there are no other orders that have
      // added a delivery fee and so it will just be the delivery fee.
      else if (communityOrders[user].deliveryFee !== undefined) {
        communityOrdersDict[orderKey].deliveryFee =
          communityOrders[user].deliveryFee;
      }

      // Checks to make sure both the old and new cashPortions are defined otherwise
      // it will just be whatever it is and be overwritten by the newest one.
      if (
        communityOrdersDict[orderKey].cashPortion !== undefined &&
        communityOrders[user].cashPortion !== undefined
      ) {
        communityOrdersDict[orderKey].cashPortion = (
          parseFloat(communityOrdersDict[orderKey].cashPortion) +
          parseFloat(communityOrders[user].cashPortion)
        ).toFixed(2);
      } else if (communityOrders[user].cashPortion !== undefined) {
        communityOrdersDict[orderKey].cashPortion =
          communityOrders[user].cashPortion;
      }

      // Checks to make sure both the old and new creditPortions are defined otherwise
      // it will just be whatever it is and be overwritten by the newest one.
      if (
        communityOrdersDict[orderKey].creditPortion !== undefined &&
        communityOrders[user].creditPortion !== undefined
      ) {
        communityOrdersDict[orderKey].creditPortion = (
          parseFloat(communityOrdersDict[orderKey].creditPortion) +
          parseFloat(communityOrders[user].creditPortion)
        ).toFixed(2);
      } else if (communityOrders[user].creditPortion !== undefined) {
        communityOrdersDict[orderKey].creditPortion =
          communityOrders[user].creditPortion;
      }

      // Add their farms to a Dictionary from the current array of farms
      communityOrders[user].farmerList.forEach((farm) => {
        communityOrdersDict[orderKey].farmDict[farm.farmName] = { ...farm };
      });

      // Copy the food items to a dictionary from the array and combine any items
      // that already included in a previous order.
      communityOrders[user].foodList.forEach((food) => {
        if (
          food.item + food.description + food.farmName in
          communityOrdersDict[orderKey].foodDict
        ) {
          communityOrdersDict[orderKey].foodDict[
            food.item + food.description + food.farmName
          ].quantity += food.quantity;
        } else {
          communityOrdersDict[orderKey].foodDict[
            food.item + food.description + food.farmName
          ] = { ...food };
        }
      });

      // If this hasn't place any orders for this pickup day yet...
    } else {
      // Add the order to the communityOrders dictionary
      communityOrdersDict[orderKey] = { ...communityOrders[user] };

      // Create a farm Dictionary and then transfer over the farm array to this
      // new dictionary
      communityOrdersDict[orderKey].farmDict = {};
      communityOrders[user].farmerList.forEach((farm) => {
        communityOrdersDict[orderKey].farmDict[farm.farmName] = { ...farm };
      });
      // Create a food dictionary and then transfer over the farm array to this
      // new dictionary
      communityOrdersDict[orderKey].foodDict = {};
      communityOrders[user].foodList.forEach((food) => {
        communityOrdersDict[orderKey].foodDict[
          food.item + food.description + food.farmName
        ] = { ...food };
      });
    }

    // Create the farmerList array that is empty.
    communityOrdersDict[orderKey].farmerList = [];
    // Cycle through the farm dictionary and add these items to the farmerList array
    Object.keys(communityOrdersDict[orderKey].farmDict).forEach((farm) => {
      communityOrdersDict[orderKey].farmerList.push({
        ...communityOrdersDict[orderKey].farmDict[farm],
      });
    });

    // Create the foodList array that is empty.
    communityOrdersDict[orderKey].foodList = [];
    // Cycle through the food dictionary and add these items to the foodList array
    Object.keys(communityOrdersDict[orderKey].foodDict).forEach((food) => {
      communityOrdersDict[orderKey].foodList.push({
        ...communityOrdersDict[orderKey].foodDict[food],
      });
    });
  });

  return communityOrdersDict;
}
