// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
// eslint-disable-next-line no-unused-vars
import { Link, useParams, Redirect } from "react-router-dom";
import "../App.css";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
import "../styles/RestaurantMenu.css";
// eslint-disable-next-line no-unused-vars
import Menu from "../components/Menu.js";
// eslint-disable-next-line no-unused-vars
import SimpleSelect from "../components/userInputs/DropDownMenu.js";
// eslint-disable-next-line no-unused-vars
import MaterialUIPickers from "../components/userInputs/DatePicker.js";
// eslint-disable-next-line no-unused-vars
import MenuItemDialog from "../components/dialogs/Dialog.js";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";

// Creates the dropdown and the calendar selection that is used by the user to choose
// the menu and date of their meal
// eslint-disable-next-line no-unused-vars
function MenuSelection(props) {
  return (
    <div className="Selection-Options">
      <h4 className="Menu-Selection-Title"> Select Menu: </h4>
      <div className="Menu-Selection-Row">
        <div style={{ marginTop: "16px", marginRight: "8px" }}>
          <SimpleSelect
            options={props.options}
            title="Menu"
            handleChange={props.handleChange}
            selected={props.selected}
          />
        </div>
        <MaterialUIPickers
          required
          date={props.date}
          handleDateChange={props.handleDateChange}
          label="Date of Meal"
        />
      </div>
    </div>
  );
}

// Display the menuItem box with the item name, local ingredients and the more details
function MenuItem(
  props,
  restaurantName,
  userId,
  date,
  displayDeliveryDate,
  index,
) {
  const itemName = props.itemName;
  const localIngredients = props.localIngredients.split(", ");
  const photo = props.photo;

  return (
    <div key={props.itemName + index} className="Menu-Item">
      <div className="Menu-Item-Box">
        <div className="Menu-Item-Text">
          <div>
            <h5 className="Item-Name">{itemName}</h5>
            {localIngredients[0] === "" ? (
              <h6 className="No-Local-Ingredients">
                We are currently looking for ways to source locally more and
                include at least one local ingredient in each dish.
              </h6>
            ) : (
              <h6 className="Local-Ingredients">
                Local Ingredients: {localIngredients.join(", ")}
              </h6>
            )}
          </div>
          <div className="Menu-Item-Row">
            {/* <h6 className = "Header-Margin">Local Farms Supported: {props.numOfFarms}</h6> */}
            <MenuItemDialog
              className="Menu-Item-Details-Button"
              itemName={itemName}
              localIngredients={localIngredients}
              restaurantName={restaurantName}
              userId={userId}
              date={date}
              displayDeliveryDate={displayDeliveryDate}
            />
          </div>
        </div>
        {photo !== "" && <img className="Food-Item-Photo" alt="" src={photo} />}
      </div>
    </div>
  );
}

// This renders the Section of the Menu currently being sent in.  The main function
// RestaurantMenu uses a map of the sections for a given menu and calls this function.
// It then uses its own map to create the menuItem box for each menuItem in a section.
function SelectedMenu(
  selectedMenu,
  section,
  menuList,
  restaurantName,
  date,
  index,
) {
  // filters out the menuItems that aren't in this section and menu
  const menuSectList = menuList.menuList.filter(
    (sec) => sec.menuName === selectedMenu && sec.sectionName === section,
  );
  return (
    <div key={"header" + index}>
      {/* Displays the Section header of the selected menu */}
      <h1 className="Section"> {section} </h1>
      <div className="Menu-List">
        {/* Creates each menu item element.  This includes the name of the item, local
            ingredients, local farms supported and the "+more details" button */}
        {menuSectList.map((item, index) =>
          MenuItem(
            item,
            restaurantName,
            menuList.userId,
            date,
            menuList.displayDeliveryDate,
            index,
          ),
        )}
      </div>
    </div>
  );
}

function RestaurantMenu() {
  // Takes the restaurantName from the url of this page.
  const { restaurantName } = useParams();

  // Checks if the user has to create a menu or if one already exists
  const [createMenu, setCreateMenu] = useState(false);

  // Contains all the menuInfo with the headers, menuItems, sections, etc.
  const [menuInfo, setMenuInfo] = useState([{}]);

  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  // Holds what menu is currently selected
  const [selectedMenu, setSelectedMenu] = useState("");

  // The date of the meal selected by the user.
  const [date, setDate] = useState(new Date());

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const database = firebase.firestore();
    const docRef = database.collection("RestaurantMenu").doc(restaurantName);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setMenuInfo(doc.data());
          setSelectedMenu(Object.keys(doc.data().menuHeaders)[0]);
          setLoading(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such Menu document!");
          setCreateMenu(true);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        // setLoading(true)
      });
  }, [restaurantName]);

  // Change the menu selection that the user chooses from the dropdown
  function handleMenuSelection(e) {
    setSelectedMenu(e.target.value);
  }

  // Change the date of the meal that the user selects to view
  function handleDateChange(e) {
    setDate(e);
  }

  // If the menu doesn't exist take the user directly to the edit page
  if (createMenu) {
    return <Redirect to={"/EditMenu/" + restaurantName} />;
  }

  // Checks if the data has been loaded yet
  if (loading) {
    const options = Object.keys(menuInfo.menuHeaders);
    const restaurantName = menuInfo.restaurantName;
    let userId = null;
    if (currentUser) {
      userId = currentUser.uid;
    }
    return (
      <div>
        <Menu />
        <div className="Page-Market">
          <div
            className="Menu-Header-Background-Image"
            style={{
              backgroundImage:
                "linear-gradient(rgba(255,255,255,-0.5), rgba(255,255,255,1.5)), url('" +
                menuInfo.coverPhoto +
                "')",
            }}
          >
            <div className="Menu-Page-Header">
              <div className="centered">
                <div>
                  <h2 className="Restaurant-Title">{restaurantName}</h2>
                  <p className="Restaurant-Info">
                    {menuInfo.restaurantAddress}, {menuInfo.restaurantCity}{" "}
                    {menuInfo.restaurantProvTerr}
                  </p>
                  <p className="Restaurant-Info">tel. {menuInfo.phoneNumber}</p>
                </div>
                <div className="left">
                  <img
                    className="Restaurant-Logo"
                    alt=""
                    src={menuInfo.logoPhoto}
                  />
                </div>
                <div className="right">
                  {userId === menuInfo.userId && (
                    <div>
                      {/*  */}
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/EditMenu/" + menuInfo.restaurantName}
                      >
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                        >
                          Edit Menu
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <MenuSelection
              options={options}
              handleChange={handleMenuSelection}
              selected={selectedMenu}
              date={date}
              handleDateChange={handleDateChange}
            />
          </div>
          {menuInfo.menuHeaders[selectedMenu].map((section, index) =>
            SelectedMenu(
              selectedMenu,
              section,
              menuInfo,
              menuInfo.restaurantName,
              date,
              index,
            ),
          )}
        </div>
      </div>
    );
  } else {
    return <h1>Loading</h1>;
  }
}

export default RestaurantMenu;
