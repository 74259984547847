// This determines the date in the format of MM-DD-YYYY of the next day of the week
// of a given day sent in by the argument.

export default function NextPickupDay(pickupDay) {
  const today = new Date();
  const daysOfTheWeek = {
    Mon: 1,
    Tues: 2,
    Wed: 3,
    Thurs: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };
  let nextDay = today;
  // Detmines the date of the next day
  nextDay = nextDay.setDate(
    today.getDate() + ((daysOfTheWeek[pickupDay] + (7 - today.getDay())) % 7),
  );

  // formats the date into a standard format
  const formattedDate = new Date(nextDay);

  // Creates the YY MM and DD format
  const y = formattedDate.getFullYear();
  const m = formattedDate.getMonth() + 1;
  const d = formattedDate.getDate();

  // String together the MM-DD-YYYY of the next day
  nextDay =
    "" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d + "-" + y;

  return nextDay;
}
