// Calculate all the Basketpage values like total donations, subsidies, grandTotal,
// etc. that is displayed and used on the basket page.
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";

export default function BasketPageCalculations(
  combinedFarmList,
  farmerInfo,
  communityFarmerInfo,
  communityFoodItems,
  combinedFoodList,
  foodItems,
  userInfo,
  customerContribution,
  donationSubsidy,
  communityDonations,
  communitySubsidies,
  communityAndImportedOrder,
  pantryFoodItems,
  currentStatus,
  cashPortion,
) {
  // Create a farmList that is combined with the community orders and the
  // user's order
  combinedFarmList = [...farmerInfo];

  // This is the starting delivery fee and the base amount.
  let baseDeliveryFee = 0.0;

  // If the pickuplocation exists.
  if (userInfo.pickupLocation !== undefined) {
    // If the user is using a community hub then the delivery fee will apply and
    // we need to update the base fee.
    if (userInfo.pickupLocation.pickupLocation !== undefined) {
      baseDeliveryFee = userInfo.pickupLocation.pickupLocation.baseDeliveryFee;
      // If the community delivery fee is defined or equal to 0 then update the base delivery
      // fee.
      if (
        userInfo.pickupLocation.communityDeliveryFee ||
        userInfo.pickupLocation.communityDeliveryFee === 0
      ) {
        baseDeliveryFee = userInfo.pickupLocation.communityDeliveryFee;
      }
    }
  }

  // Cycle through the community farm list and then check to see if that farm
  // was already added to the combined farmer list.  If not then add it.
  communityFarmerInfo.forEach((communityFarm) => {
    const result = combinedFarmList.filter(
      (farm) => farm.farmName === communityFarm.farmName,
    );
    if (result.length === 0) {
      combinedFarmList.push(communityFarm);
    }
  });

  // Add all the community foodItems to the combined food list.
  communityFoodItems.forEach((communityFoodItem) => {
    const communityFoodItemTemp = { ...communityFoodItem };
    communityFoodItemTemp.quantity = parseInt(
      Math.ceil(
        (communityFoodItem.quantity * communityFoodItem.individualQuantity) /
          communityFoodItem.distributionQuantity,
      ),
    );
    combinedFoodList.push(communityFoodItemTemp);
  });

  // Add the user's foodItems to the combined food list.  If they already exist
  // then just update the quantity.
  foodItems.forEach((food) => {
    const index = combinedFoodList.findIndex(
      (communityFood) =>
        communityFood.item === food.item &&
        communityFood.description === food.description &&
        communityFood.farmName === food.farmName,
    );
    if (index !== -1) {
      combinedFoodList[index].quantity += food.quantity;
    } else {
      combinedFoodList.push({ ...food });
    }
  });

  // The totals owed to each farm of the complete order.  This will be a dictionary
  // once it is created.
  let farmTotals = 0;

  // The total packaging owed by each each farm of the complete order.  This will be a dictionary
  // once it is created.
  let packageTotals = 0;

  // The totals taken from the pantry
  let pantryTotals = 0;
  // These are the fees that are suggested that will be used to calculate the volunteer
  // fee.
  let suggestedFees = 0;

  // If the user is an individual then send true to PriceTotals so that it
  // calculates the individual prices otherwise use the normal price
  if (userInfo.userType === "individual") {
    [farmTotals, suggestedFees] = PriceTotals(combinedFoodList, true, true);
    packageTotals = PackageTotals(combinedFoodList);
  } else {
    farmTotals = PriceTotals(combinedFoodList, false);
    // Since the pantry uses the individual values to calculate the prices we
    // set the second parameter to true.
    pantryTotals = PriceTotals(pantryFoodItems, true);
  }
  // cost of all the food items added together for the order's cost
  let costTotal = 0;

  // The total amount of all the packaging fees.
  let packageFeeTotal = 0;

  // This is the total amount that the user will pay for the delivery.  This will
  // be divided by all users if there are others.
  let deliveryFee = baseDeliveryFee;

  // cost of all the pantry food items added together for the order's cost
  let pantryCostTotal = 0;
  // grandTotal is the costTotal plus the donation/Subsidy amount
  let grandTotal = 0;
  let dollarColour = "green";
  let textFieldColour = "primary";
  let netAmountTextFieldColour = "green";
  // Cycle through the farm totals and add them all up.
  Object.keys(farmTotals).forEach((farmTotal) => {
    costTotal += farmTotals[farmTotal];
  });

  // Cycle through the pantry vendor totals and add them all up.
  Object.keys(pantryTotals).forEach((vendorTotal) => {
    pantryCostTotal += pantryTotals[vendorTotal];
  });

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
  });

  // If the number of people splitting is greater than 0 and the user
  // is picking up from a community hub then we have to calculate the
  // cost of the delivery fee.
  if (currentStatus[1] > 0 && !!userInfo.pickupLocation.pickupLocation) {
    let numberOfSplitting = currentStatus[1];
    // If the current user isn't already ordering we can include them in
    // the splitting as well.  Otherwise they've already paid the delivery fee.
    if (!currentStatus[2]) {
      // Add the user to the number of people splitting.
      numberOfSplitting += 1;
      // Update the delivery fee.
      deliveryFee = deliveryFee / numberOfSplitting;
    }
    // Otherwise the delivery fee will be 0 as the user has already paid their portion
    // of the delivery fee in a previous order.
    else {
      deliveryFee = 0.0;
    }
  }

  // Calculate the donations/subsidies if the user is an individual and adds to their
  // total cost.
  if (userInfo.userType !== "distributionLocation") {
    if (customerContribution === "donation") {
      grandTotal = costTotal + donationSubsidy;
    } else {
      grandTotal = costTotal - donationSubsidy;
      dollarColour = "red";
      textFieldColour = "secondary";
    }
    grandTotal += packageFeeTotal;

    // If the pickuplocation exists.
    if (userInfo.pickupLocation !== undefined) {
      // If the user is picking up from a pickup location then add the delivery fee in.
      if (userInfo.pickupLocation.pickupLocation) {
        grandTotal += deliveryFee;
      }
    }

    // Add the volunteer fee cash portion to the grandTotal
    if (cashPortion > 0) {
      grandTotal += parseFloat(cashPortion);
    }
  }
  // if the user is a distributionLocation then they just need to simply see
  // if the net amount is postive or negative
  else {
    if (communityDonations - communitySubsidies > 0) {
      netAmountTextFieldColour = "green";
    } else {
      netAmountTextFieldColour = "red";
    }
    grandTotal = costTotal;

    // Add up the cost of all the orders of the preorders so that you can see
    // how much food was preordered and is accounted for.  The remaining food
    // is then donated.
    // eslint-disable-next-line no-var
    var communityOrdersTotal = 0;
    // Cycle through each individual order
    Object.keys(communityAndImportedOrder).forEach((order) => {
      // This will store the amount each individual pays to each farm
      let orderTotals = {};
      // This will store the amount each individual pays to each farm
      orderTotals = PriceTotals(
        communityAndImportedOrder[order].foodList,
        true,
      );
      // Cycle through the amounts that the individual owes to the farm and
      // add each value to the overall community orders total
      Object.keys(orderTotals).forEach((farm) => {
        communityOrdersTotal += orderTotals[farm];
      });
    });
    // This is the value of all the community orders
    communityOrdersTotal = parseFloat(communityOrdersTotal).toFixed(2);
    // This is the total amount of food that is donated and is the total amount of food
    // minus the community orders + the amount of food that was taken from the pantry.
    // eslint-disable-next-line no-var
    var donationAmount = parseFloat(
      grandTotal - communityOrdersTotal + pantryCostTotal,
    ).toFixed(2);
  }

  // If the grand total is less than 0 make it 0 since we don't want any negative
  // grandTotals.
  if (grandTotal < 0) {
    grandTotal = 0;
  }

  return [
    combinedFarmList,
    combinedFoodList,
    farmTotals,
    costTotal,
    pantryTotals,
    pantryCostTotal,
    grandTotal,
    dollarColour,
    textFieldColour,
    netAmountTextFieldColour,
    communityOrdersTotal,
    donationAmount,
    packageTotals,
    deliveryFee,
    suggestedFees,
  ];
}
