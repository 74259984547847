// This function return the date in the format yyyymmdd and time since in milliseconds
// since 197-something.  Dashes is either true or false and returns with the format YYYY-MM-DD
export default function YYYYMMDDConverter(date, dashes) {
  let now = new Date();
  if (date) {
    now = new Date(date);
  }
  const y = now.getFullYear();
  const m = now.getMonth() + 1;
  const d = now.getDate();
  // If true then add dashes between the YYYY-MM-DDs
  if (dashes) {
    return (
      "" + y + "-" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d
    );
  } else {
    return "" + y + (m < 10 ? "0" : "") + m + (d < 10 ? "0" : "") + d;
  }
}
