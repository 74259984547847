// This a function that will update the visiblity of an item depending on the user
// type.  It will hide it from customers if the distribution location chooses not
// to include it or the admin user can hide it from all locations and distribution locations.
import firebase from "./../../../components/Firebase.js";

export default function UpdateVisibility(
  userInfo,
  row,
  checked,
  foodItems,
  setFoodItems,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  // This is the name of the item in the database.
  const documentName = (row.item + row.description + row.farmName).replace(
    /\s+/g,
    "",
  );
  const docRef = database.collection("FoodItems").doc(documentName);

  const rowTemp = { ...row };
  const foodItemsTemp = [...foodItems];

  const index = foodItems.findIndex((foodItem) => {
    return (
      row.item === foodItem.item &&
      row.description === foodItem.description &&
      row.farmName === foodItem.farmName
    );
  });

  // If the user is an admin user.
  if (userInfo.userType === "admin") {
    // Update the database to include the
    batch.update(docRef, { adminVisible: checked });
    // Udpdate the row temp to rerender the page for the user.
    rowTemp.adminVisible = checked;
  }
  // If the user is a distribution location.
  else {
    // Makes a temporary distribution location array that holds all the distribution
    // location's that can show this item.
    const distributionLocationVisibleTemp = [
      ...rowTemp.distributionLocationVisible,
    ];
    // Checks to see if the foodItem is being set to visible
    if (checked) {
      // Adds this distribution location to the array to allow their users to
      // view this food Item.
      distributionLocationVisibleTemp.push(userInfo.userId);
    }
    // If the user is removing this item from their distribution location.
    else {
      // Find the index of the distribution location's userId in the array.
      const indexToRemove = distributionLocationVisibleTemp.indexOf(
        userInfo.userId,
      );
      // Remove the distribution location's name from the array.
      distributionLocationVisibleTemp.splice(indexToRemove, 1);
    }
    // Updates the database to include the changes.
    batch.update(docRef, {
      distributionLocationVisible: [...distributionLocationVisibleTemp],
    });
    // Updates the row to rerender with the changes.
    rowTemp.distributionLocationVisible = distributionLocationVisibleTemp;
  }
  // Commits the changes to the database
  batch.commit();

  // Updates the temp displayedFoodItems
  foodItemsTemp[index] = { ...rowTemp };
  // Sets the hook to include the changes.
  setFoodItems([...foodItemsTemp]);

  // When a user is updating multiple foodItems at once then it needs to return
  // this instead so that the hook can be updated in one go instead of each time
  // the forloop runs.  It can't update the hooks fast enough and overwrites them.
  return foodItemsTemp;
}
