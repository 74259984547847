// Section of the homepage that gives a brief overview of the program
// to the user.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function OverviewSection() {
  return (
    <div className="w3-padding-64" id="about">
      <div
        className="Supporting-Organisations-Div"
        id="supportingOrganisations"
      >
        <h1 className="Supporting-Organisations-Header">
          Redesigning Our Food Chain
        </h1>
        <p className="Supporting-Organisations-Text">
          Empowering our community to order, receive, and distribute food
          directly from local producers.
        </p>
      </div>
      <div className="Icons-Program-Benefits">
        <div className="image-container" style={{ width: "50%" }}>
          <img
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczNJW3OG3FMljUdJnhpP2ZjZzAIZu82AMtLqpZMoG1qZqeWSO8pPrDGKJCLqmPvar6TUuXO3JKSKKNdTC8EeBa3rnpv8yRkmOdt5T7lQZrWKhRe8TcQgQtLnJsB5Al2ed06ybQR0_BXM9_gXE5osopIm=w555-h453-s-no-gm?authuser=1"
            style={{ width: "100%" }}
          />
        </div>
        <div className="image-container" style={{ width: "50%" }}>
          <img
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczM-Q5tnymP-1T4NvK9A0Kh7EJPga39gJHYHrc9Yyd_t2NGVE8v1VWKB9GnobmmhqQ_tod2JngInWfHOtqIJgEDm7oIQPyVr3fnrXf89ADmu7fAk2Bo9CNL-28-LOmY4F4NMixxWOzkU02VpCGgLStk3=w491-h444-s-no-gm?authuser=1"
            style={{ width: "calc(100% - 30px)", marginRight: "30px" }}
          />
        </div>
        <div className="image-container" style={{ width: "50%" }}>
          <img
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczMyYV6oKqDioJ00BTSFz9JwcC6FjqOVGfK6TqztiZsMDcJeLz2dm-e1910FSKriLF4puuC2itAMw85vCV7MaUDagy6XEzS751igABFlqV_KVEWeaplMG2Sx7yhFQbPV-PsEItWu4IjOW5TMH1QXH4CR=w495-h421-s-no-gm?authuser=1"
            style={{ width: "90%" }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link style={{ textDecoration: "none" }} to="/AboutUs">
          <Button
            style={{ background: "#45AA45", color: "white" }}
            size="large"
            variant="contained"
          >
            LEARN MORE
          </Button>
        </Link>
      </div>
    </div>
  );
}
