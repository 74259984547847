// This dialog appears when an individual user is trying to enter the credit code
// to redeem their credits.
import React, { useState, useEffect } from "react";
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AddUserCredits from "../../Functions/VolunteerSchedule/AddUserCredits.js";

// This is the dialog box that opens when a user clicks the add button
function EnterIndividualCreditCodeBox({
  open,
  onClose,
  volunteerScheduleTemp,
  setVolunteerSchedule,
  volunteerScheduleQuarterTemp,
  indexOfQuarter,
  date,
  position,
  shift,
  userInfo,
  handleUserInfoChange,
}) {
  // The credit code entered by the user.
  const [creditCode, setCreditCode] = useState("");
  // Show the alert in case the user entered an invalid code.
  const [showAlert, setShowAlert] = useState(false);

  const database = firebase.firestore();

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = (cancel) => {
    onClose(false);
  };

  // This is when the user saves to the database.
  const handleSave = () => {
    // If the user code matches the one for this shift then we can add the credits.
    if (
      creditCode === volunteerScheduleQuarterTemp[date][position][shift].code
    ) {
      // Add the current user to the entered codes list so they can't redeem the code
      // multiple times.
      volunteerScheduleQuarterTemp[date][position][shift].enteredCodes.push(
        userInfo.userId,
      );
      // Sets the Users update
      const batch = database.batch();
      // update the volunteer schedule.
      const volunteerScheduleDocRef = database
        .collection("VolunteerSchedule")
        .doc(volunteerScheduleQuarterTemp.timePeriod);
      // commits and updates the database.
      batch.update(volunteerScheduleDocRef, {
        ...volunteerScheduleQuarterTemp,
      });

      batch.commit();
      // Add the credits to the user's profile
      AddUserCredits(
        volunteerScheduleQuarterTemp[date][position].creditValue,
        userInfo,
        handleUserInfoChange,
      );
      // Update the volunteer schedule to include the updates.
      volunteerScheduleTemp[indexOfQuarter] = {
        ...volunteerScheduleQuarterTemp,
      };
      setVolunteerSchedule([...volunteerScheduleTemp]);
      setCreditCode("");
      onClose(false);
    } else {
      setShowAlert(true);
      setCreditCode("");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Enter the Credit Code Provided
      </DialogTitle>
      <DialogContent>
        {showAlert && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            The code you entered was incorrect —{" "}
            <strong>please try again!</strong>
          </Alert>
        )}

        <TextField
          margin="dense"
          id="creditCode"
          label="Credit Code"
          name="creditCode"
          value={creditCode}
          onChange={(e) => setCreditCode(e.target.value)}
          fullWidth
          required
        />

        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
          <Button onClick={() => handleSave()} color="primary">
            SAVE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

EnterIndividualCreditCodeBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EnterIndividualCreditCodeDialog({
  volunteerSchedule,
  setVolunteerSchedule,
  indexOfQuarter,
  date,
  position,
  shift,
  userInfo,
  handleUserInfoChange,
}) {
  const [open, setOpen] = useState(false);

  const [codeEntered, setCodeEntered] = useState(false);

  // The volunteer schedule in a temp dict to be edited.
  const volunteerScheduleTemp = [...volunteerSchedule];

  const volunteerScheduleQuarterTemp = { ...volunteerSchedule[indexOfQuarter] };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Check if this shift has enteredCodes already. If it does then we have to check
    // if the current user has already entered their code.
    if (
      volunteerScheduleQuarterTemp[date][position][shift].enteredCodes !==
      undefined
    ) {
      // If the current user has already entered their code then set codeEntered to true.
      if (
        volunteerScheduleQuarterTemp[date][position][
          shift
        ].enteredCodes.includes(userInfo.userId)
      ) {
        setCodeEntered(true);
      }
    }
    // If enteredCodes doesn't exist then we have to set it to an empty array and update teh
    // volunteerSchedule state.
    else {
      volunteerScheduleQuarterTemp[date][position][shift].enteredCodes = [];
      volunteerScheduleTemp[indexOfQuarter] = {
        ...volunteerScheduleQuarterTemp,
      };
      setVolunteerSchedule([...volunteerScheduleTemp]);
    }
  }, [{ ...volunteerScheduleQuarterTemp }]);

  return (
    <div>
      {codeEntered ? (
        <Button disabled>CODE REDEEMED</Button>
      ) : (
        <Button onClick={handleClickOpen}>ENTER CODE</Button>
      )}

      <EnterIndividualCreditCodeBox
        open={open}
        onClose={handleClose}
        volunteerScheduleTemp={volunteerSchedule}
        setVolunteerSchedule={setVolunteerSchedule}
        volunteerScheduleQuarterTemp={volunteerScheduleQuarterTemp}
        indexOfQuarter={indexOfQuarter}
        date={date}
        position={position}
        shift={shift}
        userInfo={userInfo}
        handleUserInfoChange={handleUserInfoChange}
      />
    </div>
  );
}
