// This will calculate how much subsidy the user still has
// available without making a contribution themselves.  Otherwise
// known as the free subsidy.
import firebase from "./../../../../components/Firebase.js";
import CalcGrandTotal from "./../../../../functions/CalcGrandTotal.js";

export default async function CalcMaxSubsidy(userInfo, pickupLocation) {
  // We wrap in a try in case the file can't be loaded.
  try {
    // The amount the user has spent themselves.
    let totalSpent = 0;
    // The amount of donation and subsidies the user
    // contributed.
    let totalDonationSubsidy = 0;
    // The amount of donation and subsidies the user
    // contributed for the current week.
    let currentWeekDonationSubsidy = 0;

    const daysInWeek = 7; // Number of days in a week
    const database = firebase.firestore();

    // Get today's day index
    const today = new Date();
    // Get the time of today.
    const todayDate = today.getTime();
    // Get the day of the week
    const todayDayIndex = today.getDay();

    // Calculate the target day index for pickup
    const targetDayIndex = [
      "Sun",
      "Mon",
      "Tues",
      "Wed",
      "Thurs",
      "Fri",
      "Sat",
    ].indexOf(pickupLocation.pickupDay);

    // Calculate the difference between today's day index and target day index
    let dayDifference =
      (todayDayIndex - targetDayIndex - 1 + daysInWeek) % daysInWeek;

    // Adjust the day difference if it's greater than the number of days in a week
    if (dayDifference === 0) {
      dayDifference = 7;
    }
    // Calculate the start date based on the pickup day and number of weeks
    const startDate = new Date(
      todayDate -
        24 *
          60 *
          60 *
          1000 *
          (dayDifference + 7 * (pickupLocation.freqOfFreeSubsidy - 1)),
    );

    // Convert start and end dates to YYYYMMDD format
    const startDateStr = [
      startDate.getFullYear(),
      (startDate.getMonth() + 1).toString().padStart(2, "0"),
      startDate.getDate().toString().padStart(2, "0"),
      "0000000000000",
    ].join("");

    // This is the date of the current week which is used to
    // see if the user has used a subsidy this week already.
    const startOfWeekDate = new Date(
      todayDate - 24 * 60 * 60 * 1000 * dayDifference,
    );

    // Convert start of the week to YYYYMMDD format
    const startOfWeekDateStr = [
      startOfWeekDate.getFullYear(),
      (startOfWeekDate.getMonth() + 1).toString().padStart(2, "0"),
      startOfWeekDate.getDate().toString().padStart(2, "0"),
      "0000000000000",
    ].join("");
    // The end date is just the last day we're looking at which
    // will be today.
    const endDateStr = [
      today.getFullYear(),
      (today.getMonth() + 1).toString().padStart(2, "0"),
      today.getDate().toString().padStart(2, "0"),
      "9999999999999",
    ].join("");

    // Load all orders the user placed between the start date
    // and today's date.
    const docRef = database
      .collection("Users")
      .doc(userInfo.userId)
      .collection("Orders")
      .where("orderDate", ">=", startDateStr)
      .where("orderDate", "<=", endDateStr);

    const snapshot = await docRef.get();
    snapshot.forEach((doc) => {
      // Calculate the grand total.
      const [grandTotal] = CalcGrandTotal(doc.data());
      // If the user is giving a donation then we add to the
      // totalDonationSubsidy otherwise we subtract.
      if (doc.data().customerContribution === "donation") {
        totalDonationSubsidy += doc.data().donationSubsidy;
        // If the orderDate is within the current week then add that
        // amount to currentWeekDonationSubsidy.
        if (doc.data().orderDate > startOfWeekDateStr) {
          currentWeekDonationSubsidy += doc.data().donationSubsidy;
        }
      } else {
        totalDonationSubsidy -= doc.data().donationSubsidy;
        // If the orderDate is within the current week then add that
        // amount to currentWeekDonationSubsidy.
        if (doc.data().orderDate > startOfWeekDateStr) {
          currentWeekDonationSubsidy -= doc.data().donationSubsidy;
        }
      }
      // Add the grandTotal of each order their totalSpent.
      totalSpent += parseFloat(grandTotal);
    });
    // The amount of subsidy they have that is free.
    let freeSubsidy = 0;
    // If the totalDonationSubidy is negative then they've
    // used some subsidy.
    if (totalDonationSubsidy < 0) {
      // The free subsidy is the available subsidy the difference
      // between what they've spent and what they've used in subsidy.
      freeSubsidy =
        pickupLocation.subsidyAmount + (totalSpent + totalDonationSubsidy);
      // If the freeSubsidy is greater than the total amount they can
      // have in subsidy then set the freeSubisdy to the max subsidy of
      // the location.
      if (freeSubsidy > pickupLocation.subsidyAmount) {
        freeSubsidy = pickupLocation.subsidyAmount;
      }
    } else {
      freeSubsidy = pickupLocation.subsidyAmount;
    }

    // If the user somehow was able to access more than the
    // normally available subsidy or the subsidy amounts changed.
    // we will reset the subsidy to 0 if it is below 0.
    if (freeSubsidy < 0) {
      freeSubsidy = 0;
    }

    return [freeSubsidy, currentWeekDonationSubsidy];
  } catch (error) {
    console.error("Error fetching subsidy amount:", error);
    throw error;
  }
}
