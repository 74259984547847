// This function runs when you place the order again.  It runs the function
// placeOrder and then it adds this order to the orderLog so it displays for
// the user in real time and then shows the snackBar confirming the action.
export default function OrderAgain(
  order,
  setOrderLog,
  setPlacingOrder,
  PlaceOrder,
  userInfo,
  setSnackBarOpen,
  orderLog,
) {
  const ordersLogTemp = [];
  // ValidItems are the items that aren't recurring.  You can't order again
  // recurring orders.  Those need to placed individually
  const validItems = [];
  const validFarms = [];
  const validContactMethods = {};
  // Checks to see if the foodItem has a checked property and if it doesn't then
  // it's not a recurring order and is valid
  order.foodList.forEach((food) => {
    if (food.checked !== true) {
      validItems.push(food);
    }
  });

  // Cycles through the farmers list and filters out any farms that are only
  // delivering recurring orders.  Now you'll have only valid the farms and the
  // contact methods used with them.
  order.farmerList.forEach((farm) => {
    const index = validItems.findIndex(
      (food) => farm.farmName === food.farmName,
    );
    if (index !== -1) {
      validFarms.push(farm);
      validContactMethods[farm.farmName] = order.contactMethods[farm.farmName];
    }
  });

  // Once the recurring orders are filtered out check to see if there are still
  // any foodItems left and place the order otherwise alert the user that they
  // can't order recurring order again
  if (validItems.length !== 0) {
    const placedOrder = PlaceOrder(
      validFarms,
      validItems,
      userInfo,
      validContactMethods,
      order.donationSubsidy,
      order.customerContribution,
      null,
      null,
      null,
      null,
      null,
      null,
      setPlacingOrder,
      setSnackBarOpen,
    );
    ordersLogTemp.push(placedOrder);
    ordersLogTemp.push(...orderLog);
    setOrderLog(ordersLogTemp);
    setPlacingOrder(true);
  } else {
    // eslint-disable-next-line no-undef
    alert("You can't use order again to place recurring orders");
  }
}
