// This is called when a user logs in and will check if the user is still approved
// by the community host.
import firebase from "../components/Firebase.js";

// Checks to see if the user's selected community host pick up is still approved
// to pick up from this community host.
export default function CheckCommunityHostApproval(
  userInfoTemp,
  setUserInfo,
  setLoading,
) {
  const database = firebase.firestore();

  // Sets the communityLocation of the user.
  const communityLocation = userInfoTemp.pickupLocation;

  // Sets the naem of the distribution Location partnered with the community host.
  // eslint-disable-next-line no-unused-vars
  const distributionLocationName =
    userInfoTemp.pickupLocation.pickupLocation.name;

  // Creates the document name of where the community host information is shared in
  // the database.
  const documentName = [
    communityLocation.firstName,
    communityLocation.lastName,
    communityLocation.userId,
  ].join("");

  const docRef = database.collection("CommunityLocations").doc(documentName);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // Reads the document and checks that the community host still includes the current users
        // userid in their approved community member list and that it isn't hidden.  If it is then
        // it will delete the user's pickup location so that they have to pick another one.
        if (
          doc.data().hidden ||
          (doc.data().communityHubType !== "publicNoApproval" &&
            !doc.data().approvedCommunityMembers.includes(userInfoTemp.userId))
        ) {
          delete userInfoTemp.pickupLocation;
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      setUserInfo(userInfoTemp);
      setLoading(true);
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      setUserInfo(userInfoTemp);
      setLoading(true);
    });
}
