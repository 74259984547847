// This function is called when a distribution location is adding to an existing
// order and they need to uncover what items are being added to the pantry by taking into
// account the items that were put in the pantry before and what customers took from the pantry
// that should have just been taken from the current order.
import CreateCustomerLists from "./CreateCustomerLists.js";
import CreateLeftoverList from "./CreateLeftoverList.js";
import UpdatedPantryLeftovers from "./UpdatedPantryLeftovers.js";
import UpdateCustomerOrdersToRemoveLimits from "./UpdateCustomerOrdersToRemoveLimits.js";

export default function CreateUpdatedLeftoverList(
  newCommunityOrders,
  newImportedOrder,
  existingCommunityOrders,
  existingImportedOrder,
  existingFoodItems,
  userInfo,
  foodItems,
  combinedFoodList,
  combinedPantryFoodList,
) {
  // This is the foodList of all the items that the customers ordered.  The quantity is in individualQuantity sizes.
  // This is also the customer pantry food list which are the pantry items that were ordered in customer's orders.
  const [newCustomerFoodList, newPantryFoodList] = CreateCustomerLists(
    newCommunityOrders,
    newImportedOrder,
  );
  // This is the foodList of all the items that the customers ordered in the existing order.  The quantity is in individualQuantity sizes.
  // This is also the customer pantry food list from the exisiting order which are the pantry items that were ordered in customer's orders.
  // eslint-disable-next-line no-unused-vars
  const [existingCustomerFoodList, existingPantryFoodList] =
    CreateCustomerLists(existingCommunityOrders, existingImportedOrder);

  // This is the list of foodItems that we added to the pantry in the existing order.
  const existingLeftoverList = CreateLeftoverList(
    existingFoodItems,
    existingCustomerFoodList,
    userInfo,
  );

  // Leftovers to add to the pantry after taking into account the ones already added and the ones
  // taken.
  // combinedPantryFoodListTemp is the finally tally of foodItems that were taken from the pantry for this
  // combined order of new and existing.
  const [leftoversToAdd, combinedPantryFoodListTemp] = UpdatedPantryLeftovers(
    combinedFoodList,
    newCustomerFoodList,
    newPantryFoodList,
    existingLeftoverList,
    combinedPantryFoodList,
    userInfo.organisationName,
  );
  // Transfer over the temp value
  combinedPantryFoodList = combinedPantryFoodListTemp;

  UpdateCustomerOrdersToRemoveLimits(
    combinedPantryFoodList,
    newPantryFoodList,
    newCommunityOrders,
    newImportedOrder,
  );
  // Return the value of the leftovers to add to the order and the final list of food
  // items taken from the pantry.
  return [leftoversToAdd, combinedPantryFoodList];
}
