// Takes in the steps of the program that are explained by drop down buttons.  This
// function is called multiple times depending on how many steps there are.  Downward
// arrow is attached to each step unless this is the last step.
import React, { useState } from "react";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import StepButtons from "./StepButtons.jsx";

export default function HowItWorksSteps({
  stepNumber,
  title,
  src,
  totalSteps,
  text,
}) {
  // Expand the read more of the steps to see the text or hide the text of this text.
  const [expand, setExpand] = useState(false);

  // When the user clicks to expand or collapse the step.
  // eslint-disable-next-line no-unused-vars
  function onExpand() {
    setExpand(!expand);
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {stepNumber % 2 > 0 ? (
        <div
          style={{
            display: "flex",
            width: "800px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StepButtons
            stepNumber={stepNumber}
            title={title}
            src={src}
            text={text}
          />
          <img
            referrerPolicy="no-referrer"
            style={{ width: "300px" }}
            src={src}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "800px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            referrerPolicy="no-referrer"
            style={{ width: "300px" }}
            src={src}
          />
          <StepButtons
            stepNumber={stepNumber}
            title={title}
            src={src}
            text={text}
          />
        </div>
      )}

      {stepNumber < totalSteps && (
        <ArrowDownwardOutlinedIcon
          style={{ margin: "20px", color: "#9FBF59", fontSize: "60px" }}
        />
      )}
    </div>
  );
}
