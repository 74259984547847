// When the admin user deletes an item from the marketplace then the farm categories
// have to be checked as to whether the DistributionLocation -> Vancouver doc needs
// to be updated with the change.  If it does the function does that.
import firebase from "./../components/Firebase.js";
import UpdateCommunityLocation from "../functions/UpdateCommunityLocation.js";

export default function RemoveCategoryFromDistributionLocations(
  newFarmCategories,
  farmToChange,
  category,
) {
  if (!newFarmCategories[farmToChange]) {
    newFarmCategories[farmToChange] = [];
  }

  // Checks to see if the farm categories have changed in length for the given farm.
  // If the have we know we have to delete a category
  if (!newFarmCategories[farmToChange].includes(category)) {
    const database = firebase.firestore();

    const batch = database.batch();

    const distributionLocationsDocRef = database
      .collection("DistributionLocations")
      .doc("Vancouver");

    distributionLocationsDocRef.get().then((doc) => {
      if (doc.exists) {
        const distributionLocationInformationTemp = doc.data();
        // Cycles through all the locations stored in DistributionLocation -> Vancouver.
        Object.keys(distributionLocationInformationTemp).forEach((location) => {
          // Makes sure they have a categories field.
          if (distributionLocationInformationTemp[location].categories) {
            // Cycles through all the categories of each location.
            Object.keys(
              distributionLocationInformationTemp[location].categories,
            ).forEach((farm) => {
              // If there is the location sources from the affected farm then
              // update the farmCategories with the new one.
              if (farm === farmToChange) {
                distributionLocationInformationTemp[location].categories[farm] =
                  newFarmCategories[farm];
              }
            });
          }
        });

        // Update the distributionLocation -> Vancouver document with the updated
        // data.
        Object.keys(distributionLocationInformationTemp).forEach((location) => {
          batch.set(
            distributionLocationsDocRef,
            { [location]: distributionLocationInformationTemp[location] },
            { merge: true },
          );
          // Updates any community locations that may exist that have this distribution location as their partner.
          UpdateCommunityLocation(
            distributionLocationInformationTemp[location].userId,
            distributionLocationInformationTemp[location],
          );
        });

        // Commit these changes
        batch.commit();
      }
    });
  }
}
