// This is uesd to calcualte the grandTotal of an order.
// It will return the costTotal which excludes subsidy/donation
// and then the grandTotal which includes them.
import PriceTotals from "./PriceTotals.js";
import PackageTotals from "./PackageTotals.js";

export default function CalcGrandTotal(userOrder) {
  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);
  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);

  // The total including the donation or subsidy
  let grandTotal = 0;

  // The total packaging fee.
  let packageFeeTotal = 0;

  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion || 0;
  // The total of the food not including the donation or subsidy
  let costTotal = 0;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
    costTotal = grandTotal;
  });

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
    costTotal += packageTotals[packageTotal];
  });

  // Add in the subsidy and donation amounts
  if (userOrder.customerContribution === "donation") {
    grandTotal += userOrder.donationSubsidy;
  } else {
    grandTotal -= userOrder.donationSubsidy;
  }

  // If the volunteer cash portion is greater than 0 then we need to add to the
  // grandTotal and costTotal.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
    costTotal += parseFloat(volunteerFeeCash);
  }

  // If the delivery fee is greater than 0 then add it to the grandTotal and the costTotal.
  if (parseFloat(userOrder.deliveryFee) > 0) {
    grandTotal += userOrder.deliveryFee;
    costTotal += userOrder.deliveryFee;
  }

  grandTotal += packageFeeTotal;

  // Make sure that the grandTotal isn't ever negative.
  if (grandTotal < 0 && grandTotal > -0.01) {
    grandTotal = 0;
  }

  return [grandTotal.toFixed(2), costTotal];
}
