import React, { useEffect, useState, useContext } from "react";
import "../App.css";
import "../styles/ListOfIssues.css";
// eslint-disable-next-line no-unused-vars
import Menu from "../components/Menu.js";
// eslint-disable-next-line no-unused-vars
import LikeCounter from "../components/userInputs/LikeCounter.js";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";
// eslint-disable-next-line no-unused-vars
import ColourButton from "../components/userInputs/ColourButton.js";
// eslint-disable-next-line no-unused-vars
import LoadingContent from "../components/LoadingContent.jsx";

// eslint-disable-next-line no-unused-vars
class Issue extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
  }

  toggleHover() {
    this.props.toggleHover(this.props.rank - 1);
  }

  toggleNoHover() {
    this.props.toggleNoHover();
  }

  toggleOnClick() {
    this.props.toggleOnClick();
  }

  render() {
    const issueItem = this.props.issueItem;
    let hoverStyle;
    if (this.props.hover && this.props.title === issueItem.title) {
      hoverStyle = { backgroundColor: "#ffc0cb2e", cursor: "pointer" };
    } else {
      hoverStyle = { backgroundColor: "pink" };
    }
    return (
      <div
        className="Issue-Element"
        style={hoverStyle}
        onClick={this.toggleOnClick}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleNoHover}
      >
        <h2 className="Header-2"> {this.props.rank} </h2>
        <h2 className="Header-2"> {issueItem.title} </h2>
        <LikeCounter
          numOfLikes={issueItem.numOfLikes}
          title={issueItem.title}
          handleVoteChange={this.props.handleVoteChange}
          votesUsed={this.props.votesUsed}
        />
      </div>
    );
  }
}

// This is the entire List of elements.  It runs a map function of the issues
// and creates an element for each one.  It also handles the hovers and clicks
// of an issue and will show the dialog popup when someone does hover or clicks
// an issue.  It holds the state of the last hovered over element that is sent
// up from the issue itself.  This tells it which issue to show the dialog for.
// eslint-disable-next-line no-unused-vars
class ListElement extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      hover: false,
      click: false,
      listIndex: 0,
    };
  }

  toggleHover(index) {
    this.setState({
      hover: true,
      click: false,
      listIndex: index,
    });
  }

  toggleNoHover() {
    !this.state.click && this.setState({ hover: false });
  }

  toggleOnClick() {
    this.setState({ click: true });
  }

  handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.props.onBottom();
    }
  }

  render() {
    const issueList = this.props.issueList;
    const listIndex = this.state.listIndex;
    return (
      <div className="Issue-Page">
        <div className="Issue-List">
          {/* Logic to check if the user is logged in or not to show them how many
          Votes the have left */}
          {this.props.currentUser ? (
            <span className="Title-Row">
              <p className="Hidden-Element">
                {" "}
                Votes Used: {this.props.votesUsed}/10{" "}
              </p>
              <p className="Issue-Title"> Improvement List </p>
              <p> Votes Used: {this.props.votesUsed}/10 </p>
            </span>
          ) : (
            <p className="Issue-Title"> Improvement List </p>
          )}
          <div className="Issue-List-Element" onScroll={this.handleScroll}>
            {issueList.map((issueItem, index) => (
              <Issue
                key={index}
                issueItem={issueItem}
                rank={index + 1}
                toggleHover={this.toggleHover}
                toggleNoHover={this.toggleNoHover}
                toggleOnClick={this.toggleOnClick}
                hover={this.state.hover}
                title={issueList[listIndex].title}
                handleVoteChange={this.props.handleVoteChange}
                votesUsed={this.props.votesUsed}
              />
            ))}
          </div>
        </div>
        {/* When the user is hovering or has clicked on an issue than this.state.hover
        will be true and the pop up will show. */}
        {this.state.hover && (
          <IssueDetails
            currentUser={this.props.currentUser}
            title={issueList[listIndex].title}
            numOfLikes={issueList[listIndex].numOfLikes}
            handleVoteChange={this.props.handleVoteChange}
            votesUsed={this.props.votesUsed}
            description={issueList[listIndex].description}
            link={issueList[listIndex].link}
          />
        )}
      </div>
    );
  }
}

// This is the pop up that will show when you hover over an issue in the
// list.  If you click the item this pop up will stay open.  It displays
// the title, like counter and description of the issue.
// eslint-disable-next-line no-unused-vars
function IssueDetails(props) {
  return (
    <div className="Issue-Details-Box">
      <h2 style={{ textAlign: "center" }} className="Header-2">
        {" "}
        {props.title}{" "}
      </h2>

      <div className="Menu-Row">
        <LikeCounter
          numOfLikes={props.numOfLikes}
          title={props.title}
          handleVoteChange={props.handleVoteChange}
          votesUsed={props.votesUsed}
        />
      </div>
      {!!props.currentUser && (
        <ColourButton
          hoverColour="#cc97e6"
          colour="#c624ef"
          title="Discuss Further"
          onClick={() => handleListItemClick(props.link)}
        />
      )}

      <p> {props.description} </p>
      {/* <div className = "Test">
        <h4> Additions </h4>
        <div>
          {Top10IssueList.issues.map(issueItem => Additions(issueItem))}
        </div>
      </div> */}
    </div>
  );
}

// function Additions(props){
//   return(
//     <div>
//       <h5> {props.rank} </h5>
//       <p> {props.title} </p>
//       <div className="Menu-Row" >
//         <ThumbUpIcon fontSize = "small" color = "primary"/>
//         {props.numOfLikes}
//       </div>
//     </div>
//   );
// }

function handleListItemClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

function ListOfIssues() {
  // The hook where the data downloaded from the database is stored.  It is intialized
  // to have the contents of a document that is empty.  That way the map function doesn't
  // complain
  const [issueList, setIssueList] = useState({});
  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  const database = firebase.firestore();

  // These check if the user is logged in or not.  Provides all the userInfo and
  // the function callback for to update the userInfo
  const { currentUser, userInfo, handleUserInfoChange } =
    useContext(AuthContext);

  // Contains the last loaded issue in the issue list
  const [lastLoadedIssue, setLastLoadedIssue] = useState(undefined);

  // How many issues to load in each batch
  const loadLimit = 20;

  useEffect(() => {
    // This is used to grab the data of Issues from the database.  It
    // contains the information on all the issues
    const docRef = database
      .collection("Issues")
      .where("status", "==", "active")
      .orderBy("numOfLikes", "desc")
      .limit(loadLimit);
    const dataTransfer = [];
    docRef
      .get()
      .then((collection) => {
        // Stores the last document loaded to the hook
        setLastLoadedIssue(collection.docs[collection.docs.length - 1]);
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            dataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setIssueList(dataTransfer);
        setLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [database]);

  // This is to load more orders at 5 at a time
  function LoadMore() {
    if (lastLoadedIssue !== undefined) {
      const dataTransfer = [...issueList];
      // This is to stop showing the Load more once they've all been loaded
      let loadedDocs = loadLimit - 1;

      database
        .collection("Issues")
        .where("status", "==", "active")
        .orderBy("numOfLikes", "desc")
        .startAfter(lastLoadedIssue)
        .limit(loadLimit)
        .get()
        .then((collection) => {
          setLastLoadedIssue(collection.docs[collection.docs.length - 1]);
          collection.docs.forEach((doc, index) => {
            dataTransfer.push(doc.data());
            loadedDocs = index;
          });
          // Checks if the number of loaded docs is less than the limit
          if (loadedDocs < loadLimit - 1) {
            setLastLoadedIssue(undefined);
          }
          setIssueList(dataTransfer);
        });
    }
  }

  // This function will handle when a user upvotes or downvotes an issue.  It updates
  // the hooks that are currently holding the issue list in the top 10 issues box
  // as well as the user's own issue lists of issues they've voted for themselves.
  // It will check that the user has votes left and will make sure that user can
  // only downvote an item when they can.  It then updates the database to reflect
  // these changes
  function handleVoteChange(title, votesUsed, upVote) {
    // The maximum allowed votes from the user
    const maxVote = 10;
    // This will be the issue list that contains all the changes from the user
    const updatedIssueList = [...issueList];
    // This will be the user's own issue list that contains all the changes from
    // the users
    let updatedUsersLikedIssues = [];

    // This is to check if the user has liked anything yet and if so will set our
    // list equal thier current list.
    if (userInfo.votedIssues != null) {
      updatedUsersLikedIssues = [...userInfo.votedIssues];
    }
    const issuesDocRef = database.collection("Issues").doc(title);
    const usersDocRef = database.collection("Users").doc(currentUser.uid);
    const batch = database.batch();
    let change = null;

    // Finds the index of the selected item in the top 10 issues box
    const indexIssueList = updatedIssueList.findIndex((list) => {
      return list.title === title;
    });

    // Finds the index of the selected item in the users issue list
    const indexUsersLikedIssues = updatedUsersLikedIssues.findIndex((list) => {
      return list.title === title;
    });

    // This fucntions compares the number of likes of the items to ensure they
    // stay ranked in the correct order
    function compare(a, b) {
      if (a.numOfLikes < b.numOfLikes) {
        return 1;
      }
      if (a.numOfLikes > b.numOfLikes) {
        return -1;
      }
      return 0;
    }

    // If the user upvoted and they haven't reached they max number of votes yet
    if (upVote && votesUsed < maxVote) {
      // Updates the issue list to include their like
      updatedIssueList[indexIssueList].numOfLikes += 1;

      // Checks if the user has already liked this issue and if they haven't then
      // it will push this issue into their issue list and give it one vote.
      // This was difficult because it is an array of dictionaires so you have to
      // make sure you're adding the values of the dictionary and not a reference
      // to the dictionary itself.
      if (indexUsersLikedIssues === -1) {
        updatedUsersLikedIssues.push({ ...updatedIssueList[indexIssueList] });
        updatedUsersLikedIssues[updatedUsersLikedIssues.length - 1].votes = 1;
      }
      // If the user has liked this issue before then it will just add 1 more vote
      // to this issue in their user list.
      else {
        updatedUsersLikedIssues[indexUsersLikedIssues].votes += 1;
        updatedUsersLikedIssues[indexUsersLikedIssues].numOfLikes += 1;
      }

      // The change variable is set to increment 1 value
      change = firebase.firestore.FieldValue.increment(1);
    }
    // If the user down voted and they selected an item that is in their issue list
    else if (!upVote && indexUsersLikedIssues !== -1) {
      // Updates the issue list to include their like removal
      updatedIssueList[indexIssueList].numOfLikes -= 1;

      // Checks if the user has only one like left for this issue and if it does
      // removes this issue for thier list
      if (updatedUsersLikedIssues[indexUsersLikedIssues].votes === 1) {
        updatedUsersLikedIssues.splice(indexUsersLikedIssues, 1);
      }
      // If the user has more than one like for this issue then it just subtracts
      // one from their total
      else {
        updatedUsersLikedIssues[indexUsersLikedIssues].votes -= 1;
        updatedUsersLikedIssues[indexUsersLikedIssues].numOfLikes -= 1;
      }
      change = firebase.firestore.FieldValue.increment(-1);
    }

    // If there was a valid change sent through then before the update to the
    // the issueList, the user's issue list.  The userList must be sent through
    // a function to the Auth.js and the issue list is a hook and needs to be updated.

    if (change !== null) {
      batch.update(issuesDocRef, { numOfLikes: change });
      batch.set(
        usersDocRef,
        { votedIssues: updatedUsersLikedIssues },
        { merge: true },
      );
      batch.commit().catch(function (error) {
        console.log(
          "Error finding Issue in database.  Changes were not saved:",
          error,
        );
      });

      updatedIssueList.sort(compare);
      updatedUsersLikedIssues.sort(compare);
      handleUserInfoChange(updatedUsersLikedIssues);
      setIssueList(updatedIssueList);
    }
  }

  if (loading) {
    // Calculate the votes that a user has made by adding up the vote amounts in
    // each item
    let votesUsed = 0;
    if (userInfo.votedIssues != null) {
      userInfo.votedIssues.forEach((issue) => {
        votesUsed += issue.votes;
      });
    }
    return (
      <div>
        <Menu />
        <div className="Page-Market">
          <ListElement
            issueList={issueList}
            onBottom={LoadMore}
            handleVoteChange={handleVoteChange}
            currentUser={currentUser}
            votesUsed={votesUsed}
          />
        </div>
      </div>
    );
  } else {
    return <LoadingContent />;
  }
}

export default ListOfIssues;
