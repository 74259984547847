// This is used to calculate the delivery fees per location.
export default function CalculateDeliveryFees(
  communityOrders,
  importedOrder,
  selectedDistributionLocation,
  location,
) {
  // The deliveryFees per location.
  const deliveryFees = {};

  // The total delivery Fees at all locations.
  let totalDeliveryFees = 0;

  // Set the current location's delivery fees to 0
  deliveryFees[location] = 0;
  // Cycle through the community orders of this location.
  Object.keys(communityOrders).forEach((customerOrder) => {
    // If the delivery fee exists and it is more that nothing.
    if (communityOrders[customerOrder].deliveryFee > 0) {
      // Add the delivery amount to the location's total
      deliveryFees[location] += parseFloat(
        communityOrders[customerOrder].deliveryFee,
      );
      // Add the delivery amount to the total of all locations.
      totalDeliveryFees += parseFloat(
        communityOrders[customerOrder].deliveryFee,
      );
    }
  });

  // Cycle through the imported locations
  Object.keys(importedOrder).forEach((importedLocation) => {
    // Set importedCommunityOrders so it easy to access this location's communityOrders.
    const importedCommunityOrders =
      importedOrder[importedLocation].communityOrders;
    // Initialise the current location's packaging fees to 0
    deliveryFees[importedLocation] = 0;
    // Cycle through the community orders of each imported location.
    Object.keys(importedCommunityOrders).forEach((customerOrder) => {
      // If the delivery fee exists and it is more that nothing.
      if (importedCommunityOrders[customerOrder].deliveryFee > 0) {
        // Add the delivery amount to the location's total
        deliveryFees[importedLocation] += parseFloat(
          importedCommunityOrders[customerOrder].deliveryFee,
        );
        // Add the delivery amount to the total of all locations.
        totalDeliveryFees += parseFloat(
          importedCommunityOrders[customerOrder].deliveryFee,
        );
      }
    });
  });

  // Set the total in the map to the total delivery fees.
  deliveryFees.Total = totalDeliveryFees;

  return deliveryFees;
}
