// Creates an Excel sheet for the user of the volunteer schedule.
import * as XLSX from "xlsx";
import FindNextDay from "../../../functions/FindNextDay.js";
import YYYYMMDDConverter from "../../../functions/YYYYMMDDConverter.js";

// Created the matrix of the schedule
function CreateVolunteerDict(volunteerSchedule) {
  // The headers of the excel sheet
  const headersTemp = {};
  // The dictionary reformatted from the volunteerSchedule to
  // make the matrix.
  const scheduleInDict = {};
  // The current year we are looking at.
  const year = volunteerSchedule.timePeriod.split("-")[0];
  // We don't need this anymore and it causes trouble when cycling through.
  delete volunteerSchedule.timePeriod;
  // The date of the volunteer shift.  Default to first week.
  let date = Object.keys(volunteerSchedule).sort()[0];
  // The month of the volunteer shift.
  let month = parseInt(date.substring(0, 2)) + 1;
  // The Sunday of the volunteer shift.
  let day = parseInt(date.substring(2, 4));

  // The date in the format we use to find the next Saturday
  date = new Date([year, month, day].join("-"));

  // Set the week which is the date of the next Saturday.
  let week = FindNextDay(date, 6);
  // Set the week to the next Saturday in the format with "YYYY-MM-DD"
  week = YYYYMMDDConverter(week, true);
  // Add this week to the scheduleInDict.
  scheduleInDict[week] = {};

  // Cycle through the volunteer schedule.
  Object.keys(volunteerSchedule)
    .sort()
    .forEach((volunteerDay) => {
      // Set the date of this volunteer shift.
      month = parseInt(volunteerDay.substring(0, 2)) + 1;
      day = parseInt(volunteerDay.substring(2, 4));

      date = new Date([year, month, day].join("-"));

      // Find the next Saturday of this volunteer shift.
      let currentWeek = FindNextDay(date, 6);
      currentWeek = YYYYMMDDConverter(currentWeek, true);

      // If the currentWeek is greater than current week then this shift is
      // in a future week and we need to add a new week.
      if (currentWeek > week) {
        // Add to the headers
        Object.keys(scheduleInDict[week])
          .sort()
          .forEach((position) => {
            // If the position already exists then we need to update the count of these positions
            // in this week.
            if (Object.keys(headersTemp).includes(position)) {
              // if the count is greaterthan the current count then we update it as
              // this is the highest count so far out of any weeks.
              if (
                scheduleInDict[week][position].count > headersTemp[position]
              ) {
                headersTemp[position] = scheduleInDict[week][position].count;
              }
            }
            // If the position is new then just set the count to this amount.
            else {
              headersTemp[position] = scheduleInDict[week][position].count;
            }
          });
        // update the week to the current week
        week = currentWeek;
        // Set the empty week
        scheduleInDict[week] = {};
      }

      // Cycle through the postion's in this day of volunteering.
      Object.keys(volunteerSchedule[volunteerDay]).forEach((position) => {
        // If the position doesn't exist then add it with the count and credits.
        if (scheduleInDict[week][position] == null) {
          scheduleInDict[week][position] = {
            count: 0,
            credits: volunteerSchedule[volunteerDay][position].creditValue,
          };
        }
        // Cycle through the shifts of this position.
        Object.keys(volunteerSchedule[volunteerDay][position]).forEach(
          (shift) => {
            // Skip the non-shift keys
            if (shift !== "creditValue" && shift !== "location") {
              // Add to the count of volunteers for this position for that week.
              scheduleInDict[week][position].count +=
                volunteerSchedule[volunteerDay][position][
                  shift
                ].volunteers.length;
            }
          },
        );
      });
    });
  // Set the matrix to be empty.
  const scheduleMatrix = [];
  // Set the first value in the headers column.
  const headersCol = ["Week YYYY-MM-DD"];
  // Cycle through all the positions and see what their top
  // volunteer count is and create the header column.
  Object.keys(headersTemp)
    .sort()
    .forEach((position) => {
      for (let i = 0; i < headersTemp[position]; i++) {
        headersCol.push(position.concat(parseInt(i + 1)));
      }
    });

  // Push the headers row.
  scheduleMatrix.push(headersCol);

  // Cycle through all the weeks of the schedule and add them to the weeks.
  Object.keys(scheduleInDict)
    .sort()
    .forEach((week) => {
      // The row starts with the week we're looking at.
      const row = [week];
      // Cycle through the positions of the headers.
      Object.keys(headersTemp)
        .sort()
        .forEach((position) => {
          // Cycle though however many each position has at their max
          // week count for the quarter.
          for (let i = 0; i < headersTemp[position]; i++) {
            // If there are positions that don't exist in that week then
            // we still have to set this column as 0.
            if (scheduleInDict[week][position] == null) {
              row.push(0);
            }
            // Otherwise check if there was a volunteer for this position
            // for that week.
            else {
              // If the count of this week is greater than the current value i
              // then this week still has volunteers to add and we push those credits.
              if (scheduleInDict[week][position].count > i) {
                row.push(scheduleInDict[week][position].credits);
              }
              // There are no more volunteers for this position that week but it's not
              // the week with the highest count of this position.
              else {
                row.push(0);
              }
            }
          }
        });
      // Add the row to the matrix.
      scheduleMatrix.push(row);
    });

  return scheduleMatrix;
}

/**
 *  Creates a .xls format spreadsheet that includes the following columns:
 *    * Week YYYY-MM-DD
 *    * VolunteerPositionA1
 *    * VolunteerPositionA2
 *    * VolunteerPositionA3
 *    * VolunteerPositionB1
 *    * VolunteerPositionC1
 *    * VolunteerPositionC2
 *    * VolunteerPositionXX
 */
export function createVolunteerSpreadsheetUtil(volunteerSchedule) {
  // Sets the time period of this schedule.
  const timePeriod = volunteerSchedule.timePeriod;
  // Create the matrix from the schedule.
  const scheduleMatrix = CreateVolunteerDict(volunteerSchedule);

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(scheduleMatrix);
  const worksheetColsWidths = [];

  for (let i = 0; i < scheduleMatrix[0].length; i++) {
    // This sets each columns width.
    worksheetColsWidths.push({
      wch: 25,
    });
  }

  worksheet["!cols"] = worksheetColsWidths;

  XLSX.utils.book_append_sheet(workbook, worksheet, "Volunteer Summary");
  XLSX.writeFile(workbook, `${timePeriod}-Volunteer-Schedule-.xls`);
}
