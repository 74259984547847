// Takes in the order of a distribution location and the foodItem that has been modified
// it outputs a dictionary for both the imported and the Community Orders of all the
// individual orders that were affected.
export default function CommunityOrdersAffected(order, foodItemUpdate) {
  // Orders to change from community Orders
  const ordersToChange = [];
  // Orders to change for the imported orders
  const importedOrdersToChange = [];

  // Community orders and the imported orders set to a value to be used
  const communityOrders = order.communityOrders;
  const importedOrder = order.importedOrder;

  // Cycle through the orders check to see if the food item matches.  If it does then
  // add that to the orders to change array
  Object.keys(communityOrders).forEach((communityMember) => {
    communityOrders[communityMember].foodList.forEach((foodItem) => {
      if (
        foodItem.description === foodItemUpdate.description &&
        foodItem.farmName === foodItemUpdate.farmName &&
        foodItem.item === foodItemUpdate.item &&
        !!foodItem.limit === !!foodItemUpdate.limit
      ) {
        ordersToChange.push(communityMember);
      }
    });
  });
  // With the introduction of the multiple imported orders there is new way to
  // interact with the orders.
  if (order.orderDate > "202106150000000000000") {
    // Cycle through the imported orders check to see if the food item matches.  If it does then
    // add that to the orders to change array
    if (Object.keys(order.importedOrder).length !== 0) {
      // Cycle through all the distribution locations
      Object.keys(importedOrder).forEach((location) => {
        // Create an empty array for each location
        importedOrdersToChange[location] = [];
        // Cycle through the imported locations community orders
        Object.keys(importedOrder[location].communityOrders).forEach(
          (communityMember) => {
            // Cycle through each indivduals' foodList of items
            importedOrder[location].communityOrders[
              communityMember
            ].foodList.forEach((foodItem) => {
              // Find teh matching foodItem with the one we're updating or deleting.
              if (
                foodItem.description === foodItemUpdate.description &&
                foodItem.farmName === foodItemUpdate.farmName &&
                foodItem.item === foodItemUpdate.item &&
                !!foodItem.limit === !!foodItemUpdate.limit
              ) {
                // Push this community member to the location's array
                importedOrdersToChange[location].push(communityMember);
              }
            });
          },
        );
      });
    }
  } else {
    // Cycle through the imported orders check to see if the food item matches.  If it does then
    // add that to the orders to change array
    if (
      order.importedDistributionDate !== null ||
      order.importedDistributionDate !== undefined
    ) {
      Object.keys(importedOrder).forEach((communityMember) => {
        importedOrder[communityMember].foodList.forEach((foodItem) => {
          if (
            foodItem.description === foodItemUpdate.description &&
            foodItem.farmName === foodItemUpdate.farmName &&
            foodItem.item === foodItemUpdate.item &&
            !!foodItem.limit === !!foodItemUpdate.limit
          ) {
            importedOrdersToChange.push(communityMember);
          }
        });
      });
    }
  }

  return [ordersToChange, importedOrdersToChange];
}
