// When the distributionLocation toggles which farms are to display their items
// in the pantry AND their catalogue this is called to update the distributionLocation
import firebase from "./../components/Firebase.js";
import NextPickupDay from "./../functions/NextPickupDay.js";
import UpdateCommunityLocation from "./../functions/UpdateCommunityLocation.js";

export default function UpdateFarmsInPantryAndCatalogue(
  userInfo,
  distributionLocationInformation,
  setDistributionLocationInformation,
  farm,
  addFarm,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");
  // stores the information that would be stored in distributionLocations -> Vancouver -> location
  const distributionLocationInformationTemp = {
    ...distributionLocationInformation,
  };

  // Creates a reference to the farm key to update in the document
  let locationToUpdate = [userInfo.organisationName, "farmsPickupDates", farm];
  // create a dot reference
  locationToUpdate = locationToUpdate.join(".");

  // This is for whether the farm is showing the pantry and the catalogue at the same
  // time.
  let locationToUpdateForCatalogueAndPantry = [
    userInfo.organisationName,
    "farmsCatalogueAndPantry",
    farm,
  ];
  // create a dot reference
  locationToUpdateForCatalogueAndPantry =
    locationToUpdateForCatalogueAndPantry.join(".");

  // set the date to not set as default
  let setDate = distributionLocationInformationTemp.farmsPickupDates[farm];

  // If we are turning on the PantryAndCatalogue then we have to make sure that
  // the pantry is on too.
  if (addFarm) {
    // Turn on the pantry first.
    setDate = NextPickupDay(userInfo.pickupDay);
    distributionLocationInformationTemp.farmsPickupDates[farm] = setDate;
    // Turn on the farmsCatalogueAndPantry
    distributionLocationInformationTemp.farmsCatalogueAndPantry[farm] = true;
  }
  // We are turning off the PantryAndCatalogue so we just set farmsCatalogueAndPantry
  // to false.
  else {
    distributionLocationInformationTemp.farmsCatalogueAndPantry[farm] = false;
  }

  // Update the document.  The only way to turn on farmsCatalogueAndPantry is to
  // use its specific toggle so there is no way to change this to true from this function.
  batch.update(distributionLocationsDocRef, {
    [locationToUpdate]: setDate,
    [locationToUpdateForCatalogueAndPantry]:
      distributionLocationInformationTemp.farmsCatalogueAndPantry[farm],
  });
  // Updates the community location in CommunityLocations collection in the database.
  UpdateCommunityLocation(
    distributionLocationInformationTemp.userId,
    distributionLocationInformationTemp,
  );

  batch.commit();
  setDistributionLocationInformation({
    ...distributionLocationInformationTemp,
  });
}
