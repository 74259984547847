// This is used to calculate the volunteer Fees per location.
export default function CalculateVolunteerFees(
  communityOrders,
  importedOrder,
  selectedDistributionLocation,
  location,
) {
  // The volunteer Fees per location.
  const volunteerFees = {};

  // The total volunteer fees at all locations.
  let totalVolunteerFees = 0;

  // Set the current location's packaging fees to 0
  volunteerFees[location] = 0;
  // Cycle through the community orders of this location.
  Object.keys(communityOrders).forEach((customerOrder) => {
    // If the volunteer fees exists and it is more that nothing.
    if (parseFloat(communityOrders[customerOrder].cashPortion) > 0) {
      // Add the volunteer fees amount to the location's total
      volunteerFees[location] += parseFloat(
        communityOrders[customerOrder].cashPortion,
      );
      // Add the volunteer fees amount to the total of all locations.
      totalVolunteerFees += parseFloat(
        communityOrders[customerOrder].cashPortion,
      );
    }
  });

  // Cycle through the imported locations
  Object.keys(importedOrder).forEach((importedLocation) => {
    // Set importedCommunityOrders so it easy to access this location's communityOrders.
    const importedCommunityOrders =
      importedOrder[importedLocation].communityOrders;
    // Initialise the current location's volunteer fees to 0
    volunteerFees[importedLocation] = 0;
    // Cycle through the community orders of each imported location.
    Object.keys(importedCommunityOrders).forEach((customerOrder) => {
      // If the volunteer Fees exists and it is more that nothing.
      if (parseFloat(importedCommunityOrders[customerOrder].cashPortion) > 0) {
        // Add the volunteer Fees amount to the location's total
        volunteerFees[importedLocation] += parseFloat(
          importedCommunityOrders[customerOrder].cashPortion,
        );
        // Add the volunteer Fees amount to the total of all locations.
        totalVolunteerFees += parseFloat(
          importedCommunityOrders[customerOrder].cashPortion,
        );
      }
    });
  });

  // Set the total in the map to the total package fees.
  volunteerFees.Total = totalVolunteerFees;

  return volunteerFees;
}
