// The is the Pop Dialog that appears when a customer clicks on the MORE DETAILS
// button on the menu page.  This will display information about where the ingredients
// came from and provide them with links to the websites of the farmers.

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import List from "@material-ui/core/List";
// eslint-disable-next-line no-unused-vars
import ListItem from "@material-ui/core/ListItem";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";

import firebase from "./../Firebase.js";

// This is the box in the list that contains an ingredient, it shares the information
// of harvestedOn, deliveredOn, supplyingFarm and the ingredient's name.
function IngredientListItem(ingredient, displayDeliveryDate) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="First-Row">
        <p className="Dialog-Item-Text"> {ingredient.ingredient} </p>
        {displayDeliveryDate && !!ingredient.deliveredOn && (
          <p className="Dialog-Item-Text">
            {" "}
            Delivered On: {ingredient.deliveredOn}{" "}
          </p>
        )}
      </div>
      {/* <div className = "Important-Dates-Column" >
          <p className = "Dialog-Item-Text"> Harvested On: {ingredient.harvestedOn} </p>
        </div> */}
      <p className="Dialog-Item-Text">
        {" "}
        Supplied By: {ingredient.supplyingFarm.farmName}{" "}
      </p>
    </div>
  );
}

// This is the Subheader of the dialog that popsUp it shows the Date of the Meal
// a line to divide and the the local ingredients.
// eslint-disable-next-line no-unused-vars
function PopUpSubHeader(props) {
  return (
    <div>
      <p className="Date-Of-Meal"> Date of Meal: {props.dateOfMeal} </p>
      <h4 className="Local-Ingredients-Header">Local Ingredients</h4>
    </div>
  );
}

// This function takes in a url and then opens it in another tab.  Used to open
// The farmer's websites
function handleListItemClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

function SimpleDialog(props) {
  const { onClose, open } = props;

  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  // The ingredientList will be where the entire list of ingredients that a single
  // restaurant has stored as a dictionary with the key being the name of the ingredient
  // and the details of the ingredient stored in a dictionary as the value of the key
  const [ingredientList, setIngredientList] = useState("Loading");

  // This is set to false until the data from the database been loaded
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const database = firebase.firestore();
    const today = moment(props.dateOfMeal).format().substring(0, 10);
    const dataTransfer = [];
    const dataTransfer2 = [];
    // Cycle through all the ingredients in this item and find the document for
    // each of them
    props.localIngredients.forEach((ingredient, index) => {
      // This will find the document that has the userId of the selected
      // restaurantMenu, the chosen ingredient, and only ones whose delivery date
      // is before or on today.  Then order them from most recent to last and
      // Take the most recent one and that is the document.
      const docRef = database
        .collection("IngredientList")
        .where("userId", "==", props.userId)
        .where("ingredient", "==", ingredient)
        .where("deliveredOnISO", "<=", today)
        .orderBy("deliveredOnISO", "desc")
        .limit(1);
      docRef
        .get()
        .then((collection) => {
          collection.docs.forEach((doc) => {
            dataTransfer.push(doc.data());
          });
          if (dataTransfer[index] === undefined) {
            dataTransfer.push({ ingredient });
          }
          // setIngredientList(dataTransfer);
          // Once you have cycled through all the ingredients dataTransfer should
          // have as many entries and ingredients on your list.  You know that it
          // has finished loading then.  Now we're ready to start checking for recurring
          // orders
          if (dataTransfer.length === props.localIngredients.length) {
            dataTransfer.forEach(
              (dataTransferIngredient, dataTransferIndex) => {
                const recurringOrderDocRef = database
                  .collection("IngredientList")
                  .where("userId", "==", props.userId)
                  .where("ingredient", "==", dataTransferIngredient.ingredient)
                  .where("status", "==", "ongoing")
                  .limit(1);

                recurringOrderDocRef.get().then((collection) => {
                  collection.docs.forEach((doc) => {
                    dataTransfer2.push(doc.data());
                  });

                  // If there is no recurring order.
                  if (dataTransfer2[dataTransferIndex] === undefined) {
                    // If there is no data on this ingredient at all.
                    if (dataTransferIngredient.supplyingFarm === undefined) {
                      dataTransfer2[dataTransferIndex] =
                        dataTransferIngredient.ingredient;
                    }
                    // If there is only a one time order on this ingredient
                    else {
                      dataTransfer2[dataTransferIndex] = dataTransferIngredient;
                    }
                  }
                  // If there is a recurring order
                  else {
                    // If there is also a one time order
                    if (dataTransferIngredient.supplyingFarm !== undefined) {
                      // If the order of the one time order is after the startDay of
                      // the recurring order than the one time order is the most recent
                      // otherwise do leave the recurring order in the array.
                      if (
                        dataTransferIngredient.deliveredOnISO >
                        dataTransfer2[dataTransferIndex].startDay
                      ) {
                        dataTransfer2[dataTransferIndex] =
                          dataTransferIngredient;
                      }
                    }
                  }

                  if (dataTransfer2.length === props.localIngredients.length) {
                    setIngredientList(dataTransfer2);
                    setLoading(true);
                  }
                });
              },
            );
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    });
  }, [props.dateOfMeal, props.localIngredients, props.userId]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <span style={{ display: "flex", flexDirection: "row-reverse" }}>
        <button onClick={handleClose} className="Close-Button">
          x
        </button>
      </span>
      <div style={{ margin: "10px" }}>
        <DialogTitle id="simple-dialog-title">{props.itemName}</DialogTitle>
        <PopUpSubHeader
          dateOfMeal={props.dateOfMeal.toGMTString().substring(0, 16)}
        />
        {loading ? (
          <List className="List-Container">
            {ingredientList[0] === "" ? (
              <ListItem className="No-Farm-Data">
                <p className="Dialog-Item-Text">
                  Working together with other organisations and farmers we are
                  trying to increase the availability and affordiability of
                  locally sourced ingredients.
                </p>
              </ListItem>
            ) : (
              <div>
                {/* This reads the ingredientList that contains all the ingredient info
                  and then cycles through each ingredient and creates a list element for
                  for each ingredient.  If the ingredient doesn't have any farm info in
                  the database then it will say the farm data isn't available. */}
                {ingredientList.map((ingredient, i) => {
                  if (typeof ingredient === "string") {
                    return (
                      <ListItem className="No-Farm-Data" key={i}>
                        <p key={i} className="Dialog-Item-Text">
                          Farm data is unavailable for {ingredient}
                        </p>
                      </ListItem>
                    );
                  } else {
                    return (
                      <ListItem
                        key={i}
                        className="Ingredient-List"
                        button
                        onClick={() =>
                          handleListItemClick(ingredient.supplyingFarm.farmURL)
                        }
                      >
                        {IngredientListItem(
                          ingredient,
                          props.displayDeliveryDate,
                        )}
                      </ListItem>
                    );
                  }
                })}
              </div>
            )}
          </List>
        ) : (
          <h3> Loading </h3>
        )}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function MenuItemDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        + More Details
      </Button>
      {open && (
        <SimpleDialog
          open={open}
          onClose={handleClose}
          itemName={props.itemName}
          localIngredients={props.localIngredients}
          dateOfMeal={props.date}
          restaurantName={props.restaurantName}
          userId={props.userId}
          displayDeliveryDate={props.displayDeliveryDate}
        />
      )}
    </div>
  );
}
