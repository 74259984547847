// This creates the list that we will use when a distribution is deleting food items and would like to
// remove them from the community members' orders.  This will list will contain each user once even if they
// have multiple orders as this list will used to determine if the user will receive an email or not.
import CreateUsersRemovedItems from "../../../../components/dialogs/Components/SendUserEmails/RemovedItemEmail/CreateUsersRemovedItems.js";
import LoadAffectedUserEmails from "../../../../components/dialogs/Components/SendUserEmails/RemovedItemEmail/LoadAffectedUserEmails.js";

export default function CreateListOfForReview(
  order,
  setUsersWithFoodItemsRemoved,
  setCheckList,
  setLoadingEmails,
  setUserEmails,
  foodUserRemovalList,
  setFoodUserRemovalList,
) {
  // A temporary value that the user uses to run through the selectedItems and
  // finds which users had this item in their order.
  let foodItemUpdate = {};
  // These are the userOrders that need to be changed. {firstName-lastName-userid-orderDate : boolean}
  const ordersToChangeDict = {};
  // These are the imported userOrders that need to be changed. {location: {firstName-lastName-userid : boolean}}
  const importedOrdersToChangeDict = {};
  // Creates a checkList with each user being sent an email by setting it to true
  // True is the default it is default to send an email to all the affected users.
  // {firstName-lastName-userId : true}
  const checkListTemp = {};

  // This creates the list of users that have had their foodItems removed.
  // {firstName-lastName-userId : [{...foodItem}]}
  let usersWithFoodItemsRemovedTemp = {};
  // We'll use this variable to update the foodUserRemovalList with the ordesToChange.
  const foodUserRemovalListTemp = { ...foodUserRemovalList };

  // Cycle through the selectedItems and then the selectedPantryItems.
  Object.keys(foodUserRemovalList).forEach((currentItems) => {
    // Cycle through the indices for the foodItems that we're removing.
    Object.keys(foodUserRemovalList[currentItems]).forEach((foodItemIndex) => {
      // Set the foodItem to update.
      foodItemUpdate = order[currentItems][foodItemIndex];
      // This will be used to list the orders that we're changing.
      const ordersToChange = [];
      // If the foodItem exists then we will cycle through all the users' orders that have this food
      // Item. It will then check if that user was checked and so an order we want to update.
      if (foodUserRemovalList[currentItems][foodItemIndex] !== undefined) {
        Object.keys(
          foodUserRemovalList[currentItems][foodItemIndex].detailedOrders,
        ).forEach((user) => {
          // Check if this user was checked for removal.
          if (
            foodUserRemovalList[currentItems][foodItemIndex].detailedOrders[
              user
            ].checked
          ) {
            // Add this user to the ordersToChange array.
            ordersToChange.push(user);
          }
        });
      }

      // Cycles through the array of this distribution location's orders that were
      // affected.
      ordersToChange.forEach((userOrder) => {
        // Set this to true to allow for the user's email to be loaded.
        ordersToChangeDict[userOrder] = true;
        // Set the checklist to true for this user to receive an email.
        checkListTemp[
          [
            userOrder.split("-")[0],
            userOrder.split("-")[1],
            userOrder.split("-")[2],
          ].join("-")
        ] = true;
        // Creates a dictionary with the users and an array of all the foodItems
        // removed from their order. {firstName-lastName-userId : [{...foodItem}]}
        usersWithFoodItemsRemovedTemp = CreateUsersRemovedItems(
          foodItemUpdate,
          order,
          userOrder,
          usersWithFoodItemsRemovedTemp,
        );
      });
      foodUserRemovalListTemp[currentItems][foodItemIndex].ordersToChange = [
        ...ordersToChange,
      ];
    });
  });

  setFoodUserRemovalList({ ...foodUserRemovalListTemp });
  setUsersWithFoodItemsRemoved({ ...usersWithFoodItemsRemovedTemp });
  // Set the checkList to the temporary one created that sets all the users to
  // true to receive an email by default.
  setCheckList({ ...checkListTemp });
  // Loads all the users emails and controls the loading hooks.
  LoadAffectedUserEmails(
    ordersToChangeDict,
    importedOrdersToChangeDict,
    setLoadingEmails,
    setUserEmails,
  );
}
