// eslint-disable-next-line no-unused-vars
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import MenuItem from "@material-ui/core/MenuItem";
// eslint-disable-next-line no-unused-vars
import FormControl from "@material-ui/core/FormControl";
// eslint-disable-next-line no-unused-vars
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect({
  farmName,
  options,
  selected,
  title,
  handleChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <FormControl required className={classes.formControl}>
        {/* <InputLabel id="demo-simple-select-required-label">{title}</InputLabel> */}
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={selected}
          // TODO memoize callback
          onChange={(e) => handleChange(e, farmName)}
          className={classes.selectEmpty}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={option + index} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
