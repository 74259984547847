// This function handles when a distributionLocation deletes a sourcing farm.  It has
// to update their categories dictionary on the DistributionLocations -> Vancouver document
// and update the farmsPickupDates.
import firebase from "../../../components/Firebase.js";
import UpdateCommunityLocation from "../../../functions/UpdateCommunityLocation.js";

export default function DeleteSourcingFarm(
  index,
  userInfo,
  handleUserInfoChange,
  distributionLocationInformation,
  setDistributionLocationInformation,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  const userInfoTemp = { ...userInfo };

  // Stores the data that is in distributionLocation collection
  const distributionLocationInformationTemp = {
    ...distributionLocationInformation,
  };

  // Creates the dot reference to update a distributionLocations farmsPickupDates
  let locationToUpdate = [userInfo.organisationName, "farmsPickupDates"];
  locationToUpdate = locationToUpdate.join(".");

  // Splices out this farm from the distributionLocation's farmsPickupDates
  delete distributionLocationInformationTemp.farmsPickupDates[
    userInfoTemp.farms[index].farmName
  ];

  // This is for whether the farm is showing the pantry and the catalogue at the same
  // time.
  let locationToUpdateForCatalogueAndPantry = [
    userInfo.organisationName,
    "farmsCatalogueAndPantry",
  ];
  locationToUpdateForCatalogueAndPantry =
    locationToUpdateForCatalogueAndPantry.join(".");

  // Splices out this farm from the distributionLocation's farmsCatalogueAndPantry
  delete distributionLocationInformationTemp.farmsCatalogueAndPantry[
    userInfoTemp.farms[index].farmName
  ];

  // Creates the dot reference to update a distributionLocations categories
  let locationToUpdate2 = [userInfo.organisationName, "categories"];
  locationToUpdate2 = locationToUpdate2.join(".");

  if (distributionLocationInformationTemp.categories) {
    // Splices out this farm from the distributionLocation's categories
    delete distributionLocationInformationTemp.categories[
      userInfoTemp.farms[index].farmName
    ];
  }

  // Updates the information in the DistributionLocations -> Vancouver Document.
  batch.update(distributionLocationsDocRef, {
    [locationToUpdate]: {
      ...distributionLocationInformationTemp.farmsPickupDates,
    },
    [locationToUpdateForCatalogueAndPantry]: {
      ...distributionLocationInformationTemp.farmsCatalogueAndPantry,
    },
    [locationToUpdate2]: { ...distributionLocationInformationTemp.categories },
  });

  // Removes this farm from the user's farms in userInfo
  userInfoTemp.farms.splice(index, 1);

  // updates userInfo
  handleUserInfoChange({ ...userInfoTemp }, true);
  // Updates the community location in CommunityLocations collection in the database.
  UpdateCommunityLocation(
    distributionLocationInformationTemp.userId,
    distributionLocationInformationTemp,
  );

  batch.commit();
  setDistributionLocationInformation(distributionLocationInformationTemp);
}
