// Called by PlaceOrder.js to email users that had their order modified by the cancellation
// of a community host.
import firebase from "../../../components/Firebase.js";

export default function UsersToEmailUpdates(modifiedUserPickups) {
  // The list of users that had their orders modified. {cancelled : {}, relocated : {}}
  const modifiedUserPickupsTemp = { ...modifiedUserPickups };
  const database = firebase.firestore();

  // The modifiedPickupEmail cloud function.
  const modifiedPickupEmail = firebase
    .functions()
    .httpsCallable("modifiedPickupEmail");

  // Cycle through the dictionary of modifiedUserPickups
  Object.keys(modifiedUserPickups).forEach((modification, i) => {
    Object.keys(modifiedUserPickups[modification]).forEach((user, ind) => {
      // split up the user.
      // eslint-disable-next-line no-unused-vars
      const [firstName, lastName, userId] = user.split("-");
      // reference the document in the Users collection.
      const docRef = database.collection("Users").doc(userId);

      // Read the user's document.
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // Find the user's email and add it to the modified user dictionary.
            modifiedUserPickupsTemp[modification][user].email =
              doc.data().email;
            // Set the destination of the email to the user and the distribution location.
            const dest = [
              modifiedUserPickupsTemp[modification][user].communityPickup
                .pickupLocation.email,
              doc.data().email,
            ];

            // The stringified version of order
            let orderString = "";
            // The total cost of the order.
            let costTotal = 0;

            // Create the order string and calculate the order's cost.
            modifiedUserPickupsTemp[modification][user].foodList.forEach(
              (orderItem) => {
                orderString = orderString.concat(
                  orderItem.individualDescription.toString(),
                  " ",
                  orderItem.item.toString(),
                  " ",
                  " x",
                  orderItem.quantity.toString(),
                  "<br />",
                );
                costTotal +=
                  (orderItem.quantity * orderItem.price) /
                  (orderItem.distributionQuantity /
                    orderItem.individualQuantity);
              },
            );

            // Call the modifiedPickupEmail cloud Function on firebase.
            modifiedPickupEmail({
              dest,
              costTotal,
              order: orderString,
              firstName,
              modifiedUserOrder: modifiedUserPickupsTemp[modification][user],
            })
              .then(function (result) {
                console.log("The result is", result);
              })
              .catch(function (error) {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                console.error(
                  "There was an error when calling the Cloud Function",
                  error,
                );
                window.alert(
                  "There was an error when calling the Cloud Function:\n\nError Code: " +
                    code +
                    "\nError Message:" +
                    message,
                );
              });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
  });
}
