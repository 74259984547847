// This dialog is used to display when the distribution location is deleting
// items from their food list.  It triggers as soon as they click delete.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

function DeletingItemsBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Deleting Items</DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          The items you selected are being deleted.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

DeletingItemsBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function DeletingItemsDialog(props) {
  const open = props.open;

  return (
    <div>
      <DeletingItemsBox open={open} />
    </div>
  );
}
