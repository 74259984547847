// This is 2/2 dialogs used by distribution locations to send their customers an
// email when they have an item removed.   It displays all the users that were affected
// along with their email and select a greeting.  The user can't send out the emails unless
// thye've selected at least one user.
import React from "react";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

export default function ReviewUserUpdatesEmailDialog({
  userEmails,
  openReviewUserUpdatesEmailDialog,
  setOpenReviewUserUpdatesEmailDialog,
  setStateOfDialog,
  greeting,
  setGreeting,
  checkList,
  setCheckList,
  handleEmailAndOrderRemoval,
}) {
  // If the user closes this dialog then reset the state, checkList and close the dialog.
  const handleClose = () => {
    setOpenReviewUserUpdatesEmailDialog(false);
    setStateOfDialog("selectAction");
    setCheckList({});
  };

  // When the user changes the checklist
  const handleChange = (e) => {
    const target = e.target;
    setCheckList({ ...checkList, [target.name]: target.checked });
  };

  // When the user changes the greeting option.
  const handleGreetingChange = (e) => {
    // eslint-disable-next-line no-unused-vars
    const target = e.target;
    setGreeting(e.target.value);
  };

  // Since we don't want the user moving to the next state until they've selected
  // users then we just check to make sure that condition is met and then set
  // this variable to true to display the Send button.
  let sendButtonVisible = false;
  Object.keys(checkList).forEach((user) => {
    if (checkList[user]) {
      sendButtonVisible = true;
    }
  });

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openReviewUserUpdatesEmailDialog}
      >
        <DialogTitle id="simple-dialog-title">
          What Would You Like To Do?
        </DialogTitle>
        <DialogContent>
          <List>
            <FormLabel component="legend">Select Users to Email</FormLabel>
            <FormGroup>
              {Object.keys(userEmails).map((location, ind) => (
                <React.Fragment key={ind}>
                  <h6>
                    <u>{location}</u>
                  </h6>
                  {Object.keys(userEmails[location]).map((user, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          name={user}
                          checked={checkList[user]}
                          onChange={handleChange}
                        />
                      }
                      label={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmails[location][user],
                      ].join(" ")}
                    />
                  ))}
                </React.Fragment>
              ))}
            </FormGroup>
          </List>
          <FormLabel component="legend">Select a Greeting</FormLabel>
          <RadioGroup
            aria-label="greeting"
            name="greeting"
            value={greeting}
            onChange={handleGreetingChange}
          >
            <FormControlLabel
              value="Dear"
              control={<Radio />}
              label="Dear <First Name>"
            />
            <FormControlLabel
              value="Hi"
              control={<Radio />}
              label="Hi <First Name>"
            />
            <FormControlLabel
              value="Hello"
              control={<Radio />}
              label="Hello <First Name>"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          {sendButtonVisible && (
            <Button color="primary" onClick={handleEmailAndOrderRemoval}>
              SEND
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
