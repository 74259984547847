// This dialog appears when the admin user is editing a volunteer position.
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import UpdateVolunteerPosition from "../../Functions/VolunteerSchedule/UpdateVolunteerPosition.js";
import VolunteerShiftCreationDialog from "./VolunteerShiftCreationDialog.jsx";

// This is the dialog box that opens when a user clicks the edit button
function EditVolunteerJobBox({
  open,
  onClose,
  volunteerPosition,
  index,
  volunteerJobs,
  setVolunteerJobs,
}) {
  // The title of the position.
  const [title, setTitle] = useState(volunteerPosition.title);
  // How frequently we intake new volunteers for this position.
  const [intake, setIntake] = useState(volunteerPosition.intake);
  // When we need volunteers.
  const [availability, setAvailability] = useState(
    volunteerPosition.availability,
  );
  // How often we need voluneers ex. weekly, monthly, etc.
  const [recurrance, setRecurrance] = useState(volunteerPosition.recurrance);
  // description of the role.
  const [description, setDescription] = useState(volunteerPosition.description);
  // Whether this role is active or not.
  const [status, setStatus] = useState(volunteerPosition.status);
  // An icon or image of the task.
  const [image, setImage] = useState(volunteerPosition.image);
  // Where the volunteering occurs.
  const [location, setLocation] = useState(volunteerPosition.location);
  // The reference link to the image used.
  const [imageRefLink, setImageRefLink] = useState(
    volunteerPosition.imageRefLink,
  );
  // The reference title for the a tag.
  const [imageRefTitle, setImageRefTitle] = useState(
    volunteerPosition.imageRefTitle,
  );
  // The text that follows the link reference.
  const [imageRefText, setImageRefText] = useState(
    volunteerPosition.imageRefText,
  );
  // The credit value of doing this shift
  const [creditValue, setCreditValue] = useState(volunteerPosition.creditValue);
  // The shifts available.
  const [shiftsAvailable, setShiftsAvailable] = useState(
    volunteerPosition.shiftsAvailable,
  );

  // We need to make sure that if volunteer position changes then we update the values
  // to the correct ones.
  useEffect(() => {
    setTitle(volunteerPosition.title);
    setIntake(volunteerPosition.intake);
    setAvailability(volunteerPosition.availability);
    setRecurrance(volunteerPosition.recurrance);
    setDescription(volunteerPosition.description);
    setStatus(volunteerPosition.status);
    setImage(volunteerPosition.image);
    setLocation(volunteerPosition.location);
    setImageRefLink(volunteerPosition.imageRefLink);
    setImageRefTitle(volunteerPosition.imageRefTitle);
    setImageRefText(volunteerPosition.imageRefText);
    setCreditValue(volunteerPosition.creditValue);
    setShiftsAvailable(volunteerPosition.shiftsAvailable);
  }, [volunteerPosition]);
  // If the user closes then close the dialog and if they cancel
  // their changes then set everything back to what the original values were
  // sent in through the volunteerPosition.
  const handleClose = (cancel) => {
    onClose(false);
    if (cancel === "cancel") {
      setTitle(volunteerPosition.title);
      setIntake(volunteerPosition.intake);
      setAvailability(volunteerPosition.availability);
      setRecurrance(volunteerPosition.recurrance);
      setDescription(volunteerPosition.description);
      setStatus(volunteerPosition.status);
      setImage(volunteerPosition.image);
      setLocation(volunteerPosition.location);
      setImageRefLink(volunteerPosition.imageRefLink);
      setImageRefTitle(volunteerPosition.imageRefTitle);
      setImageRefText(volunteerPosition.imageRefText);
      setCreditValue(volunteerPosition.creditValue);
      setShiftsAvailable(volunteerPosition.shiftsAvailable);
    }
  };

  // If the user is deleting a shift.
  const handleDeleteShift = (index) => {
    const shiftsAvailableTemp = [...shiftsAvailable];
    shiftsAvailableTemp.splice(index, 1);
    setShiftsAvailable([...shiftsAvailableTemp]);
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();
    // Creates a dictionary of the volunteer position to be saved to the database.
    const volunteerPositionNew = {
      title,
      intake,
      availability,
      recurrance,
      description,
      status,
      image,
      location,
      imageRefLink,
      imageRefTitle,
      imageRefText,
      creditValue,
      shiftsAvailable,
    };
    // Updates the database the new position.
    UpdateVolunteerPosition(
      volunteerPositionNew,
      volunteerPosition.title,
      volunteerJobs,
      setVolunteerJobs,
      index,
    );

    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Edit information about the position
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <span>
            <TextField
              margin="dense"
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="location"
              label="Location"
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="creditValue"
              label="Credit Per Shift"
              name="creditValue"
              type="number"
              value={creditValue}
              onChange={(e) => setCreditValue(parseFloat(e.target.value))}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="intake"
              label="Intake"
              name="intake"
              value={intake}
              onChange={(e) => setIntake(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="availability"
              label="Availablity"
              name="availability"
              value={availability}
              onChange={(e) => setAvailability(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="recurrance"
              label="Recurrance"
              name="recurrance"
              value={recurrance}
              onChange={(e) => setRecurrance(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="description"
              label="Description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="image"
              label="Icon"
              name="image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="imageRefLink"
              label="Reference to Image Link"
              name="imageRefLink"
              value={imageRefLink}
              onChange={(e) => setImageRefLink(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="imageRefTitle"
              label="Reference to Image Title"
              name="imageRefTitle"
              value={imageRefTitle}
              onChange={(e) => setImageRefTitle(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="imageRefText"
              label="Reference to Image Text"
              name="imageRefText"
              value={imageRefText}
              onChange={(e) => setImageRefText(e.target.value)}
              fullWidth
            />
            <VolunteerShiftCreationDialog
              shiftsAvailable={shiftsAvailable}
              setShiftsAvailable={setShiftsAvailable}
            />
            {shiftsAvailable !== undefined &&
              shiftsAvailable.map((shift, index) => (
                <div key={index}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <h5 style={{ margin: "0px" }}>
                      <u>Shift {index + 1}</u>
                    </h5>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={() => handleDeleteShift(index)}
                    >
                      Delete
                    </Button>
                  </div>
                  <p>Day of the Week : {shift.DayOfTheWeek}</p>
                  <p>Start Time : {shift.StartTime}</p>
                  <p>End Time : {shift.EndTime}</p>
                  <p>Frequency : {shift.Frequency}</p>
                </div>
              ))}

            <FormControl>
              <FormLabel id="radio-buttons-group-status">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                value={status}
                onChange={(e) => setStatus(!status)}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </FormControl>
          </span>
          <DialogActions>
            <Button onClick={() => handleClose("cancel")} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EditVolunteerJobBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EditVolunteerJobDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        {props.volunteerPosition.title} <EditIcon />
      </IconButton>
      <EditVolunteerJobBox
        open={open}
        onClose={handleClose}
        volunteerPosition={{ ...props.volunteerPosition }}
        index={props.index}
        volunteerJobs={props.volunteerJobs}
        setVolunteerJobs={props.setVolunteerJobs}
      />
    </div>
  );
}
