// This is a dialog that pops up when a user tries to delete a food item from
// the admin marketplace page page.   It will pop to confirm the user meant to
// delete the food item and warn them the action can't be undone.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

function RemoveFoodItemBox(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleRemoveFoodItem = () => {
    props.handleDelete();
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Confirm Item Removal</DialogTitle>
      <DialogContent>
        Please confirm you wish to delete this food item. This action cannot be
        undone.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleRemoveFoodItem}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RemoveFoodItemBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function RemoveFoodItemDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="Order-Again-Button">
        <button className="Delete-Button" onClick={handleClickOpen}>
          x
        </button>
      </div>

      <RemoveFoodItemBox
        open={open}
        onClose={handleClose}
        handleDelete={props.handleDelete}
      />
    </div>
  );
}
