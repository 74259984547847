// This is called by CommunityMembersAccordian.jsx when a community host chooses to
// approve a community member to pick up from their community location.
import firebase from "../../../components/Firebase.js";

export default function ApproveCommunityMember(
  communityMembers,
  setCommunityMembers,
  communityMember,
  userInfo,
  personalMessage,
  setSendingEmail,
  setOpen,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Set the approved community members array.
  const approvedCommunityMembers = [];
  // Set the community members to a temporary dictionary.  {firstName-lastName-userId : boolean}
  const communityMembersTemp = { ...communityMembers };

  // Cycle through the community memebers and add any members that are approved
  // already.
  Object.keys(communityMembers).forEach((member, i) => {
    if (
      communityMembers[member] &&
      !approvedCommunityMembers.includes(member)
    ) {
      approvedCommunityMembers.push(member.split("-")[2]);
    }
  });

  // Now check if this community member has already been approved if not then add them.
  if (!approvedCommunityMembers.includes(communityMember.split("-")[2])) {
    approvedCommunityMembers.push(communityMember.split("-")[2]);
  }

  // Update the database.
  const documentName = [
    userInfo.firstName,
    userInfo.lastName,
    userInfo.userId,
  ].join("");

  const communityLocationsDocRef = database
    .collection("CommunityLocations")
    .doc(documentName);
  const userDocRef = database
    .collection("Users")
    .doc(userInfo.userId)
    .collection("CommunityHosts")
    .doc("CommunityMembers");

  batch.update(communityLocationsDocRef, {
    approvedCommunityMembers,
  });

  batch.update(userDocRef, {
    [communityMember]: true,
  });

  // Update the hooks.
  communityMembersTemp[communityMember] = true;

  // Sets the email cloud function.
  const customMessageToUsers = firebase
    .functions()
    .httpsCallable("customMessageToUsers");
  // Grabs the user Id of the user being approved.  We will use their userId to look
  // up their email.
  const userId = communityMember.split("-")[2];

  // Set the doc reference to the user being approved.
  const newUserDocRef = database.collection("Users").doc(userId);

  newUserDocRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // Sets the destination of the email to the user and the distribution location.
        const dest = [userInfo.email, doc.data().email];
        // Call the customMessageToUsers cloud Function on firebase to send the email.
        customMessageToUsers({
          dest,
          subject: "Approved for Community Hub",
          greeting: "Hi",
          firstName: communityMember.split("-")[0],
          customMessage: personalMessage,
        })
          .then(function (result) {
            // Save the changes to the database.
            batch.commit();
            // Update the state and the screen.
            setCommunityMembers({ ...communityMembersTemp });
          })
          .catch(function (error) {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            console.error(
              "There was an error when calling the Cloud Function",
              error,
            );
            window.alert(
              "There was an error when calling the Cloud Function:\n\nError Code: " +
                code +
                "\nError Message:" +
                message,
            );
          });
      } else {
        setSendingEmail(false);
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((error) => {
      setSendingEmail(false);
      console.log("Error getting document:", error);
    });
}
