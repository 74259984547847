// This returns an array of all the categories of a given marketplace.
export default function LoadCategories(pickupLocationDetails, farmCategories) {
  // The array that will be returned with all the categories.
  const marketplaceCategoriesTemp = [];
  // If the user is an individual than they'll have the pickupLocationDetails. Otherwise
  // it will be the distributionLocation or the Admin who don't have pickupLocationDetails.
  if (pickupLocationDetails.categories) {
    // Cycles through the pickupLocationDetails categories and find each farm
    Object.keys(pickupLocationDetails.categories).forEach((farm) => {
      // Cycle through each category in the array.
      pickupLocationDetails.categories[farm].forEach((category) => {
        // If the category was already added skip otherwise add it.
        if (!marketplaceCategoriesTemp.includes(category)) {
          marketplaceCategoriesTemp.push(category);
        }
      });
    });
  }
  // This happens when it is being used by the Admin, distributionLocation users
  else if (farmCategories) {
    // Cycle through all the farm categories.
    Object.keys(farmCategories).forEach((farm) => {
      // Cycle through each category in the array.
      farmCategories[farm].forEach((category) => {
        // If the category was already added skip otherwise add it.
        if (!marketplaceCategoriesTemp.includes(category)) {
          marketplaceCategoriesTemp.push(category);
        }
      });
    });
  }

  return marketplaceCategoriesTemp;
}
