// This is called by when a user is sorting their list of foodItems.  It takes in the type
// of sorting that they want to do and sorts the food.
import CompareItemNames from "../../../functions/CompareItemNames.js";

// This function compares the visibility of the items for both admin and distribution location orders.
const CompareVisibilities = (item1, item2, userInfo) => {
  // First set the default to if the user is admin and they are
  // checking if it visible.
  let itemName1 = item1.adminVisible;
  let itemName2 = item2.adminVisible;

  // If the user is a distribution location then we have to check if the location
  // is included in the array for the item.
  if (userInfo.userType === "distributionLocation") {
    itemName1 = item1.distributionLocationVisible.includes(userInfo.userId);
    itemName2 = item2.distributionLocationVisible.includes(userInfo.userId);
  }
  // Compare the two items.
  return itemName2 - itemName1;
};

export default function HandleFoodItemsSort(
  value,
  foodItems,
  setFoodItems,
  setSortBy,
  userInfo,
) {
  // Set the temp variable to update.
  const foodItemsTemp = [...foodItems];

  // Check what kind of sorting the user wants to do and compare.
  if (value === "farmNameAsc") {
    foodItemsTemp.sort((a, b) => {
      return CompareItemNames(a, b, "farmName");
    });
  } else if (value === "farmNameDesc") {
    foodItemsTemp.sort((a, b) => {
      return CompareItemNames(b, a, "farmName");
    });
  } else if (value === "itemNameAsc") {
    foodItemsTemp.sort((a, b) => {
      return CompareItemNames(a, b, "item");
    });
  } else if (value === "itemNameDesc") {
    foodItemsTemp.sort((a, b) => {
      return CompareItemNames(b, a, "item");
    });
  } else if (value === "visibilityAsc") {
    foodItemsTemp.sort((a, b) => {
      return CompareVisibilities(a, b, userInfo);
    });
  } else if (value === "visibilityDesc") {
    foodItemsTemp.sort((a, b) => {
      return CompareVisibilities(b, a, userInfo);
    });
  }
  // Set the foodList they need to update.
  setFoodItems([...foodItemsTemp]);
  // Make sure the sortBy value is the value they sent in.
  setSortBy(value);
}
