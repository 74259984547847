// This is a form that the user uses to send in their application to become a community
// pickup location.  It will update the database and then also send emails to distribution
// location and the user applying.
import React from "react";
import firebase from "../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DistributionLocationCard from "../../components/Cards/DistributionLocationCard.jsx";
import ApplicationEmails from "../../pages/MyAccountPage/Functions/ApplicationEmails.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SimpleSelect from "../../components/userInputs/DropDownMenu.js";

export default function CommunityHubApplicationForm({
  pickupLocations,
  userInfo,
  setSendingEmail,
  handleUserInfoChange,
  handleClose,
  partnerLocation,
  setPartnerLocation,
  communityHubType,
  setCommunityHubType,
  locationName,
  setLocationName,
  address,
  setAddress,
  obscuredAddress,
  setObscuredAddress,
  maxParticipants,
  setMaxParticipants,
  availableTimes,
  setAvailableTimes,
  additionalComments,
  setAdditionalComments,
}) {
  // Save sthe application and sends it off once the user is done applying.
  const handleSave = (e) => {
    e.preventDefault();
    // Sets the circular loading to true.
    setSendingEmail(true);
    const database = firebase.firestore();
    const batch = database.batch();

    // Set the distributionLocation to the user's pickup location
    const distributionLocation = pickupLocations[partnerLocation];
    if (distributionLocation !== undefined) {
      // Update the database.
      const documentName = (
        userInfo.firstName +
        userInfo.lastName +
        firebase.auth().currentUser.uid
      ).replace(/\s+/g, "");
      const docRef = database
        .collection("CommunityLocations")
        .doc(documentName);
      const communityLocation = {
        locationName,
        partnerLocation,
        communityHubType,
        address,
        obscuredAddress,
        maxParticipants,
        availableTimes,
        additionalComments,
        userId: firebase.auth().currentUser.uid,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        pickupLocation: distributionLocation,
        distributionLocationName: distributionLocation.name,
        distributionLocationuserId: distributionLocation.userId,
        email: userInfo.email,
        secretCode: userInfo.userId.slice(0, 9),
        approved: false,
      };
      batch.set(docRef, communityLocation);

      batch.commit();

      // Updates the userInfo to include the new changes.
      const userInfoTemp = { ...userInfo };
      // Add the new Community location to the userInfo.
      userInfoTemp.communityLocation = communityLocation;
      handleUserInfoChange({ ...userInfoTemp }, true);
      console.log("Successfully wrote to database!");
      // Send an application email.
      ApplicationEmails(
        userInfo,
        communityLocation,
        setSendingEmail,
        distributionLocation,
      );
      handleClose();
    } else {
      alert(
        'Please select a distribution location to partner with first by clicking "CHANGE".',
      );
    }
  };

  return (
    <div>
      <p>
        The following information will be sent to your partnered distribution
        location. They will review your application to determine your
        eligibility and then contact you. None of this information will be
        shared online until the distribution location contacts you and asks for
        your permission. Thank you for supporting your community!
      </p>
      <form onSubmit={handleSave}>
        <span>
          <FormLabel component="legend">
            Select a Partner Distribution Location
          </FormLabel>
          {Object.keys(pickupLocations).length > 0 && (
            <SimpleSelect
              margin="dense"
              id="selectOrderStatsLocation"
              options={Object.keys(pickupLocations)}
              handleChange={(e) => setPartnerLocation(e.target.value)}
              selected={partnerLocation}
            />
          )}
          <DistributionLocationCard
            pickupLocation={pickupLocations[partnerLocation]}
          />

          <FormLabel component="legend">Community Hub Type</FormLabel>
          <RadioGroup
            aria-label="communityHubType"
            name="communityHubType"
            value={communityHubType}
            onChange={(e) => setCommunityHubType(e.target.value)}
          >
            <FormControlLabel
              value="publicApproval"
              control={<Radio />}
              label="Public, Approval Required"
            />
            <FormControlLabel
              value="private"
              control={<Radio />}
              label="Private Hidden"
            />
            <FormControlLabel
              value="publicNoApproval"
              control={<Radio />}
              label="Public, No Approval Required"
            />
          </RadioGroup>

          <TextField
            margin="dense"
            id="locationName"
            label="Location name"
            name="locationName"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="address"
            label="Your address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="obscuredAddress"
            label="Your obscured address for the public to see, ex. cross streets."
            name="obscuredAddress"
            value={obscuredAddress}
            onChange={(e) => setObscuredAddress(e.target.value)}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="maxParticipants"
            label="Maximum participants allowed at your location per week"
            name="maxParticipants"
            type="number"
            value={maxParticipants}
            onChange={(e) => setMaxParticipants(e.target.value)}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="availableTimes"
            label="What are the available times for others to pickup?"
            name="availableTimes"
            value={availableTimes}
            onChange={(e) => setAvailableTimes(e.target.value)}
            fullWidth
            required
          />
          <TextField
            id="additionalComments"
            label="Additional comments or special instructions for pick up?"
            placeholder="Questions, details about pickup spot, such as, sun exposure, accessiblity, etc."
            maxRows={5}
            multiline
            variant="outlined"
            name="additionalComments"
            value={additionalComments}
            onChange={(e) => setAdditionalComments(e.target.value)}
            fullWidth
          />
        </span>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Apply
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}
