// This function is used to take in the community orders and the hook setters for
// CommunityfoodItems, CommunityfarmerInfo, communityDonations, and communitySubsidies.
// It combines all the orders of the customers into one order and ensures that all the
// the farms are accounted for.
// The last argument is to tell the function if the call is coming from the MyAccount
// or the basket page.  If it is coming from the MyAccount is won't set the values
// of the hooks
export default function AggregateCustomerList(
  communityOrders,
  setCommunityFoodItems,
  setCommunityFarmerInfo,
  setCommunityDonations,
  setCommunitySubsidies,
  myAccountCaller,
  setPantryFoodItems,
) {
  // The total subisidies of all community orders
  let subsidies = 0;
  // The total donations of all community orders
  let donations = 0;
  // The food list in a Dictionary. Will be used to combine order
  const foodDict = {};
  // The food list in an array. Will be used to combine order
  const foodArray = [];
  // The farm list in an Dictionary. Will be used to combine order
  const farmDict = {};
  // The farm list in an array. Will be used to combine order
  const farmArray = [];
  // The pantry list in a Dictionary. Will be used to combine order
  const pantryFoodDict = {};
  // A list of food that was taken from the pantry
  const pantryFoodArray = [];

  // Take the communityOrders that was taken from the database and get each of
  // their keys.
  Object.keys(communityOrders).forEach((customer) => {
    // Create a customer Order equal to this will be the new community orders
    // with all the orders properly aggregated.
    const customerOrder = { ...communityOrders[customer] };
    // Checks to see if the user donated or was subsidized and then adds that value
    // depending on the contribution
    if (customerOrder.customerContribution === "subsidy") {
      subsidies += customerOrder.donationSubsidy;
    } else {
      donations += customerOrder.donationSubsidy;
    }

    // Dictionaries were the easiest way to combine things since the array's are
    // just placed in random arrays

    // Creates a dictionary of the farm list.  If the farm has already been added
    // it's just overwritten with the same farm information.
    customerOrder.farmerList.forEach((farm) => {
      farmDict[farm.farmName] = { ...farm };
    });

    // Creates a dictionary of the food list from the community's orders.  If the item
    // has already been added it updates the quantity to reflect the new amount.  If the
    // item is a pantry item then do the same but with the pantryFoodItems.
    customerOrder.foodList.forEach((food) => {
      // Check to see if the item is from the pantry.
      if (food.limit) {
        // Check to see if the item is already in the dictionary.
        if (food.item + food.description + food.farmName in pantryFoodDict) {
          // Update the quantity to update the exisiting foodItem.
          pantryFoodDict[
            food.item + food.description + food.farmName
          ].quantity += food.quantity;
          // If the limit is less then the existing foodItem then update as that means this user
          // placed their order after the other user and their limit is more accurate.
          if (
            pantryFoodDict[food.item + food.description + food.farmName].limit <
            food.limit
          ) {
            // update the limit.
            pantryFoodDict[food.item + food.description + food.farmName].limit =
              food.limit;
          }
        }
        // If the item doesn't exist then add the foodItem.
        else {
          pantryFoodDict[food.item + food.description + food.farmName] = {
            ...food,
          };
        }
      }
      // If the limit doesn't exist then it is a foodItem.
      else {
        // Check if the foodItem already exists
        if (food.item + food.description + food.farmName in foodDict) {
          // Update the quantity to include the new item.
          foodDict[food.item + food.description + food.farmName].quantity +=
            food.quantity;
        } else {
          // Add the new item to the dictionary.
          foodDict[food.item + food.description + food.farmName] = { ...food };
        }
      }
    });
  });

  // Update the farm array so that is the same as the dictionary and includes
  // all the farms
  Object.keys(farmDict).forEach((farm) => {
    farmArray.push(farmDict[farm]);
  });

  // Update the food array so that is the same as the dictionary and includes
  // all the food items
  Object.keys(foodDict).forEach((food) => {
    foodArray.push(foodDict[food]);
  });

  // Update the pantry food array so that is the same as the dictionary and includes
  // all the food items
  Object.keys(pantryFoodDict).forEach((food) => {
    pantryFoodArray.push(pantryFoodDict[food]);
  });

  // If this call came from the basket page then the program will update the hooks
  // on the basket page with these new values.  Otherwise the only information
  // needed are the donations and the subsisides for the myAccount page.
  if (!myAccountCaller) {
    setCommunityFoodItems(foodArray);
    setCommunityFarmerInfo(farmArray);
    setCommunityDonations(donations);
    setCommunitySubsidies(subsidies);
    setPantryFoodItems(pantryFoodArray);
    return [donations, subsidies, foodArray, pantryFoodArray];
  } else {
    return [donations, subsidies, foodArray, pantryFoodArray];
  }
}
