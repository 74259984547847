// The Distribution Location's Marketplace header information displayed.  Includes
// the marketplace, and the information about the Distribution Location.
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import NextPickupDay from "../../../functions/NextPickupDay.js";
// eslint-disable-next-line no-unused-vars
import OrderCutoffPopover from "./OrderCutoffPopover.jsx";

export default function DistributionLocationMarketplaceHeaderInformation({
  userInfo,
}) {
  // If the user has selected a pickupLocation
  if (userInfo !== undefined) {
    let orderCutoffDate = "no date";
    // Provides the date of the pickup day for the user.
    let pickupDate = "no date";
    // Provides the date of the order cutoff for the user.
    orderCutoffDate = moment(
      NextPickupDay(userInfo.orderCutoff),
      "MM-DD-YYYY",
    ).format("MMMM D Y");
    // Provides the date of the pickup day for the user.
    pickupDate = moment(NextPickupDay(userInfo.pickupDay), "MM-DD-YYYY").format(
      "MMMM D Y",
    );
    return (
      <Grid
        container
        className="Marketplace-Grid-Header"
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          alignItems: "baseline",
        }}
      >
        <Grid item xs>
          <h1 className="Title-Marketplace">Marketplace</h1>
        </Grid>
        <Grid item xs>
          {userInfo !== undefined && (
            <div className="Location-Title-And-Details">
              <span
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  minWidth: "400px",
                }}
              >
                <h1 className="Pickup-Location-Title">
                  {userInfo.organisationName}
                </h1>
              </span>
              <span className="Distribution-Location-Details">
                <p style={{ margin: "0px" }}>
                  <b>Address :</b> {userInfo.restaurantAddress}
                </p>
                <p style={{ margin: "5px 0px" }}>
                  <b>Order Cutoff :</b> {userInfo.orderCutoff},{" "}
                  {orderCutoffDate}
                </p>
                <p style={{ margin: "0px" }}>
                  <b>Pickup Time :</b> {userInfo.pickupDay}{" "}
                  {userInfo.pickupTime}, {pickupDate}
                </p>
                <p style={{ margin: "5px 0px" }}>
                  <b>Maximum Subsidy Per Order :</b> ${userInfo.subsidyAmount}
                </p>
              </span>
            </div>
          )}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}
