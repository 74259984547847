// This is C3/3 dialogs used by distribution locations to send their customers a
// order payment reminder email.   It displays the custom email message, the user's order and the greeting and
// asks the distribution location to confirm before sending out the emails.  It will
// then attach the order to each email.
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import firebase from "../../../../components/Firebase.js";
import OrderPaymentReminder from "./functions/OrderPaymentReminder.js";
import batchEmails from "../../../../functions/Email/BatchEmailer.js";

// This function is used to send in to the email batcher to help send off
// the emails.
export async function PaymentReminderEmails(item) {
  const [
    userEmailsNoLocation,
    user,
    setSendingEmails,
    greeting,
    userPaymentReminderSubjectLines,
    userPaymentReminderMessages,
    setOpenConfirmOrderPaymentReminderDialog,
    setCheckList,
    setStateOfDialog,
  ] = item;

  try {
    // Sets the email cloud function
    const customMessageToUsers = firebase
      .functions()
      .httpsCallable("customMessageToUsers");

    // Sets the destination of the email to the user's email.
    const dest = [userEmailsNoLocation[user]];
    // Sets this to true to display the loading dialog while emails are sent
    setSendingEmails(true);

    // calls the cloud function
    await customMessageToUsers({
      dest,
      subject: userPaymentReminderSubjectLines[user],
      greeting,
      firstName: user.split("-")[0],
      customMessage: userPaymentReminderMessages[user],
    });
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    console.error("There was an error when calling the Cloud Function", error);
    window.alert(
      "There was an error when calling the Cloud Function:\n\nError Code: " +
        code +
        "\nError Message:" +
        message,
    );
    setOpenConfirmOrderPaymentReminderDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
    setSendingEmails(false);
  }
}

export default function ConfirmOrderPaymentReminderDialog({
  openConfirmOrderPaymentReminderDialog,
  setOpenConfirmOrderPaymentReminderDialog,
  stateOfDialog,
  setStateOfDialog,
  userEmails,
  checkList,
  setCheckList,
  greeting,
  message,
  userInfo,
  setSendingEmails,
  communityMembers,
}) {
  // This hook will store the user and their custom message {firstName-lastName-userId : customMessage + order}
  const [userPaymentReminderMessages, setPaymentReminderMessages] = useState(
    {},
  );
  // This hook will store the user and their custom subject Line {firstName-lastName-userId : Order Payment Reminder for $date}
  const [userPaymentReminderSubjectLines, setUserPaymentReminderSubjectLines] =
    useState({});

  // If the user hits the back button then it will clear their checkList and set the
  // the current state (value) to "OrderPaymentReminder" where they will write their message and
  // choose who to email.
  const handleBack = (value) => {
    setOpenConfirmOrderPaymentReminderDialog(false);
    setStateOfDialog(value);
    setCheckList({});
  };
  // If the user close the dialog then reset the state of this dialog series to
  // "selectAction" and clear the checkList.
  const handleClose = () => {
    setOpenConfirmOrderPaymentReminderDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
  };

  // Since we have the imported orders the userEmails is a dictionary like this
  // {location : {user} : email}.  We don't need the location on this dialog so this
  // is a dictionary with just the {user : email}
  const userEmailsNoLocation = {};

  // Cycle through the userEmails to remove the locations and just have the {user : email}
  Object.keys(userEmails).forEach((location) => {
    Object.keys(userEmails[location]).forEach((user) => {
      userEmailsNoLocation[user] = userEmails[location][user];
    });
  });

  // If the user confirms they wish to send the emails.
  const handleConfirm = async () => {
    // Creates the batch of emails to send off.
    const emailBatch = [];
    // Create an object of the userPaymentReminderMessages to cycle through.
    const users = Object.keys(userPaymentReminderMessages);
    // Cycle through the user's with a drop off message.
    for (const user of users) {
      emailBatch.push([
        userEmailsNoLocation,
        user,
        setSendingEmails,
        greeting,
        userPaymentReminderSubjectLines,
        userPaymentReminderMessages,
        setOpenConfirmOrderPaymentReminderDialog,
        setCheckList,
        setStateOfDialog,
      ]);
    }

    // Call the batch email function that will handle the delays necessary
    // to sending the emails off without overloading the send mail function.
    await batchEmails({
      batchedParamsForEmailFunc: emailBatch,
      singularEmailFunc: PaymentReminderEmails,
    });

    // Close the dialog
    setOpenConfirmOrderPaymentReminderDialog(false);
    // Reset the checklist.
    setCheckList({});
    // reset the state.
    setStateOfDialog("selectAction");
    // Close the sending emails progress dialog
    setSendingEmails(false);
  };

  // When the state of the dialog is set to ConfirmOrderPaymentReminder then we will
  // create the new order payment reminder message with the custom message included.
  useEffect(() => {
    if (stateOfDialog === "ConfirmOrderPaymentReminder") {
      OrderPaymentReminder(
        message,
        communityMembers,
        checkList,
        setPaymentReminderMessages,
        setUserPaymentReminderSubjectLines,
        userInfo,
      );
    }
  }, [stateOfDialog]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openConfirmOrderPaymentReminderDialog}
      >
        <DialogTitle id="simple-dialog-title">Confirm Emails</DialogTitle>
        <DialogContent>
          <List>
            <h6>
              <u>Users being emailed</u>
            </h6>
            {Object.keys(checkList).map((user, i) => (
              <span key={i}>
                {checkList[user] && (
                  <ListItem autoFocus>
                    <ListItemText
                      primary={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmailsNoLocation[user],
                      ].join(" ")}
                    />
                  </ListItem>
                )}
              </span>
            ))}
          </List>

          <h6>
            <u>Custom Message</u>
          </h6>
          <p>
            {greeting} "First Name",
            <br />
            {message}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleBack("OrderPaymentReminder")}
          >
            BACK
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            SEND
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
