// This is used to calculate the volunteer Credits per location.
export default function CalculateVolunteerCredits(
  communityOrders,
  importedOrder,
  selectedDistributionLocation,
  location,
) {
  // The volunteer Credits per location.
  const volunteerCredits = {};

  // The total volunteer Credits at all locations.
  let totalVolunteerCredits = 0;

  // Set the current location's volunteer Credits to 0
  volunteerCredits[location] = 0;
  // Cycle through the community orders of this location.
  Object.keys(communityOrders).forEach((customerOrder) => {
    // If the volunteer Credits exists and it is more that nothing.
    if (parseFloat(communityOrders[customerOrder].creditPortion) > 0) {
      // Add the volunteer Credits amount to the location's total
      volunteerCredits[location] += parseFloat(
        communityOrders[customerOrder].creditPortion,
      );
      // Add the volunteer Credits amount to the total of all locations.
      totalVolunteerCredits += parseFloat(
        communityOrders[customerOrder].creditPortion,
      );
    }
  });

  // Cycle through the imported locations
  Object.keys(importedOrder).forEach((importedLocation) => {
    // Set importedCommunityOrders so it easy to access this location's communityOrders.
    const importedCommunityOrders =
      importedOrder[importedLocation].communityOrders;
    // Initialise the current location's volunteer Credits to 0
    volunteerCredits[importedLocation] = 0;
    // Cycle through the community orders of each imported location.
    Object.keys(importedCommunityOrders).forEach((customerOrder) => {
      // If the volunteer Credits exists and it is more that nothing.
      if (
        parseFloat(importedCommunityOrders[customerOrder].creditPortion) > 0
      ) {
        // Add the volunteer Credits amount to the location's total
        volunteerCredits[importedLocation] += parseFloat(
          importedCommunityOrders[customerOrder].creditPortion,
        );
        // Add the volunteer Credits amount to the total of all locations.
        totalVolunteerCredits += parseFloat(
          importedCommunityOrders[customerOrder].creditPortion,
        );
      }
    });
  });

  // Set the total in the map to the total package fees.
  volunteerCredits.Total = totalVolunteerCredits;

  return volunteerCredits;
}
