// This is a dialog that the user uses to enter their secret code.  This will then
// show them if there is a location that matches this secret code and allow them to
// join the location without any appovals.
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CommunityLocationCardSelectionPage from "../Components/CommunityLocationCardSelectionPage.jsx";

export default function EnterSecretCodeDialog({
  pickupLocations,
  communityDistributionLocations,
  privateCommunityDistributionLocations,
  setCommunityDistributionLocations,
  setPrivateCommunityDistributionLocations,
}) {
  // Whether the dialog is open or not.
  const [open, setOpen] = useState(false);
  // The secret code entered by the user.
  const [secretCode, setSecretCode] = useState("");
  // The secret location if there is one that matches the secret code.
  const [secretLocation, setSecretLocation] = useState({});
  // While the searching is being done after the user entered the secret code.
  const [searchingDone, setSearchingDone] = useState(false);

  // Combine the communityLocations and the private locations into one list to
  // easily sift through for the code.
  const allCommunityLocations = communityDistributionLocations.concat(
    privateCommunityDistributionLocations,
  );

  const handleClose = () => {
    setOpen(false);
    setSecretCode("");
    setSecretLocation({});
    setSearchingDone(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // The secret location if there is one.
    let secretLocation = {};

    // Find the index of the secret location that matches the one entered by the user.
    const indexOfCommunityLocation = allCommunityLocations.findIndex(
      (communityLocation) => {
        return communityLocation.secretCode === secretCode;
      },
    );
    // If there is a secret code then we want to update the hook that is secretLocation
    // and then that will be sent to the card that displays the information about the
    // secret location and allows the user to decide if they want to add it.
    if (indexOfCommunityLocation !== -1) {
      secretLocation = allCommunityLocations[indexOfCommunityLocation];
      setSecretLocation(secretLocation);
    } else {
      setSecretLocation("");
    }
    // After this is all done we know the search is done
    setSearchingDone(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        ENTER YOUR SECRET CODE HERE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Enter the code your friend gave you to access their pickup location.
        </DialogTitle>
        <DialogContent>
          <p>
            A community host can share their secret code which automatically
            provides access to their location without approval. It also allows
            you to access secret locations that are hidden.
          </p>
          <form onSubmit={handleSearch}>
            <span>
              <TextField
                margin="dense"
                color="primary"
                variant="outlined"
                id="secretCode"
                label="Secret Code"
                name="secretCode"
                value={secretCode}
                onChange={(e) => setSecretCode(e.target.value)}
                fullWidth
                required
              />
              {searchingDone && (
                <CommunityLocationCardSelectionPage
                  secretLocation={secretLocation}
                  communityDistributionLocations={
                    communityDistributionLocations
                  }
                  privateCommunityDistributionLocations={
                    privateCommunityDistributionLocations
                  }
                  setCommunityDistributionLocations={
                    setCommunityDistributionLocations
                  }
                  setPrivateCommunityDistributionLocations={
                    setPrivateCommunityDistributionLocations
                  }
                />
              )}
            </span>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                CANCEL
              </Button>
              <Button type="submit" color="primary">
                SEARCH
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
