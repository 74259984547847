// This is the dialog that appears on a community member's user receipts and when clicked shows their answers to
// the questionnaire.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DisplayAnswersHomePage from "../Components/DisplayAnswersHomePage.jsx";
import PropTypes from "prop-types";

// Displays the different answers.
function DisplayAnswersBox({
  open,
  onClose,
  indexOfQuestionnaire,
  userId,
  questionnaireAnswers,
}) {
  const questionnaireAnswerSheet = {
    ...questionnaireAnswers[indexOfQuestionnaire],
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="questionaire-answer-dialog"
        aria-describedby="questionaire-answer-dialog"
      >
        <DialogTitle
          id="questionnaire-dialog-title"
          style={{ paddingTop: 6, paddingBottom: 0 }}
        >
          {questionnaireAnswerSheet.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="questionnaire-dialog-description">
            {questionnaireAnswerSheet.description}
          </DialogContentText>
          <div>
            {questionnaireAnswerSheet.questions.map(
              (question, indexOfQuestion) => (
                <DisplayAnswersHomePage
                  key={indexOfQuestion}
                  question={question}
                  indexOfQuestion={indexOfQuestion}
                  userAnswers={questionnaireAnswerSheet.answers[userId]}
                />
              ),
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DisplayAnswersBox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  indexOfQuestionnaire: PropTypes.number,
  userId: PropTypes.string,
  questionnaireAnswers: PropTypes.array,
};

export default function DisplayAnswersDialog({
  indexOfQuestionnaire,
  userId,
  questionnaireAnswers,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{ margin: "2px" }}
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
      >
        ANSWERS TO QUESTIONNAIRE {indexOfQuestionnaire + 1}
      </Button>

      <DisplayAnswersBox
        open={open}
        onClose={handleClose}
        indexOfQuestionnaire={indexOfQuestionnaire}
        userId={userId}
        questionnaireAnswers={questionnaireAnswers}
      />
    </div>
  );
}

DisplayAnswersDialog.propTypes = {
  indexOfQuestionnaire: PropTypes.number,
  userId: PropTypes.string,
  questionnaireAnswers: PropTypes.array,
};
