// This is used the questionnaire for a distribution location.  It will give them the option to turn
// on or off the questionnaire or expand the accordion to edit it or delete it.
import React, { useContext } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DisplayQuestionDistributionLocation from "../Questionnaires/DisplayQuestionDistributionLocation.jsx";
import EditQuestionnaireDialog from "../../Dialogs/Questionnaires/EditQuestionnaireDialog.jsx";
import DeleteQuestionnaireDialog from "../../Dialogs/Questionnaires/DeleteQuestionnaireDialog.jsx";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import EnableQuestionnaire from "../../Functions/Questionnaires/EnableQuestionnaire.js";
import { AuthContext } from "../../../../components/authentication/Auth.js";
import PropTypes from "prop-types";

// This renders the accordian
export default function QuestionnaireAccordion({
  questionnaire,
  indexOfQuestionnaire,
  questionnaires,
  setQuestionnaires,
}) {
  // Get the current user data.
  // eslint-disable-next-line no-unused-vars
  const { currentUser, userInfo, handleUserInfoChange } =
    useContext(AuthContext);

  // Enable the questionnaire if this is true then the switch will be turned on.
  let questionnaireEnabled = false;

  // If the current questionnaire exists in the current user's questionnaire list then
  // set that it is enabled to true.
  if (userInfo.questionnaireList !== undefined) {
    const index = Object.keys(userInfo.questionnaireList).indexOf(
      questionnaire.createdDate,
    );

    if (index !== -1) {
      questionnaireEnabled = true;
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{questionnaire.title}</Typography>
        <FormControlLabel
          label="Turn on Questionnaire"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <Switch
              checked={questionnaireEnabled}
              onChange={() =>
                EnableQuestionnaire(
                  questionnaire,
                  userInfo,
                  handleUserInfoChange,
                  questionnaireEnabled,
                )
              }
              name="TurnOnQuestionnaireEnabled"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
        />
      </AccordionSummary>
      <AccordionDetails>
        <span>
          <h4>{questionnaire.description}</h4>
          {questionnaireEnabled && (
            <h6 style={{ color: "red" }}>
              <i>
                <b>
                  Turn off Questionnaire to enable edit and delete features.
                </b>
              </i>
            </h6>
          )}
          <EditQuestionnaireDialog
            questionnaire={questionnaire}
            indexOfQuestionnaire={indexOfQuestionnaire}
            questionnaires={questionnaires}
            setQuestionnaires={setQuestionnaires}
            questionnaireEnabled={questionnaireEnabled}
          />
          <DeleteQuestionnaireDialog
            questionnaire={questionnaire}
            indexOfQuestionnaire={indexOfQuestionnaire}
            questionnaires={questionnaires}
            setQuestionnaires={setQuestionnaires}
            questionnaireEnabled={questionnaireEnabled}
          />

          <div>
            {questionnaire.questions.map((question, indexOfQuestion) => (
              <DisplayQuestionDistributionLocation
                key={indexOfQuestion}
                question={question}
              />
            ))}
          </div>
        </span>
      </AccordionDetails>
    </Accordion>
  );
}

QuestionnaireAccordion.propTypes = {
  questionnaire: PropTypes.object,
  indexOfQuestionnaire: PropTypes.number,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
};
