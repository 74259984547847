// This dialog appears when a distribution location is changing the amount owed
// by a user manually because for some reason the number doesn't match.  They can
// change the amount owed and add a comment.
import React, { useState, useContext } from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import EditCustomerPayment from "../Functions/EditCustomerPayment.js";
import { ChangeOrderLogContext } from "../../MyAccount.js";

// This is the dialog box that opens when a user clicks the edit button
function EditCustomerPaymentBox(props) {
  const { onClose, open } = props;

  // The new value owed by the user.
  const [newTotal, setNewTotal] = useState(props.totalOwed);

  // The notes the user chooses to add.
  const [orderNotes, setOrderNotes] = useState("Adjusted by Admin User");

  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );

  // If the user closes or cancels their changes then resets the values.
  const handleClose = () => {
    onClose(false);
    setNewTotal(props.totalOwed);
    setOrderNotes("Adjusted by Admin User");
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();

    // This sends in the updated values that will be used to update the customer's
    // owed amount.
    EditCustomerPayment(
      newTotal,
      orderNotes,
      props.orderDate,
      props.imported,
      props.distributionLocation,
      props.user,
      props.updatePaymentHistory,
    );
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Update the amount owed by this user
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <span>
            <TextField
              margin="dense"
              id="newTotal"
              label="New Total $"
              name="newTotal"
              value={newTotal}
              onChange={(e) => setNewTotal(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="orderNotes"
              label="Order Notes"
              name="orderNotes"
              value={orderNotes}
              onChange={(e) => setOrderNotes(e.target.value)}
              fullWidth
              required
            />
          </span>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EditCustomerPaymentBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function EditCustomerPaymentDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <EditCustomerPaymentBox
        open={open}
        onClose={handleClose}
        orderDate={props.orderDate}
        imported={props.imported}
        distributionLocation={props.distributionLocation}
        user={props.user}
        updatePaymentHistory={props.updatePaymentHistory}
        totalOwed={props.totalOwed}
      />
    </div>
  );
}
