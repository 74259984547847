// This function return the date in the format yyyymmdd and time since in milliseconds
// since 197-something
export default function YYYYMMDDConverter(date) {
  let now = new Date();
  if (date) {
    now = new Date(date);
  }
  const y = now.getFullYear();
  const m = now.getMonth() + 1;
  const d = now.getDate();
  const time = now.getTime();
  return "" + y + (m < 10 ? "0" : "") + m + (d < 10 ? "0" : "") + d + time;
}
