// This function sends and email when a user applies at a distribution location
// to become a community host.
import firebase from "../../../components/Firebase.js";

export default function ApplicationEmails(
  userInfo,
  communityLocation,
  setSendingEmail,
  distributionLocation,
) {
  const communityLocationApplication = firebase
    .functions()
    .httpsCallable("communityLocationApplication");

  // If this is not an individual than add the farms to the order.  If this is an
  // individual than at their pickupLocation's email to the marketplace.
  const dest = [userInfo.email, distributionLocation.email];

  // Call the communityLocationApplication cloud Function on firebase.
  communityLocationApplication({
    dest,
    userInfo,
    communityLocation,
    distributionLocation,
  })
    .then(function (result) {
      setSendingEmail(false);
      console.log("The result is", result);
    })
    .catch(function (error) {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      console.error(
        "There was an error when calling the Cloud Function",
        error,
      );
      window.alert(
        "There was an error when calling the Cloud Function:\n\nError Code: " +
          code +
          "\nError Message:" +
          message,
      );
    });
}
