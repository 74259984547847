// This creates the foodUserRemovalList which will be in the format below. foodList Index
// is the index of the foodItem the distribution location's foodList and the pantry follows the same
// format of the foodList only for the pantryItems.
// {"foodList" : [1 <- (foodListIndex) :
// "detailedOrders" : {FirstName-LastName-userId-date : {quantity: 1, checked: true} }
// "fullRemovalFlag" : true
// "ordersToChange": []]
// "pantryFoodItems" : same]}

import CommunityOrdersAffected from "../../../../functions/CommunityOrdersAffected.js";

export default function CreateListOfAffectedOrders(
  selectedItems,
  selectedPantryItems,
  order,
  setFoodUserRemovalList,
) {
  // A temporary value that the user uses to run through the selectedItems and
  // finds which users had this item in their order.
  let foodItemUpdate = {};
  // These are the imported userOrders that need to be changed. {location: {firstName-lastName-userid : boolean}}
  // depraceted feature of imported orders.
  const importedOrdersToChangeDict = {};
  // Creates the temporary foodUserRemovalList to update until it's ready for the state.
  const foodUserRemovalListTemp = {};

  // Cycle through twice as the first time will be for the foodList and the second time will
  // be for the pantry items.
  for (let cycle = 0; cycle < 2; cycle++) {
    // This is to say to use the foodList selected Items to remove.
    let currentItems = { ...selectedItems };
    // This is used to create the dictionary and make sure that the foodList key is used.
    let foodListKey = "foodList";
    // If cycle is 1 then we're doing the pantryFoodList.
    if (cycle === 1) {
      // This is to say to use the pantryFoodItems selected Items to remove.
      currentItems = { ...selectedPantryItems };
      // This is used to create the dictionary and make sure that the pantryFoodItems key is used.
      foodListKey = "pantryFoodItems";
    }
    // Create the empty dictionary.
    foodUserRemovalListTemp[foodListKey] = {};

    // Cycle through the selected foodItems to remove the distribution location chose.
    Object.keys(currentItems).forEach((index) => {
      // Check to see if the selectedItem is true which means it was selected.
      if (currentItems[index]) {
        // Set the dictionary object that is the foodItem the distribution
        // location ordered.
        if (cycle === 1) {
          // Create the foodList item so that it is empty.
          foodUserRemovalListTemp[foodListKey][index] = {
            fullRemovalFlag: true,
            ordersToChange: [],
            detailedOrders: {},
          };
          // Set the foodItem we're updating tho the value of the list.
          foodItemUpdate = order.pantryFoodItems[index];
        }
        // The first time through the loop then we will use FoodItems.
        else {
          // Create the empty food list item
          foodUserRemovalListTemp[foodListKey][index] = {
            fullRemovalFlag: true,
            ordersToChange: [],
            detailedOrders: {},
          };
          // Set the foodItem we're updating tho the value of the list.
          foodItemUpdate = order.foodList[index];
        }
        // This sends in the order and the food item that needs to be removed.  It returns
        // an array with the FirstName-LastName-userid-orderDate of all the orders that
        // have this item that is being removed.
        const [ordersToChange, importedOrdersToChange] =
          CommunityOrdersAffected(order, foodItemUpdate);

        // Cycles through the array of this distribution location's orders that were
        // affected.
        ordersToChange.forEach((userOrder) => {
          // Find the of the current foodItem
          const i = order.communityOrders[userOrder].foodList.findIndex(
            (foodItem) =>
              foodItem.description === foodItemUpdate.description &&
              foodItem.farmName === foodItemUpdate.farmName &&
              foodItem.item === foodItemUpdate.item &&
              !!foodItem.limit === !!foodItemUpdate.limit,
          );

          // Set the quantity of the item that the user has ordered.
          const quantityOfItem =
            order.communityOrders[userOrder].foodList[i].quantity;

          // Copy the quantity over from it and put it in the dictionary
          foodUserRemovalListTemp[foodListKey][index].detailedOrders[
            userOrder
          ] = { quantity: quantityOfItem, checked: true };
        });
        // Cycle through the importedOrders first starting with the location.
        // This is deprecated.
        Object.keys(importedOrdersToChange).forEach((location) => {
          // Check to see if this location has had any user orders yet.
          if (importedOrdersToChangeDict[location] === undefined) {
            // if not then create an empty dictionary.
            importedOrdersToChangeDict[location] = {};
          }
          // Cycle through all the userOrders from the imported locations.
          importedOrdersToChange[location].forEach((userOrder) => {
            // Set this to true to allow for the user's email to be loaded.
            importedOrdersToChangeDict[location][userOrder] = true;
          });
        });
      }
    });
  }
  // Set the state to equal the new dictionary.
  setFoodUserRemovalList({ ...foodUserRemovalListTemp });
}
