import firebase from "../../../../components/Firebase.js";

export default function LoadMoreVolunteerSchedule(
  volunteerSchedule,
  setVolunteerSchedule,
  setLoading,
  lastLoadedQuarter,
  setLastLoadedQuarter,
) {
  // Set the loading to false while the extra ones load.
  setLoading(false);
  const database = firebase.firestore();
  // Load the volunteer Schedule.  Load two so that we know if we're getting the last one.
  const volunteerDocRef = database
    .collection("VolunteerSchedule")
    .orderBy("timePeriod", "desc")
    .startAfter(lastLoadedQuarter)
    .limit(2);

  // Set it to the current schedule
  const volunteerDataTransfer = [...volunteerSchedule];

  // Cycle through all the found documents and add them to the volunteerJobs hook.
  volunteerDocRef
    .get()
    .then((collection) => {
      const doc = collection.docs[0];
      if (doc.exists) {
        // Add the older schedule to the front of the array.
        volunteerDataTransfer.unshift(doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setLastLoadedQuarter(null);
      }

      // Set the last loaded quarter to either null or the current document.  If the
      // length is less than two then we know that there aren't anymore to load.
      if (collection.docs.length < 2) {
        setLastLoadedQuarter(null);
      } else {
        setLastLoadedQuarter(collection.docs[0]);
      }

      setVolunteerSchedule([...volunteerDataTransfer]);
      setLoading(true);
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
