// This function is used to calculate the community orders cost.  It takes in the
// dictionary of commuinty orders (can also be imported orders) and then outputs
// a dollar value.
import PriceTotals from "./PriceTotals.js";

export default function CommunityOrdersTotal(communityOrders) {
  // Add up the cost of all the orders of the preorders so that you can see
  // how much food was preordered and is accounted for.  The remaining food
  // is then donated.
  let communityOrdersTotal = 0;
  // Cycle through each individual order
  Object.keys(communityOrders).forEach((order) => {
    // This will store the amount each individual pays to each farm
    let orderTotals = {};
    // This will store the amount each individual pays to each farm
    orderTotals = PriceTotals(communityOrders[order].foodList, true);
    // Cycle through the amounts that the individual owes to the farm and
    // add each value to the overall community orders total
    Object.keys(orderTotals).forEach((farm) => {
      communityOrdersTotal += orderTotals[farm];
    });
  });
  // This is the value of all the community orders
  communityOrdersTotal = parseFloat(communityOrdersTotal).toFixed(2);

  return communityOrdersTotal;
}
