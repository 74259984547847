// This function is used to take in the imported community orders and combines
// all the orders of the customers into one order and ensures that all the
// the farms are accounted for.
export default function AggregateImportedOrders(importedOrders) {
  // The total subisidies of all community orders
  const subsidies = {};
  // The total donations of all community orders
  const donations = {};

  // Take the communityOrders that was taken from the database and get each of
  // their keys.
  Object.keys(importedOrders).forEach((location) => {
    subsidies[location] = 0;
    donations[location] = 0;
    Object.keys(importedOrders[location].communityOrders).forEach(
      (customer) => {
        // Create a customer Order equal to this will be the new community orders
        // with all the orders properly aggregated.
        const customerOrder = {
          ...importedOrders[location].communityOrders[customer],
        };
        // Checks to see if the user donated or was subsidized and then adds that value
        // depending on the contribution
        if (customerOrder.customerContribution === "subsidy") {
          subsidies[location] += customerOrder.donationSubsidy;
        } else {
          donations[location] += customerOrder.donationSubsidy;
        }
      },
    );
  });

  return [donations, subsidies];
}
