// When an order fails to be placed the program needs to restore any items that were
// taken from the farmLimit and be restored to what they were before this order was placed.
// It takes in a dictionary that has the name of the FoodItem document and how many of that item
// were taken.
import firebase from "../../../components/Firebase.js";

export default function OrderIncompleteRestoreItems(foodItemsToRestore) {
  // Set up the database.
  const database = firebase.firestore();
  const batch = database.batch();

  // Cycle through the dictionary adding back each foodItem.
  Object.keys(foodItemsToRestore).forEach((foodItemToRestore) => {
    // reference the document name.
    const foodItemDocRef = database
      .collection("FoodItems")
      .doc(foodItemToRestore);
    // Increment the amount that was taken from the farmLimit.
    batch.update(foodItemDocRef, {
      farmLimit: firebase.firestore.FieldValue.increment(
        foodItemsToRestore[foodItemToRestore],
      ),
    });
  });

  // Commit the changes to the database.
  batch.commit();
}
