// This dialog is used to display when the distribution location is sending the emails
// to their selected users.  Once it is done sending this dialog disappears.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

function SendingEmailsBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        Sending Emails to users
      </DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          Emails are sending please wait until we are done.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

SendingEmailsBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function SendingEmailsDialog(props) {
  const open = props.open;

  return (
    <div>
      <SendingEmailsBox open={open} />
    </div>
  );
}
