// This will take in the updated and original order info to update a user's order
// being transferred from one location to another.  It will update the payment history,
// DistributionLocations and the distribution location's Users -> Orders documents in firebase.
import firebase from "../../../components/Firebase.js";
import UpdateCommunityOrdersDatabase from "../../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

// Takes an individal order from community Orders at a location and moves it to an imported location
export default async function CopyOrdersBetweenLocations(
  orderDate,
  userOrderIdentifier,
  pickupLocationToUpdate,
  imported,
  listOfLocations,
  pickupLocationOriginal,
  distributionLocationOriginal,
  distributionDateOriginal,
  orderLog,
  orderIndex,
  updateOrderLog,
  updatePaymentHistory,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  // Initialize the database.
  const database = firebase.firestore();
  const batch = database.batch();
  // We want to have just the user's firstName-lastName-userId to be used in payment history and to check
  // if there are multiple orders placed by the same user.
  const userPaymentIdentifier = [
    userOrderIdentifier.split("-")[0],
    userOrderIdentifier.split("-")[1],
    userOrderIdentifier.split("-")[2],
  ].join("-");
  // This is the orderLog that contains all the orders of this user.  We use that to read the old data.
  const orderLogTemp = [...orderLog];
  // When copying a user from one location to another there may be a user that has
  // placed multiple orders.  If that is the case then we cannot remove the old payment history in case
  // there are still orders left.   This variable will tell the program not to delete the payment history from
  // the old location's paymentHistory.
  let userStillExists = false;
  // This sets the distribution Location to the new pickuplocation.
  let distributionLocationToUpdate = pickupLocationToUpdate;
  // This is the community pickup information.
  let communityPickupTemp = [];
  // If there is no community pickup information then we know it is a distribution
  // location and will skip this otherwise we need to set the community info and update
  // the distribution location to update as it is currently set to the community hub.
  if (listOfLocations[pickupLocationToUpdate][2] !== undefined) {
    communityPickupTemp = listOfLocations[pickupLocationToUpdate][2];
    distributionLocationToUpdate =
      listOfLocations[pickupLocationToUpdate][2].distributionLocationName;
  }

  // This is the original document to update DistributionLocations.
  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // Update the current distribution locations orders in the DistributionLocations
  // collection
  const communityOrdersRef = database
    .collection("DistributionLocations")
    .doc(distributionLocationOriginal)
    .collection("CommunityOrders");

  const querySnapshot = await communityOrdersRef
    .where("distributionDate", "==", distributionDateOriginal)
    .get();

  // Loop through the documents to find the one with the matching user key
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // Check if the document contains the user key
    if (userOrderIdentifier in data) {
      communityOrdersDocRef = doc.ref; // Reference to the found document
    }
  });

  // This is the community order being copied.
  let communityOrder = {};

  communityOrder = {
    ...orderLogTemp[orderIndex].communityOrders[userOrderIdentifier],
  };
  // Delete the community order from the original location.
  delete orderLogTemp[orderIndex].communityOrders[userOrderIdentifier];
  // Cycle through all the community orders and check to see if the community member had placed multiple
  // orders at different times.  If there are any orders that are left at this location the we want to set
  // userStillExists to true as we won't want to delete the payment history from the original location.
  Object.keys(orderLogTemp[orderIndex].communityOrders).forEach((userKey) => {
    if (userKey.includes(userPaymentIdentifier)) {
      userStillExists = true;
    }
  });

  // Update communityPickup to the correct info.  Either [] or the dictionary with all
  // the information.
  communityOrder.communityPickup = communityPickupTemp;

  // Update the order info with the updated community order.
  orderLogTemp[orderIndex].communityOrders[userOrderIdentifier] = {
    ...communityOrder,
  };

  // Remove the original community order from the database
  if (communityOrdersDocRef) {
    batch.update(communityOrdersDocRef, {
      [userOrderIdentifier]: { ...communityOrder },
    });
  }
  // Update the order document of this distribution location.
  UpdateCommunityOrdersDatabase(
    "CompleteOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    { ...orderLogTemp[orderIndex].communityOrders },
    orderDate,
  );

  // Use this orderDateExists to find the document containing this paymentHistory.
  const paymentHistoryFlag = orderDate.concat("Exists");

  // Find the document where the flag is true.
  const paymentHistoryCollectionRef = database
    .collection("PaymentHistory")
    .where(paymentHistoryFlag, "==", true);

  // Pull the document and find the matching document.
  paymentHistoryCollectionRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since payment can be updated by adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database
            .runTransaction((transaction) => {
              // Store the document's reference.
              const paymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(documentName);

              // This code may get re-run multiple times if there are conflicts.
              return transaction
                .get(paymentHistoryDocRef)
                .then((paymentHistoryDoc) => {
                  if (!paymentHistoryDoc.exists) {
                    // eslint-disable-next-line no-throw-literal
                    throw "Document does not exist!";
                  }

                  // Load the full document of this order Date.
                  // eslint-disable-next-line no-unused-vars
                  const documentData = paymentHistoryDoc.data();

                  const index = Object.keys(doc.data()).indexOf(orderDate);
                  // The order exists in this document.
                  if (index !== -1) {
                    // This will be the full document.
                    const paymentHistoryTemp = { ...doc.data() };
                    // This will be the specific user payment history.
                    let paymentUserDataTemp = {};

                    // Set the payment history of the specific user.
                    paymentUserDataTemp = {
                      ...doc.data()[orderDate].communityOrders[
                        userPaymentIdentifier
                      ],
                    };
                    // If the user doesn't still exist at the original location then we can delete the payment history
                    // from the original location.
                    if (!userStillExists) {
                      delete paymentHistoryTemp[orderDate].communityOrders[
                        userPaymentIdentifier
                      ];
                    }
                    // Update the distribution location to the new one if it changed.
                    paymentUserDataTemp.distributionLocation =
                      distributionLocationToUpdate;

                    // Update the notes to inlcude the transferred order.
                    paymentUserDataTemp.orderNotes =
                      paymentUserDataTemp.orderNotes.concat(
                        `Transferred order from ${pickupLocationOriginal} to ${pickupLocationToUpdate}. `,
                      );

                    paymentHistoryTemp[orderDate].communityOrders[
                      userPaymentIdentifier
                    ] = { ...paymentUserDataTemp };

                    // Complete the transaction.  If the document has been changed then
                    // this document has to go again.
                    transaction.update(
                      paymentHistoryDocRef,
                      paymentHistoryTemp,
                    );
                    // Update the paymentHistory dictionary
                    updatePaymentHistory(paymentHistoryTemp, orderDate);
                  }
                  // This order doesn't exist in this document so you can't update it.
                  else {
                    console.log("This order cannot be updated.");
                  }
                });
            })
            .then(() => {
              batch.commit();
              updateOrderLog(orderLogTemp);
            });
        } else {
          console.log("UNDEFINED");
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
