// This is a function called when a user adds all items to their basket that have
// a quantity greater than 0.
import HandleBasketItems from "./HandleBasketItems.js";

export default function HandleAddAllToBasket(
  displayedFoodItems,
  setDisplayedFoodItems,
  setSnackBarOpen,
  setSnackBarMessage,
  userInfo,
  handleUserInfoChange,
) {
  // Creates a temporary array of the items the user can see.
  const arrFood = [...displayedFoodItems];

  // Checks to see if the user has changed any of the foodItem.quantity fields
  // from 0 and has anything to add to the basket.
  let valuesToUpdate = 0;
  arrFood.forEach((arrFood) => {
    valuesToUpdate += arrFood.quantity;
  });

  // If there are no quantities to update then the valuesToUpdate will be equal ot 0
  // and you will skip over this section to the else. Other perform update.
  if (valuesToUpdate !== 0) {
    // Call HandleBasketItems that will add each item with a quantity greater than
    // 0 to the basket.
    HandleBasketItems(
      userInfo,
      displayedFoodItems,
      undefined,
      undefined,
      undefined,
      handleUserInfoChange,
    );
    // Resets all the quantities of the foodItems to 0
    arrFood.forEach((foodItem, index) => {
      arrFood[index].quantity = 0;
    });
    // Resets the displayedFoodItems so they have the correct quantities.
    setDisplayedFoodItems([...arrFood]);
    setSnackBarMessage("Items have been added to your cart");
    // Show the user a snackbar that they have added the items successfully.
    setSnackBarOpen(true);
  } else {
    console.log("DONT UPDATE");
  }
}
