// This dialog is created when a user places an order and they're sending so many
// emails that we have to stagger them to deal with the emailer
// limitations on sending emails.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/MyAccount.css";

function PlacingOrderBatchesBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Placing Order</DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          Please wait while we send your emails.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

PlacingOrderBatchesBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function PlacingOrderBatchesDialog(props) {
  const open = props.open;

  return (
    <div>
      <PlacingOrderBatchesBox open={open} />
    </div>
  );
}
