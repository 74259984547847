// This component is what each step of the how it works button looks like.  It
// includes the shape of the step, title, read more text and then when the step is
// clicked and expanded show the additional text.
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

export default function StepButtons({
  stepNumber,
  title,
  src,
  totalSteps,
  text,
}) {
  // Expand the read more of the steps to see the text or hide the text of this text.
  const [expand, setExpand] = useState(false);
  // Whether the click to read more or click to read less is showed.
  const [readMoreMessage, setReadMoreMessage] = useState("Click to Read More+");

  // When the user clicks to expand or collapse the step.  Changes the read more
  // messages to match what the user should be seeing.
  function onExpand() {
    setExpand(!expand);
    if (expand) {
      setReadMoreMessage("Click to Read More+");
    } else {
      setReadMoreMessage("Click to Read Less-");
    }
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <button onClick={onExpand} className="Step-Buttons">
        <span style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <h4 className="Step-Buttons-Step-Font">Step {stepNumber}</h4>
            <h5 className="Step-Buttons-Click-Font">{readMoreMessage}</h5>
          </span>
          <h3 className="Step-Buttons-Title-Font">{title}</h3>
        </span>
      </button>
      {expand && <span className="Steps-Read-More">{text}</span>}
    </div>
  );
}
