// This adds a users credits to their profile.  It will read their current credits
// from the database and add their total.  If they don't have any credits then
// it will set their current credits to the amount being added.  It will then update
// the userInfo so that it is loaded properly.
import firebase from "../../../../components/Firebase.js";

export default function AddUserCredits(
  creditsToAdd,
  userInfo,
  handleUserInfoChange,
) {
  // set up the datebase.
  const database = firebase.firestore();

  // Find the document where this order date is saved.
  const userDocRef = database.collection("Users").doc(userInfo.userId);

  // Pull the document for reading.
  userDocRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // Run a transaction since this document uses it's old data to update
        // in the future we need to make sure we're not overwriting changes that are
        // occuring at the same time as this update.
        return database
          .runTransaction((transaction) => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(userDocRef).then((userDoc) => {
              if (!userDoc.exists) {
                // eslint-disable-next-line no-throw-literal
                throw "Document does not exist!";
              }

              // Load the full document of this order Date.
              const documentData = userDoc.data();

              // If the user has credits we will just add to them.  Else it will
              // set the user's credits to the amount being added.
              if (documentData.credits !== undefined) {
                documentData.credits += creditsToAdd;
              } else {
                documentData.credits = creditsToAdd;
              }

              // Complete the transaction.  If the document has been changed then
              // this document has to go again.
              transaction.update(userDocRef, documentData);
              // // Update the userInfo
              handleUserInfoChange(documentData, false, false, true);
            });
          })
          .then(() => {})
          .catch((err) => {
            // Send an alert to the user to tell them of the error.
            // eslint-disable-next-line no-undef
            alert(err);
            // Return false that the transaction failed.
            return false;
          });
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
