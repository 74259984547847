// This is called when the user submits their request for community host access
// of a particular distribution location.
import firebase from "../../../components/Firebase.js";

export default function RequestCommunityHostAccess(
  locationDetails,
  userInfo,
  handleUserInfoChange,
  setSendingEmail,
  personalMessage,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  const documentName = locationDetails.userId;

  // Reference the document in the database of accepted community memebers of distribution
  // locations.
  const docRef = database
    .collection("Users")
    .doc(documentName)
    .collection("CommunityHosts")
    .doc("CommunityMembers");

  // Sets the key location of the userInfo
  const keyLocation = [
    userInfo.firstName,
    userInfo.lastName,
    userInfo.userId,
  ].join("-");

  batch.update(docRef, {
    [keyLocation]: false,
  });

  // Sets the community location's label which is used in the database.
  const communityLocationLabel = [
    locationDetails.firstName,
    locationDetails.lastName,
    locationDetails.userId,
  ].join("-");

  // Reference the current user's document.
  const userDocRef = database.collection("Users").doc(userInfo.userId);

  // Sets the reference to the documents fields through dot notation.
  const userInfoLocationToUpdate = [
    "CommunityHostAccess",
    communityLocationLabel,
  ].join(".");

  batch.update(userDocRef, {
    [userInfoLocationToUpdate]: {
      locationName: locationDetails.locationName,
      status: "pending",
    },
  });

  batch.commit();

  const userInfoTemp = { ...userInfo };

  // Checks if the user has CommunityHostAccess in their userInfo.
  if (userInfoTemp.CommunityHostAccess === undefined) {
    // Adds CommunityHostAccess if it doesn't exist.
    userInfoTemp.CommunityHostAccess = {};
  }
  // Add the following to the userInfo.CommunityHostAccess[distributionLocation]
  userInfoTemp.CommunityHostAccess[communityLocationLabel] = {
    locationName: locationDetails.locationName,
    status: "pending",
  };
  handleUserInfoChange({ ...userInfoTemp }, true);

  // Sets the email cloud function.
  const communityHostAccessRequest = firebase
    .functions()
    .httpsCallable("communityHostAccessRequest");

  // Sets the destination of the email to the user and the distribution location.
  const dest = [userInfo.email, locationDetails.email];

  // Call the communityHostAccessRequest cloud Function on firebase.
  communityHostAccessRequest({
    dest,
    userInfo,
    locationDetails,
    personalMessage,
  })
    .then(function (result) {
      setSendingEmail(false);
      console.log("The result is", result);
    })
    .catch(function (error) {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      console.error(
        "There was an error when calling the Cloud Function",
        error,
      );
      window.alert(
        "There was an error when calling the Cloud Function:\n\nError Code: " +
          code +
          "\nError Message:" +
          message,
      );
    });
}
