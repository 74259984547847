// This function takes in the userInfo of an individual user and checks if their
// basket has items in it and if it does then checks if these items are still valid.
// If they're not then remove the items.
export default function CheckBasketItemsAvailable(userInfo) {
  const daysOfTheWeek = {
    Mon: 1,
    Tues: 2,
    Wed: 3,
    Thurs: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // Creates a temporary variable to read the user's pickupLocation
  let pickupLocation = userInfo.pickupLocation;

  // In Case the user is using a communityPickup Location
  if (userInfo.pickupLocation.pickupLocation) {
    pickupLocation = userInfo.pickupLocation.pickupLocation;
  }

  // Sets the value of today's date
  const today = new Date();

  // Checks when the user last updated their basket.
  let lastBasketUpdateTime = userInfo.lastBasketUpdateTime;

  // If lastBasketUpdateTime is undefined then we don't know if the user's basket
  // is stale or not so just remove everything.
  if (lastBasketUpdateTime === undefined) {
    userInfo.basketItems = [];
  } else {
    // Creates a date variable with the lastBasketUpdateTime from the string that
    // is saved in the userInfo
    lastBasketUpdateTime = new Date(lastBasketUpdateTime);
    // if there are no items in the basket than there is no need to check if items
    // are stale.
    if (userInfo.basketItems !== []) {
      // The nextCutoffDay is the next cutoff day for whenever the user last
      // last updated their basket.  NOT the next cutoff day from today.
      const nextCutoffDay = new Date(lastBasketUpdateTime);
      // Determines the day after the next cut off day following the last basket update.  The added one
      // makes sure of that.
      nextCutoffDay.setDate(
        lastBasketUpdateTime.getDate() +
          ((daysOfTheWeek[pickupLocation.orderCutoff] +
            (7 - lastBasketUpdateTime.getDay())) %
            7) +
          1,
      );

      // If today is after the next cutoff day then we now all the items in the
      // basket are stale and from a previous week so remove everything.
      if (today > nextCutoffDay) {
        userInfo.basketItems = [];
      }
      // Otherwise we have to check if any of the items come from farms who are
      // past their cutoff day
      else {
        // create a variable to remove items that are stale.
        const itemsToRemove = [];
        // Cycle through all the farms of this distribution location and see if
        // any are leftovers only.  If they are then we have to make sure the user
        // added them from the pantry and not from before the pantry switch.
        Object.keys(pickupLocation.farmsPickupDates).forEach((farm) => {
          // If the farm is Not Set than we know the item is still valid.
          if (pickupLocation.farmsPickupDates[farm] !== "Not Set") {
            // If the farm is set than we have to cycle through the items and
            // for any item that matches the set farm we have to remove it.  We
            // can't depend on whether a limit exists as if one does exist then
            // the item might have been added before the farm switched to pantry only.
            // Check to see if the farm shows the pantry and the catalogue because in this
            // case then the item only needs to be removed if it has a limit since it should
            // be left if there is not limit.
            userInfo.basketItems.forEach((foodItem, index) => {
              if (foodItem.farmName === farm) {
                if (pickupLocation.farmsCatalogueAndPantry) {
                  if (pickupLocation.farmsCatalogueAndPantry[farm]) {
                    if (foodItem.limit) {
                      itemsToRemove.push(foodItem);
                    }
                  } else {
                    itemsToRemove.push(foodItem);
                  }
                } else {
                  itemsToRemove.push(foodItem);
                }
              }
            });
          }
        });
        // Cycle through all the items to remove in the basket and update the user's
        // basket
        itemsToRemove.forEach((foodItem) => {
          const foodItemIndex = userInfo.basketItems.findIndex(
            (element) =>
              element.item === foodItem.item &&
              element.description === foodItem.description &&
              element.farmName === foodItem.farmName,
          );
          userInfo.basketItems.splice(foodItemIndex, 1);
        });
      }
    }
  }
  // update the user's last update time.
  userInfo.lastBasketUpdateTime = today.toString();

  return userInfo;
}
