// This takes in the order and returns the donations, subsidies, community orders
// total cost, the cost of the food that is donated and the grand Total paid to
// the farmer
import AggregateCustomerList from "../../functions/AggregateOrder.js";
import PriceTotals from "../../functions/PriceTotals.js";
import CommunityOrdersTotal from "../../functions/CommunityOrdersTotal.js";
import CalculatePackagingFees from "./FeesCalculation/CalculatePackagingFees.js";
import CalculateDeliveryFees from "./FeesCalculation/CalculateDeliveryFees.js";
import CalculateVolunteerFees from "./FeesCalculation/CalculateVolunteerFees.js";
import CalculateVolunteerCredits from "./FeesCalculation/CalculateVolunteerCredits.js";

export default function CalculateOrderStats(
  order,
  organisationName,
  selectedDistributionLocation,
  location,
) {
  const communityOrders = order.communityOrders;
  const foodList = order.foodList;

  // The packagingFees is for the entire communities fees by location and with "Total".
  let packagingFees = {};
  // The delivery fees is for the entire communities fees by location and with "Total".
  let deliveryFees = {};
  // The volunteer credits is for the entire communities fees by location and with "Total".
  let volunteerCredits = {};
  // The volunteer fees is for the entire communities fees by location and with "Total".
  let volunteerFees = {};

  // This contains both the community and the imported order.  If there is no
  // imported order then it just the community order and vice versa.
  let communityAndImportedOrder = communityOrders;

  // Contains the imported order if it exists
  let importedOrder = order.importedOrder;

  // If there is no imported order then set it to an empty array otherwise create
  // the communityAndImportedOrder dictionary.
  if (importedOrder === undefined) {
    importedOrder = {};
  } else {
    // Cycle through all the importedOrder Locations and create the combined
    // community and imported order list.
    // eslint-disable-next-line array-callback-return
    Object.keys(importedOrder).map((location) => {
      // Some of the old orders followed a different database database structure.
      // If there is no dictionary than we know it is the old way
      if (importedOrder[location].communityOrders === undefined) {
        communityAndImportedOrder = Object.assign(
          {},
          communityOrders,
          importedOrder,
        );
      } else {
        communityAndImportedOrder = Object.assign(
          {},
          communityAndImportedOrder,
          importedOrder[location].communityOrders,
        );
      }
    });
  }

  // With the introduction of the multiple imported orders we have to make sure it
  // is a new order as the older orders cannot have their package fees checked.
  if (order.orderDate > "202106150000000000000") {
    // Calculate the packagingFees.
    packagingFees = CalculatePackagingFees(
      communityOrders,
      importedOrder,
      selectedDistributionLocation,
      location,
    );
    deliveryFees = CalculateDeliveryFees(
      communityOrders,
      importedOrder,
      selectedDistributionLocation,
      location,
    );
    volunteerCredits = CalculateVolunteerCredits(
      communityOrders,
      importedOrder,
      selectedDistributionLocation,
      location,
    );
    volunteerFees = CalculateVolunteerFees(
      communityOrders,
      importedOrder,
      selectedDistributionLocation,
      location,
    );
  }

  // A dictionary of the the farms(keys) and the total owed to them (values)
  let farmTotals = {};
  farmTotals = PriceTotals(foodList, false);

  let grandTotal = 0;
  // Sum up the grandTotal of all the vendors together.
  Object.keys(farmTotals).forEach((vendorTotal) => {
    grandTotal += farmTotals[vendorTotal];
  });

  // The pantryTotal is used to show how much food was taken from the pantry.
  let pantryTotal = 0;
  // Check to see if pantryFoodItems exists.
  if (order.pantryFoodItems) {
    // Since the pantry uses the individual values to calculate the prices we
    // set the second parameter to true.
    const pantryTotals = PriceTotals(order.pantryFoodItems, true);

    // Sum up the total of all the vendor's totals of the food taken from the pantry.
    Object.keys(pantryTotals).forEach((vendorTotal) => {
      // Total cost of food taken from the pantry.
      pantryTotal += pantryTotals[vendorTotal];
    });
  }
  // Add up the cost of all the orders of the preorders so that you can see
  // how much food was preordered and is accounted for.  The remaining food
  // is then donated.

  const communityOrdersTotal = CommunityOrdersTotal(communityAndImportedOrder);

  // This is the total amount of food that is donated at each location that participated
  // in this order.  The total is the total food donated.  Then each organsation that participated
  // will have a total as well.
  const donationAmount = { Total: 0 };

  // An array of all the participating organsations of this order.
  const arrParticipatingOrgs = [organisationName].concat(
    Object.keys(importedOrder),
  );

  arrParticipatingOrgs.forEach((location) => {
    // The total donated to this location to 0
    donationAmount[location] = 0;
    // Check to see if the order has any distributed food to begin with.
    if (order.distributedFoodItems) {
      // Check to see if this location has any distributed food.
      if (order.distributedFoodItems[location]) {
        // Cycle through all the foodItems that were donated and then add them to
        // the total donated for this order and the total donated to this specific location.
        order.distributedFoodItems[location].forEach((donatedFoodItem) => {
          // Don't add to the total if the item is just a suggested price.
          if (!donatedFoodItem.suggestedPrice) {
            donationAmount.Total +=
              donatedFoodItem.distributed *
              ((donatedFoodItem.price * donatedFoodItem.individualQuantity) /
                donatedFoodItem.distributionQuantity);
            donationAmount[location] +=
              donatedFoodItem.distributed *
              ((donatedFoodItem.price * donatedFoodItem.individualQuantity) /
                donatedFoodItem.distributionQuantity);
          }
        });
      }
    }
  });

  const [donations, subsidies] = AggregateCustomerList(
    communityAndImportedOrder,
    null,
    null,
    null,
    null,
    true,
  );

  return [
    subsidies,
    donations,
    communityOrdersTotal,
    donationAmount,
    grandTotal,
    pantryTotal,
    packagingFees,
    deliveryFees,
    volunteerCredits,
    volunteerFees,
  ];
}
