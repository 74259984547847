// Displays a user current pickup location.
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import MyAccountBecomeCommunityHostDialog from "../../MyAccountPage/Dialogs/MyAccountBecomeCommunityHostDialog.jsx";
import SendingApplicationDialog from "../../MyAccountPage/Dialogs/SendingApplicationDialog.jsx";
import { AuthContext } from "../../../components/authentication/Auth.js";

// This is the Pickup Location Info Tab which contains the Pickup Location name,
// Street Address, order cutoff day and thep pickup time and day
export default function PickupLocation({ userInfo }) {
  let name = userInfo.pickupLocation.name;
  const address = userInfo.pickupLocation.address;
  let orderCutoff = userInfo.pickupLocation.orderCutoff;
  const pickupDay = userInfo.pickupLocation.pickupDay;
  let pickupTime = userInfo.pickupLocation.pickupTime;
  // Displays any comments if this is a community location
  let additionalComments = "";
  // The distribution location that supplies to the community location
  let distributionLocation = "";
  // A circular loading dialog will appear if the user applies to be a distribution
  // location while the email sends.
  const [sendingEmail, setSendingEmail] = useState(false);
  const { handleUserInfoChange } = useContext(AuthContext);

  // If the user has selected a community location then update the variables.
  if (userInfo.pickupLocation.pickupLocation) {
    name = userInfo.pickupLocation.locationName;
    pickupTime = userInfo.pickupLocation.availableTimes;
    orderCutoff = userInfo.pickupLocation.pickupLocation.orderCutoff;
    additionalComments = userInfo.pickupLocation.additionalComments;
    distributionLocation = userInfo.pickupLocation.distributionLocationName;
  }

  return (
    <div className="Tab-Box">
      <span style={{ marginTop: "16px" }}>
        {userInfo.communityLocation === undefined ? (
          <MyAccountBecomeCommunityHostDialog
            userInfo={userInfo}
            handleUserInfoChange={handleUserInfoChange}
            setSendingEmail={setSendingEmail}
          />
        ) : (
          <>
            {userInfo.communityLocation.approved ? (
              <>Community Pickup : Approved</>
            ) : (
              <div>
                <Button size="small" variant="outlined" disabled>
                  COMMUNITY DISTRIBUTION APPLICATION PENDING PLEASE CHECK YOUR
                  EMAIL
                </Button>
              </div>
            )}
          </>
        )}
      </span>

      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Pickup Location: </h5>
        <p className="My-Account-Item"> {name} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Address: </h5>
        <p className="My-Account-Item"> {address} </p>
      </div>
      {/* NEEDS WORK
        <div className = "Tab-Item-Row" >
          <h5 className = "Row-Title"> Order Cutoff: </h5>
          <p className = "My-Account-Item" > {props.orderCutoff} at {props.orderCutoffTime} </p>
        </div>
      */}
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Order Cutoff: </h5>
        <p className="My-Account-Item"> {orderCutoff}</p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Pickup Time: </h5>
        <p className="My-Account-Item">
          {" "}
          {pickupDay} at {pickupTime}{" "}
        </p>
      </div>
      {!!userInfo.pickupLocation.pickupLocation && (
        <>
          <div className="Tab-Item-Row">
            <h5 className="Row-Title"> Comments from Community House: </h5>
            <p className="My-Account-Item"> {additionalComments} </p>
          </div>
          <div className="Tab-Item-Row">
            <h5 className="Row-Title"> Partnered Distribution Location: </h5>
            <p className="My-Account-Item"> {distributionLocation} </p>
          </div>
        </>
      )}
      <Link
        to={{ pathname: "/DistributionLocationSelection", query: "/MyAccount" }}
      >
        <Button color="primary">CHANGE</Button>
      </Link>
      <SendingApplicationDialog open={sendingEmail} />
    </div>
  );
}
