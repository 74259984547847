// This takes the in the day you are starting on and then x which is the day of the
// numerical value of the day you are looking for where Monday = 1 to Sunday = 7.
// It then takes these days and finds date of the next occurance.  Ex. It is Tuesday
// and you are looking for the next Sunday it will then find out the date of the following
// Sunday
export default function FindNextDay(startDay, x) {
  const nextDay = new Date(startDay);
  nextDay.setDate(startDay.getDate() + ((x + (7 - startDay.getDay())) % 7));
  return nextDay;
}
