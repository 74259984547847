// This is the commmunity host pop up on the Distribution Selection Screen.
import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ColourButton from "../../../../components/userInputs/ColourButton.js";
import RequestCommunityHostAccessDialog from "../../Dialogs/RequestCommunityHostAccessDialog.jsx";

export default function CommunityDistributionLocationPopUp({
  activeIcon,
  previousPage,
  handleClickOpen,
  userInfo,
  setSendingEmail,
}) {
  // A boolean that states whether the user has been approved for this community host.
  let approvedByCommunityLocation = false;
  // A boolean that states whether the user is pending approval for this community host.
  let pendingCommunityLocation = false;

  // The name of the partnered distribution location.
  // eslint-disable-next-line no-unused-vars
  const distributionLocationName = activeIcon.distributionLocationName;
  // The name of the community host found in userInfo CommunityHostAccess
  const userInfoRef = [
    activeIcon.firstName,
    activeIcon.lastName,
    activeIcon.userId,
  ].join("-");

  // Checks if the activeIcon has approved the current user or if no approval is needed.
  if (
    activeIcon.approvedCommunityMembers.includes(userInfo.userId) ||
    activeIcon.communityHubType === "publicNoApproval"
  ) {
    // Set approvedByCommunityLocation to true.
    approvedByCommunityLocation = true;
  }
  // Otherwise check if CommunityHostAccess exists
  else if (userInfo.CommunityHostAccess !== undefined) {
    // First check if this user has applied to this location before
    if (userInfo.CommunityHostAccess[userInfoRef] !== undefined) {
      // Checks if the user is waiting to hear back from this location.
      if (userInfo.CommunityHostAccess[userInfoRef].status === "pending") {
        // Set pendingCommunityLocation to true.
        pendingCommunityLocation = true;
      }
    }
  }

  return (
    <div>
      <h2>{activeIcon.locationName}</h2>
      Delivered Through {activeIcon.distributionLocationName}
      {approvedByCommunityLocation ? (
        <p>
          <strong>Address: </strong>
          {activeIcon.address}
        </p>
      ) : (
        <p>
          <strong>Close to: </strong>
          {activeIcon.obscuredAddress}
        </p>
      )}
      <p>
        <strong>Pickup Day:</strong> {activeIcon.pickupDay}
      </p>
      <p>
        <strong>Pickup Time:</strong> {activeIcon.availableTimes}
      </p>
      {/* NEEDS WORK
        <p><strong>Order Cutoff:</strong> {activeIcon.orderCutoff} at {activeIcon.orderCutoffTime}</p>
      */}
      <p>
        <strong>Order Cutoff:</strong> {activeIcon.pickupLocation.orderCutoff}
      </p>
      {userInfo.userId ? (
        <>
          {approvedByCommunityLocation ? (
            <Link
              style={{ textDecoration: "none" }}
              to={{ pathname: previousPage, query: activeIcon }}
            >
              <ColourButton
                hoverColour="#1c8c1c"
                colour="#9FBF59"
                title="SELECT"
                onClick={() => {
                  handleClickOpen(activeIcon);
                }}
              />
            </Link>
          ) : (
            <>
              {pendingCommunityLocation ? (
                <Button disabled>PENDING APPROVAL</Button>
              ) : (
                <RequestCommunityHostAccessDialog
                  locationDetails={activeIcon}
                  userInfo={userInfo}
                  setSendingEmail={setSendingEmail}
                />
              )}
            </>
          )}
        </>
      ) : (
        <Button disabled>SIGN IN TO SELECT</Button>
      )}
    </div>
  );
}
