// Renders a transaction date with it's payment  details.  It also includes a
// button to view the details of each specific community member's order.  It displays
// the current etransfers and cash deposits and the previous week's cash and etransfer deposits.
import React from "react";
import PaymentHistoryByDateDetailsDialog from "./../Dialogs/PaymentHistoryByDateDetailsDialog.jsx";
import PaymentHistoryByDateGridDialog from "./../Dialogs/PaymentHistoryByDateGridDialog.jsx";
import { Grid } from "@material-ui/core";

export default function OrderPaymentByDateListItem({
  paymentOrderDetails,
  moneyDepositDate,
  orderPaymentHistory,
  distributionLocation,
}) {
  // This is the year the order was placed YYYY
  const year = moneyDepositDate.substring(0, 4);
  // This is the month the order was placed MM
  const month = moneyDepositDate.substring(4, 6);
  // This is the day the order was placed DD
  const day = moneyDepositDate.substring(6, 8);
  const date = month + "-" + day + "-" + year;

  // The total number of deposits including the old and new for this date.
  const totalDeposited =
    paymentOrderDetails[distributionLocation].etransferCurrent +
    paymentOrderDetails[distributionLocation].cashCurrent +
    paymentOrderDetails[distributionLocation].etransferOld +
    paymentOrderDetails[distributionLocation].cashOld;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      style={{ borderBottom: "solid" }}
    >
      <Grid item xs={4}>
        <h5> Deposit On {date}</h5>
        <h5 style={{ color: "red", fontSize: "14px" }}>
          <i>Distributed by {distributionLocation}</i>
        </h5>
      </Grid>
      <Grid item xs={6}>
        <h5>
          Etransfer Current : $
          {paymentOrderDetails[distributionLocation].etransferCurrent.toFixed(
            2,
          )}
        </h5>
        <h5>
          Cash Current : $
          {paymentOrderDetails[distributionLocation].cashCurrent.toFixed(2)}
        </h5>
        <h5>
          Etransfer Previous : $
          {paymentOrderDetails[distributionLocation].etransferOld.toFixed(2)}
        </h5>
        <h5>
          Cash Previous :{" "}
          {paymentOrderDetails[distributionLocation].cashOld.toFixed(2)}
        </h5>
        <h5>
          Refunds :{" "}
          {paymentOrderDetails[distributionLocation].refunds.toFixed(2)}
        </h5>
      </Grid>
      <Grid item xs={2}>
        <PaymentHistoryByDateGridDialog
          paymentOrderDetails={paymentOrderDetails[distributionLocation]}
          distributionLocation={distributionLocation}
          moneyDepositDate={moneyDepositDate}
        />
        <PaymentHistoryByDateDetailsDialog
          paymentOrderDetails={paymentOrderDetails[distributionLocation]}
          distributionLocation={distributionLocation}
          moneyDepositDate={moneyDepositDate}
          totalDeposited={totalDeposited}
        />
      </Grid>
    </Grid>
  );
}
