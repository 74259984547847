// This displays the marketplace for a restaurant.
import React from "react";
import { Link } from "react-router-dom";
import Menu from "../../../components/Menu.js";
import ColourButton from "../../../components/userInputs/ColourButton.js";
import CustomizedSnackbars from "../../../components/snackBars/Snackbar.js";
import RestaurantFoodRow from "../RestaurantFoodRow.jsx";
import MarketplaceAddDialog from "../../../components/userForms/MarketplaceAddForm.js";
import HandleAddAllToBasket from "./../Functions/HandleAddAllToBasket.js";

// Restaurant User. The header titles for the columns of the basket information displayed.
function RestaurantTableHeader() {
  return (
    <div className="Food-Table">
      <div className="Restaurant-Table-Header">
        <h2 className="Header-2">Item</h2>
      </div>
      <div className="Restaurant-Table-Header">
        <h2 className="Header-2">Description</h2>
      </div>
      <div className="Restaurant-Table-Header">
        <h2 className="Header-2">Farm Name</h2>
      </div>
      <div className="Restaurant-Table-Header">
        <h2 className="Header-2">Quantity</h2>
      </div>
    </div>
  );
}

export default function RestaurantMarketplace({
  userInfo,
  handleUserInfoChange,
  foodItems,
  displayedFoodItems,
  setDisplayedFoodItems,
  currentUser,
  totalBasketItems,
  snackBarOpen,
  setSnackBarOpen,
  handleSnackBarClose,
}) {
  return (
    <div>
      <Menu />
      <div className="Page-Market">
        <div className="Page-Header">
          <h1 className="Marketplace-Title">Marketplace</h1>
        </div>

        <span>
          {displayedFoodItems.length !== 0 ? (
            <div>
              {/* Check to see what kind of user is logging in and if they're admin,
               individual, or a restaurant.  This will determine which marketplace
               they see. */}
              <div>
                <RestaurantTableHeader />
                {displayedFoodItems.map((row, index) => (
                  <RestaurantFoodRow
                    key={index + "-" + row}
                    index={index}
                    row={row}
                    currentUser={currentUser}
                    userInfo={userInfo}
                    handleUserInfoChange={handleUserInfoChange}
                    displayedFoodItems={displayedFoodItems}
                    setDisplayedFoodItems={setDisplayedFoodItems}
                  />
                ))}
              </div>

              <div className="Checkout-Add-Items-Buttons">
                <div className="Flex-Row-End">
                  <span className="Next-Button">
                    <MarketplaceAddDialog
                      foodItems={displayedFoodItems}
                      setFoodItems={setDisplayedFoodItems}
                      farms={userInfo.farms}
                      userInfo={userInfo}
                    />
                  </span>

                  <span className="Next-Button">
                    <ColourButton
                      hoverColour="#1c8c1c"
                      colour="#17c917"
                      title="Add all items to basket"
                      onClick={() =>
                        HandleAddAllToBasket(
                          displayedFoodItems,
                          setDisplayedFoodItems,
                          setSnackBarOpen,
                          userInfo,
                          handleUserInfoChange,
                        )
                      }
                    />
                  </span>
                </div>
                {totalBasketItems > 0 && (
                  <span className="Next-Button">
                    <Link style={{ textDecoration: "none" }} to="/Basket">
                      <ColourButton
                        hoverColour="#0c7aa5"
                        colour="#13b6f5"
                        title="HEAD TO CHECKOUT"
                        onClick={() => {
                          return null;
                        }}
                      >
                        Goto Checkout
                      </ColourButton>
                    </Link>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <span>
              <div>
                <h3 className="EmptyMarketplace">
                  {" "}
                  No items added to your Marketplace yet!{" "}
                </h3>
                <div className="Flex-Row-End">
                  {(userInfo.userType === "restaurant" ||
                    userInfo.userType === "admin") && (
                    <span className="Next-Button">
                      <MarketplaceAddDialog
                        foodItems={displayedFoodItems}
                        setFoodItems={setDisplayedFoodItems}
                        farms={userInfo.farms}
                        userInfo={userInfo}
                      />
                    </span>
                  )}
                </div>
              </div>
            </span>
          )}
        </span>
        <CustomizedSnackbars
          message="Items have been added to Basket"
          open={snackBarOpen}
          handleClose={handleSnackBarClose}
        />
      </div>
    </div>
  );
}
