// This is called when a user logs in and will update the user's community hub
// location if there are any changes that have been made.  Sometimes when the
// hub is updated then it will be missing some info in the userInfo Community hub
// which can cause them to be out of sync and so this is necessary.
import firebase from "../components/Firebase.js";

export default function UpdateCommunityHostData(
  userInfoTemp,
  setUserInfo,
  setLoading,
) {
  const database = firebase.firestore();

  // Sets the communityLocation of the user.
  const communityLocation = userInfoTemp.communityLocation;

  // Creates the document name of where the community host information is shared in
  // the database.
  const documentName = [
    communityLocation.firstName,
    communityLocation.lastName,
    communityLocation.userId,
  ].join("");

  const docRef = database.collection("CommunityLocations").doc(documentName);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        userInfoTemp.communityLocation = doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      setUserInfo(userInfoTemp);
      setLoading(true);
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      setUserInfo(userInfoTemp);
      setLoading(true);
    });
}
