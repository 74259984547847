// This is to remove a foodItem from a community or imported order.  It is done
// from the myAccount Page.
import firebase from "./../Firebase.js";
import AddRemoveItemPaymentHistory from "./../../pages/MyAccountPage/Functions/PriceHistoryChanges/AddRemoveItemPaymentHistory.js";
import VolunteerFeeAdjustment from "./VolunteerFeeAdjustment.js";
import UpdateCommunityOrdersDatabase from "./../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

export default async function RemoveFoodItemCommunityOrders(
  userOrder,
  user,
  imported,
  order,
  indexOfItem,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
  location,
  updatePaymentHistory,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // This contains an array of foodItems that the user ordered
  let foodListTemp = [];

  // This contains an array of farmers that the user ordered from
  let farmerListTemp = [];

  // pull the current foodList of the user being modified
  foodListTemp = [...order.communityOrders[user].foodList];

  // pull the current farmerList of the user being modified
  farmerListTemp = [...order.communityOrders[user].farmerList];

  // Update the current distribution locations orders in the DistributionLocations
  // collection
  const communityOrdersRef = database
    .collection("DistributionLocations")
    .doc(userInfo.organisationName)
    .collection("CommunityOrders");

  const querySnapshot = await communityOrdersRef
    .where("distributionDate", "==", order.selectedDate)
    .get();

  // Loop through the documents to find the one with the matching user key
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // Check if the document contains the user key
    if (user in data) {
      communityOrdersDocRef = doc.ref; // Reference to the found document
    }
  });

  // Save the foodItem to remove so we can update the order cost.
  const foodItemToRemove = { ...foodListTemp[indexOfItem] };

  // We use this to calculate the new cash and credit portions of the volunteer fee after the
  // adjustments.
  let [userCashPortion, userCreditPortion, volunteerFeeChange] =
    VolunteerFeeAdjustment(
      userOrder,
      userId[2],
      userInfo,
      foodItemToRemove,
      database,
      batch,
      true,
    );
  // Make sure the cash portion is a number otherwise set to 0
  if (userCashPortion === undefined) {
    userCashPortion = parseFloat(0);
  }
  // Make sure the credit portion is a number otherwise set to 0
  if (userCreditPortion === undefined) {
    userCreditPortion = parseFloat(0);
  }
  // Make sure the volunteerFeeChange portion is a number otherwise set to 0
  if (volunteerFeeChange === undefined) {
    volunteerFeeChange = parseFloat(0);
  }
  // Splice out the foodItem
  foodListTemp.splice(indexOfItem, 1);

  // Check if the farm still exists and if not then splice out the farm as well.
  farmerListTemp.forEach((farm, indexOfFarm) => {
    const indexExists = foodListTemp.findIndex((foodItem) => {
      return foodItem.farmName === farm.farmName;
    });
    if (indexExists === -1) {
      farmerListTemp.splice(indexOfFarm, 1);
    }
  });
  // Update the user order
  const userOrderTemp = JSON.parse(JSON.stringify(order.communityOrders[user]));
  userOrderTemp.foodList = [...foodListTemp];
  userOrderTemp.farmerList = [...farmerListTemp];
  userOrderTemp.cashPortion = userCashPortion.toFixed(2);
  userOrderTemp.creditPortion = userCreditPortion.toFixed(2);
  // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
  // FirstName-LastName-userId-date
  UpdateCommunityOrdersDatabase(
    "SingleUserOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    null,
    order.orderDate,
    user,
    userOrderTemp,
  );
  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  if (communityOrdersDocRef) {
    batch.update(communityOrdersDocRef, {
      [user.concat(".foodList")]: [...foodListTemp],
      [user.concat(".farmerList")]: [...farmerListTemp],
      [user.concat(".cashPortion")]: userCashPortion.toFixed(2),
      [user.concat(".creditPortion")]: userCreditPortion.toFixed(2),
    });
  }
  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userDocRef, {
    foodList: [...foodListTemp],
    farmerList: [...farmerListTemp],
    cashPortion: userCashPortion.toFixed(2),
    creditPortion: userCreditPortion.toFixed(2),
  });

  // This is to make sure the page rerenders these items correctly
  if (imported) {
    orderLogTemp[orderIndex].importedOrder[location].communityOrders[
      user
    ].foodList = [...foodListTemp];
    orderLogTemp[orderIndex].importedOrder[location].communityOrders[
      user
    ].farmerList = [...farmerListTemp];
    orderLogTemp[orderIndex].importedOrder[location].communityOrders[
      user
    ].cashPortion = userCashPortion.toFixed(2);
    orderLogTemp[orderIndex].importedOrder[location].communityOrders[
      user
    ].creditPortion = userCreditPortion.toFixed(2);
  } else {
    orderLogTemp[orderIndex].communityOrders[user].foodList = [...foodListTemp];
    orderLogTemp[orderIndex].communityOrders[user].farmerList = [
      ...farmerListTemp,
    ];
    orderLogTemp[orderIndex].communityOrders[user].cashPortion =
      userCashPortion.toFixed(2);
    orderLogTemp[orderIndex].communityOrders[user].creditPortion =
      userCreditPortion.toFixed(2);
  }

  await batch.commit();
  // Update the payment history with the new item.
  AddRemoveItemPaymentHistory(
    order.orderDate,
    imported,
    foodItemToRemove,
    user,
    location,
    false,
    updatePaymentHistory,
    volunteerFeeChange,
  );
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
