// This dialog shows the payment history by the transaction date.  It shows the dates
// where deposits happened and uses whichever date is specified.  For example it may
// show every Saturday as the deposit date per location.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Grid } from "@material-ui/core";
import UserPaymentByDateDetails from "./../Components/UserPaymentByDateDetails.jsx";

// This function compares the names of the users and sorts them alphabetically.
function SortOrdersAlphabetically(user1, user2) {
  const userName1 = user1.toUpperCase();
  const userName2 = user2.toUpperCase();

  if (userName1 < userName2) {
    return -1;
  }
  if (userName1 > userName2) {
    return 1;
  }
  return 0;
}

function PaymentHistoryDetailsBox({
  open,
  onClose,
  paymentOrderDetails,
  distributionLocation,
  moneyDepositDate,
  totalDeposited,
}) {
  const handleClose = () => {
    onClose(false);
  };

  // This is the year the order was placed YYYY
  const year = moneyDepositDate.substring(0, 4);
  // This is the month the order was placed MM
  const month = moneyDepositDate.substring(4, 6);
  // This is the day the order was placed DD
  const day = moneyDepositDate.substring(6, 8);
  // The date of transaction we are looking at.
  const depositDate = month + "-" + day + "-" + year;

  // Put the list of names and user names of the orders in an list to be sorted.
  const sortedCommunityOrder = Object.keys(paymentOrderDetails.communityOrders);

  // Sort the orders alphabetically.
  sortedCommunityOrder.sort((user1, user2) => {
    return SortOrdersAlphabetically(user1, user2);
  });

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Deposit Date History for {distributionLocation} from {depositDate}{" "}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-around",
          }}
        >
          <h5>
            <u>Total Deposited</u>
            <br />${totalDeposited.toFixed(2)}
          </h5>
        </div>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          style={{
            borderBottom: "solid",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <Grid item xs>
            Name
          </Grid>
          <Grid item xs>
            Amount
          </Grid>
          <Grid item xs>
            Date of Transaction
          </Grid>
          <Grid item xs>
            Distribution Date
          </Grid>
          <Grid item xs>
            Payment Method
          </Grid>
        </Grid>
        {sortedCommunityOrder.map((user) => (
          <UserPaymentByDateDetails
            key={user}
            paymentDetails={paymentOrderDetails.communityOrders[user]}
            user={user}
            moneyDepositDate={moneyDepositDate}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaymentHistoryDetailsBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PaymentHistoryByDateDetailsDialog({
  paymentOrderDetails,
  distributionLocation,
  moneyDepositDate,
  totalDeposited,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <div>
          <Button color="primary" onClick={handleClickOpen}>
            DETAILS
          </Button>
        </div>
      </div>
      <PaymentHistoryDetailsBox
        open={open}
        onClose={handleClose}
        paymentOrderDetails={paymentOrderDetails}
        distributionLocation={distributionLocation}
        moneyDepositDate={moneyDepositDate}
        totalDeposited={totalDeposited}
      />
    </div>
  );
}
