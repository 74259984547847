// Load the community members email and their phone number and add it to the hook
// that we will use to display their info.
import firebase from "../../../components/Firebase.js";

export default function LoadCommunityMemberInfo(
  communityMemberInfo,
  setCommunityMemberInfo,
  setLoading,
) {
  const database = firebase.firestore();

  // eslint-disable-next-line no-unused-vars
  const dataTransfer = [];
  // Checks the database for ongoing recurring orders under the current user's
  // profile.
  const docRef = database.collection("Users").doc(communityMemberInfo.userId);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // Create a temp variable to modify
        const communityMemberInfoTemp = { ...communityMemberInfo };
        // Add their email
        communityMemberInfoTemp.email = doc.data().email;
        // Add their phoneNumber.
        communityMemberInfoTemp.phoneNumber = doc.data().phoneNumber;
        // Update the hook.
        setCommunityMemberInfo({ ...communityMemberInfoTemp });
        // Set loading to done so that the content will be displayed.
        setLoading(false);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setLoading(false);
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      setLoading(false);
    });
}
