// This dialog appears when the admin user is adding a volunteer shift to the
// volunteer schedule.
import React, { useState, useEffect } from "react";
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LoadingContent from "../../../../components/LoadingContent.jsx";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddVolunteerShiftToDatabase from "../../Functions/VolunteerSchedule/AddVolunteerShiftToDatabase.js";

// This is the dialog box that opens when a user clicks the add button
function AddVolunteerShiftBox({
  open,
  onClose,
  volunteerSchedule,
  setVolunteerSchedule,
}) {
  // The Start Date of when the shift starts
  const [startDate, setStartDate] = useState(new Date());
  // The end date of when the shift ends.
  const [endDate, setEndDate] = useState(new Date());
  // The selected index of shift selected.
  const [shift, setShift] = useState(-1);
  const database = firebase.firestore();
  // The volunteerJobs that are used to display on the community page.
  const [volunteerJobs, setVolunteerJobs] = useState([]);
  // This is set to false until the data from the database been loaded
  const [volunteerLoading, setVolunteerLoading] = useState(false);
  // The selected position to display.
  const [positionSelected, setPositionSelected] = useState("");
  // If the user doesn't have a weekly or bi weekly shift then we don't show the end
  // date as this can only be entered once.
  const [showEndDate, setShowEndDate] = useState(false);

  // Load the volunteer position.
  useEffect(() => {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    const volunteerDocRef = database
      .collection("Volunteer")
      .where("status", "==", true);
    const volunteerDataTransfer = [];

    volunteerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            volunteerDataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setVolunteerJobs(volunteerDataTransfer);
        setVolunteerLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = (cancel) => {
    onClose(false);
  };

  // This is when the user saves to the database.
  const handleSave = async (e) => {
    e.preventDefault();

    try {
      await AddVolunteerShiftToDatabase(
        startDate,
        endDate,
        shift,
        positionSelected,
        volunteerSchedule,
        setVolunteerSchedule,
      );
    } catch (error) {
      console.error("Error adding volunteer shift:", error);
    }
    onClose(false);
  };

  // If the user changes the volunteer position that they are adding a shift to.
  const handlePositionChange = (e) => {
    const index = volunteerJobs.findIndex(
      (volunteerJob) => e.target.value === volunteerJob.title,
    );
    if (index !== -1) {
      setPositionSelected(volunteerJobs[index]);
    }
    setShift(-1);
    setShowEndDate(false);
  };

  // Handle the shift change selection and update the index.
  const handleShiftChange = (e) => {
    // The index of the selected shift.
    const shiftTemp = e.target.value;
    // Set the shift.
    setShift(parseFloat(shiftTemp));
    // If the shift is weekly or biweekly then we will show the end date as it will
    // add all possible shifts.
    if (
      positionSelected.shiftsAvailable[shiftTemp].Frequency === "Weekly" ||
      positionSelected.shiftsAvailable[shiftTemp].Frequency === "Biweekly"
    ) {
      setShowEndDate(true);
    } else {
      setShowEndDate(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Add Volunteer Shift
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          {volunteerLoading ? (
            <span>
              <FormControl fullWidth required>
                <InputLabel id="Volunteer-InputLabel-Label">
                  Select a Volunteer Position
                </InputLabel>
                <Select
                  labelId="Volunteer-Select-Label-Id"
                  id="Volunteer-Select-Id"
                  value={positionSelected.title ? positionSelected.title : " "}
                  onChange={(e) => handlePositionChange(e)}
                  label="Select a Volunteer Position"
                >
                  {volunteerJobs.map((option, index) => {
                    return (
                      <MenuItem key={option.title + index} value={option.title}>
                        {option.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {positionSelected !== "" && (
                <span>
                  <FormLabel component="legend">Select a Shift(s)</FormLabel>
                  <RadioGroup
                    aria-label="shift"
                    name="shift"
                    value={shift}
                    onChange={(e) => handleShiftChange(e)}
                  >
                    {positionSelected.shiftsAvailable.map(
                      (shiftAvailable, i) => (
                        <FormControlLabel
                          key={i}
                          value={parseFloat(i)}
                          control={<Radio />}
                          label={[
                            shiftAvailable.DayOfTheWeek,
                            " ",
                            shiftAvailable.StartTime,
                            "-",
                            shiftAvailable.EndTime,
                            " ",
                            shiftAvailable.Frequency,
                          ].join("")}
                        />
                      ),
                    )}
                  </RadioGroup>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      label="Start Date"
                      margin="normal"
                      id="date-picker-start-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <br />
                  {showEndDate && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        label="End Date"
                        margin="normal"
                        id="date-picker-end-date"
                        value={endDate}
                        onChange={(e) => setEndDate(e)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </span>
              )}
            </span>
          ) : (
            <LoadingContent inline={true} />
          )}

          <DialogActions>
            <Button onClick={() => handleClose("cancel")} color="primary">
              CANCEL
            </Button>
            {shift !== -1 && (
              <Button type="submit" color="primary">
                SAVE
              </Button>
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

AddVolunteerShiftBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AddVolunteerShiftDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>ADD VOLUNTEER SHIFT</Button>
      <AddVolunteerShiftBox
        open={open}
        onClose={handleClose}
        volunteerSchedule={props.volunteerSchedule}
        setVolunteerSchedule={props.setVolunteerSchedule}
      />
    </div>
  );
}
