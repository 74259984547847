// This is part of the Community Engagement page that shows additional information
// and resources that might be of interest to the community and can be used
// to problem solve.
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { OrderDetails as OrderDetailsV2 } from "../../../components/OrderDetails/V2";
import ImprovementBoard from "./ImprovementBoard";
import DeliveryMap from "../../DeliveryMap.js";
import BlogPosts from "../../BlogsPage/BlogPosts.jsx";
import UnderConstruction from "../../../components/UnderConstruction.jsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="Community-Tab-Panel"
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#008037",
    },
    "& .Mui-selected": {
      color: "#008037",
      fontWeight: "800",
    },
  },
  tabPanel: {
    backgroundColor: "rgba(1,1,1,0.1)",
    width: "100%",
    paddingTop: 12,
  },
}));

export default function ExtraInformation() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        textColor: "red",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Extra Information tabs"
        sx={{
          borderRight: 1,
          borderColor: "#008037",
          color: "#808080",
          minWidth: "125px",
        }}
        className={classes.tabs}
      >
        <Tab label="WEEKLY ORDER DETAILS" {...a11yProps(0)} />
        <Tab label="IMPROVEMENT BOARD" {...a11yProps(1)} />
        <Tab label="BUDGET" {...a11yProps(2)} />
        <Tab label="PAST WEEKLY NEWSLETTERS" {...a11yProps(3)} />
        <Tab label="BLOGS" {...a11yProps(4)} />
        <Tab label="DELIVERY MAP" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <OrderDetailsV2 />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ImprovementBoard />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UnderConstruction />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <UnderConstruction />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BlogPosts />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DeliveryMap />
      </TabPanel>
    </Box>
  );
}
