import React from "react";
// import firebase from './../Firebase.js';
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import DialogContent from "@material-ui/core/DialogContent";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";

export default class AddSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      section: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.props.handleSave.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleSubmit() {
    if (this.state.section !== "") {
      this.handleSave(this.state.section);
      this.setState({
        open: false,
        section: "",
      });
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className="Add-To-Menu User-Input-Font"
          onClick={this.handleClickOpen}
        >
          +
        </button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Add a New Menu
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Section"
              name="section"
              autoFocus
              onChange={this.handleChange}
              value={this.state.section}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.handleSubmit();
                }
              }}
            />
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button type="button" color="primary" onClick={this.handleSubmit}>
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
