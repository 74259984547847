// This is called whenever the distribution location is modifying one of their items.
// We have to check if one of the community members has ordered this item and update their
// orders and the prices.
import firebase from "../../../../components/Firebase.js";
import CalculateOrderTotals from "../../../../functions/CalculateOrderTotals.js";
import ModifyCommunityOrders from "./ModifyCommunityOrders.js";
import PriceChangesPaymentHistory from "./../PriceChangesPaymentHistory.js";
import UpdateCommunityOrdersDatabase from "./../../../BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

export default async function UpdatePriceAndCommunity(
  userInfo,
  order,
  ordersToChange,
  importedOrdersToChange,
  updateOrderLog,
  updatePaymentHistory,
  orderIndex,
  foodListTemp,
  fieldToUpdate,
  foodItemUpdate,
  orderLogTemp,
  updateCommunityOrders,
  updating,
  updateInfo,
  priceUpdate,
  originalPriceIndividual,
  newPriceIndividual,
  contactMethodsTemp,
  farmerListTemp,
  distributedFoodItemsTemp,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Finds when then user ordered to help find the individual's order in the database
  const orderDate = order.orderDate;

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(orderDate);

  // Create a temporary communityOrders list
  const communityOrdersTemp = { ...order.communityOrders };

  // The imported order set a temporary dictionary to modify
  const importedOrdersTemp = { ...order.importedOrder };
  // The user's that had their volunteer fees changed and will be used to update the payment history.
  const volunteerFeeChanges = {};

  // Set the original costs of the customer's orders that are changing so that we
  // can compare their orders with the new costs and update the amount they owe.
  // These amounts need to happen before the communityOrdersTemp is changed as it will lose
  // the orignal costs.
  const originalOrderCosts = CalculateOrderTotals(
    ordersToChange,
    communityOrdersTemp,
  );

  // Do the same for imported orders which will have the distribution locations as well.
  const importedOriginalOrderCosts = {};
  // Cycle through the imported orders to change.
  Object.keys(importedOrdersToChange).forEach((distributionLocation) => {
    // Check to see if there are any orders to update for the specific location.
    if (importedOrdersToChange[distributionLocation].length !== 0) {
      // Calculate the original order costs to be used later to compare the cost change.
      importedOriginalOrderCosts[distributionLocation] = CalculateOrderTotals(
        importedOrdersToChange[distributionLocation],
        { ...order.importedOrder }[distributionLocation].communityOrders,
      );
    }
  });

  // Checks to see if the only thing that was changed was the quantity of the foodItem
  // which means that the community orders don't need to be updated.
  if (updateCommunityOrders) {
    await ModifyCommunityOrders(
      ordersToChange,
      database,
      batch,
      userInfo.organisationName,
      order.selectedDate,
      communityOrdersTemp,
      foodItemUpdate,
      updating,
      updateInfo,
      priceUpdate,
      userInfo,
      volunteerFeeChanges,
    );
  }

  // Checks to see if distributionQuantity, individualQuantity or price were changed
  // as that would trigger a price total change for the individual.
  if (priceUpdate) {
    // Recalculate the customer's orders with the item price changed.
    const updatedOrderCosts = CalculateOrderTotals(
      ordersToChange,
      communityOrdersTemp,
    );

    // Recalculate the costs of the imported orders with the item price chaged.
    // importedOriginalOrderCosts : {distributionLocation -> communityOrders : [user : XX]
    const importedUpdatedOrderCosts = {};

    // Cycle through the distribution locations to update orders per location.
    Object.keys(importedOrdersToChange).forEach((distributionLocation) => {
      if (importedOrdersToChange[distributionLocation].length !== 0) {
        // calcualate the updated order costs.
        importedUpdatedOrderCosts[distributionLocation] = CalculateOrderTotals(
          importedOrdersToChange[distributionLocation],
          importedOrdersTemp[distributionLocation].communityOrders,
        );
      }
    });

    // Once the original and the updated costs are calculated then compare the change
    // in price and update the payment history that each customer owes.
    PriceChangesPaymentHistory(
      orderDate,
      originalOrderCosts,
      updatedOrderCosts,
      importedOriginalOrderCosts,
      importedUpdatedOrderCosts,
      foodItemUpdate,
      originalPriceIndividual,
      newPriceIndividual,
      updatePaymentHistory,
      volunteerFeeChanges,
    );
  }

  // Users -> distributionLocationUserId -> Orders -> date
  batch.update(docRef, {
    [fieldToUpdate]: [...foodListTemp],
  });

  await UpdateCommunityOrdersDatabase(
    "CompleteOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    { ...communityOrdersTemp },
    orderDate,
  );

  if (updating === "removeItem") {
    // Users -> distributionLocationUserId -> Orders -> date
    batch.update(docRef, {
      contactMethods: { ...contactMethodsTemp },
      farmerList: [...farmerListTemp],
      distributedFoodItems: { ...distributedFoodItemsTemp },
    });

    orderLogTemp[orderIndex].contactMethods = { ...contactMethodsTemp };
    orderLogTemp[orderIndex].farmerList = [...farmerListTemp];
    orderLogTemp[orderIndex].distributedFoodItems = {
      ...distributedFoodItemsTemp,
    };

    // Since order is a state it doesn't update fast enough if there is an update that
    // is coming from multiple items be changed at once and so we need to store a local
    // variable that is able to keep up with the changes.
    order.contactMethods = { ...contactMethodsTemp };
    order.farmerList = [...farmerListTemp];
    order.distributedFoodItems = { ...distributedFoodItemsTemp };
  }

  // This is to make sure the page rerenders these items correctly for importedOrders
  if (importedOrdersToChange.length !== 0) {
    orderLogTemp[orderIndex].importedOrder = { ...importedOrdersTemp };
    // Update the imported order to be correct.
    order.importedOrder = { ...importedOrdersTemp };
  }

  // This is to make sure the page rerenders these items so updates either the foodList
  // or the pantryFoodItems.
  orderLogTemp[orderIndex][fieldToUpdate] = [...foodListTemp];
  // Update the local variable we're using in case there are multiple items being
  // updated at once and the hook can't keep up.
  order[fieldToUpdate] = [...foodListTemp];

  // This updates the communityOrders and the foodList
  orderLogTemp[orderIndex].communityOrders = { ...communityOrdersTemp };
  // Update the local variable we're using in case there are multiple items being
  // updated at once and the hook can't keep up.
  order.communityOrders = { ...communityOrdersTemp };

  await batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);
}
