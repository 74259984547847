// When a distribution location chooses to remove a community location from their
// community host program this is called to update the database and the hooks.
import firebase from "../../../components/Firebase.js";

export default function RemoveCommunityLocation(
  communityLocation,
  index,
  communityLocations,
  setCommunityLocations,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  const communityLocationDocumentName =
    communityLocation.firstName +
    communityLocation.lastName +
    communityLocation.userId;

  // Updates the distribution location's order history
  const docRef = database
    .collection("CommunityLocations")
    .doc(communityLocationDocumentName);

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database.collection("Users").doc(communityLocation.userId);

  // Delete the order from the individual's order
  docRef
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });

  userDocRef.update({
    communityLocation: firebase.firestore.FieldValue.delete(),
  });

  const communityLocationsTemp = communityLocations;
  communityLocationsTemp.splice(index, 1);

  setCommunityLocations([...communityLocationsTemp]);

  batch.commit();

  console.log("Successfully wrote to database!");
}
