// This is to add a volunteer to a volunteer shift by the admin user and save it to the
// database.
import firebase from "../../../../components/Firebase.js";

export default function AddVolunteerToShift(
  volunteer,
  selectedPosition,
  volunteerSchedule,
  setVolunteerSchedule,
  indexOfQuarter,
  date,
  shift,
  newVolunteer,
  volunteerList,
  setVolunteerList,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // The volunteer schedule in a temp dict to be edited.
  const volunteerScheduleTemp = [...volunteerSchedule];

  const volunteerScheduleQuarterTemp = { ...volunteerSchedule[indexOfQuarter] };

  // add the volunteer to the scheduled shift.
  volunteerScheduleQuarterTemp[date][selectedPosition][shift].volunteers.push(
    volunteer,
  );

  // Update the document in the database.
  const docRef = database
    .collection("VolunteerSchedule")
    .doc(volunteerScheduleTemp[indexOfQuarter].timePeriod);

  // merge with the old document.
  batch.set(docRef, volunteerScheduleQuarterTemp, { merge: true });

  // If the volunteer is brand new then we also want to add them to the volunteer list
  // so they are easier to add next time and don't have to be searched for.
  if (newVolunteer) {
    const volunteerDocRef = database
      .collection("VolunteerList")
      .doc("Volunteers");

    // Update the database.
    batch.set(
      volunteerDocRef,
      { [volunteer.userId]: volunteer },
      { merge: true },
    );
    // The temporary volunteer list.
    const volunteerListTemp = { ...volunteerList };
    // Add the volunteer to the volunteer list.
    volunteerListTemp[volunteer.userId] = volunteer;
    // set the volunteer list so it is ready to be used.
    setVolunteerList({ ...volunteerListTemp });
  }

  batch.commit();
  volunteerScheduleTemp[indexOfQuarter] = { ...volunteerScheduleQuarterTemp };
  setVolunteerSchedule([...volunteerScheduleTemp]);
}
