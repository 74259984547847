// This function is used to take in the community orders and the imported orders
// and combines them into one list broken down by distribution location and pickup location and to whether
// the items were pantry or newly ordered.  It will then sort the list of items
// alphabetically by their item name.  Structure of the return list is
// {distributionLocationName : {pickupLocation : {"orderedItems" : [{foodItem}, ...], "pantryItems" : [{pantryItem, ...}]}}}

// This function compares the item names of the food and sorts them alphabetically.
function compareItemNames(item1, item2, key) {
  const itemName1 = item1[key].toUpperCase();
  const itemName2 = item2[key].toUpperCase();

  if (itemName1 < itemName2) {
    return -1;
  }
  if (itemName1 > itemName2) {
    return 1;
  }
  return 0;
}

// Creates an array of the food items sorted alphabetically by item name from
// the dictionary list of foodItems.
function createFoodArray(foodDict, foodArray) {
  // Cycle through the dictionary by the pickup location.
  Object.keys(foodDict).forEach((pickupLocation) => {
    // Initialise the array of the foodArray for this pickupLocation.
    foodArray[pickupLocation] = [];
    // Cycle through the foodItems from this pickupLocation.
    Object.keys(foodDict[pickupLocation]).forEach((food) => {
      // Add this foodItem to the pickupLocations food list.
      foodArray[pickupLocation].push(foodDict[pickupLocation][food]);
    });
    // Sort the items alphabetically
    foodArray[pickupLocation].sort((a, b) => {
      return compareItemNames(a, b, "item");
    });
  });

  return foodArray;
}

// This function is used to create a dictionary of all the foodItems and count up
// how many are need per location and what customers are ordering them and how many.
function tallyUpFoodItems(food, foodDict, name, pickupLocation) {
  // Check if this pickupLocation already exists and if not initialise it.
  if (!foodDict[pickupLocation]) {
    foodDict[pickupLocation] = {};
  }
  // Check to see if the item is already in the dictionary.
  if (
    food.item + food.description + food.farmName in
    foodDict[pickupLocation]
  ) {
    // Update the quantity to update the exisiting foodItem.
    foodDict[pickupLocation][
      food.item + food.description + food.farmName
    ].quantity += food.quantity;
    // Check if the customer's name already exists and if they've ordered this item in another order.
    // If so then just add the quantities together.
    if (
      name in
      foodDict[pickupLocation][food.item + food.description + food.farmName]
        .customers
    ) {
      foodDict[pickupLocation][
        food.item + food.description + food.farmName
      ].customers[name] += food.quantity;
    }
    // If this is a new customer ordering then set their amount to the quantity they ordered.
    else {
      foodDict[pickupLocation][
        food.item + food.description + food.farmName
      ].customers[name] = food.quantity;
    }
  }
  // If the item doesn't exist then add the foodItem.
  else {
    foodDict[pickupLocation][food.item + food.description + food.farmName] = {
      ...food,
    };
    // If this item is new then we have to initialise the customers dictionary.
    foodDict[pickupLocation][
      food.item + food.description + food.farmName
    ].customers = {};
    // Set the customer's quantity to be used later.
    foodDict[pickupLocation][
      food.item + food.description + food.farmName
    ].customers[name] = food.quantity;
  }

  return { ...foodDict };
}

// The main function that takes in the communityOrders and the importedOrders and then spits out a dictionary
// with the per location breakdown of the foodItems ordered and taken from the pantry.
export default function SortWholeCommunityOrders(
  communityOrders,
  importedOrder,
  organisationName,
) {
  // Set the return list.  We know that the user will have it's own customers so we can intialize and the Onsite pickup items.
  const customerFoodListPerLocation = {
    [organisationName]: { Onsite: { orderedItems: [], pantryItems: [] } },
  };

  // Cycle through the imported orders to check if there are any and then initialize them as well.
  Object.keys(importedOrder).forEach((distributionLocation) => {
    customerFoodListPerLocation[distributionLocation] = {
      Onsite: { orderedItems: [], pantryItems: [] },
    };
  });

  // Cycle through all the locations and sort their customer orders.
  Object.keys(customerFoodListPerLocation).forEach(
    (distributionLocation, i) => {
      // The food list in a Dictionary. Will be used to combine order
      let foodDict = { Onsite: {} };
      // The foodArray is a dictionary that holds arrays. Will be used to combine order
      let foodArray = { Onsite: [] };
      // The pantry list in a Dictionary. Will be used to combine order
      let pantryFoodDict = { Onsite: {} };
      // The pantryFoodArray is a dictionary that holds arrays. Will be used to combine order
      let pantryFoodArray = { Onsite: [] };

      // If this is the first time through the loop use the current location's customer orders otherwise
      // set it to the imported orders.
      let distributionLocationCommunityOrders = communityOrders;
      if (i !== 0) {
        distributionLocationCommunityOrders =
          importedOrder[distributionLocation].communityOrders;
      }

      // If there is isn't a bunch of different locations then it will be undefined and
      // we just need to set it.
      if (distributionLocationCommunityOrders === undefined) {
        distributionLocationCommunityOrders = {};
      }

      // Take the communityOrders that was taken from the database and get each of
      // their keys.
      Object.keys(distributionLocationCommunityOrders).forEach((customer) => {
        // Create a customer Order equal to this will be the new community orders
        // with all the orders properly aggregated.
        const customerOrder = {
          ...distributionLocationCommunityOrders[customer],
        };

        // Dictionaries were the easiest way to combine things since the array's are
        // just placed in random arrays

        // Creates a dictionary of the food list from the community's orders.  If the item
        // has already been added it updates the quantity to reflect the new amount.  If the
        // item is a pantry item then do the same but with the pantryFoodItems.

        // Split up the customer order into an array [firstName, lastName, userId, dateOfOrder]
        const user = customer.split("-");
        // Combine the firstName-lastName-userId
        const name = [user[0], user[1], user[2]].join("-");

        // Default that the user is picking up their order on site of the distribution location
        let pickupLocation = "Onsite";
        // Make sure that communityPickup is defined.
        if (customerOrder.communityPickup !== undefined) {
          // If the user is picking up from a community hub update their pickupLocation.
          if (customerOrder.communityPickup.locationName) {
            // the pickupLocation is the name of the community location.
            pickupLocation = customerOrder.communityPickup.locationName;
            // Initialise the community pickup location.  It will be initialised everytime another user orders from them
            // but since we don't update the values until after all customers have been reviewed it doesn't matter if it's
            // reset.
            customerFoodListPerLocation[distributionLocation][pickupLocation] =
              { orderedItems: [], pantryItems: [] };
          }
        }
        // Cycle through the customer's order.
        customerOrder.foodList.forEach((food) => {
          // Check to see if the item is from the pantry.
          if (food.limit) {
            // Create the pantryFoodDict which has all the foodItems from the pantry per pickupLocation.
            pantryFoodDict = tallyUpFoodItems(
              food,
              pantryFoodDict,
              name,
              pickupLocation,
            );
          }
          // If the limit doesn't exist then it is a new foodItem.
          else {
            // Create the foodDict which has all the foodItems that were ordered new per pickupLocation.
            foodDict = tallyUpFoodItems(food, foodDict, name, pickupLocation);
          }
        });
      });

      // Change the foodDict into an array.
      foodArray = createFoodArray(foodDict, foodArray);
      // Change the pantryFoodDict into an array.
      pantryFoodArray = createFoodArray(pantryFoodDict, pantryFoodArray);

      // Cycle through the pickupLocations and set the customerFoodListPerLocation to have the list
      // of foodItems at that pickupLocation.
      Object.keys(foodArray).forEach((pickupLocation) => {
        // Set the list of food items for the location
        customerFoodListPerLocation[distributionLocation][
          pickupLocation
        ].orderedItems = foodArray[pickupLocation];
      });

      // Cycle through the pickupLocations and set the customerFoodListPerLocation to have the list
      // of pantryFoodItems at that pickupLocation.
      Object.keys(pantryFoodArray).forEach((pickupLocation) => {
        // Set the list of food items for the location
        customerFoodListPerLocation[distributionLocation][
          pickupLocation
        ].pantryItems = pantryFoodArray[pickupLocation];
      });
    },
  );

  return customerFoodListPerLocation;
}
