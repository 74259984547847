// This is a pop up that appears when a distribution location is adding a farm.
// There will be a list of all the farmers that are available and they can add
// any new farm they would like to.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import ColourButton from "../../components/userInputs/ColourButton.js";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import List from "@material-ui/core/List";
// eslint-disable-next-line no-unused-vars
import ListItem from "@material-ui/core/ListItem";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import AddFoodCategories from "../../functions/AddFoodCategories.js";
import UpdateCommunityLocation from "../../functions/UpdateCommunityLocation.js";
import firebase from "./../Firebase.js";
// eslint-disable-next-line no-unused-vars
import LoadingContent from "./../LoadingContent.jsx";

// When a user is adding a farm to the community location.
function handleAddFarm(
  userInfo,
  farm,
  handleUserInfoChange,
  distributionLocationInformation,
  setDistributionLocationInformation,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");
  const userInfoTemp = { ...userInfo };
  // This contains all the information of the distributionLocation
  const distributionLocationInformationTemp = {
    ...distributionLocationInformation,
  };
  // We're updating in the database the organisationName.farmsPickupDates and so
  // we have to join them with dot notation to update
  let locationToUpdate = [userInfo.organisationName, "farmsPickupDates"];
  locationToUpdate = locationToUpdate.join(".");

  // This is for whether the farm is showing the pantry and the catalogue at the same
  // time.
  let locationToUpdateForCatalogueAndPantry = [
    userInfo.organisationName,
    "farmsCatalogueAndPantry",
  ];
  locationToUpdateForCatalogueAndPantry =
    locationToUpdateForCatalogueAndPantry.join(".");

  // Checks to see if a farm already exists if not then we just make the userInfo.farms
  // equal to the farm information and add the to the distributionLocation document.  We
  // set the value of the key/value pair to "Not Set" as this will be replaced with a date
  // when the user wants leftovers to be displayed for this farm.
  if (userInfo.farms[0] === "No farms available please contact developer") {
    userInfoTemp.farms = [{ ...farm }];
  } else {
    userInfoTemp.farms.push({ ...farm });
  }

  // Sets the farmPickupDates to be set to not set.
  if (distributionLocationInformationTemp.farmsPickupDates === undefined) {
    distributionLocationInformationTemp.farmsPickupDates = {};
  }
  distributionLocationInformationTemp.farmsPickupDates[farm.farmName] =
    "Not Set";

  // Sets the farmsCatalogueAndPantry to be set to false.  This means that the default
  // isn't that it is accessing both the catalogue and pantry.
  if (
    distributionLocationInformationTemp.farmsCatalogueAndPantry === undefined
  ) {
    distributionLocationInformationTemp.farmsCatalogueAndPantry = {};
  }
  distributionLocationInformationTemp.farmsCatalogueAndPantry[
    farm.farmName
  ] = false;

  // Update the distributionLocation's farmsPickupDates and the distributionLocation's farmsCatalogueAndPantry data.
  batch.update(distributionLocationsDocRef, {
    [locationToUpdate]: {
      ...distributionLocationInformationTemp.farmsPickupDates,
    },
    [locationToUpdateForCatalogueAndPantry]: {
      ...distributionLocationInformationTemp.farmsCatalogueAndPantry,
    },
  });
  // Updates any community locations that may exist that have this distribution location as their partner.
  UpdateCommunityLocation(userInfo.userId, distributionLocationInformationTemp);

  // Updates the food Categories of the distribution location and commits the the
  // the changes to the database for both the farmsPickupDates and the Categories
  // fields.  It also updates the setDistributionLocationInformation with distributionLocationInformationTemp
  AddFoodCategories(
    distributionLocationInformationTemp,
    setDistributionLocationInformation,
    farm,
    batch,
    distributionLocationsDocRef,
    userInfo,
  );

  // Update the userInfo
  handleUserInfoChange({ ...userInfoTemp }, true);

  return null;
}

// This function takes in a url and then opens it in another tab.  Used to open
// The farmer's websites
function handleListItemClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

function SimpleDialog(props) {
  const { onClose, open } = props;

  // This is set to false until the data from the database been loaded
  // const [setLoading] = useState(false);

  // The ingredientList will be where the entire list of ingredients that a single
  // restaurant has stored as a dictionary with the key being the name of the ingredient
  // and the details of the ingredient stored in a dictionary as the value of the key
  const [farmList, setFarmList] = useState("Loading");

  // This is set to false until the data from the database been loaded
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const database = firebase.firestore();

    const dataTransfer = [];
    // Checks the database for ongoing recurring orders under the current user's
    // profile.
    const docRef = database
      .collection("Farmers")
      .where("status", "==", "active");

    docRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          dataTransfer.push(doc.data());
        });
        // Uses the data from the documents to create the dictionary of all
        // the locations participating in this deliveryDay.
        // setLoading(true);
        setFarmList([...dataTransfer]);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <span style={{ display: "flex", flexDirection: "row-reverse" }}>
        <button onClick={handleClose} className="Close-Button">
          x
        </button>
      </span>
      <div style={{ margin: "10px" }}>
        <DialogTitle id="simple-dialog-title">Add Farms</DialogTitle>
        {farmList !== "Loading" ? (
          <List className="List-Container">
            {farmList.map((farm, index) => (
              <div key={index}>
                <ListItem>
                  <span className="Farm-Add-Row">
                    <span className="Farm-Info-Button">
                      <span>{farm.farmName} </span>
                      <button className="Naked-Button">
                        <InfoTwoToneIcon color="primary" />
                      </button>
                    </span>
                    <span style={{ minWidth: "200px" }}>
                      {" "}
                      Delivery Day(s): {farm.deliveryDay.join("/")}{" "}
                    </span>
                    {props.userInfo.farms.some(
                      (e) => e.farmName === farm.farmName,
                    ) ? (
                      <Button variant="contained" disabled>
                        Added
                      </Button>
                    ) : (
                      <ColourButton
                        hoverColour="#1c8c1c"
                        colour="#17c917"
                        title="Add"
                        onClick={() =>
                          handleAddFarm(
                            props.userInfo,
                            farm,
                            props.handleUserInfoChange,
                            props.distributionLocationInformation,
                            props.setDistributionLocationInformation,
                          )
                        }
                        style={{ minWidth: "50px" }}
                      />
                    )}
                  </span>
                </ListItem>
                <ListItem>
                  <div className="Farm-Info">
                    <div className="Farm-Info-Column1">
                      <span> Farmer: {farm.farmName} </span>
                      <span>
                        {" "}
                        Order Cut-off Day(s): {farm.orderCutoffDay.join(
                          "/",
                        )}{" "}
                      </span>
                      <span>
                        {" "}
                        Delivery Day(s): {farm.deliveryDay.join("/")}{" "}
                      </span>
                      <span> Minimum Order: {farm.minimumOrder} </span>
                      <span>
                        {" "}
                        Website:{" "}
                        <Button
                          color="primary"
                          onClick={() => handleListItemClick(farm.farmURL)}
                        >
                          Website
                        </Button>
                      </span>
                    </div>
                    <div className="Farm-Info-Column2">
                      <span> Email: {farm.farmerEmail} </span>
                      <span> Number: {farm.farmNumber} </span>
                      <span> Address: {farm.farmLocation} </span>
                    </div>
                  </div>
                </ListItem>
              </div>
            ))}
          </List>
        ) : (
          <LoadingContent inline={true} />
        )}
      </div>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function FarmsDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        ADD FARMS
      </Button>
      {open && (
        <SimpleDialog
          open={open}
          onClose={handleClose}
          userInfo={props.userInfo}
          handleUserInfoChange={props.handleUserInfoChange}
          distributionLocationInformation={
            props.distributionLocationInformation
          }
          setDistributionLocationInformation={
            props.setDistributionLocationInformation
          }
        />
      )}
    </div>
  );
}
