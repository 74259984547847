// This is the user receipts called by the basket page and displays all the community
// orders by the location on the distribution location's basket page.
import React from "react";
import Button from "@material-ui/core/Button";
import RemoveCustomerOrderDialog from "../../../components/dialogs/RemoveCustomerOrderDialog.jsx";
import AddItemToOrderDialog from "../../../components/dialogs/AddItemToOrderDialog.jsx";
import SubsidyDonationChangeDialog from "../../../components/dialogs/SubsidyDonationChangeDialog.jsx";
import RemoveFoodItemCommunityOrdersBasket from "../../../components/ModifyOrders/RemoveFoodItemCommunityOrdersBasket.js";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";
import VolunteerFeeChangeDialog from "../../../components/dialogs/VolunteerFeeChangeDialog.jsx";

// This is used to print on the webpage the user receipts.  This is exactly the
// same as the orderString but is in html and can be read by the browser instead
// of the docx file.
export default function UserReceiptBasket({
  idx,
  userOrder,
  handleCommunityOrderChange,
  user,
  imported,
  importedOrder,
  location,
  selectedDate,
  userInfo,
  editing,
  foodItemsToSelect,
  setFoodItemsToSelect,
  displayedFoodItemsToSelect,
  setDisplayedFoodItemsToSelect,
}) {
  // Splits up the user into an array, firstName, lastName, userId.
  const name = user.split("-");

  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);

  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);

  // The total including the donation or subsidy
  let grandTotal = 0;

  // The total packaging fee.
  let packageFeeTotal = 0;

  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion;
  // The total volunteer Fee Credit
  const volunteerFeeCredits = userOrder.creditPortion;

  // The total of the order not including the donation or subsidy
  let costTotal = 0;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
    costTotal += farmTotals[farmTotal];
  });

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
    costTotal += packageTotals[packageTotal];
    grandTotal += packageTotals[packageTotal];
  });

  // Adds or subtracts the user's donations or subsidies.
  if (userOrder.customerContribution === "donation") {
    grandTotal += userOrder.donationSubsidy;
  } else {
    grandTotal -= userOrder.donationSubsidy;
  }

  // If the user has a volunteer cash portion then we want to add this to the total
  // cost and the grand total.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
    costTotal += parseFloat(volunteerFeeCash);
  }

  // Make sure that the grandTotal isn't ever negative if it's rounding to be 0.
  if (grandTotal < 0 && grandTotal > -0.01) {
    grandTotal = 0;
  }

  return (
    <div key={idx}>
      <span className="User-Name-Remove-Button">
        <span className="Name-Guaranteed-Pickup">
          <h5>
            {name[0]} {name[1]} :{" "}
          </h5>
        </span>

        {editing && (
          <RemoveCustomerOrderDialog
            handleCommunityOrderChange={handleCommunityOrderChange}
            user={user}
            imported={imported}
            importedOrder={importedOrder}
            location={location}
            selectedDate={selectedDate}
            userInfo={userInfo}
            userOrder={userOrder}
          />
        )}
      </span>
      {editing && (
        <AddItemToOrderDialog
          idx={idx}
          userOrder={userOrder}
          handleCommunityOrderChange={handleCommunityOrderChange}
          user={user}
          imported={imported}
          importedOrder={importedOrder}
          location={location}
          selectedDate={selectedDate}
          userInfo={userInfo}
          foodItemsToSelect={foodItemsToSelect}
          setFoodItemsToSelect={setFoodItemsToSelect}
          displayedFoodItemsToSelect={displayedFoodItemsToSelect}
          setDisplayedFoodItemsToSelect={setDisplayedFoodItemsToSelect}
        />
      )}
      {userOrder.foodList.map((food, itemNumber) => {
        // Set the price of the individal item
        let individualPrice = parseFloat(
          food.price / (food.distributionQuantity / food.individualQuantity),
        );
        // The individual description of the food item for the user to know how
        // much they are purchasing.
        let individualDescription = food.individualDescription;

        // if suggestedPrice is true then we want to set the price to 0
        if (food.suggestedPrice) {
          // First add to the description the suggested Donation.
          individualDescription = individualDescription.concat(
            " - Suggested Donation of $",
            individualPrice,
          );
          // Then reset the prices to 0.
          individualPrice = 0.0;
        }

        if (food.packageFee > 0) {
          individualPrice += parseFloat(food.packageFee);
        }
        const individualPriceTotal = parseFloat(
          individualPrice * food.quantity,
        ).toFixed(2);
        individualPrice = individualPrice.toFixed(2);
        return (
          <span key={itemNumber}>
            {/* This is necessary to deal with the old database before the foodItems
            for both individual and distribution location's were combined */}
            {food.individualDescription === undefined ? (
              <p style={{ margin: "0px 0px 0px 20px" }}>
                {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
                {food.description} x{food.quantity}
              </p>
            ) : (
              <p style={{ margin: "0px 0px 0px 20px" }}>
                {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
                {individualDescription} x{food.quantity} -- ${individualPrice}{" "}
                (${individualPriceTotal})
                {food.packageFee > 0 && (
                  <>
                    *
                    <p className="Item-Package-Fee">
                      *includes package fee $
                      {parseFloat(food.packageFee).toFixed(2)}/per item
                    </p>
                  </>
                )}
                {editing && (
                  <span>
                    <Button
                      color="secondary"
                      onClick={() =>
                        RemoveFoodItemCommunityOrdersBasket(
                          userOrder,
                          handleCommunityOrderChange,
                          user,
                          imported,
                          importedOrder,
                          location,
                          selectedDate,
                          itemNumber,
                          userInfo,
                        )
                      }
                    >
                      Remove
                    </Button>
                  </span>
                )}
              </p>
            )}
          </span>
        );
      })}
      <div className="Price-Totals">
        <h5 className="Contact-Method-Title Food-Item-Header">
          {" "}
          Farm Price Totals{" "}
        </h5>
        {Object.keys(farmTotals).map((farmTotal, index) => (
          <p key={farmTotal + index} className="Prices-Per-Farm">
            {farmTotal} : ${farmTotals[farmTotal].toFixed(2)}
            {packageTotals[farmTotal] > 0 && (
              <span
                style={{ verticalAlign: "super" }}
                className="Item-Package-Fee"
              >
                + ${packageTotals[farmTotal].toFixed(2)} pkg fee
              </span>
            )}
          </p>
        ))}

        <span
          style={{
            fontSize: "12px",
            alignItems: "end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {packageFeeTotal > 0 && (
            <p className="Total-Packaging-Fees">
              Total Package Fees : ${packageFeeTotal.toFixed(2)}
            </p>
          )}
          <span className="Customer-Payment-Edit-Row">
            {editing && (
              <VolunteerFeeChangeDialog
                userOrder={userOrder}
                handleCommunityOrderChange={handleCommunityOrderChange}
                user={user}
                imported={imported}
                importedOrder={importedOrder}
                userInfo={userInfo}
                locationName={location}
                selectedDate={selectedDate}
              />
            )}
            {parseFloat(volunteerFeeCash) > 0 && (
              <p className="Total-Packaging-Fees">
                Participation Fee (Cash) : ${volunteerFeeCash}
              </p>
            )}
            {parseFloat(volunteerFeeCredits) > 0 && (
              <p className="Total-Packaging-Fees">
                Participation Fee (Credits) : {volunteerFeeCredits}
              </p>
            )}
          </span>
        </span>

        <span className="Customer-Payment-Edit-Row">
          {editing && (
            <SubsidyDonationChangeDialog
              idx={idx}
              userOrder={userOrder}
              handleCommunityOrderChange={handleCommunityOrderChange}
              user={user}
              imported={imported}
              importedOrder={importedOrder}
              location={location}
              selectedDate={selectedDate}
              userInfo={userInfo}
              costTotal={costTotal}
              packageFeeTotal={packageFeeTotal}
            />
          )}
          {userOrder.customerContribution === "donation" ? (
            <p className="Subsidy-Donation-Totals" style={{ color: "green" }}>
              Donation : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          ) : (
            <p className="Subsidy-Donation-Totals" style={{ color: "red" }}>
              Subsidy : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          )}
        </span>
        <p>
          <b>Total </b>: ${grandTotal.toFixed(2)}
        </p>
      </div>
    </div>
  );
}
