export default function CreateUsersRemovedItems(
  foodItemUpdate,
  order,
  userOrder,
  usersWithFoodItemsRemoved,
  location,
) {
  // This is the user's foodItem and contains the stats of this foodItem
  // for the user not the distribution location aka the quantity of the purchase.
  let usersFoodItemUpdate = { ...foodItemUpdate };
  // Is the index of the foodItem in the community member's foodList instead
  // of the distribution location's foodList.
  let indexOfFoodItem = 0;

  // This is an imported
  if (location) {
    // Finds the index of the foodItem in the community member's foodList that
    // matches the foodItem the distribution location is removing.
    indexOfFoodItem = order.importedOrder[location].communityOrders[
      userOrder
    ].foodList.findIndex((element) => {
      return (
        element.item === usersFoodItemUpdate.item &&
        element.farmName === usersFoodItemUpdate.farmName
      );
    });
    // Updates the user's foodItem to the corrected one.  This will hold all the
    // correct limits and quantities of the user.
    usersFoodItemUpdate =
      order.importedOrder[location].communityOrders[userOrder].foodList[
        indexOfFoodItem
      ];
  } else {
    // Finds the index of the foodItem in the community member's foodList that
    // matches the foodItem the distribution location is removing.
    indexOfFoodItem = order.communityOrders[userOrder].foodList.findIndex(
      (element) => {
        return (
          element.item === usersFoodItemUpdate.item &&
          element.farmName === usersFoodItemUpdate.farmName
        );
      },
    );
    // Updates the user's foodItem to the corrected one.  This will hold all the
    // correct limits and quantities of the user.
    usersFoodItemUpdate =
      order.communityOrders[userOrder].foodList[indexOfFoodItem];
  }

  // Sets the user's firstName-lastName-userId to be used to save the user's
  // removed foodItems.
  const user = [
    userOrder.split("-")[0],
    userOrder.split("-")[1],
    userOrder.split("-")[2],
  ].join("-");
  // Check if the user has had any items removed yet.
  if (usersWithFoodItemsRemoved[user] === undefined) {
    // If not create an empty array.
    usersWithFoodItemsRemoved[user] = [];
  }
  // Add the removed user's foodItem.
  usersWithFoodItemsRemoved[user].push({ ...usersFoodItemUpdate });

  return { ...usersWithFoodItemsRemoved };
}
