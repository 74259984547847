// This loads the document that contains all the Vancouver distribution locations
import React, { useState } from "react";
import useFirestore from "./useFirestore";

export default function useDistributionLocations() {
  const database = useFirestore();
  const [error, setError] = useState();
  // false if done loading and true if currently loading
  const [loading, setLoading] = useState(false);
  // Stores all the names of distribution locations in the region
  const [locations, setLocations] = useState([]);
  // Stores the details the distribution location such as address, userID, etc.
  const [locationDetails, setLocationDetails] = useState([]);

  const pickupLocationDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");
  // Add "Choose a Distribution Location" to the start of the array to give a default
  // selection.  The names of location will be added to this array
  const dataTranfer = ["Choose a Distribution Location"];
  // This is used to store the details of the location;
  const dataTranfer2 = [];

  const doEffect = React.useCallback(async () => {
    setLoading(false);
    pickupLocationDocRef
      .get()
      .then((doc) => {
        Object.keys(doc.data()).forEach((location) => {
          // add the location name
          dataTranfer.push(location);
          // add the details
          dataTranfer2.push(doc.data()[location]);
        });
        setLocationDetails([...dataTranfer2]);
        setLocations([...dataTranfer]);
        setLoading(true);
      })
      .catch(function (error) {
        // setLoading(true);
        console.log("Error getting document:", error);
        setError(error);
      });
  }, []);

  React.useEffect(() => {
    doEffect();
  }, [doEffect]);

  return { locations, locationDetails, loading, error };
}
