import firebase from "../../../../components/Firebase.js";
import UsersToEmailExtraUpdates from "../EmailFunctions/UsersToEmailExtraUpdates.js";
import VolunteerFeeAdjustment from "../../../../components/ModifyOrders/VolunteerFeeAdjustment.js";
import PropTypes from "prop-types"; // Import PropTypes

/**
 * Function to handle updating extra quantities, modifying user orders, and sending emails.
 *
 * @param {Object} extraQuantitiesDict - Dictionary containing extra quantities information.
 * @param {Object} communityOrders - Object containing community orders information.
 * @param {Object} userInfo - Object containing user information.
 */
export default function ExtraQuantitiesEmailsAndUpdate(
  extraQuantitiesDict,
  communityOrders,
  userInfo,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const modifiedUserOrders = {};

  // Update all the users
  Object.keys(extraQuantitiesDict).forEach((foodItem) => {
    const foodItemSplit = foodItem.split("-");
    // Find the document name in FoodItems in the database.
    const documentName = foodItemSplit.join("").replace(/\s/g, "");

    // Create a document reference to the foodItem being updated.
    const foodItemsDocRef = database.collection("FoodItems").doc(documentName);

    // Update farmLimit in FoodItems
    batch.update(foodItemsDocRef, {
      farmLimit: firebase.firestore.FieldValue.increment(
        -parseInt(extraQuantitiesDict[foodItem].totalExtra),
      ),
    });

    // Cycle through all the foodItems that have users that didn't get their
    // full order.
    Object.keys(extraQuantitiesDict[foodItem]).forEach((userOrder) => {
      // Skip the key that is totalExtra as that's for keeping track
      // of how many extra of an item were met and need to be removed from
      // the farmLimt.
      if (userOrder !== "totalExtra") {
        // Split the user firstName-lastName-userId-orderDate
        const userOrderSplit = userOrder.split("-");
        // Set the user's order document
        const userOrderDocRef = database
          .collection("Users")
          .doc(userOrderSplit[2])
          .collection("Orders")
          .doc(userOrderSplit[3]);
        // The index of the foodItem in the community member's foodList.
        const foodItemIndex = communityOrders[userOrder].foodList.findIndex(
          (customerFoodItem) => {
            return (
              foodItemSplit[0] === customerFoodItem.item &&
              foodItemSplit[1] === customerFoodItem.description &&
              foodItemSplit[2] === customerFoodItem.farmName
            );
          },
        );
        // The quantity of the now updated order.
        const quantity =
          communityOrders[userOrder].foodList[foodItemIndex].quantity;
        // What the user had originally ordered.
        const originalQuantity =
          quantity + extraQuantitiesDict[foodItem][userOrder];
        // Creating a temp variable to hold the foodList of the community member.
        const foodListTemp = communityOrders[userOrder].foodList;
        // This is used to send for the VolunteerFeeAdjustment.  We set the quantity
        // to the amount of the foodItem we're removing.
        const foodItemRemoving =
          communityOrders[userOrder].foodList[foodItemIndex];
        foodItemRemoving.quantity = originalQuantity - quantity;
        // The individual description to be shown to the user.
        const individualDescription =
          communityOrders[userOrder].foodList[foodItemIndex]
            .individualDescription;
        // We use this to calculate send credits back to a user if we removed them from their order.
        // We have to do this again since we only want to refund the credits once we place the order.
        VolunteerFeeAdjustment(
          communityOrders[userOrder],
          userOrderSplit[2],
          userInfo,
          foodItemRemoving,
          database,
          batch,
          true,
        );

        // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
        // FirstName-LastName-userId-date
        batch.update(userOrderDocRef, {
          foodList: foodListTemp,
          cashPortion: communityOrders[userOrder].cashPortion.toFixed(2),
          creditPortion: communityOrders[userOrder].creditPortion.toFixed(2),
        });
        // Check to see if the user has has a few items that have been removed
        // and if they've already been added or not.
        if (userOrder in modifiedUserOrders) {
          modifiedUserOrders[userOrder][foodItem] = {
            original: originalQuantity,
            adjusted: quantity,
            individualDescription,
          };
        } else {
          modifiedUserOrders[userOrder] = {
            [foodItem]: {
              original: originalQuantity,
              adjusted: quantity,
              individualDescription,
            },
          };
        }
      }
    });
  });

  // Send user email updates.
  UsersToEmailExtraUpdates(modifiedUserOrders);
  // Confirm all the database commits.
  batch.commit();
}

// PropTypes for the function parameters
ExtraQuantitiesEmailsAndUpdate.propTypes = {
  extraQuantitiesDict: PropTypes.object.isRequired,
  communityOrders: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
};
