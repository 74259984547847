// This is used to take in a person's information for when they volunteer.
import React, { useState, useContext } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AuthContext } from "../../../components/authentication/Auth.js";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import SimpleSelect from "../../../components/userInputs/DropDownMenu.js";
// eslint-disable-next-line no-unused-vars
import FormControl from "@material-ui/core/FormControl";
// eslint-disable-next-line no-unused-vars
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// eslint-disable-next-line no-unused-vars
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// eslint-disable-next-line no-unused-vars
import LoadingContent from "../../../components/LoadingContent.jsx";

export default function VolunteerIntakeDialog({
  setSendingApplication,
  volunteerJobs,
}) {
  const [open, setOpen] = useState(false);
  // Their city.
  const [city, setCity] = useState("");
  // the position they're applying to.
  const [position, setPosition] = useState({});
  // Their availability.
  // eslint-disable-next-line no-unused-vars
  const [availability, setAvailability] = useState("");
  // Why they want to volunteer write up.
  const [why, setWhy] = useState();
  // Any other comments they want to add write up.
  const [additionalComments, setAdditionalComments] = useState("");
  // Used to track which shifts have been selected by the user with the key being
  // the index of the shift in the list and the value being true/false if it has been
  // checked or not. ex. {0 : false, 1 : false, 2 : true}
  const [shift, setShift] = useState({});
  // currentUser is if the user is logged in, userInfo is the user's data and handleUserInfoChange
  // is if the user has to be updated.
  const { currentUser, userInfo } = useContext(AuthContext);
  // The list of all the position titles that are used to for the drop down for the user
  // to select from.
  const volunteerPositionList = [];
  // Cycle through all the volunteer jobs and add their titles to the volunter
  // position list.
  volunteerJobs.forEach((volunteerPosition) => {
    volunteerPositionList.push(volunteerPosition.title);
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCity("");
    setPosition("");
    setAvailability("");
    setWhy("");
    setAdditionalComments("");
  };

  // If the user changes the position they've selected.
  const handlePositionChange = (e) => {
    // The index of the volunteer position in the list.
    const index = volunteerJobs.findIndex(
      (volunteerJob) => e.target.value === volunteerJob.title,
    );
    // If it exists then set the selected position to this volunteer job.
    if (index !== -1) {
      setPosition(volunteerJobs[index]);
    }
    // Reset the selected shifts nothing.
    setShift({});
  };

  // Once the user hits send then their application will be sent but we need to make sure
  // they've completed all the fields first.  Then they will receive an email.
  const handleSave = (e) => {
    e.preventDefault();
    // Check to make sure they've checked at least one shift.  Otherwise show them an alert.
    if (!Object.values(shift).includes(true)) {
      // eslint-disable-next-line no-undef
      alert(
        "Please select an available shift before sending in your application.",
      );
    }
    // They've checked at least one shift.
    else {
      // Sending application true so that there is an indication to the user to wait
      // while the email is set.
      setSendingApplication(true);
      // A string of their selected shifts to include in the email.
      let selectedShifts = "";

      // Cycle through their selected shifts to include them in their email.
      Object.keys(shift).forEach((index) => {
        if (shift[index]) {
          const shiftAvailable = position.shiftsAvailable[index];
          const shiftString = [
            shiftAvailable.DayOfTheWeek,
            " ",
            shiftAvailable.StartTime,
            "-",
            shiftAvailable.EndTime,
            " ",
            shiftAvailable.Frequency,
          ].join("");
          selectedShifts = selectedShifts.concat(`• ${shiftString} <br/>`);
        }
      });

      // Sets the applicant information into one variable.
      const applicantInfo = {
        name: userInfo.firstName,
        city,
        phoneNumber: userInfo.phoneNumber,
        position: position.title,
        selectedShifts,
        why,
        additionalComments,
      };

      // Sets the email cloud function.
      const sendVolunteerApplication = firebase
        .functions()
        .httpsCallable("sendVolunteerApplication");

      // Sets the destination of the email to the user and the volunteer coordinator email.
      const dest = [userInfo.email, "farmtoplatemarketplace@spec.bc.ca"];

      // Call the sendVolunteerApplication cloud Function on firebase.
      sendVolunteerApplication({ dest, applicantInfo })
        .then(function (result) {
          setSendingApplication(false);
          console.log("The result is", result);
        })
        .catch(function (error) {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          console.error(
            "There was an error when calling the Cloud Function",
            error,
          );
          window.alert(
            "There was an error when calling the Cloud Function:\n\nError Code: " +
              code +
              "\nError Message:" +
              message,
          );
        });

      setOpen(false);
      setCity("");
      setPosition("");
      setAvailability("");
      setWhy("");
      setAdditionalComments("");
    }
  };

  // If the user has changed their selected shifts in the checkboxes.
  const handleShiftAvailableChange = (e) => {
    const target = e.target;
    // Set the selected shift to true or false with the index of the item being the key.
    setShift({ ...shift, [target.name]: target.checked });
  };

  return (
    <div>
      <Button
        style={{ background: "#9FBF59", color: "white" }}
        size="large"
        variant="contained"
        onClick={handleClickOpen}
      >
        APPLY TO VOLUNTEER
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Volunteer Application
        </DialogTitle>
        <DialogContent>
          {currentUser ? (
            <form onSubmit={handleSave}>
              <span>
                <TextField
                  margin="dense"
                  id="city"
                  label="City Residence"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  id="Volunteer-Select-Id"
                  margin="dense"
                  value={position.title ? position.title : ""}
                  onChange={(e) => handlePositionChange(e)}
                  label="Select a Volunteer Position"
                  select
                  fullWidth
                  required
                >
                  {volunteerPositionList.map((option, index) => {
                    return (
                      <MenuItem key={option + index} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {Object.keys(position).length !== 0 && (
                  <div>
                    Commitment : {position.intake}
                    <br />
                    Credits : {position.creditValue} credits/shift
                    <div>
                      <h3>Select a Shift(s)*</h3>
                      {position.shiftsAvailable.map(
                        (shiftsAvailable, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                name={index.toString()}
                                checked={
                                  shift[index.toString()]
                                    ? shift[index.toString()]
                                    : false
                                }
                                onChange={handleShiftAvailableChange}
                              />
                            }
                            label={[
                              shiftsAvailable.DayOfTheWeek,
                              " ",
                              shiftsAvailable.StartTime,
                              "-",
                              shiftsAvailable.EndTime,
                              " ",
                              shiftsAvailable.Frequency,
                            ].join("")}
                          />
                        ),
                      )}
                    </div>
                  </div>
                )}
                <TextField
                  margin="dense"
                  id="why"
                  label="Tell us why you're interested in volunteering"
                  name="why"
                  value={why}
                  multiline
                  variant="outlined"
                  rows={4}
                  onChange={(e) => setWhy(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  margin="dense"
                  id="additionalComments"
                  name="additionalComments"
                  label="Other comments or questions"
                  value={additionalComments}
                  multiline
                  variant="outlined"
                  rows={4}
                  onChange={(e) => setAdditionalComments(e.target.value)}
                  fullWidth
                />
              </span>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Send
                </Button>
              </DialogActions>
            </form>
          ) : (
            <div>
              <h4>To join our team please create or sign into your account.</h4>
              <span style={{ display: "flex", gap: "10px" }}>
                <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/SignIn"
                >
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      background: "rgb(225 119 245)",
                      color: "white",
                      margin: "8px 5px",
                    }}
                  >
                    Sign In
                  </Button>
                </Link>
                <Link
                  style={{ textDecoration: "none", width: "100%" }}
                  to="/SignUp"
                >
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      background: "rgb(159, 191, 89)",
                      color: "white",
                      margin: "8px 5px",
                    }}
                  >
                    Sign Up
                  </Button>
                </Link>
              </span>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
