// By clicking the details button on the PaymentHistoryDetailsDialog then you can see
// the details of each community member's payment history.
import React from "react";
import { Grid } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
import EnterCustomerPaymentDialog from "../Dialogs/EnterCustomerPaymentDialog.jsx";

// Calculate the payment details of a community member's order.  You'll see if they
// owe money or need a refund.
function CalculateOrderTotals(paymentDetails) {
  // The total owed or refund needed for the user.
  let finalBalance = 0;
  // Total the user has deposited
  let totalDeposited = 0;
  // Total the user has been refunded.
  let totalRefunded = 0;

  // Cycle through the deposits and add them up.
  paymentDetails.deposits.forEach((deposit) => {
    totalDeposited += deposit.amount;
  });
  // Cycle through the refunds and add them up.
  paymentDetails.orderRefunds.forEach((refund) => {
    totalRefunded += refund.amount;
  });

  // Calculates the final balance of the user's account.
  finalBalance = paymentDetails.totalOwed - (totalDeposited - totalRefunded);

  // Return the finalBalance for the user.
  return [finalBalance, totalDeposited, totalRefunded];
}

export default function UserPaymentDetails({
  paymentDetails,
  user,
  paymentOrderDetails,
  orderPaymentHistory,
}) {
  // Split the user so we can display their name.
  const name = user.split("-");

  // Check the deposits is an array in case it is still stored as a dictionary
  if (!Array.isArray(paymentDetails.deposits)) {
    paymentDetails.deposits = [];
  }
  // Check the orderRefunds is an array in case it is still stored as a dictionary
  if (!Array.isArray(paymentDetails.orderRefunds)) {
    paymentDetails.orderRefunds = [];
  }

  // Calculate the final balance of the user.
  const [finalBalance, totalDeposited, totalRefunded] =
    CalculateOrderTotals(paymentDetails);

  // If the user's order doesn't require payment then show that no payment is required.
  let nothingToDisplay = false;

  // Check to see that nothing was deposited, refunded or owed on this order which
  // means the order was not needing payment.
  if (
    totalDeposited < 0.02 &&
    totalRefunded < 0.02 &&
    paymentDetails.totalOwed < 0.02 &&
    paymentDetails.totalOwed > -0.02
  ) {
    nothingToDisplay = true;
  }

  return (
    <div style={{ borderBottom: "double", marginBottom: "5px" }}>
      {nothingToDisplay && (
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          style={{ textAlign: "center", marginBottom: "5px" }}
        >
          <Grid item xs>
            {name[0]} {name[1]}
          </Grid>
          <Grid item xs>
            ---
          </Grid>
          <Grid item xs>
            ---
          </Grid>
          <Grid item xs>
            No Payment Required
          </Grid>
          <Grid item xs>
            <EnterCustomerPaymentDialog
              orderDate={paymentOrderDetails.orderDate}
              distributedDate={paymentOrderDetails.distributedDate}
              orderPaymentHistory={{ ...orderPaymentHistory }}
              imported={paymentOrderDetails.imported}
              location={paymentOrderDetails.distributionLocation}
              user={user}
            />
          </Grid>
        </Grid>
      )}
      {paymentDetails.deposits.map((deposit) => (
        <>
          {deposit.amount > 0.01 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              <Grid item xs>
                {name[0]} {name[1]}
              </Grid>
              <Grid item xs>
                ${deposit.amount.toFixed(2)}
              </Grid>
              <Grid item xs>
                {deposit.date}
              </Grid>
              <Grid item xs>
                {deposit.method}
              </Grid>
              <Grid item xs>
                <EnterCustomerPaymentDialog
                  orderDate={paymentOrderDetails.orderDate}
                  distributedDate={paymentOrderDetails.distributedDate}
                  orderPaymentHistory={{ ...orderPaymentHistory }}
                  imported={paymentOrderDetails.imported}
                  location={paymentOrderDetails.distributionLocation}
                  user={user}
                />
              </Grid>
            </Grid>
          )}
        </>
      ))}
      {paymentDetails.orderRefunds.map((refund) => (
        <>
          {refund.amount > 0.01 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              <Grid item xs>
                {name[0]} {name[1]}
              </Grid>
              <Grid item xs>
                ${refund.amount.toFixed(2)}
              </Grid>
              <Grid item xs>
                {refund.date}
              </Grid>
              <Grid item xs>
                Refund
              </Grid>
              <Grid item xs>
                <EnterCustomerPaymentDialog
                  orderDate={paymentOrderDetails.orderDate}
                  distributedDate={paymentOrderDetails.distributedDate}
                  orderPaymentHistory={orderPaymentHistory}
                  imported={paymentOrderDetails.imported}
                  location={paymentOrderDetails.distributionLocation}
                  user={user}
                />
              </Grid>
            </Grid>
          )}
        </>
      ))}
      {finalBalance > 0.01 && (
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          style={{
            textAlign: "center",
            color: "rgb(0 193 6)",
            marginBottom: "5px",
          }}
        >
          <Grid item xs>
            {name[0]} {name[1]}
          </Grid>
          <Grid item xs>
            ${finalBalance.toFixed(2)}
          </Grid>
          <Grid item xs>
            ---
          </Grid>
          <Grid item xs>
            Unpaid
          </Grid>
          <Grid item xs>
            <EnterCustomerPaymentDialog
              orderDate={paymentOrderDetails.orderDate}
              distributedDate={paymentOrderDetails.distributedDate}
              orderPaymentHistory={orderPaymentHistory}
              imported={paymentOrderDetails.imported}
              location={paymentOrderDetails.distributionLocation}
              user={user}
            />
          </Grid>
        </Grid>
      )}
      {finalBalance < -0.01 && (
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          style={{
            color: "rgb(237 57 57)",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <Grid item xs>
            {name[0]} {name[1]}
          </Grid>
          <Grid item xs>
            ${finalBalance.toFixed(2)}
          </Grid>
          <Grid item xs>
            ---
          </Grid>
          <Grid item xs>
            Refund Owed
          </Grid>
          <Grid item xs>
            <EnterCustomerPaymentDialog
              orderDate={paymentOrderDetails.orderDate}
              distributedDate={paymentOrderDetails.distributedDate}
              orderPaymentHistory={orderPaymentHistory}
              imported={paymentOrderDetails.imported}
              location={paymentOrderDetails.distributionLocation}
              user={user}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
