// This is a dialog that appears when the user edits their marketplace message from the basket page.
// This will allow the user to type in a new message.  Once the user places the order then it will
// saved to the database.
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useContext } from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "../../styles/MyAccount.css";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

// This is the dialog box that opens when a user clicks the edit button
function BasketMarketplaceMessageBox(props) {
  const { onClose, open } = props;
  const [messageUpdate, setMessageUpdate] = useState(props.marketplaceMessage);

  const handleClose = () => {
    setMessageUpdate(props.marketplaceMessage);
    onClose();
  };

  // This is when the user saves to the database.
  const handleSave = (messageUpdate) => {
    // This alert appears when the user has changed the message to be blank but
    // still has the display message as on which would render a blank message.
    if (
      (messageUpdate === undefined || messageUpdate === "") &&
      props.marketplaceMessageEnabled
    ) {
      // eslint-disable-next-line no-undef
      alert(
        'You can\'t display an empty message.  First you must turn off "Display Marketplace Message".',
      );
    } else {
      onClose();
      props.setMarketplaceMessage(messageUpdate);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Change the Message that will appear on the Markeplace Page
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          multiline
          fullWidth
          label="Marketplace Message"
          name="marketplaceMessage"
          onChange={(e) => setMessageUpdate(e.target.value)}
          value={messageUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button color="primary" onClick={() => handleSave(messageUpdate)}>
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BasketMarketplaceMessageBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function BasketMarketplaceMessageDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <BasketMarketplaceMessageBox
        open={open}
        onClose={handleClose}
        marketplaceMessage={props.marketplaceMessage}
        marketplaceMessageEnabled={props.marketplaceMessageEnabled}
        setMarketplaceMessage={props.setMarketplaceMessage}
      />
    </div>
  );
}
