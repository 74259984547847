// The checkboxes that appear on the Add Food Item dialog.  They
// are used by the user to toggle which farms to show the foodItems of.
import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function FarmCheckboxes({
  farmersAvailable,
  foodItemsToSelect,
  displayedFoodItemsToSelect,
  setDisplayedFoodItemsToSelect,
  setIndexOfSelectedItem,
}) {
  // If the user has toggle the checkbox
  const handleCheckbox = (e, farmer) => {
    // Check if the user is setting it to false or true.
    const checked = e.target.checked;
    // Set a temp array that we can modify.
    const displayedFoodItemsToSelectTemp = [...displayedFoodItemsToSelect];
    // If the checked is true then we're adding the farm's items
    // back to the list.
    if (checked) {
      // Cycle through the foodItems and add back the ones that
      // have the farmer that they've selected.
      foodItemsToSelect.forEach((foodItem) => {
        if (foodItem.farmName === farmer) {
          displayedFoodItemsToSelectTemp.push(foodItem);
        }
      });
    }
    // If the user is unchecking then we'll remove the item.
    else {
      // Remove
      // Since we're removing we have to keep track of how many
      // items have been removed and update the index to match the
      // the updated one.
      let indexOffset = 0;
      // Cycle through the displayed fooditems.
      displayedFoodItemsToSelect.forEach((foodItem, index) => {
        // If the farmer matches then remove the item.
        if (foodItem.farmName === farmer) {
          // Subtract the offset from the index to get the correct
          // index.
          const indexOfModifiedArray = index - indexOffset;
          displayedFoodItemsToSelectTemp.splice(indexOfModifiedArray, 1);
          // Increase the offset.
          indexOffset += 1;
        }
      });
    }
    // Set the selected index to nothing as the user might have
    // removed the foodItem they had selected.
    setIndexOfSelectedItem("");
    setDisplayedFoodItemsToSelect([...displayedFoodItemsToSelectTemp]);
  };

  return (
    <FormGroup>
      {farmersAvailable.map((farmer, index) => (
        <FormControlLabel
          key={index}
          onChange={(e) => handleCheckbox(e, farmer)}
          control={<Checkbox />}
          label={farmer}
        />
      ))}
    </FormGroup>
  );
}
