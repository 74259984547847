// This a dialog displays the totals owed to each farm, total taken and added to the pantry, in grid format.
// It then displays the total customer orders, subsidies, donations, and donated food per location involved with
// an order in grid format.
// The purpose of this grid format is to make copy and pasting to the budget excel sheet easier.
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// Calculates the data per location involved in the order.  Data includes, total
// customer orders, total subsidies, total donations, total donated food amount and total packaging fees.
function CalculateDataPerLocation({
  statsOptions,
  importedSubsidies,
  importedDonations,
  importedCommunityOrdersTotal,
  communityOrdersTotal,
  subsidies,
  donations,
  donationAmount,
  currentLocation,
  packagingFees,
  deliveryFees,
  volunteerCredits,
  volunteerFees,
}) {
  // The total of all the imported distributionlocations stats intialized to 0
  let importedSubsidiesTotals = 0;
  let importedDonationsTotals = 0;
  let importedCommunityOrdersTotals = 0;

  // Sum up the total subsidies, donations and orders from all the imported
  // locations.
  Object.keys(importedSubsidies).forEach((location) => {
    importedSubsidiesTotals += importedSubsidies[location];
    importedDonationsTotals += importedDonations[location];
    importedCommunityOrdersTotals += parseFloat(
      importedCommunityOrdersTotal[location],
    );
  });

  // The dictionary that holds the data of each location.
  const locationsData = {};

  // remove the first "Combined Orders" option as we're only interested in the per location.
  statsOptions.shift();

  // Cycle through the other options which show each location.
  statsOptions.forEach((statsOption) => {
    // Take the last characters of the selections to remove the "Orders stats for"
    // portion of the user's selection to have just the location's name.
    const distributionLocation = statsOption.substring(16);

    // If it is the logged in location's data
    if (distributionLocation === currentLocation) {
      locationsData[distributionLocation] = {};
      locationsData[distributionLocation].customerOrders =
        communityOrdersTotal - importedCommunityOrdersTotals;
      locationsData[distributionLocation].totalSubsidies =
        subsidies - importedSubsidiesTotals;
      locationsData[distributionLocation].totalDonations =
        donations - importedDonationsTotals;
      locationsData[distributionLocation].totalDonatedFood =
        donationAmount[distributionLocation];
      locationsData[distributionLocation].totalPackageFees =
        packagingFees[distributionLocation];
      locationsData[distributionLocation].totalDeliveryFees =
        deliveryFees[distributionLocation];
      locationsData[distributionLocation].totalVolunteerCredits =
        volunteerCredits[distributionLocation];
      locationsData[distributionLocation].totalVolunteerFees =
        volunteerFees[distributionLocation];
    }
    // Imported location's data.
    else {
      locationsData[distributionLocation] = {};
      locationsData[distributionLocation].customerOrders = parseFloat(
        importedCommunityOrdersTotal[distributionLocation],
      );
      locationsData[distributionLocation].totalSubsidies =
        importedSubsidies[distributionLocation];
      locationsData[distributionLocation].totalDonations =
        importedDonations[distributionLocation];
      locationsData[distributionLocation].totalDonatedFood =
        donationAmount[distributionLocation];
      locationsData[distributionLocation].totalPackageFees =
        packagingFees[distributionLocation];
      locationsData[distributionLocation].totalDeliveryFees =
        deliveryFees[distributionLocation];
      locationsData[distributionLocation].totalVolunteerCredits =
        volunteerCredits[distributionLocation];
      locationsData[distributionLocation].totalVolunteerFees =
        volunteerFees[distributionLocation];
    }
  });

  return (
    <div>
      {Object.keys(locationsData).map((locationData, i) => (
        <table key="i" border="1">
          <thead>
            <tr>
              <th>{locationData}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {parseFloat(locationsData[locationData].customerOrders).toFixed(
                  2,
                )}
              </td>
            </tr>
            <tr>
              <td>
                (
                {parseFloat(locationsData[locationData].totalSubsidies).toFixed(
                  2,
                )}
                )
              </td>
            </tr>
            <tr>
              <td>
                {parseFloat(locationsData[locationData].totalDonations).toFixed(
                  2,
                )}
              </td>
            </tr>
            <tr>
              <td>
                (
                {parseFloat(
                  locationsData[locationData].totalDonatedFood,
                ).toFixed(2)}
                )
              </td>
            </tr>
            <tr>
              <td>
                {parseFloat(
                  locationsData[locationData].totalPackageFees,
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>
                {parseFloat(
                  locationsData[locationData].totalDeliveryFees,
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>
                {parseFloat(
                  locationsData[locationData].totalVolunteerCredits,
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>
                {parseFloat(
                  locationsData[locationData].totalVolunteerFees,
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );
}

export default function GridFormatsForBudgeting({
  totals,
  statsOptions,
  importedSubsidies,
  importedDonations,
  importedCommunityOrdersTotal,
  communityOrdersTotal,
  subsidies,
  donations,
  donationAmount,
  currentLocation,
  pantryTotals,
  grandTotal,
  pantryTotal,
  packagingFees,
  deliveryFees,
  volunteerCredits,
  volunteerFees,
}) {
  const [open, setOpen] = useState(false);

  // Calculate the total amount of food that was added to the pantry.
  const addedToPantryAmount =
    grandTotal + pantryTotal - donationAmount.Total - communityOrdersTotal;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // The total cost of the food taken from the pantry.
  let pantryCostTotal = 0;
  // Cycle through the pantryTotals  and add them all up.
  Object.keys(pantryTotals).forEach((farmTotal) => {
    pantryCostTotal += pantryTotals[farmTotal];
  });

  return (
    <div>
      <Button size="small" color="secondary" onClick={handleClickOpen}>
        VIEW TOTALS IN GRID FORMAT
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Grid Format for Budgeting
        </DialogTitle>
        <DialogContent>
          <table id="tableId" border="1">
            <thead>
              <tr>
                <th>Farm Totals $</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(totals).map((farmTotal, i) => (
                <tr key={i}>
                  <td>{parseFloat(totals[farmTotal]).toFixed(2)}</td>
                </tr>
              ))}
              <tr style={{ backgroundColor: "orange" }}>
                <td>{parseFloat(pantryCostTotal).toFixed(2)}</td>
              </tr>
              <tr style={{ backgroundColor: "orange" }}>
                <td>{parseFloat(addedToPantryAmount).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>

          <CalculateDataPerLocation
            statsOptions={[...statsOptions]}
            importedSubsidies={importedSubsidies}
            importedDonations={importedDonations}
            importedCommunityOrdersTotal={importedCommunityOrdersTotal}
            communityOrdersTotal={communityOrdersTotal}
            subsidies={subsidies}
            donations={donations}
            donationAmount={donationAmount}
            currentLocation={currentLocation}
            packagingFees={packagingFees}
            deliveryFees={deliveryFees}
            volunteerCredits={volunteerCredits}
            volunteerFees={volunteerFees}
          />

          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              CLOSE
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
