// This is the tab for the volunteer schedule on the admin user account.
// It will load the volunteer schedule that is most recent and will allow the user
// to add a volunteer shift to the schedule and also selected from a drop down which
// position the user wants to look at and show that volunteer schedule.
import React, { useEffect, useState } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import AddVolunteerShiftDialog from "../Dialogs/VolunteerSchedule/AddVolunteerShiftDialog.jsx";
import LoadingContent from "../../../components/LoadingContent.jsx";
import VolunteerScheduleList from "../Components/VolunteerSchedule/VolunteerScheduleList.jsx";
import LoadMoreVolunteerSchedule from "../Functions/VolunteerSchedule/LoadMoreVolunteerSchedule.js";

export default function VolunteerScheduleTab({ volunteerJobs }) {
  const [volunteerSchedule, setVolunteerSchedule] = useState({});
  const [loading, setLoading] = useState(false);
  // The last loaded document that contains the last quarter that the schedule loaded.
  const [lastLoadedQuarter, setLastLoadedQuarter] = useState(false);
  const database = firebase.firestore();

  useEffect(() => {
    // Load the volunteer collection.
    const volunteerDocRef = database
      .collection("VolunteerSchedule")
      .orderBy("timePeriod", "desc")
      .limit(1);

    const volunteerDataTransfer = [];

    // Cycle through all the found documents and add them to the volunteerJobs hook.
    volunteerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            // Add the new data to the document to the array.
            volunteerDataTransfer.push(doc.data());
            // Since we're only loading one document we know that the last loaded document will
            // be this one.
            setLastLoadedQuarter(doc);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setVolunteerSchedule(volunteerDataTransfer);
        setLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  if (loading) {
    return (
      <div>
        <AddVolunteerShiftDialog
          volunteerSchedule={volunteerSchedule}
          setVolunteerSchedule={setVolunteerSchedule}
        />
        {lastLoadedQuarter !== null && (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              LoadMoreVolunteerSchedule(
                volunteerSchedule,
                setVolunteerSchedule,
                setLoading,
                lastLoadedQuarter,
                setLastLoadedQuarter,
              )
            }
          >
            LOAD PREV SCHEDULE
          </Button>
        )}

        <VolunteerScheduleList
          volunteerSchedule={volunteerSchedule}
          setVolunteerSchedule={setVolunteerSchedule}
          volunteerJobs={volunteerJobs}
        />
      </div>
    );
  } else {
    return (
      <div>
        <LoadingContent inline={true} />
      </div>
    );
  }
}
