import React from "react";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import DialogContent from "@material-ui/core/DialogContent";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";

export default class ChangeTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.props.handleSave.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose(e) {
    this.setState({
      open: false,
    });
  }

  handleSubmit() {
    if (this.state.title !== "") {
      this.handleSave(this.state.title, this.props.name);
      this.setState({
        open: false,
        title: "",
      });
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div>
        <Button color="primary" onClick={this.handleClickOpen}>
          {this.props.title}
        </Button>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            {this.props.title}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Title"
              name="title"
              autoFocus
              value={this.state.title}
              onChange={this.handleChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.handleSubmit();
                }
              }}
            />
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                id="Save-Button"
                type="button"
                color="primary"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
