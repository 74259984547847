// Updates the categories to the distribution Locations that are saved in the
// DistributionLocations -> Vancouver document. It takes in the old and new farms
// and updates both these farms.  If the farm hasn't changed then they'll be the same.
import firebase from "./../components/Firebase.js";
import UpdateCommunityLocation from "../functions/UpdateCommunityLocation.js";

export default function ModifyCategoryAtDistributionLocation(
  newFarmCategories,
  farmToChange,
  oldFarm,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  // Reads the distributionLocation document.
  distributionLocationsDocRef.get().then((doc) => {
    if (doc.exists) {
      // create a dictionary of all the distributionLocations information
      const distributionLocationInformationTemp = doc.data();
      // Cycle through each distribution Location
      Object.keys(distributionLocationInformationTemp).forEach((location) => {
        // Some distribution location's may be missing the category field in
        // which case skip it.
        if (distributionLocationInformationTemp[location].categories) {
          // Cycle through all the distribution Location's farms in the category array
          Object.keys(
            distributionLocationInformationTemp[location].categories,
          ).forEach((farm) => {
            // Check if the farm in the array matches the farm being changed or
            // the old farm if the farm was changed.
            if (farm === farmToChange || farm === oldFarm) {
              // add this category to the farm's array
              distributionLocationInformationTemp[location].categories[farm] =
                newFarmCategories[farm];
            }
          });
        }
      });

      // Update the distributionLocation's locations
      Object.keys(distributionLocationInformationTemp).forEach((location) => {
        batch.set(
          distributionLocationsDocRef,
          { [location]: distributionLocationInformationTemp[location] },
          { merge: true },
        );
        // Updates any community locations that may exist that have this distribution location as their partner.
        UpdateCommunityLocation(
          distributionLocationInformationTemp[location].userId,
          distributionLocationInformationTemp[location],
        );
      });

      // Commit these changes
      batch.commit();
    }
  });
}
