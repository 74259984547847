// Asks the user to select a hub first.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export default function SelectDistributionLink() {
  return (
    <div className="Select-Distribution-Link-Container">
      <div className="Select-Distribution-Link">
        <p style={{ fontSize: "25px", textAlign: "center" }}>
          There may be variances at locations depending on storage capacity and
          food restrictions. Please select your pickup location first to view
          the marketplace and items.
        </p>

        <Link
          style={{ textDecoration: "none" }}
          to={{
            pathname: "/DistributionLocationSelection",
            query: "/Marketplace",
          }}
        >
          <Button
            style={{ background: "#45AA45", color: "white" }}
            size="large"
            variant="contained"
          >
            SELECT PICKUP LOCATION
          </Button>
        </Link>
      </div>
    </div>
  );
}
