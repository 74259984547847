// Load recurring deliveries of an ingredient.
import firebase from "../../../components/Firebase.js";

// This is to give the user the option to view their recurring orders.  They
// press the button and the orders will be loaded.
export default function LoadRecurringOrders(
  setNoRecurringOrders,
  setRecurringOrders,
) {
  const database = firebase.firestore();

  const dataTransfer = [];
  // Checks the database for ongoing recurring orders under the current user's
  // profile.
  const docRef = database
    .collection("IngredientList")
    .where("userId", "==", firebase.auth().currentUser.uid)
    .where("status", "==", "ongoing");

  docRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        dataTransfer.push(doc.data());
      });
      // Uses the data from the documents to create the dictionary of all
      // the locations participating in this deliveryDay.
      if (dataTransfer.length === 0) {
        setNoRecurringOrders(true);
      } else {
        setRecurringOrders([...dataTransfer]);
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
