// This function will update any community locations that may exist that have partnered
// with the curretly changing distribution location.  It is used for any function that
// modifies the distribution location except for the functions called by PlaceOrder and
// PlaceExistingOrder as they use UpdateCommunityLocationFarms.js.  Depending on while part of
// the distribution location is being updated it will update the items.  Aka if the address form,
// personal info, marketplace message or the announcement dialog is being updated.

import firebase from "../components/Firebase.js";

export default function UpdateCommunityLocation(
  userId,
  distributionLocationInformationTemp,
  address,
  personalInfo,
  state,
  updateMessage,
  announcementUpdate,
  questionnaireUpdate,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Reference the document in firebase
  const docRef = database
    .collection("CommunityLocations")
    .where("distributionLocationuserId", "==", userId);

  // Read the document.
  docRef.get().then((collection) => {
    // eslint-disable-next-line no-unused-vars
    const dataTransfer = [];
    // Cycle through all the community locations that have parntnered with the distribution
    // location/
    collection.docs.forEach((doc) => {
      // Check to the make sure the community Location is visible to the public
      if (doc.exists) {
        // Reference the document Name of the community location  this is to write.
        const documentName = [
          doc.data().firstName,
          doc.data().lastName,
          doc.data().userId,
        ].join("");
        // Reference the documents to write to which are the same as the ones we previously referenced the
        // collection read but we want to make sure we get each one individually.
        const updateDocRef = database
          .collection("CommunityLocations")
          .doc(documentName);
        // If the user is updating the address of the distribution location
        if (address) {
          batch.update(updateDocRef, {
            [["pickupLocation", "address"].join(".")]: state.restaurantAddress,
            [["pickupLocation", "latitude"].join(".")]:
              state.restaurantLatitude,
            [["pickupLocation", "longitude"].join(".")]:
              state.restaurantLongitude,
            [["pickupLocation", "userId"].join(".")]: userId,
          });
        }
        // If the user is updating the personal Info of the distribution location
        else if (personalInfo) {
          batch.update(updateDocRef, {
            [["pickupLocation", "orderCutoff"].join(".")]: state.orderCutoff,
            [["pickupLocation", "pickupDay"].join(".")]: state.pickupDay,
            [["pickupLocation", "etransferType"].join(".")]:
              state.etransferType,
            [["pickupLocation", "pickupTime"].join(".")]: state.pickupTime,
            [["pickupLocation", "name"].join(".")]: state.organisationName,
            [["pickupLocation", "subsidyAmount"].join(".")]:
              state.subsidyAmount,
            [["pickupLocation", "freqOfFreeSubsidy"].join(".")]:
              state.freqOfFreeSubsidy,
            [["pickupLocation", "emptyMarketplaceMessage"].join(".")]:
              state.emptyMarketplaceMessage,
            [["pickupLocation", "email"].join(".")]: state.email,
            [["pickupLocation", "userId"].join(".")]: userId,
            [["pickupLocation", "etransferEmail"].join(".")]:
              state.etransferEmail,
            [["pickupLocation", "baseDeliveryFee"].join(".")]:
              state.baseDeliveryFee,
            [["pickupLocation", "volunteerPercentage"].join(".")]:
              state.volunteerPercentage,
          });
        }
        // If the user is updating the marketplace message for a location.
        else if (updateMessage) {
          batch.update(updateDocRef, {
            [["pickupLocation", "marketplaceMessage"].join(".")]:
              state.marketplaceMessage,
            [["pickupLocation", "marketplaceMessageEnabled"].join(".")]:
              state.marketplaceMessageEnabled,
          });
        } else if (announcementUpdate) {
          batch.update(updateDocRef, {
            [["pickupLocation", "announcementTitle"].join(".")]:
              state.announcementTitle,
            [["pickupLocation", "announcementOn"].join(".")]:
              state.announcementOn,
            [["pickupLocation", "announcementDescription"].join(".")]:
              state.announcementDescription,
            [["pickupLocation", "announcementImage"].join(".")]:
              state.announcementImage,
            [["pickupLocation", "announcementWebsite"].join(".")]:
              state.announcementWebsite,
          });
        } else if (questionnaireUpdate) {
          batch.update(updateDocRef, {
            [["pickupLocation", "questionnaireList"].join(".")]:
              distributionLocationInformationTemp.questionnaireList,
          });
        }
        // If the user is updating anything else from the distribution location that
        // comes from anywhere but when a distributionlocation places an order.
        else {
          batch.update(updateDocRef, {
            pickupLocation: { ...distributionLocationInformationTemp },
          });
        }
      }
    });
    batch.commit();
  });
}
