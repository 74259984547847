// Takes in the current combined foodList (existing plus new),  the new customer foodList,
// the pantryFoodList from the customers orders, the leftovers that were put away by the existing
// order, the total items actually taken from the pantry of the total foodList and the organisation's name.
export default function UpdatedPantryLeftovers(
  combinedFoodList,
  newCustomerFoodList,
  newPantryFoodList,
  existingLeftoverList,
  combinedPantryFoodList,
  organisationName,
) {
  // List of foodItems that need to be added to the pantry after this order.
  const leftoversToAdd = [];

  // Cycle through the combinedFoodList to update the pantry items being added.
  combinedFoodList.forEach((foodItemTemp, indexOfCombinedFoodList) => {
    // The current foodItem being observed.
    const foodItem = { ...foodItemTemp };
    // The total of this item that was preordered from customers.  Limited or not.
    let totalPreOrdered = 0;
    // The total of this foodItem that was added to the pantry from the existing order.
    let addedToPantryBefore = 0;
    // This is how much was taken from the pantry from current customers.
    let takingFromPantry = 0;
    // The total amount of food that was ordered from both the existing and the new order.
    let totalOrdered = 0;

    // The index of the foodItem in the customerFoodList.
    const indexOfCustomerList = newCustomerFoodList.findIndex(
      (customerFoodItem) => {
        return (
          customerFoodItem.item === foodItem.item &&
          customerFoodItem.individualDescription ===
            foodItem.individualDescription &&
          customerFoodItem.farmName === foodItem.farmName
        );
      },
    );

    // The index of the foodItem in the newPantryFoodList. used to calculate totalPreOrdered and takingFromPantry
    const indexOfPantryCustomerList = newPantryFoodList.findIndex(
      (pantryCustomerFoodItem) => {
        return (
          pantryCustomerFoodItem.item === foodItem.item &&
          pantryCustomerFoodItem.individualDescription ===
            foodItem.individualDescription &&
          pantryCustomerFoodItem.farmName === foodItem.farmName
        );
      },
    );

    // The index of the foodItem in the existingLeftoverList. Used to calculate addedToPantryBefore
    const indexOfExistingLeftoverList = existingLeftoverList.findIndex(
      (existingLeftoverFoodItem) => {
        return (
          existingLeftoverFoodItem.item === foodItem.item &&
          existingLeftoverFoodItem.individualDescription ===
            foodItem.individualDescription &&
          existingLeftoverFoodItem.farmName === foodItem.farmName
        );
      },
    );

    // The index of the foodItem in the combinedPantryFoodList. Used to calculate how much the user actually took from the pantry
    // and remove this foodItem if none was actually taken.
    const indexOfCombinedPantryList = combinedPantryFoodList.findIndex(
      (combinedPantryItem) => {
        return (
          combinedPantryItem.item === foodItem.item &&
          combinedPantryItem.individualDescription ===
            foodItem.individualDescription &&
          combinedPantryItem.farmName === foodItem.farmName
        );
      },
    );
    // Checks to see if the new customer list has this foodItem.
    if (indexOfCustomerList !== -1) {
      // Calculate the total preordered food of this item.
      totalPreOrdered += newCustomerFoodList[indexOfCustomerList].quantity;
    }

    // Checks to see if this item exists in the new pantry foodList.
    if (indexOfPantryCustomerList !== -1) {
      // Add this amount taken from the pantry to the total preordered.
      totalPreOrdered += newPantryFoodList[indexOfPantryCustomerList].quantity;
      // Save what was taken from the pantry.
      takingFromPantry = newPantryFoodList[indexOfPantryCustomerList].quantity;
    }

    // Checks to see if the foodItem was already added to the pantry in the previous order.
    if (indexOfExistingLeftoverList !== -1) {
      // The total that was added to the pantry before
      addedToPantryBefore =
        existingLeftoverList[indexOfExistingLeftoverList].limit;
    }

    // Checks to see the total ordered.
    totalOrdered =
      (combinedFoodList[indexOfCombinedFoodList].quantity *
        combinedFoodList[indexOfCombinedFoodList].distributionQuantity) /
      combinedFoodList[indexOfCombinedFoodList].individualQuantity;

    // Reads the total combined pantry list and checks to make sure that items are
    // still being taken from the pantry or if they were in fact taken from the currently
    // order foodList.
    if (indexOfCombinedPantryList !== -1) {
      // If the total ordered - the preordered is greater than 0 or is 0 we know that
      // there were no items taken from the pantry.
      if (totalOrdered - totalPreOrdered >= 0) {
        // Remove this item from the pantry.
        combinedPantryFoodList.splice(indexOfCombinedPantryList, 1);
      }
      // Otherwise some of the item was taken from the pantry so update it.
      else {
        // It will equal the total preordered minus the total ordered.
        combinedPantryFoodList[indexOfCombinedPantryList].quantity =
          totalPreOrdered - totalOrdered;
      }
    }

    // The total added to the pantry is what will be need to be added after taking into
    // consideration the amounts added before and taken from the pantry between this order.
    const totalToAddToPantry =
      totalOrdered - totalPreOrdered - addedToPantryBefore + takingFromPantry;

    // If there is some amout that needs to be added to the pantry then add to the
    // leftoversToAdd list.
    if (totalToAddToPantry > 0) {
      // updates the foodLimit to what is being added to the pantry.
      foodItem.limit = totalToAddToPantry;
      // Sets the pantryLocation of the leftover food.
      foodItem.pantryLocation = organisationName;
      // Creates the leftover Foodlist to be sent to the add to pantry function.
      leftoversToAdd.push(foodItem);
    }
  });
  // Returns the items that still need to be added to the pantry after taking into
  // account the existing order and the orders of the customers as well as the final
  // pantry food list of items actually taken from the pantry.
  return [leftoversToAdd, combinedPantryFoodList];
}
