// This is to remove a shared pantry from a receiving organisation.
import firebase from "./../Firebase.js";

export default function DisconnectSharedPantry(
  leftovers,
  setLeftovers,
  userInfo,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const leftoversTemp = [...leftovers];

  // We know the first element is "importedBy" so that is all there is to remove
  leftoversTemp.splice(0, 1);

  const pantryDocRef = database
    .collection("Pantry")
    .doc(userInfo.organisationName);
  // Delete the importedBy key
  batch.update(pantryDocRef, {
    importedBy: firebase.firestore.FieldValue.delete(),
  });

  batch.commit();
  setLeftovers([...leftoversTemp]);
}
