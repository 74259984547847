// Called to send users an email letting them know that their orders
// have been changed when they order more than the guaranteed quantity
// and their orders couldn't be fulfilled.
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";

// Async function that sends email notifications to users with modified orders.
// Takes a dictionary (modifiedUserOrders) as a parameter where keys are user IDs
// and values are dictionaries representing modified food items for each user.
async function UsersToEmailExtraUpdates(modifiedUserOrders) {
  const database = firebase.firestore();
  const greeting = "Dear";

  // Set the ordering email function to the custom message sent by sendGrid.
  const modifiedOrderEmail = firebase
    .functions()
    .httpsCallable("customerOrderSendGrid");

  // Cycle through each user with modified orders.
  for (const user of Object.keys(modifiedUserOrders)) {
    // Extract user information from the user key.
    const userOrderSplit = user.split("-");
    const docRef = database.collection("Users").doc(userOrderSplit[2]);

    try {
      // Get user document from Firestore.
      const doc = await docRef.get();

      if (doc.exists) {
        // If the user document exists, extract user email.
        const dest = [doc.data().email];
        // Build the email message.
        let messageToSend = `We hope this message finds you well. Due to high demand, we had to make some adjustments to the extra item(s) in your order. Our goal is to ensure fairness and accommodate as many customers as possible with their guaranteed amounts.<br /><br />
        If you have any questions or concerns regarding these adjustments, please don't hesitate to reach out to us. We are here to assist you.<br /><br />
        Should you wish to make further modifications to your order, kindly contact us, and we will do our best to accommodate your adjustments. To review your complete order, please visit <a href="https://farmtoplatemarketplace.com" target="_blank">farmtoplatemarketplace.com</a> and navigate to My Account -> Order History.<br /><br />
        The following item(s) were adjusted:<br />`;

        // Iterate over modified food items for the user.
        Object.keys(modifiedUserOrders[user]).forEach((foodItem, index) => {
          const { original, adjusted, individualDescription } =
            modifiedUserOrders[user][foodItem];
          const foodItemSplit = foodItem.split("-");

          if (original !== undefined && adjusted !== undefined) {
            // Add information about each modified food item to the email message.
            messageToSend += `<p style="font-size: 18px; color: rgb(255, 0, 0);">
            ${parseInt(index) + 1}. ${
              foodItemSplit[0]
            } ${individualDescription} from ${
              foodItemSplit[2]
            }: Original x${original}, Adjusted x${adjusted} <br /> </p>`;
          }
        });

        // Complete the email message.
        messageToSend += `<br />Thank you for your understanding and continued support.<br /><br />
          Best regards,<br />
          Farm-to-Plate Marketplace`;

        // Send the modified order email using Firebase Cloud Functions.
        await modifiedOrderEmail({
          dest,
          subject: "Modification to Your Extra Items",
          greeting,
          firstName: userOrderSplit[0],
          customMessage: messageToSend,
        });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  }
}

UsersToEmailExtraUpdates.propTypes = {
  modifiedUserOrders: PropTypes.object.isRequired,
};

export default UsersToEmailExtraUpdates;
