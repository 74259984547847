// The form that the organisation enters to update their personal information the following.
// organisationName
// firstName
// lastName
// phoneNumber
// email
// orderCutoff
// userId
// etransferType : autodeposit is set up or there is a password required.
// subsidyAmount : the maximum subsidy amount
// emptyMarketplaceMessage
// pickupDay
// pickupTime
// etransferEmail
// baseDeliveryFee : the percentage that is charged to the user for deliveries
// volunteerPercentage : the participation fee that users can use credits to cover.
import React from "react";
import firebase from "./../Firebase.js";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import Radio from "@material-ui/core/Radio";
// eslint-disable-next-line no-unused-vars
import RadioGroup from "@material-ui/core/RadioGroup";
// eslint-disable-next-line no-unused-vars
import FormControlLabel from "@material-ui/core/FormControlLabel";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import DialogContent from "@material-ui/core/DialogContent";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";
import UpdateCommunityLocation from "../../functions/UpdateCommunityLocation.js";
import PropTypes from "prop-types";

export default class OrganisationInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      organisationName: props.userInfo.organisationName,
      firstName: props.userInfo.firstName,
      lastName: props.userInfo.lastName,
      phoneNumber: props.userInfo.phoneNumber,
      email: props.userInfo.email,
      orderCutoff: props.userInfo.orderCutoff,
      userId: props.userInfo.userId,
      etransferType: props.userInfo.etransferType,
      subsidyAmount: props.userInfo.subsidyAmount,
      freqOfFreeSubsidy: props.userInfo.freqOfFreeSubsidy,
      emptyMarketplaceMessage: props.userInfo.emptyMarketplaceMessage,
      // NEEDS WORK
      // orderCutoffTime : props.userInfo.orderCutoffTime,
      pickupDay: props.userInfo.pickupDay,
      pickupTime: props.userInfo.pickupTime,
      etransferEmail: props.userInfo.etransferEmail,
      baseDeliveryFee: props.userInfo.baseDeliveryFee,
      volunteerPercentage: props.userInfo.volunteerPercentage,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleSave(e) {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();
    // NEEDS WORK I need to correct the fact that it only looks at Vancouver
    const distributionLocationsDocRef = database
      .collection("DistributionLocations")
      .doc("Vancouver");
    const userInfo = this.props.userInfo;

    this.setState({
      open: false,
    });

    batch.update(distributionLocationsDocRef, {
      [[userInfo.organisationName, "orderCutoff"].join(".")]:
        this.state.orderCutoff,
      [[userInfo.organisationName, "pickupDay"].join(".")]:
        this.state.pickupDay,
      [[userInfo.organisationName, "etransferType"].join(".")]:
        this.state.etransferType,
      [[userInfo.organisationName, "pickupTime"].join(".")]:
        this.state.pickupTime,
      [[userInfo.organisationName, "name"].join(".")]:
        this.state.organisationName,
      [[userInfo.organisationName, "subsidyAmount"].join(".")]:
        this.state.subsidyAmount,
      [[userInfo.organisationName, "freqOfFreeSubsidy"].join(".")]:
        this.state.freqOfFreeSubsidy,
      [[userInfo.organisationName, "emptyMarketplaceMessage"].join(".")]:
        this.state.emptyMarketplaceMessage,
      [[userInfo.organisationName, "email"].join(".")]: this.state.email,
      [[userInfo.organisationName, "userId"].join(".")]: userInfo.userId,
      [[userInfo.organisationName, "etransferEmail"].join(".")]:
        this.state.etransferEmail,
      [[userInfo.organisationName, "baseDeliveryFee"].join(".")]:
        this.state.baseDeliveryFee,
      [[userInfo.organisationName, "volunteerPercentage"].join(".")]:
        this.state.volunteerPercentage,
    });
    // Updates any community locations that may exist that have this distribution location as their partner.
    UpdateCommunityLocation(userInfo.userId, {}, false, true, this.state);

    batch.commit();

    // if the user changed the email then we have to update their sign in options.
    // In the event the user hasn't logged in recently nothing will be updated.
    if (userInfo.email !== this.state.email) {
      this.props.currentUser
        .updateEmail(this.state.email)
        .then((props) => {
          // Update successful.
          this.props.handleInfoUpdate(this.state);
          console.log("Email Updated");
        })
        // eslint-disable-next-line n/handle-callback-err
        .catch(function (error) {
          // eslint-disable-next-line no-undef
          alert(
            "It's been too long since you signed in last please log out and sign in again.",
          );
        });
    } else {
      this.props.handleInfoUpdate(this.state);
    }

    return null;
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    // If the value being changed is the subsidyAmount then set the value to
    // a float otherwise it just equals the value.
    if (
      name === "subsidyAmount" ||
      name === "baseDeliveryFee" ||
      name === "volunteerPercentage"
    ) {
      this.setState({
        [name]: parseFloat(value),
      });
    } else if (name === "freqOfFreeSubsidy") {
      this.setState({
        [name]: parseInt(value),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleFocus(e) {
    e.currentTarget.select();
  }

  render() {
    return (
      <div>
        <Button
          className="Edit-Button"
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          edit
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Organisation Information
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              {this.props.userInfo.userType !== "individual" && (
                <TextField
                  margin="dense"
                  id="organisationName name"
                  label="Organisation Name"
                  name="organisationName"
                  value={this.state.organisationName}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
              )}
              <TextField
                margin="dense"
                id="first name"
                label="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="last name"
                label="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="phone number"
                label="Phone Number"
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="pickup time"
                label="Pickup Time"
                name="pickupTime"
                value={this.state.pickupTime}
                onChange={this.handleChange}
                fullWidth
                required
              />

              <TextField
                margin="dense"
                id="subsidyAmount"
                label="Subsidy Amount"
                name="subsidyAmount"
                type="number"
                value={this.state.subsidyAmount}
                onChange={this.handleChange}
                fullWidth
                required
              />

              <TextField
                margin="dense"
                id="freqOfFreeSubsidy"
                label="Free Subsidy Frequency"
                name="freqOfFreeSubsidy"
                type="number"
                value={this.state.freqOfFreeSubsidy}
                onChange={this.handleChange}
                fullWidth
                required
              />

              <TextField
                margin="dense"
                id="emptyMarketplaceMessage"
                label="Empty Marketplace Message"
                name="emptyMarketplaceMessage"
                value={this.state.emptyMarketplaceMessage}
                onChange={this.handleChange}
                fullWidth
                required
              />

              <TextField
                margin="dense"
                id="etransferEmail"
                label="Etransfer Email Address"
                name="etransferEmail"
                value={this.state.etransferEmail}
                onChange={this.handleChange}
                fullWidth
                required
              />

              <TextField
                margin="dense"
                id="baseDeliveryFee"
                label="Base Delivery Fee"
                name="baseDeliveryFee"
                value={this.state.baseDeliveryFee}
                onChange={this.handleChange}
                type="number"
                fullWidth
                required
              />

              <TextField
                margin="dense"
                id="volunteerPercentage"
                label="Participation Percentage"
                name="volunteerPercentage"
                value={this.state.volunteerPercentage}
                onChange={this.handleChange}
                type="number"
                fullWidth
                required
              />

              <h6 style={{ textDecoration: "underline" }}>
                E-transfer Deposit Type
              </h6>
              <RadioGroup
                row
                aria-label="etransferType"
                name="etransferType"
                value={this.state.etransferType}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="Auto-Deposit"
                  control={<Radio />}
                  label="Auto-Deposit"
                />
                <FormControlLabel
                  value="Question and Answer"
                  control={<Radio />}
                  label="Question and Answer"
                />
              </RadioGroup>

              <h6 style={{ textDecoration: "underline" }}>Order Cutoff Day</h6>
              <RadioGroup
                row
                aria-label="gender"
                name="orderCutoff"
                value={this.state.orderCutoff}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="Mon"
                  control={<Radio />}
                  label="Monday"
                />
                <FormControlLabel
                  value="Tues"
                  control={<Radio />}
                  label="Tuesday"
                />
                <FormControlLabel
                  value="Wed"
                  control={<Radio />}
                  label="Wednesday"
                />
                <FormControlLabel
                  value="Thurs"
                  control={<Radio />}
                  label="Thursday"
                />
                <FormControlLabel
                  value="Fri"
                  control={<Radio />}
                  label="Friday"
                />
                <FormControlLabel
                  value="Sat"
                  control={<Radio />}
                  label="Saturday"
                />
                <FormControlLabel
                  value="Sun"
                  control={<Radio />}
                  label="Sunday"
                />
              </RadioGroup>

              <h6 style={{ textDecoration: "underline" }}>Pickup Day</h6>
              <RadioGroup
                row
                aria-label="gender"
                name="pickupDay"
                value={this.state.pickupDay}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="Mon"
                  control={<Radio />}
                  label="Monday"
                />
                <FormControlLabel
                  value="Tues"
                  control={<Radio />}
                  label="Tuesday"
                />
                <FormControlLabel
                  value="Wed"
                  control={<Radio />}
                  label="Wednesday"
                />
                <FormControlLabel
                  value="Thurs"
                  control={<Radio />}
                  label="Thursday"
                />
                <FormControlLabel
                  value="Fri"
                  control={<Radio />}
                  label="Friday"
                />
                <FormControlLabel
                  value="Sat"
                  control={<Radio />}
                  label="Saturday"
                />
                <FormControlLabel
                  value="Sun"
                  control={<Radio />}
                  label="Sunday"
                />
              </RadioGroup>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

OrganisationInfoForm.propTypes = {
  userInfo: PropTypes.shape({
    organisationName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    orderCutoff: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    etransferType: PropTypes.oneOf(["Auto-Deposit", "Question and Answer"])
      .isRequired,
    subsidyAmount: PropTypes.number.isRequired,
    freqOfFreeSubsidy: PropTypes.number.isRequired,
    emptyMarketplaceMessage: PropTypes.string.isRequired,
    pickupDay: PropTypes.oneOf([
      "Mon",
      "Tues",
      "Wed",
      "Thurs",
      "Fri",
      "Sat",
      "Sun",
    ]).isRequired,
    pickupTime: PropTypes.string.isRequired,
    etransferEmail: PropTypes.string.isRequired,
    baseDeliveryFee: PropTypes.number.isRequired,
    volunteerPercentage: PropTypes.number.isRequired,
  }),
  currentUser: PropTypes.object.isRequired,
  handleInfoUpdate: PropTypes.func.isRequired,
};
