import React from "react";
import firebase from "./../Firebase.js";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import DialogContent from "@material-ui/core/DialogContent";
// eslint-disable-next-line no-unused-vars
import DialogTitle from "@material-ui/core/DialogTitle";
import UpdateCommunityLocation from "../../functions/UpdateCommunityLocation.js";

export default class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      restaurantAddress: props.restaurantAddress,
      restaurantCountry: props.restaurantCountry,
      restaurantCity: props.restaurantCity,
      restaurantProvTerr: props.restaurantProvTerr,
      restaurantPostalCode: props.restaurantPostalCode,
      restaurantLatitude: props.restaurantLatitude,
      restaurantLongitude: props.restaurantLongitude,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  handleSave(e) {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();
    // NEEDS WORK I need to correct the fact that it only looks at Vancouver
    const distributionLocationsDocRef = database
      .collection("DistributionLocations")
      .doc("Vancouver");
    const userInfo = this.props.userInfo;

    if (userInfo.userType === "restaurant") {
      const menuDocRef = database
        .collection("RestaurantMenu")
        .doc(this.props.restaurantName);
      batch.set(
        menuDocRef,
        {
          restaurantAddress: this.state.restaurantAddress,
          restaurantCity: this.state.restaurantCity,
          restaurantProvTerr: this.state.restaurantProvTerr,
        },
        { merge: true },
      );

      batch.commit();
    }

    if (userInfo.userType === "distributionLocation") {
      batch.update(distributionLocationsDocRef, {
        [[userInfo.organisationName, "address"].join(".")]:
          this.state.restaurantAddress,
        [[userInfo.organisationName, "latitude"].join(".")]:
          this.state.restaurantLatitude,
        [[userInfo.organisationName, "longitude"].join(".")]:
          this.state.restaurantLongitude,
        [[userInfo.organisationName, "userId"].join(".")]: userInfo.userId,
      });
      // Updates any community locations that may exist that have this distribution location as their partner.
      UpdateCommunityLocation(userInfo.userId, {}, true, false, this.state);
      batch.commit();
    }

    this.setState({
      open: false,
    });
    this.props.handleInfoUpdate(this.state);
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div>
        <Button
          className="Edit-Button"
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          edit
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Personal Information
          </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSave}>
              <TextField
                margin="dense"
                id="street address"
                label="Street Address"
                name="restaurantAddress"
                value={this.state.restaurantAddress}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="country"
                label="Country"
                name="restaurantCountry"
                value={this.state.restaurantCountry}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="city"
                label="City"
                name="restaurantCity"
                value={this.state.restaurantCity}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="province/territory"
                label="Province/Territory"
                name="restaurantProvTerr"
                value={this.state.restaurantProvTerr}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="postal code"
                label="Postal Code"
                name="restaurantPostalCode"
                value={this.state.restaurantPostalCode}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="latitude"
                label="Latitude"
                name="restaurantLatitude"
                value={this.state.restaurantLatitude}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="longitude"
                label="Longitude"
                name="restaurantLongitude"
                value={this.state.restaurantLongitude}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
