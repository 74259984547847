// This dialog is used to display when the distribution location is loading the Emails
// of all the users of this order.  Once it is done loading this dialog disappears.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoadingEmailsBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Loading Users</DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          We are loading the users involved in this order.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

LoadingEmailsBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function LoadingEmailsDialog(props) {
  const open = props.open;

  return (
    <div>
      <LoadingEmailsBox open={open} />
    </div>
  );
}
