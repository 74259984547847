// This takes in all the foodItems of the admin user and then creates a dictionary
// with the key all the farms and then the values an array of categories for that farm.
export default function CreateFarmCategories(foodItems) {
  // intialize the dictionary
  const farmCategories = {};

  // Cycles through all the foodItems
  foodItems.forEach((foodItem) => {
    // intializes the key for the farm if it doesn't exist with an empty array.
    if (!farmCategories[foodItem.farmName]) {
      farmCategories[foodItem.farmName] = [];
    }
    // Adds the category to the farm if it hasn't already been added by another
    // foodItem with the same category.
    if (!farmCategories[foodItem.farmName].includes(foodItem.category)) {
      farmCategories[foodItem.farmName].push(foodItem.category);
    }
  });

  return farmCategories;
}
