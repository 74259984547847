// This is a list of all the community and distribution locations that a user can
// view on the distribution selection screen.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import DeliveryLocation from "../Components/DeliveryLocation.jsx";

// This is the list of all distribution locations that are participating in this
// delivery day under the current parameters.  It calls DeliveryLocation which
// creates the individual items in the list.

export default class ListElement extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
    this.state = { hover: false, click: false, locationSelected: 0 };
  }

  toggleHover(location) {
    this.setState({ hover: true, click: false, locationSelected: location });
  }

  toggleNoHover() {
    this.setState({ hover: false });
  }

  // By Clicking a location you filter the map so that it shows only that
  // restaurant/farm and other farms/restaurants that it is directly involved in
  toggleOnClick(location) {
    this.setState({ click: true });
    this.props.toggleOnClick(location);
  }

  render() {
    // deliveries is the a dictionary of all the locaitons participating in the
    // delivery day under the current parameters
    const pickupLocations = this.props.pickupLocations;

    const activeIcon = this.props.activeIcon;

    const previousPage = this.props.previousPage;

    // The list element needs to know which location in the list is being hovered
    // over so that when the user clicks it will be able to know which location
    // was picked.
    const locationSelected = this.state.locationSelected;
    return (
      <div className="List-Of-Locations">
        {Object.keys(pickupLocations).map((location, index) => (
          <DeliveryLocation
            key={location + index}
            userInfo={this.props.userInfo}
            previousPage={previousPage}
            activeIcon={activeIcon}
            location={location}
            locationDetails={pickupLocations[location]}
            toggleHover={this.toggleHover}
            toggleNoHover={this.toggleNoHover}
            toggleOnClick={this.toggleOnClick}
            hover={this.state.hover}
            title={locationSelected}
            handleClickOpen={this.props.handleClickOpen}
            communityDistributionLocations={
              this.props.communityDistributionLocations
            }
            setSendingEmail={this.props.setSendingEmail}
          />
        ))}
      </div>
    );
  }
}
