// This dialog is created when a user places an order.  This is to give the browser
// time to place the order and send the user an email confirmation.  Once the email
// has been sent then this dialog will close automatically.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/MyAccount.css";

function PlacingOrderBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Placing Order</DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          Please wait while we place your order.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

PlacingOrderBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function PlacingOrderDialog(props) {
  const open = props.open;

  return (
    <div>
      <PlacingOrderBox open={open} />
    </div>
  );
}
