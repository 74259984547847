// Displays the items in the pantry for the distribution location on the myAccount page.
// The user can also add to the pantry and remove items
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import AddItemToPantryDialog from "../../components/Pantry/Dialogs/AddItemToPantryDialog.jsx";
import RemoveItemFromPantry from "../../components/Pantry/RemoveItemFromPantry.js";
import DisconnectSharedPantry from "../../components/Pantry/DisconnectSharedPantry.js";
import PantryEditDialog from "../../components/Pantry/Dialogs/PantryEditDialog.jsx";
import "../../styles/Pantry.css";
import { getIconByKey } from "../../images/Icons"; // Import the function
import PantrySortFilter from "../../pages/MyAccountPage/Components/PantrySortFilter.jsx";

export default function DisplayPantry({ userInfo, leftovers, setLeftovers }) {
  return (
    <div>
      {leftovers.length !== 0 ? (
        <span>
          {typeof leftovers[0] === "string" ? (
            <>
              <h1>You're Sharing {leftovers[0]}'s Pantry</h1>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  DisconnectSharedPantry(leftovers, setLeftovers, userInfo)
                }
              >
                Disconnect Shared Pantry
              </Button>
            </>
          ) : (
            <>
              <h1>Items in Pantry</h1>
              <List className="List-Container">
                <PantrySortFilter
                  leftovers={leftovers}
                  setLeftovers={setLeftovers}
                />
                {leftovers.map((foodItem, index) => (
                  <ListItem key={index}>
                    <div className="Pantry-Cell">
                      {foodItem.item}
                      {/* Check if food.tagList exists and has items */}
                      {foodItem.tagList && foodItem.tagList.length > 0 && (
                        <div
                          style={{ display: "inline-flex", marginLeft: "8px" }}
                        >
                          {foodItem.tagList.map((tag) => (
                            <span key={tag.key} style={{ marginRight: "4px" }}>
                              {getIconByKey(tag.key)}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="Pantry-Cell">
                      {foodItem.individualDescription}
                    </div>
                    <div className="Pantry-Cell">{foodItem.farmName}</div>
                    <div className="Pantry-Cell">
                      $
                      {parseFloat(
                        foodItem.price /
                          (foodItem.distributionQuantity /
                            foodItem.individualQuantity),
                      ).toFixed(2)}
                    </div>
                    <div className="Pantry-Cell">
                      {foodItem.limit.toFixed(2)} remaining
                      <button
                        className="Pantry-Delete-Button"
                        onClick={() =>
                          RemoveItemFromPantry(
                            foodItem,
                            index,
                            leftovers,
                            setLeftovers,
                            userInfo,
                          )
                        }
                      >
                        x
                      </button>
                      <PantryEditDialog
                        leftovers={leftovers}
                        setLeftovers={setLeftovers}
                        userInfo={userInfo}
                        foodItem={foodItem}
                        index={index}
                      />
                    </div>
                  </ListItem>
                ))}
                <ListItem>
                  <AddItemToPantryDialog
                    leftovers={leftovers}
                    setLeftovers={setLeftovers}
                    userInfo={userInfo}
                  />
                </ListItem>
              </List>
            </>
          )}
        </span>
      ) : (
        <>
          <h1>No items are in your pantry yet. Add some!</h1>
          <AddItemToPantryDialog
            leftovers={leftovers}
            setLeftovers={setLeftovers}
            userInfo={userInfo}
          />
        </>
      )}
    </div>
  );
}
