// This is called by the individual's marketplace, the individual's basket and the
// Myaccount Page under the individual user's pickup location.
import NextPickupDay from "./NextPickupDay.js";
import firebase from "../components/Firebase.js";

export default async function CalculateCommunityLocationStatus(
  communityLocation,
  setCurrentStatus,
  userInfo,
) {
  // The distribution location of the community host.
  const distributionLocation = communityLocation.pickupLocation;
  // Find the next pickup date of this distribution location.
  const distributionDate = NextPickupDay(distributionLocation.pickupDay);

  const database = firebase.firestore();
  const communityOrdersCollectionRef = database
    .collection("DistributionLocations")
    .doc(distributionLocation.name)
    .collection("CommunityOrders");

  try {
    // Fetch all documents that have the distributionDate equal to distributionDate
    const querySnapshot = await communityOrdersCollectionRef
      .where("distributionDate", "==", distributionDate)
      .get();
    // Initialize variables to store combined orders and user data
    const combinedOrders = {};
    // Checks if the user has already ordered from this location for this week
    let userParticipating = false;
    // Contains the list of userIds of all the users that will be used to sum up
    // the total user count.  The length of this array is total users
    const listOfUsers = [];

    // Process each document found
    querySnapshot.forEach((doc) => {
      const orders = doc.data();
      // Combine orders while removing distributionDate and orderCount
      Object.keys(orders).forEach((order) => {
        if (order !== "distributionDate" && order !== "orderCount") {
          combinedOrders[order] = orders[order];
        }
      });
    });

    // Cycles through all the orders
    Object.keys(combinedOrders).forEach((order) => {
      const userId = order.split("-")[2];

      // Checks if the order has a communityPickup or not.
      if (combinedOrders[order].communityPickup) {
        // Checks to see if their community pickup is for the location we're checking
        if (
          combinedOrders[order].communityPickup.locationName ===
          communityLocation.locationName
        ) {
          // Checks to see if the current user is the orderer
          if (userInfo.userId === order.split("-")[2]) {
            // Set this to true if there is ever an order that the current uesr ordered.
            userParticipating = true;
          }
          // Checks to see if this user has already been counted
          if (!listOfUsers.includes(userId)) {
            // Adds the new user to the array
            listOfUsers.push(userId);
          }
        }
      }
    });
    // Sets the currentStatus to the total cost, the total users and the whether the current user has already ordered.
    setCurrentStatus([
      listOfUsers,
      parseInt(listOfUsers.length),
      userParticipating,
    ]);
  } catch (error) {
    console.error("Error fetching community orders:", error);
    // Handle error accordingly
  }
}
