// communityFoodRemove updates the community farmer list and the food list when
// the user removes a food item that was added because of the community orders.
export default function CommunityFoodItemRemove(
  item,
  pantry,
  communityFoodItems,
  setCommunityFoodItems,
  pantryFoodItems,
  setPantryFoodItems,
  setCommunityListChanged,
  communityFarmerInfo,
  setCommunityFarmerInfo,
) {
  // Copy the foodItems array hook to a new one that can be manipulated
  const arrFood = [...communityFoodItems];
  // Copy the pantry foodItems array hook to a new one that can be manipulated
  const arrPantryFood = [...pantryFoodItems];

  // If the user isn't removing an item from the pantry but from the normal foodItems
  // list.
  if (!pantry) {
    // Find the first and only index of the food item that has been to been
    // removed
    const index1 = arrFood.findIndex(
      (element) =>
        item ===
        (element.item + element.description + element.farmName).replace(
          /\s+/g,
          "",
        ),
    );
    if (index1 !== -1) {
      // Update the copy by removing the item from the array and update the hook
      arrFood.splice(index1, 1);
      setCommunityFoodItems([...arrFood]);
      setCommunityListChanged(true);
    }
  }
  // If the user is removing from the pantry.
  else {
    // Find the first and only index of the food item that has been to been
    // removed
    const index2 = arrPantryFood.findIndex(
      (element) =>
        item ===
        (element.item + element.description + element.farmName).replace(
          /\s+/g,
          "",
        ),
    );
    if (index2 !== -1) {
      // Update the copy by removing the item from the array and update the hook
      arrPantryFood.splice(index2, 1);
      setPantryFoodItems([...arrPantryFood]);
      setCommunityListChanged(true);
    }
  }

  // Create a new list of farms providing the order starting with an empty array
  const arrFarms = [];
  // Cycle through the current farms providing the order before the remove
  // and check to see if that farm is still providing any ingredients.  If it
  // then add it to the new farm list and set the farm list to equal the new
  // one.
  communityFarmerInfo.forEach((farm) => {
    // These farms are use to show the user farmer details and so whether there
    // are only pantry Items or not from this farm we still want to show those farm
    // details.
    const farmIndex = arrFood.findIndex(
      (food) => farm.farmName === food.farmName,
    );
    const pantryIndex = arrPantryFood.findIndex(
      (food) => farm.farmName === food.farmName,
    );
    if (farmIndex !== -1) {
      arrFarms.push(farm);
    } else if (pantryIndex !== -1) {
      arrFarms.push(farm);
    }
  });

  setCommunityFarmerInfo([...arrFarms]);
}
