// This component takes the user's inputs for the refund.  It asks for the amount
// and the date deposited.
// Inputs: refund - which is a dictionary with the amount of the refund and the
// date.  Index is the index of this specific refund in the array. handleRefundChange
// and handleRemoveRefund are functions that add and remove a specific refund.
import React from "react";
import FormControl from "@material-ui/core/FormControl";
// eslint-disable-next-line no-unused-vars
import FormLabel from "@material-ui/core/FormLabel";
// eslint-disable-next-line no-unused-vars
import FormControlLabel from "@material-ui/core/FormControlLabel";
// eslint-disable-next-line no-unused-vars
import Radio from "@material-ui/core/Radio";
// eslint-disable-next-line no-unused-vars
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MaterialUIPickers from "../../../components/userInputs/DatePicker.js";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import SyncDisabledRoundedIcon from "@material-ui/icons/SyncDisabledRounded";

export default function RefundEntry({
  refund,
  index,
  handleRefundChange,
  handleRemoveRefund,
  syncRefunds,
}) {
  // The amount of the refund.
  const amount = refund.amount;
  // The date of the refund is given.
  const date = refund.date;
  return (
    <>
      <h5>
        <u>Refund {index + 1}</u>
        {syncRefunds[index] ? (
          <SyncRoundedIcon style={{ fill: "green" }} />
        ) : (
          <SyncDisabledRoundedIcon style={{ fill: "red" }} />
        )}
      </h5>
      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
        <OutlinedInput
          required
          id="outlined-adornment-amount"
          value={amount}
          onChange={(e) => handleRefundChange(e, index)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Amount"
          type="number"
        />
      </FormControl>

      <MaterialUIPickers
        required
        date={date}
        handleDateChange={(e) => handleRefundChange(e, index, true)}
        label="Refund Date"
      />
      {index !== 0 && (
        <IconButton onClick={() => handleRemoveRefund(index)}>
          <RemoveCircleOutlineOutlinedIcon
            fontSize="large"
            style={{ color: "red" }}
          />
        </IconButton>
      )}
    </>
  );
}
