// This displays the row of farm chips to show the user which farms are providing
// this order and give them an option to remove the farm.
import React from "react";
import Chip from "@material-ui/core/Chip";
import { faTractor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveItem from "./RemoveItem.js";

// If the user chooses to remove this farm than we have to remove all the items
// that come from this farm and remove the farm from the farmerInfo.
function handleFarmRemove(
  farmName,
  foodItems,
  communityFoodItems,
  setFoodItems,
  pantryFoodItems,
  setPantryFoodItems,
  setCommunityFoodItems,
  setFarmsRemoved,
  setCommunityListChanged,
  farmerInfo,
  communityFarmerInfo,
  setFarmerInfo,
  setCommunityFarmerInfo,
) {
  // Remove the items from the distribution location's foodList
  RemoveItem(foodItems, farmName, setFoodItems, setFarmsRemoved);
  // Remove the items from the communityOrders's foodList
  RemoveItem(
    communityFoodItems,
    farmName,
    setCommunityFoodItems,
    setFarmsRemoved,
  );
  // Remove the items from the pantry's foodList
  RemoveItem(pantryFoodItems, farmName, setPantryFoodItems, setFarmsRemoved);
  // Copy the current farmInfo array and find out where the farm being removed's
  // index is.  Remove that farm from the array.
  const farmerInfoTemp = [...farmerInfo];
  const farmIndex = farmerInfoTemp.findIndex(
    (farm) => farm.farmName === farmName,
  );
  if (farmIndex !== -1) {
    farmerInfoTemp.splice(farmIndex, 1);
    setFarmerInfo([...farmerInfoTemp]);
  }

  // Copy the current community farmInfo array and find out where the farm being removed's
  // index is.  Remove that farm from the array.
  const communityFarmerInfoTemp = [...communityFarmerInfo];
  const communityFarmIndex = communityFarmerInfoTemp.findIndex(
    (farm) => farm.farmName === farmName,
  );
  if (communityFarmIndex !== -1) {
    communityFarmerInfoTemp.splice(communityFarmIndex, 1);
    setCommunityFarmerInfo([...communityFarmerInfoTemp]);
  }
}

export default function FarmsProvidingChips(
  farmList,
  foodItems,
  communityFoodItems,
  setFoodItems,
  pantryFoodItems,
  setPantryFoodItems,
  setCommunityFoodItems,
  setFarmsRemoved,
  setCommunityListChanged,
  farmerInfo,
  communityFarmerInfo,
  setFarmerInfo,
  setCommunityFarmerInfo,
) {
  return (
    <span>
      <h3 className="Header-3"> Farms Providing Order: </h3>
      {farmList.map((farm, i) => (
        <Chip
          key={i}
          color="primary"
          style={{ margin: "5px" }}
          size="small"
          label={farm.farmName}
          icon={
            <FontAwesomeIcon className="w3-hover-opacity" icon={faTractor} />
          }
          onDelete={() =>
            handleFarmRemove(
              farm.farmName,
              foodItems,
              communityFoodItems,
              setFoodItems,
              pantryFoodItems,
              setPantryFoodItems,
              setCommunityFoodItems,
              setFarmsRemoved,
              setCommunityListChanged,
              farmerInfo,
              communityFarmerInfo,
              setFarmerInfo,
              setCommunityFarmerInfo,
            )
          }
        />
      ))}
    </span>
  );
}
