// This displays a banner on the individual's marketplace page.  This can be any
// message and there will also be a message from the community host if they have any
// comments or instructions on their pick up location.
import React from "react";

export default function MarketplaceMessageBanner({
  pickupLocationDetails,
  userInfo,
}) {
  // If there is a community host message to display.
  let communityMessageToDisplay = false;
  // If the userInfo is not defined then we will not need to check this.  It means
  // it's not coming from an individual user.
  if (userInfo !== undefined) {
    // Check that the user has selected a pickupLocation.
    if (userInfo.pickupLocation !== undefined) {
      // Check if the user's pickupLocation has additionalComments and that they aren't
      // empty.  Then set the community host message to true.
      if (
        userInfo.pickupLocation.additionalComments !== undefined &&
        userInfo.pickupLocation.additionalComments !== ""
      ) {
        communityMessageToDisplay = true;
      }
    }
  }
  return (
    <div>
      {pickupLocationDetails.marketplaceMessageEnabled && (
        <div className="Marketplace-Banner-Message">
          <b className="Marketplace-Banner-Title">Marketplace Message</b>
          <br />
          {pickupLocationDetails.marketplaceMessage}
        </div>
      )}
      {communityMessageToDisplay && (
        <div className="Community-Host-Banner-Message">
          <b className="Community-Host-Banner-Title">
            Community Hub Instructions
          </b>
          <br />
          {userInfo.pickupLocation.additionalComments}
        </div>
      )}
    </div>
  );
}
