// This dialog allows the user to select a time period to download the volunteer schedule
// from a drop down of all the time periods.
import React, { useState, useEffect } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createVolunteerSpreadsheetUtil } from "../Functions/CreateVolunteerSpreadsheetUtil.js";
import DownloadIcon from "@mui/icons-material/Download";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// This created the available quarters.  The schedule goes back as far as
// Q2-2023 so depending on when they click it it will generate all the subsequent
// quarters.
function quartersGeneration(setAvailableQuarters) {
  // The list of available quarters to choose from.
  const availableQuarters = [];
  // The date today.
  const now = new Date();
  // Today's year.
  let y = now.getFullYear();
  // Today's month.
  const m = now.getMonth();
  // The current quarter is defaulted to the quarter of today's date.
  let currentQuarter = 3;
  // This is the quarter being added to the availableQuarters array.
  let addedQuarter = "";

  // Determine what quarter the user is currently in based off their real date.
  // If the first quarter.
  if (12 / m < 0.26) {
    currentQuarter = 1;
  }
  // If the last quarter.
  else if (12 / m > 0.75) {
    currentQuarter = 4;
  }
  // If the 2 quarter.
  else if (12 / m > 0.25 && 12 / m < 0.55) {
    currentQuarter = 2;
  }

  // Create all the quarters until you reach the starting quarter.
  while (addedQuarter !== "2023-Q2") {
    // Create the quarter.
    const quarter = "Q".concat(currentQuarter);
    // Make the addedQuarter string.
    addedQuarter = [y, quarter].join("-");
    // Add this quarter.
    availableQuarters.push(addedQuarter);
    // Check if the current quarter is 1 and then restart to
    // the 4th quarter and subtract a year from the year.
    if (currentQuarter === 1) {
      currentQuarter = 4;
      y = y - 1;
    }
    // If it's not the first quarter then the year stays the same and you
    // just have to subtract the quarter.
    else {
      currentQuarter = currentQuarter - 1;
    }
  }
  // Reverse the order so it's chronological.
  availableQuarters.reverse();

  // set the hook.
  setAvailableQuarters(availableQuarters);
}

export default function VolunteerScheduleDownloadDialog() {
  const [open, setOpen] = useState(false);
  // Array of quarters that the user can select from.
  const [availableQuarters, setAvailableQuarters] = useState([]);
  // The selected quarter the user has chosen.
  const [selectedQuarter, setSelectedQuarter] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Generate the quarters available.
  useEffect(() => {
    quartersGeneration(setAvailableQuarters);
  }, []);

  // If the user clicks to download the excel sheet.
  const handleDownload = () => {
    const database = firebase.firestore();
    // Load the volunteer Schedule.
    const volunteerDocRef = database
      .collection("VolunteerSchedule")
      .doc(selectedQuarter);

    // Cycle through all the found documents and add them to the volunteerJobs hook.
    volunteerDocRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Create the sheet and send to user's browser.
          createVolunteerSpreadsheetUtil(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClickOpen}
        endIcon={<DownloadIcon />}
      >
        DOWNLOAD VOLUNTEER SCHEDULE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Select the Time Period You'd Like to Download
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Volunteer Schedules</InputLabel>
            <Select
              MenuProps={{ disablePortal: true }}
              value={selectedQuarter}
              label="Volunteer Time Period"
              onChange={(e) => setSelectedQuarter(e.target.value)}
            >
              {availableQuarters.map((timePeriod, index) => (
                <MenuItem key={index} value={timePeriod}>
                  {timePeriod}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CANCEL
          </Button>
          {selectedQuarter !== "" && (
            <Button color="primary" onClick={handleDownload}>
              DOWNLOAD
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
