// This function runs when the user clicks to download the orders.  It takes in
// selectedDate and the string of orders as arguments that can be read by a docx
// file.
export default function DownloadOrders(
  downloadString,
  distributionDate,
  distributionLocation,
) {
  const preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title><style>.order-container {border: 1px solid #000;padding: 10px;margin-bottom: 20px;width: calc(50% - 10px);box-sizing: border-box;}.order-container p, .order-container ul {margin: 0;padding: 0;}</style></head><body>";
  const postHtml = "</body></html>";

  const html = preHtml + downloadString + postHtml;

  // Specify link url
  const url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  const filename = `${distributionLocation}-CommunityOrders-${distributionDate}.doc`;

  // Create download link downloadString
  const downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = url;

  // Setting the file name
  downloadLink.download = filename;

  // triggering the function
  downloadLink.click();

  document.body.removeChild(downloadLink);
}
