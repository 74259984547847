// This is used to calculate the packaging fees per location.
export default function CalculatePackagingFees(
  communityOrders,
  importedOrder,
  selectedDistributionLocation,
  location,
) {
  // The packagingFees per location.
  const packagingFees = {};

  // The total packaging fees at all locations.
  let totalPackagingFees = 0;

  // Set the current location's packaging fees to 0
  packagingFees[location] = 0;
  // Cycle through the community orders of this location.
  Object.keys(communityOrders).forEach((customerOrder) => {
    // Cycle through each foodItem ordered by each customer.
    communityOrders[customerOrder].foodList.forEach((foodItem) => {
      // If the package fee exists and it is more that nothing.
      if (!!foodItem.packageFee && foodItem.packageFee > 0) {
        // Add the packaging amount to the location's total
        packagingFees[location] +=
          parseFloat(foodItem.packageFee) * foodItem.quantity;
        // Add the packaging amount to the total of all locations.
        totalPackagingFees +=
          parseFloat(foodItem.packageFee) * foodItem.quantity;
      }
    });
  });

  // Cycle through the imported locations
  Object.keys(importedOrder).forEach((importedLocation) => {
    // Set importedCommunityOrders so it easy to access this location's communityOrders.
    const importedCommunityOrders =
      importedOrder[importedLocation].communityOrders;
    // Initialise the current location's packaging fees to 0
    packagingFees[importedLocation] = 0;
    // Cycle through the community orders of each imported location.
    Object.keys(importedCommunityOrders).forEach((customerOrder) => {
      // Cycle through each foodItem ordered by each customer.
      importedCommunityOrders[customerOrder].foodList.forEach((foodItem) => {
        // If the package fee exists and it is more that nothing.
        if (!!foodItem.packageFee && foodItem.packageFee > 0) {
          // Add the packaging amount to the location's total
          packagingFees[importedLocation] +=
            parseFloat(foodItem.packageFee) * foodItem.quantity;
          // Add the packaging amount to the total of all locations.
          totalPackagingFees +=
            parseFloat(foodItem.packageFee) * foodItem.quantity;
        }
      });
    });
  });

  // Set the total in the map to the total package fees.
  packagingFees.Total = totalPackagingFees;

  return packagingFees;
}
