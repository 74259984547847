// RemoveItem is used to remove an item from either the distribution location's foodItem
// list or the communityOrder list that is from a farm that is sent in through
// the parameter farmName.  It updates the hooks to rerender the page.
export default function RemoveItem(
  foodItems,
  farmName,
  setFoodItems,
  setFarmsRemoved,
) {
  // Copy the foodItems array hook to a new one that can be manipulated
  const arrFood = [...foodItems];

  // Cycle through all the foodItems that were sent it.
  foodItems.forEach((foodItem) => {
    // checks to see if the foodItem comes from the farm we're removing.  If
    // it does then remove it.
    if (foodItem.farmName === farmName) {
      // Find the index of the foodItem in the arrFod array
      const index1 = arrFood.findIndex(
        (element) =>
          foodItem.item === element.item &&
          foodItem.description === element.description &&
          foodItem.farmName === element.farmName,
      );
      if (index1 !== -1) {
        // Update the copy by removing the item from the array and update the hook
        arrFood.splice(index1, 1);
        // Set the flag that says that the farm was removed which will show a
        // on the basket page that allows the user to reverse the removal.
        setFarmsRemoved(true);
      }
    }
  });
  // update the hook that contains the food
  setFoodItems([...arrFood]);
}
