// This is when the distribution location is enabling or disabling the questionnaire for their locations.
// This is called and it will either turn or turn off the questionnaire depending what the current
// status of it was.
import firebase from "../../../../components/Firebase.js";
import UpdateCommunityLocation from "../../../../functions/UpdateCommunityLocation.js";
import PropTypes from "prop-types";

export default function EnableQuestionnaire(
  questionnaire,
  userInfo,
  handleUserInfoChange,
  questionnaireEnabled,
) {
  // Load the database.
  const database = firebase.firestore();
  const batch = database.batch();
  // The questionnaire temp that can be edited.
  const questionnaireTemp = { ...questionnaire };
  const tempUserInfo = { ...userInfo };
  // The time now in a string.
  const now = Date.now().toString();
  // The list of questionnaires for the current distribution location.
  let questionnaireList = userInfo.questionnaireList;
  // If the questionnaire is enabled.
  if (questionnaireEnabled) {
    // Delete the questionnaire since it has to be opposite of being on.
    delete questionnaireList[questionnaireTemp.createdDate];
  } else {
    // If the questionnaire list isn't defined make sure it is.
    if (questionnaireList === undefined) {
      questionnaireList = {};
    }
    // Load the questionnaire answers reference which will be used to store the
    // answers to this questionnaire.
    const docRef = database.collection("QuestionnaireAnswers").doc(now);
    // The answers to the questionnaire are blank.
    questionnaireTemp.answers = {};
    // The name of the questionnaire document is the current time.
    questionnaireTemp.QuestionnaireDocumentName = questionnaireTemp.createdDate;
    // The now of the createdDate.
    questionnaireTemp.createdDate = now;
    // Update the questionnaire list.
    questionnaireList[questionnaireTemp.QuestionnaireDocumentName] =
      questionnaireTemp.createdDate;

    batch.set(docRef, questionnaireTemp);
  }
  // Set the userInfo to update teh questionnairelist.
  tempUserInfo.questionnaireList = { ...questionnaireList };
  // Update the userInfo.
  handleUserInfoChange(tempUserInfo, false, true);

  // Update the database.
  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  batch.update(distributionLocationsDocRef, {
    [[userInfo.organisationName, "questionnaireList"].join(".")]:
      questionnaireList,
  });
  // Updates any community locations that may exist that have this distribution location as their partner.
  UpdateCommunityLocation(
    userInfo.userId,
    { ...tempUserInfo },
    false,
    false,
    {},
    false,
    false,
    true,
  );

  batch.commit();
}

EnableQuestionnaire.propTypes = {
  questionnaire: PropTypes.object,
  userInfo: PropTypes.object,
  handleUserInfoChange: PropTypes.func,
  questionnaireEnabled: PropTypes.bool,
};
