// This button shows a list where a specific items is to be picked up and how much
// of it will be distributed there.
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import SortWholeCommunityOrders from "../../Functions/SortWholeCommunityOrders.js";
import CustomersByLocationsOrderedItem from "./CustomersByLocationsOrderedItem.jsx";

export default function LocationsOrderedItem({
  food,
  communityOrders,
  importedOrder,
  organisationName,
}) {
  // Whether to display the pickup locations and the quantities.
  const [open, setOpen] = useState(false);

  // Where the items are coming from and so where to look in the customerFoodListPerLocation
  // dictionary.  They can come from the ordered items or the pantry items.
  let itemsOrigination = "orderedItems";

  // If the item has a food limit it is from the pantry and therefore we need to update.
  if (food.limit) {
    itemsOrigination = "pantryItems";
  }

  // Send the community orders and the imported orders to be put into one list where
  // the food items are per location and the pantry items are per location.
  const customerFoodListPerLocation = SortWholeCommunityOrders(
    communityOrders,
    importedOrder,
    organisationName,
  );

  // This stores the extra data that we want per food item including the number of items
  // going to a specific location and the customers that ordered them.
  const moreData = {};

  // Cycle through the first layer of the dictionary which is the distribution Locations.
  Object.keys(customerFoodListPerLocation).forEach((distributionLocation) => {
    // Cycle through the second layer of the dictionary which is the pickup Location which
    // can include the community pickups.
    Object.keys(customerFoodListPerLocation[distributionLocation]).forEach(
      (pickupLocation) => {
        // This is the index of the foodItem that we're currently observing in the customerFoodListPerLocation
        // which has all the information we need.
        const indexOfFoodItem = customerFoodListPerLocation[
          distributionLocation
        ][pickupLocation][itemsOrigination].findIndex((foodItem) => {
          return (
            food.item === foodItem.item &&
            food.individualDescription === foodItem.individualDescription &&
            food.farmName === foodItem.farmName
          );
        });
        // If the foodItem exists at this location then we need to add the info to our
        // moreData dictionary.
        if (indexOfFoodItem !== -1) {
          // The foodItem and it's details.
          const foodItem =
            customerFoodListPerLocation[distributionLocation][pickupLocation][
              itemsOrigination
            ][indexOfFoodItem];
          // Set the pickupLocation
          let pickupLocationData = pickupLocation;
          // If it is onsite we'll call it the distributionLocation since we want to know
          // the name of the distribution location as well.
          if (pickupLocation === "Onsite") {
            pickupLocationData = distributionLocation;
          }
          // Update moreData with the information.
          moreData[pickupLocationData] = {
            quantity: foodItem.quantity,
            customers: { ...foodItem.customers },
          };
        }
      },
    );
  });

  return (
    <div className="Distribution-Locations-Per-Item">
      <Button
        size="small"
        style={{ background: "#9FBF59", color: "white" }}
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        DISTRIBUTED
      </Button>

      {open && (
        <div>
          {Object.keys(moreData).map((pickupLocation, index) => {
            return (
              <div key={index}>
                <b>
                  {index + 1}. {pickupLocation} {food.individualDescription}{" "}
                  <u style={{ color: "red" }}>
                    x{moreData[pickupLocation].quantity}
                  </u>
                </b>
                <CustomersByLocationsOrderedItem
                  customers={moreData[pickupLocation].customers}
                  individualDescription={food.individualDescription}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
