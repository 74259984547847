// This dialog is created when a user places an order.  This is to give the browser
// time to place the order and any items to the pantry.  Once the items have been
// added to the pantry then this will close.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/MyAccount.css";

function AddingToPantryBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        Adding Items to the Pantry.
      </DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          Please wait while add your items to the pantry.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

AddingToPantryBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function AddingToPantryDialog(props) {
  const open = props.open;

  return (
    <div>
      <AddingToPantryBox open={open} />
    </div>
  );
}
