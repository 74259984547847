// This a select that we use to allow the user to sort the food by how they want.  It will
// change depending on what they select.
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import HandleFoodItemsSort from "../Functions/HandleFoodItemsSort.js";

export default function SortFoodItemsSelect({
  foodItems,
  setFoodItems,
  sortBy,
  setSortBy,
  userInfo,
}) {
  return (
    <div>
      <FormControl variant="standard">
        <InputLabel id="">Sort By</InputLabel>
        <Select
          labelId=""
          id=""
          value={sortBy}
          onChange={(e) =>
            HandleFoodItemsSort(
              e.target.value,
              foodItems,
              setFoodItems,
              setSortBy,
              userInfo,
            )
          }
          label="Sort By"
        >
          <MenuItem value={"farmNameAsc"}>Farm Name A-Z</MenuItem>
          <MenuItem value={"farmNameDesc"}>Farm Name Z-A</MenuItem>
          <MenuItem value={"itemNameAsc"}>Item Name A-Z</MenuItem>
          <MenuItem value={"itemNameDesc"}>Item Name Z-A</MenuItem>
          {userInfo.userType !== "individual" && (
            <MenuItem value={"visibilityAsc"}>Visibile Top</MenuItem>
          )}
          {userInfo.userType !== "individual" && (
            <MenuItem value={"visibilityDesc"}>Visible Bottom</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
