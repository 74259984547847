// This is old code that was used before the imported orders was changed and multiple
// orders can be imported.  June 17 2021.

// This is to update an individual user's donation or subsidy amounts and update
// whether they are donating or using a subsidy from the myAccount Page
import firebase from "./../Firebase.js";

export default function UpdateCustomerContributionOld(props, userOrder) {
  const database = firebase.firestore();
  const batch = database.batch();
  // Includes the user's entire order
  const order = props.order;
  // This is the orderLog of all the orders made by the Distribution Location
  const orderLog = props.orderLog;
  const user = props.user;
  const imported = props.imported;
  const userInfo = props.userInfo;
  const updateOrderLog = props.updateOrderLog;
  const orderIndex = props.orderIndex;

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(order.orderDate);

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // Assume the order is coming from the communityOrders this is used to set the
  // database name
  let userDataRef = "communityOrders";

  if (imported) {
    // If the imported Orders are being modified change this to be correct
    userDataRef = "importedOrder";

    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(order.importedDistributionLocation)
      .collection("CommunityOrders")
      .doc(order.importedDistributionDate);
  } else {
    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);
  }

  // Add the userkey and the date to find the correct key to modifiy in the farmList
  // array if the farm is new.
  userDataRef = userDataRef.concat(".", user);

  // Remove the Dicts as they were added for rendering purposes and should not
  // be saved to the database
  delete userOrder.foodDict;
  delete userOrder.farmDict;

  // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
  // FirstName-LastName-userId-date
  batch.update(docRef, {
    [userDataRef]: { ...userOrder },
  });

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  batch.update(communityOrdersDocRef, {
    [user]: { ...userOrder },
  });

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userDocRef, {
    customerContribution: userOrder.customerContribution,
    donationSubsidy: userOrder.donationSubsidy,
  });

  // This is to make sure the page rerenders these items correctly
  if (imported) {
    orderLogTemp[orderIndex].importedOrder[user] = { ...userOrder };
  } else {
    orderLogTemp[orderIndex].communityOrders[user] = { ...userOrder };
  }
  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
