// This is a dialog asks the user if they would like to update individual customer
// orders to match the removal of the distribution location.  It also confirms that
// the user wants to send each of these affected users an email.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function MultipleFoodItemRemoveDialog({
  openSelectAction,
  setOpenSelectAction,
  setStateOfDialog,
  updateOrders,
  setUpdateOrders,
  handleEmailAndOrderRemoval,
}) {
  // Flag set to determine if the distribution location wants to email the users
  // that were affected by this change.
  const [emailUsers, setEmailUsers] = useState(true);

  // Makes open equal to openSelectAction so that it is just simpler to type
  const open = openSelectAction;

  // If the user decides to close he dialog then just close the dialog.
  const handleClose = () => {
    setOpenSelectAction(false);
  };

  // if the user selects to send emails to users than they will click next and
  // review their actions before confirming.
  const handleNext = (value) => {
    setOpenSelectAction(false);
    setStateOfDialog(value);
  };

  // Changes the flags to determine the users actions.  If they want to update the orders
  // and the second option is if they want to send the users emails.
  const handleChange = (e) => {
    if (e.target.value === "updateOrders") {
      setUpdateOrders(!updateOrders);
    } else {
      setEmailUsers(!emailUsers);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Delete The Following Items
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleEmailAndOrderRemoval}>
            <span className="Modify-Orders-Check">
              <Checkbox
                edge="start"
                onClick={handleChange}
                checked={updateOrders}
                value="updateOrders"
                tabIndex={-1}
                disableRipple
              />
              <p>
                Check to also remove this/these item(s) from individual orders.{" "}
              </p>
            </span>
            <span className="Modify-Orders-Check">
              <Checkbox
                edge="start"
                onClick={handleChange}
                checked={emailUsers}
                value="emailUsers"
                tabIndex={-1}
                disableRipple
              />
              <p>Check to also email each user of the changes. </p>
            </span>

            <DialogActions>
              <Button color="primary" onClick={handleClose}>
                Cancel
              </Button>
              {emailUsers ? (
                <Button
                  onClick={() => handleNext("UsersSelect")}
                  color="primary"
                >
                  Next
                </Button>
              ) : (
                <Button type="submit" color="primary">
                  Confirm
                </Button>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
