// This function takes in an array of users firstName-lastName-userId and then cycles through
// them using the communityOrders dictionary to calculate these specific user's total order cost
// By adding up their foodItems and then subtracting or adding the subsidy or donation.
import PriceTotals from "../functions/PriceTotals.js";
import PackageTotals from "../functions/PackageTotals.js";

export default function CalculateOrderTotals(userOrders, communityOrders) {
  // The dictionary that will store the user and their amount owed {firstName-lastName-userId : XX}
  const customerOrderCosts = {};

  // Cycle through the userOrders
  userOrders.forEach((userOrder) => {
    // In case the userOrder includes a date at the end of it we want to only use the
    // firstName, lastName and userId and save that in a user.
    const user = [
      userOrder.split("-")[0],
      userOrder.split("-")[1],
      userOrder.split("-")[2],
    ].join("-");

    // Total up the cost of the order using PriceTotals which separates out the total
    // owed to each farm.
    const orderFarmsCost = PriceTotals(
      communityOrders[userOrder].foodList,
      true,
    );
    // A dictionary of the the farms(keys) and the total packaging fees to them (values)
    const packageTotals = PackageTotals(communityOrders[userOrder].foodList);
    // set the order cost to 0.
    let orderCost = 0;
    // Cycle through the totals owed to each farm and add them up.
    Object.keys(orderFarmsCost).forEach((farmName) => {
      orderCost += orderFarmsCost[farmName];
    });
    // Cycle through the totals owed to each farm and add them up.
    Object.keys(packageTotals).forEach((packageTotal) => {
      orderCost += packageTotals[packageTotal];
    });
    // Add or subtract the subsidy and donations to the total order cost.
    if (communityOrders[userOrder].customerContribution === "donation") {
      orderCost += communityOrders[userOrder].donationSubsidy;
    } else {
      orderCost -= communityOrders[userOrder].donationSubsidy;
    }

    // Check to see if this user already exists because they placed more than one
    // order.
    if (customerOrderCosts[user]) {
      // Add to existing order(s) the new order cost.
      customerOrderCosts[user] += orderCost;
    } else {
      // Create a new key value holding the user and their order cost.
      customerOrderCosts[user] = orderCost;
    }
  });

  // Provide a dictionary with all the users and the amounts they owe.
  return customerOrderCosts;
}
