// This page is used by the user to pick a place where they would like to place
// their order.   They will be shown a map with the places displayed on the map.
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../components/authentication/Auth.js";
import "../App.css";
import "../styles/DeliveryMap.css";
import "../styles/DistributionLocationSelection.css";
import firebase from "../components/Firebase.js";
import LongLatAdjustment from "./DistributionLocationSelectionPage/Functions/LongLatAdjustment.js";
import DistributionLocationMap from "./DistributionLocationSelectionPage/Components/DistributionLocationMap";
import DeliveryMapLegend from "./DistributionLocationSelectionPage/Components/DeliveryMapLegend";
import DistributionLocationSelectionHeader from "./DistributionLocationSelectionPage/Components/DistributionLocationSelectionHeader";
import ListElement from "./DistributionLocationSelectionPage/Components/ListElement";
import SendingRequestDialog from "./DistributionLocationSelectionPage/Dialogs/SendingRequestDialog";
import Footer from "../components/Footer";
import LoadingContent from "../components/LoadingContent";
import Menu from "../components/Menu";
// The main function that loads the page
export default function DistributionLocationSelection(props) {
  let previousPage = props.location.query;

  // In case the previous page isn't set then we just send them back to the marketplace
  // page.
  if (previousPage === undefined) {
    previousPage = "/Marketplace";
  }

  // The database being used
  const database = firebase.firestore();

  const { userInfo, currentUser, handleUserInfoChange } =
    useContext(AuthContext);

  // This is used to store the location information when the user clicks an icon
  // on the map
  const [activeIcon, setActiveIcon] = useState(null);

  // This stores all the pickupLocations.
  const [pickupLocations, setPickupLocations] = useState([]);

  const [loading, setLoading] = useState(false);

  // A hook that states whether the community distribution locations have been loaded
  // from the database yet.
  const [
    communityDistributionLocationsLoading,
    setCommunityDistributionLocationsLoading,
  ] = useState(false);
  // The community locations that the current user is allowed to see.
  const [communityDistributionLocations, setCommunityDistributionLocations] =
    useState([]);
  // When the user applies to view more community locations an email is sent out and this will
  // stay true until the email is done sending.
  const [sendingEmail, setSendingEmail] = useState(false);
  // Community hosts that are hidden from the user that are private.
  const [
    privateCommunityDistributionLocations,
    setPrivateCommunityDistributionLocations,
  ] = useState([]);

  useEffect(() => {
    const docRef = database
      .collection("DistributionLocations")
      .doc("Vancouver");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Temp variable to hold the data that we can modify.
          const pickupLocationsTemp = { ...doc.data() };
          // Cycle through all the distribution locations and check if any are inactive.
          Object.keys(doc.data()).forEach((distributionLocation) => {
            // If this location is inactive.
            if (doc.data()[distributionLocation].status === "inactive") {
              // Delete it so that it cannot be selected.
              delete pickupLocationsTemp[distributionLocation];
            }
          });
          // Set the pickup locations state.
          setPickupLocations(pickupLocationsTemp);
          setLoading(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(true);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        // setLoading(true)
      });
  }, [database, currentUser]);

  // Once the program has finished loading all the current user's approved distribution
  // locations that allow them to see their community hosts cycle load the community
  // hosts themselves.
  useEffect(() => {
    // Sets this to false in case it is called and needs to load again.
    setCommunityDistributionLocationsLoading(false);
    // If the approvedDistributionLocationsLoading is done then run this effect.
    if (loading) {
      // The approved list of all the community locations.
      const approvedLocations = [];
      // Make sure the user has a community host access
      if (userInfo.CommunityHostAccess) {
        // Cycle through all the community hosts of each of those distribution locations.
        Object.keys(userInfo.CommunityHostAccess).forEach((communityHost) => {
          // Check to see if the user thinks it has been approved by this location
          if (
            userInfo.CommunityHostAccess[communityHost].status === "approved"
          ) {
            // add this location to the approved location list.  Approved from the user's point of view
            approvedLocations.push(
              userInfo.CommunityHostAccess[communityHost].locationName,
            );
          }
        });
      }
      // Create a list of communityLocations with the key being the distribution
      // location and the value being an array of all the commmunity hosts.
      // Pull all the community Hosts that are from the distribution location
      const docRef = database
        .collection("CommunityLocations")
        .where("approved", "==", true);
      docRef.get().then((collection) => {
        // Community Hubs that are public.
        const dataTransfer = [];
        // Community Hubs that are private.
        const dataTransfer2 = [];
        // push all the the community hosts into an array for this location
        collection.docs.forEach((doc) => {
          // Check to the make sure the community Location is visible to the public
          if (!doc.data().hidden) {
            // If this community host is in the user's approved list check to
            // make sure it still is
            if (approvedLocations.includes(doc.data().locationName)) {
              // Check that the user's userid is in this location's approvedCommunityMembers
              if (
                doc.data().approvedCommunityMembers.includes(userInfo.userId)
              ) {
                // if it is then add this communityhost
                dataTransfer.push(doc.data());
              }
            }
            // if the user hasn't been approved by this location before then add it.
            else if (doc.data().communityHubType === "private") {
              dataTransfer2.push(doc.data());
            } else {
              dataTransfer.push(doc.data());
            }
          }
        });
        // If there are community hosts
        if (dataTransfer.length !== 0) {
          // set the community host list
          setCommunityDistributionLocations([...dataTransfer]);
        }
        // If there are private community hosts
        if (dataTransfer2.length !== 0) {
          // set the community host list
          setPrivateCommunityDistributionLocations([...dataTransfer2]);
        }

        setCommunityDistributionLocationsLoading(true);
      });
    }
  }, [database, loading]);

  // When you click on a specific location then the map will toggle the activeIcon
  // to the location you selected.
  function handleActiveIcon(locationDetails) {
    // Checks to see if the currently selected item is the activeIcon.  If it is
    // then just remove it.  Otherwise set it as the activeIcon.
    if (locationDetails === activeIcon) {
      setActiveIcon(null);
    }
    // If the location isn't currently set
    else {
      // Check to see if this is a commmunity location.
      if (locationDetails.pickupLocation) {
        // Adjusts the longitude and latitude in case this user shouldn't be able
        // to see the exact location.
        const [latitude, longitude] = LongLatAdjustment(
          locationDetails,
          userInfo,
        );
        // Sets the activeIconTemp to the location.
        const activeIconTemp = { ...locationDetails };
        // Adjusts the latitude. If the user doesn't have access to this location then
        // it will be obscured otherwise it will stay the same.
        activeIconTemp.latitude = latitude;
        // Adjusts the longitude. If the user doesn't have access to this location then
        // it will be obscured otherwise it will stay the same.
        activeIconTemp.longitude = longitude;
        setActiveIcon({ ...activeIconTemp });
      }
      // If it is a distribution location.
      else {
        setActiveIcon(locationDetails);
      }
    }
  }

  // When the user clicks the select button on a distribution location this will
  // set the new pickupLocation.  Location includes all the details of the location
  // copied from the DistributionLocations -> City [locationName]
  function handleClickOpen(location) {
    const userInfoTemp = { ...userInfo };
    userInfoTemp.pickupLocation = location;
    // Check to see if they're logged in and update their userInfo.
    if (currentUser) {
      handleUserInfoChange({ ...userInfoTemp }, false, true);
    }
  }

  if (loading && communityDistributionLocationsLoading) {
    return (
      <div id="home">
        <Menu />
        <DistributionLocationSelectionHeader
          userInfo={userInfo}
          handleUserInfoChange={handleUserInfoChange}
          currentUser={currentUser}
          pickupLocations={pickupLocations}
          setSendingEmail={setSendingEmail}
          communityDistributionLocations={communityDistributionLocations}
          setCommunityDistributionLocations={setCommunityDistributionLocations}
          privateCommunityDistributionLocations={
            privateCommunityDistributionLocations
          }
          setPrivateCommunityDistributionLocations={
            setPrivateCommunityDistributionLocations
          }
        />
        <div className="Distribution-Location-Page">
          {/* Checks if the user has any pickupLocations to show if not then
              it tells the user that there are no pickupLocations on the selected day. */}
          <h3 className="Legend-Title">
            <u>Difference Between Locations</u>
          </h3>
          <DeliveryMapLegend />
          {Object.keys(pickupLocations).length !== 0 ? (
            <div className="List-And-Map-Distribution-Selection">
              <ListElement
                pickupLocations={pickupLocations}
                userInfo={userInfo}
                activeIcon={activeIcon}
                toggleOnClick={handleActiveIcon}
                handleClickOpen={handleClickOpen}
                previousPage={previousPage}
                communityDistributionLocations={communityDistributionLocations}
                setSendingEmail={setSendingEmail}
              />
              <DistributionLocationMap
                pickupLocations={pickupLocations}
                handleActiveIcon={handleActiveIcon}
                communityDistributionLocations={communityDistributionLocations}
                userInfo={userInfo}
                activeIcon={activeIcon}
                setActiveIcon={setActiveIcon}
                handleClickOpen={handleClickOpen}
                previousPage={previousPage}
                setSendingEmail={setSendingEmail}
              />
            </div>
          ) : (
            <h1>No distribution locations are available at this time.</h1>
          )}
        </div>
        <SendingRequestDialog open={sendingEmail} />
        <Footer />
      </div>
    );
  } else {
    return <LoadingContent />;
  }
}
