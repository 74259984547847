// This dialog appears the distribution location wants to create a new questionnaire.
import React, { useState } from "react";
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ButtonGroup from "@mui/material/ButtonGroup";
import QuestionEditorCard from "../../Components/Questionnaires/QuestionEditorCard.jsx";

// This is the dialog box that opens when a user clicks the add button
function CreateQuestionnaireBox({
  open,
  onClose,
  userInfo,
  questionnaires,
  setQuestionnaires,
}) {
  // Sets the questionnaire to have the defaults.
  const [questionnaire, setQuestionnaire] = useState({
    author: userInfo.userId,
    title: "",
    description: "",
    questions: [],
  });

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  // If the user saves their changes
  const handleSave = (e) => {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();
    // Set the now into a string that is used to say when the questionnaire was created.
    const now = Date.now().toString();
    // The questionnaire temp variable.
    const questionnaireTemp = { ...questionnaire };
    // Create a reference to the Questionnaries collection.
    const docRef = database.collection("Questionnaires").doc(now);
    // Set the created Date
    questionnaireTemp.createdDate = now;

    batch.set(docRef, questionnaireTemp);

    batch.commit();
    // Set the questionnaires list that exists for this locaiton.  List of all questionnaires that already
    // exist.
    const questionnairesTemp = [...questionnaires];
    // Add the questionnaireTemp to the array.
    questionnairesTemp.push({ ...questionnaireTemp });

    setQuestionnaires([...questionnairesTemp]);

    setQuestionnaire({
      author: userInfo.userId,
      title: "",
      description: "",
      questions: [],
    });

    onClose(false);
  };

  // If the user adds another question to the questionnarie then we want to add it.
  const handleAddQuestion = (value) => {
    // Temp variable to use to manipulate the current questionnaire.
    const questionnaireTemp = { ...questionnaire };
    // Temp array to use to update the current set of questions.
    const questionsTemp = [...questionnaire.questions];
    // The question to add.
    const questionToAdd = {};
    // The question prompt for the new question set to a blank string.
    questionToAdd.questionPrompt = "";
    // The options for the question which is an array that with blank answers.
    questionToAdd.options = [""];

    // Set the question to the questiontype the user chose.
    if (value === "multi") {
      questionToAdd.type = "multi";
    } else if (value === "checkbox") {
      questionToAdd.type = "checkbox";
    } else {
      questionToAdd.type = "text";
    }

    // Add the question to the array of current questions
    questionsTemp.push(questionToAdd);

    // update the questionnaire questions.
    questionnaireTemp.questions = [...questionsTemp];
    // Update the questionnaire.
    setQuestionnaire({ ...questionnaireTemp });
  };

  // If the user changes the questionnaire values.
  const handleQuestionnaireChange = (target) => {
    // Temp variable for the questionnaire to change.
    const questionnaireTemp = { ...questionnaire };
    const value = target.value;
    const name = target.name;
    // If the name is the questionnaireTitle then we're updating the title
    // otherwise we're updating the description.
    if (name === "questionnaireTitle") {
      questionnaireTemp.title = value;
    } else {
      questionnaireTemp.description = value;
    }

    // Set the questionnaire.
    setQuestionnaire({ ...questionnaireTemp });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Create a Questionnaire
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <TextField
            margin="dense"
            id="questionnaireTitle"
            label="Questionnaire Title"
            name="questionnaireTitle"
            value={questionnaire.title}
            onChange={(e) => {
              handleQuestionnaireChange(e.target);
            }}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="questionnaireDescription"
            label="Question Description"
            name="questionnaireDescription"
            value={questionnaire.description}
            onChange={(e) => {
              handleQuestionnaireChange(e.target);
            }}
            fullWidth
            required
          />
          <ButtonGroup>
            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddQuestion("multi")}
            >
              MULTIPLE CHOICE
            </Button>
            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddQuestion("checkbox")}
            >
              CHECKBOXES
            </Button>
            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddQuestion("text")}
            >
              TEXT ANSWER
            </Button>
          </ButtonGroup>
          <div>
            {questionnaire.questions.map((question, index) => (
              <QuestionEditorCard
                key={index}
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                indexOfQuestion={index}
              />
            ))}
          </div>
          <DialogActions>
            <Button onClick={() => handleClose()} color="primary">
              CLOSE
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

CreateQuestionnaireBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
};

export default function CreateQuestionnaireDialog({
  userInfo,
  questionnaires,
  setQuestionnaires,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        startIcon={<AddCircleIcon />}
      >
        CREATE NEW QUESTIONNAIRE
      </Button>

      <CreateQuestionnaireBox
        open={open}
        onClose={handleClose}
        userInfo={userInfo}
        questionnaires={questionnaires}
        setQuestionnaires={setQuestionnaires}
      />
    </div>
  );
}

CreateQuestionnaireDialog.propTypes = {
  userInfo: PropTypes.object,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
};
