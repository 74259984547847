// This is called when an order has been placed by a distribution location or a
// restaurant.   It will update the pantry, send out any emails to cancelled community
// pickup locations, and send the order emails to the food vendors.  It will also
// reset the basket to being empty.
import AddLeftoversToPantry from "../../../components/Pantry/AddLeftoversToPantry.js";
import UsersToEmailUpdates from "./UsersToEmailUpdates.js";
import moment from "moment";
import ExtraQuantitiesEmailsAndUpdate from "./OrderUpdates/ExtraQuantitiesEmailsAndUpdate.js";
import batchEmails from "../../../functions/Email/BatchEmailer.js";
import OrderEmails from "../../../pages/BasketPage/OrderEmails.js";

// combinedFoodList: is if a distribution location is adding to an existing order we
// will combine the old foodList with the new one.
// foodItemsUpdated: The items that have farmLimits on them and have been updated already. {foodItemRef : amountDeducted}
// leftoversToAdd: When a distribution location adds to an existing order then the leftovers are calculated by taking into
// account the exisiting order, the new order, the amount that was added to the pantry already and how many items were taken
// from the pantry between orders.
export default function FinalisePlaceOrder(
  combinedFoodList,
  importedOrder,
  communityOrders,
  userInfo,
  checkList,
  handleUserInfoChange,
  batch,
  modifiedUserPickups,
  noRepeatsFarmerInfo,
  farmDeliveryDays,
  contactMethods,
  foodItems,
  farmTotals,
  donationSubsidy,
  customerContribution,
  setPlacingOrder,
  setPlacingBatchesOrder,
  setSnackBarOpen,
  setFoodItems,
  setCommunityFoodItems,
  restaurantOrder,
  setAddingToPantry,
  setImportedAddingToPantry,
  setPantryFoodItems,
  foodItemsUpdated,
  leftoversToAdd,
  extraQuantitiesDict,
) {
  // This array is used to reflect the dropOff day of a recurring order
  const daysOfTheWeekArray = [
    null,
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // If the user is a restaurant then there is no pantry.
  if (!restaurantOrder) {
    // Write to the Pantry of the distribution Location which items to be stored from
    // this order
    // Testing Remove
    setAddingToPantry(true);
    setImportedAddingToPantry(true);
    AddLeftoversToPantry(
      foodItems,
      importedOrder,
      communityOrders,
      userInfo,
      checkList,
      setAddingToPantry,
      setImportedAddingToPantry,
      foodItemsUpdated,
      leftoversToAdd,
    );
  }

  // Set all the quantities that were in the basket back to 0 remove them from
  // the basket since they've been ordered.
  userInfo.basketItems = [];
  // Testing Remove
  handleUserInfoChange(userInfo, true);

  // Commit these changes to the database.
  // Testing Remove
  batch.commit();
  // Testing Remove
  UsersToEmailUpdates(modifiedUserPickups);

  // if extraQuantitiesDict exists then we have some food to distribute to users.
  if (extraQuantitiesDict !== undefined) {
    ExtraQuantitiesEmailsAndUpdate(
      extraQuantitiesDict,
      communityOrders,
      userInfo,
    );
  }

  // If there are no foodItems (when a distribution location is only ordering from the pantry)
  // then we need to set the placing order to done and turn on the notice that the order has been placed.
  // Skip the order emails since no one will be emailed for this type of order.
  if (foodItems.length === 0) {
    setPlacingOrder(false);
    setSnackBarOpen(true);
  } else {
    // Call OrderEmails for each farm, batching the emails to avoid exceeding Outlook's concurrent connection limit
    const emailBatch = [];

    noRepeatsFarmerInfo.forEach((farm) => {
      // The date of the delivery
      const deliveryDate = farmDeliveryDays[farm.farmName]
        .toString()
        .substring(0, 15);
      // This is the day of the week of the delivery.  This is for recurring orders
      // and is included in the email to the farmer
      const deliveryDay =
        daysOfTheWeekArray[moment(farmDeliveryDays[farm.farmName]).day()];
      // Testing Remove
      if (contactMethods[farm.farmName] === "Email") {
        emailBatch.push([
          farm,
          foodItems,
          userInfo,
          deliveryDate,
          deliveryDay,
          farmTotals[farm.farmName],
          donationSubsidy,
          customerContribution,
          setPlacingOrder,
          setSnackBarOpen,
          null,
        ]);
      }
    });

    setPlacingBatchesOrder(true);

    // Send emails in batches
    batchEmails({
      batchedParamsForEmailFunc: emailBatch,
      singularEmailFunc: OrderEmails,
    })
      .then(() => {
        // All emails sent successfully
        setFoodItems([]);
        setCommunityFoodItems([]);
        setPantryFoodItems([]);
        setPlacingBatchesOrder(false);
      })
      .catch((error) => {
        console.error("Error sending batch emails:", error);
      });
  }
}
