// This takes in the orderPaymentHistory and displays the deposit dates and how
// much was deposited from the current week vs. previous weeks. It first needs
// to pull all the payment history over the last year 10x5 = 50 weeks and then
// organises them so we can see total refunds.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import OrderPaymentByDateListItem from "./OrderPaymentByDateListItem.jsx";
import FindNextDay from "../../../functions/FindNextDay.js";
import YYYYMMDDConverter from "../../../functions/YYYYMMDDConverter.js";
import firebase from "../../../components/Firebase.js";
import LoadingContent from "../../../components/LoadingContent.jsx";

// Adds the deposits to the payment history simplified.
function AddDepositsToPaymentHistorySimplified(
  orderPaymentHistory,
  locationOfDeposits,
  userOrder,
  paymentHistorySimplified,
  endOfCurrentWeek,
  distributionLocation,
  endOfCurrentWeekYYYYMMDD,
) {
  // Cycle through all the deposits.
  locationOfDeposits.deposits.forEach((deposit) => {
    // Check to see if the amount of the deposit is greater than 0 otherwise we can ignore it.
    if (deposit.amount > 0) {
      // Set the date that the money was deposited.
      let depositDate = new Date(deposit.date);
      // Check what the next Saturday following this deposit date is.
      depositDate = FindNextDay(depositDate, 6);
      // Set the format of the next Saturday.
      const depositDateYYYYMMDD = YYYYMMDDConverter(depositDate);
      // Check to see if this date of deposit already exists.  If not then
      // we have to create it.
      if (!paymentHistorySimplified[depositDateYYYYMMDD]) {
        paymentHistorySimplified[depositDateYYYYMMDD] = {};
      }
      // Check to see if this deposit date exists and the current distribution location exists otherwise
      // create it with defaults.
      if (
        !paymentHistorySimplified[depositDateYYYYMMDD][distributionLocation]
      ) {
        paymentHistorySimplified[depositDateYYYYMMDD][distributionLocation] = {
          communityOrders: {},
          etransferCurrent: 0,
          cashCurrent: 0,
          etransferOld: 0,
          cashOld: 0,
          refunds: 0,
          imported: false,
        };
      }
      // Check if the deposit date is after the order's distribution date.  If it is then we know it was late and
      // so it's an old payment.
      if (depositDate.getTime() > endOfCurrentWeek.getTime()) {
        // Check if it was cash or etransfer.
        if (deposit.method === "Cash") {
          // Add to the total cash amount for previous week payments.
          paymentHistorySimplified[depositDateYYYYMMDD][
            distributionLocation
          ].cashOld += deposit.amount;
        }
        // Otherwise it's an etransfer for a previous payment.
        else {
          // Add to the total etransfer amount for previous week payments.
          paymentHistorySimplified[depositDateYYYYMMDD][
            distributionLocation
          ].etransferOld += deposit.amount;
        }
      }
      // Otherwise it's a payment for the current week.s
      else {
        // Check if it was cash or etransfer.
        if (deposit.method === "Cash") {
          // Add to the total cash amount for the current week payments.
          paymentHistorySimplified[depositDateYYYYMMDD][
            distributionLocation
          ].cashCurrent += deposit.amount;
        }
        // Otherwise it's an etransfer for a previous payment.
        else {
          // Add to the total etransfer amount for the current week payments.
          paymentHistorySimplified[depositDateYYYYMMDD][
            distributionLocation
          ].etransferCurrent += deposit.amount;
        }
      }
      // Add this order to the community orders list and include the distribution week as there may be multiple payments from the same person
      // from different weeks on one deposit date. These are used to show the details of the deposits.
      paymentHistorySimplified[depositDateYYYYMMDD][
        distributionLocation
      ].communityOrders[userOrder.concat("-", endOfCurrentWeekYYYYMMDD)] =
        locationOfDeposits;
    }
  });

  return paymentHistorySimplified;
}

// Adds the refunds to the payment history simplified.
function AddRefundsToPaymentHistorySimplified(
  orderPaymentHistory,
  locationOfRefunds,
  userOrder,
  paymentHistorySimplified,
  endOfCurrentWeek,
  distributionLocation,
  endOfCurrentWeekYYYYMMDD,
) {
  // Cycle through all the refunds.
  locationOfRefunds.orderRefunds.forEach((refund) => {
    // Only record payment if the amount of the record is greater than 0 otherwise we can ignore it.
    if (refund.amount > 0) {
      // Set the date that the money was refunded.
      let refundDate = new Date(refund.date);
      // Check what the next Saturday following this refund date is.
      refundDate = FindNextDay(refundDate, 6);
      // Set the format of the next Saturday.
      const refundDateYYYYMMDD = YYYYMMDDConverter(refundDate);
      // Check to see if this date of refund already exists.  If not then
      // we have to create it.
      if (!paymentHistorySimplified[refundDateYYYYMMDD]) {
        paymentHistorySimplified[refundDateYYYYMMDD] = {};
      }
      // Check to see if this refund date exists and the current distribution location exists otherwise
      // create it with defaults.
      if (!paymentHistorySimplified[refundDateYYYYMMDD][distributionLocation]) {
        paymentHistorySimplified[refundDateYYYYMMDD][distributionLocation] = {
          communityOrders: {},
          etransferCurrent: 0,
          cashCurrent: 0,
          etransferOld: 0,
          cashOld: 0,
          refunds: 0,
          imported: false,
        };
      }
      // Add to the total amount refunded for this date.
      paymentHistorySimplified[refundDateYYYYMMDD][
        distributionLocation
      ].refunds += refund.amount;
      // Add this order to the community orders list and include the distribution week as there may be multiple refunds from the same person
      // from different weeks on one date.  These are used to show the details of the refunds.
      paymentHistorySimplified[refundDateYYYYMMDD][
        distributionLocation
      ].communityOrders[userOrder.concat("-", endOfCurrentWeekYYYYMMDD)] =
        locationOfRefunds;
    }
  });

  return paymentHistorySimplified;
}

// The main function which displays the order payment history in a list.
export default function OrderPaymentByDateList({ userInfo }) {
  // Creates the database reference.
  const database = firebase.firestore();
  // OrderPaymentHistory where all the documents from Payment History in the database
  // will be stored.
  const [orderPaymentHistory, setOrderPaymentHistory] = useState({});
  // Set to false while the payment history finishes loading.
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);

  // Load all the payment history documents in the last year.
  useEffect(() => {
    // This is used to the payment history documents and the put them in the orderPaymentHistory
    // state when it's done.
    // eslint-disable-next-line no-unused-vars
    const dataTransfer = [];
    // Load the last 10 payment histories.  This will order them by their created date and then
    // pick the ten newest ones.  Each document contains 5 weeks and so we'll be loading 50 weeks
    // worth of data which is a year.
    const paymentHistoryDocRef = database
      .collection("PaymentHistory")
      .orderBy("createdDate", "desc")
      .limit(10);
    // Update the Payment History to store information to help track payments.
    paymentHistoryDocRef
      .get()
      .then((collection) => {
        let dataTransfer = {};

        collection.docs.forEach((doc) => {
          if (doc.exists) {
            dataTransfer = Object.assign({}, dataTransfer, doc.data());
          }
        });
        setOrderPaymentHistory({ ...dataTransfer });
        setLoadingPaymentHistory(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  // The payment history with an array of each order date and it's details.
  let paymentHistorySimplified = {};

  // Cycle through the each order in orderPaymentHistory.  This order can include
  // both this distribution location's orders and imported orders.
  Object.keys(orderPaymentHistory).forEach((order) => {
    // There are multiple fields in the orderPayment that we don't need to make use
    // of such as the exists fields so make sure it is one that has the communityOrders
    // or the importedOrder.
    if (
      orderPaymentHistory[order].communityOrders ||
      orderPaymentHistory[order].importedOrder
    ) {
      // This is the date of distribution of the current order.
      let endOfCurrentWeek = new Date(orderPaymentHistory[order].selectedDate);
      // Saturday immediately following the distribution day of the user's order.
      endOfCurrentWeek = FindNextDay(endOfCurrentWeek, 6);
      // Convert to format we want.
      const endOfCurrentWeekYYYYMMDD = YYYYMMDDConverter(endOfCurrentWeek);

      // Check to see if the order was imported or not.
      if (orderPaymentHistory[order].communityOrders) {
        // Cycle through all the non imported community orders.
        Object.keys(orderPaymentHistory[order].communityOrders).forEach(
          (userOrder) => {
            // Make sure that deposits is an array as it was a dictionary for a brief few weeks when
            // we first started tracking.
            if (
              Array.isArray(
                orderPaymentHistory[order].communityOrders[userOrder].deposits,
              )
            ) {
              // Add the deposits of the current community members order to the list.
              paymentHistorySimplified = AddDepositsToPaymentHistorySimplified(
                orderPaymentHistory,
                orderPaymentHistory[order].communityOrders[userOrder],
                userOrder,
                paymentHistorySimplified,
                endOfCurrentWeek,
                userInfo.organisationName,
                endOfCurrentWeekYYYYMMDD,
              );
            }
            // Make sure that refunds is an array as it was a dictionary for a brief few weeks when
            // we first started tracking.
            if (
              Array.isArray(
                orderPaymentHistory[order].communityOrders[userOrder]
                  .orderRefunds,
              )
            ) {
              // Add the refunds of the current community members order to the list.
              paymentHistorySimplified = AddRefundsToPaymentHistorySimplified(
                orderPaymentHistory,
                orderPaymentHistory[order].communityOrders[userOrder],
                userOrder,
                paymentHistorySimplified,
                endOfCurrentWeek,
                userInfo.organisationName,
                endOfCurrentWeekYYYYMMDD,
              );
            }
          },
        );
      }
      // If the order was imported.
      if (orderPaymentHistory[order].importedOrder) {
        // Cycle through the different imported distribution locations.
        Object.keys(orderPaymentHistory[order].importedOrder).forEach(
          (distributionLocation) => {
            // Cycle through all the imported community orders.
            Object.keys(
              orderPaymentHistory[order].importedOrder[distributionLocation]
                .communityOrders,
            ).forEach((userOrder) => {
              // Make sure that deposits is an array as it was a dictionary for a brief few weeks when
              // we first started tracking.
              if (
                Array.isArray(
                  orderPaymentHistory[order].importedOrder[distributionLocation]
                    .communityOrders[userOrder].deposits,
                )
              ) {
                // Add the deposits of the imported community members order to the list.
                paymentHistorySimplified =
                  AddDepositsToPaymentHistorySimplified(
                    orderPaymentHistory,
                    orderPaymentHistory[order].importedOrder[
                      distributionLocation
                    ].communityOrders[userOrder],
                    userOrder,
                    paymentHistorySimplified,
                    endOfCurrentWeek,
                    distributionLocation,
                    endOfCurrentWeekYYYYMMDD,
                  );
              }

              // Make sure that refunds is an array as it was a dictionary for a brief few weeks when
              // we first started tracking.
              if (
                Array.isArray(
                  orderPaymentHistory[order].importedOrder[distributionLocation]
                    .communityOrders[userOrder].orderRefunds,
                )
              ) {
                // Add the refunds of the imported community members order to the list.
                paymentHistorySimplified = AddRefundsToPaymentHistorySimplified(
                  orderPaymentHistory,
                  orderPaymentHistory[order].importedOrder[distributionLocation]
                    .communityOrders[userOrder],
                  userOrder,
                  paymentHistorySimplified,
                  endOfCurrentWeek,
                  distributionLocation,
                  endOfCurrentWeekYYYYMMDD,
                );
              }
            });
          },
        );
      }
    }
  });

  // Resort the list so the dates that are newest are at the top.
  const depositDates = [];

  // Cycles through payment history which is a list an can't be sorted and grabs
  // all the dates and puts them in a sorted array.
  Object.keys(paymentHistorySimplified).forEach((depositDate) => {
    depositDates.push(depositDate);
  });
  depositDates.sort().reverse();

  // When the payment history is loaded display the list of transaction dates.
  if (loadingPaymentHistory) {
    return (
      <div>
        {depositDates.map((moneyDepositDate) =>
          /* Sorts distibution locations alphabetically first.  Should have distribution location
            that is ordering be first which works for JFS, KNH and LMNH but isn't fool proof. */
          Object.keys(paymentHistorySimplified[moneyDepositDate])
            .sort()
            .map((distributionLocation) => (
              <OrderPaymentByDateListItem
                key={distributionLocation}
                paymentOrderDetails={paymentHistorySimplified[moneyDepositDate]}
                moneyDepositDate={moneyDepositDate}
                orderPaymentHistory={orderPaymentHistory}
                distributionLocation={distributionLocation}
              />
            )),
        )}
      </div>
    );
  } else {
    return <LoadingContent />;
  }
}
