// This is used to display all the user's that have applied, been approved or denied
// for a person's community location.  It is displayed on the My Account -> Community
//  -> My Community Location for an individual user.
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import firebase from "../../../../components/Firebase.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ApproveCommunityMemberDialog from "../../Dialogs/ApproveCommunityMemberDialog.jsx";
import RemoveCommunityMemberDialog from "../../Dialogs/RemoveCommunityMemberDialog.jsx";
import DeleteCommunityMemberDialog from "../../Dialogs/DeleteCommunityMemberDialog.jsx";

// This renders the accordian
export default function CommunityMembersAccordian({ userInfo }) {
  const database = firebase.firestore();

  // A hook with the list of users that have applied for this location.
  const [communityMembers, setCommunityMembers] = useState({});

  useEffect(() => {
    // References the document in the database where all users for a community host
    // are stored.
    const docRef = database
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("CommunityHosts")
      .doc("CommunityMembers");

    // Reads the document and if it exists set the community members.
    docRef.get().then((doc) => {
      if (doc.exists) {
        setCommunityMembers({ ...doc.data() });
      }
    });
  }, [database]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Community Members</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <span>
          {Object.keys(communityMembers).length === 0 && (
            <>No Community Members Participating Yet.</>
          )}
          {Object.keys(communityMembers).map((communityMember) => (
            <span key={communityMember} className="Community-Members-Boxes">
              <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs>
                  <p>
                    {communityMember.split("-")[0]}{" "}
                    {communityMember.split("-")[1]}
                  </p>
                </Grid>
                {communityMembers[communityMember] ? (
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {communityMember.split("-")[2] !==
                    firebase.auth().currentUser.uid ? (
                      <RemoveCommunityMemberDialog
                        communityMember={communityMember}
                        userInfo={userInfo}
                        communityMembers={communityMembers}
                        setCommunityMembers={setCommunityMembers}
                      />
                    ) : (
                      <p>(You)</p>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={9}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span className="Approve-Delete-Buttons">
                      <ApproveCommunityMemberDialog
                        communityMembers={communityMembers}
                        setCommunityMembers={setCommunityMembers}
                        communityMember={communityMember}
                        userInfo={userInfo}
                      />
                      <DeleteCommunityMemberDialog
                        communityMember={communityMember}
                        userInfo={userInfo}
                        communityMembers={communityMembers}
                        setCommunityMembers={setCommunityMembers}
                      />
                    </span>
                  </Grid>
                )}
              </Grid>
            </span>
          ))}
        </span>
      </AccordionDetails>
    </Accordion>
  );
}
