// Formats a Date object into the format MM-DD-YYYY
export default function FormatDate(
  date,
  setCommunityListChanged,
  setSelectedDate,
) {
  if (date === null) {
    return null;
  }
  const formattedDate = new Date(date);

  const y = formattedDate.getFullYear();
  const m = formattedDate.getMonth() + 1;
  const d = formattedDate.getDate();

  setCommunityListChanged(false);
  setSelectedDate(
    "" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d + "-" + y,
  );
}
