// This is used to display the row on the basket page of a foodItem that was added
// by the community orders but from the pantry items.
import React from "react";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../../components/Firebase.js";
import IncrCounter from "../../../../components/userInputs/IncrementalCounter.js";
import { Button } from "@material-ui/core";

// This is to display all the community ordered items for the distribution location
// that came from the pantry. It will render the accumulated individual orders of
// the community that came from the pantry and display those values in light green.
export default function PantryBasketFoodRow(props) {
  // Removes the PantryBasketFoodRow Item.
  function handleRemove() {
    const itemToBeRemoved = (
      props.row.item +
      props.row.description +
      props.row.farmName
    ).replace(/\s+/g, "");

    props.communityFoodRemove(itemToBeRemoved, true);

    console.log("Removed from Basket", itemToBeRemoved);
  }

  const row = props.row;
  let price = 0;

  // This is saved as the individual's price
  price = parseFloat(
    row.price / (row.distributionQuantity / row.individualQuantity),
  ).toFixed(2);
  // This is saved as the individual's quantity
  const description = row.individualDescription;

  const remainingItems = parseInt(row.limit - row.quantity);

  return (
    <div id={row.item + "-" + props.idx} className="Food-Table">
      <div className="Basket-Pantry-Cell">{row.item}</div>
      <div className="Basket-Pantry-Cell">{description}</div>
      <div className="Basket-Pantry-Cell">{row.farmName}</div>
      <div className="Basket-Pantry-Cell">${price}</div>
      <div className="Basket-Pantry-Cell">
        <IncrCounter quantity={row.quantity} hideButtons={true} />
      </div>
      <div className="Basket-Pantry-Cell">
        <Button onClick={handleRemove} color="secondary">
          Remove
        </Button>
        last updated remaining {remainingItems}
      </div>
    </div>
  );
}
