// The Individual User's Basket header information. It displays the location to pickup
// and important details for the user.
import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import NextPickupDay from "../../../../functions/NextPickupDay.js";
import DeliveryChargePopover from "../../../MarketplacePage/Components/DeliveryChargePopover.jsx";

export default function IndBasketHeaderInformation({
  userInfo,
  currentStatus,
}) {
  // This is the communityLocation details if one exists.  This is when the user
  // selects a community member's location to pickup instead of a distribution location.
  let communityLocation = "";
  // This is the distribution location's details.  This is used when a user selects
  // a community location and we need to know the details of the distribution location
  // this community location is partnered with.
  let distributionLocation = "";
  // The order cutoff date that is calculate from the distribution location next orderCutoff.
  let orderCutoffDate = "no date";
  // Provides the date of the pickup day for the user.
  let pickupDate = "no date";
  // The address of the pickup location.
  let address = "";
  // The order cutoff of the distribution location for ordering.
  let orderCutoff = "";
  // The pickup day for this location.
  let pickupDay = "";
  // The time to pickup the order from this location.
  let pickupTime = "";
  // The delivery fee charged to each user after being divided amongst them.
  let deliveryFee = 0;
  // The base delivery fee that is shared by all the community members.
  let baseDeliveryFee = 0;
  // If the user has selected a pickupLocation
  if (userInfo.pickupLocation !== undefined) {
    // If the marketplace is for pickup at a community host.
    if (userInfo.pickupLocation.pickupLocation) {
      // Set the CommunityHost to the pickup location.
      communityLocation = userInfo.pickupLocation;
      // The base delivery fee that is set by the distribution location.
      baseDeliveryFee = communityLocation.pickupLocation.baseDeliveryFee;
      // If the communityHub has a special delivery fee set it.
      if (
        communityLocation.communityDeliveryFee ||
        communityLocation.communityDeliveryFee === 0
      ) {
        baseDeliveryFee = communityLocation.communityDeliveryFee;
      }
      // Set the distributionLocation to the CommunityHost's distribution location.
      distributionLocation = userInfo.pickupLocation.pickupLocation;
      // Provides the date of the order cutoff for the user.
      orderCutoffDate = moment(
        NextPickupDay(distributionLocation.orderCutoff),
        "MM-DD-YYYY",
      ).format("MMMM D Y");
      // Provides the date of the pickup day for the user.
      pickupDate = moment(
        NextPickupDay(distributionLocation.pickupDay),
        "MM-DD-YYYY",
      ).format("MMMM D Y");
      // Set the location's address
      address = userInfo.pickupLocation.address;
      // Set the location's orderCutoff
      orderCutoff = distributionLocation.orderCutoff;
      // Set the location's pickupDay
      pickupDay = communityLocation.pickupDay;
      // Set the location's pickup times
      pickupTime = communityLocation.availableTimes;
      // Check to see if there any people ordering from this location.  If there
      // is no one yet then we don't want to divide by 0 so we just say it is the maximum
      // delivery fee amount.
      if (currentStatus[1] > 0 && !!userInfo.pickupLocation.pickupLocation) {
        let numberOfSplitting = currentStatus[1];
        // If the current user has not already ordered and they are placing a new
        // order then we want to add them to the total count.
        if (!currentStatus[2]) {
          numberOfSplitting += 1;
        }
        // Set the delivery fee to equal the base fee divided amongst all the users.
        deliveryFee = baseDeliveryFee / numberOfSplitting;
      }
      // If there are no other users then the delivery fee is the base fee.
      else {
        deliveryFee = baseDeliveryFee;
      }
    } else {
      // Provides the date of the order cutoff for the user.
      orderCutoffDate = moment(
        NextPickupDay(userInfo.pickupLocation.orderCutoff),
        "MM-DD-YYYY",
      ).format("MMMM D Y");
      // Provides the date of the pickup day for the user.
      pickupDate = moment(
        NextPickupDay(userInfo.pickupLocation.pickupDay),
        "MM-DD-YYYY",
      ).format("MMMM D Y");
      // Set the location's address
      address = userInfo.pickupLocation.address;
      // Set the location's orderCutoff
      orderCutoff = userInfo.pickupLocation.orderCutoff;
      // Set the location's pickupDay
      pickupDay = userInfo.pickupLocation.pickupDay;
      // Set the location's pickup times
      pickupTime = userInfo.pickupLocation.pickupTime;
    }

    return (
      <Grid
        container
        className="Marketplace-Grid-Header"
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          alignItems: "baseline",
        }}
      >
        <Grid item xs>
          <h1 className="Title-Marketplace">Basket Review</h1>
        </Grid>
        <Grid item xs>
          <div className="Location-Title-And-Details">
            <span
              style={{
                display: "flex",
                alignItems: "baseline",
                minWidth: "400px",
              }}
            >
              {userInfo.pickupLocation.pickupLocation !== undefined ? (
                <div className="Community-Title-And-Distribution-Location">
                  <h1 className="Pickup-Location-Title">
                    {communityLocation.locationName}
                  </h1>
                  <p style={{ margin: "-10px 0px 0.5em 0px" }}>
                    Delivered through : {distributionLocation.name}
                  </p>
                </div>
              ) : (
                <h1 className="Pickup-Location-Title">
                  {userInfo.pickupLocation.name}
                </h1>
              )}

              <Link
                to={{
                  pathname: "/DistributionLocationSelection",
                  query: "/Marketplace",
                }}
              >
                <Button color="primary">CHANGE</Button>
              </Link>
            </span>

            <span className="Distribution-Location-Details">
              <p style={{ margin: "0px" }}>
                <b>Address :</b> {address}
              </p>
              <p style={{ margin: "0px" }}>
                <b>Order Cutoff :</b> {orderCutoff}, {orderCutoffDate}
              </p>
              <p style={{ margin: "0px" }}>
                <b>Pickup Time :</b> {pickupDay} {pickupTime}, {pickupDate}
              </p>
              {userInfo.pickupLocation.pickupLocation !== undefined && (
                <>
                  {deliveryFee > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        right: "31px",
                      }}
                    >
                      <DeliveryChargePopover
                        deliveryFee={deliveryFee.toFixed(2)}
                        baseDeliveryFee={baseDeliveryFee.toFixed(2)}
                      />
                      <p style={{ margin: "5px 0px" }}>
                        <b>Current Delivery Charge:</b> $
                        {deliveryFee.toFixed(2)}
                      </p>
                    </div>
                  )}
                  <p style={{ margin: "5px 0px" }}>
                    <b>Current Total Customers vs. Max Allowed:</b>{" "}
                    {currentStatus[1]}/{communityLocation.maxParticipants}
                  </p>
                </>
              )}
            </span>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}
