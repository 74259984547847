// This displays the individual's marketplace.

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button, Grid } from "@material-ui/core";
import Menu from "../../../components/Menu.js";
import CustomizedSnackbars from "../../../components/snackBars/Snackbar.js";
import FoodElement from "../Components/FoodElement.jsx";
import FilterColumn from "../Components/FilterColumn.jsx";
import IndMarketplaceHeaderInformation from "../Components/IndMarketplaceHeaderInformation.jsx";
import HandleAddAllToBasket from "./../Functions/HandleAddAllToBasket.js";
import CalculateCommunityLocationStatus from "../../../functions/CalculateCommunityLocationStatus.js";
import MarketplaceMessageBanner from "../Components/MarketplaceMessageBanner.jsx";
import Footer from "../../../components/Footer.jsx";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SortFoodItemsSelect from "./../Components/SortFoodItemsSelect.jsx";
import HandleFoodItemsSort from "../Functions/HandleFoodItemsSort.js";
import SelectDistributionLink from "../Components/SelectDistributionLink.jsx";

export default function IndividualMarketplace({
  userInfo,
  handleUserInfoChange,
  farmDetails,
  foodItems,
  displayedFoodItems,
  setDisplayedFoodItems,
  currentUser,
  marketplaceCategories,
  totalBasketItems,
  pickupLocationDetails,
  snackBarOpen,
  setSnackBarOpen,
  snackBarMessage,
  setSnackBarMessage,
  handleSnackBarClose,
  selectedCategory,
  setSelectedCategory,
  checkedFarms,
  setCheckedFarms,
}) {
  let emptyMarketplaceMessage =
    "Items will be added soon!  Please come back later.";
  if (pickupLocationDetails.emptyMarketplaceMessage !== undefined) {
    emptyMarketplaceMessage = pickupLocationDetails.emptyMarketplaceMessage;
  }
  if (displayedFoodItems.length === 0 && foodItems.length !== 0) {
    emptyMarketplaceMessage =
      "There are no items to display for the filters you selected.  Please broaden your search.";
  }

  // Set the default selection for sorting to farm name ascending.
  const [sortBy, setSortBy] = useState("farmNameAsc");
  // When the page loads we want to make sure that the foodItems are sorted correctly.
  useEffect(() => {
    // Sort the foodItems.
    HandleFoodItemsSort(
      sortBy,
      displayedFoodItems,
      setDisplayedFoodItems,
      setSortBy,
    );
  }, [selectedCategory, checkedFarms]);

  const [currentStatus, setCurrentStatus] = useState([
    "loading",
    "loading",
    false,
  ]);
  const communityLocation = userInfo.pickupLocation;

  useEffect(() => {
    if (communityLocation !== undefined) {
      if (communityLocation.pickupLocation) {
        CalculateCommunityLocationStatus(
          userInfo.pickupLocation,
          setCurrentStatus,
          userInfo,
        );
      }
    } else {
      setCurrentStatus(["failed to load", "failed to load", false]);
    }
  }, []);

  // Counts if there are any items that the user has pressed the plus button but not
  // yet added to the basket.
  let addAllVisible = 0;
  // Counts up the displayed items quantities to see if any items need to be added to
  // the basket.
  displayedFoodItems.forEach((displayedFoodItem) => {
    addAllVisible += displayedFoodItem.quantity;
  });

  return (
    <div
      style={{ background: "rgb(244, 249, 224)", minWidth: "950px" }}
      id="home"
    >
      <Menu />
      <div
        style={{
          margin: "-1px",
          padding: "1px",
          background: "rgb(159 191 89)",
        }}
      >
        <MarketplaceMessageBanner
          pickupLocationDetails={pickupLocationDetails}
          userInfo={userInfo}
        />
      </div>
      <div className="Marketplace-Page-Header">
        <IndMarketplaceHeaderInformation
          userInfo={userInfo}
          currentStatus={currentStatus}
          farmDetails={farmDetails}
        />
      </div>
      <div className="Page-Market" id="pageMarket">
        {userInfo.pickupLocation === undefined ? (
          <SelectDistributionLink />
        ) : (
          <>
            {currentStatus[0] !== "loading" &&
            communityLocation.maxParticipants < currentStatus[1] &&
            !currentStatus[2] ? (
              <Alert severity="error">
                <AlertTitle>Location Full</AlertTitle>
                This location has reached its capacity of partipants{" "}
                {currentStatus[1]}/{communityLocation.maxParticipants}.
                <strong> Please select another pickup location!</strong>
              </Alert>
            ) : (
              <Grid container spacing={2}>
                {foodItems.length !== 0 && (
                  <Grid item xs={3}>
                    <div className="Checkout-Add-Items-Buttons">
                      {totalBasketItems > 0 && (
                        <Link
                          style={{
                            textDecoration: "none",
                            marginBottom: "10px",
                          }}
                          to="/Basket"
                        >
                          <Button
                            endIcon={<ShoppingCartCheckoutIcon size="large" />}
                            variant="contained"
                            style={{
                              background: "rgb(21, 101, 192)",
                              color: "white",
                              width: "100%",
                            }}
                          >
                            HEAD TO CHECKOUT
                          </Button>
                        </Link>
                      )}
                      {addAllVisible > 0 && (
                        <Button
                          onClick={() =>
                            HandleAddAllToBasket(
                              displayedFoodItems,
                              setDisplayedFoodItems,
                              setSnackBarOpen,
                              setSnackBarMessage,
                              userInfo,
                              handleUserInfoChange,
                            )
                          }
                          endIcon={<AddShoppingCartIcon />}
                          variant="contained"
                          style={{
                            backgroundColor: "#9FBF59",
                            color: "white",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          ADD ALL ITEMS TO CART
                        </Button>
                      )}

                      <FilterColumn
                        marketplaceCategories={marketplaceCategories}
                        foodItems={foodItems}
                        setDisplayedFoodItems={setDisplayedFoodItems}
                        checkedFarms={checkedFarms}
                        setCheckedFarms={setCheckedFarms}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs>
                  {displayedFoodItems.length !== 0 ? (
                    <span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          margin: "15px",
                        }}
                      >
                        <SortFoodItemsSelect
                          foodItems={displayedFoodItems}
                          setFoodItems={setDisplayedFoodItems}
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                          userInfo={userInfo}
                        />
                      </div>
                      <div className="Food-List">
                        {displayedFoodItems.map((row, index) => (
                          <FoodElement
                            key={index}
                            index={index}
                            row={row}
                            currentUser={currentUser}
                            userInfo={userInfo}
                            handleUserInfoChange={handleUserInfoChange}
                            displayedFoodItems={displayedFoodItems}
                            setDisplayedFoodItems={setDisplayedFoodItems}
                            setSnackBarOpen={setSnackBarOpen}
                            setSnackBarMessage={setSnackBarMessage}
                          />
                        ))}
                      </div>
                      <div>
                        Icons made by{" "}
                        <a
                          href="https://www.flaticon.com/authors/photo3idea-studio"
                          title="photo3idea_studio"
                        >
                          photo3idea_studio
                        </a>{" "}
                        from{" "}
                        <a href="https://www.flaticon.com/" title="Flaticon">
                          www.flaticon.com
                        </a>
                      </div>
                    </span>
                  ) : (
                    <span>
                      <span>
                        <h3 className="EmptyMarketplace">
                          {emptyMarketplaceMessage}
                        </h3>
                      </span>
                    </span>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
        <CustomizedSnackbars
          message={snackBarMessage}
          open={snackBarOpen}
          handleClose={handleSnackBarClose}
        />
      </div>
      <Footer />
    </div>
  );
}
