import AggregateCustomerList from "../../../../functions/AggregateOrder.js";

// This function takes in the  location, locationDetails and the date of the
// user inputs and then imports the order requested.  It sets the hook communityAndImportedOrder,
// importedOrder and calls the AggregateCustomerList to combine the imported
// and the current distribution location if there are any.
export default function ImportOrder(
  location,
  locationDetails,
  date,
  database,
  importedOrder,
  setImportedOrder,
  communityAndImportedOrder,
  setCommunityAndImportedOrder,
  setCommunityFoodItems,
  setPantryFoodItems,
  setCommunityFarmerInfo,
  setCommunityDonations,
  setCommunitySubsidies,
) {
  const importedOrderDocRef = database
    .collection("DistributionLocations")
    .doc(location)
    .collection("CommunityOrders")
    .doc(date);
  importedOrderDocRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // Creates a combined community and imported dictionary
        let communityAndImportedOrderTemp = {};
        // Sets the imported order values
        const importedOrderTemp = { ...importedOrder };
        importedOrderTemp[location] = {
          location,
          locationDetails,
          date,
          communityOrders: doc.data(),
        };
        setImportedOrder({ ...importedOrderTemp });

        // Checks to see if the current distribution location has any community
        // orders if so then combine them with the imported ones otherwise just
        // leave it.
        if (Object.keys(communityAndImportedOrder).length !== 0) {
          communityAndImportedOrderTemp = Object.assign(
            {},
            doc.data(),
            communityAndImportedOrder,
          );
        } else {
          communityAndImportedOrderTemp = doc.data();
        }

        // Set the combined orders
        setCommunityAndImportedOrder(communityAndImportedOrderTemp);
        // Aggregate the orders and set all the values
        AggregateCustomerList(
          communityAndImportedOrderTemp,
          setCommunityFoodItems,
          setCommunityFarmerInfo,
          setCommunityDonations,
          setCommunitySubsidies,
          false,
          setPantryFoodItems,
        );
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
