// This function will calcualate the amounts owed by a user who's order has been
// updated.  It will take in the original cost of the user's orders and their new order
// and then calculate the difference and write to the database.  This is used for
// when a user's foodItem's price is directly changed, if an order is deleted or
// the subsidy/donation is changed.
import firebase from "../../../../components/Firebase.js";
import YYYYMMDDConverter from "../../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";

export default function IndividualPriceChangesPaymentHistory(
  orderDate,
  originalOrderCost,
  updatedOrderCost,
  itemName,
  originalDetails,
  newDetails,
  imported,
  location,
  user,
  commentAdjustment,
  updatePaymentHistory,
) {
  // Find the difference between a user's old and new order.
  const orderCostChange = updatedOrderCost - originalOrderCost;

  // User includes a date at the end of it we want to only use the
  // firstName, lastName and userId and save that in a userPointer.
  const userPointer = [
    user.split("-")[0],
    user.split("-")[1],
    user.split("-")[2],
  ].join("-");

  // Make the orderNotes string an empty string.
  let orderNotes = "";

  // If the price of an item was changes then we set the order note to include this information.
  // Otherwise it is a removal of an item and we want to include that message.
  if (itemName) {
    // Set the order note of the item's price being updated.
    orderNotes = `Price adjustment on ${itemName} from $${originalDetails.toFixed(
      2,
    )} to $${newDetails.toFixed(2)}. `;
  } else if (commentAdjustment === "donationSubsidyChange") {
    // Set the order note of the donation/subsidy change.
    orderNotes = `The user's subsidy/donation changed from $${originalOrderCost.toFixed(
      2,
    )} to $${updatedOrderCost.toFixed(2)}. `;
  } else if (commentAdjustment === "volunteerFeeChange") {
    // Set the order note of the volunteer fee change.
    orderNotes = `The user's volunteer fee changed from $${originalOrderCost.toFixed(
      2,
    )} to $${updatedOrderCost.toFixed(
      2,
    )} and credits ${originalDetails} to ${newDetails}. `;
  } else if (commentAdjustment === "deliveryFeeChange") {
    // Set the order note of the volunteer fee change.
    orderNotes = `The user's delivery fee changed from $${originalOrderCost.toFixed(
      2,
    )} to $${updatedOrderCost.toFixed(2)}. `;
  } else {
    // Set the order note to note that the order was removed.
    orderNotes = `Order placed on ${orderDate} was removed and cost ${originalOrderCost.toFixed(
      2,
    )}. `;
  }

  // Stores the specific dates payment history. Includes the importedOrder, the communityOrders
  // and the selectedDate.  Will be updated and used to update the database.
  let orderDatePaymentHistory = {};
  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // set up the datebase.
  const database = firebase.firestore();
  // eslint-disable-next-line no-unused-vars
  const batch = database.batch();

  // Find the document where this order date is saved.
  const paymentHistoryCollectRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);

  // Pull the document for reading.
  paymentHistoryCollectRef
    .get()
    .then((collection) => {
      // Cycle through the documents but there should only be 1 that matches.
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since we'll be adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database
            .runTransaction((transaction) => {
              // Store the document's reference.
              const paymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(documentName);

              // This code may get re-run multiple times if there are conflicts.
              return transaction
                .get(paymentHistoryDocRef)
                .then((paymentHistoryDoc) => {
                  if (!paymentHistoryDoc.exists) {
                    // eslint-disable-next-line no-throw-literal
                    throw "Document does not exist!";
                  }

                  // Find the year month day of current time to update the order history
                  // with a timestamp of the changes.
                  const now = YYYYMMDDConverter();

                  // Load the full document of this order Date.
                  const documentData = paymentHistoryDoc.data();

                  // Store the current orderDate.
                  orderDatePaymentHistory = paymentHistoryDoc.data()[orderDate];

                  // Store the communityOrders of this date.
                  const communityOrdersPaymentHistory =
                    orderDatePaymentHistory.communityOrders;
                  // Store the importedOrder of this date.
                  const importedCommunityOrdersPaymentHistory =
                    orderDatePaymentHistory.importedOrder;

                  if (!imported) {
                    // Add the new orders amount to their old amount.
                    communityOrdersPaymentHistory[userPointer].totalOwed +=
                      orderCostChange;
                    // Add this new order's total and current date to the orderHistory.
                    communityOrdersPaymentHistory[userPointer].orderHistory[
                      now
                    ] = communityOrdersPaymentHistory[userPointer].totalOwed;
                    // Update the notes to inlcude the additional order added.
                    communityOrdersPaymentHistory[userPointer].orderNotes =
                      communityOrdersPaymentHistory[
                        userPointer
                      ].orderNotes.concat(orderNotes);
                  } else {
                    // Add the new orders amount to their old amount.
                    importedCommunityOrdersPaymentHistory[
                      location
                    ].communityOrders[userPointer].totalOwed += orderCostChange;
                    // Add this new order's total and date to the orderHistory.
                    importedCommunityOrdersPaymentHistory[
                      location
                    ].communityOrders[userPointer].orderHistory[now] =
                      importedCommunityOrdersPaymentHistory[
                        location
                      ].communityOrders[userPointer].totalOwed;
                    // Update the notes to inlcude the additional order added.
                    importedCommunityOrdersPaymentHistory[
                      location
                    ].communityOrders[userPointer].orderNotes =
                      importedCommunityOrdersPaymentHistory[
                        location
                      ].communityOrders[userPointer].orderNotes.concat(
                        orderNotes,
                      );
                  }

                  // Update the Payment History community orders.
                  orderDatePaymentHistory.communityOrders =
                    communityOrdersPaymentHistory;
                  // Update the Payment History imported orders.
                  orderDatePaymentHistory.importedOrder =
                    importedCommunityOrdersPaymentHistory;

                  // Update the full documentName to include the changes for this date.
                  documentData[orderDate] = orderDatePaymentHistory;

                  // Complete the transaction.  If the document has been changed then
                  // this document has to go again.
                  transaction.update(paymentHistoryDocRef, documentData);
                  // Update the paymentHistory dictionary
                  updatePaymentHistory(documentData, orderDate);
                });
            })
            .then(() => {})
            .catch((err) => {
              // Send an alert to the user to tell them of the error.
              // eslint-disable-next-line no-undef
              alert(err);
              // Return false that the transaction failed.
              return false;
            });
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
