// This is a dialog that appears when the user edits their marketplace message.
// This will allow the user to type in a new message and save it to send to the
// database.
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useContext } from "react";
import firebase from "../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "../../../styles/MyAccount.css";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import UpdateCommunityLocation from "../../../functions/UpdateCommunityLocation.js";

// This is the dialog box that opens when a user clicks the edit button
function ChangeMarketplaceMessageBox(props) {
  const { onClose, open } = props;
  // This is the message that the user has created to include the message banner
  const [messageUpdate, setMessageUpdate] = useState(props.message);

  const handleClose = () => {
    onClose(false);
  };

  // This is when the user saves to the database.
  const handleSave = (messageUpdate) => {
    // This alert appears when the user has changed the message to be blank but
    // still has the display message as on which would render a blank message.
    if (
      (messageUpdate === undefined || messageUpdate === "") &&
      props.userInfo.marketplaceMessageEnabled
    ) {
      // eslint-disable-next-line no-undef
      alert(
        'You can\'t display an empty message.  First you must turn off "Display Marketplace Message".',
      );
    } else {
      const database = firebase.firestore();
      const batch = database.batch();
      // Creates a temporary userInfo to update the userInfo.
      const userInfoTemp = props.userInfo;
      // The locatin in the document that is being updated. DistributionLocation.marketplaceMessage
      const locationToUpdate = [
        props.userInfo.organisationName,
        "marketplaceMessage",
      ].join(".");

      // References the database where the Distribution Location exist.
      const docRef = database
        .collection("DistributionLocations")
        .doc("Vancouver");

      // Update the database.
      batch.update(docRef, { [locationToUpdate]: messageUpdate });

      // Update the distribution location's information in the Users collection
      const userDocRef = database
        .collection("Users")
        .doc(props.userInfo.userId);

      // Update the database.
      batch.update(userDocRef, { marketplaceMessage: messageUpdate });

      batch.commit();

      // Update the userInfo message.
      userInfoTemp.marketplaceMessage = messageUpdate;

      // Updates the Distribution location information that is saved into the database.
      const distributionLocationInformationTemp = {
        ...props.distributionLocationInformation,
      };
      distributionLocationInformationTemp.marketplaceMessage = messageUpdate;
      props.setDistributionLocationInformation({
        ...distributionLocationInformationTemp,
      });

      // Updates any community locations that may exist that have this distribution location as their partner.
      UpdateCommunityLocation(
        props.userInfo.userId,
        {},
        false,
        false,
        userInfoTemp,
        true,
      );

      props.handleUserInfoChange({ ...userInfoTemp }, true);
      onClose(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Change the Message that will appear on the Markeplace Page
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          multiline
          fullWidth
          label="Marketplace Message"
          name="marketplaceMessage"
          onChange={(e) => setMessageUpdate(e.target.value)}
          value={messageUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button color="primary" onClick={() => handleSave(messageUpdate)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChangeMarketplaceMessageBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ChangeMarketplaceMessageDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <ChangeMarketplaceMessageBox
        open={open}
        onClose={handleClose}
        message={props.message}
        userInfo={props.userInfo}
        handleUserInfoChange={props.handleUserInfoChange}
        distributionLocationInformation={props.distributionLocationInformation}
        setDistributionLocationInformation={
          props.setDistributionLocationInformation
        }
      />
    </div>
  );
}
