import React from "react";
import firebase from "./../../components/Firebase.js";
import IncrCounter from "./../../components/userInputs/IncrementalCounter.js";
import HandleRefresh from "./Functions/HandleRefresh.js";
import HandleQuantityChange from "./Functions/HandleQuantityChange.js";
import HandleBasketItems from "./Functions/HandleBasketItems.js";

// Restaurant User. Row of each food item.  Each Food Item is its own row.
export default class RestaurantFoodRow extends React.Component {
  constructor(props) {
    super(props);

    this.database = firebase.firestore();
  }

  handleAddToBasket = () => {
    if (this.props.row.quantity !== 0) {
      // Here we need to update the user's basketItems so we call this function
      // which takes the index of the row and returns the new basketItems and the
      // documentName of the current User's document.  We then update the database
      HandleBasketItems(
        this.props.userInfo,
        this.props.displayedFoodItems,
        this.props.index,
        false,
        this.props.row.quantity,
        this.props.handleUserInfoChange,
      );

      // We have to update the quanities hook so that the quantities are reset to 0
      // we do a callback to update these.
      HandleQuantityChange(
        this.props.displayedFoodItems,
        this.props.setDisplayedFoodItems,
        true,
        (
          this.props.row.item +
          this.props.row.description +
          this.props.row.farmName
        ).replace(/\s+/g, ""),
        true,
      );
      console.log("Added to Basket");
    }
  };

  handleDelete = () => {
    const documentName = (
      this.props.row.item +
      this.props.row.description +
      this.props.row.farmName +
      this.props.currentUser.uid
    ).replace(/\s+/g, "");

    this.database
      .collection("FoodItems")
      .doc(documentName)
      .delete()
      .then(function () {})
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });

    // Here we need to update the user's basketItems so we call this function
    // which takes the index of the row and a boolean to tell it we are deleting
    // something. It returns the new basketItems and the documentName of the
    // current User's document.  We then update the database.
    HandleBasketItems(
      this.props.userInfo,
      this.props.displayedFoodItems,
      this.props.index,
      true,
      this.props.row.quantity,
      this.props.handleUserInfoChange,
    );
    HandleRefresh(
      this.props.displayedFoodItems,
      this.props.setDisplayedFoodItems,
      (
        this.props.row.item +
        this.props.row.description +
        this.props.row.farmName
      ).replace(/\s+/g, ""),
      false,
    );
  };

  incrementItem = () => {
    HandleQuantityChange(
      this.props.displayedFoodItems,
      this.props.setDisplayedFoodItems,
      true,
      (
        this.props.row.item +
        this.props.row.description +
        this.props.row.farmName
      ).replace(/\s+/g, ""),
    );
  };

  decreaseItem = () => {
    // eslint-disable-next-line no-empty
    if (this.props.row.quantity === 0) {
    } else {
      HandleQuantityChange(
        this.props.displayedFoodItems,
        this.props.setDisplayedFoodItems,
        false,
        (
          this.props.row.item +
          this.props.row.description +
          this.props.row.farmName
        ).replace(/\s+/g, ""),
      );
    }
  };

  render() {
    const updateButton = "ADD TO BASKET";
    return (
      <div className="Food-Table">
        <div className="Restaurant-Food-Cell">{this.props.row.item}</div>
        <div className="Restaurant-Food-Cell">{this.props.row.description}</div>
        <div className="Restaurant-Food-Cell">{this.props.row.farmName}</div>
        <div className="Restaurant-Food-Cell">
          <IncrCounter
            quantity={this.props.row.quantity}
            incrementItem={this.incrementItem}
            decreaseItem={this.decreaseItem}
          />
          <button className="Add-To-Basket" onClick={this.handleAddToBasket}>
            {updateButton}
          </button>
          <button className="Delete-Button" onClick={this.handleDelete}>
            x
          </button>
        </div>
      </div>
    );
  }
}
