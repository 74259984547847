// Provides additional information to the user that explains what the delivery the
// current delivery charge is and how it will change depending on how many people order.
import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";

export default function DeliveryChargePopover({
  deliveryFee,
  baseDeliveryFee,
}) {
  // Set the anchor of where to place the popover.
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <HelpIcon
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ color: "red", marginRight: "5px", fontSize: "25px" }}
      />
      <Popper open={open} anchorEl={anchorEl} placement="left-start">
        <Box
          sx={{
            width: "400px",
            boxShadow:
              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            bgcolor: "background.paper",
          }}
        >
          <Typography sx={{ p: 1 }}>
            The delivery charge is how much each customer is currently paying to
            cover the ${deliveryFee} delivery charge. The delivery charge is
            calculated by dividing ${baseDeliveryFee} by the current total
            customers. You can see how many people are currently ordering in the
            next line below where it says &quot;Current Total Customers vs. Max
            Allowed.&quot;
          </Typography>
        </Box>
      </Popper>
    </div>
  );
}
