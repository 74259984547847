// This button shows a list of the customers that ordered a specific item per location.
// When clicked it list out all the customers and how much of the item they ordered.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";

export default function CustomersByLocationsOrderedItem({
  customers,
  individualDescription,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="Distribution-Locations-Per-Item">
      <Button
        size="small"
        style={{ background: "#ffd9e6", color: "black" }}
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        CUSTOMERS
      </Button>

      {open && (
        <div>
          {Object.keys(customers)
            .sort()
            .map((customer, index) => {
              // Split up the customer order into an array [firstName, lastName, userId]
              const user = customer.split("-");

              return (
                <div key={index} style={{ color: "#541919de" }}>
                  {index + 1}. {user[0]} {user[1]} {individualDescription}{" "}
                  <u style={{ color: "red" }}>x{customers[customer]}</u>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
