// When a user is on the Marketplace Page this function is called to load the items
// saved in the database that match the incoming farm.  These foodItems are then added
// to the foodList and the quantities are all set to 0
import firebase from "../components/Firebase.js";

export default function LoadMarketplaceItems(
  farm,
  loadLimit,
  lastFarm,
  lastLoadedItem,
  setFoodItems,
  setDisplayedFoodItems,
  setLastLoadedItem,
  setLoading,
  userInfo,
  pickupLocationDetails,
) {
  const lastLoadedItemTemp = { ...lastLoadedItem };
  const database = firebase.firestore();
  // The distribution location's id is used to load the marketplace items that are for this
  // location if the user has logged in and is just looking at the marketplace.
  let distributionLocationUserId = pickupLocationDetails.userId;

  // This is the id of the community's hub owner if there is one.
  let communityLocationUserId = "";

  // If the user is an individual then set the distributionLocationUserId to their selected
  // location.
  if (userInfo.userType === "individual") {
    // Check if the user is using a community location.
    if (userInfo.pickupLocation.pickupLocation) {
      // Set the distribution location ids
      distributionLocationUserId =
        userInfo.pickupLocation.pickupLocation.userId;
      communityLocationUserId = userInfo.pickupLocation.userId;
    }
    // if the user is picking up from the distribution location.
    else {
      distributionLocationUserId = userInfo.pickupLocation.userId;
    }
  }

  // The document to load with all the FoodItems
  let docRef = "";

  // If the user is a distribution location then load the FoodItems that are
  // have been set by the admin to user to visible so they can see these items.
  if (userInfo.userType === "distributionLocation") {
    // If farm isn't set then load all the items.
    if (farm == null) {
      docRef = database
        .collection("FoodItems")
        .where("userType", "==", "admin")
        .where("adminVisible", "==", true)
        .orderBy("item")
        .limit(loadLimit);
    }
    // Only load the farm specified.
    else {
      docRef = database
        .collection("FoodItems")
        .where("farmName", "==", farm)
        .where("userType", "==", "admin")
        .where("adminVisible", "==", true)
        .orderBy("item")
        .limit(loadLimit);
    }
  }
  // If the user is a individual then load the items only if admin is true and
  // the distribution location they selected has this item.
  else {
    docRef = database
      .collection("FoodItems")
      .where("farmName", "==", farm)
      .where("userType", "==", "admin")
      .where("adminVisible", "==", true)
      .where("distributionLocationVisible", "array-contains-any", [
        distributionLocationUserId,
        communityLocationUserId,
      ])
      .orderBy("item")
      .limit(loadLimit);
  }

  docRef
    .get()
    .then((collection) => {
      // Save the last loaded food Item document for each farm.
      if (lastLoadedItemTemp != null) {
        lastLoadedItemTemp[farm] = collection.docs[collection.docs.length - 1];
      }

      collection.docs.forEach((doc) => {
        if (doc.exists) {
          const foodItemTemp = doc.data();
          // creates a quantity field to be used to store how many a user has added
          foodItemTemp.quantity = 0;
          // A flag for if the item is disabled.
          let itemDisabled = false;

          // Check if the footItem has the key communityLocationDisable.
          if (foodItemTemp.communityLocationDisable != null) {
            // Check if the array includes the current community hubs id
            if (
              foodItemTemp.communityLocationDisable.includes(
                communityLocationUserId,
              )
            ) {
              // If it does then we will disable this item and not load it.
              itemDisabled = true;
            }
          }
          // If the location is not disabled then add it to the food List
          if (!itemDisabled) {
            // Add the foodItem to the foodItem Hook
            setFoodItems((prevArray) => [...prevArray, foodItemTemp]);
          }
          // On page load the user won't have any filters so the foodItems will
          // be equal to the displayedFoodItems.
          if (setDisplayedFoodItems != null) {
            setDisplayedFoodItems([]);
          }
          console.log("Database read!");
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });

      // Checks that the index of the current farm in the forEach loop is the
      // last one in the farms list which means it's time to update the last
      // loaded item for this farm.
      if (lastFarm) {
        // If this function doesn't exist then skip.
        if (setLastLoadedItem != null) {
          setLastLoadedItem({ ...lastLoadedItemTemp });
        }
        setLoading(true);
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      setLoading(true);
    });
}
