// This displays a distribution location on the list of the distribution location
// selection screen.   It will also check if this location has any community hosts
// and display them below it.
import React from "react";
import { Link } from "react-router-dom";
import ColourButton from "../../../components/userInputs/ColourButton.js";
// eslint-disable-next-line no-unused-vars
import AccordionSummary from "@material-ui/core/AccordionSummary";
// eslint-disable-next-line no-unused-vars
import AccordionDetails from "@material-ui/core/AccordionDetails";
// eslint-disable-next-line no-unused-vars
import Typography from "@material-ui/core/Typography";
// eslint-disable-next-line no-unused-vars
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommunityLocation from "../Components/CommunityLocation.jsx";

// This describes each location in the list of locations
export default class DeliveryLocation extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
    this.state = { farm: true };
  }

  toggleHover() {
    this.props.toggleHover(this.props.location);
  }

  toggleNoHover() {
    this.props.toggleNoHover();
  }

  toggleOnClick(locationDetails) {
    this.props.toggleOnClick(locationDetails);
  }

  render() {
    // This is just the locations name and no other info
    const location = this.props.location;

    // This is the location's details but does not contain the name.
    const locationDetails = this.props.locationDetails;
    locationDetails.name = location;

    const activeIcon = this.props.activeIcon;
    // This is used a location is clicked on and the colour changes to indicate
    // the user is looking at that spot on the map.
    let clickedStyle;

    // Checks if the item has been clicked by the user
    if (activeIcon !== undefined && activeIcon !== null) {
      if (activeIcon.name === location) {
        clickedStyle = { background: "#CCF0E6" };
      }
    }

    return (
      <>
        <div
          className="Selection-Location-Element"
          style={clickedStyle}
          onClick={() => this.toggleOnClick(locationDetails)}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleNoHover}
        >
          <span className="Title-And-Select-Button">
            <h2 className="Pickup-Location-Name-Header"> {location} </h2>
            <Link
              style={{ textDecoration: "none" }}
              to={{ pathname: this.props.previousPage, query: locationDetails }}
            >
              <ColourButton
                hoverColour="#1c8c1c"
                colour="#9FBF59"
                title="SELECT"
                onClick={() => {
                  this.props.handleClickOpen(locationDetails);
                }}
              />
            </Link>
          </span>
          <p className="Pickup-Location-Details-Selection">
            <strong>Address: </strong>
            {locationDetails.address}
          </p>
          <p className="Pickup-Location-Details-Selection">
            <strong>Pickup Day: </strong>
            {locationDetails.pickupDay}
          </p>
          {/* NEEDS WORK
          <p><strong>Cutoff Day and Time : </strong>{locationDetails["orderCutoff"]} at {locationDetails["orderCutoffTime"]}</p>

          */}
          <p className="Pickup-Location-Details-Selection">
            <strong>Cutoff Day : </strong>
            {locationDetails.orderCutoff}
          </p>
          <p className="Pickup-Location-Details-Selection">
            <strong>Pickup Time: </strong>
            {locationDetails.pickupTime}
          </p>
        </div>
        {this.props.communityDistributionLocations[0] !== undefined && (
          <div>
            {this.props.communityDistributionLocations.map(
              (communityLocationDetails, i) => (
                <CommunityLocation
                  communityLocationDetails={communityLocationDetails}
                  locationDetails={locationDetails}
                  userInfo={this.props.userInfo}
                  key={i}
                  toggleHover={this.props.toggleHover}
                  toggleNoHover={this.props.toggleNoHover}
                  toggleOnClick={this.props.toggleOnClick}
                  activeIcon={activeIcon}
                  hover={this.props.hover}
                  title={this.props.title}
                  handleClickOpen={this.props.handleClickOpen}
                  previousPage={this.props.previousPage}
                  setSendingEmail={this.props.setSendingEmail}
                />
              ),
            )}
          </div>
        )}
      </>
    );
  }
}
