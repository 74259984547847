// This function compares the item names of the food and sorts them alphabetically.
export default function CompareItemNames(item1, item2, key) {
  const itemName1 = item1[key].toUpperCase();
  const itemName2 = item2[key].toUpperCase();

  if (itemName1 < itemName2) {
    return -1;
  }
  if (itemName1 > itemName2) {
    return 1;
  }
  return 0;
}
