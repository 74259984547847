// When the user selects the blog post tab on the community board then this function
// pulls all the blog posts from the database and displays them in chronological order
// for the user to select from.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import firebase from "../../components/Firebase.js";
import BlogPostDialog from "../../pages/BlogsPage/Components/Dialogs/BlogPostDialog.jsx";
import LoadingContent from "../../components/LoadingContent.jsx";

export default function BlogPosts() {
  // The list of blog posts
  const [blogPosts, setBlogPosts] = useState([]);
  // Whether the program is loading the blog posts still
  const [loading, setLoading] = useState(false);

  const database = firebase.firestore();

  useEffect(() => {
    // Loads the blog posts in chronological order.
    const docRef = database.collection("BlogPosts").orderBy("date", "desc");
    const dataTransfer = [];
    docRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            dataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setBlogPosts(dataTransfer);
        setLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [database]);
  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        {blogPosts.map((blogPost, i) => (
          <BlogPostDialog key={i} blogPost={blogPost} />
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <LoadingContent inline={true} />
      </div>
    );
  }
}
