// This takes in the order and outputs the stats of the order along with the list
// of items ordered and the receipts of each individual order.  This is called by
// OrderCard.jsx and is used to display information on the Community Board.
import React, { useState, useEffect } from "react";
import { formatDollars } from "./util";
import CalculateOrderStats from "./CalculateOrderStats.js";
import PriceTotals from "../../functions/PriceTotals.js";
import PackageTotals from "../../functions/PackageTotals.js";
import CommunityOrdersTotal from "../../functions/CommunityOrdersTotal.js";
import ImportedOrdersTotal from "./ImportedOrdersTotal.js";
import CommunityOrdersDialogHomePage from "../../pages/HomePagePage/Dialogs/CommunityOrdersDialogHomePage.js";
import AggregateCustomerList from "../../functions/AggregateOrder.js";
import AggregateImportedOrders from "./AggregateImportedOrders.js";
import SimpleSelect from "../../components/userInputs/DropDownMenu.js";
import Orders from "./Orders";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DistributionLocationMyAccountFarmTotalsLine from "../../components/OrderDetails/DistributionLocationMyAccountFarmTotalsLine.jsx";
import FoodAmountStatsHomePageDialog from "../../pages/HomePagePage/Dialogs/FoodAmountStatsHomePageDialog.jsx";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
}));

export default function OrderDetailsText(order, location) {
  const classes = useStyles();

  // The date of the pickup
  const { selectedDate: date } = order || {};

  // This holds the date of distribution and will either be equal to the distribution
  // location's distribution date or the imported distribution location's date.
  const [distributionDate, setDistributionDate] = useState(0);

  // An array of all the foodItems that were taken from the pantry.
  const [pantryFoodItems, setPantryFoodItems] = useState([]);

  // This is holds the subsidy amount the user has requested to see, combined, imported,
  // or the distribution location's amounts.
  const [subsidiesDisplayed, setSubsidiesDisplayed] = useState(0);

  // This is holds the donated amount the user has requested to see, combined, imported,
  // or the distribution location's amounts.
  const [donationsDisplayed, setDonationsDisplayed] = useState(0);

  // This is holds the community orders dollar amount the user has requested to see,
  // combined, imported, or the distribution location's amounts.
  const [customerOrdersDisplayed, setCustomerOrdersDisplayed] = useState(0);

  // This is what the user initially chose to as the distribution location.
  const [selectedDistributionLocation, setSelectedDistributionLocation] =
    useState("Combined Order Stats");

  // This is the amount of food that is donated to the distribution location.
  const [donatedAmount, setDonatedAmount] = useState(0);

  // This is the amount of food that was added to the pantry.
  const [pantryAddedAmount, setPantryAddedAmount] = useState(0);

  // This is the amount of packaging fees that from the displayed location(s).
  const [packagingFeesDisplayed, setPackagingFeesDisplayed] = useState(0);

  // This is the amount of delivery fees that from the displayed location(s).
  const [deliveryFeesDisplayed, setDeliveryFeesDisplayed] = useState(0);

  // This is the amount of volunteer credits that from the displayed location(s).
  const [volunteerCreditsDisplayed, setVolunteerCreditsDisplayed] = useState(0);

  // This is the amount of volunteer fees that from the displayed location(s).
  const [volunteerFeesDisplayed, setVolunteerFeesDisplayed] = useState(0);

  // These are the options that a user can choose from if they want to see a specific
  // locations stats.
  const [statsOptions, setStatsOptions] = useState([]);

  let title = `Order Distributed on ${date}`;

  let [
    subsidies,
    donations,
    communityOrdersTotal,
    donationAmount,
    grandTotal,
    pantryTotal,
    packagingFees,
    deliveryFees,
    volunteerCredits,
    volunteerFees,
  ] = [0, 0, 0, { Total: 0 }, 0, 0, {}, {}, {}, {}];

  // Is a dictionary that contains how much each farm is owed
  let farmTotals = {};

  // The value of all the food that has a suggested value.
  let suggestedFees = 0;

  // The dictionary of the total value of food donated by the farmers.
  let farmSuggestedTotals = {};

  // Is a dictionary that contains how much each farm has charged for packaging.
  let packageTotals = 0;

  // The totals of food taken from the pantry from each vendor {vendor : $X.XX}
  let pantryTotals = {};

  let selectedDate = null;

  // This is the community orders and the importedOrders combined
  let communityAndImportedOrder = {};

  // Contains an array of all the community members individual orders
  let communityOrders = {};

  // Contains the imported order if it exists
  let importedOrder = order.importedOrder;

  // This contains only the imported subsidy amount
  let importedSubsidies = 0;
  // This contains only the imported donated amount
  let importedDonations = 0;
  // This contains only the imported community members amount
  let importedCommunityOrdersTotal = 0;

  // If there is no imported order then set it to an empty array otherwise create
  // the communityAndImportedOrder dictionary.
  if (importedOrder === undefined) {
    importedOrder = [];
  }

  // Once the user has selected a specific date then the order should be loaded
  if (order !== null && order.importedBy === undefined) {
    // Get the stats of this order
    [
      subsidies,
      donations,
      communityOrdersTotal,
      donationAmount,
      grandTotal,
      pantryTotal,
      packagingFees,
      deliveryFees,
      volunteerCredits,
      volunteerFees,
    ] = CalculateOrderStats(
      order,
      location,
      selectedDistributionLocation,
      location,
    );
    // Find the owed amounts for each farm
    [farmTotals, suggestedFees, farmSuggestedTotals] = PriceTotals(
      order.foodList,
      false,
      true,
    );

    // If pantryFoodItems exists then calculate the priceTotals.
    if (order.pantryFoodItems) {
      // Since the pantry uses the individual values to calculate the prices we
      // set the second parameter to true.
      pantryTotals = PriceTotals(order.pantryFoodItems, true);
    }
    communityOrders = order.communityOrders;
    selectedDate = order.selectedDate;
    // If the imported Order is not empty than calculate its specific stats in terms
    // of donations, subsidies and community orders.
    if (Object.keys(importedOrder).length > 0) {
      // This is done because there was a change in how imported orders are saved
      // in the database and so any orders placed after this date will follow the
      // new rules.
      if (order.orderDate > "202106150000000000000") {
        // Returns a dictionary with the key being the distribution location and the
        // value be a dollar value total.
        [importedDonations, importedSubsidies] = AggregateImportedOrders(
          importedOrder,
          null,
          null,
          null,
          null,
          true,
        );
        // Returns a dictionary with the key being the distribution location and the
        // value be a dollar value total.
        importedCommunityOrdersTotal = ImportedOrdersTotal(importedOrder);
      } else {
        [importedDonations, importedSubsidies] = AggregateCustomerList(
          importedOrder,
          null,
          null,
          null,
          null,
          true,
        );
        importedCommunityOrdersTotal = CommunityOrdersTotal(importedOrder);
      }
    }
  }

  // If there is no importedOrder then the combined community and imported Orders
  // are just the community orders.  Otherwise make one dictionary with them both
  if (importedOrder === undefined) {
    communityAndImportedOrder = communityOrders;
  } else {
    // This is done because there was a change in how imported orders are saved
    // in the database and so any orders placed after this date will follow the
    // new rules.  Cycle through all the importedOrder Locations and create the combined
    // community and imported order list.
    if (order.orderDate > "202106150000000000000") {
      communityAndImportedOrder = communityOrders;
      Object.keys(importedOrder).forEach((location) => {
        communityAndImportedOrder = Object.assign(
          {},
          communityAndImportedOrder,
          importedOrder[location].communityOrders,
        );
      });
    } else {
      communityAndImportedOrder = Object.assign(
        {},
        communityOrders,
        importedOrder,
      );
    }
  }

  // Aggregate the orders into one foodList of the customer's orders.  Since the
  // first to arguements are donations and subsidies we don't need to save them.
  // This value is used to display the amounts of food donated and preordered
  const [, , customerFoodList, customerPantryList] = AggregateCustomerList(
    communityAndImportedOrder,
    null,
    null,
    null,
    null,
    true,
  );

  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  packageTotals = PackageTotals(customerFoodList);

  // This is to handle the user changing what values they've selected.  Either the combined,
  // imported or current distribution location amounts.
  const handleDistributionLocationChange = (e) => {
    const valueTemp = e.target.value;
    setSelectedDistributionLocation(valueTemp);
  };

  useEffect(() => {
    // If the pantryFoodItems exists then set the hook.
    if (order.pantryFoodItems) {
      setPantryFoodItems(order.pantryFoodItems);
    }
    const statsOptionsTemp = [
      "Combined Order Stats",
      `Order Stats for ${location}`,
    ];
    // This is done because there was a change in how imported orders are saved
    // in the database and so any orders placed after this date will follow the
    // new rules.
    if (order.orderDate > "202106150000000000000") {
      // Add all the distribution locations involved in this order the options list.
      Object.keys(importedOrder).forEach((location) => {
        statsOptionsTemp.push(`Order Stats for ${location}`);
      });
    } else {
      statsOptionsTemp.push(
        `Order Stats for ${order.importedDistributionLocation}`,
      );
    }
    // Create the options list.
    setStatsOptions([...statsOptionsTemp]);
    setSelectedDistributionLocation("Combined Order Stats");
  }, [selectedDate]);

  // The useEffect sets the displayed amounts to what the user has selected.
  useEffect(() => {
    // This is done because there was a change in how imported orders are saved
    // in the database and so any orders placed after this date will follow the
    // new rules.
    if (order.orderDate > "202106150000000000000") {
      // Take the last characters of the selections to remove the "Orders stats for"
      // portion of the user's selection to have just the location's name.
      const selectedLocation = selectedDistributionLocation.substring(16);
      // The total of all the distributionlocations stats intialized to 0
      let importedSubsidiesTotals = 0;
      let importedDonationsTotals = 0;
      let importedCommunityOrdersTotals = 0;

      // Sum up the total subsidies, donations and orders from all the imported
      // locations.
      Object.keys(importedSubsidies).forEach((location) => {
        importedSubsidiesTotals += importedSubsidies[location];
        importedDonationsTotals += importedDonations[location];
        importedCommunityOrdersTotals += parseFloat(
          importedCommunityOrdersTotal[location],
        );
      });
      // Combined amounts
      if (selectedDistributionLocation === "Combined Order Stats") {
        setDistributionDate(order.selectedDate);
        setSubsidiesDisplayed(subsidies);
        setDonationsDisplayed(donations);
        setCustomerOrdersDisplayed(communityOrdersTotal);
        setDonatedAmount(donationAmount.Total);
        setPantryAddedAmount(
          grandTotal +
            pantryTotal -
            donationAmount.Total -
            communityOrdersTotal,
        );
        setPackagingFeesDisplayed(packagingFees.Total);
        setDeliveryFeesDisplayed(deliveryFees.Total);
        setVolunteerCreditsDisplayed(volunteerCredits.Total);
        setVolunteerFeesDisplayed(volunteerFees.Total);
      }
      // Amounts for the current distribution location
      else if (selectedDistributionLocation === `Order Stats for ${location}`) {
        setDistributionDate(order.selectedDate);
        setSubsidiesDisplayed(subsidies - importedSubsidiesTotals);
        setDonationsDisplayed(donations - importedDonationsTotals);
        setCustomerOrdersDisplayed(
          communityOrdersTotal - importedCommunityOrdersTotals,
        );
        setDonatedAmount(donationAmount[location]);
        setPantryAddedAmount(
          grandTotal +
            pantryTotal -
            donationAmount.Total -
            communityOrdersTotal,
        );
        setPackagingFeesDisplayed(packagingFees[location]);
        setDeliveryFeesDisplayed(deliveryFees[location]);
        setVolunteerCreditsDisplayed(volunteerCredits[location]);
        setVolunteerFeesDisplayed(volunteerFees[location]);
      }
      // Imported Amounts
      else {
        if (order.importedOrder[selectedLocation] !== undefined) {
          setDistributionDate(order.importedOrder[selectedLocation].date);
          setSubsidiesDisplayed(importedSubsidies[selectedLocation]);
          setDonationsDisplayed(importedDonations[selectedLocation]);
          setCustomerOrdersDisplayed(
            importedCommunityOrdersTotal[selectedLocation],
          );
          setDonatedAmount(donationAmount[selectedLocation]);
          setPantryAddedAmount(0);
          setPackagingFeesDisplayed(packagingFees[selectedLocation]);
          setDeliveryFeesDisplayed(deliveryFees[selectedLocation]);
          setVolunteerCreditsDisplayed(volunteerCredits[selectedLocation]);
          setVolunteerFeesDisplayed(volunteerFees[selectedLocation]);
        }
      }
    } else {
      // Combined amounts
      if (selectedDistributionLocation === "Combined Order Stats") {
        setDistributionDate(order.selectedDate);
        setSubsidiesDisplayed(subsidies);
        setDonationsDisplayed(donations);
        setCustomerOrdersDisplayed(communityOrdersTotal);
        setDonatedAmount(grandTotal - communityOrdersTotal);
      }
      // Amounts for the current distribution location
      else if (selectedDistributionLocation === `Order Stats for ${location}`) {
        setDistributionDate(order.selectedDate);
        setSubsidiesDisplayed(subsidies - importedSubsidies);
        setDonationsDisplayed(donations - importedDonations);
        setCustomerOrdersDisplayed(
          communityOrdersTotal - importedCommunityOrdersTotal,
        );
        setDonatedAmount(grandTotal - communityOrdersTotal);
      }
      // Imported Amounts
      else {
        setDistributionDate(order.importedDistributionDate);
        setSubsidiesDisplayed(importedSubsidies);
        setDonationsDisplayed(importedDonations);
        setCustomerOrdersDisplayed(importedCommunityOrdersTotal);
        setDonatedAmount(0);
      }
    }
    // If the user changes the viewed amounts or the selected dates call the useEffect
  }, [
    order.pantryFoodItems,
    donationAmount,
    pantryTotal,
    order.foodList,
    selectedDistributionLocation,
    selectedDate,
    subsidies,
    donations,
    communityOrdersTotal,
    donationAmount,
    grandTotal,
  ]);

  if (order.importedBy) {
    title = `This order was imported by ${order.importedBy}`;
    return (
      <Card className={classes.card}>
        <CardHeader title={title} />
        <CardContent>
          Please select {order.importedBy} and {order.selectedDate} to view the
          details of this order. Combining orders between distribution locations
          helps meet the farm's minimum order and allows for flexibility in the
          event that one distribution location cannot process a delivery.
        </CardContent>
      </Card>
    );
  } else {
    return (
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography variant="h6" gutterBottom>
              Order Stats:
            </Typography>
            {Object.keys(importedOrder).length > 0 && (
              <SimpleSelect
                margin="dense"
                id="selectOrderStatsLocation"
                options={statsOptions}
                handleChange={(e) => handleDistributionLocationChange(e)}
                selected={selectedDistributionLocation}
              />
            )}
            <Typography variant="body1">
              <b>Distribution Day:</b> {distributionDate}
            </Typography>
            <Typography variant="body1">
              <b>Subsidies Used:</b> {formatDollars(subsidiesDisplayed)}
            </Typography>
            <Typography variant="body1">
              <b>Donations Contributed:</b> {formatDollars(donationsDisplayed)}
            </Typography>
            <Typography variant="body1">
              <b>Net Donations:</b>{" "}
              {formatDollars(donationsDisplayed - subsidiesDisplayed)}
            </Typography>
            <Typography variant="body1">
              <b>Customer Orders:</b> {formatDollars(customerOrdersDisplayed)}
            </Typography>
            <Typography variant="body1">
              <b>Excess Food Distributed:</b> {formatDollars(donatedAmount)}
            </Typography>
            {suggestedFees > 0 && (
              <Typography variant="body1">
                <b>Value of Free Food Distributed:</b>{" "}
                {formatDollars(suggestedFees)}
              </Typography>
            )}
            {pantryAddedAmount > 0 && (
              <Typography variant="body1">
                <b>Amount Added to the Pantry:</b>{" "}
                {formatDollars(pantryAddedAmount)}
              </Typography>
            )}
            {packagingFeesDisplayed > 0 && (
              <Typography variant="body1">
                <b>Total Packaging Fees:</b>{" "}
                {formatDollars(packagingFeesDisplayed)}
              </Typography>
            )}
            {deliveryFeesDisplayed > 0 && (
              <Typography variant="body1">
                <b>Total Delivery Fees:</b>{" "}
                {formatDollars(deliveryFeesDisplayed)}
              </Typography>
            )}
            {volunteerCreditsDisplayed > 0 && (
              <Typography variant="body1">
                <b>Total Participation Credits Used:</b>{" "}
                {parseFloat(volunteerCreditsDisplayed).toFixed(2)} credits
              </Typography>
            )}
            {volunteerFeesDisplayed > 0 && (
              <Typography variant="body1">
                <b>Total Participation Fees:</b>{" "}
                {formatDollars(volunteerFeesDisplayed)}
              </Typography>
            )}

            <Typography variant="body1">
              <FoodAmountStatsHomePageDialog
                pantryFoodItems={order.pantryFoodItems}
                customerPantryList={customerPantryList}
                orderFoodList={order.foodList}
                customerFoodList={customerFoodList}
                order={order}
              />
            </Typography>
          </Grid>
          <Grid item md={4}>
            <DistributionLocationMyAccountFarmTotalsLine
              farmTotals={farmTotals}
              grandTotal={grandTotal}
              pantryTotals={pantryTotals}
              packageTotals={packageTotals}
              farmSuggestedTotals={farmSuggestedTotals}
            />
            <CommunityOrdersDialogHomePage
              communityOrders={communityOrders}
              location={location}
              order={order}
              selectedDate={selectedDate}
              imported={false}
            />
            {/* This is done because there was a change in how imported orders are saved
           in the database and so any orders placed after this date will follow the
           new rules.  */}
            {order.orderDate > "202106150000000000000" ? (
              <>
                {Object.keys(importedOrder).map((location, idx) => (
                  <CommunityOrdersDialogHomePage
                    key={idx}
                    communityOrders={importedOrder[location].communityOrders}
                    location={location}
                    order={order}
                    selectedDate={selectedDate}
                    imported={true}
                  />
                ))}
              </>
            ) : (
              <>
                {Object.keys(importedOrder).length > 0 && (
                  <CommunityOrdersDialogHomePage
                    communityOrders={importedOrder}
                    location={order.importedDistributionLocation}
                    order={order}
                    selectedDate={selectedDate}
                    imported={true}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="h6" gutterBottom>
                Items:
              </Typography>
              <Orders
                foodList={order.foodList}
                pantryFoodItems={pantryFoodItems}
                publicView={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}
