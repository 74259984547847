// Displays how much food was distributed for free at this location in their customer
// receipts.  It is a special order as it isn't a customer's order but displays at the
// top of every distribution location's customer's receipts.
import React, { useState, useEffect } from "react";

export default function DistributedAmountReceipt({
  distributedFoodItems,
  location,
}) {
  // A dictionary of the the vendors(keys) and the total owed to them (values)
  const [farmDistributedTotals, setFarmDistributedTotals] = useState({});
  // The total amount of free food.
  const [grandTotal, setGrandTotal] = useState(0);
  // An array of the food that was donated at this location.
  const [distributedAmount, setDistributedAmount] = useState([]);

  useEffect(() => {
    // Checks to see if any food was distributed with this order in the first place.
    if (distributedFoodItems) {
      // Checks to see if this location had any food distributed.
      if (distributedFoodItems[location]) {
        // Sets the distributed food array to match the parameters sent in.
        setDistributedAmount(distributedFoodItems[location]);
        // A dictionary of the the farms(keys) and the total owed to them (values)
        const farmDistributedTotalsTemp = {};

        // First go throuh all the foodItems and initialise the farm providing that
        // item to $0.
        distributedFoodItems[location].forEach((food) => {
          farmDistributedTotalsTemp[food.farmName] = 0;
        });

        // Cycle through all the foodItems again find the product of the quantity
        // and the cost of the food item and then add it to the farmDistributedTotalsTemp total
        distributedFoodItems[location].forEach((food) => {
          // We don't want to add the suggested price.
          if (!food.suggestedPrice) {
            farmDistributedTotalsTemp[food.farmName] +=
              (food.distributed * food.price) /
              (food.distributionQuantity / food.individualQuantity);
          }
        });

        setFarmDistributedTotals(farmDistributedTotalsTemp);

        // The total of all the amount all the vendors were paid.
        let grandTotalTemp = 0;

        // Sum up the grandTotal of all the vendors together.
        Object.keys(farmDistributedTotalsTemp).forEach((vendorTotal) => {
          grandTotalTemp += farmDistributedTotalsTemp[vendorTotal];
        });
        setGrandTotal(grandTotalTemp);
      }
    }
  }, []);

  // If there was any food distributed at this location then show the receipt otherwise
  // just return the function and show nothing.
  if (distributedAmount.length > 0) {
    return (
      <>
        <h4>Food Distributed for Free:</h4>

        {distributedAmount.map((food, itemNumber) => {
          let individualPrice = parseFloat(
            food.price / (food.distributionQuantity / food.individualQuantity),
          );
          // The individual description of the food item for the user to know how
          // much they are purchasing.
          let individualDescription = food.individualDescription;

          // if suggestedPrice is true then we want to set the price to 0
          if (food.suggestedPrice) {
            // First add to the description the suggested Donation.
            individualDescription = individualDescription.concat(
              " - Suggested Donation of $",
              individualPrice.toFixed(2),
            );
            // Then reset the prices to 0.
            individualPrice = 0.0;
          }

          const individualPriceTotal = parseFloat(
            individualPrice * food.distributed,
          ).toFixed(2);
          individualPrice = individualPrice.toFixed(2);

          return (
            <div key={itemNumber}>
              <span key={itemNumber}>
                <div className="Edit-Community-Items-Distribution-Location">
                  <p style={{ margin: "0px 0px 0px 20px" }}>
                    {itemNumber + 1}. {food.farmName}{" "}
                    <strong>{food.item}</strong> {individualDescription} x
                    {food.distributed} -- ${individualPrice} ($
                    {individualPriceTotal})
                    {!!food.limit && (
                      <img
                        style={{ marginLeft: "5px", height: "15px" }}
                        src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
                      />
                    )}
                  </p>
                </div>
              </span>
            </div>
          );
        })}
        <div className="Price-Totals">
          <h5 className="Contact-Method-Title Food-Item-Header">
            {" "}
            Farm Price Totals{" "}
          </h5>
          {Object.keys(farmDistributedTotals).map((farmTotal, index) => (
            <p key={farmTotal + index} className="Prices-Per-Farm">
              {farmTotal} : ${farmDistributedTotals[farmTotal].toFixed(2)}{" "}
            </p>
          ))}
          <p className="User-Total-Payment" style={{ borderTop: "solid 1px" }}>
            <b>Total </b>: ${grandTotal.toFixed(2)}
          </p>
        </div>
      </>
    );
  } else {
    return null;
  }
}
