// This displays the marketplace when a user hasn't signed in.
import React from "react";
import { Grid } from "@material-ui/core";
import Menu from "../../../components/Menu.js";
import FoodElement from "../Components/FoodElement.jsx";
import FilterColumn from "../Components/FilterColumn.jsx";
import NextPickupDay from "../../../functions/NextPickupDay.js";
import moment from "moment";
import MarketplaceMessageBanner from "../Components/MarketplaceMessageBanner.jsx";
import NoSignInMarketplaceHeaderInformation from "../Components/NoSignInMarketplaceHeaderInformation.jsx";
import Footer from "../../../components/Footer.jsx";
import SelectDistributionLink from "../Components/SelectDistributionLink.jsx";

export default function NoSignInMarketplace({
  noSignInUser,
  farmDetails,
  foodItems,
  displayedFoodItems,
  setDisplayedFoodItems,
  currentUser,
  marketplaceCategories,
  pickupLocationDetails,
  pickupLocation,
  selectedCategory,
  setSelectedCategory,
  checkedFarms,
  setCheckedFarms,
}) {
  let emptyMarketplaceMessage =
    "Items will be added soon!  Please come back later.";
  if (pickupLocationDetails.emptyMarketplaceMessage !== undefined) {
    emptyMarketplaceMessage = pickupLocationDetails.emptyMarketplaceMessage;
  }
  if (displayedFoodItems.length === 0 && foodItems.length !== 0) {
    emptyMarketplaceMessage =
      "There are no items to display for the filters you selected.  Please broaden your search.";
  }

  // Provides the date of the order cutoff for the user.
  // eslint-disable-next-line no-unused-vars
  const orderCutoffDate = moment(
    NextPickupDay(pickupLocationDetails.orderCutoff),
    "MM-DD-YYYY",
  ).format("MMMM D Y");
  // Provides the date of the pickup day for the user.
  // eslint-disable-next-line no-unused-vars
  const pickupDate = moment(
    NextPickupDay(pickupLocationDetails.pickupDay),
    "MM-DD-YYYY",
  ).format("MMMM D Y");

  return (
    <div
      style={{ background: "rgb(244, 249, 224)", minWidth: "950px" }}
      id="home"
    >
      <Menu />
      {pickupLocationDetails.marketplaceMessageEnabled && (
        <div style={{ padding: "1px", background: "rgb(159 191 89)" }}>
          <MarketplaceMessageBanner
            pickupLocationDetails={pickupLocationDetails}
          />
        </div>
      )}
      <div className="Marketplace-Page-Header">
        <NoSignInMarketplaceHeaderInformation
          pickupLocation={pickupLocationDetails}
          farmDetails={farmDetails}
        />
      </div>
      <div className="Page-Market" id="pageMarket">
        {/* If the user hasn't picked a pickup location yet then the dictionary
       will be empty and they will need to select one first. */}
        {Object.keys(pickupLocation).length === 0 ? (
          <SelectDistributionLink />
        ) : (
          <Grid container spacing={2}>
            {foodItems.length !== 0 && (
              <Grid item xs={3}>
                <div className="Checkout-Add-Items-Buttons">
                  <FilterColumn
                    marketplaceCategories={marketplaceCategories}
                    foodItems={foodItems}
                    setDisplayedFoodItems={setDisplayedFoodItems}
                    checkedFarms={checkedFarms}
                    setCheckedFarms={setCheckedFarms}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs>
              {displayedFoodItems.length !== 0 ? (
                <span>
                  <div className="Food-List">
                    {displayedFoodItems.map((row, index) => (
                      <FoodElement
                        key={index}
                        index={index}
                        row={row}
                        userInfo={noSignInUser}
                      />
                    ))}
                  </div>
                  <div>
                    Icons made by{" "}
                    <a
                      href="https://www.flaticon.com/authors/photo3idea-studio"
                      title="photo3idea_studio"
                    >
                      photo3idea_studio
                    </a>{" "}
                    from{" "}
                    <a href="https://www.flaticon.com/" title="Flaticon">
                      www.flaticon.com
                    </a>
                  </div>
                </span>
              ) : (
                <span>
                  <span>
                    <h3 className="EmptyMarketplace">
                      {emptyMarketplaceMessage}
                    </h3>
                  </span>
                </span>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <Footer />
    </div>
  );
}
