// This function is called to toggle on and off whether to display the distribution
// location's marketplace message.
import firebase from "../../../components/Firebase.js";
import UpdateCommunityLocation from "../../../functions/UpdateCommunityLocation.js";

export default function HandleMessageDisplayedSwitch(
  marketplaceMessageEnabled,
  userInfo,
  handleUserInfoChange,
  message,
  distributionLocationInformation,
  setDistributionLocationInformation,
) {
  // Check first if the user has an empty message.  If they do prevent them from
  // being able to display the message.
  if (message === undefined || message === "") {
    // eslint-disable-next-line no-undef
    alert("Please first fill out a message to display before showing message.");
  } else {
    // Create a temporary userInfo to update the changes on the screen.
    const userInfoTemp = { ...userInfo };
    const database = firebase.firestore();
    const batch = database.batch();
    // The locatin in the document that is being updated. distributionLocation.marketplaceMessageEnabled
    const locationToUpdate = [
      userInfo.organisationName,
      "marketplaceMessageEnabled",
    ].join(".");

    // References the database where the Distribution Location exist.
    const docRef = database
      .collection("DistributionLocations")
      .doc("Vancouver");

    // Update the database.
    batch.update(docRef, { [locationToUpdate]: !marketplaceMessageEnabled });

    // Update the distribution location's information in the Users collection
    const userDocRef = database.collection("Users").doc(userInfo.userId);

    // Update the database.
    batch.update(userDocRef, {
      marketplaceMessageEnabled: !marketplaceMessageEnabled,
    });

    batch.commit();

    // Update the userInfo message.
    userInfoTemp.marketplaceMessageEnabled = !marketplaceMessageEnabled;

    // Updates the Distribution location information that is saved into the database.
    const distributionLocationInformationTemp = {
      ...distributionLocationInformation,
    };
    distributionLocationInformationTemp.marketplaceMessageEnabled =
      !marketplaceMessageEnabled;
    setDistributionLocationInformation({
      ...distributionLocationInformationTemp,
    });

    // Updates any community locations that may exist that have this distribution location as their partner.
    UpdateCommunityLocation(
      userInfo.userId,
      {},
      false,
      false,
      userInfoTemp,
      true,
    );

    handleUserInfoChange({ ...userInfoTemp }, true);
  }
}
