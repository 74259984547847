// This function filters the items that are displayed based on the users chosen
// filters.
export default function FilterDisplayedItems(
  foodItems,
  setDisplayedFoodItems,
  selectedCategory,
  checkedFarms,
) {
  // Checks to see if all the farms are unchecked.
  const isFalse = (farm) => checkedFarms[farm] === false;

  // FoodItems to display to the user after filters are applied
  let displayedFoodItemsTemp = [];

  // User selected "All Products" and unchecked all farms so display all items
  if (
    selectedCategory === "All Products" &&
    Object.keys(checkedFarms).every(isFalse)
  ) {
    displayedFoodItemsTemp = [...foodItems];
  }
  // If the user has selected "All Products" but checked some farms
  else if (selectedCategory === "All Products") {
    // Make an array of all the checked farms.
    const farmsChecked = [];
    // Cycle through the checkedFarms dictionary.
    Object.keys(checkedFarms).forEach((farm) => {
      // If the farm is checked it will be true and push it to the array.
      if (checkedFarms[farm]) {
        farmsChecked.push(farm);
      }
    });

    // Cycle through all the foodItems and only add the items that are from farms
    // the user selected.
    foodItems.forEach((foodItem) => {
      if (farmsChecked.includes(foodItem.farmName)) {
        displayedFoodItemsTemp.push(foodItem);
      }
    });
  }
  // If the user has unchecked all the farms but selected a category.
  else if (Object.keys(checkedFarms).every(isFalse)) {
    // Cycle through all the items and only add the items that match the category
    // the user selected.
    foodItems.forEach((foodItem) => {
      if (foodItem.category === selectedCategory) {
        displayedFoodItemsTemp.push(foodItem);
      }
    });
  }
  // The user checked some farms and a category.
  else {
    // Make an array of all the checked farms.
    const farmsChecked = [];
    // Cycle through the checkedFarms dictionary.
    Object.keys(checkedFarms).forEach((farm) => {
      if (checkedFarms[farm]) {
        farmsChecked.push(farm);
      }
    });
    // Cycle through the foodItems and make sure that they match the selected category
    // and the are from a user selected farm.
    foodItems.forEach((foodItem) => {
      if (
        farmsChecked.includes(foodItem.farmName) &&
        foodItem.category === selectedCategory
      ) {
        displayedFoodItemsTemp.push(foodItem);
      }
    });
  }
  // set the hook of the displayed farms.
  setDisplayedFoodItems([...displayedFoodItemsTemp]);
}
