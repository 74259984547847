// This is the to display the totals paid to each vendor and allows the user to
// toggle between seeing how much food was sold from each vendor from items taken
// from the pantry.
import React, { useState, useEffect } from "react";
import FarmTotalLine from "./FarmTotalLine.jsx";
import ToggleButton from "@mui/material/ToggleButton";

export default function DistributionLocationFarmTotalsLine({
  farmTotals,
  combinedFarmList,
  userType,
  grandTotal,
  pantryTotals,
}) {
  // Show the pantry totals or the purchased totals when false.
  const [showPantryTotals, setShowPantryTotals] = useState(false);
  // The totals paid to each vendor {vendor : $total}
  let totals = farmTotals;
  // Text to display on the button.
  let displayTotals = "Click To Show Pantry Totals";
  // Calculate based on the distribution location costs or the individual costs.
  let userTypeTotals = userType;
  // The header to display on the price totals.
  let totalsHeader = "Total Ordered From Farms";

  // If the user is showing the pantryTotals then set the variables for the pantry
  // showing.
  if (showPantryTotals) {
    totals = pantryTotals;
    displayTotals = "Click To Show Ordered Totals";
    totalsHeader = "Total Taken From Pantry";
    userTypeTotals = "individual";
  }

  // Checks to make sure that if the user removed the pantry items and they were
  // looking at the pantry then switch it back.
  useEffect(() => {
    if (Object.keys(pantryTotals).length === 0) {
      setShowPantryTotals(false);
      totals = farmTotals;
      displayTotals = "Click To Show Pantry Totals";
      userTypeTotals = userType;
      totalsHeader = "Total Ordered From Farms";
    }
  }, [pantryTotals]);

  return (
    <div className="Totals-And-Button">
      {Object.keys(pantryTotals).length > 0 && (
        <ToggleButton
          value="check"
          color="secondary"
          selected={showPantryTotals}
          onChange={() => {
            setShowPantryTotals(!showPantryTotals);
          }}
        >
          {displayTotals}
        </ToggleButton>
      )}

      <h6>
        <u>{totalsHeader}</u>
      </h6>
      {Object.keys(totals).map((farmTotal, index) =>
        FarmTotalLine(totals, farmTotal, combinedFarmList, userTypeTotals),
      )}

      {!showPantryTotals && (
        <p>
          <b>Total </b>: ${grandTotal.toFixed(2)}
        </p>
      )}
    </div>
  );
}
