// This is to update an individual user's volunteer fees from the basket.
import firebase from "./../Firebase.js";

export default async function UpdateCustomerVolunteerFeesBasket(
  user,
  imported,
  userInfo,
  userOrder,
  locationName,
  oldCreditPortion,
  handleCommunityOrderChange,
  importedOrder,
  selectedDate,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userOrderDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // The indivdual's account may need to be updated in case their credits need to be changed.
  const userDocRef = database.collection("Users").doc(userId[2]);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // Update the current distribution locations orders in the DistributionLocations
  // collection
  const communityOrdersRef = database
    .collection("DistributionLocations")
    .doc(userInfo.organisationName)
    .collection("CommunityOrders");

  const querySnapshot = await communityOrdersRef
    .where("distributionDate", "==", selectedDate)
    .get();

  // Loop through the documents to find the one with the matching user key
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // Check if the document contains the user key
    if (user in data) {
      communityOrdersDocRef = doc.ref; // Reference to the found document
    }
  });

  // Check if a document was found for the user
  if (!communityOrdersDocRef) {
    console.log("No document found for user:", user);
    return; // Exit if no document was found
  }

  // Remove the Dicts as they were added for rendering purposes and should not
  // be saved to the database
  delete userOrder.foodDict;
  delete userOrder.farmDict;

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  batch.update(communityOrdersDocRef, {
    [user]: { ...userOrder },
  });

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userOrderDocRef, {
    cashPortion: userOrder.cashPortion,
    creditPortion: userOrder.creditPortion,
  });

  // This is just the value of the subsidy/donation.  It will be negative if it
  // is a subsidy or positive if it is a donation.
  const creditsChange =
    parseFloat(oldCreditPortion) - parseFloat(userOrder.creditPortion);

  if (Math.abs(creditsChange) > 0.01) {
    batch.update(userDocRef, {
      credits: firebase.firestore.FieldValue.increment(creditsChange),
    });
  }

  await batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  handleCommunityOrderChange(
    imported,
    null,
    null,
    user,
    userOrder,
    null,
    locationName,
  );

  console.log("Successfully wrote to database!");
}
