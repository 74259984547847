import React, { useContext, useEffect } from "react";
import "../App.css";
import "../styles/HomePage.css";
import Menu from "../components/Menu.js";
import "w3-css/w3.css";
import { AuthContext } from "../components/authentication/Auth.js";
import Footer from "../components/Footer.jsx";
import SupportingOrganisationsSection from "./HomePagePage/Components/SupportingOrganisationsSection.jsx";
import ParticipatingFarmsSection from "./HomePagePage/Components/ParticipatingFarmsSection.jsx";
import OverviewSection from "./HomePagePage/Components/OverviewSection.jsx";
import ExplanationSection from "./HomePagePage/Components/ExplanationSection.jsx";
import HomePageHeader from "./HomePagePage/Components/HomePageHeader.jsx";
import CommunityBoardSection from "./HomePagePage/Components/CommunityBoardSection.jsx";

export default function HomePage() {
  const { currentUser } = useContext(AuthContext);

  // Start at the top of the page when this page loads.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Menu />
      <HomePageHeader currentUser={currentUser} />
      <div>
        <OverviewSection />
        <div style={{ fontSize: "10px", marginLeft: "50px" }}>
          <a
            href="https://www.flaticon.com/free-icons/farmer"
            title="farmer icons"
          >
            Farmer icons created by max.icons;
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/baker"
            title="baker icons"
          >
            Baker icons created by afitrose;
          </a>

          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/chef"
            title="chef icons"
          >
            Chef icons,
          </a>

          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/number"
            title="number icons"
          >
            Number icons,
          </a>
          <a href="https://www.flaticon.com/free-icons/2" title="2 icons">
            2 icons,
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/number"
            title="number icons"
          >
            Number icons,
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/money"
            title="money icons"
          >
            Money icons, and
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/home"
            title="home icons"
          >
            Home icons created by Freepik;
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/market"
            title="market icons"
          >
            Market icons created by mynamepong - Flaticon
          </a>
        </div>

        <hr style={{ margin: "0px 50px" }} className="HomePage-Line-Divider" />

        <ExplanationSection />

        <div style={{ fontSize: "10px", marginLeft: "50px" }}>
          <a
            href="https://www.flaticon.com/free-icons/sovereignty"
            title="sovereignty icons"
          >
            Sovereignty icons created by gravisio;
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/garbage"
            title="garbage icons"
          >
            Garbage icons created by Eucalyp;
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/partnership"
            title="partnership icons"
          >
            Partnership icons created by surang;
          </a>
          <a
            style={{ marginLeft: "5px" }}
            href="https://www.flaticon.com/free-icons/social-engineering"
            title="social engineering icons"
          >
            Social engineering icons created by zero_wing - Flaticon
          </a>
        </div>

        <hr style={{ margin: "0px 50px" }} className="HomePage-Line-Divider" />

        <ParticipatingFarmsSection />

        <hr style={{ margin: "0px 50px" }} className="HomePage-Line-Divider" />

        <SupportingOrganisationsSection />

        <hr style={{ margin: "0px 50px" }} className="HomePage-Line-Divider" />

        <CommunityBoardSection />
      </div>
      <Footer />
    </div>
  );
}
