// When a user has been remvoed from a community location the community host then
// has the option to permanently remove the user which wipes them off the location.
// This allows the usr who was removed to reapply to this community host.
import firebase from "../../../components/Firebase.js";

export default function DeleteCommunityMemberApplication(
  communityMember,
  userInfo,
  communityMembers,
  setCommunityMembers,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  // eslint-disable-next-line no-unused-vars
  const communityLocationDocumentName = [
    userInfo.firstName,
    userInfo.lastName,
    userInfo.userId,
  ].join("");

  const userDocRef = database
    .collection("Users")
    .doc(userInfo.userId)
    .collection("CommunityHosts")
    .doc("CommunityMembers");

  // eslint-disable-next-line no-unused-vars
  const communityMembersArr = [];

  const communityMembersTemp = { ...communityMembers };

  delete communityMembersTemp[communityMember];

  batch.update(userDocRef, {
    [communityMember]: firebase.firestore.FieldValue.delete(),
  });

  batch.commit();

  setCommunityMembers({ ...communityMembersTemp });
}
