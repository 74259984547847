// This popover will show farmer info on the marketplace page so that the user
// can quickly shop while looking at the farmer's info.  The user can then click
// on read more to open the farmer page in another tab.
import React, { useState, useEffect } from "react";
import firebase from "../../../components/Firebase.js";
import { Popper, Typography, ClickAwayListener, Box } from "@material-ui/core";

export default function FarmPopover({ row }) {
  const [farmers, setFarmers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  // Get farmer information from firestore database
  const database = firebase.firestore();

  useEffect(() => {
    const farmerDocRef = database.collection("Farmers");
    const farmerDataTransfer = [];
    farmerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            farmerDataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setFarmers(farmerDataTransfer);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  // Default image for farmer if they don't have one
  const farmerDefaultImage =
    "https://lh3.googleusercontent.com/pw/AM-JKLXmYdMDBXvEVNtJYNKg0Tp7Gd5WBMDGmZu0kkpcODEdRUv8k4ITDIc0vIc0i6ukg_PmSkD3YB4ydIxzH7hFmwgxrxOBn6hdM4RUMUgUUfgU2XCmLqB7FyoEMMKHZFaqxHP8k5xDujZ3p6e5g_JMlExe=s512-no?authuser=1";

  // Link to the farmer's description.
  const linkToFarmDescription = "/Farms/#".concat(
    row.farmName.replace(/\s/g, ""),
  );

  // Farm popover controls
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {/* Clicking row.farmName (name of the farm) launches the popover. The popover displays the farm name, the names of the owners, an image and a description. More info in comments below */}
      <p className="Farm-Name-Marketplace" onClick={handleClick}>
        {row.farmName}
      </p>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top"
        style={{
          width: "275px",
          padding: "10px",
          backgroundColor: "white",
          borderRadius: "5px",
          boxShadow: "0px 0px 5px 5px rgba(0,0,0,0.08)",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Typography>
              <b>{row.farmName}</b>
              {farmers.map((farmer, i) => {
                if (farmer.farmName !== row.farmName) return null;
                return (
                  <React.Fragment key={i}>
                    {/* If farmer has a picture in the database, display it. Otherwise, display the default image */}
                    <img
                      src={
                        farmer.farmerImage
                          ? farmer.farmerImage
                          : farmerDefaultImage
                      }
                      referrerPolicy="no-referrer"
                      alt="farmer"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                        float: "right",
                      }}
                    />
                    {/* The farm's owners or managers */}
                    <Typography>{farmer.farmerName}</Typography>
                    <Typography style={{ marginBottom: "5px" }}>
                      {farmer.farmLocation}
                    </Typography>
                    <i>
                      {/* Farm description is clipped with an ellipses at the end if longer than 120 characters */}
                      {farmer.farmDescription.length > 120
                        ? `${farmer.farmDescription.substring(0, 120)}...`
                        : farmer.farmDescription}
                    </i>
                  </React.Fragment>
                );
              })}
            </Typography>
            {/* Opens a link to the farm's full description in a new tab  */}
            <Typography style={{ marginTop: "5px" }}>
              <a
                href={linkToFarmDescription}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </Typography>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
