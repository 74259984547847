// This is the section of the homepage that shows some pictures of our vendors and
// then a link to our farmers page.  There is also a description of the farmers and
// information to share.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function ParticipatingFarmsSection() {
  return (
    <div className="Participating-Farms-Section">
      <div className="Farm-Logo-Cluster">
        {/* Bottom Left */}
        <img
          className="Farm-Image6"
          referrerPolicy="no-referrer"
          src="https://lh3.googleusercontent.com/pw/AP1GczOV9jZX3QMEACf4b5zw0WTVyxy9ZyZ1ryPj-RE0pEpOu7Uldwsate-4XcnkcZf2TE8QdTqp1uFVIKoSw0v3ROhBP5M291MuBlcThYqlTwiLZDhbhNS-PX9K2J0GIQEI3QW2-gGPIQGrTHgwKKRgdYFt=w1080-h901-s-no-gm?authuser=1"
        />
        {/* Top */}
        <img
          className="Farm-Image5"
          referrerPolicy="no-referrer"
          src="https://lh3.googleusercontent.com/pw/AP1GczPWUkF_Uj8Bmgze7kVWABJ2dwJF_lVYaYNRnwjHxUXtuZHCLV0sat8J0wVvded3jjZ7wVeypCQijaok1p0WL0WnJUADY7oiJhh-Xu_D59UiYR99IVJFov8H8RpZZiBM9MwS-fTdLUEzgXhALzpg4vp3=w1080-h720-s-no-gm?authuser=1"
        />
        {/* Left Centre */}
        <img
          className="Farm-Image4"
          referrerPolicy="no-referrer"
          src="https://lh3.googleusercontent.com/pw/AP1GczOFxTT_-61cWkc276SI68pnWY6iwb9AAve763pb9uXK2qXp6yZhmnnES6H7NDtSet2Ool0rNyBNn6lx4JATpg04jRwmbklTooVRMPUoL4oPsB7MW21NnqWJz5kMBGU4yJ9a8-m5rzKlEp4OrmoBsBuW=w1005-h1005-s-no-gm?authuser=1"
        />
        {/* Bottom Right */}
        <img
          className="Farm-Image3"
          referrerPolicy="no-referrer"
          src="https://lh3.googleusercontent.com/pw/AP1GczNG1Ks5KF35wh8O5t9WhKKLKDB9G0mwwyRYrX02WgRMnmXPEOUT7LGmREN8WusWdHs8wt6Tec4lrojYIk7zq2boQue-4rfTJ7AmP7ghgb3amaQpkXnbgjO3nUmYKpZB0XaAIiShhrlmbo-1DNWEWpJ-=w640-h427-s-no-gm?authuser=1"
        />
        {/* Right centre */}
        <img
          className="Farm-Image2"
          referrerPolicy="no-referrer"
          src="https://lh3.googleusercontent.com/pw/AP1GczNqrp2xH5FOHR7B40yGHroxwws6iXrop1XepLWcSkjbWvSm8qDxLJc8HvXCs6Lq1AHR-Nl37lKjLA2FVm5jZTnFcKWQAb18r72tkL1y1tWA-DsklK1mJz-WwaGCzgvu23mCJ1OCmwBpNsSaWF6cB7i5=w1080-h608-s-no-gm?authuser=1"
        />
        {/* Centre */}
        <img
          className="Farm-Image1"
          referrerPolicy="no-referrer"
          src="https://lh3.googleusercontent.com/pw/AP1GczPEVm7OeaOyX2iFD-llWSOOsgqA1LmeH_HpW2IPs-EWFHt0ygnJHNsH7aP5LbX0IkDZwCXeD8Z6lawT3zmIDuLkAxgj1IIAdZ_LnDH0_EIRut4I7BMsTZP4QpsiAKlzVUtZLV15--K7kdGX7akv44_o=w1080-h1434-s-no-gm?authuser=1"
        />
      </div>
      <div className="Farms-Text-Button">
        <h1 className="Participating-Farms-Header">
          Participating Farms and Vendors
        </h1>
        <p className="Participating-Farms-Text">
          Join your community in supporting local farmers and vendors who
          produce and deliver an array of unique products and produce. Each
          vendor has their own growing practices, and stories to share. We
          encourage community members to connect directly with vendors with any
          questions, comments or compliments they wish to pass along. Please
          feel free to connect with them using the contact information they’ve
          provided.
          <br />
          <br />
          New vendors are added by suggestions from the community. If you have
          one that you would like to see on the marketplace please connect us at
          anthony@farmtoplatemarketplace.com.
        </p>
        <span>
          <Link style={{ textDecoration: "none" }} to="/Farms">
            <Button
              style={{ background: "#45AA45", color: "white" }}
              size="large"
              variant="contained"
            >
              VIEW ALL
            </Button>
          </Link>
        </span>
      </div>
    </div>
  );
}
