// Calculates the updated volunteer fee cash and credits portions and the amount that
// the volunteer cash portion changed.  This is only called when a user's order is having
// an item added or removed from their orders.
import firebase from "../../components/Firebase.js";

export default function VolunteerFeeAdjustment(
  userOrder,
  userId,
  userInfo,
  foodItem,
  database,
  batch,
  remove,
) {
  // Set the volunteer to the current volunteer fee at this location.  Since there
  // is no way to check the volunteer at the time if it is picked up on site then we
  // have to just set it to the current fee.
  let distributionVolunteerPercentage =
    parseFloat(userInfo.volunteerPercentage) / 100;
  // If the user ordered from a community pick up then we can check to see what the volunteer
  // fee was at the time of ordering which is better.
  if (userOrder.communityPickup !== undefined) {
    if (userOrder.communityPickup.pickupLocation !== undefined) {
      if (
        parseFloat(
          userOrder.communityPickup.pickupLocation.volunteerPercentage,
        ) > 0
      ) {
        distributionVolunteerPercentage =
          parseFloat(
            userOrder.communityPickup.pickupLocation.volunteerPercentage,
          ) / 100;
      }
    }
  }
  // Make sure that the distributionVolunteerPercentage is a number and if it's not
  // then it's just set to 0.
  if (isNaN(distributionVolunteerPercentage)) {
    distributionVolunteerPercentage = 0;
  }

  // The cash portion of the volunteerFee.
  let volunteerCashPortion = parseFloat(userOrder.cashPortion);

  // Make sure that the volunteerCashPortion is a number and if it's not
  // then it's just set to 0.
  if (isNaN(volunteerCashPortion)) {
    volunteerCashPortion = parseFloat(0);
  }
  // The credit portion of the volunteerFee.
  let volunteerCreditPortion = parseFloat(userOrder.creditPortion);
  // Make sure that the volunteerCreditPortion is a number and if it's not
  // then it's just set to 0.
  if (isNaN(volunteerCreditPortion)) {
    volunteerCreditPortion = parseFloat(0);
  }

  // The price of the item that is being adjusted.
  const pricePerIndividualItem = parseFloat(
    (foodItem.price * foodItem.individualQuantity) /
      foodItem.distributionQuantity,
  );

  // Calculate the new volunteer Fee added because of the item.
  let volunteerFeeChange =
    parseFloat(distributionVolunteerPercentage) *
    pricePerIndividualItem *
    parseFloat(foodItem.quantity);

  // If the user is not removing the item then we just need to add the volunteer
  // fee change as we know that we are adding an item which is simple.
  if (!remove) {
    volunteerCashPortion += volunteerFeeChange;
  }
  // Otherwise we are removing an item and have to calculate the changes.
  else {
    // Make the volunteer fee change negative as we are removing the item.
    volunteerFeeChange = -volunteerFeeChange;
    // This is the difference between the cash portion and the change in volunteer
    // fee.
    const cashPortionDifference = volunteerCashPortion + volunteerFeeChange;
    // If the cash portion the difference is less than 0 we will also have to update
    // the credits otherwise updating the volunteerCashPortion is all we do.
    if (cashPortionDifference >= 0) {
      // Reduce the cash portion by the amount of the price change.
      volunteerCashPortion = volunteerCashPortion + volunteerFeeChange;
    }
    // If the volunteer price change is more than the cash paid by the user we
    // also have to issue a credit refund.
    else {
      // The volunteer change of the cash portion is just the total cash portion in
      // the negative.
      volunteerFeeChange = -volunteerCashPortion;
      // Set the cash portion of the volunteer fee to 0 as we've reduced it all the way.
      volunteerCashPortion = 0;

      // Find the amount leftover of the difference between the cash and total
      // volunteerFeeChange to determine how much was left over and needing to be refunded.
      // We want the absolute value as we will be issuing a refund and adding this value to
      // the user's credit balance.
      let creditPortionRefund = Math.abs(cashPortionDifference);

      // Check to make sure that there are still enough credits available.
      if (volunteerCreditPortion + cashPortionDifference >= 0) {
        volunteerCreditPortion = cashPortionDifference + volunteerCreditPortion;
      }
      // If there wasn't enough credits then we will just refund the credit portion
      // they paid as they hadn't orignally paid the full volunteer fee.
      else {
        // The refund will just be all the credits they used to cover this order.
        creditPortionRefund = volunteerCreditPortion;
        volunteerCreditPortion = 0;
      }

      // If the user has a credit refund then we will update their account right
      // away to include their credits.  We also want to check that batch exists as
      // we may not want to update the refund credits yet.
      if (creditPortionRefund > 0 && batch != null) {
        const docRef = database.collection("Users").doc(userId);

        // Refund the volunteerCreditPortion of this user's total
        batch.update(docRef, {
          credits: firebase.firestore.FieldValue.increment(creditPortionRefund),
        });
      }
    }
  }

  return [volunteerCashPortion, volunteerCreditPortion, volunteerFeeChange];
}
