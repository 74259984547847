// The Individual Marketplace's header information needs to displayed and it may
// be either of a community host or a distribution location.
import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import NextPickupDay from "../../../functions/NextPickupDay.js";
import OrderCutoffPopover from "./OrderCutoffPopover.jsx";

export default function NoSignInMarketplaceHeaderInformation({
  pickupLocation,
  farmDetails,
}) {
  // If the user has selected a pickupLocation
  if (pickupLocation.name) {
    let orderCutoffDate = "no date";
    // Provides the date of the pickup day for the user.
    let pickupDate = "no date";
    // Provides the date of the order cutoff for the user.
    if (pickupLocation !== undefined) {
      orderCutoffDate = moment(
        NextPickupDay(pickupLocation.orderCutoff),
        "MM-DD-YYYY",
      ).format("MMMM D Y");
      // Provides the date of the pickup day for the user.
      pickupDate = moment(
        NextPickupDay(pickupLocation.pickupDay),
        "MM-DD-YYYY",
      ).format("MMMM D Y");
    }
    return (
      <Grid
        container
        className="Marketplace-Grid-Header"
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          alignItems: "baseline",
        }}
      >
        <Grid item xs>
          <h1 className="Title-Marketplace">Marketplace</h1>
        </Grid>
        <Grid item xs>
          {pickupLocation !== undefined && (
            <div className="Location-Title-And-Details">
              <span
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  minWidth: "400px",
                }}
              >
                <h1 className="Pickup-Location-Title">{pickupLocation.name}</h1>
                <Link
                  to={{
                    pathname: "/DistributionLocationSelection",
                    query: "/Marketplace",
                  }}
                >
                  <Button color="primary">CHANGE</Button>
                </Link>
              </span>
              <span className="Distribution-Location-Details">
                <p style={{ margin: "0px" }}>
                  <b>Address :</b> {pickupLocation.address}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    right: "31px",
                  }}
                >
                  <OrderCutoffPopover farmDetails={farmDetails} />
                  <p style={{ margin: "5px 0px" }}>
                    <b>Order Cutoff :</b> {pickupLocation.orderCutoff},{" "}
                    {orderCutoffDate}
                  </p>
                </div>
                <p style={{ margin: "0px" }}>
                  <b>Pickup Time :</b> {pickupLocation.pickupDay}{" "}
                  {pickupLocation.pickupTime}, {pickupDate}
                </p>
                <p style={{ margin: "5px 0px" }}>
                  <b>Maximum Subsidy Per Order :</b> $
                  {pickupLocation.subsidyAmount}
                </p>
              </span>
            </div>
          )}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}
