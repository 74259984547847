// Adds the category to the distribution Locations that are saved in the
// DistributionLocations -> Vancouver document. It takes in all the farm's current
// categories and the new items farm and category.
import firebase from "./../components/Firebase.js";
import UpdateCommunityLocation from "../functions/UpdateCommunityLocation.js";

export default function AddCategoryToDistributionLocations(
  farmCategories,
  farmToChange,
  category,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  // In case there are no farmCategories set yet then create an empty array {farmName : []}.
  if (!farmCategories[farmToChange]) {
    farmCategories[farmToChange] = [];
  }

  // If that category didn't exist in the farm than add it to all the distribution
  // locations' farms.
  if (!farmCategories[farmToChange].includes(category)) {
    distributionLocationsDocRef.get().then((doc) => {
      if (doc.exists) {
        // create a dictionary of all the distributionLocations information
        const distributionLocationInformationTemp = doc.data();
        // Cycle through each distribution Location
        Object.keys(distributionLocationInformationTemp).forEach((location) => {
          // Some distribution location's may be missing the category field in
          // which case skip it.
          if (distributionLocationInformationTemp[location].categories) {
            // Cycle through all the distribution Location's farms in the category array
            Object.keys(
              distributionLocationInformationTemp[location].categories,
            ).forEach((farm) => {
              // Check if the farm in the array matches the farm having an item
              // added to it
              if (farm === farmToChange) {
                // add this category to the farm's array
                distributionLocationInformationTemp[location].categories[
                  farm
                ].push(category);
              }
            });
          }
        });

        // Update the distributionLocation's locations
        Object.keys(distributionLocationInformationTemp).forEach((location) => {
          batch.set(
            distributionLocationsDocRef,
            { [location]: distributionLocationInformationTemp[location] },
            { merge: true },
          );
          // Updates any community locations that may exist that have this distribution location as their partner.
          UpdateCommunityLocation(
            distributionLocationInformationTemp[location].userId,
            distributionLocationInformationTemp[location],
          );
        });
        // Commit these changes
        batch.commit();
      }
    });
  }
}
