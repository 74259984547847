// This is a form that the user uses to send in their application to become a community
// pickup location.  It will update the database and then also send emails to distribution
// location and the user applying.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import CommunityHubApplicationForm from "../../../components/userForms/CommunityHubApplicationForm.jsx";

export default function BecomeCommunityHostDialog({
  pickupLocations,
  userInfo,
  setSendingEmail,
  handleUserInfoChange,
  currentUser,
}) {
  // Whether the dialog is open or not.
  const [open, setOpen] = useState(false);
  // The distribution location that the community hub is partnered with.
  const [partnerLocation, setPartnerLocation] = useState(
    Object.keys(pickupLocations)[0],
  );
  // What kind of community hub this is, publicApproval, publicNoApproval, private.
  const [communityHubType, setCommunityHubType] = useState("publicApproval");
  // The name of the community location.
  const [locationName, setLocationName] = useState(
    userInfo.firstName + "'s Community Pickup",
  );
  // Their address.
  const [address, setAddress] = useState("");
  // An address that is close to their home.
  const [obscuredAddress, setObscuredAddress] = useState("");
  // How many people they want max coming to their location.
  const [maxParticipants, setMaxParticipants] = useState("");
  // The available pick up times.
  const [availableTimes, setAvailableTimes] = useState("");
  // Any additional notes about the location.
  const [additionalComments, setAdditionalComments] = useState("");

  // Closes the dialog and resets the fields.
  const handleClose = () => {
    setOpen(false);
    setPartnerLocation(Object.keys(pickupLocations)[0]);
    setCommunityHubType("publicApproval");
    setLocationName(userInfo.firstName + "'s Community Pickup");
    setAddress("");
    setObscuredAddress("");
    setMaxParticipants("");
    setAvailableTimes("");
    setAdditionalComments("");
  };

  return (
    <div style={{ width: "100%", marginRight: "10px" }}>
      <Button
        fullWidth
        style={{ background: "#A6CE39", color: "white" }}
        size="large"
        variant="contained"
        onClick={() => setOpen(true)}
      >
        BECOME A COMMUNITY HOST
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Become a Community Pickup Location
        </DialogTitle>
        {currentUser ? (
          <DialogContent>
            <CommunityHubApplicationForm
              pickupLocations={pickupLocations}
              userInfo={userInfo}
              setSendingEmail={setSendingEmail}
              handleUserInfoChange={handleUserInfoChange}
              handleClose={handleClose}
              partnerLocation={partnerLocation}
              setPartnerLocation={setPartnerLocation}
              communityHubType={communityHubType}
              setCommunityHubType={setCommunityHubType}
              locationName={locationName}
              setLocationName={setLocationName}
              address={address}
              setAddress={setAddress}
              obscuredAddress={obscuredAddress}
              setObscuredAddress={setObscuredAddress}
              maxParticipants={maxParticipants}
              setMaxParticipants={setMaxParticipants}
              availableTimes={availableTimes}
              setAvailableTimes={setAvailableTimes}
              additionalComments={additionalComments}
              setAdditionalComments={setAdditionalComments}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <p>
              In order to become a community host please sign in or sign up
              first please.
            </p>
            <Link style={{ textDecoration: "none" }} to="/SignIn">
              <Button
                variant="contained"
                style={{
                  background: "rgb(225 119 245)",
                  color: "white",
                  margin: "8px 5px",
                }}
                fullWidth
              >
                Sign In
              </Button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/SignUp">
              <Button
                variant="contained"
                style={{
                  background: "rgb(159, 191, 89)",
                  color: "white",
                  margin: "8px 5px",
                }}
                fullWidth
              >
                Sign Up
              </Button>
            </Link>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
