// The is the Frequently Asked Questions page.  It pulls in a document
// that has a {question : answer} format.
import React, { useEffect, useState, useLayoutEffect } from "react";
import Menu from "../components/Menu.js";
import "../styles/ExtraInformation.css";
import Footer from "../components/Footer.jsx";
import firebase from "../components/Firebase.js";
import LoadingContent from "../components/LoadingContent.jsx";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@mui/material/Accordion";

export default function FAQs() {
  const database = firebase.firestore();
  // Says if the FAQ document is loading.
  const [loadFAQs, setLoadFAQs] = useState(false);
  // Holds the FAQ document which is a dictionary
  // {question1 : answer1, question2 : answer2, etc.}
  const [FAQs, setFAQs] = useState({});

  useEffect(() => {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    const FAQDocRef = database.collection("ExtraInformation").doc("FAQs");
    setLoadFAQs(true);
    FAQDocRef.get()
      .then((doc) => {
        if (doc.exists) {
          setFAQs(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        setLoadFAQs(false);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  // When the page loads make sure it loads at the top of the page or to the place
  // the user should be directed to.
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Menu />
      <div className="FAQ-Page" id="home">
        <div className="FAQ-Questions-Text">
          <h1 style={{ textAlign: "center" }}>Frequently Asked Questions</h1>
          {loadFAQs ? (
            <div>
              <LoadingContent inline={true} />
            </div>
          ) : (
            <div style={{ width: "80%" }}>
              {Object.keys(FAQs)
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((question, key) => (
                  <div key={key} style={{ margin: "10px" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>{question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: FAQs[question].replace(/\n/g, "<br />"),
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
