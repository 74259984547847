// This takes in teh orderPaymentHistory and calculates the totals for a given
// order from a specific location for the week.  It then calls the OrderPaymentListItem
// which displays the information on the screen for the user to observe payment
// details for that week.
import React from "react";
import OrderPaymentListItem from "./OrderPaymentListItem.jsx";

// Calculates the order totals for the given week and location.
function CalculateOrderTotals(communityOrders) {
  // The total amount of money collected including overpayment.
  let collected = 0;
  // Total money that is still owed.  If someone owes $5 and someone else overpaid
  // $5 they will not cancel each other out and owed will be $5.00.
  let owed = 0;
  // Total amount that is owed to users.  Again it will add up all the amounts owed
  // to users and will not take into consdierations amounts owed by others.
  let refunds = 0;

  // Cycle through all the orders payment histories.
  Object.keys(communityOrders).forEach((user) => {
    // The total deposited for this specific user.
    let totalDeposited = 0;
    // The total refunds owed to this specific user.
    let totalRefunded = 0;

    // Checks that the deposits is an array because it used to be initialised as a
    // dictionary.
    if (!Array.isArray(communityOrders[user].deposits)) {
      // Set the deposits to an array if it was a dictionary.
      communityOrders[user].deposits = [];
    }
    // Cycle through all the deposits.
    communityOrders[user].deposits.forEach((deposit) => {
      // Add each deposit's amount to the total deposited
      totalDeposited += deposit.amount;
    });

    // Checks that the orderRefunds is an array because it used to be initialised as a
    // dictionary.
    if (!Array.isArray(communityOrders[user].orderRefunds)) {
      // Set the orderRefunds to an array if it was a dictionary.
      communityOrders[user].orderRefunds = [];
    }
    // Cycle through all the orderRefunds.
    communityOrders[user].orderRefunds.forEach((refund) => {
      // Add each orderRefunds's amount to the total refunded
      totalRefunded += refund.amount;
    });

    // Calculate the total amount collected from the user.
    collected += totalDeposited - totalRefunded;

    // Calculate the final balance of this user.
    const finalBalance =
      communityOrders[user].totalOwed - totalDeposited + totalRefunded;
    // If the final balance is less then -0.04 then the user is owed a refund.
    if (finalBalance < -0.04) {
      // Add to the total refunds for this distributionLocation's order.
      refunds += finalBalance;
    }
    // If the final balance is greater then 0.04 then the user owed money.
    else if (finalBalance > 0.04) {
      // Add to the total owed for this distributionLocation's order.
      owed += finalBalance;
    }
  });

  // Total collected, owed and needed to be refunded for this location.
  return [collected, owed, refunds];
}

export default function OrderPaymentList({ orderPaymentHistory, userInfo }) {
  // The payment history with an array of each order date and it's details.
  const paymentHistorySimplified = [];
  // Cycle through the each order in orderPaymentHistory.  This order can include
  // both this distribution location's orders and imported orders.
  Object.keys(orderPaymentHistory).forEach((order) => {
    // There are multiple fields in the orderPayment that we don't need to make use
    // of such as the exists fields so make sure it is one that has the communityOrders
    // or the importedOrder.
    if (
      orderPaymentHistory[order].communityOrders ||
      orderPaymentHistory[order].importedOrder
    ) {
      // The object which includes all the payemnt details of this order.
      let paymentObject = {};
      // Check to see if the order was imported or not.
      if (orderPaymentHistory[order].communityOrders) {
        // Set the community orders to the community orders of the payment history.
        paymentObject.communityOrders = {
          ...orderPaymentHistory[order].communityOrders,
        };
        // Calculate the order totals for the week.
        const [collected, owed, refunds] = CalculateOrderTotals(
          paymentObject.communityOrders,
        );
        // Set the total collected.
        paymentObject.collected = collected;
        // Set the total owed.
        paymentObject.owed = owed;
        // Set the total refunds.
        paymentObject.refunds = refunds;
        // Set the imported to false.
        paymentObject.imported = false;
        // Set the distributedDate to the date of the hand out of the order.
        paymentObject.distributedDate = orderPaymentHistory[order].selectedDate;
        // Set the distributed location to the current organisationName.
        paymentObject.distributionLocation = userInfo.organisationName;
        // Set the order date to the date the order was placed.
        paymentObject.orderDate = order;
        // Add the payment object to the list of payment histories.
        paymentHistorySimplified.push(paymentObject);
      }
      // If the order was imported.
      if (orderPaymentHistory[order].importedOrder) {
        // Cycle through the different distribution locations.
        Object.keys(orderPaymentHistory[order].importedOrder).forEach(
          (distributionLocation) => {
            // Reset the paymentObject to a new dictionary.
            paymentObject = {};
            // Set the community orders to the imported community orders of the payment history.
            paymentObject.communityOrders =
              orderPaymentHistory[order].importedOrder[
                distributionLocation
              ].communityOrders;
            // Calculate the order totals for the week.
            const [collected, owed, refunds] = CalculateOrderTotals(
              paymentObject.communityOrders,
            );
            // Set the total collected.
            paymentObject.collected = collected;
            // Set the total owed.
            paymentObject.owed = owed;
            // Set the total refunds.
            paymentObject.refunds = refunds;
            // Set the imported to true.
            paymentObject.imported = true;
            // Set the distributedDate to the date of the hand out of the order.
            paymentObject.distributedDate =
              orderPaymentHistory[order].importedOrder[
                distributionLocation
              ].date;
            // Set the distributed location to the imported location.
            paymentObject.distributionLocation = distributionLocation;
            // Set the order date to the date the order was placed.
            paymentObject.orderDate = order;
            // Add the payment object to the list of payment histories.
            paymentHistorySimplified.push(paymentObject);
          },
        );
      }
    }
  });

  // Sort the payment history so that it is sorted by order Date with the current
  // distribution loation coming first and then the imported orders sorted by alphabetical
  // order.
  paymentHistorySimplified.sort((a, b) => {
    if (b.orderDate === a.orderDate && a.imported && b.imported) {
      return a.distributionLocation > b.distributionLocation ? 1 : -1;
    } else {
      return b.orderDate - a.orderDate;
    }
  });

  return (
    <div>
      {paymentHistorySimplified.map((paymentOrderDetails, i) => (
        <OrderPaymentListItem
          key={i}
          paymentOrderDetails={paymentOrderDetails}
          orderPaymentHistory={orderPaymentHistory}
        />
      ))}
    </div>
  );
}
