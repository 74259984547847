import { type Dayjs } from "dayjs";

// orders are on thursdays
// TODO: get dates of possible orders from database without fetching all orders.
const toThursday = (d: Dayjs) => {
  const thursday = d.add(4 - d.day(), "day").endOf("day");
  return thursday;
};

export default toThursday;
