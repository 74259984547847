// This is used by distribution locations who wish to import the order of another
// distribution location.  This provides a drop down of all the distribution locations
// and then it shows them a calendar which they can select the date they wish to import.
import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
} from "@material-ui/core";
import useDistributionLocations from "../Firestore/useDistributionLocations";
import SimpleSelect from "../userInputs/DropDownMenu.js";
import MaterialUIPickers from "../userInputs/DatePicker.js";
import LoadingContent from "./../LoadingContent.jsx";

export default function SelectImportLocation({
  idx,
  location,
  order,
  updateImportedSelectedDate,
  updateLocation,
  updateOrder,
  handleImportOrder,
}) {
  const { locations, locationDetails, loading } = useDistributionLocations();
  const [selectedDate, setSelectedDate] = useState(null);
  const [showImportOptions, setShowImportOptions] = useState(false);

  // Formats a Date object into the format MM-DD-YYYY
  function handleDateChange(date) {
    if (date === null) {
      return null;
    }
    const formattedDate = new Date(date);

    const y = formattedDate.getFullYear();
    const m = formattedDate.getMonth() + 1;
    const d = formattedDate.getDate();

    setSelectedDate(
      "" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d + "-" + y,
    );
  }

  // Formats a Date object into the format MM-DD-YYYY
  function handleSave() {
    updateImportedSelectedDate(location, selectedDate);
    handleImportOrder(location, locationDetails, selectedDate);
  }

  if (loading) {
    return (
      <div>
        {showImportOptions ? (
          <Button onClick={() => setShowImportOptions(!showImportOptions)}>
            Hide Import Options
          </Button>
        ) : (
          <Button onClick={() => setShowImportOptions(!showImportOptions)}>
            Show Import Options
          </Button>
        )}
        <Collapse in={showImportOptions}>
          <Card>
            <CardHeader title="Select a Distribution Location to Import Order" />
            <CardContent>
              <SimpleSelect
                margin="dense"
                id="distributionLocationSelect"
                title="Distribution Location"
                options={locations}
                handleChange={(e) => updateLocation(e.target.value, idx)}
                selected={location}
              />
            </CardContent>
            {location !== "Choose a Distribution Location" &&
              locations.length !== 0 && (
                <>
                  <CardHeader title="Select an Order Date" />
                  <CardContent>
                    <MaterialUIPickers
                      required
                      date={selectedDate}
                      handleDateChange={handleDateChange}
                      label="Distribution Date"
                    />
                  </CardContent>
                </>
              )}
            {location !== "Choose a Distribution Location" &&
              selectedDate !== null && (
                <CardActions>
                  <Button
                    onClick={() => {
                      handleSave();
                    }}
                    color="primary"
                  >
                    Submit
                  </Button>
                </CardActions>
              )}
          </Card>
        </Collapse>
      </div>
    );
  } else {
    return (
      <div style={{ width: "150px" }}>
        <LoadingContent inline={true} />
      </div>
    );
  }
}
