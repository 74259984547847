// This function is used to load all the users' emails that have been affected by
// the item being deleted.
import firebase from "../../../../../components/Firebase.js";

export default function LoadAffectedUserEmails(
  communityOrders,
  importedOrder,
  setLoadingEmails,
  setUserEmails,
) {
  const database = firebase.firestore();
  // This is a dictionary that will store the location of this user's ordering such as
  // an imported order. Then it will store the user along with the user's location
  // {firstName-lastName-userId : location}
  const users = {};
  // This stores the users location and their emails. {location : {firstName-lastName-userId : email}}
  const userEmailsTemp = {};
  // Cycle through all the communityOrders (there may be more than one order per person.)
  Object.keys(communityOrders).forEach((order) => {
    // Split up the order into an array [firstName, lastName, userId, dateOfOrder]
    const user = order.split("-");
    // Combine the firstName-lastName-userId
    const name = [user[0], user[1], user[2]].join("-");
    // Make sure that this user and its location are created
    users[name] = "Your Distribution Location";
    // Make sure that this location is created in the userEmails dictionary
    userEmailsTemp["Your Distribution Location"] = {};
  });

  // Cycle through the importedOrders first each location
  Object.keys(importedOrder).forEach((location, i) => {
    // Cycle through each of the communityOrders per location.
    Object.keys(importedOrder[location]).forEach((order) => {
      // Split up the order into an array [firstName, lastName, userId, dateOfOrder]
      const user = order.split("-");
      // Combine the firstName-lastName-userId
      const name = [user[0], user[1], user[2]].join("-");
      // Make sure that this user and its location are created.
      users[name] = location;
      // Make sure that this location is created in the userEmails dictionary.
      userEmailsTemp[location] = {};
    });
  });

  // Cycle through all the newly created users.
  Object.keys(users).forEach((user, i) => {
    // Set the loading emails to true while we load
    setLoadingEmails(true);
    // load the document
    const distributionLocationDocRef = database
      .collection("Users")
      .doc(user.split("-")[2]);
    // Read the document.
    distributionLocationDocRef
      .get()
      .then((doc) => {
        // If the document exists.
        if (doc.exists) {
          // Set the users email temp to {location : {user : email}}
          userEmailsTemp[users[user]][user] = doc.data().email;
        }
        // If this is the last user then set loading emails to done and set the
        // userEmails to the temp value
        if (Object.keys(users).length - 1 === i) {
          setLoadingEmails(false);
          setUserEmails({ ...userEmailsTemp });
        }
      })
      .catch(function (error) {
        setLoadingEmails(true);
        console.log("Error getting document:", error);
      });
  });
}
