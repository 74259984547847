// Takes in the list of farms that provided the exisiting order and a list of
// farms providing the current order.  It combines these two list to make one
// complete list of farmers.
export default function AddFarmsToExisting(existingFarms, newFarms) {
  // combines the two lists into one single list.
  const allFarms = existingFarms.concat(newFarms);

  // Creates a list of the farms with no repeats
  const noRepeatsFarmerInfo = [];
  allFarms.forEach((element) => {
    const result = noRepeatsFarmerInfo.filter(
      (arr) => arr.farmName === element.farmName,
    );
    if (result.length === 0) {
      noRepeatsFarmerInfo.push(element);
    }
  });

  // Returns the no repeat combined list
  return noRepeatsFarmerInfo;
}
