// This button shows a list of the customers that ordered a specific item per location.
// When clicked it list out all the customers and how much of the item they ordered.
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";

export default function CustomersOrderedItem({ food }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="Customers-Per-Item">
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        CUSTOMERS
      </Button>
      {open && (
        <div>
          {Object.keys(food.customers).map((customer, index) => {
            // Split up the customer order into an array [firstName, lastName, userId]
            const user = customer.split("-");

            return (
              <div key={index}>
                {index + 1}. {user[0]} {user[1]} {food.individualDescription}{" "}
                <u style={{ color: "red" }}>x{food.customers[customer]}</u>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
