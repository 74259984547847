// A sorting filter that will sort ALPHABETICALLY, sort alphabetically by farm name, sort by Category and by remaining quantities
import React from "react";
import { ReorderPantryItems } from "../Functions/ReorderPantryItems.js";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CategoryIcon from "@mui/icons-material/Category";
import Button from "@material-ui/core/Button";

export default function PantrySortFilter({ leftovers, setLeftovers }) {
  return (
    <div className="Order-Sort-Options">
      <h6 style={{ marginRight: "5px" }}>Sort: </h6>

      <Button
        variant="outlined"
        startIcon={<SortByAlphaIcon />}
        onClick={() =>
          ReorderPantryItems(
            leftovers,
            setLeftovers,
            true, // Alphabetically
            false,
            false,
            false,
          )
        }
      >
        ALPHABETICALLY
      </Button>

      <Button
        variant="outlined"
        startIcon={<AgricultureIcon />}
        onClick={() =>
          ReorderPantryItems(
            leftovers,
            setLeftovers,
            false,
            true, // By Farm
            false,
            false,
          )
        }
      >
        BY FARM
      </Button>

      <Button
        variant="outlined"
        startIcon={<CategoryIcon />}
        onClick={() =>
          ReorderPantryItems(
            leftovers,
            setLeftovers,
            false,
            false,
            true, // By Category
            false,
          )
        }
      >
        BY CATEGORY
      </Button>

      <Button
        variant="outlined"
        startIcon={<ReceiptLongIcon />}
        onClick={() =>
          ReorderPantryItems(
            leftovers,
            setLeftovers,
            false,
            false,
            false,
            true, // By Remaining
          )
        }
      >
        BY REMAINING
      </Button>
    </div>
  );
}
