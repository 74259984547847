import React from "react";
import ContactInfo from "./../ContactInfo.jsx";
import SimpleSelect from "../../../../components/userInputs/DropDownMenu.js";
import { Button } from "@material-ui/core";

// Opens a new tab with the URL contained in value
function handleListItemClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

// Displays th information of the farms providing the orderDate
export default function RestaurantBasketFarmRow(
  props,
  handleChange,
  contactMethod,
  index,
) {
  if (contactMethod === undefined) {
    contactMethod = "Email";
    handleChange("Email", props.farmName);
  }

  if (props.farmName === "") {
    return null;
  }
  // Possible options to contact the farmer
  const options = ["Email", "Text", "Call", "Other"];
  let deliveryDay = props.deliveryDay[0];
  if (props.deliveryDay.length > 1) {
    props.deliveryDay.forEach((day, index) => {
      if (index < 1) return;
      deliveryDay = deliveryDay + "/" + day;
    });
  }
  let orderCutoffDay = props.orderCutoffDay[0];
  if (props.orderCutoffDay.length > 1) {
    props.orderCutoffDay.forEach((day, index) => {
      if (index < 1) return;
      orderCutoffDay = orderCutoffDay + "/" + day;
    });
  }
  return (
    <div className="Food-Table" key={props + index}>
      <div className="Restaurant-Farm-Cell">{props.farmName}</div>

      <ContactInfo email={props.farmerEmail} number={props.farmNumber} />
      <div className="Farm-Select-Cell">
        <SimpleSelect
          options={options}
          title="Method"
          handleChange={handleChange}
          farmName={props.farmName}
          selected={contactMethod}
        />
      </div>
      <div className="Restaurant-Farm-Cell">{orderCutoffDay}</div>
      <div className="Restaurant-Farm-Cell">{deliveryDay}</div>
      <div className="Restaurant-Farm-Cell">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleListItemClick(props.farmURL)}
        >
          Website
        </Button>
      </div>
    </div>
  );
}
