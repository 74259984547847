// This is called by OrderPaymentListItem where it is a dialog that appears and
// shows the details of deposits, refunds, unpaid and refunds needed of specific
// users.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Grid } from "@material-ui/core";
import UserPaymentDetails from "./../Components/UserPaymentDetails.jsx";

// This function compares the names of the users and sorts them alphabetically.
function SortOrdersAlphabetically(user1, user2) {
  const userName1 = user1.toUpperCase();
  const userName2 = user2.toUpperCase();

  if (userName1 < userName2) {
    return -1;
  }
  if (userName1 > userName2) {
    return 1;
  }
  return 0;
}

function PaymentHistoryDetailsBox(props) {
  const {
    onClose,
    open,
    paymentOrderDetails,
    totalCollected,
    totalOwed,
    totalRefundsNeeded,
    orderPaymentHistory,
  } = props;

  const handleClose = () => {
    onClose(false);
  };

  // Put the list of names and user names of the orders in an list to be sorted.
  const sortedCommunityOrder = Object.keys(paymentOrderDetails.communityOrders);

  // Sort the orders alphabetically.
  sortedCommunityOrder.sort((user1, user2) => {
    return SortOrdersAlphabetically(user1, user2);
  });

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Payment History for {paymentOrderDetails.distributionLocation} from{" "}
        {paymentOrderDetails.distributedDate}{" "}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-around",
          }}
        >
          <h5>
            <u>Total Collected</u>
            <br />${totalCollected}
          </h5>
          <h5>
            <u>Total Owed</u>
            <br />${totalOwed}
          </h5>
          <h5>
            <u>Total Refunds Needed</u>
            <br />${totalRefundsNeeded}
          </h5>
        </div>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          style={{
            borderBottom: "solid",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <Grid item xs>
            Name
          </Grid>
          <Grid item xs>
            Amount
          </Grid>
          <Grid item xs>
            Date
          </Grid>
          <Grid item xs>
            Payment Method
          </Grid>
          <Grid item xs></Grid>
        </Grid>

        {sortedCommunityOrder.map((user) => (
          <UserPaymentDetails
            key={user}
            paymentDetails={paymentOrderDetails.communityOrders[user]}
            user={user}
            paymentOrderDetails={paymentOrderDetails}
            orderPaymentHistory={orderPaymentHistory}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaymentHistoryDetailsBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PaymentHistoryDetailsDialog({
  paymentOrderDetails,
  totalCollected,
  totalOwed,
  totalRefundsNeeded,
  orderPaymentHistory,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <div>
          <Button color="primary" onClick={handleClickOpen}>
            DETAILS
          </Button>
        </div>
      </div>
      <PaymentHistoryDetailsBox
        open={open}
        onClose={handleClose}
        paymentOrderDetails={paymentOrderDetails}
        totalCollected={totalCollected}
        totalOwed={totalOwed}
        totalRefundsNeeded={totalRefundsNeeded}
        orderPaymentHistory={orderPaymentHistory}
      />
    </div>
  );
}
