// handleRefresh is used to update the hooks that contain the farmerInfo and
// the foodItems lists.   When they are updated then the screen will rerender
// with the new lists without having to read the database.
export default function RefreshScreen(
  farm,
  item,
  foodItems,
  setFoodItems,
  handleBasketItems,
  userInfo,
  customerContribution,
  donationSubsidy,
  setDonationSubsidy,
  farmerInfo,
  setFarmerInfo,
) {
  // Copy the foodItems array hook to a new one that can be manipulated
  const arrFood = [...foodItems];

  // Find the first and only index of the food item that has been to been
  // removed
  const index1 = arrFood.findIndex(
    (element) =>
      item ===
      (element.item + element.description + element.farmName).replace(
        /\s+/g,
        "",
      ),
  );
  if (index1 !== -1) {
    // Update the copy by removing the item from the array and update the hook
    arrFood.splice(index1, 1);
    setFoodItems([...arrFood]);
    // update the userInfo.basketItems
    handleBasketItems(index1, true);
  }

  // When an individual user removes an item from their basket there is a risk
  // if they're using a subsidy their total could go negative.
  if (userInfo.userType === "individual") {
    let totalCostTemp = 0;
    // Sum up the total of their order.
    arrFood.forEach((foodItem) => {
      totalCostTemp +=
        foodItem.quantity *
        (foodItem.price /
          (foodItem.distributionQuantity / foodItem.individualQuantity));
    });
    // Check if the user is using a subsidy and if they are that it's not biggger
    // than their order's total.  If it is then set the subsidy to the order total.
    if (customerContribution === "subsidy" && totalCostTemp < donationSubsidy) {
      setDonationSubsidy(totalCostTemp.toFixed(2));
    }
  }

  // Create a new list of farms providing the order starting with an empty array
  const arrFarms = [];
  // Cycle through the current farms providing the order before the remove
  // and check to see if that farm is still providing any ingredients.  If it
  // then add it to the new farm list and set the farm list to equal the new
  // one.
  farmerInfo.forEach((farm) => {
    const testIndex = arrFood.findIndex(
      (food) => farm.farmName === food.farmName,
    );
    if (testIndex !== -1) {
      arrFarms.push(farm);
    }
  });

  setFarmerInfo([...arrFarms]);
}
