import "date-fns";
// eslint-disable-next-line no-unused-vars
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  // eslint-disable-next-line no-unused-vars
  MuiPickersUtilsProvider,
  // eslint-disable-next-line no-unused-vars
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function MaterialUIPickers(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        format="MM/dd/yyyy"
        margin="normal"
        shouldDisableDate={props.disableDates}
        disabled={props.calendarDisabled}
        label={props.label}
        value={props.date}
        onChange={props.handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
