// Updates the distributedFoodItems to include any updates and saves it to the database
// of this order.
import firebase from "../../../components/Firebase.js";

export default function SaveDistributedFoodItems(
  foodItem,
  orderLog,
  updateOrderLog,
  orderIndex,
  locationAmounts,
  takeFromPantry,
) {
  // The temp orderLog
  const orderLogTemp = [...orderLog];
  const database = firebase.firestore();
  const batch = database.batch();

  // Checks to see if distributedFoodItems already existed.
  if (!orderLogTemp[orderIndex].distributedFoodItems) {
    // The distributedFoodItems are set to a blank dictionary.
    orderLogTemp[orderIndex].distributedFoodItems = {};
  }

  // Cycle through the location amounts for each location.
  Object.keys(locationAmounts).forEach((location) => {
    // Set the index to -1 assuming the distributedFoodItem doesn't exist.
    let index = -1;
    // The distributed foodItems location doesn't exist then set the blank arrray.
    if (!orderLogTemp[orderIndex].distributedFoodItems[location]) {
      orderLogTemp[orderIndex].distributedFoodItems[location] = [];
    } else {
      // Find the index of the item in the distributedFoodItems.
      index = orderLogTemp[orderIndex].distributedFoodItems[location].findIndex(
        (distributedFoodItem) => {
          return (
            distributedFoodItem.item === foodItem.item &&
            distributedFoodItem.farmName === foodItem.farmName &&
            distributedFoodItem.individualDescription ===
              foodItem.individualDescription &&
            !!distributedFoodItem.limit === !!foodItem.limit
          );
        },
      );
    }
    // Set the foodItem distributed to the locationAmounts.
    foodItem.distributed = locationAmounts[location];

    // If the item exists and the user set the value to 0.
    if (index !== -1 && locationAmounts[location] === 0) {
      // If this is the only item that this location distributed then remove the
      // location completely.
      if (
        orderLogTemp[orderIndex].distributedFoodItems[location].length === 1
      ) {
        orderLogTemp[orderIndex].distributedFoodItems[location] = [];
      }
      // If this isn't the last item then just delete this item.
      else {
        orderLogTemp[orderIndex].distributedFoodItems[location].splice(
          index,
          1,
        );
      }
    }
    // If the item exists and is greater then 0 just update then exisiting item to the new
    // one.  It has the updated distributed amount.
    else if (index !== -1) {
      orderLogTemp[orderIndex].distributedFoodItems[location][index] = {
        ...foodItem,
      };
    }
    // If the item is newly distributed then push it to this location's hand out.
    else {
      if (locationAmounts[location] > 0) {
        orderLogTemp[orderIndex].distributedFoodItems[location].push({
          ...foodItem,
        });
      }
    }
  });

  updateOrderLog([...orderLogTemp]);

  const usersFoodListDocRef = database
    .collection("Users")
    .doc(orderLogTemp[orderIndex].owner)
    .collection("Orders")
    .doc(orderLogTemp[orderIndex].orderDate);

  // Update the database.
  batch.update(usersFoodListDocRef, {
    distributedFoodItems: { ...orderLogTemp[orderIndex].distributedFoodItems },
  });

  batch.commit();
}
