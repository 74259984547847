// Called when a user's order is modified.  If the user has their order modified
// where the price of their food total has changed then we need to reevaluate the
// cost of the volunteer fee.
import firebase from "../../../components/Firebase.js";

export default function AdjustVolunteerFee(
  orderTotal,
  communityOrder,
  user,
  userInfo,
  volunteerFeeChanges,
  database,
  batch,
) {
  // Check to see how much change has happened.  Update to include the volunteerFee
  // and not this hardcoded value.
  let distributionVolunteerPercentage = userInfo.volunteerPercentage / 100;
  // If the volunteer percentage isn't defined then set it to 0.
  if (distributionVolunteerPercentage === undefined) {
    distributionVolunteerPercentage = 0;
  }
  // The cash portion of the volunteerFee.
  const volunteerCashPortion = parseFloat(communityOrder.cashPortion);
  // The credit portion of the volunteerFee.
  const volunteerCreditPortion = parseFloat(communityOrder.creditPortion);
  // The new volunteer fee that is charged to this user's order.
  const volunteerFeeNew = parseFloat(
    orderTotal * distributionVolunteerPercentage,
  );
  // The old volunteer fee charged which is the credit and cash portions added together.
  const volunteerFeeOld = volunteerCreditPortion + volunteerCashPortion;
  // The change in volunteer fee for this order.
  const volunteerPriceChange = volunteerFeeNew - volunteerFeeOld;
  // This is the userKey as the user may have placed multiple orders and so we want
  // to add up all their changed fees and orderNotes so by removing the orderDate
  // we will just have the user.
  const userKey = [
    user.split("-")[0],
    user.split("-")[1],
    user.split("-")[2],
  ].join("-");

  // Check to see if this user has already been added to the volunteerFeeChange dictionary.
  // It would already exist if there was multiple orders that were updated for this user.
  if (volunteerFeeChanges[userKey] === undefined) {
    volunteerFeeChanges[userKey] = { addCash: 0, orderNotes: "" };
  }

  // Create a variable to store the userId.
  const userId = user.split("-")[2];

  // If the price change is greater than 0 then we know that we are adding to the
  // users order.
  if (volunteerPriceChange > 0) {
    // Since the volunteer fee went up then we will just add it to the cash portion.
    communityOrder.cashPortion = (
      volunteerPriceChange + volunteerCashPortion
    ).toFixed(2);
    // This is the total of cash portion added as there may be multiple user orders
    // and we want to keep a tally of the total across them all.
    volunteerFeeChanges[userKey].addCash += volunteerPriceChange;
    // Add the order notes of the total fees added.  We know that the no matter how
    // many orders the user has placed when the volunteerPriceChange is greater than 0
    // that will never change and we will always be adding to the order.
    volunteerFeeChanges[
      userKey
    ].orderNotes = `Updated volunteer fee and added $${volunteerFeeChanges[
      userKey
    ].addCash.toFixed(2)} to the volunteer cash portion paid. `;
  }
  // If the price change is less than 0 that means the user's volunteer will go down.
  // A refund will be needed and we will first refund the cash and then the credits.
  else if (volunteerPriceChange < 0) {
    // This is the difference between the cash portion and the change in volunteer
    // fee.
    const cashPortionDifference = volunteerCashPortion + volunteerPriceChange;
    // If the cash portion the difference is less than 0 we will also have to update
    // the credits otherwise updating the volunteerCashPortion is all we do.
    if (cashPortionDifference >= 0) {
      // Reduce the cash portion by the amount of the price change.
      communityOrder.cashPortion = (
        volunteerPriceChange + volunteerCashPortion
      ).toFixed(2);
      // Reduce the total owed by the price change.
      volunteerFeeChanges[userKey].addCash += volunteerPriceChange;
      // Add the refunds to the order notes about the cash refunds.
      volunteerFeeChanges[userKey].orderNotes = volunteerFeeChanges[
        userKey
      ].orderNotes.concat(
        `Refunded ${
          communityOrder.orderDate
        } the volunteer cash portion by $${(-volunteerPriceChange).toFixed(
          2,
        )}.  `,
      );
    }
    // If the volunteer price change is more than the cash paid by the user we
    // also have to issue a credit refund.
    else {
      // Remove the volunteerCashPortion from the total owed whatever that is.
      volunteerFeeChanges[userKey].addCash -= volunteerCashPortion;
      // Add the refunds to the order notes about the cash refunds.
      volunteerFeeChanges[userKey].orderNotes = volunteerFeeChanges[
        userKey
      ].orderNotes.concat(
        `Refunded ${
          communityOrder.orderDate
        } the volunteer fee cash portion by $${volunteerCashPortion.toFixed(
          2,
        )}`,
      );
      // Set the cash portion to 0 since we refunded it all or it was already 0.
      communityOrder.cashPortion = "0.00";
      // Set the portion of credits to be refunded to the absolute value of the cash
      // difference so that it's a positive amount to be refunded.
      let creditPortionRefund = Math.abs(cashPortionDifference);

      // Check to make sure that there is still enough credits available.
      if (volunteerCreditPortion + cashPortionDifference >= 0) {
        // Subtract the difference from the credit portion and update.
        communityOrder.creditPortion = (
          cashPortionDifference + volunteerCreditPortion
        ).toFixed(2);
        // Add the refunds to the order notes about the credit refunds.
        volunteerFeeChanges[userKey].orderNotes = volunteerFeeChanges[
          userKey
        ].orderNotes.concat(
          ` and refunded the volunteer fee credit portion by ${-cashPortionDifference.toFixed(
            2,
          )}. `,
        );
      }
      // If there wasn't enough credits then we will just refund the credit portion
      // they paid as they hadn't orignally paid the full volunteer fee.
      else {
        // The refund will just be all the credits they used to cover this order.
        creditPortionRefund = volunteerCreditPortion;
        // Add the refunds to the order notes about the cash refunds.
        volunteerFeeChanges[userKey].orderNotes = volunteerFeeChanges[
          userKey
        ].orderNotes.concat(
          ` and refunded the volunteer fee credit portion by ${volunteerCreditPortion.toFixed(
            2,
          )}. `,
        );
        // The user spent no credits as it's all refunded.
        communityOrder.creditPortion = "0.00";
      }

      // If the user has a credit refund then we will update their account right
      // away to include their credits.
      if (creditPortionRefund > 0) {
        const docRef = database.collection("Users").doc(userId);

        // Refund the volunteerCreditPortion of this user's total
        batch.update(docRef, {
          credits: firebase.firestore.FieldValue.increment(creditPortionRefund),
        });
      }
    }
  }
}
