// Takes in the foodItems that were ordered, the customerFoodList and the userInfo and
// returns a list of items that would be leftover from this order.
export default function CreateLeftoverList(
  foodItems,
  customerFoodList,
  userInfo,
) {
  // Creates an array containing each item that is leftover.
  const leftoverFoodList = [];

  // Cycles through each foodItem and determines which items are leftovers
  foodItems.forEach((foodItemMaster) => {
    // Make a copy of this to be updated so that the original isn't modified.
    const foodItem = { ...foodItemMaster };
    // Check to see if the current orderfoodItem was preordered by customers.  If
    // it is then take the index to compare the preordered amount with the total ordered.
    const index = customerFoodList.findIndex((customerFoodItem) => {
      return (
        customerFoodItem.item === foodItem.item &&
        customerFoodItem.individualDescription ===
          foodItem.individualDescription &&
        customerFoodItem.farmName === foodItem.farmName
      );
    });

    // The leftover amount of food.
    let leftoverQuantity = 0;

    // If the index exists then remove the amount of preordered food from the distributed
    // total amount otherwise the distribution amount is just equal to the total bought.
    if (index !== -1) {
      leftoverQuantity = parseFloat(
        (foodItem.quantity * foodItem.distributionQuantity) /
          foodItem.individualQuantity -
          customerFoodList[index].quantity,
      );
    } else {
      leftoverQuantity = parseFloat(
        (foodItem.distributionQuantity * foodItem.quantity) /
          foodItem.individualQuantity,
      );
    }

    // If the leftover amount is greater than 1 then add it to the pantry.  It's not
    // equal to 1 because it's better to have a little extra then order exactly the amount
    // preordered and then have an item run out before packaging all the items.
    if (leftoverQuantity > 1) {
      foodItem.limit = leftoverQuantity;
      foodItem.pantryLocation = userInfo.organisationName;
      leftoverFoodList.push(foodItem);
    }
  });

  return leftoverFoodList;
}
