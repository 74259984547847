import React from "react";

// The header titles for the columns of the basket information displayed
export default function RestaurantBasketTableHeader() {
  return (
    <div className="Food-Table">
      <div className="Restaurant-Basket-Table-Header">
        <h2 className="Header-2">Item</h2>
      </div>
      <div className="Restaurant-Basket-Table-Header">
        <h2 className="Header-2">Description</h2>
      </div>
      <div className="Restaurant-Basket-Table-Header">
        <h2 className="Header-2">Farm Name</h2>
      </div>
      <div className="Restaurant-Basket-Table-Header">
        <h2 className="Header-2">Quantity</h2>
      </div>
      <div className="Restaurant-Basket-Table-Header">
        <h2 className="Header-2">Remove</h2>
      </div>
    </div>
  );
}
