// This dialog is created when a user applies to be a community pick up location.
// The browser needs time to send an email confirmation and so once that has been
// send it will close.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../styles/MyAccount.css";

function SendingApplicationBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Sending Application</DialogTitle>
      <DialogContent>
        <span className="Placing-Order-Content">
          Please wait while we finish up.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

SendingApplicationBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function SendingApplicationDialog(props) {
  const open = props.open;

  return (
    <div>
      <SendingApplicationBox open={open} />
    </div>
  );
}
