// Called by AddCommunityOrderButton.jsx this function will add a user to both
// the DistributionLocations Collection and to the individual user's order.
import YYYYMMDDConverter from "../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";
import firebase from "../../../components/Firebase.js";

export default async function CreateCommunityMemberOrder(
  userInfo,
  distributionDate,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Sets the pickup location to the user's pickup location
  let pickupLocation = userInfo.pickupLocation;
  // Sets the communityPickup to an empty array.
  let communityPickup = [];
  // Checks if the user has selected a community location.
  if (pickupLocation.pickupLocation !== undefined) {
    // If it has then update the community Pickup
    communityPickup = pickupLocation;
    // If it has then update the distibution location.
    pickupLocation = pickupLocation.pickupLocation;
  }

  // Write to the Order collection in the database
  const now = YYYYMMDDConverter();
  const ordersDocRef = database
    .collection("Users")
    .doc(userInfo.userId)
    .collection("Orders")
    .doc(now);

  // Reference to the community orders collection
  const communityOrdersCollectionRef = database
    .collection("DistributionLocations")
    .doc(pickupLocation.name)
    .collection("CommunityOrders");

  let distributionLocationOrdersDocRef;

  // Check if a document with the matching distributionDate already exists
  try {
    const querySnapshot = await communityOrdersCollectionRef
      .where("distributionDate", "==", distributionDate)
      .where("orderCount", "<=", 65)
      .get();
    if (querySnapshot.empty) {
      // No document exists, create a new one with distributionLocationPickupDate as the document ID
      distributionLocationOrdersDocRef = communityOrdersCollectionRef.doc(now);
      batch.set(distributionLocationOrdersDocRef, {
        distributionDate,
        orderCount: 1,
      });
    } else {
      // Document exists, use the first matching document
      distributionLocationOrdersDocRef = querySnapshot.docs[0].ref;
      batch.update(distributionLocationOrdersDocRef, {
        orderCount: firebase.firestore.FieldValue.increment(1),
      });
    }
  } catch (error) {
    console.error("Error checking for existing document:", error);
  }

  batch.set(ordersDocRef, {
    orderDate: now,
    foodList: [],
    farmerList: [],
    contactMethods: {},
    pickupLocation,
    donationSubsidy: parseFloat(0),
    customerContribution: "donation",
    communityPickup,
    cashPortion: "0.00",
    creditPortion: "0.00",
    phoneNumber: userInfo.phoneNumber,
  });
  batch.set(
    distributionLocationOrdersDocRef,
    {
      [userInfo.firstName +
      "-" +
      userInfo.lastName +
      "-" +
      userInfo.userId +
      "-" +
      now]: {
        orderDate: now,
        foodList: [],
        farmerList: [],
        donationSubsidy: parseFloat(0),
        customerContribution: "donation",
        communityPickup,
        cashPortion: "0.00",
        creditPortion: "0.00",
        phoneNumber: userInfo.phoneNumber,
      },
    },
    { merge: true },
  );

  // commit the changes to the database.
  batch.commit();
}
