// Called after a distribution Location places an order.  It Cycles through the placed
// order and determines automatically which items are leftover when compared with the
// orders that came in from the community.
import firebase from "./../Firebase.js";
import AggregateCustomerList from "../../functions/AggregateOrder.js";
import OrderIncompleteRestoreItems from "../../pages/BasketPage/Functions/OrderIncompleteRestoreItems.js";
import Swal from "sweetalert2";

export default function AddLeftoversToPantry(
  foodItems,
  importedOrder,
  communityOrders,
  userInfo,
  checkList,
  setAddingToPantry,
  setImportedAddingToPantry,
  foodItemsUpdated,
  leftoversToAdd,
) {
  const database = firebase.firestore();

  // Creates an array containing each item that is leftover.
  const leftoverFoodList = [...leftoversToAdd];

  // If we've already got leftoverFoodList then we don't need to recalculate it since
  // we're adding to an existing order.
  if (leftoverFoodList.length === 0) {
    // A dictionary with the combined community and imported orders
    let communityAndImportedOrder = {};
    // Checks to see if the imported Order exists and if it does then combine the
    // community orders of the current distribution location and the imported one.
    if (Object.keys(importedOrder).length === 0) {
      communityAndImportedOrder = communityOrders;
    } else {
      // Start with adding the communityOrders of the distribution location itself.
      communityAndImportedOrder = communityOrders;
      // Cycle through all the distribution locations imported by this order and
      // add their community orders to the communityAndImportedOrder
      Object.keys(importedOrder).forEach((location) => {
        communityAndImportedOrder = Object.assign(
          {},
          communityAndImportedOrder,
          importedOrder[location].communityOrders,
        );
      });
    }

    // Aggregate the customer List to create one consolidated list of food.
    const [, , customerFoodList] = AggregateCustomerList(
      communityAndImportedOrder,
      null,
      null,
      null,
      null,
      true,
    );

    // Cycles through each foodItem and determines which items are leftovers
    foodItems.forEach((foodItemMaster) => {
      // Make a copy of this to be updated so that the original isn't modified.
      const foodItem = { ...foodItemMaster };
      // Check to see if the current orderfoodItem was preordered by customers.  If
      // it is then take the index to compare the preordered amount with the total ordered.
      const index = customerFoodList.findIndex((customerFoodItem) => {
        return (
          customerFoodItem.item === foodItem.item &&
          customerFoodItem.individualDescription ===
            foodItem.individualDescription &&
          customerFoodItem.farmName === foodItem.farmName
        );
      });

      // The leftover amount of food.
      let leftoverQuantity = 0;

      // If the index exists then remove the amount of preordered food from the distributed
      // total amount otherwise the distribution amount is just equal to the total bought.
      if (index !== -1) {
        leftoverQuantity = parseFloat(
          (foodItem.quantity * foodItem.distributionQuantity) /
            foodItem.individualQuantity -
            customerFoodList[index].quantity,
        );
      } else {
        leftoverQuantity = parseFloat(
          (foodItem.distributionQuantity * foodItem.quantity) /
            foodItem.individualQuantity,
        );
      }

      // If the leftover amount is greater than 1 then add it to the pantry.  It's not
      // equal to 1 because it's better to have a little extra then order exactly the amount
      // preordered and then have an item run out before packaging all the items.
      if (leftoverQuantity >= 1) {
        foodItem.limit = leftoverQuantity;
        foodItem.pantryLocation = userInfo.organisationName;
        leftoverFoodList.push(foodItem);
      }
    });
  }

  // Update the pantry to include the leftover items
  const pantryDocRef = database
    .collection("Pantry")
    .doc(userInfo.organisationName);
  // Run a transaction as we want to update the pantry list and don't want to make a mistake
  // when updating the items.
  return database
    .runTransaction((transaction) => {
      return transaction.get(pantryDocRef).then((pantryDoc) => {
        // Make sure that the pantry doc exists.
        if (pantryDoc.exists) {
          // Read the document data.
          const pantryDocData = pantryDoc.data();

          // Cycle through the leftover foodList for each item.
          leftoverFoodList.forEach((item) => {
            // Check to see if the farm is checked to add items to the pantry.
            if (checkList.includes(item.farmName)) {
              // Create a pantry reference.
              const pantryItemRef = [
                item.item,
                item.individualDescription,
                item.farmName,
              ].join("");

              // Check if pantryItemRef contains '%' or '~'as we can't save
              // these in the pantry.
              if (!/[~]/.test(pantryItemRef)) {
                // Copy that item to a temp variable.
                const itemTemp = { ...item };
                // Check that the pantryDoc and the pantry reference exists
                if (pantryDocData[pantryItemRef]) {
                  // Update the limit to include the new items being added to the old.
                  itemTemp.limit =
                    parseInt(item.limit) +
                    parseInt(pantryDocData[pantryItemRef].limit);
                  // Update the quantity so that it includes the total amount that was bought.
                  itemTemp.quantity =
                    parseInt(item.quantity) +
                    parseInt(pantryDocData[pantryItemRef].quantity);
                }
                // Update the pantryDoc with the new item.
                pantryDocData[pantryItemRef] = { ...itemTemp };
              } else {
                console.log("Contains banned character ~");
              }
            }
          });
          // Attempt to apply the update.
          transaction.update(pantryDocRef, pantryDocData);
        } else {
          // eslint-disable-next-line no-throw-literal
          throw "Pantry document does not exist!";
        }
      });
    })
    .then(() => {
      // Close the dialog that is adding the pantry.
      setAddingToPantry(false);
    })
    .catch((err) => {
      // In case there is an error with adding these items to the pantry we want
      // to add back the items that we took from the limited items.
      OrderIncompleteRestoreItems(foodItemsUpdated);
      // Send an alert to the user to tell them of the error.
      Swal.fire(err);
      // Return false that the transaction failed.
      return false;
    });
}
