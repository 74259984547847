// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import Menu from "../components/Menu.js";
// eslint-disable-next-line no-unused-vars
import DynamicForm from "../components/userForms/RestaurantMenuForm.js";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";

function EditMenu() {
  const { restaurantName } = useParams();

  const [menuInfo, setMenuInfo] = useState([{}]);
  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    const database = firebase.firestore();
    const docRef = database.collection("RestaurantMenu").doc(restaurantName);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setMenuInfo(doc.data());
          setLoading(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(true);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        // setLoading(true)
      });
  }, [restaurantName]);

  if (loading) {
    // These tests check if the menuInfo has menuHeaders and menuList if not
    // it sets their default values.
    if (menuInfo.menuList == null) {
      setMenuInfo({ ...menuInfo, menuList: [] });
    }
    if (menuInfo.menuHeaders == null) {
      setMenuInfo({ ...menuInfo, menuHeaders: {} });
    }
    return (
      <div>
        <Menu />
        <div className="Page-Market">
          <div className="Page-Header">
            <h1 className="w3-xxlarge Bold-Text">Edit Menu</h1>
          </div>
          <DynamicForm
            userInfo={userInfo}
            menuInfo={menuInfo}
            restaurantName={restaurantName}
          />
        </div>
      </div>
    );
  } else {
    return <h1>Loading</h1>;
  }
}

export default EditMenu;
