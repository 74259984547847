// When a distribution location is placing an order give them an option to update the marketplace message
// for the location and the imported locations and their community hubs.  You can also enable or disable it.
import firebase from "../../../components/Firebase.js";
import UpdateCommunityLocation from "../../../functions/UpdateCommunityLocation.js";

// Update all the database locations.  This is called multiple times per location and imported locations.
function UpdateDatabasePerLocation(
  database,
  batch,
  userInfo,
  locationName,
  distributionDocRef,
  marketplaceMessageEnabled,
  messageUpdate,
) {
  // Update the distribution location's information in the Users collection
  const userDocRef = database.collection("Users").doc(userInfo.userId);

  // The dot location in the document that is being updated. distributionLocation.marketplaceMessageEnabled
  const locationToUpdateMarketplaceMessageEnable = [
    locationName,
    "marketplaceMessageEnabled",
  ].join(".");

  // The dot location in the document that is being updated. DistributionLocation.marketplaceMessage
  const locationToUpdateMarketplaceMessage = [
    locationName,
    "marketplaceMessage",
  ].join(".");

  // Update the database for the DistributionLocations collection and the document Vancouver
  batch.update(distributionDocRef, {
    [locationToUpdateMarketplaceMessageEnable]: marketplaceMessageEnabled,
  });

  // Update the database for DistributionLocations collection and the document Vancouver
  batch.update(distributionDocRef, {
    [locationToUpdateMarketplaceMessage]: messageUpdate,
  });

  // Update the database the distribution location's user document.
  batch.update(userDocRef, { marketplaceMessageEnabled });

  // Update the database the distribution location's user document.
  batch.update(userDocRef, { marketplaceMessage: messageUpdate });

  // Updates any community locations that may exist that have this distribution location as their partner.
  UpdateCommunityLocation(
    userInfo.userId,
    {},
    false,
    false,
    { marketplaceMessage: messageUpdate, marketplaceMessageEnabled },
    true,
  );
}

export default function UpdateMarketplaceMessages(
  userInfo,
  messageUpdate,
  marketplaceMessageEnabled,
  handleUserInfoChange,
  importedOrder,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // References the database where the Distribution Location exist.
  const distributionDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  // Updates the database locations of the signed in distribution location and their
  // community locations.
  UpdateDatabasePerLocation(
    database,
    batch,
    userInfo,
    userInfo.organisationName,
    distributionDocRef,
    marketplaceMessageEnabled,
    messageUpdate,
  );

  // Update the database locations of the imported orders  and their
  // community locations.
  if (importedOrder.length !== 0) {
    // Cycle through all the distribution locations that were imported to this order.
    Object.keys(importedOrder).forEach((locationName) => {
      // Find the index of the location details for this imported order.  We need the details to update
      // the database.
      const importedOrderDetailsIndex = importedOrder[
        locationName
      ].locationDetails.findIndex((locationDetailsTemp) => {
        return locationDetailsTemp.name === locationName;
      });

      // Updates the database locations of the signed in distribution location and their
      // community locations.
      UpdateDatabasePerLocation(
        database,
        batch,
        importedOrder[locationName].locationDetails[importedOrderDetailsIndex],
        locationName,
        distributionDocRef,
        marketplaceMessageEnabled,
        messageUpdate,
      );
    });
  }

  // commit changes to the database.
  batch.commit();

  // Update the userInfo message.
  userInfo.marketplaceMessage = messageUpdate;
  userInfo.marketplaceMessageEnabled = marketplaceMessageEnabled;
  handleUserInfoChange({ ...userInfo }, true);
}
