import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import Popover from "@material-ui/core/Popover";
// eslint-disable-next-line no-unused-vars
import Typography from "@material-ui/core/Typography";
// eslint-disable-next-line no-unused-vars
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    maxWidth: "400px",
  },
}));

export default function SimplePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <button
        className="Naked-Button"
        aria-describedby={id}
        onClick={handleClick}
      >
        <InfoTwoToneIcon color="primary" />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Typography className={classes.typography}>{props.content}</Typography>
      </Popover>
    </div>
  );
}
