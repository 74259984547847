// This accordion will open up the order details for a specific community hub.  It will
// have all the items at this location.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomersOrderedItem from "../CustomersOrderedItem.jsx";
import { withStyles } from "@material-ui/core/styles";

const Accordion = withStyles({
  root: {
    background: "#fffee0",
  },
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    flexDirection: "column",
  },
})(MuiAccordionDetails);

export default function WholeOrderAccordion({
  distributionLocation,
  pickupLocation,
  customerFoodListPerLocation,
  showCustomersButton,
}) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5>
            <b>
              Order from {distributionLocation} for {pickupLocation}
            </b>
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          {Object.keys(
            customerFoodListPerLocation[distributionLocation][pickupLocation],
          ).map((foodSource, j) => (
            <div key={j}>
              {customerFoodListPerLocation[distributionLocation][
                pickupLocation
              ][foodSource].length > 0 && (
                <>
                  {foodSource === "orderedItems" ? (
                    <h5>
                      <u>Newly Ordered Items</u>
                    </h5>
                  ) : (
                    <h5>
                      <u>Pantry Items</u>
                    </h5>
                  )}
                </>
              )}

              {customerFoodListPerLocation[distributionLocation][
                pickupLocation
              ][foodSource].map((food, itemNumber) => {
                // The price of the item for the individual.
                let individualPrice = parseFloat(
                  food.price /
                    (food.distributionQuantity / food.individualQuantity),
                );
                // The individual description of the food item for the user to know how
                // much they are purchasing.
                let individualDescription = food.individualDescription;
                // if suggestedPrice is true then we want to set the price to 0
                if (food.suggestedPrice) {
                  // First add to the description the suggested Donation.
                  individualDescription = individualDescription.concat(
                    " - Suggested Donation of $",
                    individualPrice,
                  );
                  // Then reset the prices to 0.
                  individualPrice = 0.0;
                }

                if (food.packageFee > 0) {
                  individualPrice += parseFloat(food.packageFee);
                }
                // eslint-disable-next-line no-unused-vars
                const individualPriceTotal = parseFloat(
                  individualPrice * food.quantity,
                ).toFixed(2);
                individualPrice = individualPrice.toFixed(2);
                return (
                  <span key={itemNumber}>
                    <div style={{ border: "solid 1px" }}>
                      <div>
                        <p style={{ margin: "0px 0px 0px 20px" }}>
                          {itemNumber + 1}. {food.farmName}{" "}
                          <strong>{food.item}</strong> {individualDescription}{" "}
                          <u style={{ color: "red" }}>x{food.quantity}</u> -- $
                          {individualPrice}
                          {!!food.limit && (
                            <img
                              style={{ marginLeft: "5px", height: "15px" }}
                              src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
                            />
                          )}
                          {food.packageFee > 0 && (
                            <span className="Item-Package-Fee">
                              *incl pkg fee $
                              {parseFloat(food.packageFee).toFixed(2)}/per item
                            </span>
                          )}
                        </p>
                        {showCustomersButton && (
                          <CustomersOrderedItem food={food} />
                        )}
                      </div>
                    </div>
                  </span>
                );
              })}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
