// This function will total up each farms Packaging total value and output
// dictionary with "farmName" : "$TotalFees"
export default function PackageTotals(foodList) {
  const farmsPackaging = {};
  // First go throuh all the foodItems and initialise the farm providing that
  // item to $0.
  foodList.forEach((food) => {
    farmsPackaging[food.farmName] = 0;
  });

  // Cycle through all the foodItems again find the product of the quantity
  // and the packageFee of the food item and then add it to the farmsPackaging total
  foodList.forEach((food) => {
    // This if statement is included for orders that occured before the database
    // changes to the foodItems to include the individual and distribution amounts
    if (food.packageFee > 0) {
      farmsPackaging[food.farmName] += food.quantity * food.packageFee;
    }
  });
  return farmsPackaging;
}
