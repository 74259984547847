import React from "react";
import ContactInfo from "./ContactInfo.jsx";
import { Button } from "@material-ui/core";

// Opens a new tab with the URL contained in value
function handleListItemClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

// Displays the information of the farms providing the orderDate
export default function BasketFarmRow(
  props,
  handleChange,
  contactMethod,
  index,
  userInfo,
) {
  // Determines the CSS used for the farm cells
  let farmCell = "";
  // Assigns the minimumOrder of the farm to a variable with two decimal places
  const minimumOrder = parseFloat(props.minimumOrder).toFixed(2);

  // Set Email to the default contact method if no contactMethod has been specified
  if (contactMethod === undefined) {
    contactMethod = "Email";
    handleChange("Email", props.farmName);
  }

  if (props.farmName === "") {
    return null;
  }
  // Possible options to contact the farmer
  // const options = ["Email", "Text", "Call", "Other"]
  let deliveryDay = props.deliveryDay[0];
  if (props.deliveryDay.length > 1) {
    props.deliveryDay.forEach((day, index) => {
      if (index < 1) return;
      deliveryDay = deliveryDay + "/" + day;
    });
  }
  let orderCutoffDay = props.orderCutoffDay[0];
  if (props.orderCutoffDay.length > 1) {
    props.orderCutoffDay.forEach((day, index) => {
      if (index < 1) return;
      orderCutoffDay = orderCutoffDay + "/" + day;
    });
  }

  // Depending on the type of user set the CSS for the farmCell
  if (userInfo.userType === "individual") {
    farmCell = "Individual-Farm-Cell";
  }

  if (
    userInfo.userType === "distributionLocation" ||
    userInfo.userType === "admin"
  ) {
    farmCell = "Distribution-Farm-Cell";
  }

  return (
    <div key={index}>
      {userInfo.userType !== "individual" ? (
        <div className="Food-Table" key={props + index}>
          <div className={farmCell}>{props.farmName}</div>
          <ContactInfo
            email={props.farmerEmail}
            number={props.farmNumber}
            userType={userInfo.userType}
          />
          <div className={farmCell}>${minimumOrder}</div>
          <div className={farmCell}>{orderCutoffDay}</div>
          <div className={farmCell}>{deliveryDay}</div>
          <div className={farmCell}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleListItemClick(props.farmURL)}
            >
              Website
            </Button>
          </div>
        </div>
      ) : (
        <div className="Food-Table" key={props + index}>
          <div className={farmCell}>{props.farmName}</div>
          <div className={farmCell}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleListItemClick(props.farmURL)}
            >
              Website
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
