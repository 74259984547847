// This function will calcualate the amounts owed by a user who's order has been
// updated.  It will take in the original cost of the user's orders and their new order
// and then calculate the difference and write to the database.
import firebase from "../../../components/Firebase.js";
import YYYYMMDDConverter from "../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";

export default function PriceChangesPaymentHistory(
  orderDate,
  originalOrderCosts,
  updatedOrderCosts,
  importedOriginalOrderCosts,
  importedUpdatedOrderCosts,
  foodItemUpdate,
  originalPriceIndividual,
  newPriceIndividual,
  updatePaymentHistory,
  volunteerFeeChanges,
) {
  // Create a cost difference dictionary {user : 0}
  const orderCostChange = {};

  // Cycle through the original cost of the orders it from the updated order costs.
  // Now we have the difference in the cost of the orders.
  Object.keys(originalOrderCosts).forEach((user) => {
    orderCostChange[user] = updatedOrderCosts[user] - originalOrderCosts[user];
  });

  // The imported Order cost changes.
  const importedOrderCostChange = {};

  // Cycle through the distribution location's that were imported.
  Object.keys(importedOriginalOrderCosts).forEach((distributionLocation) => {
    // Set the Cost change of this distribution location to a dictionary. {user : 0}
    importedOrderCostChange[distributionLocation] = {};
    // Cycle through the original costs and subtract it from the updated costs to
    // get the difference of the order cost.
    Object.keys(importedOriginalOrderCosts[distributionLocation]).forEach(
      (user) => {
        importedOrderCostChange[distributionLocation][user] =
          importedUpdatedOrderCosts[distributionLocation][user] -
          importedOriginalOrderCosts[distributionLocation][user];
      },
    );
  });

  // Make the orderNotes string an empty string.
  let orderNotes = "";

  // If the price of an item was changed then we set the order note to include this information.
  // Otherwise it is a removal of an item and we want to include that message.
  if (originalPriceIndividual) {
    // Set the order note of the item's price being updated.
    orderNotes = `Price adjustment on ${
      foodItemUpdate.item
    } from $${originalPriceIndividual.toFixed(
      2,
    )} to $${newPriceIndividual.toFixed(2)}. `;
  } else {
    // Set the price of the cost of the item per unit.
    const priceIndividual =
      (foodItemUpdate.price / foodItemUpdate.distributionQuantity) *
      foodItemUpdate.individualQuantity;
    // Update the notes to show that an item was removed.
    orderNotes = `${
      foodItemUpdate.item
    } removed from order at $${priceIndividual.toFixed(2)} per ${
      foodItemUpdate.individualDescription
    }. `;
  }

  // Stores the specific dates payment history. Includes the importedOrder, the communityOrders
  // and the selectedDate.  Will be updated and used to update the database.
  let orderDatePaymentHistory = {};
  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // set up the datebase.
  const database = firebase.firestore();
  // eslint-disable-next-line no-unused-vars
  const batch = database.batch();

  // Find the document where this order date is saved.
  const paymentHistoryCollectRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);

  // Pull the document for reading.
  paymentHistoryCollectRef
    .get()
    .then((collection) => {
      // Cycle through the documents but there should only be 1 that matches.
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since we'll be adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database
            .runTransaction((transaction) => {
              // Store the document's reference.
              const paymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(documentName);

              // This code may get re-run multiple times if there are conflicts.
              return transaction
                .get(paymentHistoryDocRef)
                .then((paymentHistoryDoc) => {
                  if (!paymentHistoryDoc.exists) {
                    // eslint-disable-next-line no-throw-literal
                    throw "Document does not exist!";
                  }

                  // Find the year month day of current time to update the order history
                  // with a timestamp of the changes.
                  const now = YYYYMMDDConverter();

                  // Load the full document of this order Date.
                  const documentData = paymentHistoryDoc.data();

                  // Store the current orderDate.
                  orderDatePaymentHistory = paymentHistoryDoc.data()[orderDate];

                  // Store the communityOrders of this date.
                  const communityOrdersPaymentHistory =
                    orderDatePaymentHistory.communityOrders;
                  // Store the importedOrder of this date.
                  const importedCommunityOrdersPaymentHistory =
                    orderDatePaymentHistory.importedOrder;

                  // Cycle through all the communityOrders that have a change to their order.
                  Object.keys(orderCostChange).forEach((user) => {
                    // If the volunteer fee changes exist for this user then we
                    // have to update the amount they owe because of that too.
                    if (volunteerFeeChanges[user] !== undefined) {
                      // Add the new orders amount to their old amount.
                      communityOrdersPaymentHistory[user].totalOwed +=
                        volunteerFeeChanges[user].addCash;
                      // Include the order notes about the volunteer refunds.
                      communityOrdersPaymentHistory[user].orderNotes =
                        communityOrdersPaymentHistory[user].orderNotes.concat(
                          volunteerFeeChanges[user].orderNotes,
                        );
                    }

                    // Add the new orders amount to their old amount.
                    communityOrdersPaymentHistory[user].totalOwed +=
                      orderCostChange[user];
                    // Add this new order's total and current date to the orderHistory.
                    communityOrdersPaymentHistory[user].orderHistory[now] =
                      communityOrdersPaymentHistory[user].totalOwed;
                    // Update the notes to inlcude the additional order added.
                    communityOrdersPaymentHistory[user].orderNotes =
                      communityOrdersPaymentHistory[user].orderNotes.concat(
                        orderNotes,
                      );
                  });

                  // Cycle through all the imported orders that have a change to their order.
                  Object.keys(importedOriginalOrderCosts).forEach(
                    (distributionLocation) => {
                      Object.keys(
                        importedOriginalOrderCosts[distributionLocation],
                      ).forEach((user) => {
                        // If the volunteer fee changes exist for this user then we
                        // have to update the amount they owe because of that too.
                        if (volunteerFeeChanges[user] !== undefined) {
                          // Add the new orders amount to their old amount.
                          importedCommunityOrdersPaymentHistory[
                            distributionLocation
                          ].communityOrders[user].totalOwed +=
                            volunteerFeeChanges[user].addCash;
                          // Include the order notes about the volunteer refunds.
                          importedCommunityOrdersPaymentHistory[
                            distributionLocation
                          ].communityOrders[user].orderNotes =
                            importedCommunityOrdersPaymentHistory[
                              distributionLocation
                            ].communityOrders[user].orderNotes.concat(
                              volunteerFeeChanges[user].orderNotes,
                            );
                        }
                        // Add the new orders amount to their old amount.
                        importedCommunityOrdersPaymentHistory[
                          distributionLocation
                        ].communityOrders[user].totalOwed +=
                          importedOrderCostChange[distributionLocation][user];
                        // Add this new order's total and date to the orderHistory.
                        importedCommunityOrdersPaymentHistory[
                          distributionLocation
                        ].communityOrders[user].orderHistory[now] =
                          importedCommunityOrdersPaymentHistory[
                            distributionLocation
                          ].communityOrders[user].totalOwed;
                        // Update the notes to inlcude the additional order added.
                        importedCommunityOrdersPaymentHistory[
                          distributionLocation
                        ].communityOrders[user].orderNotes =
                          importedCommunityOrdersPaymentHistory[
                            distributionLocation
                          ].communityOrders[user].orderNotes.concat(orderNotes);
                      });
                    },
                  );

                  // Update the Payment History community orders.
                  orderDatePaymentHistory.communityOrders =
                    communityOrdersPaymentHistory;
                  // Update the Payment History imported orders.
                  orderDatePaymentHistory.importedOrder =
                    importedCommunityOrdersPaymentHistory;

                  // Update the full documentName to include the changes for this date.
                  documentData[orderDate] = orderDatePaymentHistory;

                  // Complete the transaction.  If the document has been changed then
                  // this document has to go again.
                  transaction.update(paymentHistoryDocRef, documentData);

                  // Update the paymentHistory dictionary
                  updatePaymentHistory(documentData, orderDate);
                });
            })
            .then(() => {})
            .catch((err) => {
              // Send an alert to the user to tell them of the error.
              // eslint-disable-next-line no-undef
              alert(err);
              // Return false that the transaction failed.
              return false;
            });
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
