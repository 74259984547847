// List the volunteer schedule of an individual user.  Here the user will be able to
// input their credits and check their schedule along with checking how many credits
// they have in their total balance.
import React, { useEffect, useState } from "react";
import LoadingContent from "../../../../components/LoadingContent.jsx";
import firebase from "../../../../components/Firebase.js";
import IndividualVolunteerSchedule from "./IndividualVolunteerSchedule.jsx";

export default function IndividualVolunteeringSchedule({ userInfo }) {
  // Total balance of credits of the user.
  const [credits, setCredits] = useState(userInfo.credits);
  // Whether the schedule is still loading or not.
  const [loading, setLoading] = useState(false);
  // The volunteer schedule the entire period and the most recent one.
  const [volunteerSchedule, setVolunteerSchedule] = useState([]);
  // This stores which was the last three month period we loaded.
  const [lastLoadedQuarter, setLastLoadedQuarter] = useState();

  const database = firebase.firestore();

  // First load the credits of the user and make sure that is updated if they change.
  // Make sure that the credits are saved to two decimals.
  useEffect(() => {
    if (credits === undefined) {
      setCredits(parseFloat(0).toFixed(2));
    } else {
      setCredits(parseFloat(userInfo.credits).toFixed(2));
    }
  }, [userInfo.credits]);

  // Load the volunteer schedule
  useEffect(() => {
    // Load the volunteer Schedule.
    const volunteerDocRef = database
      .collection("VolunteerSchedule")
      .orderBy("timePeriod", "desc")
      .limit(1);

    const volunteerDataTransfer = [];

    // Cycle through all the found documents and add them to the volunteerJobs hook.
    volunteerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            // Push the data that you're reading from the database to the schedule array.
            volunteerDataTransfer.push(doc.data());
            // Save the last quarter which is the current document
            setLastLoadedQuarter(doc);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setVolunteerSchedule([...volunteerDataTransfer]);
        setLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <div>
      <h2>Your Credits Balance : {credits} credits</h2>
      <p>
        If you need to change your schedule or have any questions please email
        us at farmtoplatemarketplace@spec.bc.ca
      </p>
      <p>
        Check out and apply to more volunteer opportunities available by{" "}
        <a style={{ color: "#3366CC" }} href="/CommunityEngagement">
          {" "}
          clicking here
        </a>
      </p>

      {loading ? (
        <IndividualVolunteerSchedule
          volunteerSchedule={volunteerSchedule}
          setVolunteerSchedule={setVolunteerSchedule}
          setLoading={setLoading}
          lastLoadedQuarter={lastLoadedQuarter}
          setLastLoadedQuarter={setLastLoadedQuarter}
        />
      ) : (
        <div>
          <LoadingContent inline={true} />
        </div>
      )}
    </div>
  );
}
