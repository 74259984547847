// The is the Policies page.  It pulls in a document
// that has a {policy title : policy description} format.
import React, { useEffect, useState, useLayoutEffect } from "react";
import Menu from "../components/Menu.js";
import "../styles/ExtraInformation.css";
import Footer from "../components/Footer.jsx";
import firebase from "../components/Firebase.js";
import LoadingContent from "../components/LoadingContent.jsx";

export default function FAQs() {
  const database = firebase.firestore();
  // Says if the policies document is loading.
  const [loadPolicies, setLoadPolicies] = useState(false);
  // Holds the policies document which is a dictionary
  // {policyTitle1 : policyDescription1, policyTitle2 : policyDescription2, etc.}
  const [policies, setPolicies] = useState({});

  useEffect(() => {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    const FAQDocRef = database.collection("ExtraInformation").doc("Policies");
    setLoadPolicies(true);
    FAQDocRef.get()
      .then((doc) => {
        if (doc.exists) {
          setPolicies(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        setLoadPolicies(false);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        setLoadPolicies(false);
      });
  }, []);

  // When the page loads make sure it loads at the top of the page or to the place
  // the user should be directed to.
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Menu />
      <div className="FAQ-Page" id="home">
        <div className="FAQ-Questions-Text">
          <h1 style={{ textAlign: "center" }}>Policies</h1>
          {loadPolicies ? (
            <div>
              <LoadingContent inline={true} />
            </div>
          ) : (
            <div style={{ width: "80%" }}>
              {Object.keys(policies)
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((policy, key) => (
                  <div key={key} style={{ margin: "10px" }}>
                    <h3>{policy}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: policies[policy].replace(/\n/g, "<br />"),
                      }}
                    ></p>
                    <hr
                      style={{ marginBottom: "50px" }}
                      className="Policy-Line-Divider"
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
