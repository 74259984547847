// Includes a full page loading screen and also a smaller inline loading for elements
// that are loaded on the page.
import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

export default function LoadingContent({ inline }) {
  // The size in pixels of progress circle.
  let progressSize = 215;

  // The styling of the Loading div.
  let loadingScreenSize = "Full-Screen-Loading";

  // The size of the logo image.
  let imageSize = "200px";

  // Change the sizing and styling for the inline loading.
  if (inline) {
    progressSize = 115;
    loadingScreenSize = "Inline-Screen-Loading";
    imageSize = "100px";
  }

  // If the page is needs loading per element the do the smaller inline loading vs.
  // the bigger loading that takes up the whole page.
  return (
    <div className={loadingScreenSize}>
      <Box sx={{ m: 1, position: "relative" }}>
        <img
          referrerPolicy="no-referrer"
          style={{ width: imageSize }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLXj1A0JBCwtz49sMvteUQlZx1BiHQ0SbiWHgm230f6lHICeuSpJ6gI0g-O4IgT18j8FqooOuapK7vfXJeceR7pqZjO2eKLQzLfP_ax-yvA2Ui5WdR55vtbTshNIF12XbAqbGkWpLgLGA3o7vXi7zQO-=s789-no?authuser=1"
        />
        <CircularProgress
          size={progressSize}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      </Box>
      <h3 className="Loading-Text">Loading...</h3>
    </div>
  );
}
