// This is called when an item is modified for a distribution location's food list
// which will affect all community orders that have this item.
import AdjustVolunteerFee from "./../AdjustVolunteerFee.js";

export default async function ModifyCommunityOrders(
  ordersToChange,
  database,
  batch,
  distributionLocation,
  orderDate,
  communityOrdersTemp,
  foodItemUpdate,
  updating,
  updateInfo,
  priceUpdate,
  userInfo,
  volunteerFeeChanges,
) {
  // Cycle through the list of orders that need to be modified.  In the communityOrders
  for (const user of ordersToChange) {
    // This is used to find the indivdual's order in the database
    const userId = user.split("-");
    // The indivdual's order also needs to be updated.  This is the reference to it
    // in firebase.
    const userDocRef = database
      .collection("Users")
      .doc(userId[2])
      .collection("Orders")
      .doc(userId[3]);

    // This is used to update the document in the DistributionLocations collection
    let communityOrdersDocRef = null;

    // Update the current distribution locations orders in the DistributionLocations
    // collection
    const communityOrdersRef = database
      .collection("DistributionLocations")
      .doc(distributionLocation)
      .collection("CommunityOrders");

    const querySnapshot = await communityOrdersRef
      .where("distributionDate", "==", orderDate)
      .get();

    // Loop through the documents to find the one with the matching user key
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      // Check if the document contains the user key
      if (user in data) {
        communityOrdersDocRef = doc.ref; // Reference to the found document
      }
    });

    // Finds the index of the item to be updated in the individual customer's orders
    const index = communityOrdersTemp[user].foodList.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName &&
        !!element.limit === !!foodItemUpdate.limit,
    );
    // If we are removing an item from the foodList.
    if (updating === "removeItem") {
      // Removes the item from the indivdual order in the communityOrders dictionary
      communityOrdersTemp[user].foodList.splice(index, 1);
    }
    // If we're changing the price then we will have to update the individual user's price.
    else if (updating === "priceUpdate") {
      // Updates the indivdual order in the communityOrders dictionary
      communityOrdersTemp[user].foodList[index].price = parseFloat(updateInfo);
    } else if (updating === "updateFoodItem") {
      // Make sure you save the user's quantity first.  We don't want the distribuiton
      // location's quantity.
      const quantityTemp = communityOrdersTemp[user].foodList[index].quantity;
      // Updates the indivdual order in the communityOrders dictionary.  Limit doesn't
      // need to be updated since it changes depending on when the user purchased.
      communityOrdersTemp[user].foodList[index] = { ...updateInfo };
      communityOrdersTemp[user].foodList[index].quantity = quantityTemp;
    }

    // If the change affects the price of the user's order then we will have to update
    // the volunteer fee and make sure that the subsidy is still valid and not making the
    // the total negative.
    if (priceUpdate) {
      // The total of this order
      let orderTotal = 0;
      // Cycle through the user's order and add up the total price of the order
      communityOrdersTemp[user].foodList.forEach((foodItem) => {
        orderTotal +=
          foodItem.quantity *
          ((foodItem.price / foodItem.distributionQuantity) *
            foodItem.individualQuantity);
      });
      // Adjust the volunteer fee and update the credits and cash portion and issue credit refunds.
      AdjustVolunteerFee(
        orderTotal,
        communityOrdersTemp[user],
        user,
        userInfo,
        volunteerFeeChanges,
        database,
        batch,
      );
      // Add the cash portion to the order total.
      orderTotal += parseFloat(communityOrdersTemp[user].cashPortion);

      // Cycle through the user's order and add up the packaging fee.  We want to do
      // this after the volunteer fee has been calculated as we only want to calculate
      // the volunteer fees based off the food costs.
      communityOrdersTemp[user].foodList.forEach((foodItem) => {
        // If the package fee is greater than 0 then add it to the total.
        if (foodItem.packageFee > 0) {
          orderTotal += foodItem.packageFee * foodItem.quantity;
        }
      });

      // Add the delivery fee if there is one.
      if (communityOrdersTemp[user].deliveryFee > 0) {
        orderTotal += communityOrdersTemp[user].deliveryFee;
      }

      // If the deliveryFee is undefined, but there is a community pickup then a delivery
      // fee will be added and so we don't want to update the donationSubsidy yet as that will
      // be done when the delivery fee is finalized.
      if (
        !(
          communityOrdersTemp[user].deliveryFee === undefined &&
          communityOrdersTemp[user].communityPickup.pickupLocation !== undefined
        )
      ) {
        // Check that if the user is using a subsidy it is not more than what they’re using
        if (
          communityOrdersTemp[user].customerContribution === "subsidy" &&
          communityOrdersTemp[user].donationSubsidy > orderTotal
        ) {
          communityOrdersTemp[user].donationSubsidy = orderTotal;
        }
      }
    }

    // Check to make sure that the farm still exists after the food Item was removed
    // if it doesn't then remove it as well
    communityOrdersTemp[user].farmerList.forEach((farm, indexOfFarm) => {
      const indexExists = communityOrdersTemp[user].foodList.findIndex(
        (foodItem) => {
          return foodItem.farmName === farm.farmName;
        },
      );
      if (indexExists === -1) {
        communityOrdersTemp[user].farmerList.splice(indexOfFarm, 1);
      }
    });

    // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
    // FirstName-LastName-userId-date
    batch.update(userDocRef, {
      foodList: [...communityOrdersTemp[user].foodList],
      farmerList: [...communityOrdersTemp[user].farmerList],
      donationSubsidy: communityOrdersTemp[user].donationSubsidy,
      cashPortion: communityOrdersTemp[user].cashPortion,
      creditPortion: communityOrdersTemp[user].creditPortion,
    });

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    if (communityOrdersDocRef) {
      batch.update(communityOrdersDocRef, {
        [user.concat(".foodList")]: [...communityOrdersTemp[user].foodList],
        [user.concat(".farmerList")]: [...communityOrdersTemp[user].farmerList],
        [user.concat(".donationSubsidy")]:
          communityOrdersTemp[user].donationSubsidy,
        [user.concat(".cashPortion")]: communityOrdersTemp[user].cashPortion,
        [user.concat(".creditPortion")]:
          communityOrdersTemp[user].creditPortion,
      });
    }
  }
}
