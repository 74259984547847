// Loads Each participating farmer and vendor with a picture of them, write up
// of their organisation and their description.
import React, { useLayoutEffect } from "react";
import Button from "@material-ui/core/Button";

// Opens the farmer's website in a new tab.
function handleWebsiteClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

export default function FarmerWriteUp({ farmer }) {
  // The paragraph description of the farmer.
  let description = farmer.farmDescription;

  // The default image of the farmer if they don't have a photo.
  let farmerImage =
    "https://lh3.googleusercontent.com/pw/AM-JKLXmYdMDBXvEVNtJYNKg0Tp7Gd5WBMDGmZu0kkpcODEdRUv8k4ITDIc0vIc0i6ukg_PmSkD3YB4ydIxzH7hFmwgxrxOBn6hdM4RUMUgUUfgU2XCmLqB7FyoEMMKHZFaqxHP8k5xDujZ3p6e5g_JMlExe=s512-no?authuser=1";

  // If the farmer has a photo then update it so it has the right photo.
  if (farmer.farmerImage) {
    farmerImage = farmer.farmerImage;
  }

  // Splits up the description so that there are separate paragraphs.  The line
  // breaks are described using the "--".
  description = description.split("--");

  // After the page renders then check to see if anything is in the url to point
  // it to a specific farm.
  useLayoutEffect(() => {
    const { hash } = window.location;

    // Remove the # from the url.
    const farmName = hash.substring(1);

    // Find the element.
    const farmId = document.getElementById(farmName);

    // If the element exists then scroll it into view.
    if (farmId !== null) {
      farmId.scrollIntoView();
    }
  }, [farmer]);

  return (
    <div
      className="Farm-Details-Description"
      id={farmer.farmName.replace(/\s/g, "")}
    >
      <div className="Farm-Details">
        <img
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
          src={farmerImage}
          referrerPolicy="no-referrer"
        />
        <h5 className="Farmer-Info-Text"> Farmers : {farmer.farmerName} </h5>
        <h5 className="Farmer-Info-Text"> Email : {farmer.farmerEmail} </h5>
        <h5 className="Farmer-Info-Text">
          {" "}
          Phone Number : {farmer.farmNumber}{" "}
        </h5>
        <h5 className="Farmer-Info-Text">
          {" "}
          Delivery Day(s) : {farmer.deliveryDay[0]}{" "}
        </h5>
        {farmer.farmURL !== undefined && farmer.farmURL !== "" && (
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#a6ce39",
              color: "white",
              width: "200px",
            }}
            onClick={() => handleWebsiteClick(farmer.farmURL)}
          >
            Website
          </Button>
        )}
      </div>
      <span>
        <h3 className="Farmer-Name-Text"> {farmer.farmName} </h3>
        <h5 className="Farmer-Info-Text"> {farmer.farmLocation} </h5>
        {description.map((paragraph, i) => (
          <p className="Farmer-Description-Text" key={i}>
            {" "}
            {paragraph}{" "}
          </p>
        ))}
      </span>
    </div>
  );
}
