// This card is shown when the user enters a community hub's secret code.  The secret
// code will then search to see if this location exists and if it does this card
// will be shown with all the community hub's information.
import React, { useContext, useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AutomaticallyAddToCommunityLocation from "../Functions/AutomaticallyAddToCommunityLocation";
import { AuthContext } from "../../../components/authentication/Auth.js";

export default function CommunityLocationCardSelectionPage({
  secretLocation,
  communityDistributionLocations,
  privateCommunityDistributionLocations,
  setCommunityDistributionLocations,
  setPrivateCommunityDistributionLocations,
}) {
  //  The default is that no location is found and so we can set the location name
  // to that.
  let communityLocationName = "No Location Found";
  // The distribution location is blank to start, but is the name of the distrbution
  // location that the community hub partnered with.
  let distributionLocation = "";
  // eslint-disable-next-line no-unused-vars
  const { currentUser, userInfo, handleUserInfoChange } =
    useContext(AuthContext);
  // Checks to see if this user has been approved for this location.  If they have
  // then it will show a disabled button that tells them they've been approved.
  const [locationApproved, setLocationApproved] = useState(false);

  // If the secret location came back with no length then we know the code they inputted
  // wasn't found.
  if (Object.keys(secretLocation).length !== 0) {
    // We rename the community location to the name of the secret location found.
    communityLocationName = secretLocation.locationName;
    // We rename the distribution location as the distribution location of the secret
    // location.
    distributionLocation = secretLocation.distributionLocationName;
  }

  // This useEffect is used to check whether the user has been approved for a secret
  // location.
  useEffect(() => {
    // The location name in the current user's dictionary key of this location.
    const locationName = [
      secretLocation.firstName,
      secretLocation.lastName,
      secretLocation.userId,
    ].join("-");

    // Checks to see if the user has a CommunityHostAccess.
    if (userInfo.CommunityHostAccess !== undefined) {
      // First check if this user has applied to this location before
      if (userInfo.CommunityHostAccess[locationName] !== undefined) {
        // Checks if the user is waiting to hear back from this location.
        if (
          userInfo.CommunityHostAccess[locationName].status === "approved" ||
          secretLocation.communityHubType === "publicNoApproval"
        ) {
          // Set pendingCommunityLocation to true.
          setLocationApproved(true);
        }
      }
    }
  }, [
    ...communityDistributionLocations,
    [...privateCommunityDistributionLocations],
    { ...secretLocation },
  ]);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {distributionLocation}
        </Typography>
        <Typography variant="h5" component="div">
          {communityLocationName}
        </Typography>
        {Object.keys(secretLocation).length === 0 ? (
          <Typography variant="body2">
            Please search for another location.
          </Typography>
        ) : (
          <Typography variant="body2">
            <p className="Pickup-Location-Details-Selection">
              <strong>Address: </strong>
              {secretLocation.address}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Pickup Day: </strong>
              {secretLocation.pickupDay}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Pickup Time: </strong>
              {secretLocation.availableTimes}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Cutoff Day: </strong>
              {secretLocation.pickupLocation.orderCutoff}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Maximum Deliveries: </strong>
              {secretLocation.maxParticipants}
            </p>
          </Typography>
        )}
      </CardContent>
      {Object.keys(secretLocation).length !== 0 && (
        <CardActions>
          {locationApproved ? (
            <Button disabled size="small">
              LOCATION IS ALREADY ACCESSIBLE
            </Button>
          ) : (
            <Button
              onClick={() =>
                AutomaticallyAddToCommunityLocation(
                  secretLocation,
                  userInfo,
                  handleUserInfoChange,
                  communityDistributionLocations,
                  privateCommunityDistributionLocations,
                  setCommunityDistributionLocations,
                  setPrivateCommunityDistributionLocations,
                )
              }
              size="small"
            >
              CONFIRM JOINING THIS LOCATION
            </Button>
          )}
        </CardActions>
      )}
    </Card>
  );
}
