// This is called by the home page to show the dialog of the community orders.
import React, { useState, useEffect } from "react";
import firebase from "../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "../../../styles/MyAccount.css";
import ColourButton from "../../../components/userInputs/ColourButton.js";
import UserReceiptHomePage from "../Components/UserReceiptHomePage.jsx";
import CreateVisibleCommunityOrders from "../../../components/dialogs/functions/CreateVisibleCommunityOrders.js";
import DistributedAmountReceipt from "../../../components/OrderDetails/DistributedAmountReceipt.jsx";
import { createOrdersSpreadsheet } from "../Functions/CreateOrdersSpreadsheetUtil.js";

// This is the dialog box that opens when a user clicks on View Customer Receipts
function CommunityOrdersBox(props) {
  const { onClose, open } = props;

  // The questionnaireAnswers documents that are part of this order. Ex.
  // [inxex : {QuestionnaireDocumentName: "1695690336594"
  //   answers:     {auCrxuO0xpXDgChTg12HDAB2Qsm2: {…}}
  //   author    :     "G3TTmauO9PXftMu06tMjpn6bytC3"
  //   createdDate    :     "1696638067426"
  //   description    :     "We are looking to better understand who we are as a community"
  //   questions    :     (3) [{…}, {…}, {…}]
  //   title    :    "Understandi..."}]
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState([]);

  const handleClose = () => {
    onClose(false);
  };

  // This is simply used as a variable to hold the value of the return of CreateVisibleCommunityOrders
  // that is then immediately inserted into commmunityOrdersDisplayed if the user
  // is just viewing the orders and not editing
  let communityOrders = {};

  // These are what are currently being displayed this is the simplified
  // orders where individual user's orders are combined into one.
  const [commmunityOrdersDisplayed, setCommunityOrdersDisplayed] = useState({});

  // This creates the displayed community orders and auto sets them to be the simplified
  // orders
  useEffect(() => {
    communityOrders = CreateVisibleCommunityOrders(
      { ...props.communityOrders },
      null,
      null,
      false,
      [],
      props.selectedDate,
    );

    setCommunityOrdersDisplayed({ ...communityOrders });
  }, [props.communityOrders]);

  // This loads the questionnaire Answer document
  useEffect(() => {
    // Sets the order to the order loaded.
    const order = props.order;
    // If there questionnaireList exists then we can start loading their answers.
    if (order.questionnaireList !== undefined) {
      // If the questionnaireList is greater than 0 then there are questionnaires to load.
      if (Object.values(order.questionnaireList).length > 0) {
        const database = firebase.firestore();
        // Set a temp array.
        const questionnaireAnswersTemp = [];
        // Cycle the questionnaire list and find the name of the questionnaire answers document name.
        Object.values(order.questionnaireList).forEach(
          (questionnaireAnswerName) => {
            const docRef = database
              .collection("QuestionnaireAnswers")
              .doc(questionnaireAnswerName);
            // Load the document
            docRef.get().then((doc) => {
              if (doc.exists) {
                // Push the answer sheet to the array.
                questionnaireAnswersTemp.push(doc.data());
                // Check if the length of the temp variable is the same length as the number of quesitonnaires there are.
                // That means it was the last one to load and we can set the hooks.
                if (
                  questionnaireAnswersTemp.length ===
                  Object.values(order.questionnaireList).length
                ) {
                  questionnaireAnswersTemp.sort().reverse();
                  setQuestionnaireAnswers([...questionnaireAnswersTemp]);
                }
              }
            });
          },
        );
      }
    }
  }, [props.communityOrders]);

  // This is the date that order is picked up from customers
  let distributionDate = props.selectedDate;

  // If it is imported than the date is the importedDistributionDate
  if (props.imported) {
    distributionDate = props.order.importedDistributionDate;
  }

  const handleExportToSpreadsheet = () => {
    createOrdersSpreadsheet(commmunityOrdersDisplayed, distributionDate);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Community Orders for pickup on {distributionDate}
      </DialogTitle>
      <DialogContent>
        <p>
          <img
            style={{ marginRight: "5px", height: "15px" }}
            src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
          />
          - Items Taken from the Pantry
        </p>
        <DistributedAmountReceipt
          distributedFoodItems={props.order.distributedFoodItems}
          location={props.location}
        />
        {Object.keys(commmunityOrdersDisplayed).map((location, i) => (
          <React.Fragment key={i}>
            {Object.keys(commmunityOrdersDisplayed[location]).length !== 0 ? (
              <span>
                {Object.keys(commmunityOrdersDisplayed[location]).map(
                  (user, idx) => (
                    <UserReceiptHomePage
                      idx={idx}
                      key={idx}
                      userId={user.split("-")[2]}
                      userOrder={commmunityOrdersDisplayed[location][user]}
                      questionnaireAnswers={questionnaireAnswers}
                    />
                  ),
                )}
              </span>
            ) : (
              <p>No Community Orders with this Order.</p>
            )}
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button color="secondary" onClick={handleExportToSpreadsheet}>
          EXPORT TO XLS FILE
        </Button>
        <Button color="primary" onClick={handleClose}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CommunityOrdersBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  communityOrders: PropTypes.object,
  location: PropTypes.string,
  selectedDate: PropTypes.string,
  order: PropTypes.object,
  imported: PropTypes.bool,
};

export default function CommunityOrdersDialogHomePage(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonTitle = `View ${props.location} Receipts`;
  let colour = "#17c917";
  let hoverColour = "#1c8c1c";
  if (props.imported) {
    colour = "#f738a2";
    hoverColour = "#942161";
  }

  return (
    <div>
      <div className="Order-Again-Button">
        <div className="Order-Again-Button">
          <ColourButton
            hoverColour={hoverColour}
            colour={colour}
            title={buttonTitle}
            onClick={handleClickOpen}
          />
        </div>
      </div>

      <CommunityOrdersBox
        open={open}
        onClose={handleClose}
        communityOrders={{ ...props.communityOrders }}
        location={props.location}
        selectedDate={props.selectedDate}
        imported={props.imported}
        importedOrder={props.importedOrder}
        order={props.order}
        importedSelectedDate={props.importedSelectedDate}
      />
    </div>
  );
}

CommunityOrdersDialogHomePage.propTypes = {
  communityOrders: PropTypes.object,
  location: PropTypes.string,
  selectedDate: PropTypes.string,
  order: PropTypes.object,
  imported: PropTypes.bool,
  importedOrder: PropTypes.object,
  importedSelectedDate: PropTypes.string,
};
