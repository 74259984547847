// This page renders all the farmers and the food vendors.  It calls FarmerWriteUp
// which renders the description, image and details of the farmers.
import React from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    "& .MuiAccordionSummary-content": {
      justifyContent: "center",
    },
  },
}));

const Accordion = withStyles({
  root: {
    background: "#CADD9E",
  },
})(MuiAccordion);

export default function VolunteerAccordian({ volunteerPosition }) {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className="Volunteer-Accordian-Title">{volunteerPosition.title}</p>
          <img
            referrerPolicy="no-referrer"
            style={{ width: "50px" }}
            src={volunteerPosition.image}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="Volunteer-Accordian-Font-Text">
            Location : {volunteerPosition.location}
            <br />
            <br />
            Credits : {parseFloat(volunteerPosition.creditValue).toFixed(2)}
            c/shift
            <br />
            <br />
            Intake : {volunteerPosition.intake}
            <br />
            <br />
            Shifts available : {volunteerPosition.availability}
            <br />
            <br />
            Recurrence : {volunteerPosition.recurrance}
            <br />
            <br />
            {volunteerPosition.description}
            <br />
            <br />
          </span>
          <a
            href={volunteerPosition.imageRefLink}
            title={volunteerPosition.imageRefTitle}
          >
            {volunteerPosition.imageRefText}
          </a>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
