// This is DC2/3 dialogs used by distribution locations to resend farmers
// the orders via email.
import React from "react";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";

export default function FarmerOrderSelectionDialog({
  farmList,
  openFarmerOrderSelectionDialog,
  setOpenFarmerOrderSelectionDialog,
  setStateOfDialog,
  checkList,
  setCheckList,
}) {
  // If the user closes this dialog then reset the state, checkList and close the dialog.
  const handleClose = () => {
    setOpenFarmerOrderSelectionDialog(false);
    setStateOfDialog("selectAction");
    setCheckList({});
  };

  // If the user moves to the next state update it.
  const handleNext = (value) => {
    setOpenFarmerOrderSelectionDialog(false);
    setStateOfDialog(value);
  };

  // When the user changes the checklist
  const handleChange = (e) => {
    const target = e.target;
    setCheckList({ ...checkList, [target.name]: target.checked });
  };

  // Since we don't want the user moving to the next state until they've selected
  // users and created a message then we just check to make sure those conditions are
  // met and then set this variable to true which displays the next button.
  let nextButtonVisible = false;
  // If the user has selected a farm then you can change the visibility.
  Object.keys(checkList).forEach((user) => {
    if (checkList[user]) {
      nextButtonVisible = true;
    }
  });

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openFarmerOrderSelectionDialog}
      >
        <DialogTitle id="simple-dialog-title">
          What Would You Like To Do?
        </DialogTitle>
        <DialogContent>
          <List>
            <FormLabel component="legend">
              Select Farmers to Email Orders
            </FormLabel>
            <FormGroup>
              {farmList.map((farmer, ind) => (
                <React.Fragment key={ind}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={farmer.farmName}
                        value={checkList[farmer.farmName]}
                        onChange={handleChange}
                      />
                    }
                    label={[farmer.farmName, ":", farmer.farmerEmail].join(" ")}
                  />
                </React.Fragment>
              ))}
            </FormGroup>
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          {nextButtonVisible && (
            <Button
              color="primary"
              onClick={() => handleNext("ConfirmFarmerOrder")}
            >
              NEXT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
