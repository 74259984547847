// This is a dialog that a user will see when they try and remove a user from their
// community location.  This is after they've removed them from their approved members
// and this will permanently delete them allowing the users to apply again to their community
// location.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteCommunityMemberApplication from "../Functions/DeleteCommunityMemberApplication.js";

function DeleteCommunityMemberBox({
  open,
  onClose,
  communityMember,
  userInfo,
  communityMembers,
  setCommunityMembers,
}) {
  const handleClose = () => {
    onClose(false);
  };

  // Delete the user.
  const handleDeleteApplication = (
    communityMember,
    userInfo,
    communityMembers,
    setCommunityMembers,
  ) => {
    DeleteCommunityMemberApplication(
      communityMember,
      userInfo,
      communityMembers,
      setCommunityMembers,
    );
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Confirm Deletion of Community Member Application
      </DialogTitle>
      <DialogContent>
        By deleting this community member you will reappear on the distribution
        selection screen and the user can request access once again from you. If
        you would like to prevent this than just leave them unapproved here.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() =>
            handleDeleteApplication(
              communityMember,
              userInfo,
              communityMembers,
              setCommunityMembers,
            )
          }
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteCommunityMemberBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function DeleteCommunityMemberDialog({
  communityMember,
  userInfo,
  communityMembers,
  setCommunityMembers,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        DELETE COMMUNITY MEMBER
      </Button>
      <DeleteCommunityMemberBox
        open={open}
        onClose={handleClose}
        communityMember={communityMember}
        userInfo={userInfo}
        communityMembers={communityMembers}
        setCommunityMembers={setCommunityMembers}
      />
    </>
  );
}
