// This dialog that is shown on the MyAccount Page and it shows a list of all the orders
// from each week and includes any imported orders.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import OrderPaymentList from "./../Components/OrderPaymentList.jsx";
import OrderPaymentByDateList from "./../Components/OrderPaymentByDateList.jsx";
import Tabs from "../../../components/Tabs.js";

function PaymentHistoryTotalsBox(props) {
  const { onClose, open, orderPaymentHistory, userInfo } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Payment History</DialogTitle>
      <DialogContent>
        <Tabs>
          <div label="Sort By Order Date">
            <div>
              <OrderPaymentList
                orderPaymentHistory={orderPaymentHistory}
                userInfo={userInfo}
              />
            </div>
          </div>
          <div label="Sort By Payment Date">
            <h1>
              <div>
                <OrderPaymentByDateList userInfo={userInfo} />
              </div>
            </h1>
          </div>
        </Tabs>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaymentHistoryTotalsBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PaymentHistoryTotalsDialog({
  orderPaymentHistory,
  userInfo,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <div>
          <Button color="primary" onClick={handleClickOpen}>
            Payment History
          </Button>
        </div>
      </div>
      <PaymentHistoryTotalsBox
        open={open}
        onClose={handleClose}
        orderPaymentHistory={orderPaymentHistory}
        userInfo={userInfo}
      />
    </div>
  );
}
