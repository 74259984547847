// This is called to refresh the page when you delete an item.  This also is
// updates the database with the changes.
export default function DeleteItemFromBasket(
  indexOfFoodItem,
  deleteItem,
  database,
  currentUser,
  userInfo,
  handleUserInfoChange,
) {
  const batch = database.batch();
  const userDocRef = database.collection("Users").doc(currentUser.uid);
  // This is to copy the userInfo so we can use it.
  const info = { ...userInfo };

  // If the user has selected to delete a row then we perform a delete.
  if (deleteItem) {
    info.basketItems.splice(indexOfFoodItem, 1);
  }

  // Finally we update userInfo with our copy and return the newly updated
  // basketItems and the the documentName of the user's document
  handleUserInfoChange({ ...info }, true);
  batch.set(
    userDocRef,
    { basketItems: [...info.basketItems] },
    { merge: true },
  );
  batch.commit();
}
