// Takes in the parameters for currentOrder and then checks it against the database
// in th DistributionLocations -> {Location} -> CommunityOrders -> selectedDate/importedDistributionDate
// to see whether there are new orders to be added.  It simple sets the hooks that are sent
// in through the parameters.
import firebase from "./../components/Firebase.js";

// This checks the current orders and the loadedOrders again each other.  If there
// are any orders that don't exist then they are added to the new Orders dictionary
// this is then returned
function CheckForNewOrders(currentOrders, loadedOrders) {
  const newOrders = {};
  Object.keys(loadedOrders).forEach((order) => {
    if (!Object.keys(currentOrders).includes(order)) {
      newOrders[order] = loadedOrders[order];
    }
  });

  return newOrders;
}

export default async function CheckCustomerUpdate(
  currentOrders,
  selectedDate,
  userInfo,
  setCheckingUpdate,
  setNewOrders,
) {
  const database = firebase.firestore();

  // The communityOrders that will be loaded into the database
  const communityOrders = [];

  // This is to give the distribution location's name. The default is the
  // userInfo.organisationName but will be changed if it is imported
  const distributionLocationName = userInfo.organisationName;
  // This is to give the date of the distribution.   The default is the
  // selectedDate but will be changed if it is imported
  const distributionDate = selectedDate;

  const distributionLocationDocRef = database
    .collection("DistributionLocations")
    .doc(distributionLocationName)
    .collection("CommunityOrders");

  try {
    // Fetch all documents that have the distributionDate equal to selectedDate
    const querySnapshot = await distributionLocationDocRef
      .where("distributionDate", "==", distributionDate)
      .get();
    if (!querySnapshot.empty) {
      // Iterate through all the documents in the query snapshot
      querySnapshot.forEach((doc) => {
        const orders = doc.data();

        // Combine the orders while removing distributionDate and orderCount
        Object.keys(orders).forEach((key) => {
          if (key !== "distributionDate" && key !== "orderCount") {
            // Combine orders
            communityOrders[key] = orders[key];
          }
        });
      });

      // Sends the current and loaded orders to be checked if they are different.
      // Then sets newOrders to whatever this dictionary is.  IF there are no new
      // orders than it will just be an empty dictionary.
      setNewOrders(CheckForNewOrders(currentOrders, communityOrders));
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.log("Error getting document:", error);
  } finally {
    // Checking update has completed.
    setCheckingUpdate(true);
  }
}
