// This is called by the basket page.
// This is to handle changes to a user's order in community orders or the imported
// community order.  This updates any hooks to make sure the page rerenders to reflect
// the changes.  Imported is boolean, foodListUpdate is the new foodList, farmerListUpdate
// is the new farmerList, userKey is user's FirstName-LastName-userId-orderDate
import AggregateCustomerList from "../../../../functions/AggregateOrder.js";

export default function CommunityOrderUpdate(
  imported,
  foodListUpdate,
  farmerListUpdate,
  userKey,
  updatedOrder,
  deleteOrder,
  userOrder,
  importedOrder,
  setImportedOrder,
  communityOrders,
  setCommunityOrders,
  setCommunityAndImportedOrder,
  setCommunityFoodItems,
  setPantryFoodItems,
  setCommunityFarmerInfo,
  setCommunityDonations,
  setCommunitySubsidies,
  location,
  communityLocation,
  modifiedUserPickups,
  setModifiedUserPickups,
) {
  // Creates a temporary dictionary to hold the community and the imported order.
  let communityAndImportedOrderTemp = {};

  // Checks if the order being updated was imported or not.
  if (imported) {
    const importedOrderTemp = { ...importedOrder };
    // If the updatedOrder exists then we know it was a change with the
    // subsidy, donation or volunteer amounts.  If deleteOrder is true then we know we're
    // removing an order otherwise we added an foodItem.
    if (updatedOrder) {
      // update the user's order to the dictionary sent in.
      importedOrderTemp[location].communityOrders[userKey] = {
        ...updatedOrder,
      };
    } else if (deleteOrder) {
      // remove the order from the importedOrder
      delete importedOrderTemp[location].communityOrders[userKey];
    }
    // Check to see if the community location exists.
    else if (communityLocation) {
      // Puts the community Ordes in the importedCommunityOrders
      const importedCommunityOrders = importedOrder[location].communityOrders;

      // Create a modifiedUserPickupsTemp in a dictionary to update the hook
      const modifiedUserPickupsTemp = { ...modifiedUserPickups };
      // Checks to see if cancelled exists and if it doesn't create an empty {}
      // for the modifiedUserPickupsTemp
      !("cancelled" in modifiedUserPickupsTemp) &&
        (modifiedUserPickupsTemp.cancelled = {});
      // Checks to see if relocated exists and if it doesn't create an empty {}
      // for the modifiedUserPickupsTemp
      !("relocated" in modifiedUserPickupsTemp) &&
        (modifiedUserPickupsTemp.relocated = {});

      // Cycle through the imported Community orders
      Object.keys(importedCommunityOrders).forEach((user) => {
        // Checks if the order is at this deleted communityLocation and if the user
        // chose to cancel their order.
        if (
          importedCommunityOrders[user].communityPickup.locationName ===
            communityLocation &&
          importedCommunityOrders[user].orderNotMet === "cancel"
        ) {
          // Adds the order to the cancelled dictionary.
          modifiedUserPickupsTemp.cancelled[user] = {
            ...importedOrder[location].communityOrders[user],
          };
          // deletes the order.
          delete importedOrderTemp[location].communityOrders[user];
        }
        // Checks if the user is at the deleted commmunity location and if they chose
        // to relocate their order.
        else if (
          importedCommunityOrders[user].communityPickup.locationName ===
            communityLocation &&
          importedCommunityOrders[user].orderNotMet !== "cancel"
        ) {
          // Add to the relocated dictionary
          modifiedUserPickupsTemp.relocated[user] = {
            ...importedOrder[location].communityOrders[user],
          };
        }
      });
      // Updates the hook
      setModifiedUserPickups({ ...modifiedUserPickupsTemp });
    } else {
      // If the user updated the foodList and farmerList then update those items
      importedOrderTemp[location].communityOrders[userKey].foodList = [
        ...foodListUpdate,
      ];
      importedOrderTemp[location].communityOrders[userKey].farmerList = [
        ...farmerListUpdate,
      ];
    }
    // Set importedOrder to include the changes
    setImportedOrder({ ...importedOrderTemp });

    // Update the combined Order of the commmunity and the imported orders
    if (Object.keys(communityOrders).length !== 0) {
      communityAndImportedOrderTemp = communityOrders;
      Object.keys(importedOrderTemp).forEach((distributionlocation) => {
        communityAndImportedOrderTemp = Object.assign(
          {},
          importedOrderTemp[distributionlocation].communityOrders,
          communityAndImportedOrderTemp,
        );
      });
    } else {
      Object.keys(importedOrderTemp).forEach((distributionlocation) => {
        communityAndImportedOrderTemp = Object.assign(
          {},
          importedOrderTemp[distributionlocation].communityOrders,
          communityAndImportedOrderTemp,
        );
      });
    }
  } else {
    // Do the same as above but for community orders that aren't imported
    const communityOrdersTemp = { ...communityOrders };
    // If the donations or volunteer amounts were changed then set the
    // donationSubsidy, customerContribution, cashPortion, and creditPortion to their potentially new values.
    if (updatedOrder) {
      communityOrdersTemp[userKey].donationSubsidy =
        updatedOrder.donationSubsidy;
      communityOrdersTemp[userKey].customerContribution =
        updatedOrder.customerContribution;
      communityOrdersTemp[userKey].cashPortion = updatedOrder.cashPortion;
      communityOrdersTemp[userKey].creditPortion = updatedOrder.creditPortion;
    } else if (deleteOrder) {
      // remove the order from the communityOrders
      delete communityOrdersTemp[userKey];
    } else if (communityLocation) {
      // Create a modifiedUserPickupsTemp in a dictionary to update the hook
      const modifiedUserPickupsTemp = { ...modifiedUserPickups };
      // Checks to see if cancelled exists and if it doesn't create an empty {}
      // for the modifiedUserPickupsTemp
      !("cancelled" in modifiedUserPickupsTemp) &&
        (modifiedUserPickupsTemp.cancelled = {});
      // Checks to see if relocated exists and if it doesn't create an empty {}
      // for the modifiedUserPickupsTemp
      !("relocated" in modifiedUserPickupsTemp) &&
        (modifiedUserPickupsTemp.relocated = {});

      // Cycle through the Community orders
      Object.keys(communityOrders).forEach((user) => {
        // Checks if the order is at this deleted communityLocation and if the user
        // chose to cancel their order.
        if (
          communityOrders[user].communityPickup.locationName ===
            communityLocation &&
          communityOrders[user].orderNotMet === "cancel"
        ) {
          // deletes the order.
          delete communityOrdersTemp[user];
          // Adds the order to the cancelled dictionary.
          modifiedUserPickupsTemp.cancelled[user] = {
            ...communityOrders[user],
          };
        }
        // Checks if the user is at the deleted commmunity location and if they chose
        // to relocate their order.
        else if (
          communityOrders[user].communityPickup.locationName ===
            communityLocation &&
          communityOrders[user].orderNotMet !== "cancel"
        ) {
          // Add to the relocated dictionary
          modifiedUserPickupsTemp.relocated[user] = {
            ...communityOrders[user],
          };
        }
      });

      setModifiedUserPickups({ ...modifiedUserPickupsTemp });
    } else {
      communityOrdersTemp[userKey] = userOrder;
    }
    setCommunityOrders({ ...communityOrdersTemp });

    if (Object.keys(importedOrder).length !== 0) {
      // combine the community orders of this location and the imported one
      communityAndImportedOrderTemp = communityOrdersTemp;
      Object.keys(importedOrder).forEach((distributionlocation) => {
        communityAndImportedOrderTemp = Object.assign(
          {},
          importedOrder[distributionlocation].communityOrders,
          communityAndImportedOrderTemp,
        );
      });
    } else {
      // If there is no imported order then just set this to the community
      // orders of this location
      communityAndImportedOrderTemp = communityOrdersTemp;
    }
  }
  setCommunityAndImportedOrder({ ...communityAndImportedOrderTemp });
  // Call AggregateCustomerList to set the values of the hooks that are
  // sent in.  It has values that it returns but they aren't used
  AggregateCustomerList(
    communityAndImportedOrderTemp,
    setCommunityFoodItems,
    setCommunityFarmerInfo,
    setCommunityDonations,
    setCommunitySubsidies,
    false,
    setPantryFoodItems,
  );
}
