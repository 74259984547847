// This is the community pick up location accordion that will display all the users
// that have picked up from a specific location when sending an email to them for their
// order drop off.
import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CommunityPickupAccordion = ({
  location,
  userEmails,
  checkList,
  handleChange,
  handleSelectAll,
}) => (
  <Accordion
    style={{
      background: "#fffee0",
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          {location}
        </Typography>
        <FormControlLabel
          control={<Checkbox onChange={handleSelectAll} name={location} />}
          label="Select All"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        />
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box display="flex" flexDirection="column">
        {Object.keys(userEmails[location]).map((user, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                name={user}
                checked={checkList[user] || false}
                onChange={handleChange}
              />
            }
            label={[
              user.split("-")[0],
              user.split("-")[1],
              ":",
              userEmails[location][user],
            ].join(" ")}
          />
        ))}
      </Box>
    </AccordionDetails>
  </Accordion>
);
export default CommunityPickupAccordion;
