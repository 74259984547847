import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

let config = {};

if (process.env.NODE_ENV === "production") {
  //  	Set Production config
  config = {
    apiKey: "AIzaSyDQMbLpxMCD0dzOUVZOyz4rNPCwZFNOPhE",
    authDomain: "farm-to-table-1177f.firebaseapp.com",
    databaseURL: "https://farm-to-table-1177f.firebaseio.com",
    projectId: "farm-to-table-1177f",
    storageBucket: "farm-to-table-1177f.appspot.com",
    messagingSenderId: "208134024825",
    appId: "1:208134024825:web:416d94e09df79f8a502ea8",
    measurementId: "G-5XFC8SVLJS",
  };
} else {
  // Set development config
  config = {
    apiKey: "AIzaSyBOdCVCw-N0gRGgHYFpVo5KaXvnCA-dlq4",
    authDomain: "farm-to-plate-marketplace-dev.firebaseapp.com",
    projectId: "farm-to-plate-marketplace-dev",
    storageBucket: "farm-to-plate-marketplace-dev.appspot.com",
    messagingSenderId: "306055588962",
    appId: "1:306055588962:web:d10e34ef5db054ab58753b",
    measurementId: "G-GVHW716DDS",
  };
}

firebase.initializeApp(config);
export default firebase;
