// This is 1/3 dialogs used by distribution locations to send their volunteers
// emails.   It displays actions that a distribution location can do when
// sending out an email.
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "date-fns";

export default function SelectVolunteerActionDialog({
  openSelectAction,
  setOpenSelectAction,
  setStateOfDialog,
}) {
  // If the user closes the dialog
  const handleClose = () => {
    setOpenSelectAction(false);
  };

  // if the user selects an action it will pass through the value and set update the
  // state so we can move to the next dialog.
  const handleNext = (value) => {
    setOpenSelectAction(false);
    setStateOfDialog(value);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openSelectAction}
      >
        <DialogTitle id="simple-dialog-title">
          What Would You Like To Do?
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem autoFocus button onClick={() => handleNext("Custom")}>
              <ListItemText primary="Send users a custom message" />
            </ListItem>
            <ListItem
              autoFocus
              button
              onClick={() => handleNext("CreditsSend")}
            >
              <ListItemText primary="Send volunteer credits" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
