// This is used to display the information under MyAccount -> Community -> My Community
// Location for an individual user.  It will display the user's community host's information
// and then the community members wishing to use their location.  It will also allow the user
// to apply to become a community host.
import React, { useState, useContext } from "react";
import { AuthContext } from "../../../components/authentication/Auth.js";
import CommunityLocationAccordian from "./Accordions/CommunityLocationAccordian.jsx";
import CommunityMembersAccordian from "./Accordions/CommunityMembersAccordian.jsx";
import MyAccountBecomeCommunityHostDialog from "../Dialogs/MyAccountBecomeCommunityHostDialog.jsx";

export default function MyCommunityLocation({
  userInfo,
  index,
  communityLocations,
  setCommunityLocations,
}) {
  // set the communityLocation if it exists
  const communityLocation = userInfo.communityLocation;
  // When a user applies to become a community host an email is sent and this is set
  // to true while the email sends.
  // eslint-disable-next-line no-unused-vars
  const [sendingEmail, setSendingEmail] = useState(false);
  const { handleUserInfoChange } = useContext(AuthContext);

  return (
    <>
      {userInfo.communityLocation === undefined ? (
        <div style={{ margin: "16px" }}>
          <p>
            Becoming a community host helps more people access local food by
            providing additional places and times to pick up orders. It also
            connects communities by introducing neighbours and newcomers.
            <br />
            <br />
            &nbsp; 1. Apply to become a community host by clicking the button
            &quot;BECOME A COMMUNITY HOST&quot; below.
            <br />
            &nbsp; 2. Your selected distribution location will review your
            application and then follow up to confirm your interest and
            logistics.
            <br />
            &nbsp; 3. Once confirmed your community location will be visible
            with limited details to screened individuals who participate at your
            partnered distribution location.
            <br />
            &nbsp; 4. Community members will request, from you, the ability to
            pick up from your community location.
            <br />
            &nbsp; 5. Orders will be delivered and dropped off at your
            designated spot.
            <br />
            &nbsp; 6. Community members will come pick up their food.
            <br />
            &nbsp; 7. You can cancel at anytime.
            <br />
            Learn more by emailing anthony@farmtoplatemarketplace.com or your
            local distribution location.
          </p>
          <MyAccountBecomeCommunityHostDialog
            userInfo={userInfo}
            handleUserInfoChange={handleUserInfoChange}
            setSendingEmail={setSendingEmail}
          />
        </div>
      ) : (
        <div style={{ margin: "16px" }}>
          <p>
            View details about your community location by clicking on your
            location&apos;s name below. If you would like to change anything
            about your location please contact{" "}
            {userInfo.communityLocation.pickupLocation.email}. If it is urgent
            please contact 778-896-6754. Thank you for helping connect and feed
            your community.
          </p>
          <CommunityLocationAccordian communityLocation={communityLocation} />
          <p>
            Click &quot;Community Members&quot; below to view people that you
            have approved or have requested access to pick up from your
            location. You may remove, approve or completely erase any users. An
            email should have been sent {userInfo.email} with each person&apos;s
            request to pickup from your location.
          </p>
          <CommunityMembersAccordian userInfo={userInfo} />
        </div>
      )}
    </>
  );
}
