// The header for the distribution location selection page.  It includes two buttons,
// 1. that is to become a community host and the other to enter a community hub's secret
// code for instant approval.
import React from "react";
import BecomeCommunityHostDialog from "../Dialogs/BecomeCommunityHostDialog.jsx";
import EnterSecretCodeDialog from "../Dialogs/EnterSecretCodeDialog.jsx";

export default function DistributionLocationSelectionHeader({
  userInfo,
  handleUserInfoChange,
  currentUser,
  pickupLocations,
  setSendingEmail,
  communityDistributionLocations,
  setCommunityDistributionLocations,
  privateCommunityDistributionLocations,
  setPrivateCommunityDistributionLocations,
}) {
  return (
    <div className="Distribution-Location-Header">
      <h1 className="Distribution-Location-Title">Marketplace</h1>
      <h1 className="Distribution-Location-Sub-Title">
        Start by selecting a pick-up location
      </h1>
      <div className="Distribution-Selection-Header-Buttons">
        <BecomeCommunityHostDialog
          userInfo={userInfo}
          handleUserInfoChange={handleUserInfoChange}
          pickupLocations={pickupLocations}
          setSendingEmail={setSendingEmail}
          currentUser={currentUser}
        />
        {!!currentUser && (
          <EnterSecretCodeDialog
            pickupLocations={pickupLocations}
            communityDistributionLocations={communityDistributionLocations}
            privateCommunityDistributionLocations={
              privateCommunityDistributionLocations
            }
            setCommunityDistributionLocations={
              setCommunityDistributionLocations
            }
            setPrivateCommunityDistributionLocations={
              setPrivateCommunityDistributionLocations
            }
          />
        )}
      </div>
    </div>
  );
}
