// Takes in the communityOrders and the importedOrders and turns them into one Foodlist
// of all the items that the community members order.  It also does the same for the items
// that the community ordered from the pantry and have limits on them.
import AggregateCustomerList from "../../../functions/AggregateOrder.js";

export default function CreateCustomerLists(communityOrders, importedOrder) {
  // A dictionary with the combined community and imported orders
  let communityAndImportedOrder = {};

  // Checks to see if the imported Order exists and if it does then combine the
  // community orders of the current distribution location and the imported one.
  if (Object.keys(importedOrder).length === 0) {
    communityAndImportedOrder = communityOrders;
  } else {
    // Start with adding the communityOrders of the distribution location itself.
    communityAndImportedOrder = communityOrders;
    // Cycle through all the distribution locations imported by this order and
    // add their community orders to the communityAndImportedOrder
    Object.keys(importedOrder).forEach((location) => {
      communityAndImportedOrder = Object.assign(
        {},
        communityAndImportedOrder,
        importedOrder[location].communityOrders,
      );
    });
  }

  // Aggregate the customer List to create one consolidated list of food.
  const [, , customerFoodList, pantryFoodList] = AggregateCustomerList(
    communityAndImportedOrder,
    null,
    null,
    null,
    null,
    true,
  );

  return [customerFoodList, pantryFoodList];
}
