// Displays the map that is shown on the selection marketplace screen.
import React from "react";
import { Map, Marker, Circle, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import LongLatAdjustment from "./../Functions/LongLatAdjustment.js";
import DistributionLocationPopUp from "./PopUps/DistributionLocationPopUp.jsx";
import CommunityDistributionLocationPopUp from "./PopUps/CommunityDistributionLocationPopUp.jsx";

export default function DistributionLocationMap({
  pickupLocations,
  handleActiveIcon,
  communityDistributionLocations,
  userInfo,
  activeIcon,
  setActiveIcon,
  handleClickOpen,
  previousPage,
  setSendingEmail,
}) {
  // The icon used to display a distribution location.
  const locationIcon = new Icon({
    iconUrl:
      "https://lh3.googleusercontent.com/pw/AL9nZEUljxHnDQdsxOw2TA_Wzt2ElhM1474eE7aPi0p4AmcFRrbqZ-drCWtYiA5KqD0YaaU5YCg1Wdg9sqAz59Bf2U3dqGAFvnsoC5CiVZ9IpkiyhmxqKSGpjZ4vuB21Fs3lt8VnINRf50fCnGxt2GtSSuNt=s732-no?authuser=1",
    iconSize: [35, 35],
  });
  // The icon used to display a public community location.
  const publicCommunityLocationIcon = new Icon({
    iconUrl:
      "https://lh3.googleusercontent.com/pw/ACtC-3e_PLev5mqx2dUnS4dZ-qCMzRigr-IjKPmbJP6sITCtoWw16UroJgV6tH_PvGHK4Ff_w0dXLJChQvWB1OWp_mFMAPKAOvZ2URz5ZXn7GHV1RsQ6Qe4lzzA8L0zC-FZxT_3_0wpRMseKkjWuWlG2SeSl=w237-h244-no?authuser=2",
    iconSize: [25, 25],
  });
  // The icon used to display a community location.
  const communityLocationIcon = new Icon({
    iconUrl:
      "https://lh3.googleusercontent.com/pw/ACtC-3f98F9NH8MxMg3EQvamd0DU0hOH4YLKfNkprRp_sfAREyKh7QAhdDepl6s2pD6_4FtFuZNwUjuLlAObxdeasbJSjSnnhHdhAKPXFGu_C5CfKUMhKIb3zcquktVP9off3e9s9wziJrcRpZeNNFqaUg4e=w240-h239-no?authuser=2",
    iconSize: [25, 25],
  });

  return (
    <Map center={[49.246292, -123.116226]} zoom={12}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Cycles through all the locations in the filtered/unfiltered
        dictionary and adds markers depending on whether they're farms
        or restaurants */}
      {Object.keys(pickupLocations).map((location, index) => {
        return (
          <Marker
            key={"location" + index}
            position={[
              pickupLocations[location].latitude,
              pickupLocations[location].longitude,
            ]}
            onClick={() => {
              handleActiveIcon(pickupLocations[location]);
            }}
            icon={locationIcon}
          />
        );
      })}

      {communityDistributionLocations.map((communityLocation, index) => {
        const [latitude, longitude, approved] = LongLatAdjustment(
          communityLocation,
          userInfo,
        );

        let iconToShow = communityLocationIcon;

        if (communityLocation.communityHubType === "publicNoApproval") {
          iconToShow = publicCommunityLocationIcon;
        }

        return (
          <>
            <Marker
              key={index}
              position={[latitude, longitude]}
              onClick={() => {
                handleActiveIcon(communityLocation);
              }}
              icon={iconToShow}
            />
            {!approved && (
              <Circle radius={400} center={[latitude, longitude]} />
            )}
          </>
        );
      })}

      {activeIcon && (
        <Popup
          position={[activeIcon.latitude, activeIcon.longitude]}
          onClose={() => {
            setActiveIcon(null);
          }}
        >
          {activeIcon.pickupLocation === undefined ? (
            <DistributionLocationPopUp
              activeIcon={activeIcon}
              handleClickOpen={handleClickOpen}
              previousPage={previousPage}
            />
          ) : (
            <CommunityDistributionLocationPopUp
              activeIcon={activeIcon}
              handleClickOpen={handleClickOpen}
              previousPage={previousPage}
              userInfo={userInfo}
              setSendingEmail={setSendingEmail}
            />
          )}
        </Popup>
      )}
    </Map>
  );
}
