// This is D3/3 dialogs used by distribution locations to send farmers an
// email of their order. This can be useful if the orginal email didn't send
// for some reason.
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import OrderEmails from "../../../../pages/BasketPage/OrderEmails.js";
import batchEmails from "../../../../functions/Email/BatchEmailer.js";
import PriceTotals from "../../../../functions/PriceTotals.js";

export default function ConfirmFarmerOrderDialog({
  openConfirmFarmerOrderDialog,
  setOpenConfirmFarmerOrderDialog,
  order,
  setStateOfDialog,
  farmList,
  checkList,
  setCheckList,
  userInfo,
  setSendingEmails,
}) {
  const farmDict = {};

  farmList.forEach((farm) => {
    farmDict[farm.farmName] = { ...farm };
  });

  // If the user hits the back button then it will clear their checkList and set the
  // the current state (value) to "FarmerOrderSelectionDialog" where they will pick
  // their farmers again.
  const handleBack = (value) => {
    setOpenConfirmFarmerOrderDialog(false);
    setStateOfDialog(value);
    setCheckList({});
  };
  // If the user close the dialog then reset the state of this dialog series to
  // "selectAction" and clear the checkList.
  const handleClose = () => {
    setOpenConfirmFarmerOrderDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
  };

  // If the user confirms they wish to send the emails.
  const handleConfirm = async () => {
    // Call OrderEmails for each farm, batching the emails to avoid exceeding Outlook's concurrent connection limit
    const emailBatches = [];

    Object.keys(checkList).forEach((farm) => {
      // The date of the delivery
      let deliveryDate = new Date(order.selectedDate);
      // Sets the date.
      deliveryDate.setDate(deliveryDate.getDate());
      // Turns into the format "Day MMM DD YYYY"
      deliveryDate = deliveryDate.toString().substring(0, 15);
      // The cost the farmers will charge for each order.
      const farmTotals = PriceTotals(order.foodList, false);
      // Create the batches that will be sent.
      emailBatches.push([
        farmDict[farm],
        order.foodList,
        userInfo,
        deliveryDate,
        "",
        farmTotals[farm],
        0,
        "",
        setSendingEmails,
        null,
        0,
      ]);
    });

    // Call the batch email function that will handle the delays necessary
    // to sending the emails off without overloading the send mail function.
    await batchEmails({
      batchedParamsForEmailFunc: emailBatches,
      singularEmailFunc: OrderEmails,
    });

    // Close the dialog
    setOpenConfirmFarmerOrderDialog(false);
    // Reset the checklist.
    setCheckList({});
    // reset the state.
    setStateOfDialog("selectAction");
    // Close the sending emails progress dialog
    setSendingEmails(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openConfirmFarmerOrderDialog}
      >
        <DialogTitle id="simple-dialog-title">Confirm Emails</DialogTitle>
        <DialogContent>
          <List>
            <h6>
              <u>Farmers being emailed</u>
            </h6>
            {Object.keys(checkList).map((farmName, i) => (
              <span key={i}>
                {checkList[farmName] && farmDict[farmName] && (
                  <ListItem autoFocus>
                    <ListItemText
                      primary={[
                        farmName,
                        ":",
                        farmDict[farmName].farmerEmail,
                      ].join(" ")}
                    />
                  </ListItem>
                )}
              </span>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleBack("FarmerOrderSelection")}
          >
            BACK
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            SEND
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
