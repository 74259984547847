// This page renders all the community Engagement items.  This includes the volunteer
// Opportunities and the farm-to-plate information and details.
import React, { useEffect, useState } from "react";
import "../App.css";
import "../styles/CommunityEngagement.css";
import { Grid } from "@material-ui/core";
import Menu from "../components/Menu.js";
import firebase from "../components/Firebase.js";
import LoadingContent from "../components/LoadingContent.jsx";
import Footer from "../components/Footer.jsx";
import VolunteerAccordian from "./CommunityEngagementPage/Components/VolunteerAccordian.jsx";
import ExtraInformation from "./CommunityEngagementPage/Components/ExtraInformation.jsx";
import ImageLinkButton from "./CommunityEngagementPage/Components/ImageLinkButton.jsx";
import VolunteerIntakeDialog from "./CommunityEngagementPage/Dialogs/VolunteerIntakeDialog.jsx";
import SendingVolunteerApplicationDialog from "./CommunityEngagementPage/Dialogs/SendingVolunteerApplicationDialog.jsx";
import VolunteerScheduleDownloadDialog from "./CommunityEngagementPage/Dialogs/VolunteerScheduleDownloadDialog.jsx";

export default function CommunityEngagement() {
  const database = firebase.firestore();

  // The volunteerJobs that are used to display on the community page.
  const [volunteerJobs, setVolunteerJobs] = useState([]);

  // This is set to false until the data from the database been loaded
  const [volunteerLoading, setVolunteerLoading] = useState(false);

  // This is set to true when the user sends their application and the browser takes
  // time to finsih sending the email.
  const [sendingApplication, setSendingApplication] = useState(false);

  useEffect(() => {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    const volunteerDocRef = database
      .collection("Volunteer")
      .where("status", "==", true);
    const volunteerDataTransfer = [];
    volunteerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            volunteerDataTransfer.push(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setVolunteerJobs(volunteerDataTransfer);
        setVolunteerLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    // scroll to the top of the page.
    window.scrollTo(0, 0);
    const { hash } = window.location;

    // Remove the # from the url.
    const scrollLocation = hash.substring(1);

    // Find the element.
    const scrollLocationId = document.getElementById(scrollLocation);

    // If the element exists then scroll it into view.
    if (scrollLocationId !== null) {
      scrollLocationId.scrollIntoView();
    }
  }, [window.location]);

  return (
    <div id="home">
      <Menu />
      <h1 className="Community-Engagement-Title">Community Engagement</h1>
      <div className="Community-Engagement-Page">
        <div>
          <a
            href="/CommunityEngagement#VolunteerOpportunities"
            style={{ textDecoration: "none" }}
          >
            <ImageLinkButton
              title="Volunteer Opportunities"
              imgUrl="https://lh3.googleusercontent.com/pw/AM-JKLVqokQ_WcFcWvSBzM5wClsgtnOOWeNh_1-FOhreJtPF6aJK-KGrEOSWJoJXu_TMPuhLAVz_ZdnBVBsC5Vz-8BaHw3Yg2_tcGNZaUr_mKyzJ6U-DhtueHzETabkj-9DR5kO11Bp7BnCQz3rMUqLDAlHv=w976-h732-no?authuser=1"
            />
          </a>
          <a
            href="/CommunityEngagement#ProgramData"
            style={{ textDecoration: "none" }}
          >
            <ImageLinkButton
              title="Program Data and Detailed Information"
              imgUrl="https://lh3.googleusercontent.com/pw/AM-JKLUJi2V3G4CypuQpvZcD-M6xkz6NX2KeblR0STuxkKS8mB-x_XPE27afbAKbfHVkjGdo3EpYI4YE-GE3gQ79U6dGZHVYfkc1-7jG1x5bhlgxBmMpvB7stGPUaFu4gvcT_yWz9kj9NaLL7NDpTTYGbTGf=w759-h732-no?authuser=1"
            />
          </a>
          {/* <img referrerPolicy="no-referrer" style = {{width : "100%"}} src = "https://lh3.googleusercontent.com/pw/AM-JKLWFiAzT3unW8PWP_Uu_KqYEs91k841voM5VLobSnEpp-qnV4G4gsYbea7Auzcl1HmU_98N_UHphTRHlJ6uYrT_yvFr6GnlXcEuFQkq84W43UP6xql3Dryjxj7YBzu772U5djMWjvpQeFUrk2zUWJ2cP=w1440-h648-no?authuser=1"/> */}
        </div>

        {volunteerLoading ? (
          <div>
            <div className="Community-Sections-Div" id="VolunteerOpportunities">
              <h1 className="Community-Sections-Header">
                Volunteer Opportunities
              </h1>
              <VolunteerIntakeDialog
                setSendingApplication={setSendingApplication}
                volunteerJobs={volunteerJobs}
              />
              <h4 className="Linus-Law-Quote">
                Contribute your skills to build an inclusive and sustainable
                food system.
              </h4>
              <span
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <VolunteerScheduleDownloadDialog />
              </span>
            </div>
            <div style={{ margin: "0px 50px" }}>
              <u className="Food-Sovereignty-Title">Food Sovereignty</u>
              <p className="Food-Sovereignty-Definition">
                A food system in which those who produce, distribute, and
                consume food also control the mechanisms and policies of
                production and distribution.
              </p>
              <Grid container spacing={2}>
                {volunteerJobs.map((volunteerPosition, index) => (
                  <Grid key={index} item xs>
                    <VolunteerAccordian
                      style={{ background: "red" }}
                      volunteerPosition={volunteerPosition}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        ) : (
          <LoadingContent inline={true} />
        )}

        <div className="Community-Sections-Div" id="ProgramData">
          <h1 className="Community-Sections-Header">
            Farm-to-Plate Marketplace Details and Information
          </h1>
          <h4 className="Linus-Law-Quote">
            "Given enough eyeballs, all bugs are shallow". - Linus's Law
          </h4>
        </div>

        <div style={{ margin: "0px 50px" }}>
          <p className="F2PM-Details-Description">
            Farm-to-Plate Marketplace combines the connection of food with
            technology’s ability to share information to teach us how to better
            support one another.
            <br />
            Our goal is to use data transparency to empower our collective brain
            to end food insecurity and create a sustainable, fair and local food
            system.
          </p>

          <ExtraInformation />
        </div>
      </div>
      <SendingVolunteerApplicationDialog open={sendingApplication} />
      <Footer />
    </div>
  );
}
