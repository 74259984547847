import React from "react";
import RestaurantBasketTableHeader from "../Components/HeadersAndRows/RestaurantBasketTableHeader.jsx";
import RestaurantFarmTableHeader from "../Components/HeadersAndRows/RestaurantFarmTableHeader.jsx";
import RestaurantBasketFoodRow from "../Components/HeadersAndRows/RestaurantBasketFoodRow.jsx";
import RestaurantBasketFarmRow from "../Components/HeadersAndRows/RestaurantBasketFarmRow.jsx";

export default function RestaurantBasket({
  foodItems,
  handleRefresh,
  handleQuantityChange,
  userInfo,
  handleCheckboxChange,
  farmerInfo,
  handleMethodSelection,
  contactMethods,
}) {
  return (
    <div>
      <RestaurantBasketTableHeader />
      {/* Cycle through the list of food initialised at the beginning and
        create the rows */}
      {foodItems.map((row, idx) => (
        <RestaurantBasketFoodRow
          key={row + idx}
          row={row}
          checked={row.checked}
          idx={idx}
          handleRefresh={handleRefresh}
          handleQuantityChange={handleQuantityChange}
          userType={userInfo.userType}
          handleCheckboxChange={handleCheckboxChange}
        />
      ))}
      <h3
        className="Header-3"
        style={{ textDecoration: "underline", marginBottom: "0px" }}
      >
        Farms Providing this Order
      </h3>

      <RestaurantFarmTableHeader />

      {farmerInfo.length !== 0 ? (
        <div>
          {/* Cycle through the list of Farms and create the rows */}
          {farmerInfo.map((farm, index) =>
            RestaurantBasketFarmRow(
              farm,
              handleMethodSelection,
              contactMethods[farm.farmName],
              index,
            ),
          )}
        </div>
      ) : (
        <h3 className="Header-3"> No farm data available!</h3>
      )}
    </div>
  );
}
