// This takes in the order and outputs the stats of the order along with the list
// of items ordered and the receipts of each individual orderer.
import React from "react";
import OrderDetailsText from "./OrderDetailsText";
import { CardHeader, CardContent } from "@material-ui/core";

export default function OrderCard({ order, location }) {
  // The date of the pickup
  const { selectedDate: date } = order || {};

  const title = `Order Distributed on ${date}`;

  return (
    <>
      {!order.importedBy && <CardHeader title={title} />}
      {/* This is done because there was a change in how imported orders are saved
        in the database and so any orders placed after this date will follow the
        new rules. */}
      {order.orderDate > "202106150000000000000" ? (
        <>
          {Object.keys(order.importedOrder).length > 0 && (
            <CardContent>
              {Object.keys(order.importedOrder).map((location, index) => (
                <span key={location}>
                  <>
                    {index + 1}. The imported order from {location} was
                    distributed on {order.importedOrder[location].date}
                  </>
                  <br />
                </span>
              ))}
            </CardContent>
          )}
          {/* NEEDS WORK */}
        </>
      ) : (
        <>
          {!!order.importedDistributionLocation && (
            <CardContent>
              The imported order from {order.importedDistributionLocation} was
              distributed on {order.importedDistributionDate}
            </CardContent>
          )}
        </>
      )}
      {OrderDetailsText(order, location)}
    </>
  );
}
