// This is the dialog that appears to allow the user to edit a questionniare.
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

// The content of the card to edit.
const EditorContent = ({
  questionnaire,
  setQuestionnaire,
  indexOfQuestion,
}) => {
  // The question of the questionnaire that is being edited.
  const question = questionnaire.questions[indexOfQuestion];
  // The questionnaire object that allows you to edit the questionnaire.
  const questionnaireTemp = { ...questionnaire };
  // The list of questions of the questionnaire.
  const questionsTemp = [...questionnaire.questions];
  // The Type of question.
  const questionType = question.type;
  // The Type of question that will be used.
  let typeOfQuestion = <Radio />;
  // The title of the question.
  let questionTypeTitle = "Multiple Choice Question";
  // Changes the question to the correct type.
  if (questionType === "checkbox") {
    typeOfQuestion = <Checkbox />;
    questionTypeTitle = "Checkboxes";
  } else if (questionType === "text") {
    typeOfQuestion = <TextField label="Short-Answer Text" />;
    questionTypeTitle = "Short Answer";
  }
  // If the question is deleted then remove it from the questionnaire.
  const handleQuestionDelete = () => {
    questionsTemp.splice(indexOfQuestion, 1);
    questionnaireTemp.questions = [...questionsTemp];
    setQuestionnaire({ ...questionnaireTemp });
  };
  // If a new open is added to the question like another option for
  // multiple choice or for the checkbox.
  const handleAddOption = () => {
    questionsTemp[indexOfQuestion].options.push("");
    questionnaireTemp.questions = [...questionsTemp];
    setQuestionnaire({ ...questionnaireTemp });
  };

  // If an option is delete from a question.
  const handleDeleteOption = (optionInd) => {
    questionsTemp[indexOfQuestion].options.splice(optionInd, 1);
    questionnaireTemp.questions = [...questionsTemp];
    setQuestionnaire({ ...questionnaireTemp });
  };
  // If the user changes what the option is.
  const handleOptionChange = (value, optionInd) => {
    questionsTemp[indexOfQuestion].options[optionInd] = value;
    questionnaireTemp.questions = [...questionsTemp];
    setQuestionnaire({ ...questionnaireTemp });
  };
  // If the question is prompt is changed which is what the user will see as the question.
  const handleQuestionChange = (value) => {
    questionsTemp[indexOfQuestion].questionPrompt = value;
    questionnaireTemp.questions = [...questionsTemp];
    setQuestionnaire({ ...questionnaireTemp });
  };

  return (
    <div>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {questionTypeTitle}
        </Typography>
        <TextField
          margin="dense"
          id="questionPrompt"
          label="What is your question?"
          name="questionPrompt"
          value={question.questionPrompt}
          onChange={(e) => {
            handleQuestionChange(e.target.value);
          }}
          fullWidth
          required
        />
        <FormControl>
          <FormGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {question.options.map((option, optionInd) => (
              <span key={optionInd} style={{ display: "flex" }}>
                {questionType !== "text" && (
                  <IconButton onClick={handleAddOption}>
                    <AddCircleIcon />
                  </IconButton>
                )}
                <FormControlLabel disabled control={typeOfQuestion} />
                {questionType !== "text" && (
                  <TextField
                    margin="dense"
                    id={"option ".concat(optionInd + 1)}
                    label={"Option ".concat(optionInd + 1)}
                    name={"option"}
                    value={option}
                    onChange={(e) => {
                      handleOptionChange(e.target.value, optionInd);
                    }}
                    fullWidth
                    required
                  />
                )}
                {question.options.length > 1 && (
                  <IconButton onClick={() => handleDeleteOption(optionInd)}>
                    <ClearIcon />
                  </IconButton>
                )}
              </span>
            ))}
          </FormGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleQuestionDelete}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </div>
  );
};

EditorContent.propTypes = {
  questionnaire: PropTypes.object,
  setQuestionnaire: PropTypes.func,
  indexOfQuestion: PropTypes.number,
};

export default function QuestionEditorCard({
  questionnaire,
  setQuestionnaire,
  indexOfQuestion,
}) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <EditorContent
          questionnaire={questionnaire}
          setQuestionnaire={setQuestionnaire}
          indexOfQuestion={indexOfQuestion}
        />
      </Card>
    </Box>
  );
}

QuestionEditorCard.propTypes = {
  questionnaire: PropTypes.object,
  setQuestionnaire: PropTypes.func,
  indexOfQuestion: PropTypes.number,
};
