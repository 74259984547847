// Takes in the list of foodItems that were prebiously ordered in the exisiting
// order and the list of foodItems being ordered currently.  It combines these
// two list to make one complete list of all foodItems.
export default function AddFoodToExisting(existingFoodList, newFoodList) {
  // add the food Items from the previous order to the combined foodList
  const combinedFoodList = JSON.parse(JSON.stringify(existingFoodList));

  // Cycles through all the foodItems that are currently being ordered and adds
  // them to the old orders.  If the item already exists it updates the item
  // otherwise push the foodItem to the list.
  newFoodList.forEach((foodItem) => {
    // First look to see if the index exists where this foodItem already exists
    // in the combinedFoodList.
    const index = existingFoodList.findIndex(
      (item) =>
        item.item === foodItem.item &&
        item.description === foodItem.description &&
        item.farmName === foodItem.farmName,
    );

    // If the item exists already than just add the quantity to it otherwise push
    // the whole foodItem.
    if (index !== -1) {
      combinedFoodList[index].quantity += foodItem.quantity;
    } else {
      combinedFoodList.push({ ...foodItem });
    }
  });

  // Return the foodList
  return combinedFoodList;
}
