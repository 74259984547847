// This function updates the basket items array that the user has saved with them.
// The indexOfFoodItem is for when you add only one item to the basket or when
// you delete an item.   The delete prop is a boolean to indicate whether something
// is being deleted from the user's marketplace. itemQuantity is the quantity
// of the specific row that has been added to the basket.
export default function HandleBasketItems(
  userInfo,
  displayedFoodItems,
  indexOfFoodItem,
  deleteItem,
  itemQuantity,
  handleUserInfoChange,
) {
  // This is to copy the userInfo so we can use it.  First we need to check that
  // the current items in the basket are valid.
  const info = { ...userInfo };

  // First we check if the indexOfFoodItem was sent in.  If it is undefined we
  // know that all the rows are being affected so they must have clicked add all.
  // If it defined then that would indicate that a specific row action was specified.
  if (indexOfFoodItem === undefined) {
    // Cycle through all of the foodItems.
    displayedFoodItems.forEach((foodItem) => {
      // Check to see if the user is adding this item
      if (foodItem.quantity > 0) {
        // Check to see if this food item is already in the basket or if it
        // is a new food item.  Return the index of the basketItem.
        const index = info.basketItems.findIndex(
          (item) =>
            item.item === foodItem.item &&
            item.description === foodItem.description &&
            item.farmName === foodItem.farmName,
        );
        // If the item is already in your basket just add the new quantity
        if (index !== -1) {
          info.basketItems[index].quantity += foodItem.quantity;
        }
        // If this is a new item being added to the user's basket.
        else {
          info.basketItems.push({ ...foodItem });
        }
      }
    });
  }
  // If there is an indexOfFoodItem then we will be either deleting a row or
  // we are add only one item to the basket.
  else {
    // First we find the index of the of the basketItems array that matches
    // the foodItem row for the corresponding item.
    const index = info.basketItems.findIndex(
      (item) =>
        item.item === displayedFoodItems[indexOfFoodItem].item &&
        item.description === displayedFoodItems[indexOfFoodItem].description &&
        item.farmName === displayedFoodItems[indexOfFoodItem].farmName,
    );
    // If the user has selected to delete a row then we perform a delete.
    if (deleteItem) {
      // We check if this item is even in the basket and if it is we splice it
      // out of the basketItems array.  If it doesn't exist then we're already
      // good.
      if (index !== -1) {
        info.basketItems.splice(index, 1);
      }
    }
    // Finally if the user didn't delete and item then we know that they're
    // Adding just one item to their basket.
    else {
      // We first check if the item is already in the basket and if we're
      // simply just updating the new quantity.  If it isn't in the basket then
      // we add this item to the basketItems and update the basket field to true.
      if (index !== -1) {
        info.basketItems[index].quantity += itemQuantity;
      } else {
        info.basketItems.push({ ...displayedFoodItems[indexOfFoodItem] });
      }
    }
  }

  // Finally we update userInfo with our copy and return the newly updated
  // basketItems and the the documentName of the user's document
  handleUserInfoChange({ ...info }, true);
}
