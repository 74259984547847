// This dialog is displayed for a distribution location who is trying to remove a
// community location from their approved community hosts.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import RemoveCommunityLocation from "../../MyAccountPage/Functions/RemoveCommunityLocation.js";

// Remove the community location.
function RemoveCommunityLocationBox(props) {
  const {
    open,
    onClose,
    communityLocation,
    index,
    communityLocations,
    setCommunityLocations,
  } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleRemoveOrder = () => {
    RemoveCommunityLocation(
      communityLocation,
      index,
      communityLocations,
      setCommunityLocations,
    );
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Confirm Community Location Removal
      </DialogTitle>
      <DialogContent>
        Please confirm you wish to remove this community location. This action
        cannot be undone.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleRemoveOrder}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RemoveCommunityLocationBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function RemoveCommunityLocationDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="Order-Again-Button">
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          REMOVE COMMUNITY LOCATION
        </Button>
      </div>

      <RemoveCommunityLocationBox
        open={open}
        onClose={handleClose}
        communityLocation={props.communityLocation}
        index={props.index}
        communityLocations={props.communityLocations}
        setCommunityLocations={props.setCommunityLocations}
      />
    </div>
  );
}
