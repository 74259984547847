// When a distribution location saves their changes to an individual's payment
// history then we need to save these changes to the database.
// Arguments:
// order - the full order of the distribution location.
// deposits - an array with this user's deposits.
// refunds - an array with the user's refunds.
// notes - orderNotes for this user's order.
// imported - boolean if the user's order was imported by another location.
// location - used for if the user's order was imported and shows their original location.
// user - The user who's payment we're updating firstName-lastName-userId.
import firebase from "../../../components/Firebase.js";

export default function RecordPaymentHistory(
  orderDate,
  deposits,
  refunds,
  notes,
  imported,
  location,
  user,
  updatePaymentHistory,
) {
  // set up the datebase.
  const database = firebase.firestore();
  // eslint-disable-next-line no-unused-vars
  const batch = database.batch();

  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // Find the document where this order date is saved.
  const paymentHistoryCollectRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);

  // Pull the document for reading.
  paymentHistoryCollectRef
    .get()
    .then((collection) => {
      // Cycle through the documents but there should only be 1 that matches.
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since this document uses it's old data to update
          // in the future we need to make sure we're not overwriting changes that are
          // occuring at the same time as this update.
          return database
            .runTransaction((transaction) => {
              // Store the document's reference.
              const paymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(documentName);

              // This code may get re-run multiple times if there are conflicts.
              return transaction
                .get(paymentHistoryDocRef)
                .then((paymentHistoryDoc) => {
                  if (!paymentHistoryDoc.exists) {
                    // eslint-disable-next-line no-throw-literal
                    throw "Document does not exist!";
                  }

                  // Load the full document of this order Date.
                  const documentData = paymentHistoryDoc.data();

                  // Check to see if the order was imported if not then update the
                  // communityOrders.
                  if (!imported) {
                    // Update the three fields for payment.
                    documentData[orderDate].communityOrders[user].deposits =
                      deposits;
                    documentData[orderDate].communityOrders[user].orderNotes =
                      notes;
                    documentData[orderDate].communityOrders[user].orderRefunds =
                      refunds;
                  }
                  // If the user ordered through a location that was imported then
                  // update the imported order.
                  else {
                    // Update the three fields for payment.
                    documentData[orderDate].importedOrder[
                      location
                    ].communityOrders[user].deposits = deposits;
                    documentData[orderDate].importedOrder[
                      location
                    ].communityOrders[user].orderNotes = notes;
                    documentData[orderDate].importedOrder[
                      location
                    ].communityOrders[user].orderRefunds = refunds;
                  }

                  // Complete the transaction.  If the document has been changed then
                  // this document has to go again.
                  transaction.update(paymentHistoryDocRef, documentData);
                  // Update the paymentHistory dictionary
                  updatePaymentHistory(documentData, orderDate);
                });
            })
            .then(() => {})
            .catch((err) => {
              // Send an alert to the user to tell them of the error.
              // eslint-disable-next-line no-undef
              alert(err);
              // Return false that the transaction failed.
              return false;
            });
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
