// A function that is called by MyAccount, Basket and Checking for customer order
// updates that splits up community orders by distribution location and community hosts.

// This function takes community orders which is a dictionary of each order placed
// for this distribution location as the argument.

// It organises the community orders so that any users that ordered multiple times
// gets combined into one order and then also creates the string that the distribution
// location will need to download the orders.
import CreateDownloadOrdersString from "./CreateDownloadOrdersString.js";
import OrganizeCommunityOrders from "./OrganizeCommunityOrders.js";
import SeparateByPickupLocation from "./SeparateByPickupLocation.js";
import CompareItemNames from "../../../functions/CompareItemNames.js";

export default function CreateVisibleCommunityOrders(
  communityOrders,
  setDownloadString,
  setDownloadGridString,
  editing,
  removedCommunityHosts,
  selectedDate,
) {
  // Creates a string that saves the orders of all the users in a receipt.  This
  // then used to create the docx file when a user downloads the receipts
  let orderString = "";

  // Creates a string that saves the orders of all the users in grid format.  This
  // then used to create the docx file when a user downloads the receipts in grid format.
  let orderGridString = "";

  // The modified community order dictionary
  let communityOrdersByLocationDict = {};

  // The modified community order dictionary
  const communityOrdersDict = {};

  // In case there are not communityOrders then return null
  if (communityOrders === undefined) {
    return [null, []];
  }

  // Create a dictionary with each distribution location and community host as the key
  // and then their community orders as their values.
  communityOrdersByLocationDict = SeparateByPickupLocation(
    communityOrders,
    removedCommunityHosts,
  );

  // Sort the foodList to be displayed alphabetically.  This is important to sort
  // for when the user is looking at the editing.  Also add in the selectedDate
  Object.keys(communityOrdersByLocationDict).forEach((location) => {
    Object.keys(communityOrdersByLocationDict[location]).forEach(
      (userOrder) => {
        // If the selectedDate exists then we will add it to the user so it can be used.
        if (selectedDate !== undefined) {
          communityOrdersByLocationDict[location][userOrder].distributionDate =
            selectedDate;
        }
        communityOrdersByLocationDict[location][userOrder].foodList.sort(
          (a, b) => {
            return CompareItemNames(a, b, "item");
          },
        );
      },
    );
  });

  // Cycle through each location and combine the community orders so that users
  // that ordered multiple orders are combined into one order.
  Object.keys(communityOrdersByLocationDict).forEach((location) => {
    communityOrdersDict[location] = OrganizeCommunityOrders(
      communityOrdersByLocationDict[location],
    );
  });

  // Sort the foodList to be displayed alphabetically
  Object.keys(communityOrdersDict).forEach((location) => {
    Object.keys(communityOrdersDict[location]).forEach((userOrder) => {
      communityOrdersDict[location][userOrder].foodList.sort((a, b) => {
        return CompareItemNames(a, b, "item");
      });
    });
  });

  // Set the string that is used to display the print outs of the community orders
  if (setDownloadString) {
    // Create a string that is used for the distribution location to download the orders.
    [orderString, orderGridString] = CreateDownloadOrdersString(
      communityOrdersDict,
      selectedDate,
    );
    setDownloadString(orderString);
    setDownloadGridString(orderGridString);
  }

  // If the user is editing than return the community orders that have not combined
  // a user's multiple orders otherwise return the combined version.
  if (editing) {
    return communityOrdersByLocationDict;
  } else {
    return communityOrdersDict;
  }
}
