// This is old code that was used before the imported orders was changed and multiple
// orders can be imported.  June 17 2021.

// This is to remove an individual user's order from the myAccount orderHistory
// page.
import firebase from "./../Firebase.js";

// order: includes the user's full order {...}
// user : the user in the format FirstName-LastName-UserId-orderDate
// imported: boolean if this order was imported
// userInfo : the distribution location's userInfo
// orderIndex : the index of the distribution location's order in the orderLog (not the individual's order)
// orderLog : the distribution locations orders [...]
// updateOrderLog : a function to update the orderLog
export default function RemoveCustomerOrderOld(
  order,
  user,
  imported,
  userInfo,
  orderIndex,
  orderLog,
  updateOrderLog,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Updates the distribution location's order history
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(order.orderDate);

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userId[3]);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // Assume the order is coming from the communityOrders this is used to set the
  // database name
  let userDataRef = "communityOrders";

  if (imported) {
    // If the imported Orders are being modified change this to be correct
    userDataRef = "importedOrder";

    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(order.importedDistributionLocation)
      .collection("CommunityOrders")
      .doc(order.importedDistributionDate);
  } else {
    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);
  }

  // Add the user to the imported or community orders to correctly update the nested
  // data
  userDataRef = userDataRef.concat(".", user);

  // This is to make sure the page rerenders these items correctly
  if (imported) {
    delete orderLogTemp[orderIndex].importedOrder[user];
  } else {
    delete orderLogTemp[orderIndex].communityOrders[user];
  }

  // Remove the order from the distirbution location's order history
  batch.update(docRef, {
    [userDataRef]: firebase.firestore.FieldValue.delete(),
  });

  // Remove the order from the DistributionLocation collection
  batch.update(communityOrdersDocRef, {
    [user]: firebase.firestore.FieldValue.delete(),
  });

  // Delete the order from the individual's order
  userDocRef
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });

  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
