// This function takes in a paymentHistory and checks if the paymen history is in
// sync with the database or if it still needs to be saved.
export default function OrderSyncCheck(
  orderNotes,
  userPaymentHistory,
  syncOrderNotes,
  deposits,
  syncDeposits,
  syncDepositsLength,
  refunds,
  syncRefunds,
  syncRefundsLength,
) {
  // If orderNotes has been changed and is not longer matching the value saved in
  // the database.
  if (orderNotes !== userPaymentHistory.orderNotes) {
    // Set the order notes to false.
    syncOrderNotes = false;
  }

  // Checks to see if the deposits lengths has changed because a deposit has been
  // added or removed.
  if (deposits.length === userPaymentHistory.deposits.length) {
    // Cycle through the deposits and check that they are each matching.
    deposits.forEach((deposit, index) => {
      if (
        deposit.amount === userPaymentHistory.deposits[index].amount &&
        deposit.date === userPaymentHistory.deposits[index].date &&
        deposit.method === userPaymentHistory.deposits[index].method
      ) {
        // Add that each deposit is synced.
        syncDeposits.push(true);
      } else {
        // Add that this deposit is not synced.
        syncDeposits.push(false);
      }
    });
  }
  // If the length is not the same determine if a deposit was removed or added.
  else {
    // The new list is shorter because an deposit was removed and so it is the restraining list.
    let restrainedList = deposits;
    // The old list is longer because an deposit was removed and so it is unrestrainedList
    let unrestrainedList = userPaymentHistory.deposits;

    // If the deposits list is longer then it had a deposit added and so the restraining
    // list is the old deposit list.
    if (deposits.length > userPaymentHistory.deposits.length) {
      restrainedList = userPaymentHistory.deposits;
      unrestrainedList = deposits;
    } else {
      // A deposit was removed so we set the deposit sync length list to false.
      syncDepositsLength = false;
    }

    // Cycle through the restained list and check if they are in sync or not.  If
    // any deposits are added they will be undefined and so false by nature.
    restrainedList.forEach((deposit, index) => {
      if (
        deposit.amount === unrestrainedList[index].amount &&
        deposit.date === unrestrainedList[index].date &&
        deposit.method === unrestrainedList[index].method
      ) {
        syncDeposits.push(true);
      } else {
        syncDeposits.push(false);
      }
    });
  }

  // Checks to see if the refunds lengths has changed because a refund has been
  // added or removed.
  if (refunds.length === userPaymentHistory.orderRefunds.length) {
    // Cycle through the refunds and check that they are each matching.
    refunds.forEach((refund, index) => {
      if (
        refund.amount === userPaymentHistory.orderRefunds[index].amount &&
        refund.date === userPaymentHistory.orderRefunds[index].date
      ) {
        // Add that each refund is synced.
        syncRefunds.push(true);
      } else {
        // Add that this refund is not synced.
        syncRefunds.push(false);
      }
    });
  }
  // If the length is not the same determine if a refund was removed or added.
  else {
    // The new list is shorter because an refund was removed and so it is the restraining list.
    let restrainedList = refunds;
    // The old list is longer because an refund was removed and so it is unrestrainedList
    let unrestrainedList = userPaymentHistory.orderRefunds;

    // If the refunds list is longer then it had a refund added and so the restraining
    // list is the old refund list.
    if (refunds.length > userPaymentHistory.orderRefunds.length) {
      restrainedList = userPaymentHistory.orderRefunds;
      unrestrainedList = refunds;
    } else {
      // A refund was removed so we set the refund sync length list to false.
      syncRefundsLength = false;
    }

    // Cycle through the restained list and check if they are in sync or not.  If
    // any refunds are added they will be undefined and so false by nature.
    restrainedList.forEach((refund, index) => {
      if (
        refund.amount === unrestrainedList[index].amount &&
        refund.date === unrestrainedList[index].date
      ) {
        syncRefunds.push(true);
      } else {
        syncRefunds.push(false);
      }
    });
  }

  return [
    syncOrderNotes,
    syncDeposits,
    syncDepositsLength,
    syncRefunds,
    syncRefundsLength,
  ];
}
