// This is used to under the communications tab to display the tabs of
// Announcements and the Questionnaires.
import React from "react";
import Tabs from "../../../components/Tabs.js";
import MyCommunityLocation from "../Components/MyCommunityLocation.jsx";
import IndividualVolunteeringSchedule from "../Components/VolunteerSchedule/IndividualVolunteeringSchedule.jsx";
import PropTypes from "prop-types";

export default function CommunityIndividual({ userInfo }) {
  return (
    <div className="Tab-Box">
      <Tabs>
        <div label="My Community Location">
          <MyCommunityLocation userInfo={userInfo} />
        </div>
        <div label="Volunteering">
          <IndividualVolunteeringSchedule userInfo={userInfo} />
        </div>
      </Tabs>
    </div>
  );
}

CommunityIndividual.propTypes = {
  userInfo: PropTypes.object,
};
