import React from "react";
import AnnouncementForm from "../../../components/userForms/AnnouncementForm.js";

// This is the Announcements Info Tab
export default function Announcement({
  announcementOn,
  announcementTitle,
  announcementDescription,
  announcementImage,
  announcementWebsite,
  handleInfoUpdate,
  userInfo,
}) {
  return (
    <div className="Tab-Box">
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Announcement On: </h5>
        <p className="My-Account-Item"> {announcementOn.toString()} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Announcement Title: </h5>
        <p className="My-Account-Item"> {announcementTitle} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Description of event or announcement: </h5>
        <p className="My-Account-Item"> {announcementDescription} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title">
          {" "}
          Image of poster or flyer (if applicable):{" "}
        </h5>
        <a
          href={announcementImage}
          className="My-Account-Item"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* This is to make sure the image url doesn't take up too much space on the screen. It gets clipped at 60 characters */}
          {announcementImage.length > 60
            ? `${announcementImage.substring(0, 60)}...`
            : announcementImage}
        </a>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Announcement website (if applicable): </h5>
        <a
          href={announcementWebsite}
          className="My-Account-Item"
          target="_blank"
          rel="noopener noreferrer"
        >
          {announcementWebsite}
        </a>
      </div>
      <AnnouncementForm
        announcementOn={announcementOn}
        announcementTitle={announcementTitle}
        announcementDescription={announcementDescription}
        announcementImage={announcementImage}
        announcementWebsite={announcementWebsite}
        handleInfoUpdate={handleInfoUpdate}
        userInfo={userInfo}
      />
    </div>
  );
}
// Set the default values.
Announcement.defaultProps = {
  announcementOn: false,
  announcementTitle: "",
  announcementDescription: "",
  announcementImage: "",
  announcementWebsite: "",
};
