// Adds a new volunteer position to the database when the admin user creates one.
import firebase from "../../../../components/Firebase.js";

export default function AddVolunteerPosition(
  volunteerPosition,
  volunteerJobs,
  setVolunteerJobs,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Finds the volunteer database collection and names a document using the title.
  const volunteerDocRef = database
    .collection("Volunteer")
    .doc(volunteerPosition.title);

  // Sets the batch.
  batch.set(volunteerDocRef, volunteerPosition);

  // Commits the changes.
  batch.commit();

  // Creates a temp array with the other volunteer jobs if they exist.
  const volunteerJobsTemp = [...volunteerJobs];

  // Adds this new position to the array.
  volunteerJobsTemp.push(volunteerPosition);

  // Updates the voluneer array.
  setVolunteerJobs(volunteerJobsTemp);
}
