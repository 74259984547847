// This dialog appears when the admin user is creating or editing a volunteer position
// and they are add a shift to the position.
import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SimpleSelect from "../../../../components/userInputs/DropDownMenu.js";
import Stack from "@mui/material/Stack";

// This is the dialog box that opens when a user clicks the edit button
function VolunteerShiftCreationBox({
  open,
  onClose,
  shiftsAvailable,
  setShiftsAvailable,
}) {
  // The start time of the shift.
  const [startTime, setStartTime] = useState(dayjs("2022-01-01T21:11:54"));
  // The end time of the shift.
  const [endTime, setEndTime] = useState(dayjs("2022-01-01T21:11:54"));
  // The days of the week to select from
  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // The chosen day of the week for the shift.
  const [choosenDayOfTheWeek, setChoosenDayOfTheWeek] = useState("Sunday");
  // The frequency of the shift.
  const [frequency, setFrequency] = useState("");

  // If the user closes then close the dialog and if they cancel
  // their changes then set everything back to what the original values were
  // sent in through the volunteerPosition.
  const handleClose = () => {
    onClose(false);
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();
    // The current shifts available.
    let shiftsAvailableTemp = [];
    // If the shiftsAvailable exists then we will add those to the temp.
    if (shiftsAvailable !== undefined) {
      shiftsAvailableTemp = [...shiftsAvailable];
    }
    // The start time in HHMM so we can use it to save.
    const startTimeHHMM = [
      dayjs(startTime).format("HH"),
      dayjs(startTime).format("mm"),
    ].join(":");
    // The end time in HHMM so we can use it to save.
    const endTimeHHMM = [
      dayjs(endTime).format("HH"),
      dayjs(endTime).format("mm"),
    ].join(":");
    // Add this new shift to the available shift list.
    shiftsAvailableTemp.push({
      DayOfTheWeek: choosenDayOfTheWeek,
      StartTime: startTimeHHMM,
      EndTime: endTimeHHMM,
      Frequency: frequency,
    });
    setShiftsAvailable([...shiftsAvailableTemp]);
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Add a Volunteer Shift
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <SimpleSelect
            margin="dense"
            id="selectOrderStatsLocation"
            options={daysOfTheWeek}
            handleChange={(e) => setChoosenDayOfTheWeek(e.target.value)}
            selected={choosenDayOfTheWeek}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              TrapFocusProps={{ disableEnforceFocus: true }}
              value={startTime}
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <TimePicker
              label="End Time"
              TrapFocusProps={{ disableEnforceFocus: true }}
              value={endTime}
              onChange={(newValue) => {
                setEndTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <TextField
            id="frequency"
            label="Frequency"
            name="frequency"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            fullWidth
            required
          />
        </Stack>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

VolunteerShiftCreationBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function VolunteerShiftCreationDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        Volunteer Shifts <AddCircleIcon />
      </IconButton>
      <VolunteerShiftCreationBox
        open={open}
        onClose={handleClose}
        shiftsAvailable={props.shiftsAvailable}
        setShiftsAvailable={props.setShiftsAvailable}
      />
    </div>
  );
}
