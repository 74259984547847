// Section of the homepage that explains what the program is and has a link to
// the how it works page.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function ExplanationSection() {
  return (
    <div className="w3-padding-64" id="about">
      <div>
        <h1 className="Header-Fonts">By shopping farm-to-plate, you:</h1>
        <div className="How-It-Works">
          <img
            width="100%"
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczMpHozbin2uJLEVe-Dd41wAVbLHzj_-gopX6bZLO6msfMh55MMr916WWzCO6bS-aEB2dC5C2eiSaxO2UXfoIKedR2rdR0ODa-_8LBQBQl31Shj_z-42dqx9s3RPdzlrOdAV2U1L_AyTI9XK-cbrotRh=w1514-h250-s-no-gm?authuser=1"
          />
          <img
            width="100%"
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczNXz2B9ypjHM7oaW0k1IzuWKn-cG5Pyu3NPrJkWcD5C40vBYPayjMWh4aOqRxzMfF-7YexuRclZxIwu8Jf6hGlJ-_T6YCTMytb1PEFwnmZZcUNvzPFpTl396n6bgesEiD-VIukFAKC-pMj0bEL1frq0=w1514-h331-s-no-gm?authuser=1"
          />
          <img
            width="100%"
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczOaCWnFIeTYfH3Z07vStHEXWAJQy6XYssK5zEmiJNfyznhGf0jIC9RK1beAEkgpQpU7wv4T-8uXoO4E9jxSUgmORhAIWjHhYkrFoiWVH-79mzcfTX8iSWUQnnchKJpAz1YTjewzge2c1EzwtSTSQqqw=w1514-h312-s-no-gm?authuser=1"
          />
          <img
            width="100%"
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczN-GaRxUNKcKF6pjuS-rb4bT3LkU-EXDZHFH7xedqU3vBOCsY20PkLetVG2Bl8eZNrHhgOWnKHjPbTjqKzq46vpJ5AxGQmzlCElpEqQ2WW63ICFpuCYYWWDVHIJbaXIa3gRLuIEBE77G19al2w-0zPU=w1514-h263-s-no-gm?authuser=1"
          />
          <img
            width="100%"
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczM-oNmFq9hNvPgHqMhmfDs9fLmmjNhlMGCuV-Kwuwd2_t3dyV-6xn7Bxwso9oOBWvWtw03Dl_ZtCtRbJes7PxJiLxjwNCjYbCVXC36M6rbEQCfo7s4Z9G6-5n6IqkC71ozPEfgT5Bqd3qViUcYwMyl-=w1514-h250-s-no-gm?authuser=1"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link style={{ textDecoration: "none" }} to="/HowItWorks">
            <Button
              style={{ background: "#45AA45", color: "white" }}
              size="large"
              variant="contained"
            >
              LEARN MORE ABOUT HOW WE WORK!
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
