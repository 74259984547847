// This is the community Engagement section of the homepage.  It has pictures of
// the community and a link to the community board.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function CommunityBoardSection() {
  return (
    <div id="communityBoard">
      <h1 className="Community-Board-Header">Now What?</h1>
      <div className="What-Next-Container">
        <div className="Community-Image-Container">
          <div className="What-Next-Text">
            <h3 className="Image-Header">Join Your Community’s Order</h3>
            <p className="Subtext">
              {" "}
              Together, we order, so that individually we can choose. Place your
              custom order weekly.
            </p>
            <Link style={{ textDecoration: "none" }} to="/Marketplace">
              <Button
                style={{ background: "#45AA45", color: "white" }}
                size="large"
                variant="contained"
              >
                VISIT MARKETPLACE
              </Button>
            </Link>
          </div>

          <img
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczOuNbECRsEyhmHD8soVnLVKJwRFGuEurW1Z15biUoSgaFrEj1XGnU4i4GlzldqOv5RFTwoBtwe4pt2p7KZe7AeRfWU8FS7aywn0_zgPEHbspY8oKzznQ7fYTMiipNMZg9XnWH56dtVuIbCzNbj4ucGj=w751-h727-s-no-gm?authuser=1"
            style={{ padding: "20px" }}
          />
        </div>

        <div className="Community-Image-Container">
          <div className="What-Next-Text">
            <h3 className="Image-Header">Participate In Your Food System</h3>
            <p className="Subtext">
              Come participate, discuss and learn about your food system.
            </p>
            <Link style={{ textDecoration: "none" }} to="/CommunityEngagement">
              <Button
                style={{ background: "#45AA45", color: "white" }}
                size="large"
                variant="contained"
              >
                HEAD TO COMMUNITY PAGE
              </Button>
            </Link>
          </div>

          <img
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczOS1KlINcWGZpkwdGZ6e41uyx1yE2BocG-TvHABDj-6452FdogCA2t70-cgNZlGRIPmJjMRU-MAONLaivpR9UXBlYy7gdXwBFei3FfYWnlG6cfNYndAg-EehHu9fQ-X5IWK_9Tdn0tBJLVUHVABmI3G=w732-h723-s-no-gm?authuser=1"
            style={{ padding: "20px" }}
          />
        </div>
      </div>
    </div>
  );
}
