// Renders an order with it's specific payment history details.  It also includes a
// button to view the details of each specific community member's order.
import React from "react";
import PaymentHistoryDetailsDialog from "./../Dialogs/PaymentHistoryDetailsDialog.jsx";
import { Grid } from "@material-ui/core";

export default function OrderPaymentListItem({
  paymentOrderDetails,
  orderPaymentHistory,
}) {
  // This is the year the order was placed YYYY
  const year = paymentOrderDetails.orderDate.substring(0, 4);
  // This is the month the order was placed MM
  const month = paymentOrderDetails.orderDate.substring(4, 6);
  // This is the day the order was placed DD
  const day = paymentOrderDetails.orderDate.substring(6, 8);
  const date = month + "-" + day + "-" + year;

  // This is used to highlight orders need attention.  Red for Refunds needed, Green
  // for orders that have money owed, or nothing for when an order has payment complete.
  let paymentIndicatorColour = "rgb(255 255 255)";
  // IF the order has user's that owe money then set to green.
  if (paymentOrderDetails.owed > 0.02) {
    paymentIndicatorColour = "rgb(118 253 122)";
  }
  // If the order has user's that need a refund set to red.
  else if (paymentOrderDetails.refunds < -0.02) {
    paymentIndicatorColour = "rgb(255 112 112)";
  }
  // If there are no owed or refunds needed for this order then set the colour to
  // white which matches the background.
  else {
    paymentIndicatorColour = "rgb(255 255 255)";
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      style={{ borderBottom: "solid" }}
    >
      <Grid item xs={4}>
        <h5 style={{ backgroundColor: paymentIndicatorColour }}>
          {" "}
          Ordered On {date}
        </h5>
        {paymentOrderDetails.imported && (
          <h7 style={{ color: "red" }}>
            Imported from {paymentOrderDetails.distributionLocation}
          </h7>
        )}
      </Grid>
      <Grid item xs={6}>
        <h5>Payment Collected : ${paymentOrderDetails.collected.toFixed(2)}</h5>
        <h5>Payment Owed : ${paymentOrderDetails.owed.toFixed(2)}</h5>
        <h5>Refunds Needed : ${paymentOrderDetails.refunds.toFixed(2)}</h5>
        <h5>Distributed Date : {paymentOrderDetails.distributedDate}</h5>
      </Grid>
      <Grid item xs={2}>
        <PaymentHistoryDetailsDialog
          paymentOrderDetails={paymentOrderDetails}
          totalCollected={paymentOrderDetails.collected.toFixed(2)}
          totalOwed={paymentOrderDetails.owed.toFixed(2)}
          totalRefundsNeeded={paymentOrderDetails.refunds.toFixed(2)}
          orderPaymentHistory={orderPaymentHistory}
        />
      </Grid>
    </Grid>
  );
}
