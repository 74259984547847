// This function will calcualate the amounts owed by a user who's order has been
// updated.  It will take in the original cost of the user's orders and their new order
// and then calculate the difference and write to the database.  This is when a user
// has an item removed or added directly to their order.
import firebase from "../../../../components/Firebase.js";
import YYYYMMDDConverter from "../../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";

export default function AddRemoveItemPaymentHistory(
  orderDate,
  imported,
  foodItem,
  user,
  distributionLocation,
  addItem,
  updatePaymentHistory,
  volunteerFeeChange,
) {
  // The price of the item that is being removed.
  let individualPrice =
    (foodItem.price / foodItem.distributionQuantity) *
    foodItem.individualQuantity;
  // The individual description of the food item for the user to know how
  // much they are purchasing.
  let individualDescription = foodItem.individualDescription;
  // if suggestedPrice is true then we want to set the price to 0
  if (foodItem.suggestedPrice) {
    // First add to the description the suggested Donation.
    individualDescription = individualDescription.concat(
      " - Suggested Donation of $",
      individualPrice,
    );
    // Then reset the prices to 0.
    individualPrice = 0.0;
  }

  // This will be the added cost of adding this item with it's quantity.
  let priceChange = foodItem.quantity * individualPrice;
  // If there is a package fee then include that amount too.
  if (foodItem.packageFee > 0) {
    priceChange += foodItem.packageFee * foodItem.quantity;
  }

  // Make the orderNotes string an empty string.
  let orderNotes = "";

  // User includes a date at the end of it we want to only use the
  // firstName, lastName and userId and save that in a userPointer.
  const userPointer = [
    user.split("-")[0],
    user.split("-")[1],
    user.split("-")[2],
  ].join("-");

  // If the item is being added or removed from the order.
  if (addItem) {
    // Set the order note of the item being added. Periods separate the order notes so only use one period per order note.
    orderNotes = ` ${foodItem.item} x${
      foodItem.quantity
    } added to order at ${individualPrice} per ${individualDescription}.  Added $${volunteerFeeChange.toFixed(
      2,
    )} to the volunteer fee cash portion.`;
  } else {
    // Set the order note of the item being removed. Periods separate the order notes so only use one period per order note.
    orderNotes = ` ${foodItem.item} x${
      foodItem.quantity
    } removed from order at ${individualPrice} per ${individualDescription}. Removed $${-volunteerFeeChange.toFixed(
      2,
    )} from the volunteer fee cash portion.`;
  }

  // Stores the specific dates payment history. Includes the importedOrder, the communityOrders
  // and the selectedDate.  Will be updated and used to update the database.
  let orderDatePaymentHistory = {};
  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // set up the datebase.
  const database = firebase.firestore();
  // eslint-disable-next-line no-unused-vars
  const batch = database.batch();

  // Find the document where this order date is saved.
  const paymentHistoryCollectRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);

  // Pull the document for reading.
  paymentHistoryCollectRef
    .get()
    .then((collection) => {
      // Cycle through the documents but there should only be 1 that matches.
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since we'll be adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database
            .runTransaction((transaction) => {
              // Store the document's reference.
              const paymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(documentName);

              // This code may get re-run multiple times if there are conflicts.
              return transaction
                .get(paymentHistoryDocRef)
                .then((paymentHistoryDoc) => {
                  if (!paymentHistoryDoc.exists) {
                    // eslint-disable-next-line no-throw-literal
                    throw "Document does not exist!";
                  }

                  // Find the year month day of current time to update the order history
                  // with a timestamp of the changes.
                  const now = YYYYMMDDConverter();

                  // Load the full document of this order Date.
                  const documentData = paymentHistoryDoc.data();

                  // Store the current orderDate.
                  orderDatePaymentHistory = paymentHistoryDoc.data()[orderDate];

                  // Store the communityOrders of this date.
                  let communityOrdersPaymentHistory =
                    orderDatePaymentHistory.communityOrders;
                  if (imported) {
                    communityOrdersPaymentHistory =
                      orderDatePaymentHistory.importedOrder[
                        distributionLocation
                      ].communityOrders;
                  }

                  // if the item is being added and not removed.
                  if (addItem) {
                    // Add the new orders amount to their old amount.
                    communityOrdersPaymentHistory[userPointer].totalOwed +=
                      priceChange + volunteerFeeChange;
                  } else {
                    // Subtract the orders amount to their old amount.
                    communityOrdersPaymentHistory[userPointer].totalOwed -=
                      priceChange - volunteerFeeChange;
                  }

                  // Add this new order's total and current date to the orderHistory.
                  communityOrdersPaymentHistory[userPointer].orderHistory[now] =
                    communityOrdersPaymentHistory[userPointer].totalOwed;
                  // Update the notes to inlcude the additional order added.
                  communityOrdersPaymentHistory[userPointer].orderNotes =
                    communityOrdersPaymentHistory[
                      userPointer
                    ].orderNotes.concat(orderNotes);

                  if (!imported) {
                    // Update the Payment History community orders.
                    orderDatePaymentHistory.communityOrders =
                      communityOrdersPaymentHistory;
                  } else {
                    // Update the Payment History imported orders.
                    orderDatePaymentHistory.importedOrder[
                      distributionLocation
                    ].communityOrders = communityOrdersPaymentHistory;
                  }

                  // Update the full documentName to include the changes for this date.
                  documentData[orderDate] = orderDatePaymentHistory;

                  // Complete the transaction.  If the document has been changed then
                  // this document has to go again.
                  transaction.update(paymentHistoryDocRef, documentData);
                  // Update the paymentHistory dictionary
                  updatePaymentHistory(documentData, orderDate);
                });
            })
            .then(() => {})
            .catch((err) => {
              // Send an alert to the user to tell them of the error.
              // eslint-disable-next-line no-undef
              alert(err);
              // Return false that the transaction failed.
              return false;
            });
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
