// This line is used on the individual user's basket order summary.  This shows how much
// each farmer is paid as well as provides a link to the description on the website.
import React from "react";
import { Button } from "@material-ui/core";

// This is used to display the total amount owed to each farm.  It is called for
// each total that exists and will print out the "FarmName" : "Total".  It will
// also check the minimumOrder of the farm and if it has been reached.  If not
// then it will display the total in red indicating it's insuffienient.
export default function FarmTotalLineIndividual(
  farmTotals,
  farmTotal,
  farmerInfo,
  idx,
  userType,
  packageTotals,
) {
  // farmTotal is a dictionary that has the "farmName" : "$$$" so first you have
  // to find the info on the farm currently being used
  const index = farmerInfo.findIndex((farm) => {
    return farm.farmName === farmTotal;
  });
  if (index === -1) {
    return;
  }
  // Assign the minimumOrder of this farm to a variable
  // eslint-disable-next-line no-unused-vars
  const minimumOrder = farmerInfo[index].minimumOrder;

  // Link to the farmer's description.
  const linkToFarmDescription = "/Farms/#".concat(farmTotal.replace(/\s/g, ""));

  return (
    <p
      className="Farm-Total-Line-Individual"
      key={farmerInfo[index].farmName + index}
    >
      <a
        style={{ textDecoration: "none", marginRight: "5px" }}
        href={linkToFarmDescription}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant="contained" size="small" color="primary">
          {farmTotal}
        </Button>{" "}
        :
      </a>
      <span>
        ${farmTotals[farmTotal].toFixed(2)}
        {packageTotals[farmTotal] > 0 && (
          <span style={{ verticalAlign: "super" }} className="Item-Package-Fee">
            + ${packageTotals[farmTotal].toFixed(2)} pkg fee
          </span>
        )}
      </span>
    </p>
  );
}
