import React from "react";
import firebase from "../../../../components/Firebase.js";
import IncrCounter from "../../../../components/userInputs/IncrementalCounter.js";
import { Button } from "@material-ui/core";

// Creates each basket item row of the basket information displayed
export default class RestaurantBasketFoodRow extends React.Component {
  constructor(props) {
    super(props);
    this.database = firebase.firestore();
  }

  // Removes the basketRow Item and sets the quantity to 0 by updating the database
  // It will also call handleRefresh and remove the farm from the list of providing
  // farms
  handleRemove = () => {
    const itemToBeRemoved = (
      this.props.row.item +
      this.props.row.description +
      this.props.row.farmName
    ).replace(/\s+/g, "");
    this.props.handleRefresh(this.props.row.farmName, itemToBeRemoved);

    console.log("Removed from Basket", itemToBeRemoved);
  };

  incrementItem = () => {
    this.props.handleQuantityChange(
      true,
      (
        this.props.row.item +
        this.props.row.description +
        this.props.row.farmName
      ).replace(/\s+/g, ""),
    );
  };

  decreaseItem = () => {
    if (this.props.row.quantity === 1) {
      this.handleRemove();
    } else {
      this.props.handleQuantityChange(
        false,
        (
          this.props.row.item +
          this.props.row.description +
          this.props.row.farmName
        ).replace(/\s+/g, ""),
      );
    }
  };

  render() {
    return (
      <div
        id={this.props.row.item + "-" + this.props.idx}
        className="Food-Table"
      >
        <div className="Restaurant-Basket-Cell">{this.props.row.item}</div>
        <div className="Restaurant-Basket-Cell">
          {this.props.row.description}
        </div>
        <div className="Restaurant-Basket-Cell">{this.props.row.farmName}</div>
        <div className="Restaurant-Basket-Cell">
          <IncrCounter
            quantity={this.props.row.quantity}
            incrementItem={this.incrementItem}
            decreaseItem={this.decreaseItem}
          />
          <div className="Weekly-Checkbox">
            <label> Weekly </label>
            <br />
            <input
              checked={this.props.checked}
              type="checkbox"
              id="recurring"
              name="recurring"
              onChange={() => this.props.handleCheckboxChange(this.props.idx)}
            />
          </div>
        </div>
        <div className="Restaurant-Basket-Cell">
          <Button onClick={this.handleRemove} color="secondary">
            Remove
          </Button>
        </div>
      </div>
    );
  }
}
