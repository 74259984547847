// This called to apply for access to a community host.  This occurs after receving
// access to a distribution location's community hosts.  The user will click the button
// "Request Access" on the distribution selection screen.
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../components/authentication/Auth.js";
import ColourButton from "../../../components/userInputs/ColourButton.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
import RequestCommunityHostAccess from "../Functions/RequestCommunityHostAccess.jsx";
import TextField from "@material-ui/core/TextField";

function SimpleDialog({
  onClose,
  open,
  userInfo,
  locationDetails,
  setSendingEmail,
}) {
  const { handleUserInfoChange } = useContext(AuthContext);
  // This is a personal message written by the community member to give the host more
  // context of who this person is.
  const [personalMessage, setPersonalMessage] = useState("");
  // This is the placeholder text to let the user know what to write in the text box.
  const placeholderText = `Please introduce yourself to ${locationDetails.firstName}.  Ex. Why you care about eating locally, how long have you lived in Vancouver, the neighbourhood you live, etc.`;

  // This is set to false until the data from the database been loaded
  const handleClose = () => {
    onClose();
  };

  // Sends emails to the users and the distribution location.
  const handleSend = () => {
    setSendingEmail(true);
    RequestCommunityHostAccess(
      locationDetails,
      userInfo,
      handleUserInfoChange,
      setSendingEmail,
      personalMessage,
    );
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Request Access from {locationDetails.locationName}
      </DialogTitle>
      <DialogContent>
        Please remember to be respectful. Check your inbox for an email
        connecting you and <b>{locationDetails.firstName}</b> once you&apos;ve
        sent your request.
        <form onSubmit={handleSend}>
          <TextField
            margin="dense"
            id="personalMessage"
            label="Personal Message"
            name="personalMessage"
            placeholder={placeholderText}
            value={personalMessage}
            onChange={(e) => setPersonalMessage(e.target.value)}
            fullWidth
            required
            multiline
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              CANCEL
            </Button>
            <Button type="submit" color="primary">
              SEND REQUEST
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function RequestCommunityHostAccessDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ColourButton
        hoverColour="#bc61b9"
        colour="#e874e4"
        title="REQUEST ACCESS"
        onClick={handleClickOpen}
      />
      {open && (
        <SimpleDialog
          open={open}
          onClose={handleClose}
          userInfo={props.userInfo}
          locationDetails={props.locationDetails}
          setSendingEmail={props.setSendingEmail}
        />
      )}
    </div>
  );
}
