// This line shows the user's of a community location what items
// are available and let's them choose which items they want at their
// location.
import React, { useState, useEffect } from "react";
import ItemAvailableDetails from "./ItemAvailableDetails";
import Switch from "@material-ui/core/Switch";

export default function FoodItemAvailability({
  index,
  row,
  handleSwitch,
  communityLocation,
}) {
  // Let the visibility shows whether the item is visible to the main hub.
  let visible = row.adminVisible;
  // Set the userId of the distribution location.
  const distributionLocationUserId = communityLocation.pickupLocation.userId;
  // Check to see if distributionLocationVisible key exists.
  if (row.distributionLocationVisible || row.limit != null) {
    // If the row limit exists then it's a pantry item and is visible
    // at the location.
    if (row.limit != null) {
      // set it to true
      visible = true;
    }
    // Check to see if this distribution location has set this item to be visibile
    // on their own marketplace to individuals.
    else if (
      row.distributionLocationVisible.includes(distributionLocationUserId)
    ) {
      // set it to true
      visible = true;
    } else {
      // set visibility to individual users to false.
      visible = false;
    }
  } else {
    // If there is no distributionLocationVisible then set it to false by default.
    visible = false;
  }

  // This shows if the food item is visible at the community location.  This should
  // mimic the visibility of the distribution location by default.
  const [communityhubVisibility, setCommunityhubVisibility] = useState(visible);

  // This use effect updates and checks the visbility of the community hub
  // and updates the hook that holds this information and updates when the user
  // clicks on the switch to change the Visibility.
  useEffect(() => {
    // default the visibility to the visibility of the distribution location.
    let communityhubVisibilityTemp = visible;
    // Make sure the field exists.
    if (row.distributionLocationVisible != null) {
      // Check if the location is included in the visibility.  This updates
      // the visibilty in case the distribution location has it off, but the community
      // location wants it on.
      if (row.distributionLocationVisible.includes(communityLocation.userId)) {
        communityhubVisibilityTemp = true;
      }
    }

    // Make sure the field exists.
    if (row.communityLocationDisable != null) {
      // This checks if the community locaiton wants the item to be
      // off regardless of the distribution location's status.
      if (row.communityLocationDisable.includes(communityLocation.userId)) {
        communityhubVisibilityTemp = false;
      }
    }
    // Updtes the hook used for the switch.
    setCommunityhubVisibility(communityhubVisibilityTemp);
  }, [row]);

  return (
    <div style={{ display: "flex" }}>
      <span style={{ width: "100px", flexShrink: 0 }}>
        <Switch
          checked={communityhubVisibility}
          onChange={(e) => handleSwitch(index, e.target.checked)}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </span>

      <ItemAvailableDetails key={index} row={row} visible={visible} />
    </div>
  );
}
