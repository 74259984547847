// This is used to display the communications on the distribution location's
// my account.
import React from "react";
import Tabs from "../../../components/Tabs.js";
import Announcement from "./Announcement.jsx";
import QuestionnaireDisplay from "../Components/Questionnaires/QuestionnaireDisplay.jsx";
import PropTypes from "prop-types";

// This is the Communications Tab which shows the announcements and the questionnaires.
export default function Communications({ userInfo, handleInfoUpdate }) {
  return (
    <div className="Tab-Box">
      <Tabs>
        <div label="Announcement">
          <Announcement
            announcementOn={userInfo.announcementOn}
            announcementTitle={userInfo.announcementTitle}
            announcementDescription={userInfo.announcementDescription}
            announcementImage={userInfo.announcementImage}
            announcementWebsite={userInfo.announcementWebsite}
            handleInfoUpdate={handleInfoUpdate}
            userInfo={userInfo}
          />
        </div>
        <div label="Questionnaires">
          <QuestionnaireDisplay userInfo={userInfo} />
        </div>
      </Tabs>
    </div>
  );
}

Communications.propTypes = {
  userInfo: PropTypes.object,
  handleInfoUpdate: PropTypes.func,
};
