// This is called to obscure the longitude and latitude of a user.  It will just
// reset the longitude and latitude to a set place from the distribution location
// depending on its proximity to the distribution location.
export default function LongLatAdjustment(locationDetails, userInfo) {
  // Checks if the user has been approved by the community host.
  let approvedByCommunityLocation = false;
  // Sets the real latitude
  let latitude = locationDetails.latitude;
  // Sets the real longitude.
  let longitude = locationDetails.longitude;
  // Checks if the user has been approved by the community host or not or if the
  // communityHub is an open location and not in need of approval.
  if (
    locationDetails.approvedCommunityMembers.includes(userInfo.userId) ||
    locationDetails.communityHubType === "publicNoApproval"
  ) {
    approvedByCommunityLocation = true;
  }
  // If they haven't been approved
  if (!approvedByCommunityLocation) {
    // Set the latitude and longitude to the obscured locations.
    latitude = locationDetails.obscuredLatitude;
    longitude = locationDetails.obscuredLongitude;
  }

  // If there is no obscuredLatitude and obscuredLongitude then we'll reset it back
  // to their latitude and longitude.
  // eslint-disable-next-line eqeqeq
  if (latitude == undefined || longitude == undefined) {
    // Sets the real latitude
    latitude = locationDetails.latitude;
    // Sets the real longitude.
    longitude = locationDetails.longitude;
  }

  return [latitude, longitude, approvedByCommunityLocation];
}
