// After editing a volunteer position this will save those changes to the database
// when the admin user finishes editing one.
import firebase from "../../../../components/Firebase.js";

export default function UpdateVolunteerPosition(
  volunteerPosition,
  oldTitle,
  volunteerJobs,
  setVolunteerJobs,
  index,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Creates a temp array with the other volunteer jobs if they exist.
  const volunteerJobsTemp = [...volunteerJobs];

  // Finds the document of the original volunteer position in the database or creates
  // a new document reference if it doesn't already exist.
  const volunteerDocRef = database
    .collection("Volunteer")
    .doc(volunteerPosition.title);

  // If the position's title changes then we have to delete the old one and create a
  // new one since it's document name must match the title.
  if (volunteerPosition.title !== oldTitle) {
    // Deletes the old title.  Once deleted then add the new one.
    database
      .collection("Volunteer")
      .doc(oldTitle)
      .delete()
      .then(() => {
        // Sets the new document.
        batch.set(volunteerDocRef, volunteerPosition);

        // Commit changes.
        batch.commit();

        // Remove the old position from the array.
        volunteerJobsTemp.splice(index, 1);

        // Add the new one.
        volunteerJobsTemp.push(volunteerPosition);

        setVolunteerJobs([...volunteerJobsTemp]);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  } else {
    // If the title didn't change then just update the old document.
    batch.update(volunteerDocRef, volunteerPosition);

    // Commit changes.
    batch.commit();

    // Update the volunteer jobs array at the index of the changing position.
    volunteerJobsTemp[index] = volunteerPosition;

    setVolunteerJobs([...volunteerJobsTemp]);
  }
}
