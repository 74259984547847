import React from "react";
import AddressForm from "../../../components/userForms/AddressForm.js";

// This is the Address Info Tab which contains the Street Address, Country, City,
// Province/Territory, Postal Code
export default function Address(props) {
  return (
    <div className="Tab-Box">
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Street Address: </h5>
        <p className="My-Account-Item"> {props.streetAddress} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Country: </h5>
        <p className="My-Account-Item"> {props.country} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> City: </h5>
        <p className="My-Account-Item"> {props.city} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Province/Territory: </h5>
        <p className="My-Account-Item"> {props.provTerr} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Postal Code: </h5>
        <p className="My-Account-Item"> {props.postalCode} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Latitude: </h5>
        <p className="My-Account-Item"> {props.latitude} </p>
      </div>
      <div className="Tab-Item-Row">
        <h5 className="Row-Title"> Longitude: </h5>
        <p className="My-Account-Item"> {props.longitude} </p>
      </div>
      <AddressForm
        restaurantAddress={props.streetAddress}
        restaurantCountry={props.country}
        restaurantCity={props.city}
        restaurantProvTerr={props.provTerr}
        restaurantPostalCode={props.postalCode}
        handleInfoUpdate={props.handleInfoUpdate}
        restaurantName={props.restaurantName}
        restaurantLatitude={props.latitude}
        restaurantLongitude={props.longitude}
        userInfo={props.userInfo}
      />
    </div>
  );
}
