// The function is called by the Marketplace page when a user selects a distribution
// location that has foodItems in the pantry from a specific farm.  This is called once
// once the marketplace has determined which farms have foodItems in the pantry.
import firebase from "../components/Firebase.js";

export default function MarketplaceBulkAmounts(
  pickupLocationDetails,
  loadLeftovers,
  setFoodItems,
  setDisplayedFoodItems,
  setPantryLoading,
  marketplaceCategories,
  setMarketplaceCategories,
  userInfo,
) {
  const database = firebase.firestore();
  const pantryDocRef = database
    .collection("Pantry")
    .doc(pickupLocationDetails.name);

  // Set the id of the community location's owner.
  let communityLocationUserId = "";

  // If the user is an individual then set the distributionLocationUserId to their selected
  // location.
  if (userInfo.userType === "individual") {
    // Check if the user is using a community location.
    if (userInfo.pickupLocation.pickupLocation) {
      communityLocationUserId = userInfo.pickupLocation.userId;
    }
  }

  // Used for when there are leftovers and the categories need to be added to the
  // distributionLocation since there may be categories that exclusive to the distribution
  // locations pantry.   It has all the marketplace categories from the distribution
  // location's set categories in DistributionLocation -> Vancouver doc.
  const marketplaceCategoriesTemp = [...marketplaceCategories];

  pantryDocRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        // stores the foodItems as an array with each item at an index
        const leftovers = [];

        // If the current distribution Location's Pantry Document contains an entry
        // that's called importedBy then we know they have to observe the items of
        // another distributionLocation.
        if (doc.data().importedBy) {
          // create a document reference with the distribution location's name that imported
          // this location's order
          const importedPantryDocRef = database
            .collection("Pantry")
            .doc(doc.data().importedBy);

          importedPantryDocRef
            .get()
            .then((importedDoc) => {
              if (importedDoc.exists) {
                // store the data in a variable
                const data = importedDoc.data();
                // The items are stored in the Pantry as a key (name of the foodItem)
                // and the value (foodItem information).  Cycle through all the items
                // and check whether or not the farm is included in the farm's that have
                // been said to use pantry only.
                Object.keys(data).forEach((item) => {
                  if (loadLeftovers.includes(data[item].farmName)) {
                    // Adds a quantity field to store the users selected quantities
                    data[item].quantity = 0;
                    // Checks to make sure there is at least one remaining.
                    if (data[item].limit >= 1) {
                      leftovers.push(data[item]);
                    }
                  }
                });
                // Cycles through the leftovers to check if the category already exists
                // and if not then adds to the array.
                leftovers.forEach((foodItem) => {
                  if (!marketplaceCategoriesTemp.includes(foodItem.category)) {
                    marketplaceCategoriesTemp.push(foodItem.category);
                  }
                });

                // Add the item to the hook that store the food array
                setFoodItems((prevArray) => [...prevArray, ...leftovers]);

                // If these aren't set then we're just showing the visibility dialog and
                // we don't need to set the categories or displayed foordItems.
                if (
                  setMarketplaceCategories != null &&
                  setDisplayedFoodItems != null
                ) {
                  // Sets the updated marketplaceCategories.
                  setMarketplaceCategories([...marketplaceCategoriesTemp]);
                  setDisplayedFoodItems((prevArray) => [
                    ...prevArray,
                    ...leftovers,
                  ]);
                }
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
              setPantryLoading(true);
            })
            .catch(function (error) {
              console.log("Error getting document:", error);
            });
        } else {
          // store the data in a variable

          const data = doc.data();
          // The items are stored in the Pantry as a key (name of the foodItem)
          // and the value (foodItem information).  Cycle through all the items
          // and check whether or not the farm is included in the farm's that have
          // been said to use pantry only.
          Object.keys(data).forEach((item) => {
            if (loadLeftovers.includes(data[item].farmName)) {
              // Set the disabled flag to false.
              let itemDisabled = false;
              // Check if the key communityLocationDisable exists.
              if (data[item].communityLocationDisable != null) {
                // Check if the community location has disabled this item.
                if (
                  data[item].communityLocationDisable.includes(
                    communityLocationUserId,
                  )
                ) {
                  // Set the flag to being disabled.
                  itemDisabled = true;
                }
              }
              // If the item isn't disabled then add it to the leftovers.
              if (!itemDisabled) {
                data[item].quantity = 0;
                // Checks to make sure there is still at least one remaining.
                if (data[item].limit >= 1) {
                  leftovers.push(data[item]);
                }
              }
            }
          });
          // Cycles through the leftovers to check if the category already exists
          // and if not then adds to the array.
          leftovers.forEach((foodItem) => {
            if (!marketplaceCategoriesTemp.includes(foodItem.category)) {
              marketplaceCategoriesTemp.push(foodItem.category);
            }
          });

          // Add the item to the hook that store the food array
          // If these aren't set then we're just showing the visibility dialog and
          // we don't need to set the categories or displayed foordItems.
          setFoodItems((prevArray) => [...prevArray, ...leftovers]);
          if (
            setMarketplaceCategories != null &&
            setDisplayedFoodItems != null
          ) {
            // Sets the updated marketplaceCategories.
            setMarketplaceCategories([...marketplaceCategoriesTemp]);
            setDisplayedFoodItems((prevArray) => [...prevArray, ...leftovers]);
          }
          setPantryLoading(true);
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setPantryLoading(true);
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
