// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "../../App.css";
// eslint-disable-next-line no-unused-vars
import IconButton from "@material-ui/core/IconButton";
// eslint-disable-next-line no-unused-vars
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
// eslint-disable-next-line no-unused-vars
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

export default class IncrCounter extends Component {
  render() {
    return (
      <div className="Incremental-Counter">
        {!this.props.hideButtons && (
          <IconButton onClick={this.props.decreaseItem}>
            <RemoveCircleOutlineIcon fontSize="large" />
          </IconButton>
        )}
        <p style={{ margin: "0px", fontWeight: "bold", fontSize: "30px" }}>
          {this.props.quantity}
        </p>
        {!this.props.hideButtons && (
          <IconButton onClick={this.props.incrementItem}>
            <AddCircleOutlineOutlinedIcon
              fontSize="large"
              style={{ color: "white" }}
            />
          </IconButton>
        )}
      </div>
    );
  }
}
