import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router";
import "../App.css";
import "../styles/Basket.css";
import Menu from "../components/Menu.js";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";
import PlaceOrder from "../functions/PlaceOrder.js";
import ExtraQuantitiesUpdate from "../pages/BasketPage/Functions/OrderUpdates/ExtraQuantitiesUpdate.js";
import FormatDate from "../pages/BasketPage/Functions/UserInputs/FormatDate.js";
import RefreshScreen from "../pages/BasketPage/Functions/OrderUpdates/RefreshScreen.js";
import CommunityFoodItemRemove from "../pages/BasketPage/Functions/OrderUpdates/CommunityFoodItemRemove.js";
import QuantityChange from "../pages/BasketPage/Functions/OrderUpdates/QuantityChange.js";
import DeleteItemFromBasket from "../pages/BasketPage/Functions/OrderUpdates/DeleteItemFromBasket.js";
import RecurringOrderCheckbox from "../pages/BasketPage/Functions/UserInputs/RecurringOrderCheckbox.js";
import ImportOrder from "../pages/BasketPage/Functions/OrderUpdates/ImportOrder.js";
import CommunityOrderUpdate from "../pages/BasketPage/Functions/OrderUpdates/CommunityOrderUpdate.js";
import RemoveImportedOrder from "../pages/BasketPage/Functions/OrderUpdates/RemoveImportedOrder.js";
import BasketPageCalculations from "../pages/BasketPage/Functions/BasketPageCalculations.js";
import RestaurantBasket from "../pages/BasketPage/UserViews/RestaurantBasket.jsx";
import DistributionLocationBasket from "../pages/BasketPage/UserViews/DistributionLocationBasket.jsx";
import IndividualBasket from "../pages/BasketPage/UserViews/IndividualBasket.jsx";
import LoadingContent from "../components/LoadingContent.jsx";
import Footer from "../components/Footer.jsx";
import CalculateCommunityLocationStatus from "../functions/CalculateCommunityLocationStatus.js";

export const NonFoodCostsContext = React.createContext(null);

// Start the Basket Page.  This page is used to display to the user what they have
// in the basket and let them choose to increase the quantity of an item, remove,
// and place the order.  They can also choose how to contact the farmers and which
// farmers are supplying their goods.
export default function Basket() {
  const database = firebase.firestore();

  const { userInfo, currentUser, handleUserInfoChange } =
    useContext(AuthContext);
  if (userInfo.basketItems === undefined) {
    userInfo.basketItems = [];
    handleUserInfoChange({ ...userInfo }, true);
  }
  // FoodItems contains an array of all the food items that have been added to the
  // user's basket
  const [foodItems, setFoodItems] = useState([...userInfo.basketItems]);

  // DistributionLocations users can choose a date to add customers' orders to their
  // basket.
  const [selectedDate, setSelectedDate] = useState(null);

  // DistributionLocations users can choose a date to import customers' orders to their
  // basket from another distribution location.
  const [importedSelectedDate, setImportedSelectedDate] = useState({});

  // DistributionLocations users store the foodItems that the community ordered
  const [communityFoodItems, setCommunityFoodItems] = useState([]);

  // DistributionLocations users store the pantryfoodItems that the community ordered
  const [pantryFoodItems, setPantryFoodItems] = useState([]);

  // When a distribution location modifies the community order list then they
  // will cause this boolean to change so that the program knows to give them
  // the option to reset the community orders.
  const [communityListChanged, setCommunityListChanged] = useState(false);

  // This is a flag that says that the user removed a farm from the basket.  This
  // removes all foodItems from this farm as well, community and distribution location
  // foodItems.  This flag when true shows a button to reverse this removal and adds
  // back the farm and all the foodItems
  const [farmsRemoved, setFarmsRemoved] = useState(false);

  // If the distribution location user hits the reset button to reset their
  // community order list then the website will make another call to the Database
  // and reset all the loaded values.
  const [communityListReset, setCommunityListReset] = useState(false);

  const [farmerInfo, setFarmerInfo] = useState([]);

  // This contains a dictionary that has the name of location being imported, the
  // location details, its distribution date, and the list of community Orders
  const [importedOrder, setImportedOrder] = useState({});

  // This has both the community and the imported orders combined
  const [communityAndImportedOrder, setCommunityAndImportedOrder] = useState(
    [],
  );

  // The foodItems from the community and the user.  This will be equal to
  // foodItems for restaurants and individuals as there are no community orders
  let combinedFoodList = [];

  // The farmList from the community and the user orders.  This will be equal to
  // farmerInfo for restaurants and individuals as there are no community orders
  let combinedFarmList = [];

  // For distribution sites these are the farms that provide the community's orders
  const [communityFarmerInfo, setCommunityFarmerInfo] = useState([]);

  // This is set to false until the data from the database been loaded
  const [loading, setLoading] = useState(false);

  // This is set to false until the communityOrdersLoading from the database been loaded
  const [communityOrdersLoading, setCommunityOrdersLoading] = useState(false);

  // For distribution location users who will need to aggregate the donations of all the
  // community members
  const [communityDonations, setCommunityDonations] = useState(0);

  // For distribution location users who will need to aggregate the subsidies of all the
  // community members
  const [communitySubsidies, setCommunitySubsidies] = useState(0);

  // This is the amount an individual user is donating or being subsidised by.
  const [donationSubsidy, setDonationSubsidy] = useState(4);
  // The cash portion of the volunteer fee that user is spending if and individual
  // user is selected.
  const [cashPortion, setCashPortion] = useState(0);
  // The credit portion of the volunteer fee that user is spending if and individual
  // user is selected.
  const [creditPortion, setCreditPortion] = useState(0);

  // This is either "donation" or "subsidy" and it just means the user is receiving
  // a subsidy or a donation with their order and then it allows the donationSubsidy
  // hook to be determined as negative or positive.
  const [customerContribution, setCustomerContribution] = useState("donation");

  // For DistributionLocations this will save those orders
  const [communityOrders, setCommunityOrders] = useState({});

  // This stores a dictionary where the key is the farm and the method of contact
  //  for the farm is the value
  const [contactMethods, setContactMethods] = useState([]);

  // This is true while the order is being placed.  This gives time for the website
  // to send the email to the user.
  const [placingOrder, setPlacingOrder] = useState(false);

  // This is true while the order is being placed and there are multiple batches
  // that need to be placed if the user is sending many emails.
  const [placingBatchesOrder, setPlacingBatchesOrder] = useState(false);

  // This is true while the items are being added to the pantry.
  const [addingToPantry, setAddingToPantry] = useState(false);

  // This is true while the imported orders are being updated with which pantry
  // they're looking at.
  const [importedAddingToPantry, setImportedAddingToPantry] = useState(false);

  // Saves whether the snackBar that displays after placing order is open or not
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  // Community members who have had their pick up change due to a community host
  // removal
  const [modifiedUserPickups, setModifiedUserPickups] = useState([]);

  // This is the name of the location that the user has selected for importing
  const [importingLocation, setImportingLocation] = useState([
    "Choose a Distribution Location",
  ]);

  // This sets the current status of a community host.
  const [currentStatus, setCurrentStatus] = useState([
    "loading",
    "loading",
    false,
  ]);

  // This is used in case users order more than the guaunteed limits and will
  // store which foodItems users didn't have their extras met and how many extra items
  // were ordered total to adjust the final limit
  const [extraQuantitiesDict, setExtraQuantitiesDict] = useState({});

  // The total charge for the delivery fee.  Only for community host pickups.
  let deliveryFee = -1;

  useEffect(() => {
    // Cycle through the items and make the user hasn't taken more then the remaining
    // amount of food.  If the quantity is greater than the limit then set the quantity
    // to the limit.  The user may be limited by the pantry and if not that then it could
    // be limited by the farmer amount.
    const foodItemsTemp = [...foodItems];
    foodItemsTemp.forEach((item) => {
      if (item.limit && item.limit - item.quantity < 0) {
        item.quantity = item.limit;
      } else if (
        userInfo.userType === "individual" &&
        item.farmLimit &&
        item.farmLimit - item.quantity < 0
      ) {
        item.quantity = item.farmLimit;
      } else if (
        userInfo.userType !== "individual" &&
        item.farmLimit &&
        (item.farmLimit * item.individualQuantity) / item.distributionQuantity -
          item.quantity <
          0
      ) {
        item.quantity =
          (item.farmLimit * item.individualQuantity) /
          item.distributionQuantity;
      }
    });
    setFoodItems([...foodItemsTemp]);

    const farmDataTransfer = [];
    // If there are no foodItems then skip over this useEffect
    if (
      foodItems.length === 0 &&
      userInfo.userType !== "distributionLocation"
    ) {
      setFarmerInfo([]);
      setLoading(true);
      setCommunityOrdersLoading(true);
    } else {
      if (userInfo.userType === "distributionLocation" && !!selectedDate) {
        const distributionLocationCollectionRef = database
          .collection("DistributionLocations")
          .doc(userInfo.organisationName)
          .collection("CommunityOrders");

        // Fetch all documents that have the distributionDate equal to selectedDate
        distributionLocationCollectionRef
          .where("distributionDate", "==", selectedDate)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              // Creates a temporary dictionary to hold the community order.
              const communityOrdersData = {}; // To hold combined community orders

              // Iterate through all the documents in the query snapshot
              querySnapshot.forEach((doc) => {
                const orders = doc.data();

                // Combine the orders while removing distributionDate and orderCount
                Object.keys(orders).forEach((key) => {
                  if (key !== "distributionDate" && key !== "orderCount") {
                    communityOrdersData[key] = orders[key];
                  }
                });
              });

              // Sets the community orders for this distribution location
              setCommunityOrders(communityOrdersData);

              // This will go through and update user orders that have ordered
              // items that have limits to them.  This will then aggregate the orders
              // into one list regardless of whether there were any limits on foodItems or not.
              ExtraQuantitiesUpdate(
                communityOrdersData,
                setCommunityFoodItems,
                setCommunityFarmerInfo,
                setCommunityDonations,
                setCommunitySubsidies,
                setPantryFoodItems,
                setCommunityOrders,
                setExtraQuantitiesDict,
                userInfo,
                foodItems,
              );

              setCommunityAndImportedOrder(communityOrdersData);

              if (foodItems.length === 0) {
                setLoading(true);
              }
              setCommunityOrdersLoading(true);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              setCommunityFoodItems([]);
              setPantryFoodItems([]);
              setCommunityFarmerInfo([]);
              setCommunityDonations(0);
              setCommunitySubsidies(0);
              setLoading(true);
              setCommunityOrdersLoading(true);
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setLoading(true);
        setCommunityOrdersLoading(true);
      }
      // Create a list of the farms providing the order but make the list without
      // repeating the same farm in the event it is providing more than one ingredient.
      const noRepeatsFarmerInfo = [];
      foodItems.forEach((element) => {
        const result = noRepeatsFarmerInfo.filter(
          (arr) => arr === element.farmName,
        );
        if (result.length === 0) {
          noRepeatsFarmerInfo.push(element.farmName);
        }
      });

      // Cycle through the items and add their farm to a list of farms providing
      // this order
      noRepeatsFarmerInfo.forEach((farm) => {
        const farmDocRef = database
          .collection("Farmers")
          .where("farmName", "==", farm);
        farmDocRef
          .get()
          .then((farmCollection) => {
            farmCollection.docs.forEach((farmDoc) => {
              if (farmDoc.exists) {
                farmDataTransfer.push(farmDoc.data());
                // There was some major issue where the page was rendering before these
                // for loops were finished completing and so only the first farm would
                // load.  I added this setLoad to false to trigger a rerender with the
                // final and complete list.
                setLoading(false);
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
            });
            setFarmerInfo(farmDataTransfer);
            setLoading(true);
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
            setLoading(true);
          });
      });
    }
  }, [database, communityListReset, selectedDate]);

  // Calculates the current status of the community host if it exists.
  useEffect(() => {
    // First make sure the user has even selected a distribution location yet.
    if (userInfo.pickupLocation !== undefined) {
      // Check to see if the user has selected a community host.
      if (userInfo.pickupLocation.pickupLocation) {
        // Calculates the community host's status and sets the hooks.
        CalculateCommunityLocationStatus(
          userInfo.pickupLocation,
          setCurrentStatus,
          userInfo,
        );
      } else {
        // sets the status to failed if it doesn't exist.
        setCurrentStatus(["failed to load", "failed to load", false]);
      }
    } else {
      // sets the status to failed if it doesn't exist.
      setCurrentStatus(["failed to load", "failed to load", false]);
    }
  }, []);

  // Formats a Date object into the format MM-DD-YYYY
  function handleDateChange(date) {
    FormatDate(date, setCommunityListChanged, setSelectedDate);
  }

  // handleRefresh is used to update the hooks that contain the farmerInfo and
  // the foodItems lists.   When they are updated then the screen will rerender
  // with the new lists without having to read the database.
  function handleRefresh(farm, item) {
    RefreshScreen(
      farm,
      item,
      foodItems,
      setFoodItems,
      handleBasketItems,
      userInfo,
      customerContribution,
      donationSubsidy,
      setDonationSubsidy,
      farmerInfo,
      setFarmerInfo,
    );
  }

  // communityFoodRemove updates the community farmer list and the food list when
  // the user removes a food item that was added because of the community orders.
  function communityFoodRemove(item, pantry) {
    CommunityFoodItemRemove(
      item,
      pantry,
      communityFoodItems,
      setCommunityFoodItems,
      pantryFoodItems,
      setPantryFoodItems,
      setCommunityListChanged,
      communityFarmerInfo,
      setCommunityFarmerInfo,
    );
  }

  // When the quantity of an item is changed in the Basket this is called to update
  // reflect that change by updating the hook foodItems. If an item goes below zero
  // this function won't be called and will be handled by handleRemove in BasketFoodRow
  function handleQuantityChange(incrementItem, item) {
    QuantityChange(
      incrementItem,
      item,
      foodItems,
      setFoodItems,
      userInfo,
      customerContribution,
      donationSubsidy,
      setDonationSubsidy,
    );
  }

  function handleMethodSelection(e, farmName) {
    let method = e;
    if (e.target !== undefined) {
      method = e.target.value;
    }
    setContactMethods({ ...contactMethods, [farmName]: method });
  }

  // This is called to refresh the page when you delete an item.  This also is
  // updates the database with the changes.
  function handleBasketItems(indexOfFoodItem, deleteItem) {
    DeleteItemFromBasket(
      indexOfFoodItem,
      deleteItem,
      database,
      currentUser,
      userInfo,
      handleUserInfoChange,
    );
  }

  // This handles when the user selects the checkbox for a weekly recurring delivery
  // It receives the index of the food item in the list of food items on the basket
  // page.
  function handleCheckboxChange(food) {
    RecurringOrderCheckbox(food, foodItems, setFoodItems);
  }

  // Calls placeOrder and updates the basket to empty and shows the snackBar.
  function handlePlaceOrder(
    checkList,
    addToExistingOrder,
    marketplaceMessage,
    marketplaceMessageEnabled,
  ) {
    setPlacingOrder(true);
    PlaceOrder(
      [...combinedFarmList],
      [...combinedFoodList],
      { ...userInfo },
      contactMethods,
      donationSubsidy,
      customerContribution,
      handleUserInfoChange,
      true,
      communityOrders,
      selectedDate,
      costTotal,
      grandTotal,
      importedOrder,
      setPlacingOrder,
      setPlacingBatchesOrder,
      setSnackBarOpen,
      [...checkList],
      farmTotals,
      modifiedUserPickups,
      setFoodItems,
      setCommunityFoodItems,
      addToExistingOrder,
      pantryFoodItems,
      setPantryFoodItems,
      setAddingToPantry,
      setImportedAddingToPantry,
      marketplaceMessage,
      marketplaceMessageEnabled,
      deliveryFee,
      cashPortion,
      creditPortion,
      extraQuantitiesDict,
    );
  }

  // handles the Saved any changed snackbar closing
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  // This function takes in the  location, locationDetails and the date of the
  // user inputs and then imports the order requested.  It sets the hook communityAndImportedOrder,
  // importedOrder and calls the AggregateCustomerList to combine the imported
  // and the current distribution location if there are any.
  function handleImportOrder(location, locationDetails, date) {
    ImportOrder(
      location,
      locationDetails,
      date,
      database,
      importedOrder,
      setImportedOrder,
      communityAndImportedOrder,
      setCommunityAndImportedOrder,
      setCommunityFoodItems,
      setPantryFoodItems,
      setCommunityFarmerInfo,
      setCommunityDonations,
      setCommunitySubsidies,
    );
  }

  // This is to handle changes to a user's order in community orders or the imported
  // community order.  This updates any hooks to make sure the page rerenders to reflect
  // the changes.  Imported is boolean, foodListUpdate is the new foodList, farmerListUpdate
  // is the new farmerList, userKey is user's FirstName-LastName-userId-orderDate
  function handleCommunityOrderChange(
    imported,
    foodListUpdate,
    farmerListUpdate,
    userKey,
    updatedOrder,
    deleteOrder,
    location,
    communityLocation,
    userOrder,
  ) {
    CommunityOrderUpdate(
      imported,
      foodListUpdate,
      farmerListUpdate,
      userKey,
      updatedOrder,
      deleteOrder,
      userOrder,
      importedOrder,
      setImportedOrder,
      communityOrders,
      setCommunityOrders,
      setCommunityAndImportedOrder,
      setCommunityFoodItems,
      setPantryFoodItems,
      setCommunityFarmerInfo,
      setCommunityDonations,
      setCommunitySubsidies,
      location,
      communityLocation,
      modifiedUserPickups,
      setModifiedUserPickups,
    );
  }

  function handleRemoveImportedOrder(location, idx) {
    RemoveImportedOrder(
      location,
      idx,
      importedOrder,
      setImportedOrder,
      importedSelectedDate,
      setImportedSelectedDate,
      communityAndImportedOrder,
      importingLocation,
      setImportingLocation,
      setCommunityAndImportedOrder,
      setCommunityFoodItems,
      setPantryFoodItems,
      setCommunityFarmerInfo,
      setCommunityDonations,
      setCommunitySubsidies,
    );
  }

  // When snackBar is set than we know the user's order has been placed and so
  // if the user is also an individual then take them to the confirmation page
  // and send over the userInfo and the customerContribution so it knows what
  // to render.
  if (snackBarOpen && userInfo.userType === "individual") {
    return (
      <Redirect
        to={{
          pathname: "/OrderConfirmation",
          state: { userInfo, customerContribution },
        }}
      />
    );
  }

  if (communityOrdersLoading && loading) {
    // The totals owed to each farm of the complete order.  This will be a dictionary
    // once it is created.
    // eslint-disable-next-line no-var
    var farmTotals = 0;

    // The totals for each farmer of the packaging fees.  Doesn't necessarily go to the farmer
    let packageTotals = 0;

    // The totals from the pantry of the foodItems broken down by vendor {vendor : $XX.XXs}
    let pantryTotals = 0;
    // cost of all the food items added together for the order's cost
    // eslint-disable-next-line no-var
    var costTotal = 0;
    // cost of all the pantry food items added together.
    let pantryCostTotal = 0;
    // grandTotal is the costTotal plus the donation/Subsidy amount
    // eslint-disable-next-line no-var
    var grandTotal = 0;
    let dollarColour = "";
    let textFieldColour = "";
    let netAmountTextFieldColour = "";

    let communityOrdersTotal = 0;

    let donationAmount = 0;
    // Total suggested fees that are used to calculate the total volunteer fees
    let suggestedFees = 0;

    [
      combinedFarmList,
      combinedFoodList,
      farmTotals,
      costTotal,
      pantryTotals,
      pantryCostTotal,
      grandTotal,
      dollarColour,
      textFieldColour,
      netAmountTextFieldColour,
      communityOrdersTotal,
      donationAmount,
      packageTotals,
      deliveryFee,
      suggestedFees,
    ] = BasketPageCalculations(
      combinedFarmList,
      farmerInfo,
      communityFarmerInfo,
      communityFoodItems,
      combinedFoodList,
      foodItems,
      userInfo,
      customerContribution,
      donationSubsidy,
      communityDonations,
      communitySubsidies,
      communityAndImportedOrder,
      pantryFoodItems,
      currentStatus,
      cashPortion,
    );

    return (
      <div style={{ background: "rgb(244, 249, 224)" }} id="home">
        <Menu />
        {userInfo.userType === "distributionLocation" && (
          <DistributionLocationBasket
            foodItems={foodItems}
            setFoodItems={setFoodItems}
            communityFoodItems={communityFoodItems}
            setCommunityFoodItems={setCommunityFoodItems}
            pantryFoodItems={pantryFoodItems}
            setPantryFoodItems={setPantryFoodItems}
            communityOrders={communityOrders}
            selectedDate={selectedDate}
            importedSelectedDate={importedSelectedDate}
            setImportedSelectedDate={setImportedSelectedDate}
            importedOrder={importedOrder}
            importingLocation={importingLocation}
            setImportingLocation={setImportingLocation}
            communityListChanged={communityListChanged}
            setCommunityListChanged={setCommunityListChanged}
            communityListReset={communityListReset}
            setCommunityListReset={setCommunityListReset}
            farmsRemoved={farmsRemoved}
            setFarmsRemoved={setFarmsRemoved}
            userInfo={userInfo}
            combinedFarmList={combinedFarmList}
            farmerInfo={farmerInfo}
            setFarmerInfo={setFarmerInfo}
            communityFarmerInfo={communityFarmerInfo}
            setCommunityFarmerInfo={setCommunityFarmerInfo}
            communityFoodRemove={communityFoodRemove}
            contactMethods={contactMethods}
            customerContribution={customerContribution}
            donationSubsidy={donationSubsidy}
            modifiedUserPickups={modifiedUserPickups}
            setModifiedUserPickups={setModifiedUserPickups}
            farmTotals={farmTotals}
            pantryTotals={pantryTotals}
            pantryCostTotal={pantryCostTotal}
            grandTotal={grandTotal}
            communityOrdersTotal={communityOrdersTotal}
            donationAmount={donationAmount}
            communityDonations={communityDonations}
            communitySubsidies={communitySubsidies}
            netAmountTextFieldColour={netAmountTextFieldColour}
            placingOrder={placingOrder}
            placingBatchesOrder={placingBatchesOrder}
            addingToPantry={addingToPantry}
            importedAddingToPantry={importedAddingToPantry}
            snackBarOpen={snackBarOpen}
            handleRemoveImportedOrder={handleRemoveImportedOrder}
            handleCommunityOrderChange={handleCommunityOrderChange}
            handleDateChange={handleDateChange}
            handleImportOrder={handleImportOrder}
            handleRefresh={handleRefresh}
            handleQuantityChange={handleQuantityChange}
            handleMethodSelection={handleMethodSelection}
            handlePlaceOrder={handlePlaceOrder}
            handleSnackBarClose={handleSnackBarClose}
          />
        )}

        {userInfo.userType === "restaurant" && (
          <RestaurantBasket
            foodItems={foodItems}
            handleRefresh={handleRefresh}
            handleQuantityChange={handleQuantityChange}
            userInfo={userInfo}
            handleCheckboxChange={handleCheckboxChange}
            farmerInfo={farmerInfo}
            handleMethodSelection={handleMethodSelection}
            contactMethods={contactMethods}
          />
        )}

        {userInfo.userType === "individual" && (
          <NonFoodCostsContext.Provider
            value={{
              cashPortion,
              setCashPortion,
              creditPortion,
              setCreditPortion,
            }}
          >
            <IndividualBasket
              foodItems={foodItems}
              userInfo={userInfo}
              combinedFarmList={combinedFarmList}
              contactMethods={contactMethods}
              customerContribution={customerContribution}
              setCustomerContribution={setCustomerContribution}
              donationSubsidy={donationSubsidy}
              setDonationSubsidy={setDonationSubsidy}
              farmTotals={farmTotals}
              packageTotals={packageTotals}
              dollarColour={dollarColour}
              textFieldColour={textFieldColour}
              costTotal={costTotal}
              grandTotal={grandTotal}
              placingOrder={placingOrder}
              snackBarOpen={snackBarOpen}
              handleRefresh={handleRefresh}
              handleQuantityChange={handleQuantityChange}
              handleMethodSelection={handleMethodSelection}
              handlePlaceOrder={handlePlaceOrder}
              handleSnackBarClose={handleSnackBarClose}
              deliveryFee={deliveryFee}
              suggestedFees={suggestedFees}
            />
          </NonFoodCostsContext.Provider>
        )}
        <Footer />
      </div>
    );
  } else {
    return <LoadingContent />;
  }
}
