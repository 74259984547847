// Displays the quesitonnaires for a given distribution location so that they can turn them on or off
// edit or delete a questionnaire.
import React, { useEffect, useState } from "react";
import firebase from "../../../../components/Firebase.js";
import CreateQuestionnaireDialog from "../../Dialogs/Questionnaires/CreateQuestionnaireDialog.jsx";
import QuestionnaireAccordion from "../Accordions/QuestionnaireAccordion.jsx";
import PropTypes from "prop-types";

export default function QuestionnaireDisplay({ userInfo }) {
  // The list of questionnaires.
  const [questionnaires, setQuestionnaires] = useState([]);

  // Load all the questionnaires.
  useEffect(() => {
    const database = firebase.firestore();

    // Limit the number of questionnaires they can load to 50 and only show the questionnaires that
    // current distribution location has created.
    const docRef = database
      .collection("Questionnaires")
      .orderBy("createdDate", "desc")
      .where("author", "==", userInfo.userId)
      .limit(50);
    // Update the Payment History to store information to help track payments.
    docRef
      .get()
      .then((collection) => {
        const dataTransfer = [];

        collection.docs.forEach((doc) => {
          if (doc.exists) {
            dataTransfer.push(doc.data());
          }
        });
        setQuestionnaires([...dataTransfer]);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <div>
      <CreateQuestionnaireDialog
        userInfo={userInfo}
        questionnaires={questionnaires}
        setQuestionnaires={setQuestionnaires}
      />

      {questionnaires.map((questionnaire, indexOfQuestionnaire) => (
        <QuestionnaireAccordion
          key={indexOfQuestionnaire}
          questionnaire={questionnaire}
          indexOfQuestionnaire={indexOfQuestionnaire}
          questionnaires={questionnaires}
          setQuestionnaires={setQuestionnaires}
          userInfo={userInfo}
        />
      ))}
    </div>
  );
}

QuestionnaireDisplay.propTypes = {
  userInfo: PropTypes.object.isRequired,
};
