// This function will calcualate the payments owed by each indivdual who placed an
// order and store it in the database to be tracked.
import firebase from "../../../components/Firebase.js";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";

export default function CreatePaymentHistory(
  now,
  selectedDate,
  importedOrder,
  communityOrders,
  userInfo,
  addToExistingOrder,
  ordersPaymentHistoryExisting,
  docName,
) {
  // This dictionary is used to store the data in the database including.
  //  selectedDate : MM-DD-YYYY
  //   importedOrder :
  // 	  DistributionLocation :
  // 		 Location : XXXX
  // 	   date : MM-DD-YYYY
  // 	   communityOrders :
  //      firstName-lastName-userId-date :
  //      deposits: { timestamp : $XX.XX, timestamp : $XX.XX,
  //                            timestamp : $XX.XX}
  //      totalOwed : $XX.XX
  //      DistributionLocation : XXXX
  //      Owed History : [timestamp : $XX.XX, timestamp : $XX.XX, timestamp : $XX.XX]
  //      OrderNotes : XXXXXX
  //      orderRefunds :{ timestamp : $XX.XX, timestamp : $XX.XX,
  //                      timestamp : $XX.XX}
  // 	 communityOrders
  // 		firstName-lastName-userId-date :
  //      deposits: { timestamp : $XX.XX, timestamp : $XX.XX,
  //                            timestamp : $XX.XX}
  //      totalOwed : $XX.XX
  //      DistributionLocation : XXXX
  //      Owed History : [timestamp : $XX.XX, timestamp : $XX.XX, timestamp : $XX.XX]
  //      OrderNotes : XXXXXX
  //      orderRefunds :{ timestamp : $XX.XX, timestamp : $XX.XX,
  //                      timestamp : $XX.XX}
  // Set up the datebase.
  const database = firebase.firestore();
  const batch = database.batch();

  let orderPaymentHistory = {};
  const orderExistsField = now.concat("Exists");
  // The dictionary that saves the community orders payment history.  This is used
  // later and added into the larger payment history that is saved to the database.  This
  // is specifically for the community location's orders not their imported orders.
  const communityOrdersPaymentHistory = {};
  // The community orders dictionary needs to be initialized.
  communityOrdersPaymentHistory.communityOrders = {};

  // If the distribution location is adding to an existing order then we don't
  // want to overwrite the payment history that already exists.
  if (addToExistingOrder) {
    communityOrdersPaymentHistory.communityOrders =
      ordersPaymentHistoryExisting.communityOrders;
  }

  // Since we want to order the user's order history from oldest to newest we have to
  // sort the order.
  const userOrders = Object.keys(communityOrders).slice();
  userOrders.sort();

  // Cycle through all the communityOrders
  userOrders.forEach((userOrder) => {
    // Total up the cost of the order using PriceTotals which separates out the total
    // owed to each farm.
    const orderFarmsCost = PriceTotals(
      communityOrders[userOrder].foodList,
      true,
    );

    // A dictionary of the the farms(keys) and the total packaging fees to them (values)
    const packageTotals = PackageTotals(communityOrders[userOrder].foodList);
    // set the order cost to 0.
    let orderCost = 0;
    // Cycle through the totals owed to each farm and add them up.
    Object.keys(orderFarmsCost).forEach((farmName) => {
      orderCost += orderFarmsCost[farmName];
      if (packageTotals[farmName] > 0) {
        orderCost += packageTotals[farmName];
      }
    });
    // Add or subtract the subsidy and donations to the total order cost.
    if (communityOrders[userOrder].customerContribution === "donation") {
      orderCost += communityOrders[userOrder].donationSubsidy;
    } else {
      orderCost -= communityOrders[userOrder].donationSubsidy;
    }

    // If there is a cash portion for the volunteer fee then add it to the total
    // order cost.
    if (communityOrders[userOrder].cashPortion) {
      orderCost += parseFloat(communityOrders[userOrder].cashPortion);
    }

    // Split the user's name up to remove the date of the order as that is stored in
    // the orderHistory key.
    const user = [
      userOrder.split("-")[0],
      userOrder.split("-")[1],
      userOrder.split("-")[2],
    ].join("-");
    // Store the date of the user's order separately to be used later.
    const userOrderTime = userOrder.split("-")[3];

    // If the user has already placed an order then we are just updating their order
    // otherwise create a new order history for them.
    if (communityOrdersPaymentHistory.communityOrders[user]) {
      // If this userOrderTime exists in the order history we know it was already
      // accounted for in the payment history so skip over it otherwise we need to
      // add the order's payment history to this user's payment history.  In case
      // any modifications occured from the original order then we don't want to
      // overwrite that information.
      if (
        !communityOrdersPaymentHistory.communityOrders[user].orderHistory[
          userOrderTime
        ]
      ) {
        // Add the new orders amount to their old amount.
        communityOrdersPaymentHistory.communityOrders[user].totalOwed +=
          orderCost;
        // Add this new order's total and date to the orderHistory.
        communityOrdersPaymentHistory.communityOrders[user].orderHistory[
          userOrderTime
        ] = communityOrdersPaymentHistory.communityOrders[user].totalOwed;
        // Update the notes to inlcude the additional order added.
        communityOrdersPaymentHistory.communityOrders[user].orderNotes =
          communityOrdersPaymentHistory.communityOrders[user].orderNotes.concat(
            `Added additional order on ${userOrderTime}. `,
          );
      }
    } else {
      // Create a blank dictionary to store all the information of the user's order.
      communityOrdersPaymentHistory.communityOrders[user] = {};
      // Create an empty deposit array for when the user eventually pays.
      communityOrdersPaymentHistory.communityOrders[user].deposits = [];
      // The total amount the user owes for their order.
      communityOrdersPaymentHistory.communityOrders[user].totalOwed = orderCost;
      // Include the distribution location's name their ordering from.
      communityOrdersPaymentHistory.communityOrders[user].distributionLocation =
        userInfo.organisationName;
      // Add to the order history to include the timestamp of the order and the cost.
      communityOrdersPaymentHistory.communityOrders[user].orderHistory = {
        [userOrderTime]: orderCost,
      };
      // Create a blank order notes.
      communityOrdersPaymentHistory.communityOrders[user].orderNotes = "";
      // Create an array for if the user needs refunds in the future.
      communityOrdersPaymentHistory.communityOrders[user].orderRefunds = [];
    }
  });

  // Update the payment history to include the community orders.
  orderPaymentHistory = communityOrdersPaymentHistory;

  // Saved selectedDate.
  orderPaymentHistory.selectedDate = selectedDate;

  // This is the same as above but includes the imported communty order history instead
  // including some additional information about the imported location.
  let importedCommunityOrdersPaymentHistory = {};

  // If the distribution location is adding to an existing order then we don't
  // want to overwrite the payment history that already exists.
  if (addToExistingOrder) {
    importedCommunityOrdersPaymentHistory =
      ordersPaymentHistoryExisting.importedOrder;
  }

  // If there are any imported orders then start this section.
  if (importedOrder.length !== 0) {
    // Cycle through all the imported locations.
    Object.keys(importedOrder).forEach((distributionlocation) => {
      // Check to see if this distribution location was already added to the order.
      // If it already exists then we don't want to reintialize it and erase the
      // dictionary.
      if (!importedCommunityOrdersPaymentHistory[distributionlocation]) {
        // Initialize the dictionary
        importedCommunityOrdersPaymentHistory[distributionlocation] = {};
        // set the location to the imported distributionLocation
        importedCommunityOrdersPaymentHistory[distributionlocation].location =
          distributionlocation;
        // Set the date of the importedOrder distribution date.
        importedCommunityOrdersPaymentHistory[distributionlocation].date =
          importedOrder[distributionlocation].date;
        // Initialize the communityOrders
        importedCommunityOrdersPaymentHistory[
          distributionlocation
        ].communityOrders = {};
      }

      // Since we want to order the user's order history from oldest to newest we have to
      // sort the order of community orders.
      const importedUserOrders = Object.keys(
        importedOrder[distributionlocation].communityOrders,
      ).slice();
      importedUserOrders.sort();

      // Cycle through all the communityOrders
      importedUserOrders.forEach((userOrder) => {
        // Total up the cost of the order using PriceTotals which separates out the total
        // owed to each farm.
        const orderFarmsCost = PriceTotals(
          importedOrder[distributionlocation].communityOrders[userOrder]
            .foodList,
          true,
        );
        // A dictionary of the the farms(keys) and the total packaging fees to them (values)
        const packageTotals = PackageTotals(
          importedOrder[distributionlocation].communityOrders[userOrder]
            .foodList,
        );
        // set the order cost to 0.
        let orderCost = 0;
        // Cycle through the totals owed to each farm and add them up.
        Object.keys(orderFarmsCost).forEach((farmName) => {
          orderCost += orderFarmsCost[farmName];
          if (packageTotals[farmName] > 0) {
            orderCost += packageTotals[farmName];
          }
        });
        // Add or subtract the subsidy and donations to the total order cost.
        if (
          importedOrder[distributionlocation].communityOrders[userOrder]
            .customerContribution === "donation"
        ) {
          orderCost +=
            importedOrder[distributionlocation].communityOrders[userOrder]
              .donationSubsidy;
        } else {
          orderCost -=
            importedOrder[distributionlocation].communityOrders[userOrder]
              .donationSubsidy;
        }
        // If there is a cash portion for the volunteer fee then add it to the total
        // order cost.
        if (
          importedOrder[distributionlocation].communityOrders[userOrder]
            .cashPortion
        ) {
          orderCost += parseFloat(
            importedOrder[distributionlocation].communityOrders[userOrder]
              .cashPortion,
          );
        }

        // Split the user's name up to remove the date of the order as that is stored in
        // the orderHistory key.
        const user = [
          userOrder.split("-")[0],
          userOrder.split("-")[1],
          userOrder.split("-")[2],
        ].join("-");
        // Store the date of the user's order separately to be used later.
        const userOrderTime = userOrder.split("-")[3];
        // If the user has already placed an order then we are just updating their order
        // otherwise create a new order history for them.
        if (
          importedCommunityOrdersPaymentHistory[distributionlocation]
            .communityOrders[user]
        ) {
          // If this userOrderTime exists in the order history we know it was already
          // accounted for in the payment history so skip over it otherwise we need to
          // add the order's payment history to this user's payment history.  Their order
          // may have been updated since the last order was placed and so we don't want to overwrite
          // that information.
          if (
            !importedCommunityOrdersPaymentHistory[distributionlocation]
              .communityOrders[user].orderHistory[userOrderTime]
          ) {
            // Add the new orders amount to their old amount.
            importedCommunityOrdersPaymentHistory[
              distributionlocation
            ].communityOrders[user].totalOwed += orderCost;
            // Add this new order's total and date to the orderHistory.
            importedCommunityOrdersPaymentHistory[
              distributionlocation
            ].communityOrders[user].orderHistory[userOrderTime] =
              importedCommunityOrdersPaymentHistory[
                distributionlocation
              ].communityOrders[user].totalOwed;
            // Update the notes to inlcude the additional order added.
            importedCommunityOrdersPaymentHistory[
              distributionlocation
            ].communityOrders[user].orderNotes =
              importedCommunityOrdersPaymentHistory[
                distributionlocation
              ].communityOrders[user].orderNotes.concat(
                `Added additional order on ${userOrderTime}. `,
              );
          }
        } else {
          // Create a blank dictionary to store all the information of the user's order.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user] = {};
          // Create an empty deposit array for when the user eventually pays.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user].deposits = [];
          // The total amount the user owes for their order.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user].totalOwed = orderCost;
          // Include the distribution location's name their ordering from.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user].distributionLocation =
            userInfo.organisationName;
          // Add to the order history to include the timestamp of the order and the cost.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user].orderHistory = { [userOrderTime]: orderCost };
          // Create a blank order notes.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user].orderNotes = "";
          // Create an array for if the user needs refunds in the future.
          importedCommunityOrdersPaymentHistory[
            distributionlocation
          ].communityOrders[user].orderRefunds = [];
        }
      });
    });
  }

  // Update the payment history to include the community orders from all the imported
  // locations.
  orderPaymentHistory.importedOrder = importedCommunityOrdersPaymentHistory;

  // Checks if we're just adding to an existing order.  If we are then we can just
  // update the orderPaymentHistory with our changes.
  if (addToExistingOrder) {
    const newPaymentHistoryDocRef = database
      .collection("PaymentHistory")
      .doc(docName);

    // Update the payment history
    return newPaymentHistoryDocRef
      .update({
        [now]: orderPaymentHistory,
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }
  // If we aren't adding to an existing order then we need to create a new payment
  // history.
  else {
    // Find the most recent payment history.
    const paymentHistoryDocRef = database
      .collection("PaymentHistory")
      .orderBy("createdDate", "desc")
      .limit(1);
    // Update the Payment History to store information to help track payments.
    paymentHistoryDocRef
      .get()
      .then((collection) => {
        // If the collection is 0 length it means that we didn't already have a
        // payment history saved and we need to create a new document.
        if (collection.docs.length === 0) {
          const newPaymentHistoryDocRef = database
            .collection("PaymentHistory")
            .doc(now);

          batch.set(newPaymentHistoryDocRef, {
            [now]: orderPaymentHistory,
            createdDate: now,
            [orderExistsField]: true,
          });
        }
        // Read the existing document.
        collection.docs.forEach((doc) => {
          // If the document exists count how many current orders are saved in this
          // document.  There should be two fields for every order one with the order
          // payment history and one with the "Exists" boolean.
          if (doc.exists) {
            let orderCount = 0;
            Object.keys(doc.data()).forEach((order) => {
              if (order !== "createdDate") {
                orderCount += 1;
              }
            });
            // If there are already 5 orders then we have to create a new document.
            // We use 10 instead of 5 because each order has the payment history and the
            // orderDate + "Exists" field so there are two keys for every 1 order.
            if (orderCount >= 10) {
              // Create a new document with the name of now.
              const newPaymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(now);
              // Set the document to be equal to the current payment history.
              batch.set(newPaymentHistoryDocRef, {
                [now]: orderPaymentHistory,
                createdDate: now,
                [orderExistsField]: true,
              });
            }
            // If there are less than 5 then we can add this order to the last created
            // document.
            else {
              // Set the dataTransfer equal to the data in the document.
              const dataTransfer = doc.data();
              // Add the new orderPaymentHistory that was just created.
              dataTransfer[now] = orderPaymentHistory;
              dataTransfer[orderExistsField] = true;
              const createdDate = dataTransfer.createdDate;

              const newPaymentHistoryDocRef = database
                .collection("PaymentHistory")
                .doc(createdDate);

              // set the document to be equal to the nw updated orderPaymentHistory.
              // newPaymentHistoryDocRef.set(dataTransfer)
              // Set the document to be equal to the current payment history.
              batch.set(newPaymentHistoryDocRef, dataTransfer);
            }
          } else {
            const newPaymentHistoryDocRef = database
              .collection("PaymentHistory")
              .doc(now);

            batch.set(newPaymentHistoryDocRef, {
              [now]: orderPaymentHistory,
              createdDate: now,
              [orderExistsField]: true,
            });
          }
        });
        batch.commit();
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }
}
