// When a community host wants to approve a community member to use their spot then
// this dialog will appear that will confirm that they are approving the member and
// they will have the option to send them a message.  This will send the approved user an email.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import ApproveCommunityMember from "../Functions/ApproveCommunityMember.js";
import TextField from "@material-ui/core/TextField";
import SendingEmailsDialog from "../../../components/dialogs/Components/SendUserEmails/SendingEmailsDialog.jsx";

function ApproveCommunityMemberBox({
  open,
  onClose,
  communityMembers,
  setCommunityMembers,
  communityMember,
  userInfo,
  setOpen,
  setSendingEmail,
}) {
  const handleClose = () => {
    onClose(false);
  };

  // Handle the user clicking on approve user and the email being sent and database
  // being updated.
  const handleApproval = () => {
    setSendingEmail(true);
    onClose(false);
    ApproveCommunityMember(
      communityMembers,
      setCommunityMembers,
      communityMember,
      userInfo,
      personalMessage,
      setSendingEmail,
      setOpen,
    );
  };

  // The personal message that the community host will include in their email to the
  // new user.  It will default to this message.
  const [personalMessage, setPersonalMessage] = useState(
    `Thank you for applying to join my community hub.  I have approved you and you are now able to pick up from my spot.  <br/><br/>Looking forward to meeting you, <br/>${userInfo.firstName}`,
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Confirm Approval of Community Member
      </DialogTitle>
      <DialogContent>
        You are giving{" "}
        <b>
          {communityMember.split("-")[0]} {communityMember.split("-")[1]}
        </b>{" "}
        access to your community hub. An email will be sent to them notifying
        them that you&apos;ve approved them.
        <TextField
          margin="dense"
          id="personalMessage"
          label="Personal Message"
          name="personalMessage"
          value={personalMessage}
          onChange={(e) => setPersonalMessage(e.target.value)}
          fullWidth
          required
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleApproval}>
          APPROVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApproveCommunityMemberBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ApproveCommunityMemberDialog({
  communityMembers,
  setCommunityMembers,
  communityMember,
  userInfo,
}) {
  const [open, setOpen] = useState(false);
  // Show sending email while it is finishing up.
  const [sendingEmail, setSendingEmail] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{ marginBottom: "5px" }}
        fullWidth
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        APPROVE
      </Button>
      <ApproveCommunityMemberBox
        open={open}
        onClose={handleClose}
        communityMembers={communityMembers}
        setCommunityMembers={setCommunityMembers}
        communityMember={communityMember}
        userInfo={userInfo}
        setOpen={setOpen}
        setSendingEmail={setSendingEmail}
      />

      <SendingEmailsDialog open={sendingEmail} />
    </div>
  );
}
