// eslint-disable-next-line no-unused-vars
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default function ColourButton(props) {
  // eslint-disable-next-line no-unused-vars
  const ColourButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(props.hoverColour),
      backgroundColor: props.colour,
      "&:hover": {
        backgroundColor: props.hoverColour,
      },
    },
  }))(Button);

  let size = "small";

  if (props.size) {
    size = props.size;
  }
  return (
    <ColourButton
      variant="contained"
      color="primary"
      size={size}
      onClick={props.onClick}
    >
      {props.title}
    </ColourButton>
  );
}
