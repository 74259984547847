import React from "react";

// All other users.  Displays the columns for the table displaying the farms participating in the order
export default function DistributionFarmTableHeader() {
  return (
    <div className="Food-Table">
      <div className="Distribution-Farm-Table-Header">
        <h4
          className="Header-4"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          Farm
        </h4>
      </div>
      <div className="Distribution-Farm-Table-Header">
        <h4
          className="Header-4"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          Contact Info
        </h4>
      </div>
      <div className="Distribution-Farm-Table-Header">
        <h4
          className="Header-4"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          Minimum Order
        </h4>
      </div>
      <div className="Distribution-Farm-Table-Header">
        <h4
          className="Header-4"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          Order Cutoff Day(s)
        </h4>
      </div>
      <div className="Distribution-Farm-Table-Header">
        <h4
          className="Header-4"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          Delivery Day(s)
        </h4>
      </div>
      <div className="Distribution-Farm-Table-Header">
        <h4
          className="Header-4"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          Link to Website
        </h4>
      </div>
    </div>
  );
}
