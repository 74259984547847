// When a user has their items removed this email will send them a notice of their
// updated order.
import RemoveFoodItem from "../../../../ModifyOrders/RemoveFoodItem.js";
import firebase from "../../../../../components/Firebase.js";
import CreateVisibleCommunityOrders from "../../../../dialogs/functions/CreateVisibleCommunityOrders.js";
import UpdateCommunityOrders from "./UpdateCommunityOrders.js";
import batchEmails from "../../../../../functions/Email/BatchEmailer.js";

// This function is used to send in to the email batcher to help send off
// the emails.
export async function RemovalEmails(item) {
  const [
    userEmailsNoLocation,
    user,
    setSendingEmails,
    greeting,
    updatedOrders,
    orderString,
    foodItemsRemovedString,
    packageFeeString,
    deliveryFeeString,
    volunteerFeeString,
    setOpenReviewUserUpdatesEmailDialog,
    setMultipleSelection,
    setStateOfDialog,
    setSelectedItems,
    setSelectedPantryItems,
  ] = item;

  try {
    // Creates the callable function to send the emails to the users.
    const itemRemoval = firebase.functions().httpsCallable("itemRemoval");

    // Sets the destination of the email to the current distribution location's
    // email and to the user's email.
    const dest = [userEmailsNoLocation[user]];

    // Sets this to true to display the loading dialog while emails are sent
    setSendingEmails(true);

    // calls the cloud function
    await itemRemoval({
      dest,
      greeting,
      firstName: user.split("-")[0],
      updatedOrder: updatedOrders[user],
      order: orderString,
      foodItemsRemoved: foodItemsRemovedString,
      packageFeeString,
      deliveryFeeString,
      volunteerFeeString,
    });
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    console.error("There was an error when calling the Cloud Function", error);
    window.alert(
      "There was an error when calling the Cloud Function:\n\nError Code: " +
        code +
        "\nError Message:" +
        message,
    );
    setOpenReviewUserUpdatesEmailDialog(false);
    setMultipleSelection(false);
    setStateOfDialog("selectAction");
    setSendingEmails(false);
    setSelectedItems({});
    setSelectedPantryItems({});
  }
}

// If the user opens the first dialog and is in state 1/3
export default async function HandleEmailAndOrderRemoval(
  setSelectedItems,
  setSelectedPantryItems,
  order,
  userInfo,
  orderLog,
  updateOrderLog,
  updateOrders,
  orderIndex,
  userEmails,
  checkList,
  setSendingEmails,
  setDeletingItems,
  greeting,
  setOpenReviewUserUpdatesEmailDialog,
  setMultipleSelection,
  setStateOfDialog,
  usersWithFoodItemsRemoved,
  updatePaymentHistory,
  foodUserRemovalList,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  // Creates the batch of emails to send off.
  const emailBatch = [];
  // This creates a copy of the state order so that we can quickly and easily
  // update it while we cycle through the list to delete items.
  const orderCopy = JSON.parse(JSON.stringify(order));
  // Cycle through both the selected FoodItems
  // and then second check for the selected pantryItems.
  for (const currentItems of Object.keys(foodUserRemovalList)) {
    let cycle = 0;
    // This is used to adjust the index if an item prior was removed and now the
    // the foodList or pantryFoodItems has less items.  Each time an item is removed this number is adjusted
    // to account for more than one item being removed.
    let indexAdjustment = 0;
    for (const foodItemIndex of Object.keys(
      foodUserRemovalList[currentItems],
    )) {
      // Check if the item is exists.
      if (foodUserRemovalList[currentItems][foodItemIndex] !== undefined) {
        // Adjust the index so that it is accurate.  The first removal will have no
        // adjustment 0 and then every one after that will have +1
        const indexOfItem = foodItemIndex - indexAdjustment;

        // Check to see if this item is supposed to be removed.  It may not be
        // if the item is only being removed from user's orders.
        if (foodUserRemovalList[currentItems][foodItemIndex].fullRemovalFlag) {
          indexAdjustment += 1;
        }

        // This holds the foodItem that is currently being removed.
        const foodItemUpdate = orderCopy[currentItems][indexOfItem];

        // This is an array with the FirstName-LastName-userid-orderDate of all the orders that
        // have this item that is being removed.
        const ordersToChange =
          foodUserRemovalList[currentItems][foodItemIndex].ordersToChange;

        // We are discontinuing support for imported orders so we have this as a placeholder
        // so that it still works.
        const importedOrdersToChange = {};

        // Remove the foodItem from all affected users.
        await RemoveFoodItem(
          userInfo,
          orderCopy,
          orderLog,
          updateOrderLog,
          orderIndex,
          indexOfItem,
          foodItemUpdate,
          ordersToChange,
          importedOrdersToChange,
          updateOrders,
          updatePaymentHistory,
          cycle,
          foodUserRemovalList[currentItems][foodItemIndex].fullRemovalFlag,
          communityOrderChunks,
          setCommunityOrderChunks,
        );
      }
    }
    // Increment cycle by 1 on each iteration
    cycle += 1;
  }

  // Since we have the imported orders the userEmails is a dictionary like this
  // {location : {user} : email}.  We don't need the location on this dialog so this
  // is a dictionary with just the {user : email}
  const userEmailsNoLocation = {};

  // Cycle through the userEmails to remove the locations and just have the {user : email}
  Object.keys(userEmails).forEach((location) => {
    Object.keys(userEmails[location]).forEach((user) => {
      userEmailsNoLocation[user] = userEmails[location][user];
    });
  });

  // This is the combined orders of all the community members as some users may
  // have placed multiple orders and may have multiple orders affected.
  const communityOrdersCombined = CreateVisibleCommunityOrders(
    { ...orderCopy.communityOrders },
    null,
    null,
    false,
    [],
  );

  // This is the combined imported orders of all the community members as some users may
  // have placed multiple orders and may have multiple orders affected.
  const importedOrdersCombined = {};
  Object.keys(orderCopy.importedOrder).forEach((location, idx) => {
    importedOrdersCombined[location] = CreateVisibleCommunityOrders(
      { ...orderCopy.importedOrder[location].communityOrders },
      null,
      null,
      false,
      [],
    );
  });

  // This holds the updated orders with the the items removed {foodList : {}, customerContribution : "Donation" donationSubsidy : 10, etc.}
  let updatedOrders = {};

  updatedOrders = UpdateCommunityOrders(
    checkList,
    communityOrdersCombined,
    updatedOrders,
  );
  // Create an object of the checklist to cycle through.
  const users = Object.keys(checkList);
  // Cycle through the checkList that the user has selected.
  for (const user of users) {
    // Checks to see if the user has been checked
    if (checkList[user]) {
      // Creates a string to be included in the email of the user's updated order.
      let orderString = "";
      // The package fees in total for the order.
      let packageFeeTotal = 0;
      // The package fees owed for the order in string format to be put in the email.
      let packageFeeString = "";
      // The delivery fees owed for the order in string format to be put in the email.
      let deliveryFeeString = "";
      // The volunteer Fees owed for the order in string format to be put in the email.
      let volunteerFeeString = "";
      // Cycle through the user's updated order foodList and add each item to the string.
      updatedOrders[user].foodList.forEach((orderItem) => {
        // Set the price of the individal item
        let individualPrice = parseFloat(
          orderItem.price /
            (orderItem.distributionQuantity / orderItem.individualQuantity),
        );
        // If there is a package fee then add it to the item price and add it to the
        // the total package fee.
        if (orderItem.packageFee > 0) {
          individualPrice += parseFloat(orderItem.packageFee);
          packageFeeTotal +=
            parseFloat(orderItem.packageFee) * parseFloat(orderItem.quantity);
        }
        // Set the price of the individal item multiplied by the amount of that item.
        const individualPriceTotal = parseFloat(
          individualPrice * orderItem.quantity,
        ).toFixed(2);
        // Make the price a 2 decimal float for dollar format.
        individualPrice = individualPrice.toFixed(2);
        // Create the string for the email with the order item.
        orderString = orderString.concat(
          "• ",
          orderItem.item.toString(),
          " ",
          orderItem.individualDescription.toString(),
          " ",
          " x",
          orderItem.quantity.toString(),
          " -- $",
          individualPrice,
          " ($",
          individualPriceTotal.toString(),
          ")",
        );
        // If there is a package fee then add it to the string to let the user know.
        if (orderItem.packageFee > 0) {
          orderString = orderString.concat(
            " *includes pkg fee of ",
            parseFloat(orderItem.packageFee).toFixed(2),
            "/per item.",
          );
        }
        orderString = orderString.concat("<br />");
      });

      // Creates a string to be included in the email of all the items that were removed
      // from the order.
      let foodItemsRemovedString = "";
      // If a user ordered a removed item in two separate orders than we need to
      // combine them so we know how many and how much they were total for the user.
      const combinedRemovedFoodItems = {};

      // First we need to cycle through all the removed items and combine any items
      // that were ordered multiple times by a user in separate orders.
      usersWithFoodItemsRemoved[user].forEach((foodItem) => {
        // Create a foodKey that stores the food item and will allow us to add to it
        // if the user ordered this item more than once.
        const foodItemkey = [foodItem.item, foodItem.farmName].join("-");
        // Checks to see if the item had been removed multiple times
        if (combinedRemovedFoodItems[foodItemkey] !== undefined) {
          // Add the quantities together.
          combinedRemovedFoodItems[foodItemkey].quantity += foodItem.quantity;
        } else {
          // Add the foodItem as is.
          combinedRemovedFoodItems[foodItemkey] = { ...foodItem };
        }
      });
      // Once we have the combine removed food items now we can turn them into
      // strings to be added to the email.
      Object.keys(combinedRemovedFoodItems).forEach((foodItem, i) => {
        // Set a variable to be used for the removed food item.
        const removedFoodItem = combinedRemovedFoodItems[foodItem];
        // Set the price of the individal item
        let deletedItemPrice = parseFloat(
          removedFoodItem.price /
            (removedFoodItem.distributionQuantity /
              removedFoodItem.individualQuantity),
        );

        // If there is a package fee then add it to the item price and add it to the
        // the total package fee.
        if (removedFoodItem.packageFee > 0) {
          deletedItemPrice += parseFloat(removedFoodItem.packageFee);
        }

        // Set the price of the individal item multiplied by the amount of that item.
        const deletedItemPriceTotal = parseFloat(
          deletedItemPrice * combinedRemovedFoodItems[foodItem].quantity,
        ).toFixed(2);
        // Make the price a 2 decimal float for dollar format.
        deletedItemPrice = deletedItemPrice.toFixed(2);

        // Set the string of the removed food item to be included in the email.
        foodItemsRemovedString = foodItemsRemovedString.concat(
          i + 1,
          ". ",
          removedFoodItem.item.toString(),
          " ",
          removedFoodItem.individualDescription.toString(),
          " ",
          " x",
          removedFoodItem.quantity.toString(),
          " -- $",
          deletedItemPrice,
          " ($",
          deletedItemPriceTotal.toString(),
          ")",
        );
        // If there is a package fee then add it to the string to let the user know.
        if (removedFoodItem.packageFee > 0) {
          foodItemsRemovedString = foodItemsRemovedString.concat(
            " *includes pkg fee of ",
            parseFloat(removedFoodItem.packageFee).toFixed(2),
            "/per item.",
          );
        }
        foodItemsRemovedString = foodItemsRemovedString.concat("<br />");
      });

      // If the packageFee is greater than 0 then we want to print the total fees.
      if (packageFeeTotal > 0) {
        // Update the package Fee String used in the email.
        packageFeeString = packageFeeString.concat(
          "Included Package Fees : $",
          packageFeeTotal.toFixed(2),
          "<br />",
        );
      }

      // If the deliveryFee is greater than 0 then we want to print the total fees.
      if (updatedOrders[user].deliveryFee > 0) {
        deliveryFeeString = deliveryFeeString.concat(
          "+ Delivery Fee : $",
          updatedOrders[user].deliveryFee.toFixed(2),
          "<br />",
        );
      }
      // If there is no deliveryFee but it is from a community pickup then we use
      // the deliveryFee based on the current status.
      else if (updatedOrders[user].deliveryFeeTemp > 0) {
        deliveryFeeString = deliveryFeeString.concat(
          "+ Delivery Fee : $",
          updatedOrders[user].deliveryFeeTemp.toFixed(2),
          "**<br />",
        );
      }
      // If the Participation Fee Credits is greater than 0 then we want to print the total fees.
      if (updatedOrders[user].creditPortion > 0) {
        volunteerFeeString = volunteerFeeString.concat(
          "Participation Fee Credits : ",
          updatedOrders[user].creditPortion,
          " credits<br />",
        );
      }
      // If the Participation Fee Cash is greater than 0 then we want to print the total fees.
      if (updatedOrders[user].cashPortion > 0) {
        volunteerFeeString = volunteerFeeString.concat(
          "+ Participation Fee Cash : $",
          updatedOrders[user].cashPortion,
          "<br />",
        );
      }

      emailBatch.push([
        userEmailsNoLocation,
        user,
        setSendingEmails,
        greeting,
        updatedOrders,
        orderString,
        foodItemsRemovedString,
        packageFeeString,
        deliveryFeeString,
        volunteerFeeString,
        setOpenReviewUserUpdatesEmailDialog,
        setMultipleSelection,
        setStateOfDialog,
        setSelectedItems,
        setSelectedPantryItems,
      ]);
    }
  }

  // Call the batch email function that will handle the delays necessary
  // to sending the emails off without overloading the send mail function.
  await batchEmails({
    batchedParamsForEmailFunc: emailBatch,
    singularEmailFunc: RemovalEmails,
  });

  // Close the dialog
  setOpenReviewUserUpdatesEmailDialog(false);
  // Reset the checklist.
  setMultipleSelection(false);
  // reset the state.
  setStateOfDialog("selectAction");
  // Close the sending emails progress dialog
  setSendingEmails(false);
  setDeletingItems(false);
  setSelectedItems({});
  setSelectedPantryItems({});
}
