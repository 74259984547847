// This dialog is to edit a questionnaire.
import React, { useState } from "react";
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ButtonGroup from "@mui/material/ButtonGroup";
import QuestionEditorCard from "../../Components/Questionnaires/QuestionEditorCard.jsx";
import EditIcon from "@material-ui/icons/Edit";

// This is the dialog box that opens when a user clicks the edit questionnaire dialog
function EditQuestionnaireBox({
  open,
  onClose,
  questionnaire,
  indexOfQuestionnaire,
  questionnaires,
  setQuestionnaires,
}) {
  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = (cancel) => {
    onClose(false);
    // If the user clicks on cancel then reset the questionnaire to the originals.
    if (cancel === "cancel") {
      setQuestionnaireToUpdate({ ...questionnaire });
    }
  };

  // Create a deep copy of the questionnaire that we're updating.
  const [questionnaireToUpdate, setQuestionnaireToUpdate] = useState(
    JSON.parse(JSON.stringify(questionnaire)),
  );

  // If the user saves their changes then update the database and the hook.
  const handleSave = (e) => {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();

    // Create a reference to the distribution location's order for the selected order
    const docRef = database
      .collection("Questionnaires")
      .doc(questionnaireToUpdate.createdDate);

    batch.update(docRef, questionnaireToUpdate);

    batch.commit();
    // Create a variable to update the questionnaires list
    const questionnairesTemp = [...questionnaires];
    // Set the questionnaire to the updated one in the list.
    questionnairesTemp[indexOfQuestionnaire] = { ...questionnaireToUpdate };
    // Update the hook that holds the questionnaires.
    setQuestionnaires([...questionnairesTemp]);

    onClose(false);
  };

  // If the user adds a question when editing.
  const handleAddQuestion = (value) => {
    // Create a variable of the questionnaire to update.
    const questionnaireTemp = { ...questionnaireToUpdate };
    // Include a list of the questions of this questionnaire which we will update.
    const questionsTemp = [...questionnaireToUpdate.questions];
    // Set the question to add to an empty dictionary.
    const questionToAdd = {};
    // Create a blank question prompt.
    questionToAdd.questionPrompt = "";
    // Set the question options to an empty array.
    questionToAdd.options = [""];
    // Set the type of question we are adding.
    if (value === "multi") {
      questionToAdd.type = "multi";
    } else if (value === "checkbox") {
      questionToAdd.type = "checkbox";
    } else {
      questionToAdd.type = "text";
    }
    // Add the question to the questions list.
    questionsTemp.push(questionToAdd);
    // Questions to update.
    questionnaireTemp.questions = [...questionsTemp];

    setQuestionnaireToUpdate({ ...questionnaireTemp });
  };

  // If the questionnaire is changed then update the values like it's title, description.
  const handleQuestionnaireChange = (target) => {
    // Create a questionnaire temp to update.s
    const questionnaireTemp = { ...questionnaireToUpdate };

    const value = target.value;
    const name = target.name;
    // The questionnaire title is to update the title otherwise it's the questionnaire description.
    if (name === "questionnaireTitle") {
      questionnaireTemp.title = value;
    } else {
      questionnaireTemp.description = value;
    }
    // Update the questionnaire.
    setQuestionnaireToUpdate({ ...questionnaireTemp });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Edit Questionnaire
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <TextField
            margin="dense"
            id="questionnaireTitle"
            label="Questionnaire Title"
            name="questionnaireTitle"
            value={questionnaireToUpdate.title}
            onChange={(e) => {
              handleQuestionnaireChange(e.target);
            }}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="questionnaireDescription"
            label="Question Description"
            name="questionnaireDescription"
            value={questionnaireToUpdate.description}
            onChange={(e) => {
              handleQuestionnaireChange(e.target);
            }}
            fullWidth
            required
          />
          <ButtonGroup>
            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddQuestion("multi")}
            >
              MULTIPLE CHOICE
            </Button>
            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddQuestion("checkbox")}
            >
              CHECKBOXES
            </Button>
            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddQuestion("text")}
            >
              TEXT ANSWER
            </Button>
          </ButtonGroup>
          <div>
            {questionnaireToUpdate.questions.map((question, index) => (
              <QuestionEditorCard
                key={index}
                questionnaire={questionnaireToUpdate}
                setQuestionnaire={setQuestionnaireToUpdate}
                indexOfQuestion={index}
              />
            ))}
          </div>
          <DialogActions>
            <Button onClick={() => handleClose("cancel")} color="primary">
              CANCEL
            </Button>
            <Button type="submit" color="primary">
              SAVE
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

EditQuestionnaireBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  questionnaire: PropTypes.object,
  indexOfQuestionnaire: PropTypes.number,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
  questionnaireEnabled: PropTypes.bool,
};

export default function EditQuestionnaireDialog({
  questionnaire,
  indexOfQuestionnaire,
  questionnaires,
  setQuestionnaires,
  questionnaireEnabled,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        disabled={questionnaireEnabled}
        onClick={handleClickOpen}
        variant="outlined"
        startIcon={<EditIcon />}
      >
        EDIT QUESTIONNAIRE
      </Button>

      <EditQuestionnaireBox
        open={open}
        onClose={handleClose}
        questionnaire={questionnaire}
        indexOfQuestionnaire={indexOfQuestionnaire}
        questionnaires={questionnaires}
        setQuestionnaires={setQuestionnaires}
      />
    </div>
  );
}

EditQuestionnaireDialog.propTypes = {
  questionnaire: PropTypes.object,
  indexOfQuestionnaire: PropTypes.number,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
  questionnaireEnabled: PropTypes.bool,
};
