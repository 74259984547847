// This is used to update the community orders for a distribution location that is
// removing foodItems from a user.  This is to ensure their order stats are updated
// such as subsidy and grandTotal so that we can include them in the email.  The foodList
// will already be updated we just want the right prices.
import PriceTotals from "../../../../../functions/PriceTotals.js";
import PackageTotals from "../../../../../functions/PackageTotals.js";

export default function UpdateCommunityOrders(
  checkList,
  communityOrdersCombined,
  updatedOrders,
) {
  // Cycle through the checkList of the checked users to update the correct amounts
  // for grandTotal, donations, subsidies etc.
  Object.keys(checkList).forEach((user, i) => {
    // Cycle through the communityOrders with one order per person.
    Object.keys(communityOrdersCombined).forEach((pickupLocation) => {
      // Cycle through the pickupLocation's as distribution locations have their
      // pickup on site location and then also the community locations.
      Object.keys(communityOrdersCombined[pickupLocation]).forEach(
        (userOrder) => {
          // Check to see if the current user matches the userOrder in the checkList
          if (user === userOrder) {
            // This is the total users at the location and will be used to calculate the current
            // delivery fee at this location.
            const totalUsersAtLocation = Object.keys(
              communityOrdersCombined[pickupLocation],
            ).length;
            // Set the updated order to the new communityOrder which has the correct
            // foodList already.
            updatedOrders[user] = communityOrdersCombined[pickupLocation][user];

            // A dictionary of the the farms(keys) and the total owed to them (values)
            const farmTotals = PriceTotals(updatedOrders[user].foodList, true);
            // A dictionary of the the farms(keys) and the total packaging fees to them (values)
            const packageTotals = PackageTotals(updatedOrders[user].foodList);
            // The total including the donation or subsidy
            let grandTotal = 0;

            // Sum up the grandTotal of all the farms together.
            Object.keys(farmTotals).forEach((farmTotal) => {
              grandTotal += farmTotals[farmTotal];
            });

            // Cycle through the package Totals and add them all up.
            Object.keys(packageTotals).forEach((packageTotal) => {
              grandTotal += packageTotals[packageTotal];
            });

            // If the user is not picking up on site then we know their going to a
            // community hub and will need to add the delivery fee.
            if (pickupLocation !== "Pickup On Site") {
              // If the user has a delivery fee already then we just add that to the total.
              if (updatedOrders[user].deliveryFee !== undefined) {
                grandTotal += updatedOrders[user].deliveryFee;
              }
              // If the user doesn't have a delivery fee then we have to calculate it
              // base on the current delivery fee.
              else {
                // The delivery base fee from the community hubs.  We want to make sure
                // we calculate the delivery fee based off the time it was purchased and not
                // the location's current base fee in case it has changed.
                let baseDeliveryFee = parseFloat(
                  updatedOrders[user].communityPickup.pickupLocation
                    .baseDeliveryFee,
                );
                // If the communityHub has a special delivery fee set it.
                if (
                  updatedOrders[user].communityPickup.communityDeliveryFee ||
                  updatedOrders[user].communityPickup.communityDeliveryFee === 0
                ) {
                  baseDeliveryFee =
                    updatedOrders[user].communityPickup.communityDeliveryFee;
                }
                // Per user delivery fee.
                const deliveryFee = baseDeliveryFee / totalUsersAtLocation;
                // Add a temp delivery fee that will be used.
                updatedOrders[user].deliveryFeeTemp = deliveryFee;
                // Add the delivery fee amount to the total
                grandTotal += deliveryFee;
              }
            }

            // If the volunteer cash portion is greater than 0 then we want to add it to the total.
            if (updatedOrders[user].cashPortion > 0) {
              grandTotal += parseFloat(updatedOrders[user].cashPortion);
            }

            // Add in the subdidy and donation amounts
            if (updatedOrders[user].customerContribution === "donation") {
              grandTotal += updatedOrders[user].donationSubsidy;
            } else {
              grandTotal -= updatedOrders[user].donationSubsidy;
              // If the grandTotal is less than 0 then we don't want the subsidy to
              // be more than the total amount so we will deduct from the total subsidy and
              // set the grandTotal to 0.
              if (grandTotal < 0) {
                updatedOrders[user].donationSubsidy += grandTotal;
                // We just want to make sure that the subsidy doesn't go negative
                // if there was some strange error.
                if (updatedOrders[user].donationSubsidy < 0) {
                  updatedOrders[user].donationSubsidy = 0;
                }
                // Set the grandTotal to 0 because we don't want it to be less than 0.
                grandTotal = 0;
              }
            }
            // The user's grandTotal cost added to the updatedOrders.
            updatedOrders[user].grandTotal = grandTotal;
          }
        },
      );
    });
  });
  // Return the updated Orders.
  return updatedOrders;
}
