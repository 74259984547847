// This is called when a community host removes a user from being able to order
// through their community location.  The user being removed will no longer be
// able to see the community host and they will have their pickup location reset
// to the partnered distribution location.
import firebase from "../../../components/Firebase.js";

export default function RemoveCommunityMember(
  communityMember,
  userInfo,
  communityMembers,
  setCommunityMembers,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  const communityLocationDocumentName = [
    userInfo.firstName,
    userInfo.lastName,
    userInfo.userId,
  ].join("");

  const communityLocationsDocRef = database
    .collection("CommunityLocations")
    .doc(communityLocationDocumentName);

  const userDocRef = database
    .collection("Users")
    .doc(userInfo.userId)
    .collection("CommunityHosts")
    .doc("CommunityMembers");

  // Create a blank array for the community memebers that have been approved.
  const communityMembersArr = [];

  // Create a temporary list for all the community members.
  const communityMembersTemp = { ...communityMembers };

  // Set the currently being deleted member to false.
  communityMembersTemp[communityMember] = false;

  // Cycle through the communityMembers and add the rest of the community memebers
  // that are still approved.
  Object.keys(communityMembersTemp).forEach((member) => {
    if (communityMembersTemp[member]) {
      communityMembersArr.push(member.split("-")[2]);
    }
  });

  // Update the database.
  batch.update(communityLocationsDocRef, {
    approvedCommunityMembers: [...communityMembersArr],
  });

  batch.update(userDocRef, {
    [communityMember]: false,
  });

  batch.commit();

  setCommunityMembers({ ...communityMembersTemp });
}
