// This dialog is created when a user applies to a volunteer position.
// The browser needs time to send an email confirmation and so once that has been
// send it will close.
import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../styles/MyAccount.css";

function SendingVolunteerApplicationBox(props) {
  const { open } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Sending Application</DialogTitle>
      <DialogContent>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "85px",
          }}
        >
          Please wait while we send your application.
          <CircularProgress />
        </span>
      </DialogContent>
    </Dialog>
  );
}

SendingVolunteerApplicationBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function SendingVolunteerApplicationDialog(props) {
  const open = props.open;

  return (
    <div>
      <SendingVolunteerApplicationBox open={open} />
    </div>
  );
}
