// This handles when the user selects the checkbox for a weekly recurring delivery
// It receives the index of the food item in the list of food items on the basket
// page.
export default function RecurringOrderCheckbox(food, foodItems, setFoodItems) {
  // creates a temporary array to store the list of food items on the basket
  // page.
  const arr = [...foodItems];
  // Checks to see if the foodItems checked variable exists yet.  If it doesn't
  // then we set it true otherwise we just not the boolean.
  if (arr[food].checked === undefined) {
    arr[food].checked = true;
  } else {
    arr[food].checked = !arr[food].checked;
  }
  // Update foodItems to the temporary array
  setFoodItems([...arr]);
}
