// This accordion show the users that are having their items removed.  This will also allow the
// distribution location to determine if they want to remove all the item or just from some users.
import React, { useState } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Accordion = withStyles({
  root: {
    background: "#b4ece6",
  },
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    flexDirection: "column",
  },
})(MuiAccordionDetails);

export default function RemovingFoodUserAccordion({
  foodItemIndex,
  foodUserRemovalList,
  setFoodUserRemovalList,
  foodList,
  order,
}) {
  // When this is set to 0 then we are removing all the items and so we also
  // will remove the item from the distribution location's list.
  const [totalRemoval, setTotalRemoval] = useState(0);

  // This is the foodItem as it is in the foodList.
  const foodItem = order[foodList][foodItemIndex];

  // This is the dictionary that lists all the foodItems that have been removed including the
  // pantryItems or the newly ordered food items.  Then for each foodItem it contains details about
  //  which users have ordered the item being removed and flags about whether the item will be
  // removed and also the ordersToChange array.
  const foodUserRemovalListTemp = { ...foodUserRemovalList };

  // These are the details of this foodItem such as the users ordering the item, ordersToChange and the flag
  // whether we will remove this from the distribution location's foodList.
  const foodItemDetailsTemp = foodUserRemovalList[foodList][foodItemIndex];

  // When the user changes the checklist
  const handleChange = (user) => {
    // First set a variable that we can update that will have the number of items not being removed.
    let totalRemovalTemp = totalRemoval;
    // Set the user's checkmark to the opposite of what it is now.
    foodItemDetailsTemp.detailedOrders[user].checked =
      !foodItemDetailsTemp.detailedOrders[user].checked;

    // If there new checkmark is true then we will subtract the quantity from total removal as it is no longer
    // being removed.
    if (foodItemDetailsTemp.detailedOrders[user].checked) {
      totalRemovalTemp -= foodItemDetailsTemp.detailedOrders[user].quantity;
    }
    // Otherwise we'll add it so we know that there are items that have not been removed.
    else {
      totalRemovalTemp += foodItemDetailsTemp.detailedOrders[user].quantity;
    }

    // If the total removal is equal to 0 then we will set the flag to true as we will
    // want to remove this item from the distribution location.
    if (totalRemovalTemp === 0) {
      foodItemDetailsTemp.fullRemovalFlag = true;
    }
    // Otherwise this item isn't being removed from the distribution location since there will be
    // some users keeping their foodItems.
    else {
      foodItemDetailsTemp.fullRemovalFlag = false;
    }

    // Set the state to make sure it is saved.
    setTotalRemoval(totalRemovalTemp);

    // Update the foodUserRemovalListTemp
    foodUserRemovalListTemp[foodList][foodItemIndex] = foodItemDetailsTemp;

    // Update the foodUserRemovalList state
    setFoodUserRemovalList({ ...foodUserRemovalListTemp });
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5>
            <b>
              {foodItem.item} {foodItem.individualDescription} from{" "}
              {foodItem.farmName}
            </b>
          </h5>
        </AccordionSummary>
        <AccordionDetails>
          {Object.keys(foodItemDetailsTemp.detailedOrders).map(
            (user, keyIndex) => (
              <FormControlLabel
                key={keyIndex}
                control={
                  <Checkbox
                    name={user}
                    checked={foodItemDetailsTemp.detailedOrders[user].checked}
                    onChange={() => handleChange(user)}
                  />
                }
                label={[
                  user.split("-")[0],
                  user.split("-")[1],
                  " x",
                  foodItemDetailsTemp.detailedOrders[user].quantity,
                ].join(" ")}
              />
            ),
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
