// These are the user receipts displayed on the homepage for everyone to see.  These
// have everyone's names blurred out.  It is called by CommunityOrdersDialogHomePage.
import React, { useEffect, useState } from "react";
import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";
import DisplayAnswersDialog from "../Dialogs/DisplayAnswersDialog.jsx";
import PropTypes from "prop-types";
import {
  calculatePackageFeeTotal,
  calculateTotalCost,
} from "../Functions/CreateOrdersSpreadsheetUtil.js";

// This is used to print on the webpage the user receipts.  This is exactly the
// same as the orderString but is in html and can be read by the browser instead
// of the docx file.
export default function UserReceiptHomePage({
  idx,
  userOrder,
  userId,
  questionnaireAnswers,
}) {
  // A dictionary of the the farms(keys) and the total owed to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);

  // The answers the questions of this user to the questionnaire.
  const [answeredQuestionnaires, setAnsweredQuestionnaires] = useState([]);

  // Load the answers of the users from the questionnaire answers that was loaded from the database.
  useEffect(() => {
    // The array temp to use while loading.
    const answeredQuestionnairesTemp = [];
    // Cycle through the questionnaires with their answers.
    questionnaireAnswers.forEach((questionnaire, indexOfQuestionnaire) => {
      // Check if the current user receipt was placed by a user that has answered the questionnaire.
      if (Object.keys(questionnaire.answers).includes(userId)) {
        // Add the index of the questionnaire that the user has answered to make sure
        // we check later for their answers and display them.
        answeredQuestionnairesTemp.push(indexOfQuestionnaire);
      }
    });
    setAnsweredQuestionnaires([...answeredQuestionnairesTemp]);
  }, []);

  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);

  // The total including the donation or subsidy
  const grandTotal = calculateTotalCost(userOrder);

  // The total packaging fee.
  const packageFeeTotal = calculatePackageFeeTotal(userOrder);

  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion;
  // The total volunteer Fee creit portion.
  const volunteerFeeCredits = userOrder.creditPortion;

  return (
    <div key={idx}>
      <h5>Customer Order</h5>
      {answeredQuestionnaires.map((indexOfQuestionnaire, indexOfMap) => (
        <DisplayAnswersDialog
          key={indexOfMap}
          indexOfQuestionnaire={indexOfQuestionnaire}
          userId={userId}
          questionnaireAnswers={questionnaireAnswers}
        />
      ))}
      {userOrder.foodList.map((food, itemNumber) => {
        // Set the price of the individal item
        let individualPrice = parseFloat(
          food.price / (food.distributionQuantity / food.individualQuantity),
        );
        // The individual description of the food item for the user to know how
        // much they are purchasing.
        let individualDescription = food.individualDescription;

        // if suggestedPrice is true then we want to set the price to 0
        if (food.suggestedPrice) {
          // First add to the description the suggested Donation.
          individualDescription = individualDescription.concat(
            " - Suggested Donation of $",
            individualPrice,
          );
          // Then reset the prices to 0.
          individualPrice = 0.0;
        }

        if (food.packageFee > 0) {
          individualPrice += parseFloat(food.packageFee);
        }
        const individualPriceTotal = parseFloat(
          individualPrice * food.quantity,
        ).toFixed(2);
        individualPrice = individualPrice.toFixed(2);
        return (
          <span key={itemNumber}>
            {/* This is necessary to deal with the old database before the foodItems
            for both individual and distribution location's were combined */}
            {food.individualDescription === undefined ? (
              <p style={{ margin: "0px 0px 0px 20px" }}>
                {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
                {food.description} x{food.quantity}
              </p>
            ) : (
              <p style={{ margin: "0px 0px 0px 20px" }}>
                {itemNumber + 1}. {food.farmName} <strong>{food.item}</strong>{" "}
                {individualDescription} x{food.quantity} -- ${individualPrice}{" "}
                (${individualPriceTotal}){food.packageFee > 0 && <>*</>}
                {!!food.limit && (
                  <img
                    style={{ marginLeft: "5px", height: "15px" }}
                    src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
                  />
                )}
                {food.packageFee > 0 && (
                  <p className="Item-Package-Fee">
                    *includes package fee $
                    {parseFloat(food.packageFee).toFixed(2)}/per item
                  </p>
                )}
              </p>
            )}
          </span>
        );
      })}
      <div className="Price-Totals">
        <h5 className="Contact-Method-Title Food-Item-Header">
          {" "}
          Farm Price Totals{" "}
        </h5>
        {Object.keys(farmTotals).map((farmTotal, index) => (
          <p key={farmTotal + index} className="Prices-Per-Farm">
            {farmTotal} : ${farmTotals[farmTotal].toFixed(2)}
            {packageTotals[farmTotal] > 0 && (
              <span
                style={{ verticalAlign: "super" }}
                className="Item-Package-Fee"
              >
                + ${packageTotals[farmTotal].toFixed(2)} pkg fee
              </span>
            )}
          </p>
        ))}

        <span style={{ fontSize: "12px", textAlign: "end" }}>
          {packageFeeTotal > 0 && (
            <p className="Total-Packaging-Fees">
              Total Package Fees : ${packageFeeTotal.toFixed(2)}
            </p>
          )}
          {parseFloat(userOrder.deliveryFee) > 0 && (
            <p className="Total-Packaging-Fees">
              Delivery Fee : ${userOrder.deliveryFee.toFixed(2)}
            </p>
          )}
          {parseFloat(volunteerFeeCash) > 0 && (
            <p className="Total-Packaging-Fees">
              Participation Fee (Cash) : ${volunteerFeeCash}
            </p>
          )}
          {parseFloat(volunteerFeeCredits) > 0 && (
            <p className="Total-Packaging-Fees">
              Participation Fee (Credits) : {volunteerFeeCredits}
            </p>
          )}
        </span>

        <span>
          {userOrder.customerContribution === "donation" ? (
            <p className="Subsidy-Donation-Totals" style={{ color: "green" }}>
              Donation : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          ) : (
            <p className="Subsidy-Donation-Totals" style={{ color: "red" }}>
              Subsidy : ${userOrder.donationSubsidy.toFixed(2)}
            </p>
          )}
        </span>
        <p>
          <b>Total </b>: ${grandTotal.toFixed(2)}
        </p>
      </div>
    </div>
  );
}

UserReceiptHomePage.propTypes = {
  idx: PropTypes.number,
  userOrder: PropTypes.object,
  userId: PropTypes.string,
  questionnaireAnswers: PropTypes.array,
};
