// This function is used to calculate the imported community orders cost.  It takes in the
// dictionary of imported community order and then outputs
// a dollar value.
import PriceTotals from "../../functions/PriceTotals.js";

export default function ImportedOrdersTotal(importedOrders) {
  // Add up the cost of all the orders of the preorders so that you can see
  // how much food was preordered and is accounted for.
  const communityOrdersTotal = {};
  // Cycles through all the imported locations.
  Object.keys(importedOrders).forEach((location) => {
    // initializes the total cost to $0
    communityOrdersTotal[location] = 0;
    // Cycle through each individual customer
    Object.keys(importedOrders[location].communityOrders).forEach(
      (customer) => {
        // This will store the amount each individual pays to each farm
        let orderTotals = {};
        // returns the total owed to each farm
        orderTotals = PriceTotals(
          importedOrders[location].communityOrders[customer].foodList,
          true,
        );
        // Cycle through the amounts that the individual owes to the farm and
        // add each value to the overall specificed location's orders total
        Object.keys(orderTotals).forEach((farm) => {
          communityOrdersTotal[location] += orderTotals[farm];
        });
      },
    );
    // This is the value of all the community orders
    // conert this total to a float.
    communityOrdersTotal[location] = parseFloat(
      communityOrdersTotal[location],
    ).toFixed(2);
  });

  return communityOrdersTotal;
}
