// This is to remove an individual user's order from the Basket page.
import firebase from "./../Firebase.js";

// user : the user in the format FirstName-LastName-UserId-orderDate
// imported: boolean if this order was imported
// handleCommunityOrderChange : This is a function used to rerender the page after the removal
// importedOrder : A dictionary that includes the inforamtion about the importedOrder
// location : the name of the distribution location.
// selectedDate : the date of the distribution.
export default async function RemoveCustomerOrderBasket(
  user,
  imported,
  userInfo,
  handleCommunityOrderChange,
  importedOrder,
  location,
  selectedDate,
  userOrder,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userOrderDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userId[3]);

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database.collection("Users").doc(userId[2]);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // Update the current distribution locations orders in the DistributionLocations
  // collection
  const communityOrdersRef = database
    .collection("DistributionLocations")
    .doc(userInfo.organisationName)
    .collection("CommunityOrders");

  const querySnapshot = await communityOrdersRef
    .where("distributionDate", "==", selectedDate)
    .get();

  // Loop through the documents to find the one with the matching user key
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // Check if the document contains the user key
    if (user in data) {
      communityOrdersDocRef = doc.ref; // Reference to the found document
    }
  });

  // Check if a document was found for the user
  if (!communityOrdersDocRef) {
    console.log("No document found for user:", user);
    return; // Exit if no document was found
  }

  // Remove the order from the DistributionLocation collection
  batch.update(communityOrdersDocRef, {
    [user]: firebase.firestore.FieldValue.delete(),
    orderCount: firebase.firestore.FieldValue.increment(-1),
  });

  // If the user used credits for their order then we want to refund their credits.
  if (parseFloat(userOrder.creditPortion) > 0) {
    batch.update(userDocRef, {
      credits: firebase.firestore.FieldValue.increment(
        parseFloat(userOrder.creditPortion),
      ),
    });
  }

  // Delete the order from the individual's order
  userOrderDocRef
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });

  await batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  handleCommunityOrderChange(imported, null, null, user, null, true, location);

  console.log("Successfully wrote to database!");
}
