//   ReorderPantryItems function sorts the pantry items based on the specified criteria:
//   alphabetically by item name, by farm name, by category (tagList), or by remaining quantity (limit).
//   It takes the current pantry items, sorts them based on the selected sorting criteria,
//  and updates the state with the newly sorted items.

export function ReorderPantryItems(
  pantryItems, // The list of pantry items (leftovers)
  setPantryItems, // Function to update the sorted pantry items (setLeftovers)
  alphabetically, // Boolean to indicate if items should be sorted alphabetically
  byFarm, // Boolean to indicate if items should be sorted by farm name
  byCategory, // Boolean to indicate if items should be sorted by category (tags)
  byRemaining, // Boolean to indicate if items should be sorted by remaining quantity
) {
  // Helper function to sort by a specific key in each item (like item name or farm name)
  const sortByKey = (arr, key) => {
    return arr.sort((a, b) => {
      // Sort case-insensitively by comparing the specified key
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      return 0;
    });
  };

  // Helper function to sort items by their tagList (used as category)
  const sortByTagList = (arr) => {
    return arr.sort((a, b) => {
      // Check if both items have a valid tagList
      const aHasTagList = Array.isArray(a.tagList) && a.tagList.length > 0;
      const bHasTagList = Array.isArray(b.tagList) && b.tagList.length > 0;

      // Handle cases where one item has a tag list and the other doesn't
      if (!aHasTagList && bHasTagList) return 1;
      if (aHasTagList && !bHasTagList) return -1;
      if (!aHasTagList && !bHasTagList) return 0;

      // Compare the first tag in the tag list for both items
      const aFirstTag = a.tagList[0].label.toLowerCase();
      const bFirstTag = b.tagList[0].label.toLowerCase();

      return aFirstTag.localeCompare(bFirstTag);
    });
  };

  // Helper function to sort items by their remaining quantity (limit field)
  const sortByRemaining = (arr) => {
    return arr.sort((a, b) => {
      // Convert 'limit' field to a float and check for valid values
      const aRemaining = parseFloat(a.limit); // 'limit' represents remaining quantity
      const bRemaining = parseFloat(b.limit); // 'limit' represents remaining quantity
      if (isNaN(aRemaining) || isNaN(bRemaining)) {
        // Log an error if there's an invalid value in the remaining field
        console.error("Invalid remaining value:", a, b);
        return 0;
      }
      // Sort in ascending order by remaining quantity
      return aRemaining - bRemaining;
    });
  };

  // Convert pantryItems object to an array (if it's an object), or use an empty array if null
  const pantryArray = Object.values(pantryItems || {});

  // Copy the pantryArray to avoid mutating the original array
  let sortedItems = [...pantryArray];

  // Sorting logic: apply the appropriate sort based on the flags passed in
  if (alphabetically) {
    sortedItems = sortByKey(sortedItems, "item"); // Sort by item name alphabetically
  } else if (byFarm) {
    sortedItems = sortByKey(sortedItems, "farmName"); // Sort by farm name alphabetically
  } else if (byCategory) {
    sortedItems = sortByTagList(sortedItems); // Sort by category (tag list)
  } else if (byRemaining) {
    sortedItems = sortByRemaining(sortedItems); // Sort by remaining quantity in ascending order
  }

  // Update the state with the sorted pantry items
  setPantryItems(sortedItems);
}
