// This is used to add an item to the pantry food list.
import React, { useState, useEffect } from "react";
import firebase from "../../../components/Firebase.js";
import AddPantryFoodItem from "../../../components/ModifyOrders/AddPantryFoodItem.js";
import LoadMarketplaceItems from "./../../../functions/LoadMarketplaceItems.js";
import FoodItemInformationDialog from "../../../components/dialogs/Components/FoodItemInformationDialog.jsx";

export const SetPantryVariablesContext = React.createContext();

export default function AddItemToPantryOrderDialog({
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  userInfo,
}) {
  const [open, setOpen] = useState(false);
  // State of loading the foodItems.
  const [loading, setLoading] = useState(false);
  // The list of all the food Items available.
  const [foodItemsToSelect, setFoodItemsToSelect] = useState([]);
  // The foodItems that are being shown in case the user has selected
  // a farm to be removed.
  const [displayedFoodItemsToSelect, setDisplayedFoodItemsToSelect] = useState(
    [],
  );
  // The farmers that exist and provide the foodItems.
  const [farmersAvailable, setFarmersAvailable] = useState([]);
  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}

  // Load the foodItems that are available for the user to select
  // from to add if they would like to autofill.
  useEffect(() => {
    // If the length is 0 then we haven't loaded the foodItems yet.
    // We want it to be open so that we don't end up with a bunch
    // of loads when we only need to do it once when the user opens.
    if (foodItemsToSelect.length === 0 && open) {
      // Load foodItemsToSelect
      LoadMarketplaceItems(
        null,
        1000,
        true,
        {},
        setFoodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        null,
        setLoading,
        userInfo,
        {},
      );
    }
    // If the length is greater than 0 then we have already loaded
    // the foodItems and can just set loading to true.
    else if (foodItemsToSelect.length > 0 && open) {
      setLoading(true);
    }
  }, [open]);

  // Once we have the list of foodItems then we have to set the list
  // of farmers available so the user can toggle the items from each farm
  // on or off.
  useEffect(() => {
    //  Make sure the foodItems exist and that the user has opened
    // the dialog.
    if (foodItemsToSelect.length > 0 && open) {
      const farmersAvailableTemp = [];
      // Cycle through all the foodItems and add the farms
      // to the list.  Some items will come from the same
      // farm so check if it's already been added.
      foodItemsToSelect.forEach((foodItem) => {
        if (!farmersAvailableTemp.includes(foodItem.farmName)) {
          farmersAvailableTemp.push(foodItem.farmName);
        }
      });
      farmersAvailableTemp.sort();
      setFarmersAvailable([...farmersAvailableTemp]);
    }
  }, [foodItemsToSelect, open]);

  const handleSave = (state, handleClose) => {
    // Sets the pantryFoodItem so that it includes all the items it needs to
    // be added.
    const pantryFoodItem = { ...state };
    pantryFoodItem.pantryLocation = userInfo.organisationName;
    pantryFoodItem.userId = firebase.auth().currentUser.uid;
    // This is hardcoded to match deprecated code
    pantryFoodItem.userType = "admin";

    // Add the foodItem to the pantryFoodList.
    AddPantryFoodItem(
      pantryFoodItem,
      order,
      orderLog,
      updateOrderLog,
      orderIndex,
    );
    handleClose(false);
  };

  return (
    <SetPantryVariablesContext.Provider
      value={{
        displayedFoodItemsToSelect,
        farmersAvailable,
        foodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        open,
      }}
    >
      <FoodItemInformationDialog
        buttonStyle={1}
        callSaveFunction={handleSave}
        userInfo={userInfo}
        showAutofill={true}
        loading={loading}
        setParentOpen={setOpen}
        limitRequired={true}
      />
    </SetPantryVariablesContext.Provider>
  );
}
