// This Function adds food categories to a distribution location's key in the
// DistributionLocations -> Vancouver document when a distribution location
// adds a farm to it's sourcing.
import firebase from "./../components/Firebase.js";

export default function AddFoodCategories(
  distributionLocationInformationTemp,
  setDistributionLocationInformation,
  farm,
  batch,
  distributionLocationsDocRef,
  userInfo,
) {
  const database = firebase.firestore();
  // finds all the foodItems that are sourced from the given farm.
  const foodItemsDocRef = database
    .collection("FoodItems")
    .where("farmName", "==", farm.farmName)
    .where("userType", "==", "admin");
  // Creates an array of all the categories.
  const farmCategories = [];
  // Create dot reference to the document to change.
  let locationToUpdate = [userInfo.organisationName, "categories"];
  locationToUpdate = locationToUpdate.join(".");

  // Cycles through the foodItems and finds the matching foodItems from the farm.
  // then adds all the categories to each farm
  foodItemsDocRef.get().then((collection) => {
    collection.docs.forEach((doc) => {
      if (doc.exists) {
        // Only add the category if it hasn't already been added.
        if (!farmCategories.includes(doc.data().category)) {
          farmCategories.push(doc.data().category);
        }
      }
    });

    if (distributionLocationInformationTemp.categories === undefined) {
      distributionLocationInformationTemp.categories = {};
    }
    distributionLocationInformationTemp.categories[farm.farmName] =
      farmCategories;

    // Update the distributionLocation's categories data.
    batch.update(distributionLocationsDocRef, {
      [locationToUpdate]: { ...distributionLocationInformationTemp.categories },
    });

    // Commit these changes
    batch.commit();
    // update the hook to display the correct information for the farm
    setDistributionLocationInformation({
      ...distributionLocationInformationTemp,
    });
  });
}
