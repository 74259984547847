// This function handles when a distributionLocation when it deletes a sourcing farm
// that was added by another distribution location that imported this order.  It has
// to update their categories dictionary on the DistributionLocations -> Vancouver document
// and update the farmsPickupDates.
import firebase from "../../../components/Firebase.js";
import UpdateCommunityLocation from "../../../functions/UpdateCommunityLocation.js";

export default function DeleteImportedSourcingFarm(
  farm,
  organisationName,
  distributionLocationInformation,
  setDistributionLocationInformation,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");

  // Stores the data that is in distributionLocation collection
  const distributionLocationInformationTemp = {
    ...distributionLocationInformation,
  };

  // Creates the dot reference to update a distributionLocations farmsPickupDates
  let locationToUpdate = [organisationName, "farmsPickupDates"];
  locationToUpdate = locationToUpdate.join(".");

  // Splices out this farm from the distributionLocation's farmsPickupDates
  delete distributionLocationInformationTemp.farmsPickupDates[farm];

  // This is for whether the farm is showing the pantry and the catalogue at the same
  // time.
  let locationToUpdateForCatalogueAndPantry = [
    organisationName,
    "farmsCatalogueAndPantry",
  ];
  locationToUpdateForCatalogueAndPantry =
    locationToUpdateForCatalogueAndPantry.join(".");

  // Splices out this farm from the distributionLocation's farmsCatalogueAndPantry
  delete distributionLocationInformationTemp.farmsCatalogueAndPantry[farm];

  // Creates the dot reference to update a distributionLocations categories
  let locationToUpdate2 = [organisationName, "categories"];
  locationToUpdate2 = locationToUpdate2.join(".");

  // Splices out this farm from the distributionLocation's categories
  delete distributionLocationInformationTemp.categories[farm];

  // Updates the information in the DistributionLocations -> Vancouver Document for farmsPickupDates and farmsCatalogueAndPantry.
  batch.update(distributionLocationsDocRef, {
    [locationToUpdate]: {
      ...distributionLocationInformationTemp.farmsPickupDates,
    },
    [locationToUpdateForCatalogueAndPantry]: {
      ...distributionLocationInformationTemp.farmsCatalogueAndPantry,
    },
    [locationToUpdate2]: { ...distributionLocationInformationTemp.categories },
  });

  // Updates the community location in CommunityLocations collection in the database.
  UpdateCommunityLocation(
    distributionLocationInformationTemp.userId,
    distributionLocationInformationTemp,
  );
  batch.commit();
  setDistributionLocationInformation(distributionLocationInformationTemp);
}
