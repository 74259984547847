// This function will update any community locations that may exist that have partnered
// with the curretly changing distribution location.  It is only called when a distribution
// location places an order or adds to an exisiting order.  There is UpdateCommunityLocation.js
// for all other updates.

import firebase from "../components/Firebase.js";

export default function UpdateCommunityLocationFarms(location, farm, date) {
  const database = firebase.firestore();
  const batch = database.batch();

  // The CommunityLocations that were are going to read through to find which are being
  // affected.
  const docRef = database
    .collection("CommunityLocations")
    .where("distributionLocationName", "==", location);

  // Read these documents
  docRef.get().then((collection) => {
    // eslint-disable-next-line no-unused-vars
    const dataTransfer = [];
    // Cycle through all these community locations.
    collection.docs.forEach((doc) => {
      // If the location exists.
      if (doc.exists) {
        // Set the document name.
        const documentName = [
          doc.data().firstName,
          doc.data().lastName,
          doc.data().userId,
        ].join("");
        // Reference each document that we need to update.
        const updateDocRef = database
          .collection("CommunityLocations")
          .doc(documentName);
        // Update the farms that need to be set.  Since we only want the pantry to show then
        // we'll remove the catalogue items.
        batch.update(updateDocRef, {
          [["pickupLocation", "farmsPickupDates", farm].join(".")]: date,
          [["pickupLocation", "farmsCatalogueAndPantry", farm].join(
            ".",
          )]: false,
        });
      }
    });
    batch.commit();
  });
}
