// This is used to calculate how much of an item is distributed to each location
// and shown to the user when they're setting the distributed amounts.
export default function CreateLocationAmounts(
  orderLog,
  orderIndex,
  userInfo,
  foodItem,
  distributedAmount,
  setLocationAmounts,
  setLocationsParticipating,
) {
  // The location amounts that have distributed food.
  const locationAmountsTemp = {};
  // An array of all the participating organisations in this order.
  const locationsParticipatingTemp = [];

  // Check to make sure there are communityOrders.
  if (Object.keys(orderLog[orderIndex].communityOrders).length > 0) {
    // Add the current user's organisation.
    locationsParticipatingTemp.push(userInfo.organisationName);
    // set the distributedamount to 0.
    locationAmountsTemp[userInfo.organisationName] = 0;
    // Check  if the distributedFoodItems exist with this order.
    if (orderLog[orderIndex].distributedFoodItems) {
      // If they do exist check for this organisation's distributedamount.
      if (
        orderLog[orderIndex].distributedFoodItems[userInfo.organisationName]
      ) {
        // Check to see if the current foodItem exists.
        const index = orderLog[orderIndex].distributedFoodItems[
          userInfo.organisationName
        ].findIndex((distributedFoodItem) => {
          return (
            distributedFoodItem.item === foodItem.item &&
            distributedFoodItem.farmName === foodItem.farmName &&
            distributedFoodItem.individualDescription ===
              foodItem.individualDescription &&
            !!distributedFoodItem.limit === !!foodItem.limit
          );
        });
        // If the index exists then add that amount to the locationAmountsTemp
        if (index !== -1) {
          locationAmountsTemp[userInfo.organisationName] =
            orderLog[orderIndex].distributedFoodItems[
              userInfo.organisationName
            ][index].distributed;
        }
      }
    }
  }

  // If there is an importedOrder.
  if (Object.keys(orderLog[orderIndex].importedOrder).length > 0) {
    // Cycle through the importedOrder locations.
    Object.keys(orderLog[orderIndex].importedOrder).forEach((location) => {
      // Add the location to the participating locations.
      locationsParticipatingTemp.push(location);
      // Initialise their distributed amount to 0.
      locationAmountsTemp[location] = 0;
      // Check  if the distributedFoodItems exist with this order.
      if (orderLog[orderIndex].distributedFoodItems) {
        // Check to see if this location exists in distributedFoodItems.
        if (orderLog[orderIndex].distributedFoodItems[location]) {
          // Find if the current foodItem was distributed at this location.
          const index = orderLog[orderIndex].distributedFoodItems[
            location
          ].findIndex((distributedFoodItem) => {
            return (
              distributedFoodItem.item === foodItem.item &&
              distributedFoodItem.farmName === foodItem.farmName &&
              distributedFoodItem.individualDescription ===
                foodItem.individualDescription &&
              !!distributedFoodItem.limit === !!foodItem.limit
            );
          });
          // If it was then set the total distributed amount to the saved amount.
          if (index !== -1) {
            locationAmountsTemp[location] =
              orderLog[orderIndex].distributedFoodItems[location][
                index
              ].distributed;
          }
        }
      }
    });
  }

  // Check to see if any of the locations have any distributed amounts.
  let noneSet = 0;

  Object.keys(locationAmountsTemp).forEach((location) => {
    noneSet += locationAmountsTemp[location];
  });
  // If none of the locations have any distributed amounts then just set the distributed
  // amount to the total distributed.
  if (noneSet === 0) {
    locationAmountsTemp[userInfo.organisationName] = distributedAmount;
  }
  // set the hooks
  setLocationAmounts({ ...locationAmountsTemp });
  setLocationsParticipating([...locationsParticipatingTemp]);
}
