// When the distributionLocation toggles which farms are to display their items
// in the pantry this is called to update the distributionLocation
import firebase from "./../components/Firebase.js";
import NextPickupDay from "./../functions/NextPickupDay.js";
import UpdateCommunityLocation from "./../functions/UpdateCommunityLocation.js";

export default function UpdateFarmsInPantry(
  userInfo,
  distributionLocationInformation,
  setDistributionLocationInformation,
  farm,
  addFarm,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  const distributionLocationsDocRef = database
    .collection("DistributionLocations")
    .doc("Vancouver");
  // stores the information that would be stored in distributionLocations -> Vancouver -> location
  const distributionLocationInformationTemp = {
    ...distributionLocationInformation,
  };

  // Creates a reference to the farm key to update in the document
  let locationToUpdate = [userInfo.organisationName, "farmsPickupDates", farm];
  // create a dot reference
  locationToUpdate = locationToUpdate.join(".");

  // This is for whether the farm is showing the pantry and the catalogue at the same
  // time.
  let locationToUpdateForCatalogueAndPantry = [
    userInfo.organisationName,
    "farmsCatalogueAndPantry",
    farm,
  ];
  // create a dot reference
  locationToUpdateForCatalogueAndPantry =
    locationToUpdateForCatalogueAndPantry.join(".");
  // set the date to not set as default
  let setDate = "Not Set";

  // if the farm is being added to the pantry then find out the next pickup day
  // of the distribution location and set the farm's date to that.  Otherwise
  // set it to "Not Set"
  if (addFarm) {
    setDate = NextPickupDay(userInfo.pickupDay);
    distributionLocationInformationTemp.farmsPickupDates[farm] = setDate;
  } else {
    distributionLocationInformationTemp.farmsPickupDates[farm] = setDate;
  }

  // There is no way that by toggling the use leftovers toggle will ever turn this
  // positive.  If it is being turned on then it was off which means farmsCatalogueAndPantry
  // had to be false and if it's being turned off then farmsCatalogueAndPantry has to be
  // turned off too.
  distributionLocationInformationTemp.farmsCatalogueAndPantry[farm] = false;

  // Update the document
  batch.update(distributionLocationsDocRef, { [locationToUpdate]: setDate });
  // Update the document.  The only way to turn on farmsCatalogueAndPantry is to
  // use its specific toggle so there is no way to change this to true from this function.
  batch.update(distributionLocationsDocRef, {
    [locationToUpdateForCatalogueAndPantry]: false,
  });
  // Updates the community location in CommunityLocations collection in the database.
  UpdateCommunityLocation(
    distributionLocationInformationTemp.userId,
    distributionLocationInformationTemp,
  );

  batch.commit();
  setDistributionLocationInformation({
    ...distributionLocationInformationTemp,
  });
}
