// This dialog appears when the admin user is adding another volunteer position.
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import AddVolunteerPosition from "../../Functions/VolunteerSchedule/AddVolunteerPosition.js";
import VolunteerShiftCreationDialog from "./VolunteerShiftCreationDialog.jsx";

// This is the dialog box that opens when a user clicks the add button
function AddVolunteerJobBox({
  open,
  onClose,
  volunteerJobs,
  setVolunteerJobs,
}) {
  // The title of the position.
  const [title, setTitle] = useState("");
  // How frequently we intake new volunteers for this position.
  const [intake, setIntake] = useState("");
  // When we need volunteers.
  const [availability, setAvailability] = useState("");
  // How often we need voluneers ex. weekly, monthly, etc.
  const [recurrance, setRecurrance] = useState("");
  // description of the role.
  const [description, setDescription] = useState("");
  // Whether this role is active or not.
  const [status, setStatus] = useState(true);
  // An icon or image of the task.
  const [image, setImage] = useState("");
  // Where the volunteering occurs.
  const [location, setLocation] = useState("");
  // The reference link to the image used.
  const [imageRefLink, setImageRefLink] = useState("");
  // The reference title for the a tag.
  const [imageRefTitle, setImageRefTitle] = useState("");
  // The text that follows the link reference.
  const [imageRefText, setImageRefText] = useState("");
  // The credit value of doing this shift
  const [creditValue, setCreditValue] = useState("");
  // The shifts available.
  const [shiftsAvailable, setShiftsAvailable] = useState([]);

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = (cancel) => {
    onClose(false);
    // If the user cancels then reset the states.
    if (cancel === "cancel") {
      setTitle("");
      setIntake("");
      setAvailability("");
      setRecurrance("");
      setDescription("");
      setImage("");
      setLocation("");
      setImageRefLink("");
      setImageRefTitle("");
      setImageRefText("");
      setStatus(true);
      setCreditValue("");
      setShiftsAvailable([]);
    }
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();

    // Creates a dictionary of the volunteer position to be saved to the database.
    const volunteerPosition = {
      title,
      intake,
      availability,
      recurrance,
      description,
      status,
      image,
      location,
      imageRefLink,
      imageRefTitle,
      imageRefText,
      creditValue,
      shiftsAvailable,
    };
    // Save to the database the new position.
    AddVolunteerPosition(volunteerPosition, volunteerJobs, setVolunteerJobs);

    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Fill in information about the position
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <span>
            <TextField
              margin="dense"
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="location"
              label="Location"
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="creditValue"
              label="Credit Per Shift"
              name="creditValue"
              type="number"
              value={creditValue}
              onChange={(e) => setCreditValue(parseFloat(e.target.value))}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="intake"
              label="Intake"
              name="intake"
              value={intake}
              onChange={(e) => setIntake(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="availability"
              label="Availablity"
              name="availability"
              value={availability}
              onChange={(e) => setAvailability(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="recurrance"
              label="Recurrance"
              name="recurrance"
              value={recurrance}
              onChange={(e) => setRecurrance(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="description"
              label="Description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="image"
              label="Icon"
              name="image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="imageRefLink"
              label="Reference to Image Link"
              name="imageRefLink"
              value={imageRefLink}
              onChange={(e) => setImageRefLink(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="imageRefTitle"
              label="Reference to Image Title"
              name="imageRefTitle"
              value={imageRefTitle}
              onChange={(e) => setImageRefTitle(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="imageRefText"
              label="Reference to Image Text"
              name="imageRefText"
              value={imageRefText}
              onChange={(e) => setImageRefText(e.target.value)}
              fullWidth
            />
            <VolunteerShiftCreationDialog
              shiftsAvailable={shiftsAvailable}
              setShiftsAvailable={setShiftsAvailable}
            />
            {shiftsAvailable !== undefined &&
              shiftsAvailable.map((shift, index) => (
                <div key={index}>
                  <h5>
                    <u>Shift {index + 1}</u>
                  </h5>
                  <p>Day of the Week : {shift.DayOfTheWeek}</p>
                  <p>Start Time : {shift.StartTime}</p>
                  <p>End Time : {shift.EndTime}</p>
                  <p>Frequency : {shift.Frequency}</p>
                </div>
              ))}
            <FormControl>
              <FormLabel id="radio-buttons-group-status">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                value={status}
                onChange={(e) => setStatus(!status)}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </FormControl>
          </span>
          <DialogActions>
            <Button onClick={() => handleClose("cancel")} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

AddVolunteerJobBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AddVolunteerJobDialog(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>ADD VOLUNTEER POSITION</Button>
      <AddVolunteerJobBox
        open={open}
        onClose={handleClose}
        volunteerJobs={props.volunteerJobs}
        setVolunteerJobs={props.setVolunteerJobs}
      />
    </div>
  );
}
