// This is the dialog that appears on the user's screen when they try and place an order
// and there is a questionnaire that they are being asked.
import React, { forwardRef, useEffect, useState } from "react";
import firebase from "../../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import LoadingContent from "../../../../components/LoadingContent.jsx";
import DisplayQuestionIndividual from "../DisplayQuestionIndividual.jsx";
import PropTypes from "prop-types";

// The slide animation effect for the dialog
// eslint-disable-next-line no-unused-vars
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

// This is the dialog of the Questionnaire.  The openNotice is used to say that
// the user has clicked on Place Order and should be shown the dialog if there is one.
// The pickupLocation has the questionnaire lists.
export default function QuestionnaireDialog({
  openNotice,
  docKey,
  pickupLocation,
  userId,
}) {
  const database = firebase.firestore();

  // The document that the user will write their answers to.
  const distributionLocationDocRef = database
    .collection("QuestionnaireAnswers")
    .doc(pickupLocation.questionnaireList[docKey]);

  // This is used to store the answer sheet in the page.
  const [questionnaireAnswerSheet, setQuestionnaireAnswerSheet] = useState({});

  // Whether the questionnaire is loaded or not.
  const [loading, setLoading] = useState(true);

  // Whether the questionnaire dialog should be open or not.
  const [openQuestionnaire, setOpenQuestionnaire] = useState(false);

  // The answers that the user has marked down for the questions.  It's an array and will
  // have an answer for every question including blank ones.
  const [userAnswers, setUserAnswers] = useState([]);

  // A flag to say if the user has already answered this questionnaire.
  const [submitted, setSubmitted] = useState(false);

  // This is used to load the questionnaire's answer sheets depending on many questionnaires there are.  It will
  // also check if the user has already submitted their answers.
  useEffect(() => {
    // If the dialog should be open start loading the values.
    if (openNotice) {
      // Holds the data that will be loaded and needs to be placed into the hooks.
      let dataTransfer = [];
      // Loading is now starting and so it is true.
      setLoading(true);

      distributionLocationDocRef.get().then((doc) => {
        dataTransfer = doc.data();
        // Create a temp array to hold the answers for the user whether they're blank
        // or filled in with their previous answers.
        let userAnswersTemps = [];

        // Check to see if the current's user's userId is already in the answers object.
        if (Object.keys(dataTransfer.answers).includes(userId)) {
          // Set the user answers to the user's submitted answers.
          userAnswersTemps = Object.values({ ...dataTransfer.answers[userId] });
          // Set submitted to true.
          setSubmitted(true);
        }
        // If they have not submitted any answers yet.
        else {
          // cycle through all the questions to add a default answer for each question.
          dataTransfer.questions.forEach((question) => {
            // Check what type of question it is.
            if (question.type === "checkbox") {
              // Checkboxes are an array as there can be multiple answers.
              const checkboxTemp = [];
              // Set each checkbox to false
              question.options.forEach(() => {
                checkboxTemp.push(false);
              });
              // Push the answers.
              userAnswersTemps.push([...checkboxTemp]);
            }
            //  Short answer will be a string and so will multiple choice as it's
            // just the value of the answer.
            else {
              // Push an empty string to the answers.
              userAnswersTemps.push("");
            }
          });
        }

        // Set all the hooks.
        setUserAnswers([...userAnswersTemps]);
        setQuestionnaireAnswerSheet({ ...dataTransfer });
        setOpenQuestionnaire(true);
        setLoading(false);
      });
    }
  }, [openNotice]);

  //  Save the answers to the database.
  const handleSave = () => {
    // Set the location in the dictionary for the user's answers.
    const locationToUpdate = ["answers", userId].join(".");
    // Sets the Users update
    const batch = database.batch();
    // commits and updates the database.
    batch.update(distributionLocationDocRef, {
      [locationToUpdate]: { ...userAnswers },
    });

    batch.commit();

    setOpenQuestionnaire(false);
    setSubmitted(true);
  };

  // If the loading is still happening.
  if (loading) {
    return (
      <Dialog
        // This is to make sure this dialog opens in front of other page elements
        style={{ zIndex: 100000 }}
        // If the user's has clicked on place order.
        open={openNotice}
        keepMounted
        onClose={() => setOpenQuestionnaire(false)}
        aria-labelledby="questionaire-dialog"
        aria-describedby="questionaire-dialog-description"
      >
        <LoadingContent />

        <DialogActions>
          <Button onClick={() => setLoading(false)} color="secondary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <div>
        <Dialog
          // If the user's has clicked on the place order to open the dialog and the questionnaire is
          // also open (ie. exists and not been closed by the user.).
          open={openQuestionnaire}
          onClose={() => setOpenQuestionnaire(false)}
          aria-labelledby="questionaire-dialog"
          aria-describedby="questionaire-dialog-description"
        >
          <DialogTitle
            id="questionnaire-dialog-title"
            style={{ paddingTop: 6, paddingBottom: 0 }}
          >
            {questionnaireAnswerSheet.title}
            {submitted && (
              <div style={{ color: "red", fontSize: "16px" }}>
                Your previous answers were recorded and are shown below. You can
                update and resubmit, or alternatively, click SKIP.
              </div>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="questionnaire-dialog-description">
              {questionnaireAnswerSheet.description}
            </DialogContentText>
            <div>
              {questionnaireAnswerSheet.questions.map(
                (question, indexOfQuestion) => (
                  <DisplayQuestionIndividual
                    key={indexOfQuestion}
                    question={question}
                    indexOfQuestion={indexOfQuestion}
                    userAnswers={userAnswers}
                    setUserAnswers={setUserAnswers}
                  />
                ),
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenQuestionnaire(false)}
              color="secondary"
            >
              SKIP
            </Button>
            {submitted ? (
              <Button onClick={handleSave} color="primary">
                UPDATE
              </Button>
            ) : (
              <Button onClick={handleSave} color="primary">
                SUBMIT
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

QuestionnaireDialog.propTypes = {
  openNotice: PropTypes.bool,
  docKey: PropTypes.number,
  pickupLocation: PropTypes.object,
  userId: PropTypes.string,
};
