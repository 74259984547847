// This is to show the community orders when it is coming from the Basket page.
// It is different than the other CommunityOrdersDialog.jsx because
// it doesn't include the useContext call of orderLog and updateOrderLog.
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Chip from "@material-ui/core/Chip";
import { faTractor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "../../../../styles/MyAccount.css";
import ColourButton from "../../../../components/userInputs/ColourButton.js";
import { AuthContext } from "../../../../components/authentication/Auth.js";
import UserReceiptBasket from "../../Components/UserReceiptBasket.jsx";
import CreateVisibleCommunityOrders from "../../../../components/dialogs/functions/CreateVisibleCommunityOrders.js";
import CommunityLocationStatus from "../../../../components/dialogs/Components/CommunityLocationStatus.jsx";
import OrderDownloadSelectionDialog from "../../../../components/dialogs/Components/DownloadOrders/OrderDownloadSelectionDialog.jsx";

// This is the dialog box that opens when a user clicks on View Customer Receipts
function CommunityOrdersBox(props) {
  const { onClose, open, modifiedUserPickups, setModifiedUserPickups } = props;

  // If the use is editing these community orders.
  const [editing, setEditing] = useState(false);

  // An array of the names of community hosts that have been removed from this distribution
  // location.
  const [removedCommunityHosts, setRemovedCommunityHosts] = useState([]);

  // These are what are currently being displayed this is either the simplified
  // orders where user's orders are combined into one or the full community orders
  // with each order separated
  const [commmunityOrdersDisplayed, setCommunityOrdersDisplayed] = useState({});

  // This hold the string that is used to print the orders in a doc
  const [downloadString, setDownloadString] = useState();

  // This hold the string that is used to print the orders in a doc in grid format.
  const [downloadGridString, setDownloadGridString] = useState();

  const { userInfo } = useContext(AuthContext);

  // This is simply used as a variable to hold the value of the return of ModifyOrders
  // that is then immediately inserted into commmunityOrdersDisplayed if the user
  // is just viewing the orders and not editing
  let communityOrders = {};

  const handleClose = () => {
    onClose(false);
  };

  // Resets the community hosts to include all the removed community hosts.
  const resetCommunityHosts = () => {
    // Sets the distribution location
    const distributionLocation = props.location;
    // Sets a temporary dictionary to modify to update the hook later.  Modified
    // pickups are all the orders that were either cancelled or had their location
    // changed to pick up from the distribution locations. {cancelled : {}, relocated : {}}
    const modifiedPickupTemp = { ...modifiedUserPickups };
    // Cycle through all the modified orders. First if they are relocated or cancelled.
    Object.keys(modifiedUserPickups).forEach((modification) => {
      // Cycle through the userOrders.
      Object.keys(modifiedUserPickups[modification]).forEach((userOrder) => {
        // Check to see if this user's order's community host has been removed.
        if (
          removedCommunityHosts.includes(
            modifiedUserPickups[modification][userOrder].communityPickup
              .locationName,
          )
        ) {
          // Delete this order from the modifiedPickupTemp which adds the order back
          // to the orders.
          delete modifiedPickupTemp[modification][userOrder];
        }
      });
    });
    // Update the hooks
    setModifiedUserPickups({ ...modifiedPickupTemp });
    setRemovedCommunityHosts([]);

    // Checks if the orders were for an imported distribution location.  If so then
    // reload the imported order.
    if (props.imported) {
      props.handleImportOrder(
        distributionLocation,
        props.importedOrder[distributionLocation].locationDetails,
        props.importedOrder[distributionLocation].date,
      );
    }
    // Otherwise reset the current distribution location's community orders.
    else {
      props.setCommunityListReset(!props.communityListReset);
    }
  };

  // This creates the displayed community orders and auto sets them to be the simplified
  // orders
  useEffect(() => {
    // Calls the CreateVisibleCommunityOrders which takes the community orders placed for
    // this distribution location and combines orders that the same user might have
    // placed as well as creates a dictionary for both the foodlist and the farmerList
    // so that they're easier to work with to create the downloadable file and popup.
    communityOrders = CreateVisibleCommunityOrders(
      { ...props.communityOrders },
      setDownloadString,
      setDownloadGridString,
      editing,
      removedCommunityHosts,
      props.selectedDate,
    );

    // This is necessary as when a user makes a change to the community orders
    // then the useEffect triggers and so we want to display the right community
    // orders if the user is still editing.
    setCommunityOrdersDisplayed(communityOrders);

    // setEditing(false)
  }, [props.communityOrders, removedCommunityHosts]);

  // When the user clicks on the edit button to start editing the community orders
  // or to toggle to not editing
  const handleEditing = () => {
    // Creates the community orders needed depending on if the user is editing or not.
    communityOrders = CreateVisibleCommunityOrders(
      { ...props.communityOrders },
      setDownloadString,
      setDownloadGridString,
      !editing,
      removedCommunityHosts,
      props.selectedDate,
    );
    // updated the community order displayed hook.
    setCommunityOrdersDisplayed(communityOrders);
    // Toggles the editing boolean.
    setEditing(!editing);
  };

  // This is called to remove a specific community host.  By taking in the distribution
  // location and community location.
  const handleCommunityHostRemove = (
    distributionLocation,
    communityLocation,
  ) => {
    // Creates a temporary array to modify the hook.
    const removedCommunityHostsTemp = [...removedCommunityHosts];

    // Updates the community orders.
    props.handleCommunityOrderChange(
      props.imported,
      null,
      null,
      null,
      null,
      null,
      distributionLocation,
      communityLocation,
    );
    // adds the community community location to the removed community host array.
    removedCommunityHostsTemp.push(communityLocation);
    // Update the hook
    setRemovedCommunityHosts([...removedCommunityHostsTemp]);
  };

  // This is the date that order is picked up from customers
  let distributionDate = props.selectedDate;

  // If it is imported than the date is the importedDistributionDate
  if (props.imported) {
    // If the order is undefined then we know this is the basket
    if (props.order === null) {
      distributionDate = props.importedSelectedDate;
    }
    // Otherwise it's from the homepage
    else {
      distributionDate = props.order.importedDistributionDate;
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Community Orders for pickup on {distributionDate}{" "}
        <Button onClick={() => handleEditing()}>Edit Orders</Button>
      </DialogTitle>
      <DialogContent>
        <>
          {removedCommunityHosts.length !== 0 && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <h3 className="Header-3"> Removed Community Hosts </h3>
              <Button
                onClick={() => {
                  resetCommunityHosts();
                }}
              >
                RESET HOSTS
              </Button>
              {removedCommunityHosts.map((communityHost, i) => (
                <Chip
                  key={i}
                  color="primary"
                  style={{ margin: "5px" }}
                  size="small"
                  label={communityHost}
                  icon={
                    <FontAwesomeIcon
                      className="w3-hover-opacity"
                      icon={faTractor}
                    />
                  }
                />
              ))}
            </span>
          )}

          {Object.keys(commmunityOrdersDisplayed).map((location, i) => (
            <span key={i}>
              <h4>
                <u>{location}</u>
                {location !== "Pickup On Site" && (
                  <>
                    {editing && (
                      <Button
                        onClick={() => {
                          handleCommunityHostRemove(props.location, location);
                        }}
                      >
                        REMOVE
                      </Button>
                    )}
                    <CommunityLocationStatus
                      communityOrders={commmunityOrdersDisplayed[location]}
                      userInfo={userInfo}
                    />
                  </>
                )}
              </h4>
              {Object.keys(commmunityOrdersDisplayed[location]).length !== 0 ? (
                <span>
                  {Object.keys(commmunityOrdersDisplayed[location]).map(
                    (user, idx) => (
                      <UserReceiptBasket
                        idx={idx}
                        key={idx}
                        userOrder={commmunityOrdersDisplayed[location][user]}
                        handleCommunityOrderChange={
                          props.handleCommunityOrderChange
                        }
                        user={user}
                        imported={props.imported}
                        importedOrder={props.importedOrder}
                        location={props.location}
                        selectedDate={props.selectedDate}
                        userInfo={userInfo}
                        editing={editing}
                        foodItemsToSelect={props.foodItemsToSelect}
                        setFoodItemsToSelect={props.setFoodItemsToSelect}
                        displayedFoodItemsToSelect={
                          props.displayedFoodItemsToSelect
                        }
                        setDisplayedFoodItemsToSelect={
                          props.setDisplayedFoodItemsToSelect
                        }
                      />
                    ),
                  )}
                </span>
              ) : (
                <p>No Community Orders with this Order.</p>
              )}
            </span>
          ))}
        </>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
        {Object.keys(commmunityOrdersDisplayed).length !== 0 && (
          <OrderDownloadSelectionDialog
            selectedDate={props.selectedDate}
            distributionLocation={props.location}
            downloadString={downloadString}
            downloadGridString={downloadGridString}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

CommunityOrdersBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function CommunityOrdersDialogBasket(props) {
  const [open, setOpen] = useState(false);

  const [foodItemsToSelect, setFoodItemsToSelect] = useState([]);

  const [displayedFoodItemsToSelect, setDisplayedFoodItemsToSelect] = useState(
    [],
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonTitle = `View ${props.location} Receipts`;
  let colour = "#17c917";
  let hoverColour = "#1c8c1c";
  if (props.imported) {
    colour = "#f738a2";
    hoverColour = "#942161";
  }

  return (
    <div>
      <div className="Order-Again-Button">
        <div className="Order-Again-Button">
          <ColourButton
            hoverColour={hoverColour}
            colour={colour}
            title={buttonTitle}
            onClick={handleClickOpen}
          />
        </div>
      </div>

      <CommunityOrdersBox
        open={open}
        onClose={handleClose}
        communityOrders={{ ...props.communityOrders }}
        location={props.location}
        handleCommunityOrderChange={props.handleCommunityOrderChange}
        selectedDate={props.selectedDate}
        imported={props.imported}
        importedOrder={props.importedOrder}
        order={props.order}
        importedSelectedDate={props.importedSelectedDate}
        communityListReset={props.communityListReset}
        setCommunityListReset={props.setCommunityListReset}
        handleImportOrder={props.handleImportOrder}
        modifiedUserPickups={props.modifiedUserPickups}
        setModifiedUserPickups={props.setModifiedUserPickups}
        foodItemsToSelect={foodItemsToSelect}
        setFoodItemsToSelect={setFoodItemsToSelect}
        displayedFoodItemsToSelect={displayedFoodItemsToSelect}
        setDisplayedFoodItemsToSelect={setDisplayedFoodItemsToSelect}
      />
    </div>
  );
}
