// This function will check the user's order total and make sure that their subsidy
// isn't  more than what they owe.  If it is then it will return their updated subsidy
// amount which will equal the order total.
export default function CheckSubsidyAmount(customerOrder, deliveryFee) {
  // The customer's current subsidy amount that may be updated.
  let updatedSubsidy = customerOrder.donationSubsidy;
  // The total of this order that we will use to check if the user is using a
  // subsidy it is not more than what they’re using
  let orderTotal = 0;
  // Cycle through the user's order and add up the total price of the order
  customerOrder.foodList.forEach((foodItem) => {
    // Add the cost of the food.
    orderTotal +=
      foodItem.quantity *
      ((foodItem.price / foodItem.distributionQuantity) *
        foodItem.individualQuantity);
    // If there are packaging fees add those as well.
    if (foodItem.packageFee > 0) {
      orderTotal += foodItem.packageFee * foodItem.quantity;
    }
  });

  // Add the customer's volunteer cash portion and their delivery fee.
  orderTotal += parseFloat(customerOrder.cashPortion) + parseFloat(deliveryFee);

  // Check if the subsidy is greater than the orderTotal and if it is the set
  // the subsidy to the orderTotal as the subsidy can't be more than it.
  if (customerOrder.donationSubsidy > orderTotal) {
    updatedSubsidy = orderTotal;
  }

  // Return the subsidy that may or may not have changed.
  return updatedSubsidy;
}
