// This will take a distribution locations orders and will sepearate all the orders
// into a dictionary with the keys being either "Pickup on Site" or the name of the
// community host program.   It will also check to see if any community host programs
// were removed from the order and makes sure to either put guaraunteed orders into
// the "Pickup on Site" key or removes them.
export default function SeparateByPickupLocation(
  communityOrders,
  removedCommunityHosts,
) {
  // Create the dictionary that will hold the orders
  let ordersByLocationDict = {};
  // There will always be the "Pickup On Site" key.  Even if no one ordered through them.
  ordersByLocationDict = { "Pickup On Site": {} };

  // Cycle through all the commmunityOrders
  Object.keys(communityOrders).forEach((user) => {
    // If the user has a communityPickup then create a key with an empty array
    if (communityOrders[user].communityPickup === undefined) {
      communityOrders[user].communityPickup = [];
    }
    // If the user's communityPickup is zero because we just set it to 0 then
    // we know this is an order to be picked up on site.
    if (communityOrders[user].communityPickup.length === 0) {
      ordersByLocationDict["Pickup On Site"][user] = communityOrders[user];
    }
    // Else we know that it is a community host and we want to make sure that it
    // isn't a community host that has been removed.
    else if (
      !removedCommunityHosts.includes(
        communityOrders[user].communityPickup.locationName,
      )
    ) {
      // Let the communityPickup be the info about the communityPickup
      const communityPickup =
        communityOrders[user].communityPickup.locationName;

      // If this is the first order for this location then create the key and set the
      // value to an empty dictionary.
      if (ordersByLocationDict[communityPickup] === undefined) {
        ordersByLocationDict[communityPickup] = {};
      }
      // Adds this order to the location's dictionary.
      ordersByLocationDict[communityPickup][user] = communityOrders[user];
    }
  });

  return ordersByLocationDict;
}
