// This Page is shown after an individual user places their order.  It confirms their
// and gives them their next steps.
import React from "react";
import "../App.css";
import { useLocation } from "react-router-dom";
import Menu from "../components/Menu.js";
import "w3-css/w3.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/OrderConfirmation.css";
import Footer from "../components/Footer.jsx";

// Opens the social media pages in a new tab.
function handleIconClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

export default function OrderConfirmation() {
  // Pulls the data that were sent in from the Redirect
  const location = useLocation();
  // Assigns the userInfo data
  const userInfo = location.state.userInfo;

  // This is a message to be included if the distribution location using passwords
  // and answers for their etransfers.
  let etransferMessage =
    " with the question: farmtoplate and answer: marketplace.";

  let distributionLocation = userInfo.pickupLocation;
  let pickupTime = distributionLocation.pickupTime;

  if (distributionLocation.pickupLocation !== undefined) {
    distributionLocation = distributionLocation.pickupLocation;
    pickupTime = userInfo.pickupLocation.availableTimes;
  }
  // Checks to see if the distribution location uses autodeposit and if so then
  // update the message to just be a period.
  if (distributionLocation.etransferType === "Auto-Deposit") {
    etransferMessage = ".";
  }

  return (
    <div style={{ background: "#F4F9E0" }}>
      <Menu />
      <div className="Confirmation-Page">
        <div className="Confirmation-Page-Items">
          <img
            referrerPolicy="no-referrer"
            style={{
              width: "50px",
              height: "50px",
              margin: "5px",
              objectFit: "cover",
            }}
            alt="Shopping Bag"
            src="https://lh3.googleusercontent.com/pw/ACtC-3ewYeVm0DRetEyHFAXymE06ian3azH29836GynVZ28iGSxzPi3C0GUaXvwECgeVE_ewWmHcctFXR-gFnRCt8AfClhJI5gqxwwnyarynGmitKU8EFnSPc9FSlAjAkptd31z5FyFvJBOTkeKDUKYA3u0j=s512-no?authuser=2"
          />
          <span className="Thank-You">Thank you!</span>
          <span className="Confirmation-Email">
            A confirmation email has been sent to {userInfo.email}
            <img
              referrerPolicy="no-referrer"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
                objectFit: "cover",
              }}
              alt="Email Sent"
              src="https://lh3.googleusercontent.com/pw/ACtC-3fPiTOaN8ax0RMsV40b0bpPtimZDrKRm7zLI-FOG9JM9IRicKovYEaIHrEmPv1cBXJv_VT5yXmqq9kRcbyLERAz_IBQgOWEEnaKII_EdSt0fHmHUgWGoeqXX8-W4cA1674getoiBErjOGUXHeRskwRh=s512-no?authuser=2"
            />
          </span>

          <span className="Next-Steps-Text">
            <u className="Next-Steps-Title">Next Steps:</u> <br />
            {userInfo.pickupLocation.pickupLocation !== undefined ? (
              <>1. Check on delivery day for your drop off email.</>
            ) : (
              <>
                1. Keep an eye out for any emails that we send in case there are
                changes to your order.
              </>
            )}
            <br />
            2. Bring your own bag to pick up your food.
            <img
              referrerPolicy="no-referrer"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
                objectFit: "cover",
              }}
              alt="Reusable Bag"
              src="https://lh3.googleusercontent.com/pw/ACtC-3c-4NvfftFMdjQG2Vqbbl0CQKEGwDFnwiwY5dTMANmeS4Fpoffbgqxd8EfDKcVbRwzB-_0JE-oddS1AyyzfkMP-GLlVJNpM7qeLa7cBOq-DUUBiq91hv9dxmqXvNN_zZr-UOH3HMPbjOLoMPbp8Brnn=s512-no?authuser=2"
            />
            <br />
            3. Pick up food from {userInfo.pickupLocation.address} on{" "}
            {userInfo.pickupLocation.pickupDay} between {pickupTime}
            <img
              referrerPolicy="no-referrer"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
                objectFit: "cover",
              }}
              alt="Pickup Grocery"
              src="https://lh3.googleusercontent.com/pw/ACtC-3fH4N3Zs8lmQEeZPFzq2yA6W14Mpoq_g9ce4ovoyWP7e7xL9gjRFSGl9pX2-DCXL33UVotTGCpK-ueUrvXoo1EuppTGnu5lASRtyyEM0Sz7Y1HgUUu3onVL6WpmoTnrEeP29NawPrj9F8m7x7HYGjca=s512-no?authuser=2"
            />
            <br />
            {userInfo.pickupLocation.pickupLocation !== undefined ? (
              <>
                4. Payment is by e-transfer sent to{" "}
                {distributionLocation.etransferEmail}
                {etransferMessage}
              </>
            ) : (
              <>
                4. Payment by cash on pickup or send an e-transfer to{" "}
                {distributionLocation.etransferEmail}
                {etransferMessage}
              </>
            )}
            <img
              referrerPolicy="no-referrer"
              style={{
                width: "50px",
                height: "50px",
                margin: "5px",
                objectFit: "cover",
              }}
              alt="Payment"
              src="https://lh3.googleusercontent.com/pw/ACtC-3eQPWokDeDNVXJs_q4cr7SFGIBQK7gTwIQLR7tb6DPoVgRHbIL4zc5gmlchu5hP4cgOxXkkl8ZbM5LS2sdSWAL3xTS98mWcNQ0-KOqXhBddVTGsJyUUn6Vk5sjEOjJdorUhnIwUyAENKW77jJtfvR9g=s512-no?authuser=2"
            />
          </span>

          <span className="Social-Media">
            <span> Stay up to date and follow us on : </span>
            <FontAwesomeIcon
              className="w3-hover-opacity"
              style={{ margin: "0px 5px 0px" }}
              onClick={() =>
                handleIconClick(
                  "https://www.facebook.com/farmToPlateMarketplace",
                )
              }
              icon={faFacebookSquare}
            />
            <FontAwesomeIcon
              className="w3-hover-opacity"
              style={{ margin: "0px 5px 0px" }}
              onClick={() =>
                handleIconClick(
                  "https://www.instagram.com/farmtoplate.marketplace/",
                )
              }
              icon={faInstagram}
            />
          </span>
          <div style={{ display: "flex" }}>
            <p
              className="Community-Board-Plug"
              style={{ marginRight: "5px", marginBottom: "0px" }}
            >
              Have you visited our
            </p>
            <a
              className="Community-Board-Plug"
              style={{ marginBottom: "-17px" }}
              href="/CommunityEngagement"
            >
              {" "}
              Community Page?
            </a>
          </div>
          <p style={{ marginTop: "0px" }} className="Community-Board-Plug">
            Learn about opportunities to get involved, analyze data and share
            feedback.
          </p>
        </div>
      </div>

      <div style={{ margin: "5px" }}>
        Icons made by
        <a href="https://www.freepik.com" title="Freepik">
          {" "}
          Freepik,{" "}
        </a>
        <a
          href="https://www.flaticon.com/authors/pixel-perfect"
          title="Pixel perfect"
        >
          Pixel perfect,{" "}
        </a>
        <a
          href="https://www.flaticon.com/authors/pongsakornred"
          title="pongsakornRed"
        >
          pongsakornRed, and{" "}
        </a>
        <a
          href="https://www.flaticon.com/authors/flat-icons"
          title="Flat Icons"
        >
          Flat Icons{" "}
        </a>
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
      <Footer />
    </div>
  );
}
