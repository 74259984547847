import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import moment from "moment";
import { Icon } from "leaflet";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import Chip from "@material-ui/core/Chip";
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSeedling,
  faTractor,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import "../styles/DeliveryMap.css";
import firebase from "../components/Firebase.js";
// eslint-disable-next-line no-unused-vars
import MaterialUIPickers from "../components/userInputs/DatePicker.js";

// This describes each location in the list of locations
// eslint-disable-next-line no-unused-vars
class DeliveryLocation extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
    this.state = { farm: true };
  }

  toggleHover() {
    this.props.toggleHover(this.props.delivery);
  }

  toggleNoHover() {
    this.props.toggleNoHover();
  }

  toggleOnClick(delivery) {
    this.props.toggleOnClick(delivery);
  }

  render() {
    // This is just the locations name and no other info
    const delivery = this.props.delivery;
    // This is the location's details but does not contain the name.
    const deliveryDetails = this.props.deliveryDetails;
    // This is used to determine what the object looks like: it can be a farm,
    // restaurant, hover or no hover and selected or not.
    let hoverStyle;

    // If the location is a farm
    if (deliveryDetails.isFarm === true) {
      // If the farm is being hovered over
      if (this.props.hover && this.props.title === delivery) {
        hoverStyle = {
          backgroundColor: "rgb(176, 232, 131)",
          cursor: "pointer",
        };
      }
      // How it looks when it's not being hovered over
      else {
        hoverStyle = { backgroundColor: "#83D840" };
      }
      // Checks if the item has been clicked by the user
      if (deliveryDetails.selectedInList) {
        hoverStyle = {
          border: "5px solid rgb(255, 220, 118)",
          backgroundColor: "#83D840",
        };
      }
    }
    // Otherwise it is a restaurant
    else {
      // If the restaurant is being hovered over
      if (this.props.hover && this.props.title === delivery) {
        hoverStyle = {
          backgroundColor: "rgb(230, 145, 140)",
          cursor: "pointer",
        };
      }
      // How it looks when it's not being hovered over
      else {
        hoverStyle = { backgroundColor: "#D84940" };
      }

      // Checks if the item has been clicked by the user
      if (deliveryDetails.selectedInList) {
        hoverStyle = {
          border: "5px solid rgb(255, 220, 118)",
          backgroundColor: "#D84940",
        };
      }
    }

    return (
      <div
        className="Location-Element"
        style={hoverStyle}
        onClick={() => this.toggleOnClick(delivery)}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleNoHover}
      >
        <h2 className="Header-2"> {delivery} </h2>
        <p>
          <strong>Ingredient: </strong>
          {deliveryDetails.ingredients.join(", ")}
        </p>
        {deliveryDetails.isFarm === true ? (
          <p>
            <strong>Restaurants: </strong>
            {deliveryDetails.restaurants.join(", ")}
          </p>
        ) : (
          <p>
            <strong>Farms: </strong>
            {deliveryDetails.farms.join(", ")}
          </p>
        )}
      </div>
    );
  }
}

// This is the list of all locations Farms or Restaurants that are participating
// in this delivery day under the current parameters.  It calls DeliveryLocation
// which creates the individual items in the list.

// eslint-disable-next-line no-unused-vars
class ListElement extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
    this.state = {
      hover: false,
      click: false,
      listIndex: 0,
    };
  }

  toggleHover(index) {
    this.setState({
      hover: true,
      click: false,
      listIndex: index,
    });
  }

  toggleNoHover() {
    !this.state.click && this.setState({ hover: false });
  }

  // By Clicking a location you filter the map so that it shows only that
  // restaurant/farm and other farms/restaurants that it is directly involved in
  toggleOnClick(delivery) {
    this.setState({ click: true });
    this.props.toggleOnClick(delivery);
  }

  render() {
    // deliveries is the a dictionary of all the locaitons participating in the
    // delivery day under the current parameters
    const deliveries = this.props.deliveries;
    // The list element needs to know which location in the list is being hovered
    // over so that when the user clicks it will be able to know which location
    // was picked.
    const listIndex = this.state.listIndex;
    return (
      <div className="List-Of-Locations-Delivering">
        {Object.keys(deliveries).map((delivery, index) => (
          <DeliveryLocation
            key={delivery + index}
            delivery={delivery}
            deliveryDetails={deliveries[delivery]}
            toggleHover={this.toggleHover}
            toggleNoHover={this.toggleNoHover}
            toggleOnClick={this.toggleOnClick}
            hover={this.state.hover}
            title={listIndex}
          />
        ))}
      </div>
      // {/*When the user is hovering or has clicked on an issue than this.state.hover
      // will be true and the pop up will show.

      // */}
    );
  }
}

// The main function that loads the page
export default function DeliveryMap() {
  // The database being used
  const database = firebase.firestore();

  // These icons are what appear on the map for the restaurant and the farm
  const restaurantIcon = new Icon({
    iconUrl:
      "https://lh3.googleusercontent.com/pw/ACtC-3e_PLev5mqx2dUnS4dZ-qCMzRigr-IjKPmbJP6sITCtoWw16UroJgV6tH_PvGHK4Ff_w0dXLJChQvWB1OWp_mFMAPKAOvZ2URz5ZXn7GHV1RsQ6Qe4lzzA8L0zC-FZxT_3_0wpRMseKkjWuWlG2SeSl=w237-h244-no?authuser=2",
    iconSize: [25, 25],
  });

  const farmIcon = new Icon({
    iconUrl:
      "https://lh3.googleusercontent.com/pw/ACtC-3dTAlfi-kEzb6FDbP3kuZhvSf3UAXN6S-TDc6kvRPoEtTuWjsxZaT6fhKBk8KoOs8Il3VgaMTouJidRKSPZ0apOvCFsHJnppMID-Py0Yks5anzfW_OiVKj-lxuf7YZ-hDNCNZTjBmMXS_6MBnUstHBW=s512-no?authuser=2",
    iconSize: [25, 25],
  });

  // This is the dictionary that contains all the locations that are participating
  // in a delivery on the date and under the filter condtions.
  const [deliveriesDictionary, setDeliveriesDictionary] = useState({});

  // The date of the delivery routes to be viewed selected by the user.
  const [date, setDate] = useState(null);

  // This contains the filteredIngredient that updates everytime the user types
  // something in the text field "Filter by Ingredient"
  const [filteredIngredient, setFilteredIngredient] = useState("");
  // Once the user clicks submit this hook is updated with whatever is in filteredIngredient
  // this is used and compared against filteredIngredient to see if the user has
  // changed the filteredIngredient and not clicked submit to update.
  const [ingredientSubmitted, setIngredientSubmitted] = useState(null);

  // This boolean is used to determine if the calendar can be clicked or not.  It
  // is disabled after an ingredient is submitted and the database is being loaded.
  const [calendarDisabled, setCalendarDisabled] = useState(false);

  // This dictionary contains each location regardless of date that contains a
  // specific ingredient.  It is used to create the filtered dictionary.
  const [filteredDictionaryByIngredient, setFilteredDictionaryByIngredient] =
    useState([]);

  // This is an array that contains the dates of when an ingredient is delivered
  // It is used to determine which are valid dates to be shown in the calendar
  const [ingredientDeliveryDates, setIngredientDeliveryDates] = useState([]);

  // This is used to store the location information when the user clicks an icon
  // on the map
  const [activeIcon, setActiveIcon] = useState();

  // This is an array that contains just the names of the locations that have been
  // selected
  const [selectedList, setSelectedList] = useState([]);

  // This is used to translate the number returned by date.getDay() to match with
  // the dropOff day of the recurring orders
  const daysOfTheWeekArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    // Checks to see if the user has selected a date yet.  If not then skip over
    // everything
    if (date !== null) {
      // Set the delivery date to the format saved in the datebase for deliveredOnISO
      // field
      const deliveryDay = moment(date).format().substring(0, 10);

      const dataTransfer = [];

      // Checks to see if the user has submitted a filteredIngredient and whether
      // they have changed the text box since the last submit.  If they haven't
      // submitted a filter or they have changed the text field since their last
      // submit then load the locations of the given delivery day from the database.
      if (ingredientSubmitted === null || ingredientSubmitted === "") {
        const docRef = database
          .collection("IngredientList")
          .where("deliveredOnISO", "==", deliveryDay);
        docRef
          .get()
          .then((collection) => {
            collection.docs.forEach((doc) => {
              dataTransfer.push(doc.data());
            });
            // Uses the data from the documents to create the dictionary of all
            // the locations participating in this deliveryDay.
            createDictionary([...dataTransfer]);
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });

        // This is used ot find any recurring orders that match up with the selected
        // date
        const recurringDocRef = database
          .collection("IngredientList")
          .where("startDay", "<=", deliveryDay)
          .where("status", "==", "ongoing")
          .where("dropOffDay", "==", daysOfTheWeekArray[date.getDay()]);
        recurringDocRef
          .get()
          .then((collection) => {
            collection.docs.forEach((doc) => {
              dataTransfer.push(doc.data());
            });
            // Uses the data from the documents to create the dictionary of all
            // the locations participating in this deliveryDay.
            createDictionary([...dataTransfer]);
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }
      // If the user has filtered by ingredient then we have already loaded the
      // data and just need to push this data to a temporary array and send it to
      // createDictionary.
      else {
        filteredDictionaryByIngredient.forEach((ingredient) => {
          // Checks for a matching deliveredOnISO date else if there is a recurring
          // order that has a startday endDate and dropOff day that matches with
          // user inputted date
          if (ingredient.deliveredOnISO === deliveryDay) {
            dataTransfer.push(ingredient);
          } else if (
            ingredient.startDay <= deliveryDay &&
            ingredient.endDate >= deliveryDay &&
            daysOfTheWeekArray[date.getDay()] === ingredient.dropOffDay
          ) {
            dataTransfer.push(ingredient);
          }
        });
        createDictionary([...dataTransfer]);
      }
    }
  }, [date, database]);

  // This takes in the array of all the locations that match the current user's
  // criteria and creates a dictionary of all the locations together with their data
  function createDictionary(deliveries) {
    // This is a dictionary that stores all the restaurant data
    const restaurantDictionaryTemp = {};
    // This is a dictionary that stores all the farm data
    const farmDictionaryTemp = {};

    // Creates the array of farms
    deliveries.forEach((delivery) => {
      const farmName = delivery.supplyingFarm.farmName;
      // Checks if this farm has already been added as multiple restaurants can
      // be ordering from the same farm and without this would duplicate the farm.
      // It initializes some of the data as well.
      if (!farmDictionaryTemp[farmName]) {
        // Creates a dictionary entry for the farm which is also a dictionary.
        farmDictionaryTemp[farmName] = {};
        // initializes the ingredients that the farm is delivering
        farmDictionaryTemp[farmName].ingredients = [];
        // initializes the restaurants that the farm is delivering to
        farmDictionaryTemp[farmName].restaurants = [];
        // initializes the farms order dictionary which contains the restaurantName
        // along with the ingredient that farm ordered from this farm
        farmDictionaryTemp[farmName].restaurantOrders = {};
        farmDictionaryTemp[farmName].name = farmName;
        // This is the farm's address
        farmDictionaryTemp[farmName].location =
          delivery.supplyingFarm.farmLocation;
        farmDictionaryTemp[farmName].orderCutoffDay =
          delivery.supplyingFarm.orderCutoffDay;
        farmDictionaryTemp[farmName].deliveryDay =
          delivery.supplyingFarm.deliveryDay;
        farmDictionaryTemp[farmName].longitude =
          delivery.supplyingFarm.longitude;
        farmDictionaryTemp[farmName].latitude = delivery.supplyingFarm.latitude;
        // This is used to determine if the user has selected this location in the
        // list of locations
        farmDictionaryTemp[farmName].selectedInList = false;
        farmDictionaryTemp[farmName].isFarm = true;
      }
      // Checks to see if this farm and restaurant pair has already had an ingredient
      // delivered to it and if not then it will intialize this pair with an empty array
      if (
        !farmDictionaryTemp[farmName].restaurantOrders[delivery.restaurantName]
      ) {
        farmDictionaryTemp[farmName].restaurantOrders[delivery.restaurantName] =
          [];
      }
      // Checks to see if this farm has already delivered this ingredient to a
      // restaurant and already has had it added to their ingredients array.  If it
      // hasn't then it will be pushed into the array.
      if (
        !farmDictionaryTemp[farmName].ingredients.includes(delivery.ingredient)
      ) {
        farmDictionaryTemp[farmName].ingredients.push(delivery.ingredient);
      }
      // Checks to see if this farm has already delivered to this restaurant
      // and has already been added to their restaurant array.  If it
      // hasn't then it will be pushed into the array.
      if (
        !farmDictionaryTemp[farmName].restaurants.includes(
          delivery.restaurantName,
        )
      ) {
        farmDictionaryTemp[farmName].restaurants.push(delivery.restaurantName);
      }
      // Adds this ingredient to the restaurant ex: restaurantName = [beets, carrots]
      farmDictionaryTemp[farmName].restaurantOrders[
        delivery.restaurantName
      ].push(delivery.ingredient);
    });

    // Creates the array of restaurants
    deliveries.forEach((delivery) => {
      const restaurantName = delivery.restaurantName;
      // Checks if this restaurant has already been as restaurants can be ordering
      // multiple ingredients and needs to check if it has already been added. If
      // not then it will be initialized
      if (!restaurantDictionaryTemp[restaurantName]) {
        // Creates a dictionary entry for the restaurant which is also a dictionary.
        restaurantDictionaryTemp[restaurantName] = {};
        // initializes the ingredients that the restaurant ordered
        restaurantDictionaryTemp[restaurantName].ingredients = [];
        // initializes the farms that the restaurant ordered from
        restaurantDictionaryTemp[restaurantName].farms = [];
        // initializes a dictionary to store the farms and the ingredients that
        // it is deliverying to this restaurant ex: farmName: [Beets, Carrots]
        restaurantDictionaryTemp[restaurantName].farmsDelivering = {};
        restaurantDictionaryTemp[restaurantName].name = restaurantName;
        restaurantDictionaryTemp[restaurantName].location =
          delivery.restaurantAddress;
        restaurantDictionaryTemp[restaurantName].longitude =
          delivery.restaurantLongitude;
        restaurantDictionaryTemp[restaurantName].latitude =
          delivery.restaurantLatitude;
        // This is used to determine if the user has selected this location in the
        // list of locations
        restaurantDictionaryTemp[restaurantName].selectedInList = false;
        restaurantDictionaryTemp[restaurantName].isFarm = false;
      }
      // Checks to see if this farm and restaurant pair has already had an ingredient
      // delivered to it and if not then it will intialize this pair with an empty array
      if (
        !restaurantDictionaryTemp[restaurantName].farmsDelivering[
          delivery.supplyingFarm.farmName
        ]
      ) {
        restaurantDictionaryTemp[restaurantName].farmsDelivering[
          delivery.supplyingFarm.farmName
        ] = [];
      }

      // Pushes this ingredient to the array containing the ingredients
      restaurantDictionaryTemp[restaurantName].ingredients.push(
        delivery.ingredient,
      );

      // Checks to see if this farm has already delivered to this restaurant
      // and has already been added to their farms array.  If it
      // hasn't then it will be pushed into the array.
      if (
        !restaurantDictionaryTemp[restaurantName].farms.includes(
          delivery.supplyingFarm.farmName,
        )
      ) {
        restaurantDictionaryTemp[restaurantName].farms.push(
          delivery.supplyingFarm.farmName,
        );
      }

      // Adds this ingredient to the farm ex: farmName = [beets, carrots]
      restaurantDictionaryTemp[restaurantName].farmsDelivering[
        delivery.supplyingFarm.farmName
      ].push(delivery.ingredient);
    });

    // Combines the two dictionaires into one and sets the hook deliveriesDictionary
    setDeliveriesDictionary(
      Object.assign(
        {},
        { ...restaurantDictionaryTemp },
        { ...farmDictionaryTemp },
      ),
    );
  }

  // This handles the change of the date picker.  It resets any clicked icons and
  // removes any selected locations.  This is also triggers the useEffect as the date
  // is a dependency
  function handleChange(e) {
    setDate(e);
    setActiveIcon(null);
    setSelectedList([]);
  }

  // This is called when the user selects a location in the list.  It will filter
  // the map to only show farm/restaurants that are directly involved with the
  // selected location
  function handleFilterList(delivery, removeFilter) {
    // Copies a new temporary selectedList
    const selectedListTemp = [...selectedList];
    // Copies a new temporary deliveriesDictionary.  Even though it copies
    // the top level keys it doesn't uses the direct location of the sub objects
    // and therefore changing things below the top level changes deliveriesDictionary
    // directly.
    const deliveriesDictionaryTemp = { ...deliveriesDictionary };
    // Checks to see if the user has clicked on deleting an existing filter
    if (removeFilter) {
      // Finds the index in the selectedList of this location and returns it's index
      // then it splices this index out and changes the selectedInList field in the
      // dictionary to false
      const index = selectedListTemp.indexOf(delivery);
      if (index !== -1) {
        selectedListTemp.splice(index, 1);
        deliveriesDictionaryTemp[delivery].selectedInList = false;
      }
    }
    // If the user is adding a filter
    else {
      // Checks to see if this location has already been selected.  If it hasn't
      // then it is added to the selectedList and the selectedInList is updated.
      if (!selectedListTemp.includes(delivery)) {
        selectedListTemp.push(delivery);
        deliveriesDictionaryTemp[delivery].selectedInList = true;
      }
    }

    // reset so no icons are active to make sure a filtered out icon isn't still
    // being showed
    setActiveIcon(null);
    // update the selectedList
    setSelectedList([...selectedListTemp]);
    // update the deliveriesDictionary
    setDeliveriesDictionary({ ...deliveriesDictionaryTemp });
  }

  // This displays the ingredient that the user has filtered for.
  // This allows the user to cancel an unwanted ingredient filter
  // eslint-disable-next-line no-unused-vars
  function IngredientSearchFilter(props) {
    const ingredient = props.ingredient;
    return (
      <span style={{ margin: "20px" }}>
        <Chip
          color="secondary"
          size="small"
          onDelete={() => setIngredientSubmitted(null)}
          label={ingredient}
          icon={
            <FontAwesomeIcon
              className="w3-hover-opacity"
              icon={faSeedling}
              style={{ color: "green" }}
            />
          }
        />
      </span>
    );
  }

  // This displays the locations that the user has filtered for above the map
  // This allows the user to cancel any unwanted filters
  // eslint-disable-next-line no-unused-vars
  function SearchFilter(props) {
    const location = props.location;
    let icon = "";
    let iconStyle = {};

    if (deliveriesDictionary[location].isFarm) {
      icon = faTractor;
      iconStyle = { color: "red" };
    } else {
      icon = faUtensils;
      iconStyle = { color: "default" };
    }

    return (
      <div style={{ margin: "10px 10px 10px 0px" }}>
        <Chip
          color="primary"
          size="small"
          onDelete={() => handleFilterList(location, true)}
          label={location}
          icon={
            <FontAwesomeIcon
              className="w3-hover-opacity"
              style={iconStyle}
              icon={icon}
            />
          }
        />
      </div>
    );
  }

  // This is called everytime by the date picker to check if any dates need to be
  // disabled.
  function disableDates(date) {
    // Dates only need to be disabled if the user has entered in an ingredient
    // filter.  This checks if the user has submitted an ingredient and if they
    // they haven't the it returns false to tell the picker don't disable any dates
    if (ingredientSubmitted === "" || ingredientSubmitted === null) {
      return false;
    }

    // Turns the date into a format that can be compared to deliveredOnISO
    const deliveryDay = moment(date).format().substring(0, 10);

    // Checks to see if the date matches any delivery days in the ingredientDeliveryDates
    // array which contains all the delivery days of the selected ingredient.  If
    // it doesn't match a date it returns true and disables the date from being selected.
    return !ingredientDeliveryDates.includes(deliveryDay);
  }

  // This is used to find all the dates to display for a recurring order.  It will
  // take in the startDay of the order, the endDate which is either decades in the
  // the future or the actual endDate that a user ended a recurring order, and the
  // futureDate which is simply just 2 months in the future of today and is used
  // if the endDate is after that.
  function findRecurringDates(startDay, endDate, futureDate) {
    let endDay = null;
    const dateArray = [];

    // Chooses the date that is sooner between the futureDate and the endDate
    // and then sets endDay to that date.
    if (futureDate <= endDate) {
      endDay = futureDate;
    } else {
      endDay = endDate;
    }

    // Uses a for loop that adds each date that the recurring delivery happens on
    // Starting with the startDay, each week, until the endDay.  Pushes all these
    // dates to the dateArray which is then used to reable dates on the calendar
    for (
      let dateTemp = new Date(moment(startDay).format());
      moment(dateTemp).format().substring(0, 10) < endDay;
      dateTemp.setDate(dateTemp.getDate() + 7)
    ) {
      dateArray.push(moment(dateTemp).format().substring(0, 10));
    }
    return dateArray;
  }

  // This is called when the user submits an ingredient filter.  This is to create
  // the ingredientDeliveryDates that contains an array of each date that
  // ingredient is delivered on.
  function getDeliveryDates(e) {
    e.preventDefault();
    // resets the user selected date
    setDate(null);
    // disables the calendar button while the database is being read
    setCalendarDisabled(true);
    // Set ingredientSubmitted to the ingredient currently entered in by the user
    setIngredientSubmitted(filteredIngredient);
    // Reset the TextField to an empty string
    setFilteredIngredient("");
    // Reset the selectedInList to an empty array
    setSelectedList([]);

    // The dataTransfer of the data read from the document.  This contains everything
    // in the document
    const dataTransfer = [];
    // The datesDataTransfer is used to contain just the deliveredOnISO.  This
    // is used to be added to the ingredientDeliveryDates
    const datesDataTransfer = [];
    // This is the date that is set 2 months prior so the search can be done. Up
    // to two months old.
    let historyDate = new Date();

    historyDate.setMonth(historyDate.getMonth() - 2);

    historyDate = moment(historyDate).format().substring(0, 10);

    // This is the date that is set 2 months in the future for recurring deliveries
    let futureDate = new Date();

    futureDate.setMonth(futureDate.getMonth() + 2);

    futureDate = moment(futureDate).format().substring(0, 10);

    // This is a variable that is used to temporarily hold the data from the database
    // until all the data is stored in the correct variables.
    let ingredient = null;

    // Reads from the documents and sets the hooks needed
    const docRef = database
      .collection("IngredientList")
      .where("deliveredOnISO", ">=", historyDate)
      .where("ingredient", "==", filteredIngredient);
    docRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          dataTransfer.push(doc.data());
          datesDataTransfer.push(doc.data().deliveredOnISO);
        });
        // Reenables the calendar now that the data has been loaded.
        setCalendarDisabled(false);
        setFilteredDictionaryByIngredient([...dataTransfer]);
        setIngredientDeliveryDates([...datesDataTransfer]);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    // Searches the database for a recurring order with the matching filteredIngredient
    const recurringDocRef = database
      .collection("IngredientList")
      .where("endDate", ">=", historyDate)
      .where("ingredient", "==", filteredIngredient);
    recurringDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          // Stores the document data
          ingredient = doc.data();
          // Adds this ingredient to the list
          dataTransfer.push(ingredient);

          // Calls findRecurringDates to find all the dates from a recurring order
          // This returns an array of those dates that is then added onto the
          // datesDataTransfer array that is used to reenable dates on the calendar
          const datesTemp = findRecurringDates(
            ingredient.startDay,
            ingredient.endDate,
            futureDate,
          );
          datesDataTransfer.push(...datesTemp);
        });
        // Reenables the calendar now that the data has been loaded.
        setCalendarDisabled(false);
        setFilteredDictionaryByIngredient([...dataTransfer]);
        setIngredientDeliveryDates([...datesDataTransfer]);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }

  // This is just to store the user input in the TextField in a hook.  This is
  // called each time the user types anything into the TextField.
  function handleIngredientChange(e) {
    setFilteredIngredient(e.target.value);
  }

  // Creates the filtered Dictionary of farms and restaurants to be shown on the
  // map.
  let filteredDeliveriesDictionary = {};
  // Checks to see if the user has selected any locations.  If the user hasn't
  // selected anything then filteredDeliveriesDictionary just equals the regular
  // deliveriesDictionary
  if (selectedList.length === 0) {
    filteredDeliveriesDictionary = { ...deliveriesDictionary };
  }
  // If the user has selected a locations
  else {
    // cycles through each item that is selected.
    selectedList.forEach((location) => {
      // Adds the location to the filteredDeliveriesDictionary dictionary
      filteredDeliveriesDictionary[location] = {
        ...deliveriesDictionary[location],
      };

      // If the selected location is a farm then you have to add all the restaurants
      // that the farm is deliverying to to the filteredDeliveriesDictionary as well
      if (deliveriesDictionary[location].isFarm === true) {
        deliveriesDictionary[location].restaurants.forEach((restaurant) => {
          filteredDeliveriesDictionary[restaurant] = {
            ...deliveriesDictionary[restaurant],
          };
        });
      }
      // If the selected location is a restaurant then you have to add all the
      // farms that the restaurant ordered from to the filteredDeliveriesDictionary as well
      else {
        deliveriesDictionary[location].farms.forEach((farm) => {
          filteredDeliveriesDictionary[farm] = {
            ...deliveriesDictionary[farm],
          };
        });
      }
    });
  }

  return (
    <div>
      <div className="Page-Market">
        <div className="Page-Header">
          <h1 className="w3-xxlarge Bold-Text">
            Farm and Organization Deliveries
          </h1>
        </div>
        <span className="Filter-By-Ingredient-Form">
          <form onSubmit={getDeliveryDates} onChange={handleIngredientChange}>
            <TextField
              margin="dense"
              id="ingredientFilter"
              label="Filter by ingredient"
              name="ingredientFilter"
              value={filteredIngredient}
              fullWidth
            />
            <Button type="submit" color="primary">
              Submit
            </Button>
          </form>

          {ingredientSubmitted !== null && (
            <IngredientSearchFilter
              key={"ingredientFilter"}
              ingredient={ingredientSubmitted}
            />
          )}
        </span>

        <span style={{ width: "200px" }}>
          <MaterialUIPickers
            required
            date={date}
            disableDates={disableDates}
            calendarDisabled={calendarDisabled}
            handleDateChange={handleChange}
            label="Date of Delivery"
          />
        </span>

        <span className="Filters-By-Location">
          {selectedList.map((location, index) => (
            <SearchFilter key={"filter" + index} location={location} />
          ))}
        </span>

        {date === null ? (
          <h1>Select a date to view deliveries on that day</h1>
        ) : (
          <div>
            {/* Checks if the user has any deliveriesDictionary to show if not then
              it tells the user that there are no deliveries on the selected day. */}
            {Object.keys(deliveriesDictionary).length !== 0 ? (
              <div className="List-And-Map">
                <ListElement
                  deliveries={deliveriesDictionary}
                  toggleOnClick={handleFilterList}
                />
                <Map center={[49.246292, -123.116226]} zoom={12}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />

                  {/* Cycles through all the locations in the filtered/unfiltered
                    dictionary and adds markers depending on whether they're farms
                    or restaurants */}
                  {Object.keys(filteredDeliveriesDictionary).map(
                    (location, index) => {
                      if (deliveriesDictionary[location].isFarm === true) {
                        return (
                          <Marker
                            key={"farm" + index}
                            position={[
                              deliveriesDictionary[location].latitude,
                              deliveriesDictionary[location].longitude,
                            ]}
                            onClick={() => {
                              setActiveIcon(deliveriesDictionary[location]);
                            }}
                            icon={farmIcon}
                          />
                        );
                      } else {
                        return (
                          <Marker
                            key={"restaurant" + index}
                            position={[
                              deliveriesDictionary[location].latitude,
                              deliveriesDictionary[location].longitude,
                            ]}
                            onClick={() => {
                              setActiveIcon(deliveriesDictionary[location]);
                            }}
                            icon={restaurantIcon}
                          />
                        );
                      }
                    },
                  )}

                  {activeIcon && (
                    <Popup
                      position={[activeIcon.latitude, activeIcon.longitude]}
                      onClose={() => {
                        setActiveIcon(null);
                      }}
                    >
                      {activeIcon.isFarm === true ? (
                        <div>
                          <h2>{activeIcon.name}</h2>
                          <p>
                            <strong>Cutoff Day(s):</strong>{" "}
                            {activeIcon.orderCutoffDay.join("/")}
                          </p>
                          <p>
                            <strong>Delivery Day(s):</strong>{" "}
                            {activeIcon.deliveryDay.join("/")}
                          </p>
                          <p>{activeIcon.location}</p>
                          <u>Organisation Order(s)</u>
                          {Object.keys(activeIcon.restaurantOrders).map(
                            (restaurant, index) => (
                              <p
                                key={"restaurantOrders" + index}
                                style={{ margin: "0px" }}
                              >
                                <strong>{restaurant} :</strong>{" "}
                                {activeIcon.restaurantOrders[restaurant].join(
                                  ", ",
                                )}
                              </p>
                            ),
                          )}
                        </div>
                      ) : (
                        <div>
                          <h2>{activeIcon.name}</h2>
                          <p>{activeIcon.location}</p>
                          <u>Farm(s) Delivering</u>
                          {Object.keys(activeIcon.farmsDelivering).map(
                            (farm, index) => (
                              <p
                                key={"farmsDelivering" + index}
                                style={{ margin: "0px" }}
                              >
                                <strong>{farm} :</strong>{" "}
                                {activeIcon.farmsDelivering[farm].join(", ")}
                              </p>
                            ),
                          )}
                        </div>
                      )}
                    </Popup>
                  )}
                </Map>
              </div>
            ) : (
              <h1>There are no deliveries on this day</h1>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
