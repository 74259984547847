import React from "react";
import FarmsDialog from "../../../components/dialogs/FarmsDialog.js";
import Chip from "@material-ui/core/Chip";
import { faTractor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DeleteSourcingFarm from "../../MyAccountPage/Functions/DeleteSourcingFarm.js";
import DeleteImportedSourcingFarm from "../../MyAccountPage/Functions/DeleteImportedSourcingFarm.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import NextPickupDay from "../../../functions/NextPickupDay.js";
import UpdateFarmsInPantry from "../../../functions/UpdateFarmsInPantry.js";
import UpdateFarmsInPantryAndCatalogue from "../../../functions/UpdateFarmsInPantryAndCatalogue.js";
import ChangeMarketplaceMessageDialog from "../../MyAccountPage/Dialogs/ChangeMarketplaceMessageDialog.jsx";
import HandleMessageDisplayedSwitch from "../../MyAccountPage/Functions/HandleMessageDisplayedSwitch.js";

// This is to handle when a user toggles whether to show a farms items or the pantry
// items associated with that farm
const handleSwitchChange = (
  event,
  distributionLocationInformation,
  setDistributionLocationInformation,
  userInfo,
) => {
  const farmName = event.target.name;
  const checked = event.target.checked;
  UpdateFarmsInPantry(
    userInfo,
    distributionLocationInformation,
    setDistributionLocationInformation,
    farmName,
    checked,
  );
};

// This is to handle when a user toggles whether to show a farms items and the pantry
// items associated with that farm
const handlefarmsCatalogueAndPantrySwitch = (
  event,
  distributionLocationInformation,
  setDistributionLocationInformation,
  userInfo,
) => {
  const farmName = event.target.name;
  const checked = event.target.checked;
  UpdateFarmsInPantryAndCatalogue(
    userInfo,
    distributionLocationInformation,
    setDistributionLocationInformation,
    farmName,
    checked,
  );
};

// This function takes in a url and then opens it in another tab.  Used to open
// The farmer's websites
function handleUrlOpen(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

export default function FarmTab({
  userInfo,
  handleUserInfoChange,
  distributionLocationInformation,
  setDistributionLocationInformation,
}) {
  const farmsAddedByOtherLocation = [];

  // In case the distributionLocation doesn't already have a farmsPickupDates field
  // add it in to prevent an error.
  if (distributionLocationInformation.farmsPickupDates !== undefined) {
    Object.keys(distributionLocationInformation.farmsPickupDates).forEach(
      (farm) => {
        const index = userInfo.farms.findIndex(
          (userFarm) => userFarm.farmName === farm,
        );
        if (index === -1) {
          farmsAddedByOtherLocation.push(farm);
        }
      },
    );
  } else {
    distributionLocationInformation.farmsPickupDates = {};
  }

  // In case the distributionLocation doesn't already have a farmsCatalogueAndPantry field
  // add it in to prevent an error.
  if (distributionLocationInformation.farmsCatalogueAndPantry === undefined) {
    distributionLocationInformation.farmsCatalogueAndPantry = {};
  }

  return (
    <>
      <FarmsDialog
        userInfo={userInfo}
        handleUserInfoChange={handleUserInfoChange}
        distributionLocationInformation={distributionLocationInformation}
        setDistributionLocationInformation={setDistributionLocationInformation}
      />
      {farmsAddedByOtherLocation.length !== 0 && (
        <h3>Farms Added by Other Locations:</h3>
      )}

      {farmsAddedByOtherLocation.map((farm, i) => (
        <Chip
          color="primary"
          key={i}
          size="small"
          style={{ marginRight: "4px" }}
          label={farm}
          onDelete={() =>
            DeleteImportedSourcingFarm(
              farm,
              userInfo.organisationName,
              distributionLocationInformation,
              setDistributionLocationInformation,
            )
          }
          icon={
            <FontAwesomeIcon className="w3-hover-opacity" icon={faTractor} />
          }
        />
      ))}

      <span style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          label="Display Marketplace Message: "
          control={
            <Switch
              checked={userInfo.marketplaceMessageEnabled}
              onChange={(e) =>
                HandleMessageDisplayedSwitch(
                  userInfo.marketplaceMessageEnabled,
                  userInfo,
                  handleUserInfoChange,
                  userInfo.marketplaceMessage,
                  distributionLocationInformation,
                  setDistributionLocationInformation,
                )
              }
              name="marketplaceMessageEnabled"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
        />

        <p>{userInfo.marketplaceMessage}</p>
        <ChangeMarketplaceMessageDialog
          message={userInfo.marketplaceMessage}
          userInfo={userInfo}
          handleUserInfoChange={handleUserInfoChange}
          distributionLocationInformation={distributionLocationInformation}
          setDistributionLocationInformation={
            setDistributionLocationInformation
          }
        />
      </span>

      <h3>Sourcing Farms:</h3>
      {userInfo.farms[0] !== "No farms available please contact developer" &&
      userInfo.farms.length !== 0 ? (
        <List className="List-Container">
          {userInfo.farms.map((farm, index) => (
            <div key={index}>
              <ListItem>
                <Chip
                  color="primary"
                  key={farm + "-" + index}
                  size="small"
                  style={{ marginRight: "4px" }}
                  label={farm.farmName}
                  onDelete={() =>
                    DeleteSourcingFarm(
                      index,
                      userInfo,
                      handleUserInfoChange,
                      distributionLocationInformation,
                      setDistributionLocationInformation,
                    )
                  }
                  icon={
                    <FontAwesomeIcon
                      className="w3-hover-opacity"
                      icon={faTractor}
                    />
                  }
                />
                <FormControlLabel
                  label="Use Leftovers"
                  control={
                    <Switch
                      checked={
                        NextPickupDay(userInfo.pickupDay) ===
                        distributionLocationInformation.farmsPickupDates[
                          farm.farmName
                        ]
                      }
                      onChange={(e) =>
                        handleSwitchChange(
                          e,
                          distributionLocationInformation,
                          setDistributionLocationInformation,
                          userInfo,
                        )
                      }
                      name={farm.farmName}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
                <FormControlLabel
                  label="Use Leftovers and Farm Catalogue"
                  control={
                    <Switch
                      checked={
                        NextPickupDay(userInfo.pickupDay) ===
                          distributionLocationInformation.farmsPickupDates[
                            farm.farmName
                          ] &&
                        distributionLocationInformation.farmsCatalogueAndPantry[
                          farm.farmName
                        ]
                      }
                      onChange={(e) =>
                        handlefarmsCatalogueAndPantrySwitch(
                          e,
                          distributionLocationInformation,
                          setDistributionLocationInformation,
                          userInfo,
                        )
                      }
                      name={farm.farmName}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
              </ListItem>

              <ListItem>
                <div className="Farm-Info-List">
                  <div className="Farm-Info-Column1-List">
                    <span> Farmer: {farm.farmName} </span>
                    <span>
                      {" "}
                      Order Cut-off Day(s): {farm.orderCutoffDay.join("/")}{" "}
                    </span>
                    <span> Delivery Day(s): {farm.deliveryDay.join("/")} </span>
                    <span> Minimum Order: {farm.minimumOrder} </span>
                    <span>
                      {" "}
                      Website:{" "}
                      <Button
                        color="primary"
                        onClick={() => handleUrlOpen(farm.farmURL)}
                      >
                        Website
                      </Button>
                    </span>
                  </div>
                  <div className="Farm-Info-Column2">
                    <span> Email: {farm.farmerEmail} </span>
                    <span> Number: {farm.farmNumber} </span>
                    <span> Address: {farm.farmLocation} </span>
                  </div>
                </div>
              </ListItem>
            </div>
          ))}
        </List>
      ) : (
        <div>
          <h4>No Farms to Selected Yet</h4>
        </div>
      )}
    </>
  );
}
