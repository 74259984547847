// Display the linear bar graphs from the order stats that shows how much food
// was preordered vs. distributed vs. put into the pantry.
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import SetDistributedFood from "../../Dialogs/SetDistributedFood.jsx";
import LocationsOrderedItem from "./LocationsOrderedItem.jsx";

function Bars({
  foodItem,
  distributedAmount,
  index,
  preOrderedFoodList,
  pantryItems,
}) {
  // The amount of food that was added to the pantry.  Shown as a value that is
  // the multiplied by the description to show the amount put into the pantry.
  let pantryAddedAmount =
    (foodItem.quantity * foodItem.distributionQuantity) /
      foodItem.individualQuantity -
    distributedAmount;

  // The percentage of the total food that was added to the pantry.  This is used
  // to show the width of the bar graph.
  let pantryAddedAmountPercentage = 0;

  // This is quantity of food that was preordered. Shown as a value that is
  // the multiplied by the description to show the amount of food preordered
  let preorderedAmount = 0;

  // The percentage of preordered food of the total that was preordered.
  let preOrderedPercentage = 0;

  // The percentage of food that was distributed for free.
  let distributedAmountPercentage = 0;

  // If the index exists then we know that some amount of food was pre ordered and
  // can determine the percentage otherwise if it doesn't exist then it's just 0.
  if (index !== -1) {
    // Set the preorderedAmount to the quantity.
    preorderedAmount = preOrderedFoodList[index].quantity;

    // If this bar graph is coming from an item that was added to the pantry then
    // we know nothing was added to the pantry and the preOrderedPercentage is just
    //  the preOrdered amount over the total quantity.
    if (pantryItems) {
      pantryAddedAmount = 0;
      preOrderedPercentage = Math.round(
        100 * (preorderedAmount / foodItem.quantity),
      );
    }
    // Otherwise the item came from a foodItem that was ordered.
    else {
      // Calculate the pantry added amount which is the total ordered minus the preOrdered amount
      // minus the distributedAmount.
      pantryAddedAmount =
        (foodItem.quantity * foodItem.distributionQuantity) /
          foodItem.individualQuantity -
        preorderedAmount -
        distributedAmount;
      // Calculate the percentage of preOrdered food which is the preorderedAmount times the individualQuantity / the total ordered.
      preOrderedPercentage = Math.round(
        (100 * (preorderedAmount * foodItem.individualQuantity)) /
          (foodItem.quantity * foodItem.distributionQuantity),
      );
    }
  }

  // If the item was a pantry item.
  if (pantryItems) {
    // distributedAmountPercentage is just the distributed amount divided by the total quantity.
    distributedAmountPercentage = Math.round(
      100 * (distributedAmount / foodItem.quantity),
    );
  } else {
    // distributedAmountPercentage is the distributedAmount multiplied by the individualQuantity / by the total ordered.
    distributedAmountPercentage = Math.round(
      (100 * (distributedAmount * foodItem.individualQuantity)) /
        (foodItem.quantity * foodItem.distributionQuantity),
    );
  }

  // pantryAddedAmountPercentage is the pantry added amount divided by the total ordered divided by the individualQuantity.
  pantryAddedAmountPercentage = Math.round(
    (100 * pantryAddedAmount) /
      ((foodItem.quantity * foodItem.distributionQuantity) /
        foodItem.individualQuantity),
  );

  return (
    <div className="Multicolor-Bar">
      <div className="values" style={{ fontSize: "10px" }}>
        <div
          className="value"
          style={{ color: "#168FFF", width: preOrderedPercentage + "%" }}
        >
          <span>
            {preorderedAmount} x{foodItem.individualDescription}
          </span>
        </div>
        <div
          className="value"
          style={{ color: "#f75b86", width: distributedAmountPercentage + "%" }}
        >
          <span>
            {distributedAmount} x{foodItem.individualDescription}
          </span>
        </div>
        {!pantryItems && (
          <div
            className="value"
            style={{
              color: "rgb(114 255 113)",
              width: pantryAddedAmountPercentage + "%",
            }}
          >
            <span>
              {pantryAddedAmount} x{foodItem.individualDescription}
            </span>
          </div>
        )}
      </div>

      <div className="scale">
        <div
          className="graduation"
          style={{ color: "#168FFF", width: preOrderedPercentage + "%" }}
        >
          <span>|</span>
        </div>
        <div
          className="graduation"
          style={{ color: "#f75b86", width: distributedAmountPercentage + "%" }}
        >
          <span>|</span>
        </div>
        {!pantryItems && (
          <div
            className="graduation"
            style={{
              color: "rgb(114 255 113)",
              width: pantryAddedAmountPercentage + "%",
            }}
          >
            <span>|</span>
          </div>
        )}
      </div>

      <div className="Bar-Graph">
        <div
          className="bar"
          style={{
            backgroundColor: "#168FFF",
            width: preOrderedPercentage + "%",
          }}
        >
          &nbsp;&nbsp;
        </div>
        <div
          className="bar"
          style={{
            backgroundColor: "#f75b86",
            width: distributedAmountPercentage + "%",
          }}
        >
          &nbsp;&nbsp;
        </div>
        {!pantryItems && (
          <div
            className="bar"
            style={{
              backgroundColor: "rgb(114 255 113)",
              width: pantryAddedAmountPercentage + "%",
            }}
          >
            &nbsp;&nbsp;
          </div>
        )}
      </div>
      <div className="legends">
        <div className="legend">
          <span className="dot" style={{ color: "#168FFF" }}>
            ●
          </span>
          <span className="label">Pre-Ordered</span>
        </div>
        <div className="legend">
          <span className="dot" style={{ color: "#f75b86" }}>
            ●
          </span>
          <span className="label">Distributed For Free</span>
        </div>
        {!pantryItems && (
          <div className="legend">
            <span className="dot" style={{ color: "rgb(114 255 113)" }}>
              ●
            </span>
            <span className="label">Put in Pantry</span>
          </div>
        )}
      </div>
    </div>
  );
}

// This is used to determine the bar amount and how full it should be.
export default function DisplayLinearBar({
  foodItem,
  preOrderedFoodList,
  orderFoodList,
  userInfo,
  orderLog,
  orderIndex,
  updateOrderLog,
  pantryItems,
}) {
  // The amount of distributed at this location.  Used to set the hook later.
  let distributedAmountTemp = 0;

  // The hook of the distributed food amount.
  const [distributedAmount, setDistributedAmount] = useState(
    distributedAmountTemp,
  );

  // Check to see if the current orderfoodItem was preordered by customers.  If
  // it is then take the index to compare the preordered amount with the total ordered.
  const index = preOrderedFoodList.findIndex((preOrderedFoodItem) => {
    return (
      preOrderedFoodItem.item === foodItem.item &&
      preOrderedFoodItem.individualDescription ===
        foodItem.individualDescription &&
      preOrderedFoodItem.farmName === foodItem.farmName &&
      !!preOrderedFoodItem.limit === !!foodItem.limit
    );
  });

  // If this foodItem was taken from the pantry.
  if (pantryItems) {
    // If there are preordered food.
    if (index !== -1) {
      // distributed amount is the total quantity minus the preOrdered amount.
      distributedAmountTemp =
        foodItem.quantity - preOrderedFoodList[index].quantity;
    } else {
      // If there was no preOrdered amount then everything was distributed.
      distributedAmountTemp = foodItem.quantity;
    }
  }
  // If it was an item that was ordered.
  else {
    // Check to see if this order had any distributedFoodItems.
    if (orderLog[orderIndex].distributedFoodItems) {
      // If it did then cycle through the locations that it was distributed.
      Object.keys(orderLog[orderIndex].distributedFoodItems).forEach(
        (location) => {
          // if the current location exists.
          if (orderLog[orderIndex].distributedFoodItems[location]) {
            // See if this item existed in the distributedFoodItems.
            const index = orderLog[orderIndex].distributedFoodItems[
              location
            ].findIndex((distributedFoodItem) => {
              return (
                distributedFoodItem.item === foodItem.item &&
                distributedFoodItem.farmName === foodItem.farmName &&
                distributedFoodItem.individualDescription ===
                  foodItem.individualDescription &&
                !!distributedFoodItem.limit === !!foodItem.limit
              );
            });
            // If it did exist then we know that the amount of food distributed was the amount
            // that was distributed.
            if (index !== -1) {
              distributedAmountTemp +=
                orderLog[orderIndex].distributedFoodItems[location][index]
                  .distributed;
            }
          }
        },
      );
    }
  }

  // Use Effect that updates the state distributedAmount if it changes from being resorted.
  useEffect(() => {
    setDistributedAmount(distributedAmountTemp);
  }, [distributedAmountTemp]);

  // The maxAmount is used to say how much the slider can be moved to show the distributed
  // amount of food.
  let maxAmount =
    (foodItem.quantity * foodItem.distributionQuantity) /
    foodItem.individualQuantity;

  // If the index exists then we know that some amount of food was pre ordered and
  // can determine what the new maximum amount would be.
  if (index !== -1) {
    maxAmount =
      (foodItem.quantity * foodItem.distributionQuantity) /
        foodItem.individualQuantity -
      preOrderedFoodList[index].quantity;
  }

  // This is to allow for smaller steps when the leftover amount is less than 1.
  let step = 1;

  // This is to change the step so that if there is some leftover food that is less
  // than 1 we want to make sure we are able to move the slider for it.  We just keep multiplying
  // the max amount until it is greater than 1 and each time divide the step smaller and smaller.
  // We want to make sure the maxAmount is greater that 0,0001 as we don't want to deal with amounts
  // smaller than that.
  if (maxAmount > 0.0001 && maxAmount < 1) {
    let maxAmountMagnitude = maxAmount;
    // eslint-disable-next-line no-unused-vars
    const test = 1;
    while (maxAmountMagnitude < 1) {
      maxAmountMagnitude = maxAmountMagnitude * 10;
      step = step / 10;
    }
  }

  // Checks to see if the user has changed the amount distributed.
  const distributionAmountsChange = distributedAmount - distributedAmountTemp;

  // If this is set to true then we don't know where the item came from so we can't
  // say if there is any distributed food, pantry amount or preOrdered amounts.
  if (foodItem.missingSource) {
    return <h5>Data Not Recorded</h5>;
  } else {
    return (
      <Box marginRight={4}>
        <Bars
          foodItem={foodItem}
          distributedAmount={distributedAmount}
          index={index}
          preOrderedFoodList={preOrderedFoodList}
          pantryItems={pantryItems}
        />
        {!pantryItems && (
          <Slider
            value={distributedAmount}
            aria-label="DistributedOrPantry"
            valueLabelDisplay="auto"
            onChange={(e) => setDistributedAmount(e.target.value)}
            max={maxAmount}
            step={step}
          />
        )}

        {orderLog[orderIndex].owner === userInfo.userId && (
          <>
            <SetDistributedFood
              distributedAmount={distributedAmount}
              foodItem={foodItem}
              userInfo={userInfo}
              orderLog={orderLog}
              orderIndex={orderIndex}
              updateOrderLog={updateOrderLog}
              distributionAmountsChange={distributionAmountsChange}
            />
            <LocationsOrderedItem
              food={foodItem}
              communityOrders={orderLog[orderIndex].communityOrders}
              importedOrder={orderLog[orderIndex].importedOrder}
              organisationName={userInfo.organisationName}
            />
          </>
        )}
      </Box>
    );
  }
}
