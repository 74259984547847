// This lists all the locations that are available to print.
// The user must select at least one to print something.  This
// trigger the download of all those printouts.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

// This is the dialog box that opens when a user clicks on View Customer Receipts
function PrintOutSelectionBox({
  onClose,
  open,
  downloadString,
  handleDownload,
}) {
  const handleClose = () => {
    onClose(false);
  };
  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleToggle = (location) => {
    setSelectedLocations((prevSelected) =>
      prevSelected.includes(location)
        ? prevSelected.filter((loc) => loc !== location)
        : [...prevSelected, location],
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Please Select the Locations to Download
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {Object.keys(downloadString).map((pickupLocation) => (
            <FormControlLabel
              key={pickupLocation}
              control={
                <Checkbox
                  checked={selectedLocations.includes(pickupLocation)}
                  onChange={() => handleToggle(pickupLocation)}
                  name={pickupLocation}
                />
              }
              label={pickupLocation}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          CLOSE
        </Button>
        {selectedLocations.length > 0 && (
          <Button
            color="primary"
            onClick={() => handleDownload(downloadString, selectedLocations)}
          >
            DOWNLOAD
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

PrintOutSelectionBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedDate: PropTypes.string.isRequired,
  distributionLocation: PropTypes.string.isRequired,
  downloadString: PropTypes.string.isRequired,
  downloadGridString: PropTypes.string.isRequired,
};

export default function PrintOutSelectionDialog({
  downloadString,
  handleDownload,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          marginBottom: "10px",
          border: "1px solid #2196f3",
          borderRadius: "5px",
          color: "#2196f3",
          padding: "10px 20px",
          width: "100%",
        }}
      >
        LIST FORMAT USED FOR PUBLIC HUBS
      </Button>

      <PrintOutSelectionBox
        open={open}
        onClose={handleClose}
        downloadString={downloadString}
        handleDownload={handleDownload}
      />
    </div>
  );
}
