// This is used to show the individual question to an individual user.
// It will render either a multiple choice, short answer or checkbox question.
// It will update the userAnswers hooks. To store the user's answers.
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

// This is called to display the quesiton
const QuestionContent = ({
  question,
  indexOfQuestion,
  userAnswers,
  setUserAnswers,
}) => {
  // This is the type of question this question is, (ie. multi, checkbox, or short answer)
  const questionType = question.type;

  // handle a user changing their answer.
  const handleChange = (value, optionInd) => {
    // Store the user's current answers into a temp variable that we can update.
    const userAnswersTemp = [...userAnswers];

    // If optionInd is defined then we know it was a checkbox type question.
    if (optionInd !== undefined) {
      // Negate the stored answer as either it will be false or true.
      userAnswersTemp[indexOfQuestion][optionInd] =
        !userAnswersTemp[indexOfQuestion][optionInd];
    }
    //  It's a multiple choice or short answer value and so just set the answer.
    else {
      // Set the answer to the value.
      userAnswersTemp[indexOfQuestion] = value;
    }
    // Set the states.
    setUserAnswers([...userAnswersTemp]);
  };

  return (
    <div>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {question.questionPrompt}
          {questionType === "checkbox" && " (Select all that apply)"}
          {questionType === "multi" && userAnswers[indexOfQuestion] !== "" && (
            <Button color="secondary" onClick={() => handleChange("")}>
              CLEAR ANSWER
            </Button>
          )}
        </Typography>
        {questionType === "multi" && (
          <FormControl>
            <RadioGroup
              onChange={(e) => handleChange(e.target.value)}
              value={userAnswers[indexOfQuestion]}
            >
              {question.options.map((option, optionInd) => (
                <span key={optionInd} style={{ display: "flex" }}>
                  <FormControlLabel
                    value={option}
                    label={option}
                    control={<Radio />}
                  />
                </span>
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {questionType === "checkbox" && (
          <FormControl>
            <FormGroup>
              {question.options.map((option, optionInd) => (
                <span key={optionInd} style={{ display: "flex" }}>
                  <FormControlLabel
                    checked={userAnswers[indexOfQuestion][optionInd]}
                    value={option}
                    label={option}
                    onChange={(e) => handleChange(e.target.value, optionInd)}
                    control={<Checkbox />}
                  />
                </span>
              ))}
            </FormGroup>
          </FormControl>
        )}
        {questionType === "text" && (
          <TextField
            onChange={(e) => handleChange(e.target.value)}
            value={userAnswers[indexOfQuestion]}
            label="Short-Answer Text"
          />
        )}
      </CardContent>
    </div>
  );
};

QuestionContent.propTypes = {
  question: PropTypes.object,
  indexOfQuestion: PropTypes.number,
  userAnswers: PropTypes.array,
  setUserAnswers: PropTypes.func,
};

export default function DisplayQuestionIndividual({
  question,
  indexOfQuestion,
  userAnswers,
  setUserAnswers,
}) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <QuestionContent
          question={question}
          indexOfQuestion={indexOfQuestion}
          userAnswers={userAnswers}
          setUserAnswers={setUserAnswers}
        />
      </Card>
    </Box>
  );
}

DisplayQuestionIndividual.propTypes = {
  question: PropTypes.object,
  indexOfQuestion: PropTypes.number,
  userAnswers: PropTypes.array,
  setUserAnswers: PropTypes.func,
};
