// When the quantity of an item is changed in the Basket this is called to update
// reflect that change by updating the hook foodItems. If an item goes below zero
// this function won't be called and will be handled by handleRemove in BasketFoodRow
export default function QuantityChange(
  incrementItem,
  item,
  foodItems,
  setFoodItems,
  userInfo,
  customerContribution,
  donationSubsidy,
  setDonationSubsidy,
) {
  const arrFood = [...foodItems];
  const index = arrFood.findIndex(
    (element) =>
      item ===
      (element.item + element.description + element.farmName).replace(
        /\s+/g,
        "",
      ),
  );

  if (incrementItem) {
    arrFood[index].quantity += 1;
    setFoodItems([...arrFood]);
    // If the user is passing the threshold of guaranteedMaxQuantity then we give them a warning.
    if (
      arrFood[index].quantity === arrFood[index].guaranteedMaxQuantity &&
      arrFood[index].limit == null &&
      userInfo.userType === "individual"
    ) {
      alert(
        "You've reached the maximum guaranteed quantity of " +
          arrFood[index].guaranteedMaxQuantity +
          "x" +
          arrFood[index].individualDescription +
          ". " +
          "To ensure availability for others, any extra quantity will be fulfilled based on final orders. " +
          "Thank you for your order! You will be notified of any changes to your order.",
      );
    }
  } else {
    arrFood[index].quantity -= 1;
    setFoodItems([...arrFood]);

    // With the introduction of subsidy matching we now have a subsidy that varies
    // so we have to make sure that when a user reduces their order total and their
    // subsidy goes down that they don't access a greater subsidy.
    // This needs to be revisited as we don't know what the subsidy available is to
    // adjust their subsidy available.
    // ----------------------------------------------------------------------------
    // When an individual user decrements the amount of an item from their
    // basket there is a risk if they're using a subsidy their total could go negative.
    if (userInfo.userType === "individual") {
      // let totalCostTemp = 0;
      // // Sum up the total of their order.
      // arrFood.forEach((foodItem) => {
      //   totalCostTemp +=
      //     foodItem.quantity *
      //     (foodItem.price /
      //       (foodItem.distributionQuantity / foodItem.individualQuantity));
      // });

      // Check if the user is using a subsidy and if they are, that it's not biggger
      // than their order's total.  If it is then set the subsidy to the order total.
      // if (
      //   customerContribution === "subsidy" &&
      //   totalCostTemp < donationSubsidy
      // ) {
      //   setDonationSubsidy(totalCostTemp.toFixed(2));
      // }
      if (customerContribution === "subsidy") {
        setDonationSubsidy(0);
      }
    }
  }
}
