import React from "react";

import { Button, Popover } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WeekDateCalendar from "./WeekDateCalendar";
import dayjs, { type Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import toThursday from "./toThursday";

const FIRST_ORDER_DATE = dayjs("2020-10-08");

type Props = { date: Dayjs; onChange: (d: Dayjs) => void };

export default function WeekPicker({ date: _date, onChange }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleChange = React.useCallback(
    (d: Dayjs | null, s?: PickerSelectionState) => {
      if (d === null) return;

      if (s === "finish") {
        setAnchorEl(null);
        onChange(toThursday(d));
      }
    },
    [onChange],
  );

  const date = React.useMemo(() => toThursday(_date), [_date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDownIcon />}
      >
        {date?.format("dddd, MMM D YYYY")}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <WeekDateCalendar
          value={date}
          onChange={handleChange}
          minDate={FIRST_ORDER_DATE}
          maxDate={toThursday(dayjs())}
        />
      </Popover>
    </LocalizationProvider>
  );
}
