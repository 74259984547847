// Footer at the bottom of the page that includes Site Map and contact info.
import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// The Copyright sticker
function Copyright() {
  return (
    <div className="Footer-Font">
      <Typography variant="body2">
        {"Copyright © "}
        {"Farm-To-Plate Marketplace "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

// Opens the website's facebook and Instagram depending on the icon clicked.
function handleIconClick(value) {
  const win = window.open(value, "_blank");
  win.focus();
}

export default function Footer() {
  return (
    <footer className="w3-padding-64" style={{ background: "#9FBF59" }}>
      <Grid container direction="row">
        <Grid item md={5}>
          <div className="Footer-Links">
            <Link className="Footer-Font" to="/AboutUs">
              <h3>About Us</h3>
            </Link>
            <Link className="Footer-Font" to="/Marketplace">
              <h3>Marketplace</h3>
            </Link>
            <Link className="Footer-Font" to="/Farms">
              <h3>Farms</h3>
            </Link>
            <Link
              className="Footer-Font"
              to="/AboutUs/#SupportingOrganisations"
            >
              <h3>Supporting Organisations</h3>
            </Link>
            <Link className="Footer-Font" to="/CommunityEngagement">
              <h3>Community</h3>
            </Link>
          </div>
        </Grid>
        <Grid
          item
          md
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a
            href="#home"
            className="w3-button"
            style={{ color: "white", background: "#45aa45" }}
          >
            To the top
          </a>

          <div className="w3-xlarge w3-section">
            <FontAwesomeIcon
              className="w3-hover-opacity"
              style={{ margin: "0px 5px 0px" }}
              onClick={() =>
                handleIconClick(
                  "https://www.facebook.com/farmToPlateMarketplace",
                )
              }
              icon={faFacebookSquare}
            />
            <FontAwesomeIcon
              className="w3-hover-opacity"
              style={{ margin: "0px 5px 0px" }}
              onClick={() =>
                handleIconClick(
                  "https://www.instagram.com/farmtoplate.marketplace/",
                )
              }
              icon={faInstagram}
            />
            {/* <FontAwesomeIcon
              className="w3-hover-opacity"
              style = {{margin: "0px 5px 0px"}}
              onClick = {() => handleIconClick("faTwitter")}
              icon={faTwitter} /> */}
          </div>
        </Grid>
        <Grid
          item
          md={5}
          style={{
            textAlign: "end",
            marginRight: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <span style={{ display: "flex", justifyContent: "end" }}>
            <h4 className="Footer-Font" style={{ marginRight: "10px" }}>
              Farm-to-Plate Marketplace
            </h4>
            <img
              className="w3-image"
              style={{ width: "50px" }}
              referrerPolicy="no-referrer"
              alt="Website Logo"
              src="https://lh3.googleusercontent.com/pw/AM-JKLXj1A0JBCwtz49sMvteUQlZx1BiHQ0SbiWHgm230f6lHICeuSpJ6gI0g-O4IgT18j8FqooOuapK7vfXJeceR7pqZjO2eKLQzLfP_ax-yvA2Ui5WdR55vtbTshNIF12XbAqbGkWpLgLGA3o7vXi7zQO-=s789-no?authuser=1"
            />
          </span>
          <p className="Footer-Font" style={{ marginRight: "60px" }}>
            Vancouver, British Columbia <br />
            778-896-6754 <br />
            anthony@farmtoplatemarketplace.com
          </p>
          <Box style={{ marginRight: "60px" }}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
}
