// Called by PlaceOrder function to show the farm delivery days that can then
// be used in the email sent to the farmer and the database for the ingredients
import FindNextDay from "../../../functions/FindNextDay.js";
import firebase from "../../../components/Firebase.js";
import moment from "moment";

export default function UpdateIngredientList(
  noRepeatsFarmerInfo,
  futureDate,
  today,
  daysOfTheWeek,
  orderCutoffDay,
  foodItems,
  daysOfTheWeekArray,
  userInfo,
  batch,
) {
  const database = firebase.firestore();
  // This dictionary will store each farms next deliveryDay in a date format {farmName : date}
  const farmDeliveryDays = {};
  // This dictionary will store each farms next orderCutoffDay in a date format {farmName : date}
  const farmOrderCutoffDays = {};

  // Cycle through the farms that provide the ingredients and find out when they
  // deliver and when they harvest the food based off Cutoff and delivery dates
  // from the farmerInfo.  Store these values in the dictionaries above.
  noRepeatsFarmerInfo.forEach((farm, farmIdx) => {
    let index = 0;

    let withinTheMonthDay = futureDate;
    let nextMonthDay = futureDate;

    // Cycle through all the orderCutoffDays and pick whichever is closest.
    // You can use the index of this to find the associated delivery day.
    farm.orderCutoffDay.forEach((day, orderCutoffDayIdx) => {
      // console.log("The today and day", today, day)
      const nextDay = FindNextDay(today, daysOfTheWeek[day]);
      // Once I have the date I need to check if it is in the same month or
      // if it is the following month.
      if (nextDay.getMonth() - today.getMonth() === 0) {
        if (
          100 * (withinTheMonthDay.getMonth() - today.getMonth()) +
            withinTheMonthDay.getDate() -
            today.getDate() >
          nextDay.getDate() - today.getDate()
        ) {
          withinTheMonthDay = nextDay;
          index = orderCutoffDayIdx;
        }
      } else {
        // Checks if the numerical value of the nextDay vs. whatever nextMonthDay
        // is.  If the nextDay is a smaller number than the previous ones then it
        // will be the new next day.
        if (nextMonthDay > nextDay.getDate()) {
          nextMonthDay = nextDay;
          index = orderCutoffDayIdx;
        }
      }
    });

    // console.log("withinTheMonthDay is", withinTheMonthDay)

    // Check if there is a day in the same month if so then that is the next one.
    // Otherwise pick the earliest date in the following month
    if (withinTheMonthDay !== futureDate) {
      orderCutoffDay = withinTheMonthDay;
    } else {
      orderCutoffDay = nextMonthDay;
      // console.log("IN HERE", orderCutoffDay)
      // console.log("orderCutoffDay and nextMonthDay is", orderCutoffDay, nextMonthDay)
    }

    // Knowing the index of the orderCutoffDay you know which delivery day to use
    // console.log("orderCutoffDay and index is", orderCutoffDay, index)

    const deliveryDay = FindNextDay(
      orderCutoffDay,
      daysOfTheWeek[farm.deliveryDay[index]],
    );

    // console.log("THE DELIVERY DAY IS", deliveryDay)
    // Add these dates to the dictionaries.
    farmDeliveryDays[farm.farmName] = deliveryDay;
    farmOrderCutoffDays[farm.farmName] = orderCutoffDay;
  });

  // Cycle through all the foodItems and update the collection of IngredientList
  // with each new item.
  foodItems.forEach((food) => {
    // Determine the index of noRepeatsFarmerInfo that contains the farm providing
    // this order
    const index = noRepeatsFarmerInfo.findIndex((farm) => {
      return food.farmName === farm.farmName;
    });

    // Set the Delivery days, the orderCutoffDay and the farm for this specific
    // ingredient.
    const deliveryDay = farmDeliveryDays[food.farmName];
    const orderCutoffDay = farmOrderCutoffDays[food.farmName];
    const farm = noRepeatsFarmerInfo[index];

    // Check to see the weekly order checkbox was checked and write to the datebase
    // a recurring order.  Otherwise it's a normal order.
    if (food.checked) {
      // Set the database document
      const documentName = (
        food.item +
        "R" +
        moment(deliveryDay).format().substring(0, 10) +
        firebase.auth().currentUser.uid
      ).replace(/\s+/g, "");
      const ingredientListDocRef = database
        .collection("IngredientList")
        .doc(documentName);
      batch.set(ingredientListDocRef, {
        dropOffDay: daysOfTheWeekArray[moment(deliveryDay).day()],
        endDate: "2050-01-01",
        startDay: moment(deliveryDay).format().substring(0, 10),
        status: "ongoing",
        ingredient: food.item,
        supplyingFarm: farm,
        restaurantName: userInfo.restaurantName,
        restaurantAddress:
          userInfo.restaurantAddress +
          ", " +
          userInfo.restaurantCity +
          ", " +
          userInfo.restaurantProvTerr +
          " " +
          userInfo.restaurantPostalCode,
        restaurantLatitude: userInfo.restaurantLatitude,
        restaurantLongitude: userInfo.restaurantLongitude,
        userId: firebase.auth().currentUser.uid,
      });
    } else {
      // Set the database document
      const documentName = (
        food.item +
        moment(deliveryDay).format().substring(0, 10) +
        firebase.auth().currentUser.uid
      ).replace(/\s+/g, "");
      const ingredientListDocRef = database
        .collection("IngredientList")
        .doc(documentName);

      // This is essentially the same for distribution Locations and other organizations
      // except that the other Organisations have different names for things.
      if (userInfo.userType === "distributionLocation") {
        batch.set(ingredientListDocRef, {
          deliveredOn: deliveryDay.toDateString(),
          deliveredOnISO: moment(deliveryDay).format().substring(0, 10),
          ingredient: food.item,
          harvestedOn: orderCutoffDay.toDateString(),
          supplyingFarm: farm,
          restaurantName: userInfo.organisationName,
          restaurantAddress:
            userInfo.restaurantAddress +
            ", " +
            userInfo.restaurantCity +
            ", " +
            userInfo.restaurantProvTerr +
            " " +
            userInfo.restaurantPostalCode,
          restaurantLatitude: userInfo.restaurantLatitude,
          restaurantLongitude: userInfo.restaurantLongitude,
          userType: userInfo.userType,
          userId: firebase.auth().currentUser.uid,
        });
      } else {
        batch.set(ingredientListDocRef, {
          deliveredOn: deliveryDay.toDateString(),
          deliveredOnISO: moment(deliveryDay).format().substring(0, 10),
          ingredient: food.item,
          harvestedOn: orderCutoffDay.toDateString(),
          supplyingFarm: farm,
          restaurantName: userInfo.restaurantName,
          restaurantAddress:
            userInfo.restaurantAddress +
            ", " +
            userInfo.restaurantCity +
            ", " +
            userInfo.restaurantProvTerr +
            " " +
            userInfo.restaurantPostalCode,
          restaurantLatitude: userInfo.restaurantLatitude,
          restaurantLongitude: userInfo.restaurantLongitude,
          userId: firebase.auth().currentUser.uid,
        });
      }
    }
  });
  return farmDeliveryDays;
}
