// This removes an item from the pantry when a distribution location clicks on the remove button.
import firebase from "./../Firebase.js";

export default function RemoveItemFromPantry(
  foodItem,
  index,
  leftovers,
  setLeftovers,
  userInfo,
) {
  const database = firebase.firestore();

  const batch = database.batch();

  const leftoversTemp = [...leftovers];

  leftoversTemp.splice(index, 1);

  const pantryDocRef = database
    .collection("Pantry")
    .doc(userInfo.organisationName);
  // Create the key in the firebase map to delete this item
  batch.update(pantryDocRef, {
    [[foodItem.item, foodItem.individualDescription, foodItem.farmName].join(
      "",
    )]: firebase.firestore.FieldValue.delete(),
  });

  batch.commit();
  setLeftovers([...leftoversTemp]);
}
