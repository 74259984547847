// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react";
// eslint-disable-next-line no-unused-vars
import { Redirect } from "react-router";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import "../styles/SignIn.css";
import firebase from "../components/Firebase.js";
import { AuthContext } from "../components/authentication/Auth.js";
// eslint-disable-next-line no-unused-vars
import Menu from "../components/Menu.js";
// eslint-disable-next-line no-unused-vars
import { Button } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import Avatar from "@material-ui/core/Avatar";
// eslint-disable-next-line no-unused-vars
import CssBaseline from "@material-ui/core/CssBaseline";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
// eslint-disable-next-line no-unused-vars
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line no-unused-vars
import Box from "@material-ui/core/Box";
// eslint-disable-next-line no-unused-vars
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// eslint-disable-next-line no-unused-vars
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import Footer from "../components/Footer.jsx";

// eslint-disable-next-line no-unused-vars
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/" to="/">
        Farm-To-Plate-Marketplace
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://lh3.googleusercontent.com/pw/AM-JKLX9R3PirLtTHwOF3XeC06VSb-5mrTxYgcNfh_jQZ8qlXK8AJ1Pnqa8uVDQnOEpP7r0eYFK8qhTOZIrPd3QVDglHsa9CnnHZn99KoPQJIpREEoSuNtxseEaJb76wVKVssqtc3wfrq7PqiIlium2o_suH=w1186-h789-no?authuser=1)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "left",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const handleChange = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    if (email.length < 4) {
      // eslint-disable-next-line no-undef
      alert("Please enter an email address.");
      return;
    }
    if (password.length < 4) {
      // eslint-disable-next-line no-undef
      alert("Please enter a password.");
      return;
    }
    // Sign in with email and pass.
    // [START authwithemail]
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // [START_EXCLUDE]
        if (errorCode === "auth/wrong-password") {
          // eslint-disable-next-line no-undef
          alert("Wrong password.");
        } else {
          // eslint-disable-next-line no-undef
          alert(errorMessage);
        }
        console.log(error);
        // [END_EXCLUDE]
      });
    // [END authwithemail]
  };

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div id="home">
      <Menu />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className="Sign-In-Title">
              Sign in
            </Typography>
            <form onSubmit={handleChange} className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" to="/ForgotPassword">
                    Forgot password?
                  </Link>
                </Grid>

                <Grid item>
                  <Link href="#" variant="body2" to="/SignUp">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}

// export default SignIn
