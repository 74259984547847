// This dialog is used to confirm with a distribution location that they would like
// to place where not all their farmers minimum orders have been met.  The user can
// confirm this dialog and then override those minimum requirements.
import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

function FarmOrdersNotMetBox({ open, setOpen, handleConfirmIgnore }) {
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Confirm Minimum Order Override
      </DialogTitle>
      <DialogContent>
        You have not reached the minimum order with all of your farmers. Are you
        sure that you would still like to proceed with placing your order?
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="primary" onClick={() => handleConfirmIgnore()}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FarmOrdersNotMetBox.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function FarmOrdersNotMetDialog(props) {
  const [open, setOpen] = useState(false);

  // Close this dialog and open the Confirm order one.
  const handleConfirmIgnore = () => {
    props.handleClickOpen();
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)}>MINIMUM ORDERS NOT MET</Button>
      <FarmOrdersNotMetBox
        open={open}
        setOpen={setOpen}
        handleConfirmIgnore={handleConfirmIgnore}
      />
    </div>
  );
}
