import AggregateCustomerList from "../../../../functions/AggregateOrder.js";

// This function removes an imported location from the basket page. Whether it's blank
// or includes orders
export default function RemoveImportedOrder(
  location,
  idx,
  importedOrder,
  setImportedOrder,
  importedSelectedDate,
  setImportedSelectedDate,
  communityAndImportedOrder,
  importingLocation,
  setImportingLocation,
  setCommunityAndImportedOrder,
  setCommunityFoodItems,
  setPantryFoodItems,
  setCommunityFarmerInfo,
  setCommunityDonations,
  setCommunitySubsidies,
) {
  // Set the current imported orders list
  const importingLocationTemp = [...importingLocation];

  // Remove the current location from the list
  importingLocationTemp.splice(idx, 1);

  // If this location has been loaded and includes community orders
  if (importedOrder[location]) {
    // Creates a combined community and imported dictionary
    const communityAndImportedOrderTemp = { ...communityAndImportedOrder };
    // Sets the imported order values
    const importedOrderTemp = { ...importedOrder };
    // This is a dictionary with the locations and their imported dates
    const importedSelectedDateTemp = { ...importedSelectedDate };
    // Cycle through the communityAndImportedOrderTemp and remove all the orders
    // that came with this distribution location.
    Object.keys(importedOrderTemp[location].communityOrders).forEach(
      (userOrder) => {
        delete communityAndImportedOrderTemp[userOrder];
      },
    );
    setCommunityAndImportedOrder({ ...communityAndImportedOrderTemp });

    // Remove this location from the imported orders dictionary
    delete importedOrderTemp[location];
    setImportedOrder({ ...importedOrderTemp });

    // Remove the date from the imported orders dictionaru of dates.
    delete importedSelectedDateTemp[location];
    setImportedSelectedDate({ ...importedSelectedDateTemp });

    // Aggregate the orders and set all the values
    AggregateCustomerList(
      communityAndImportedOrderTemp,
      setCommunityFoodItems,
      setCommunityFarmerInfo,
      setCommunityDonations,
      setCommunitySubsidies,
      false,
      setPantryFoodItems,
    );
  }

  setImportingLocation([...importingLocationTemp]);
}
