// Called by CommunityDistributionLocation.jsx on the MyAccount -> Community -> Community Hosts
// for distribution location users.  It will display all the community host's information that is
// partnered with this distribution location.
import React, { useEffect, useState } from "react";
import firebase from "../../../components/Firebase.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CommunityLocationEditDialog from "../Dialogs/CommunityLocationEditDialog.jsx";
import Typography from "@material-ui/core/Typography";
import ApproveCommunityPickupDialog from "../Dialogs/ApproveCommunityPickupDialog.jsx";
import RemoveCommunityLocationDialog from "../Dialogs/RemoveCommunityLocationDialog.jsx";
import CalculateCommunityLocationStatus from "../../../functions/CalculateCommunityLocationStatus.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CommunityLocationCard({
  communityLocation,
  index,
  communityLocations,
  setCommunityLocations,
  userInfo,
}) {
  // The current status of the community location
  const [currentStatus, setCurrentStatus] = useState(["loading", "loading"]);
  useEffect(() => {
    // Calculate the current status of the location and set the current status.
    CalculateCommunityLocationStatus(
      communityLocation,
      setCurrentStatus,
      userInfo,
    );
  }, []);
  const classes = useStyles();

  // The label shown for the switch that turns on or off the community hub.
  let switchLabel = "Community hub is open";
  // If the community location is hidden then we want to change the message
  if (communityLocation.hidden) {
    switchLabel = "Community hub is unavailable.";
  }

  // The user has to decide what kind of community hub they want to create and run
  // it can be a public that requires approval, private and hidden, or public and
  // no approval is needed. Depending on the communityHubType we will display different
  // text.
  let communityHubType = "Public, Approval Required";
  // If the location is private then we have to set the text to display.
  if (communityLocation.communityHubType === "private") {
    communityHubType = "Private Hidden";
  } else if (communityLocation.communityHubType === "publicNoApproval") {
    communityHubType = "Public, No Approval Required";
  }

  // This switch will hide or unhide a community location from the community hubs
  // If it is hidden then users will default back to the distribution location.  The
  // switch acts as false is on and true is off as it's saying the location is hidden
  // true or false.  As this may be counter intuitive to the user we reversed it for the
  // switch itself and how it is displayed.
  const handleSwitch = () => {
    const database = firebase.firestore();
    const batch = database.batch();
    // Update the database.
    const documentName = (
      communityLocation.firstName +
      communityLocation.lastName +
      communityLocation.userId
    ).replace(/\s+/g, "");
    const docRef = database.collection("CommunityLocations").doc(documentName);
    // Create a temporary variable that will updated the community locations list
    // that displays the community locations.
    const communityLocationsTemp = [...communityLocations];
    // Check if the location has a hidden field and if not then create and set it
    // to true.  Otherwise just make it the opposite of whatever it is.
    if (!communityLocation.hidden) {
      communityLocationsTemp[index].hidden = true;
    } else {
      communityLocationsTemp[index].hidden =
        !communityLocationsTemp[index].hidden;
    }

    batch.update(docRef, communityLocationsTemp[index]);

    batch.commit();

    // Updates the community locations with the change.
    setCommunityLocations([...communityLocationsTemp]);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {communityLocation.locationName}
        </Typography>
        <FormControlLabel
          label={switchLabel}
          control={
            <Switch
              checked={!communityLocation.hidden}
              onChange={handleSwitch}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
        />
        {communityLocation.approved ? (
          <RemoveCommunityLocationDialog
            communityLocation={communityLocation}
            index={index}
            communityLocations={communityLocations}
            setCommunityLocations={setCommunityLocations}
          />
        ) : (
          <ApproveCommunityPickupDialog
            communityLocation={communityLocation}
            index={index}
            communityLocations={communityLocations}
            setCommunityLocations={setCommunityLocations}
          />
        )}
        <CommunityLocationEditDialog communityLocation={communityLocation} />
        <Typography variant="h5" component="h2">
          {communityLocation.firstName} {communityLocation.lastName}
        </Typography>
        <Typography>
          <strong>Community Hub Type: </strong>
          {communityHubType}
        </Typography>
        <Typography>
          <strong>Address: </strong>
          {communityLocation.address}
        </Typography>
        <Typography>
          <strong>Pickup Time: </strong>
          {communityLocation.availableTimes}
        </Typography>
        {(communityLocation.communityDeliveryFee ||
          communityLocation.communityDeliveryFee === 0) && (
          <Typography>
            <strong>Community Hub Delivery Fee: $</strong>
            {communityLocation.communityDeliveryFee}
          </Typography>
        )}
        <Typography>
          <strong>Maximum Deliveries: </strong>
          {communityLocation.maxParticipants}
        </Typography>
        <Typography>
          <strong>Additional Comments: </strong>
          {communityLocation.additionalComments}
        </Typography>
        {communityLocation.approved && (
          <>
            <Typography>
              <strong>Obscured Address: </strong>
              {communityLocation.obscuredAddress}
            </Typography>
            <Typography>
              <strong>Pickup Day: </strong>
              {communityLocation.pickupDay}
            </Typography>
            <Typography>
              <strong>Total Customers: </strong>
              {currentStatus[1]}/{communityLocation.maxParticipants}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}
