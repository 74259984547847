// This is a component used when the component is not yet built but a placeholder
// is on the website to indicate to users that it will be coming eventually.
import React from "react";

export default function UnderConstruction() {
  return (
    <div style={{ margin: "100px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          referrerPolicy="no-referrer"
          style={{ width: "50px", height: "100%" }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLV9BuNUlwI4V8PlZwdzTwTFabGprEc72I7oVWG0jzK6n_tTCsOxBZ0wjp16CbVrA1Tgt6SCnka3xeUx8aIGyhR2obe9a7jhYySR6yVS1PSoTHYiWmS8lSwsQk7T9PC5H2DwbF_5vNWOdqDYexFQ90zH=s512-no?authuser=1"
        />
        <h3>UNDER CONSTRUCTION COMING SOON!</h3>
        <img
          referrerPolicy="no-referrer"
          style={{ width: "50px", height: "100%" }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLW-D2ihoKjBfdsr0LRoTC-uOQIbNLPGnKAtUi3DBn73TUEuQOZ6_0Xily-njCa3MlpNm4vvxnE84frmCOcJZ2wxAOaVA2YbOAxSKRsa6H5AZqvRUAtCNBw5TcThH4dvLZCVOOjDtIjeEvnLmTD5Vx_L=s512-no?authuser=1"
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          referrerPolicy="no-referrer"
          style={{ width: "50px", height: "100%" }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLU_kgu9h8CV9sd2j7QeZBNHMtAoTJZQQPYPXsMK3ghLx2lDdfr_sq_0VYuMU60RamTHOky52X30N56koIGE46Nl5Mp6NxbTqn7REVPEw0svYhwmlMMxg6G_kUcnkM8NMK0-ewSyWkfV8Lvr8hxG0JgH=s512-no?authuser=1"
        />
        <img
          referrerPolicy="no-referrer"
          style={{ width: "50px", height: "100%" }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLU_kgu9h8CV9sd2j7QeZBNHMtAoTJZQQPYPXsMK3ghLx2lDdfr_sq_0VYuMU60RamTHOky52X30N56koIGE46Nl5Mp6NxbTqn7REVPEw0svYhwmlMMxg6G_kUcnkM8NMK0-ewSyWkfV8Lvr8hxG0JgH=s512-no?authuser=1"
        />
        <img
          referrerPolicy="no-referrer"
          style={{ width: "50px", height: "100%" }}
          src="https://lh3.googleusercontent.com/pw/AM-JKLU_kgu9h8CV9sd2j7QeZBNHMtAoTJZQQPYPXsMK3ghLx2lDdfr_sq_0VYuMU60RamTHOky52X30N56koIGE46Nl5Mp6NxbTqn7REVPEw0svYhwmlMMxg6G_kUcnkM8NMK0-ewSyWkfV8Lvr8hxG0JgH=s512-no?authuser=1"
        />
      </div>
      <div style={{ fontSize: "10px", textAlign: "center", margin: "50px" }}>
        <a
          href="https://www.flaticon.com/free-icons/construction-tools"
          title="construction tools icons"
        >
          Construction tools icons and{" "}
        </a>
        <a
          href="https://www.flaticon.com/free-icons/emergency"
          title="emergency icons"
        >
          Emergency icons created by Freepik
        </a>
        <a
          href="https://www.flaticon.com/free-icons/construction"
          title="construction icons"
        >
          Construction icons created by Pause08 - Flaticon
        </a>
      </div>
    </div>
  );
}
