// The section of the homepage that shows the logos of the supporting organisations
// and has a mission statment of the collective.
import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function SupportingOrganisationsSection() {
  return (
    <div>
      <div
        className="Supporting-Organisations-Div"
        id="supportingOrganisations"
      >
        <h1 className="Supporting-Organisations-Header">
          Supporting Organisations
        </h1>
        <p className="Supporting-Organisations-Text">
          Using our collective resources to support, rebuild and stabilize our
          local food system
        </p>
        <div className="Supporting-Organisations-Logos">
          <img
            width="200px"
            style={{ margin: "0px 10px" }}
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczNlIZDDcaD_aPwyBMTn2r7Isl357fQOScZzoZA9wRgJA8AJSAHkf8XsU7tkfZDtCyWDX7rKSgcHfXuYn4RPu33SfLvNitjHYUT0-kcpwsviHbqiADzWm2qYDA-BPx1Pv-3jP-Bof4uo0krC-5NZxiCH=w200-h80-s-no-gm?authuser=1"
          />
          <img
            width="300px"
            style={{ margin: "0px 40px" }}
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/pw/AP1GczOqZftMfN4OiDxCbtSuJNDYji-zK9GhH6HL7QUq2dk9cZt4tdqv8P37bYfpFIvEMLjBWqevizyu9Viq47Cp8crE8WubDdQSGjrQpM8TAruLP9LGr9jdcwxy9vDoaMhN0cPHJlb7_EgwX-VcpYTvrHMA=w492-h96-s-no-gm?authuser=1"
          />
          <img
            width="200px"
            style={{ margin: "0px 10px" }}
            referrerPolicy="no-referrer"
            src="https://lh3.googleusercontent.com/TTI8l78gMgsIVkk-2fsQ7Bx5fSyEHCFuEUvz8l35UhcBf0TPkri8UpGo44yKqUluNViIdnw1U19OS5JDBzW375C4KG9A4m838yYK0OzCC6xosXoh11e5Wgpab-B3IA8RGgg50nU4RO58RiZa6i5LpG-aPMLhrZDuTjfkUkTSHiaRREzfQTubf7JAKpLKqBw3cOFczjEuUTpvhorrJMwzOXwhy3iDgTv0XQ0A8PI_UvLHsyNqz7tp_zYpe7t_bRVrFVkfcNWh9VJTB6mo3cypQ20hCkIAb4viuheul1FHwYcvPDcns7BGECLLqNdX0IK7AkQutRMiI2qoQrztsyFR4uzeMum8sTvnXcOalMqODxDArEnZe72Rm40Ei95MfvNef5i6TRHLumEK10XOdAXwh4Ol_VhLXqKiCd9QuU84EF43nhWHmIpQ114Gvq6vFT8IKvueS1IVcJ6jhZs5jOP-OPhF56oX3F62NeTKWbojO31-A_D8xgECsB_TrGArQCCG40VczH-TGF3txgt23gpZcsc98ttzjgrhLVBhRL6X5NUcpoeCHg6hip9wkTmjzvaolzZ9Ky5MsJvOR5GxnMwLsYUdvJ3JwkvYwq2hIyFn1TvhcnwArlgrwOJt2NDxjIbmEXACb7tJ7XmOCtMGrgWSnpCLBLNOxGVXAx6J94d2UmFeIbl_hik24mWduJ6Em1W-bIRgi2wvmq3N9TAb8yPz7buJJS2bwOLlsod62hpVQrgXf13HgCfPMedpY5H6M1ug69aQ5OHnJKnySbOrRY7Al18HkIp_HbfW=w275-h139-no?authuser=1"
          />
        </div>
      </div>
      <Link
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          bottom: "20.5px",
          textDecoration: "none",
          marginBottom: "43.5px",
        }}
        to="/AboutUs/#SupportingOrganisations"
      >
        <Button
          style={{ background: "#45AA45", color: "white" }}
          size="large"
          variant="contained"
        >
          READ ABOUT THE ROLE OF EACH ORGANISATION
        </Button>
      </Link>
    </div>
  );
}
