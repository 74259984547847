// This dialog shows the payment deposits in a grid format so that it is easy
// to copy and paste the deposits into the budget tracking sheet.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
// eslint-disable-next-line no-unused-vars
import { Grid } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import UserPaymentByDateDetails from "./../Components/UserPaymentByDateDetails.jsx";

function PaymentHistoryGridBox({
  open,
  onClose,
  paymentOrderDetails,
  distributionLocation,
  moneyDepositDate,
}) {
  const handleClose = () => {
    onClose(false);
  };

  // This is the year the order was placed YYYY
  const year = moneyDepositDate.substring(0, 4);
  // This is the month the order was placed MM
  const month = moneyDepositDate.substring(4, 6);
  // This is the day the order was placed DD
  const day = moneyDepositDate.substring(6, 8);
  // The date of transaction we are looking at.
  const depositDate = month + "-" + day + "-" + year;

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Deposit Info for {distributionLocation} from {depositDate}{" "}
      </DialogTitle>
      <DialogContent>
        <table id="tableId" border="1">
          <thead>
            <tr>
              <th>Deposit Info</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: "orange" }}>
              <td>
                {parseFloat(paymentOrderDetails.etransferCurrent).toFixed(2)}
              </td>
            </tr>
            <tr style={{ backgroundColor: "orange" }}>
              <td>{parseFloat(paymentOrderDetails.cashCurrent).toFixed(2)}</td>
            </tr>
            <tr style={{ backgroundColor: "orange" }}>
              <td>{parseFloat(paymentOrderDetails.etransferOld).toFixed(2)}</td>
            </tr>
            <tr style={{ backgroundColor: "orange" }}>
              <td>{parseFloat(paymentOrderDetails.cashOld).toFixed(2)}</td>
            </tr>
            <tr style={{ backgroundColor: "orange" }}>
              <td>({parseFloat(paymentOrderDetails.refunds).toFixed(2)})</td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaymentHistoryGridBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PaymentHistoryByDateGridDialog({
  paymentOrderDetails,
  distributionLocation,
  moneyDepositDate,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <div>
          <Button color="primary" onClick={handleClickOpen}>
            GRID
          </Button>
        </div>
      </div>
      <PaymentHistoryGridBox
        open={open}
        onClose={handleClose}
        paymentOrderDetails={paymentOrderDetails}
        distributionLocation={distributionLocation}
        moneyDepositDate={moneyDepositDate}
      />
    </div>
  );
}
