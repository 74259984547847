import React from "react";
import PropTypes from "prop-types";

// This creates the tabs and their functions.
export default class Tabs extends React.Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  // This allows the url to include a specific tab so when the url is clicked
  // it shows the selected tab.
  componentDidMount() {
    const { hash } = window.location;
    if (hash === "#CommunityBoard/ImprovementList") {
      const communityBoard = document.getElementById("communityBoard");
      communityBoard.scrollIntoView();
      this.setState({ activeTab: "Improvement List" });
    } else if (hash === "#CommunityBoard/Farms") {
      const communityBoard = document.getElementById("communityBoard");
      communityBoard.scrollIntoView();
      this.setState({ activeTab: "Farms" });
    } else if (hash === "#CommunityBoard/OrderDetails") {
      const communityBoard = document.getElementById("communityBoard");
      communityBoard.scrollIntoView();
      this.setState({ activeTab: "Order Details" });
    } else if (hash === "#CommunityBoard/Company") {
      const communityBoard = document.getElementById("communityBoard");
      communityBoard.scrollIntoView();
      this.setState({ activeTab: "Company" });
    } else if (hash === "#CommunityBoard/BlogPosts") {
      const communityBoard = document.getElementById("communityBoard");
      communityBoard.scrollIntoView();
      this.setState({ activeTab: "Blog Posts" });
    }
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child, i) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label + i}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
class Tab extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let className = " tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }

    // This prevents an empty tab from rendering
    if (label === null) {
      className += " tab-list-hidden";
    }

    return (
      <li className={className} onClick={onClick}>
        {label}
      </li>
    );
  }
}
