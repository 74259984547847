// This is 2/2 dialogs used by distribution locations to send their customers an
// email when they have an item removed.   It displays all the users that were affected
// along with their email and select a greeting.  The user can't send out the emails unless
// thye've selected at least one user.
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import RemovingFoodUserAccordion from "../../Components/Accordions/RemoveFoodItems/RemovingFoodUserAccordion.jsx";

export default function UsersSelectDialog({
  openUsersSelectDialog,
  setOpenUsersSelectDialog,
  setStateOfDialog,
  foodUserRemovalList,
  setFoodUserRemovalList,
  order,
}) {
  // If the user closes this dialog then reset the state, checkList and close the dialog.
  const handleClose = () => {
    setOpenUsersSelectDialog(false);
    setStateOfDialog("selectAction");
  };

  // if the user selects to send emails to users than they will click next and
  // review their actions before confirming.
  const handleNext = (value) => {
    setOpenUsersSelectDialog(false);
    setStateOfDialog(value);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openUsersSelectDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Select Users to Remove Items From
        </DialogTitle>
        <DialogContent>
          {Object.keys(foodUserRemovalList).map((foodList, i) => (
            <div key={foodList}>
              <h4>
                {foodList === "foodList" ? (
                  <>Newly Ordered Items</>
                ) : (
                  <>Items Taken From the Pantry</>
                )}
              </h4>
              {Object.keys(foodUserRemovalList[foodList]).map(
                (foodItemIndex, i) => (
                  <RemovingFoodUserAccordion
                    key={i}
                    foodItemIndex={foodItemIndex}
                    foodUserRemovalList={foodUserRemovalList}
                    setFoodUserRemovalList={setFoodUserRemovalList}
                    foodList={foodList}
                    order={order}
                  />
                ),
              )}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          <Button onClick={() => handleNext("Review")} color="primary">
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
