// This is the dialog that will appear when a uesr selects a listed blog post.
// The blog post will show a picture, the author, content and the resources used
// to create the blog post.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "../../../../styles/MyAccount.css";
// eslint-disable-next-line no-unused-vars
import { AuthContext } from "../../../../components/authentication/Auth.js";

// This is the dialog box that opens when a user clicks on the blog post
function BlogPostBox(props) {
  const { onClose, open, blogPost } = props;

  // Take the blog post content and split it up the paragraphs
  const blogPostParagraphs = blogPost.content.split("--");

  const handleClose = () => {
    onClose(false);
  };

  // Opens a new tab with the URL contained in value
  function handleListItemClick(value) {
    const win = window.open(value, "_blank");
    win.focus();
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{blogPost.title}</DialogTitle>
      <DialogContent>
        <img className="w3-image" alt="Blog Post" src={blogPost.photo} />
        <div>
          Author: {blogPost.author}
          <br />
          <br />
          {blogPostParagraphs.map((blogPostParagraph, i) => (
            <p key={i}>{blogPostParagraph}</p>
          ))}
          {Object.keys(blogPost.websiteResources).length !== 0 && (
            <>
              <h5>
                <u>Resources used in this post</u>
              </h5>
              {Object.keys(blogPost.websiteResources).map((websiteResource) => (
                <>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() =>
                      handleListItemClick(
                        blogPost.websiteResources[websiteResource],
                      )
                    }
                  >
                    <u>{websiteResource}</u>
                  </Button>
                  <br />
                </>
              ))}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BlogPostBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function BlogPostDialog(props) {
  const [open, setOpen] = useState(false);
  const blogPost = props.blogPost;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="Order-Again-Button">
        <div className="Order-Again-Button">
          <Button onClick={handleClickOpen} color="primary">
            <u>
              {blogPost.title} by {blogPost.author}
            </u>
          </Button>
        </div>
      </div>

      <BlogPostBox open={open} onClose={handleClose} blogPost={blogPost} />
    </div>
  );
}
