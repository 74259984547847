// When a distribution location changes the distributed amounts then the user has the option to
// also update the pantry by other taking or replacing items in the pantry.
import firebase from "../../../components/Firebase.js";

export default function UpdatePantry(
  userInfo,
  foodItem,
  distributionAmountsChange,
  leftovers,
  setLeftovers,
) {
  const database = firebase.firestore();

  // Create an updatedPantry document that we will use to update the
  // database with the changes made.
  let updatedPantryDoc = {};
  // The newLimit is the amount of the item in the pantry that is left
  // after the user takes their amount from it.
  let newLimit = 0;

  // Create a document reference to the pantry being used.
  const pantryDocRef = database
    .collection("Pantry")
    .doc(userInfo.organisationName);
  // We run a transaction as we want to make sure that the user is reading the
  // most up to date data from the document we want to make sure that if another
  // user took an item that there is still enough in the pantry for this user's order.
  return database
    .runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(pantryDocRef).then((pantryDoc) => {
        if (!pantryDoc.exists) {
          // eslint-disable-next-line no-throw-literal
          throw "Document does not exist!";
        }

        // Create an updatedPantry document that we will use to update the
        // database with the changes made.
        updatedPantryDoc = { ...pantryDoc.data() };
        // The itemName is how it is referenced in the database item + individualDescription + farmName
        let itemName = "";
        // The newLimit is the amount of the item in the pantry that is left
        // after the user takes their amount from it.
        newLimit = 0;
        // Cancel holds the string that will be returned if there is not enough
        // of an item when placing the order.
        let cancel = "";

        // Find the items reference in the firebase document.
        itemName = [
          foodItem.item,
          foodItem.individualDescription,
          foodItem.farmName,
        ].join("");
        if (updatedPantryDoc[itemName]) {
          // Calculate the newLimit after the user's order is subtracted from
          // the current limit on the item.
          newLimit =
            updatedPantryDoc[itemName].limit - distributionAmountsChange;
          // Make sure the limit isn't less than zero.  If it is then we'll
          // have to end the order and notify the user to update their quantities.
          if (newLimit < 0) {
            updatedPantryDoc[itemName].limit = 0;
          }
          // If there are enough items in the pantry then update the dictionary
          // with the updated pantry document.
          else {
            updatedPantryDoc[itemName].limit = newLimit;
          }
        } else {
          cancel = "This item does not exist in the Pantry";
        }

        // Check to see if the cancel string was ever created and if so then
        // reject the transaction.
        if (cancel !== "") {
          return Promise.reject(cancel);
        }
        // Otherwise accept the transaction and update the pantry.
        else {
          transaction.update(pantryDocRef, { ...updatedPantryDoc });
        }
      });
    })
    .then(() => {
      const index = leftovers.findIndex((leftover) => {
        return (
          leftover.farmName === foodItem.farmName &&
          leftover.individualDescription === foodItem.individualDescription &&
          leftover.item === foodItem.item
        );
      });
      if (index !== -1) {
        leftovers[index].limit = newLimit;
        setLeftovers([...leftovers]);
      }
    })
    .catch((err) => {
      // Send an alert to the user to tell them of the error.
      // eslint-disable-next-line no-undef
      alert(err);
      // Return false that the transaction failed.
      return false;
    });
}
