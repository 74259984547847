// This is to display the answers of users on the homepage for all users to see.
// Their answers can only be clicked as anonymous.  This is a dialog that when pressed
// will display the users answers for each question of the questionnaire.
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

// This is called to display the quesiton
const QuestionContent = ({ question, indexOfQuestion, userAnswers }) => {
  // This is the type of question this question is, (ie. multi, checkbox, or short answer)
  const questionType = question.type;

  return (
    <div>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {question.questionPrompt}
          {questionType === "checkbox" && " (Select all that apply)"}
        </Typography>
        {questionType === "multi" && (
          <FormControl>
            <RadioGroup value={userAnswers[indexOfQuestion]}>
              {question.options.map((option, optionInd) => (
                <span key={optionInd} style={{ display: "flex" }}>
                  <FormControlLabel
                    disabled
                    value={option}
                    label={option}
                    control={<Radio />}
                  />
                </span>
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {questionType === "checkbox" && (
          <FormControl>
            <FormGroup>
              {question.options.map((option, optionInd) => (
                <span key={optionInd} style={{ display: "flex" }}>
                  <FormControlLabel
                    checked={userAnswers[indexOfQuestion][optionInd]}
                    value={option}
                    label={option}
                    disabled
                    control={<Checkbox />}
                  />
                </span>
              ))}
            </FormGroup>
          </FormControl>
        )}
        {questionType === "text" && (
          <TextField
            disabled
            value={userAnswers[indexOfQuestion]}
            label="Short-Answer Text"
            multiline
            fullWidth
          />
        )}
      </CardContent>
    </div>
  );
};

QuestionContent.propTypes = {
  question: PropTypes.object,
  indexOfQuestion: PropTypes.number,
  userAnswers: PropTypes.object,
};

export default function DisplayAnswersHomePage({
  question,
  indexOfQuestion,
  userAnswers,
}) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <QuestionContent
          question={question}
          indexOfQuestion={indexOfQuestion}
          userAnswers={userAnswers}
        />
      </Card>
    </Box>
  );
}

DisplayAnswersHomePage.propTypes = {
  question: PropTypes.object,
  indexOfQuestion: PropTypes.number,
  userAnswers: PropTypes.object,
};
