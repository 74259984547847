// This is called to end a recurring delivery that a location has set up.
import firebase from "../../../components/Firebase.js";
import moment from "moment";

// If the user clicks to end a recurring order than this is called and the recurring
// order is updated in the database.
export default function EndRecurringDelivery(
  recurringOrder,
  index,
  recurringOrders,
  setRecurringOrders,
) {
  const database = firebase.firestore();

  const documentName = (
    recurringOrder.ingredient +
    "R" +
    recurringOrder.startDay +
    recurringOrder.userId
  ).replace(/\s+/g, "");

  const batch = database.batch();

  const docRef = database.collection("IngredientList").doc(documentName);

  // Splices out this order from the recurringOrders array displayed to the user
  const arr = [...recurringOrders];
  arr.splice(index, 1);
  setRecurringOrders([...arr]);

  // Gives the user an endDate of today and changes the status to ended.
  batch.set(
    docRef,
    {
      endDate: moment().format().substring(0, 10),
      status: "ended",
    },
    { merge: true },
  );
  batch.commit();
}
