// This is the dialog that appears on the user's screen when they try and place an order
// and there is an announcement dialog that the distribution location wants shown.
import React, { forwardRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";

// The slide animation effect for the dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// pickupLocation is being used to render the announcement information based on the user's chosen pickup location.
// openNotice and setOpenNotice are used to open the dialog. Props are being passed down from Basket.js and OrderSummary.jsx
export default function AnnouncementDialog({
  openNotice,
  setOpenNotice,
  pickupLocation,
}) {
  const handleClose = () => {
    setOpenNotice(false);
  };

  // This function takes in a url and opens it in another tab.
  function handleRegisterClick(value) {
    const win = window.open(value, "_blank");
    win.focus();
    handleClose();
  }

  return (
    <div>
      <Dialog
        // This is to make sure this dialog opens in front of other page elements
        style={{ zIndex: 100000 }}
        // If the user's chosen pickup location has an announcement then open the announcement dialog
        open={pickupLocation.announcementOn && openNotice}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="announcement-dialog"
        aria-describedby="announcement-dialog-description"
      >
        <DialogTitle
          id="announcement-dialog"
          style={{ paddingTop: 6, paddingBottom: 0 }}
        >
          {pickupLocation.announcementTitle}
        </DialogTitle>
        <DialogContent>
          {pickupLocation.announcementImage !== "" && (
            <img
              style={{ width: "100%", display: "block" }}
              rel="no-opener no-referrer"
              referrerPolicy="no-referrer"
              src={pickupLocation.announcementImage}
              alt="event poster"
            />
          )}
          <DialogContentText id="announcement-dialog-description">
            {pickupLocation.announcementDescription}
          </DialogContentText>
        </DialogContent>
        {pickupLocation.announcementWebsite !== "" ? (
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              CLOSE
            </Button>
            <Button
              onClick={() =>
                handleRegisterClick(pickupLocation.announcementWebsite)
              }
              color="primary"
            >
              NEXT
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              CLOSE
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

AnnouncementDialog.propTypes = {
  openNotice: PropTypes.bool,
  setOpenNotice: PropTypes.func,
  pickupLocation: PropTypes.object,
};
