// When a community host removed a community member the community member will no
// longer be able to see the community host.  They will become an unapproved
// community member and this location will be removed from the distribution selection
// screen.
// If the user after being removed is then deleted it will be like the never applied
// see DeleteCommunityMemberDialog for this action.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import RemoveCommunityMember from "../Functions/RemoveCommunityMember.js";

function RemoveCommunityMemberBox({
  open,
  onClose,
  communityMember,
  userInfo,
  communityMembers,
  setCommunityMembers,
}) {
  const handleClose = () => {
    onClose(false);
  };

  const handleRemoveCommunityMember = (
    communityMember,
    userInfo,
    communityMembers,
    setCommunityMembers,
  ) => {
    RemoveCommunityMember(
      communityMember,
      userInfo,
      communityMembers,
      setCommunityMembers,
    );
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Confirm Removal of Community Member
      </DialogTitle>
      <DialogContent>
        If this community member has any orders pending for pick up this will
        not cancel their order. This will however prevent them from being able
        to select your location in the future. They will not be notified that
        you removed them.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() =>
            handleRemoveCommunityMember(
              communityMember,
              userInfo,
              communityMembers,
              setCommunityMembers,
            )
          }
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RemoveCommunityMemberBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function RemoveCommunityMemberDialog({
  communityMember,
  userInfo,
  communityMembers,
  setCommunityMembers,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="Order-Again-Button">
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          REMOVE COMMUNITY MEMBER
        </Button>
      </div>
      <RemoveCommunityMemberBox
        open={open}
        onClose={handleClose}
        communityMember={communityMember}
        userInfo={userInfo}
        communityMembers={communityMembers}
        setCommunityMembers={setCommunityMembers}
      />
    </div>
  );
}
