// This dialog displays the communityMemberInfo including the phone number and their
// email.
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import LoadCommunityMemberInfo from "../Functions/LoadCommunityMemberInfo.js";
import LoadingContent from "../../../components/LoadingContent.jsx";

// This is the dialog box that opens when a user clicks the add button
function CommunityMemberInfoBox({
  open,
  onClose,
  communityMemberInfo,
  loading,
}) {
  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        {communityMemberInfo.firstName} {communityMemberInfo.lastName}{" "}
        Information <InfoIcon color="primary" />
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingContent inline={true} />
        ) : (
          <div>
            Email : {communityMemberInfo.email} <br />
            Phone Number : {communityMemberInfo.phoneNumber}
          </div>
        )}

        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

CommunityMemberInfoBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function CommunityMemberInfoDialog({ communityMember }) {
  const [open, setOpen] = useState(false);
  // Sets whether their number and email have been loaded yet.
  const [loading, setLoading] = useState(false);
  // Set their default data which we have without loading anything.
  const [communityMemberInfo, setCommunityMemberInfo] = useState({});

  // Update the hook in case the community Member changes.
  useEffect(() => {
    setCommunityMemberInfo({
      firstName: communityMember[0],
      lastName: communityMember[1],
      userId: communityMember[2],
      email: "Not available",
      phoneNumber: "Not available",
    });
  }, [communityMember]);

  const handleClickOpen = () => {
    setOpen(true);
    // Set loading to true while we retrieve their info.
    setLoading(true);
    // Load their info.  This will only happen when a user is clicked to minimize
    // the amount of reads needed.
    LoadCommunityMemberInfo(
      communityMemberInfo,
      setCommunityMemberInfo,
      setLoading,
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button size="large" onClick={handleClickOpen}>
        <InfoIcon color="primary" />
      </Button>

      <CommunityMemberInfoBox
        open={open}
        onClose={handleClose}
        communityMemberInfo={communityMemberInfo}
        loading={loading}
      />
    </div>
  );
}
