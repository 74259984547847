// Show the pillars of the program and shows their title and picture.
// When the user clicks on the pillar it will expand for the user to
// read more in depth.
import React, { useState } from "react";

export default function ProgramPillars({ title, src, text }) {
  // Expand the read more of the steps to see the text or hide the text of this text.
  const [expand, setExpand] = useState(false);

  // Whether the click to read more or click to read less is showed.
  const [readMoreMessage, setReadMoreMessage] = useState("Click to Read More+");

  // When the user clicks to expand or collapse the step.  Changes the read more
  // messages to match what the user should be seeing.
  function onExpand() {
    setExpand(!expand);
    if (expand) {
      setReadMoreMessage("Click to Read More+");
    } else {
      setReadMoreMessage("Click to Read Less-");
    }
  }

  return (
    <div className="Pillar-List">
      <button onClick={onExpand} className="Pillar-Buttons">
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            referrerPolicy="no-referrer"
            style={{ height: "100px", margin: "20px 30px" }}
            src={src}
          />
          <h3 className="Pillar-Buttons-Title-Font">{title}</h3>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <h5 className="Pillar-Buttons-Click-Font">{readMoreMessage}</h5>
          </span>
        </span>
      </button>
      {expand && <span className="Pillar-Read-More">{text}</span>}
    </div>
  );
}
