// This is to update an individual user's delivery fee from the myAccount Page
import firebase from "./../Firebase.js";
import IndividualPriceChangesPaymentHistory from "./../../pages/MyAccountPage/Functions/PriceHistoryChanges/IndividualPriceChangesPaymentHistory.js";
import UpdateCommunityOrdersDatabase from "./../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

export default async function UpdateCustomerDeliveryFees(
  user,
  imported,
  order,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
  userOrder,
  updatePaymentHistory,
  locationName,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userOrderDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // The indivdual's account may need to be updated in case their credits need to be changed.
  // eslint-disable-next-line no-unused-vars
  const userDocRef = database.collection("Users").doc(userId[2]);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // Update the current distribution locations orders in the DistributionLocations
  // collection
  const communityOrdersRef = database
    .collection("DistributionLocations")
    .doc(userInfo.organisationName)
    .collection("CommunityOrders");

  const querySnapshot = await communityOrdersRef
    .where("distributionDate", "==", order.selectedDate)
    .get();

  // Loop through the documents to find the one with the matching user key
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // Check if the document contains the user key
    if (user in data) {
      communityOrdersDocRef = doc.ref; // Reference to the found document
    }
  });

  // Remove the Dicts as they were added for rendering purposes and should not
  // be saved to the database
  delete userOrder.foodDict;
  delete userOrder.farmDict;

  // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
  // FirstName-LastName-userId-date
  UpdateCommunityOrdersDatabase(
    "SingleUserOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    null,
    order.orderDate,
    user,
    userOrder,
  );

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  if (communityOrdersDocRef) {
    batch.update(communityOrdersDocRef, {
      [user]: { ...userOrder },
    });
  }

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userOrderDocRef, {
    deliveryFee: userOrder.deliveryFee,
  });

  // The user's original order before the changes.
  let originalUserOrder = {};

  // This is to make sure the page rerenders these items correctly
  if (imported) {
    // Set the user order before changes.
    originalUserOrder = {
      ...orderLogTemp[orderIndex].importedOrder[locationName].communityOrders[
        user
      ],
    };
    orderLogTemp[orderIndex].importedOrder[locationName].communityOrders[user] =
      { ...userOrder };
  } else {
    // Set the user order before changes.
    originalUserOrder = { ...orderLogTemp[orderIndex].communityOrders[user] };
    orderLogTemp[orderIndex].communityOrders[user] = { ...userOrder };
  }

  // This is just the value of the subsidy/donation.  It will be negative if it
  // is a subsidy or positive if it is a donation.
  let originalOrderCost = 0;
  // This is just the value of the subsidy/donation after the change.  It will be negative if it
  // is a subsidy or positive if it is a donation.
  let updatedOrderCost = 0;

  originalOrderCost += parseFloat(originalUserOrder.deliveryFee);

  updatedOrderCost += parseFloat(userOrder.deliveryFee);

  // Since this could be called from the Basket Page we don't want to update the
  // payment history.
  if (updatePaymentHistory) {
    // Update the payment history.
    IndividualPriceChangesPaymentHistory(
      order.orderDate,
      originalOrderCost,
      updatedOrderCost,
      null,
      null,
      null,
      imported,
      locationName,
      user,
      "deliveryFeeChange",
      updatePaymentHistory,
    );
  }

  await batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
