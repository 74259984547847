// This is called by the questionnaire accordion and will show the questionnaire details.  The user
// will have the option to delete or edit the questions in the questionnaire.
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

// This is called to display the quesiton
const QuestionContent = ({ question }) => {
  // This is the type of question this question is, (ie. multi, checkbox, or short answer)
  const questionType = question.type;

  // Set the type of question that the object should be.
  let typeOfQuestion = <Radio />;

  // The title of the question is the question type.
  let questionTypeTitle = "Multiple Choice Question";

  // Change the values to the correct question type.
  if (questionType === "checkbox") {
    typeOfQuestion = <Checkbox />;
    questionTypeTitle = "Checkboxes";
  } else if (questionType === "text") {
    typeOfQuestion = <TextField label="Short-Answer Text" />;
    questionTypeTitle = "Short Answer";
  }

  return (
    <div>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {questionTypeTitle}
        </Typography>
        <TextField
          margin="dense"
          id="questionPrompt"
          label="What is your question?"
          name="questionPrompt"
          value={question.questionPrompt}
          fullWidth
          disabled
        />
        <FormControl>
          <FormGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {question.options.map((option, optionInd) => (
              <span key={optionInd} style={{ display: "flex" }}>
                <FormControlLabel disabled control={typeOfQuestion} />
                {questionType !== "text" && (
                  <TextField
                    margin="dense"
                    id={"option ".concat(optionInd + 1)}
                    label={"Option ".concat(optionInd + 1)}
                    name={"option"}
                    value={option}
                    fullWidth
                    disabled
                  />
                )}
              </span>
            ))}
          </FormGroup>
        </FormControl>
      </CardContent>
    </div>
  );
};

QuestionContent.propTypes = {
  question: PropTypes.object,
};

export default function DisplayQuestionDistributionLocation({ question }) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <QuestionContent question={question} />
      </Card>
    </Box>
  );
}

DisplayQuestionDistributionLocation.propTypes = {
  question: PropTypes.object,
};
