// This function will total up each farms total value and output
// dictionary with "farmName" : "$Total"
export default function PriceTotals(foodList, individual, suggestedPrice) {
  const farms = {};
  // These suggested fees that will be used to calculate the volunteer fee.
  let suggestedFees = 0;

  // The dictionary of the total value of food donated by the farmers.
  const farmSuggestedTotals = {};
  // First go throuh all the foodItems and initialise the farm providing that
  // item to $0.
  foodList.forEach((food) => {
    farms[food.farmName] = 0;
    // This is for any free food items.
    farmSuggestedTotals[food.farmName] = 0;
  });

  // Cycle through all the foodItems again find the product of the quantity
  // and the cost of the food item and then add it to the farms total
  foodList.forEach((food) => {
    // If the suggestedPrice is false than we calculate the totals normally.
    if (!food.suggestedPrice) {
      if (individual) {
        // This if statement is included for orders that occured before the database
        // changes to the foodItems to include the individual and distribution amounts
        if (food.distributionQuantity === undefined) {
          farms[food.farmName] += food.quantity * food.price;
        } else {
          farms[food.farmName] +=
            (food.quantity * food.price) /
            (food.distributionQuantity / food.individualQuantity);
        }
      } else {
        farms[food.farmName] += food.quantity * food.price;
      }
    }
    // If the fee is suggested than we will add the price to the suggestedFees.
    else {
      if (individual) {
        farmSuggestedTotals[food.farmName] +=
          (food.quantity * food.price) /
          (food.distributionQuantity / food.individualQuantity);
      } else {
        farmSuggestedTotals[food.farmName] += food.quantity * food.price;
      }
    }
  });

  // If the suggestedPrice is set than we will return both the farms and the suggestedFees.
  if (suggestedPrice) {
    // Sum up the total suggestedFees.
    Object.keys(farmSuggestedTotals).forEach((farm) => {
      suggestedFees += farmSuggestedTotals[farm];
    });
    return [farms, suggestedFees, farmSuggestedTotals];
  } else {
    return farms;
  }
}
