// This dialog appears when a use is about to delete a questionnaire and we want
// to confirm that is what they actually want to do.
import React, { useState } from "react";
import firebase from "../../../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";

// This is the dialog box that opens when a user clicks the delete questionnaire button
function DeleteQuestionnaireBox({
  open,
  onClose,
  questionnaire,
  indexOfQuestionnaire,
  questionnaires,
  setQuestionnaires,
}) {
  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  // If the user confirms they want to delete the questionnaire then we remove it from the database.
  const handleDelete = (e) => {
    e.preventDefault();
    const database = firebase.firestore();
    // Load the questionnaire document reference.
    database
      .collection("Questionnaires")
      .doc(questionnaire.createdDate)
      .delete();
    // Set the questionnaire list to a temp variable.
    const questionnairesTemp = [...questionnaires];
    // Remove the questions from the questionnaire.
    questionnairesTemp.splice(indexOfQuestionnaire, 1);
    // Set the questionnaire new list.
    setQuestionnaires([...questionnairesTemp]);

    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Are you sure you want to delete this questionnaire?
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleDelete}>
          <DialogActions>
            <Button onClick={() => handleClose()} color="primary">
              CANCEL
            </Button>
            <Button type="submit" color="secondary">
              DELETE
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

DeleteQuestionnaireBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  questionnaire: PropTypes.object,
  indexOfQuestionnaire: PropTypes.number,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
};

export default function DeleteQuestionnaireDialog({
  questionnaire,
  indexOfQuestionnaire,
  questionnaires,
  setQuestionnaires,
  questionnaireEnabled,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        disabled={questionnaireEnabled}
        onClick={handleClickOpen}
        color="secondary"
        variant="outlined"
        startIcon={<DeleteIcon />}
      >
        DELETE QUESTIONNAIRE
      </Button>

      <DeleteQuestionnaireBox
        open={open}
        onClose={handleClose}
        questionnaire={questionnaire}
        indexOfQuestionnaire={indexOfQuestionnaire}
        questionnaires={questionnaires}
        setQuestionnaires={setQuestionnaires}
      />
    </div>
  );
}

DeleteQuestionnaireDialog.propTypes = {
  questionnaire: PropTypes.object,
  indexOfQuestionnaire: PropTypes.number,
  questionnaires: PropTypes.array,
  setQuestionnaires: PropTypes.func,
  questionnaireEnabled: PropTypes.bool,
};
