// This actually isn't a dialog but calls all the dialogs in the process of sending
// out messages to volunteers.  It has a two states where the user first selects the volunteers and
// creates the customized message and then confirms and sends the message.
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import UserSelectionCustomMessageDialog from "./UserSelectionCustomMessageDialog.jsx";
import ConfirmCustomMessageDialog from "./ConfirmCustomMessageDialog.jsx";
import SendingEmailsDialog from "./SendingEmailsDialog.jsx";
import SelectVolunteerActionDialog from "./SelectVolunteerActionDialog.jsx";
import "date-fns";
import CreateCreditSendMessage from "./functions/CreateCreditSendMessage.js";

export default function VolunteerEmailDialog({
  volunteers,
  selectedPosition,
  date,
}) {
  // This is to say that the first dialog in 1/3 states is open and in process
  const [openSelectAction, setOpenSelectAction] = useState(false);
  // This is to say that the first dialog in 2/3 state is open and in process
  const [
    openUserSelectionCustomMessageDialog,
    setOpenUserSelectionCustomMessageDialog,
  ] = useState(false);
  // This is to say confirm 3/3 state
  const [openConfirmCustomMessageDialog, setOpenConfirmCustomMessageDialog] =
    useState(false);
  // This is the next state.  If it the custom message then it will be the confirm dialog next.
  // If it is on the confirm then it will be custom message state as the user can go back.
  const [nextState, setNextState] = useState("");
  // This sets what state in the 3 state process the user is in.
  const [stateOfDialog, setStateOfDialog] = useState("selectAction");
  // The checkList of customers that the user has selected to message. {firstName-lastName-userId : true}
  const [checkList, setCheckList] = useState({});
  // Which greeting message the user has selected from the radio buttons in 2nd state.
  const [greeting, setGreeting] = useState("Hi");
  // The custom message the user has written to be send to users.
  const [message, setMessage] = useState("");
  // The standard credit message that will have the code, date and position that the
  // user completed.  This will be a dictionary and for each user.
  const [creditsMessage, setCreditsMessage] = useState({});

  // This is set to true while the program sends the customers' emails and set a
  // progress dialog.
  const [sendingEmails, setSendingEmails] = useState(false);

  // This use effect checks the state of the user and makes sure that the correct
  // dialogs are being displayed.
  useEffect(() => {
    // 2/3 State
    if (stateOfDialog === "Custom") {
      setOpenSelectAction(false);
      setOpenUserSelectionCustomMessageDialog(true);
      setOpenConfirmCustomMessageDialog(false);
      // The next state going forward.
      setNextState("ConfirmCustom");
    }
    // 3/3 state
    else if (stateOfDialog === "ConfirmCustom") {
      setOpenSelectAction(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenConfirmCustomMessageDialog(true);
      // The next state going back as forward is just sending email.
      setNextState("Custom");
    }
    // 3/3 state
    else if (stateOfDialog === "CreditsSend") {
      setOpenSelectAction(false);
      setOpenUserSelectionCustomMessageDialog(true);
      setOpenConfirmCustomMessageDialog(false);
      // The next state going forward.
      setNextState("ConfirmCreditsSend");
      // Create the custom credit messages for each user.
      CreateCreditSendMessage(
        creditsMessage,
        setCreditsMessage,
        volunteers,
        selectedPosition,
        date,
        volunteerEmails,
      );
    }
    // 3/3 state
    else if (stateOfDialog === "ConfirmCreditsSend") {
      setOpenSelectAction(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenConfirmCustomMessageDialog(true);
      // The next state going back as forward is just sending email.
      setNextState("CreditsSend");
    }
    // 1/3 state
    else {
      setOpenSelectAction(false);
      setOpenUserSelectionCustomMessageDialog(false);
      setOpenConfirmCustomMessageDialog(false);
      setNextState("selectAction");
    }
  }, [stateOfDialog]);

  // The volunteer emails saved to each user.
  const volunteerEmails = {}; // shift : {firstName-lastName-userId : email
  // Cycles through the volunteers for each shift.
  Object.keys(volunteers).forEach((shift) => {
    // Skip the keys that aren't volunteers
    if (shift !== "creditValue" && shift !== "location") {
      // Cycle through all the volunteers in this shift.
      volunteers[shift].volunteers.forEach((volunteer) => {
        // If the volunteer Email is undefined then we haven't added a user
        // from this shift yet.
        if (volunteerEmails[shift] === undefined) {
          // Set the dictionary up.
          volunteerEmails[shift] = {};
          // Add this volunteer and the email.
          volunteerEmails[shift][
            [volunteer.firstName, volunteer.lastName, volunteer.userId].join(
              "-",
            )
          ] = volunteer.email;
        }
        // If there has already been another user from this shift added then we just
        // have to updated the dictionary that already exists.
        else {
          // Add this volunteer and the email.
          volunteerEmails[shift][
            [volunteer.firstName, volunteer.lastName, volunteer.userId].join(
              "-",
            )
          ] = volunteer.email;
        }
      });
    }
  });

  // If the user opens the first dialog and is in state 1/2
  const handleClickOpen = () => {
    setOpenSelectAction(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        style={{ background: "#F738A2", color: "white" }}
        onClick={handleClickOpen}
      >
        EMAIL VOLUNTEERS
      </Button>

      <SelectVolunteerActionDialog
        openSelectAction={openSelectAction}
        setOpenSelectAction={setOpenSelectAction}
        setStateOfDialog={setStateOfDialog}
      />

      <UserSelectionCustomMessageDialog
        userEmails={volunteerEmails}
        openUserSelectionCustomMessageDialog={
          openUserSelectionCustomMessageDialog
        }
        setOpenUserSelectionCustomMessageDialog={
          setOpenUserSelectionCustomMessageDialog
        }
        setStateOfDialog={setStateOfDialog}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        setGreeting={setGreeting}
        message={message}
        setMessage={setMessage}
        nextState={nextState}
      />

      <ConfirmCustomMessageDialog
        openConfirmCustomMessageDialog={openConfirmCustomMessageDialog}
        setOpenConfirmCustomMessageDialog={setOpenConfirmCustomMessageDialog}
        setStateOfDialog={setStateOfDialog}
        userEmails={volunteerEmails}
        checkList={checkList}
        setCheckList={setCheckList}
        greeting={greeting}
        message={message}
        setSendingEmails={setSendingEmails}
        nextState={nextState}
        creditsMessage={creditsMessage}
      />

      <SendingEmailsDialog open={sendingEmails} />
    </div>
  );
}
