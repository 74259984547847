// This displays the Admin's marketplace.
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import Menu from "../../../components/Menu.js";
import CustomizedSnackbars from "../../../components/snackBars/Snackbar.js";
import FoodElement from "../Components/FoodElement.jsx";
import FilterColumn from "../Components/FilterColumn.jsx";
import MarketplaceAddDialog from "../../../components/userForms/MarketplaceAddForm.js";
import HandleAddAllToBasket from "./../Functions/HandleAddAllToBasket.js";
import LoadCategories from "./../LoadCategories.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import UpdateVisibility from "./../Functions/UpdateVisibility.js";
import Footer from "../../../components/Footer.jsx";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SortFoodItemsSelect from "./../Components/SortFoodItemsSelect.jsx";
import HandleFoodItemsSort from "../Functions/HandleFoodItemsSort.js";

export default function AdminMarketplace({
  userInfo,
  handleUserInfoChange,
  foodItems,
  setFoodItems,
  displayedFoodItems,
  setDisplayedFoodItems,
  currentUser,
  farmCategories,
  totalBasketItems,
  snackBarOpen,
  setSnackBarOpen,
  snackBarMessage,
  setSnackBarMessage,
  handleSnackBarClose,
  selectedCategory,
  setSelectedCategory,
  checkedFarms,
  setCheckedFarms,
}) {
  // The marketplaceCategories so that the admin can see the categories of all
  // the items
  const marketplaceCategories = LoadCategories("", farmCategories);

  // Set the default selection for sorting to farm name ascending.
  const [sortBy, setSortBy] = useState("farmNameAsc");

  // When the page loads we want to make sure that the foodItems are sorted correctly.
  useEffect(() => {
    // Sort the foodItems.
    HandleFoodItemsSort(sortBy, foodItems, setFoodItems, setSortBy);
  }, []);

  // This is to handle the user updating all the foodItems to either checked or unchecked
  // for visbility.
  const handleSwitch = (e) => {
    // Checks if the switch is changing to true or false.
    const checked = e.target.checked;

    // This makes sure that when items are updated the master foodItem list to prevent
    // any problems when changing the Visibility while there are filters or categories selected.
    let foodItemsTemp = [...foodItems];

    // Cycle through all the foodItems to update them all.
    displayedFoodItems.forEach((foodItem, index) => {
      // Checks to see if the user is making the item visible.
      if (checked) {
        // Checks to see if the item is already visible as if it is we don't need
        // to do anything.
        if (!foodItem.adminVisible) {
          // Takes the updated displayedFoodItems and updates the temporary array.
          foodItemsTemp = UpdateVisibility(
            userInfo,
            foodItem,
            checked,
            foodItemsTemp,
            setFoodItems,
          );
        }
      }
      // If the user is making the item unvisible to users.
      else {
        // Checks to see if the item is already unvisible.
        if (foodItem.adminVisible) {
          // Takes the updated displayedFoodItems and updates the temporary array.
          foodItemsTemp = UpdateVisibility(
            userInfo,
            foodItem,
            checked,
            foodItemsTemp,
            setFoodItems,
          );
        }
      }
    });
    // When the loop has finished then update the hook.
    setFoodItems([...foodItemsTemp]);
  };

  // This means that all items are visible and so the switch is set to true.
  let allVisible = true;

  // Cycle through the foodItems to determine if they are all visible or not.
  displayedFoodItems.forEach((foodItem) => {
    // Checks if the foodItem is visible and if it's not then set the allVisible
    // to false.
    if (!foodItem.adminVisible) {
      allVisible = false;
    }
  });

  // Counts if there are any items that the user has pressed the plus button but not
  // yet added to the basket.
  let addAllVisible = 0;
  // Counts up the displayed items quantities to see if any items need to be added to
  // the basket.
  displayedFoodItems.forEach((displayedFoodItem) => {
    addAllVisible += displayedFoodItem.quantity;
  });

  return (
    <div
      style={{ background: "rgb(244, 249, 224)", minWidth: "950px" }}
      id="home"
    >
      <Menu />
      <Grid
        container
        className="Marketplace-Grid-Header"
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          alignItems: "baseline",
        }}
      >
        <Grid item xs>
          <h1 className="Title-Marketplace">Marketplace</h1>
        </Grid>
      </Grid>
      <div className="Page-Market" id="pageMarket">
        <Grid container spacing={2}>
          {foodItems.length !== 0 && (
            <Grid item xs={3}>
              <div className="Checkout-Add-Items-Buttons">
                <MarketplaceAddDialog
                  foodItems={foodItems}
                  setFoodItems={setFoodItems}
                  farms={userInfo.farms}
                  userInfo={userInfo}
                  farmCategories={farmCategories}
                />
                {totalBasketItems > 0 && (
                  <Link
                    style={{ textDecoration: "none", marginBottom: "10px" }}
                    to="/Basket"
                  >
                    <Button
                      endIcon={<ShoppingCartCheckoutIcon size="large" />}
                      variant="contained"
                      style={{
                        background: "rgb(21, 101, 192)",
                        color: "white",
                        width: "100%",
                      }}
                    >
                      HEAD TO CHECKOUT
                    </Button>
                  </Link>
                )}
                {addAllVisible > 0 && (
                  <Button
                    onClick={() =>
                      HandleAddAllToBasket(
                        displayedFoodItems,
                        setDisplayedFoodItems,
                        setSnackBarOpen,
                        setSnackBarMessage,
                        userInfo,
                        handleUserInfoChange,
                      )
                    }
                    endIcon={<AddShoppingCartIcon />}
                    variant="contained"
                    style={{
                      backgroundColor: "#9FBF59",
                      color: "white",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    ADD ALL ITEMS TO CART
                  </Button>
                )}

                <FilterColumn
                  marketplaceCategories={marketplaceCategories}
                  foodItems={foodItems}
                  setDisplayedFoodItems={setDisplayedFoodItems}
                  checkedFarms={checkedFarms}
                  setCheckedFarms={setCheckedFarms}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              </div>
            </Grid>
          )}
          <Grid item xs>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <FormControlLabel
                label="Change All Visibility"
                control={
                  <Switch
                    checked={allVisible}
                    onChange={(e) => handleSwitch(e)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
              <SortFoodItemsSelect
                foodItems={foodItems}
                setFoodItems={setFoodItems}
                sortBy={sortBy}
                setSortBy={setSortBy}
                userInfo={userInfo}
              />
            </div>

            {displayedFoodItems.length !== 0 ? (
              <span>
                <div className="Food-List">
                  {displayedFoodItems.map((row, index) => (
                    <FoodElement
                      key={index}
                      index={index}
                      row={row}
                      currentUser={currentUser}
                      userInfo={userInfo}
                      handleUserInfoChange={handleUserInfoChange}
                      foodItems={foodItems}
                      setFoodItems={setFoodItems}
                      displayedFoodItems={displayedFoodItems}
                      setDisplayedFoodItems={setDisplayedFoodItems}
                      farmCategories={farmCategories}
                      setSnackBarOpen={setSnackBarOpen}
                      setSnackBarMessage={setSnackBarMessage}
                    />
                  ))}
                </div>
                <div>
                  Icons made by{" "}
                  <a
                    href="https://www.flaticon.com/authors/photo3idea-studio"
                    title="photo3idea_studio"
                  >
                    photo3idea_studio
                  </a>{" "}
                  from{" "}
                  <a href="https://www.flaticon.com/" title="Flaticon">
                    www.flaticon.com
                  </a>
                </div>
              </span>
            ) : (
              <span>
                <h3 className="EmptyMarketplace">
                  {" "}
                  No items added to your Marketplace yet!{" "}
                </h3>
                <MarketplaceAddDialog
                  foodItems={foodItems}
                  setFoodItems={setFoodItems}
                  farms={userInfo.farms}
                  userInfo={userInfo}
                  farmCategories={farmCategories}
                />
              </span>
            )}
          </Grid>
        </Grid>

        <CustomizedSnackbars
          message={snackBarMessage}
          open={snackBarOpen}
          handleClose={handleSnackBarClose}
        />
      </div>
      <Footer />
    </div>
  );
}
