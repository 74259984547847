// When a volunteer is being deleted from a shift.
import firebase from "../../../components/Firebase.js";

export default function RemoveVolunteerFromShift(
  volunteer,
  selectedPosition,
  volunteerSchedule,
  setVolunteerSchedule,
  indexOfQuarter,
  date,
  shift,
  index,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // The volunteer schedule in a temp dict to be edited.
  const volunteerScheduleTemp = [...volunteerSchedule];

  // This is the volunteer schedule quarter that we can update.
  const volunteerScheduleQuarterTemp = { ...volunteerSchedule[indexOfQuarter] };

  // Splice out the volunteer to be removed.
  volunteerScheduleQuarterTemp[date][selectedPosition][shift].volunteers.splice(
    index,
    1,
  );

  // The volunteer schedule for this time period.
  const docRef = database
    .collection("VolunteerSchedule")
    .doc(volunteerScheduleTemp[indexOfQuarter].timePeriod);

  // update the database.
  batch.set(docRef, volunteerScheduleQuarterTemp, { merge: true });

  batch.commit();

  // Update the current volunteer schedule.
  volunteerScheduleTemp[indexOfQuarter] = { ...volunteerScheduleQuarterTemp };
  setVolunteerSchedule([...volunteerScheduleTemp]);
}
