// This is used to display the Community Host information on the distribution location
// selection screen.  It is show in the list element.
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ColourButton from "../../../components/userInputs/ColourButton.js";
// eslint-disable-next-line no-unused-vars
import AccordionSummary from "@material-ui/core/AccordionSummary";
// eslint-disable-next-line no-unused-vars
import AccordionDetails from "@material-ui/core/AccordionDetails";
// eslint-disable-next-line no-unused-vars
import Typography from "@material-ui/core/Typography";
// eslint-disable-next-line no-unused-vars
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// eslint-disable-next-line no-unused-vars
import firebase from "../../../components/Firebase.js";
import RequestCommunityHostAccessDialog from "../Dialogs/RequestCommunityHostAccessDialog.jsx";

// This describes each location in the list of locations
export default class CommunityLocation extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleNoHover = this.toggleNoHover.bind(this);
    this.toggleOnClick = this.toggleOnClick.bind(this);
  }

  toggleHover() {
    this.props.toggleHover(this.props.communityLocationDetails.locationName);
  }

  toggleNoHover() {
    this.props.toggleNoHover();
  }

  toggleOnClick(communityLocationDetails) {
    this.props.toggleOnClick(communityLocationDetails);
  }

  render() {
    // This is just the locations name and no other info
    const communityLocationDetails = this.props.communityLocationDetails;

    // This is what the current active icon is on the selection screen
    const activeIcon = this.props.activeIcon;
    // This is used a location is clicked on and the colour changes to indicate
    // the user is looking at that spot on the map.
    let clickedStyle;

    // Checks if the item has been clicked by the user
    if (activeIcon !== undefined && activeIcon !== null) {
      if (activeIcon.locationName === communityLocationDetails.locationName) {
        clickedStyle = { backgroundColor: "#CCF0E6" };
      }
    }

    // Boolean stating if the user has been approved by this community host.
    let approvedByCommunityLocation = false;

    // If the user has applied for this community host but not heard back then this
    // is true.
    let pendingCommunityLocation = false;

    // The name of the partnered distrbution location.
    const distributionLocationName =
      communityLocationDetails.distributionLocationName;

    // The userInfoRef to find the status of the location.
    const userInfoRef = [
      communityLocationDetails.firstName,
      communityLocationDetails.lastName,
      communityLocationDetails.userId,
    ].join("-");

    const userInfo = this.props.userInfo;

    // The user's userId.
    const userId = userInfo.userId;

    // Checks to see if the community location is a secret location.
    let secretCommunityHub = false;

    // Checks to see if the user has been approved by this community location.
    // Or checks to see if the community host is a public location.
    if (
      communityLocationDetails.approvedCommunityMembers.includes(userId) ||
      communityLocationDetails.communityHubType === "publicNoApproval"
    ) {
      // Set approvedByCommunityLocation to true.
      approvedByCommunityLocation = true;
    }
    // If the location is secret and they haven't been accepted to it then we know
    // they can't see it.
    else if (communityLocationDetails.communityHubType === "private") {
      secretCommunityHub = true;
    }
    // If it wasn't approved then check if CommunityHostAccess exists to see whether they've
    // applied to be approved.
    else if (userInfo.CommunityHostAccess !== undefined) {
      // First check if this user has applied to this location before
      if (userInfo.CommunityHostAccess[userInfoRef] !== undefined) {
        // Check to see if the status of this location is pending.
        if (userInfo.CommunityHostAccess[userInfoRef].status === "pending") {
          // if it is set pendingCommunityLocation to true.
          pendingCommunityLocation = true;
        }
      }
    }

    // If the secretCommunityHub is true it means that this hub is hidden and shouldn't be displayed.  Also if the distribution location's
    // userId doesn't match the distribution location's id of the communityHub then there is something wrong and shouldn't be displayed.
    if (
      secretCommunityHub ||
      this.props.locationDetails.userId !==
        communityLocationDetails.pickupLocation.userId
    ) {
      return null;
    } else {
      return (
        <>
          <div
            className="Selection-Community-Location-Element"
            style={clickedStyle}
            onClick={() => this.toggleOnClick(communityLocationDetails)}
            onMouseEnter={this.toggleHover}
            onMouseLeave={this.toggleNoHover}
          >
            <span className="Title-And-Select-Button">
              <h2 className="Pickup-Location-Name-Header">
                {" "}
                {communityLocationDetails.locationName}{" "}
              </h2>

              {userInfo.userId ? (
                <>
                  {approvedByCommunityLocation ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname: this.props.previousPage,
                        query: communityLocationDetails,
                      }}
                    >
                      <ColourButton
                        hoverColour="#1c8c1c"
                        colour="#9FBF59"
                        title="SELECT"
                        onClick={() => {
                          this.props.handleClickOpen(communityLocationDetails);
                        }}
                      />
                    </Link>
                  ) : (
                    <>
                      {pendingCommunityLocation ? (
                        <Button disabled>PENDING APPROVAL</Button>
                      ) : (
                        <RequestCommunityHostAccessDialog
                          locationDetails={communityLocationDetails}
                          userInfo={userInfo}
                          setSendingEmail={this.props.setSendingEmail}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <Button disabled>SIGN IN TO SELECT</Button>
              )}
            </span>
            Delivered Through {distributionLocationName}
            {approvedByCommunityLocation ? (
              <p className="Pickup-Location-Details-Selection">
                <strong>Address: </strong>
                {communityLocationDetails.address}
              </p>
            ) : (
              <p className="Pickup-Location-Details-Selection">
                <strong>Close to: </strong>
                {communityLocationDetails.obscuredAddress}
              </p>
            )}
            <p className="Pickup-Location-Details-Selection">
              <strong>Pickup Day: </strong>
              {communityLocationDetails.pickupDay}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Pickup Time: </strong>
              {communityLocationDetails.availableTimes}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Cutoff Day : </strong>
              {communityLocationDetails.pickupLocation.orderCutoff}
            </p>
            <p className="Pickup-Location-Details-Selection">
              <strong>Maximum Deliveries : </strong>
              {communityLocationDetails.maxParticipants}
            </p>
          </div>
        </>
      );
    }
  }
}
