import React from "react";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import "./../../styles/RestaurantMenuForm.css";
// eslint-disable-next-line no-unused-vars
import CustomizedSnackbars from "./../snackBars/Snackbar.js";
// eslint-disable-next-line no-unused-vars
import AddMenu from "./../dialogs/AddMenuDialog.js";
// eslint-disable-next-line no-unused-vars
import AddSection from "./../dialogs/AddSectionDialog.js";
// eslint-disable-next-line no-unused-vars
import ChangeTitle from "./../dialogs/ChangeTitle.js";
// eslint-disable-next-line no-unused-vars
import Button from "@material-ui/core/Button";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
import firebase from "./../Firebase.js";

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuHeaders: this.props.menuInfo.menuHeaders,
      menuList: this.props.menuInfo.menuList,
      logoPhoto: this.props.menuInfo.logoPhoto,
      coverPhoto: this.props.menuInfo.coverPhoto,
      displayDeliveryDate: this.props.menuInfo.displayDeliveryDate,
      selectedMenu: "",
      selectedSection: "",
      snackBarOpen: false,
    };
  }

  handleChange = (e) => {
    // target is the information about the button clicked by the user
    const target = e.target;
    // Value is the value of what the users chose or selected
    const value = target.value;
    // Name is the name of the element this is used to either choose the state to change
    // or the name of the menu item.
    const name = target.name;
    const id = target.id;

    if (["logo"].includes(name)) {
      this.setState({ logoPhoto: value });
    } else if (["coverPhoto"].includes(name)) {
      this.setState({ coverPhoto: value });
    }

    // if the change came from a text field in the menu item then perform this change
    else if (["itemName", "localIngredients", "photo"].includes(name)) {
      const menuList = [...this.state.menuList];
      let index = 0;
      let count = 0;
      const occurance = parseInt(target.getAttribute("dataid"));

      // The menuList does not have any particular order when it places the menu items
      // so the way to find the index of the menu item that has been changed we first
      // find the index of the menu item currently being displayed under the menu and
      // section.  If it is the first item in this menu and section then it performs the
      // following logic which finds the first occurance of this menu and section in the
      // menuList array and sets its value.
      if (occurance === 0) {
        index = menuList.findIndex((item) => {
          return (
            item.menuName === this.state.selectedMenu &&
            item.sectionName === this.state.selectedSection
          );
        });
        menuList[index][name] = value;
        this.setState({ menuList }, () => this.state.menuList);
      }
      // If however you're modifying one of the following entries then you will find the first
      // occurance where the selectedMenu and selectedSection match the item's Menu and Section
      // splice the menuList array into a new array with only the following Items and then find
      // the first occurance in that array (which would be the second occurance) in the overall
      // menuList. This continues until you've got the item you want.
      else {
        let arr = menuList;

        for (let i = 0; i <= occurance; i++) {
          index = arr.findIndex((item) => {
            return (
              item.menuName === this.state.selectedMenu &&
              item.sectionName === this.state.selectedSection
            );
          });
          count += index;
          arr = arr.slice(index + 1);
        }

        count += occurance;

        menuList[count][name] = value;
        this.setState({ menuList }, () => this.state.menuList);
      }
    } // If the change came from changing one of the drop downs then perform this change
    else if (["menus", "sections"].includes(id)) {
      // This gets the dropdown that was changed from its id
      const element = document.getElementById(id);
      // this if statement makes sure there is a value in otherwise it will skip
      if (element.options[element.selectedIndex] != null) {
        this.setState({
          [name]: element.options[element.selectedIndex].value,
        });
      }
      // This checks if the user changed the menu dropdown.  If they did then
      // they will also want to have the selectedSection changed to the first option
      // available. If there is no option than it is set to ""
      if (id === "menus") {
        if (this.state.menuHeaders[value] != null) {
          this.setState({
            selectedSection: this.state.menuHeaders[value][0],
          });
        } else {
          this.setState({
            selectedSection: "",
          });
        }
      }
    } // If the change came from somewhere else like the restaurant name then do this.
    else {
      this.setState({
        [name]: value,
      });
    }
  };

  // This is to change the name of a Menu or a Section
  changeName = (value, name) => {
    const tempHeaders = { ...this.state.menuHeaders };
    const tempMenuList = [...this.state.menuList];
    // If the user has edited the selected menu title
    if (name === "menu") {
      // Checks to see if the new menu title already exists
      if (this.state.menuHeaders[value] === undefined) {
        tempHeaders[value] = [
          ...this.state.menuHeaders[this.state.selectedMenu],
        ];
        delete tempHeaders[this.state.selectedMenu];
        tempMenuList.forEach((menuItem) => {
          if (menuItem.menuName === this.state.selectedMenu) {
            menuItem.menuName = value;
          }
        });
        this.setState({
          menuHeaders: { ...tempHeaders },
          menuList: [...tempMenuList],
          selectedMenu: value,
        });
      }
    } else {
      if (!this.state.menuHeaders[this.state.selectedMenu].includes(value)) {
        tempHeaders[this.state.selectedMenu].push(value);
        const index = tempHeaders[this.state.selectedMenu].findIndex(
          (sectionToRemove) => {
            return sectionToRemove === this.state.selectedSection;
          },
        );
        tempHeaders[this.state.selectedMenu].splice(index, 1);
        tempMenuList.forEach((menuItem) => {
          if (
            menuItem.sectionName === this.state.selectedSection &&
            menuItem.menuName === this.state.selectedMenu
          ) {
            menuItem.sectionName = value;
          }
        });

        this.setState({
          menuHeaders: { ...tempHeaders },
          menuList: [...tempMenuList],
          selectedSection: value,
        });
      }
    }
  };

  // Adds a sub-menu to the overall Menu (ex: "Dinner", "Lunch")
  addMenu = (value) => {
    const dict = this.state.menuHeaders;
    if (!Object.keys(dict).includes(value)) {
      dict[value] = [];

      // Set the states and make the selected Menu the one just created and the selectedMenu
      // will be nothing as a newly created Menu won't have any sections yet
      this.setState({
        menuHeaders: dict,
        selectedMenu: value,
        selectedSection: "",
      });
    }
  };

  // Adds a section to a menu (ex: "Starters", "Mains")
  addSection = (value) => {
    const dict = this.state.menuHeaders;
    const arr = dict[this.state.selectedMenu];
    if (!arr.includes(value)) {
      arr.push(value);
      dict[this.state.selectedMenu] = arr;
      this.setState({ menuHeaders: dict, selectedSection: value });
    }
  };

  addMenuItem = (e) => {
    this.setState((prevState) => ({
      menuList: [
        ...prevState.menuList,
        {
          itemName: "",
          localIngredients: "",
          photo: "",
          menuName: this.state.selectedMenu,
          sectionName: this.state.selectedSection,
        },
      ],
    }));
  };

  handleDelete = (e) => {
    // target is the information about the button clicked by the user
    const target = e.target;
    // Name is the name of the element this is used to either choose the state to change
    // or the name of the menu item.
    const name = target.name;
    let index = 0;

    // If you're deleting a specific menu item.
    if (["item"].includes(name)) {
      const menuList = [...this.state.menuList];
      let count = 0;
      const occurance = parseInt(target.getAttribute("dataid"));

      if (occurance === 0) {
        index = menuList.findIndex((item) => {
          return (
            item.menuName === this.state.selectedMenu &&
            item.sectionName === this.state.selectedSection
          );
        });
        menuList.splice(index, 1);
        this.setState({ menuList }, () => this.state.menuList);
      } else {
        let arr = menuList;
        for (let i = 0; i <= occurance; i++) {
          index = arr.findIndex((item) => {
            return (
              item.menuName === this.state.selectedMenu &&
              item.sectionName === this.state.selectedSection
            );
          });
          count += index;
          arr = arr.slice(index + 1);
        }
        count += occurance;
        menuList.splice(count, 1);
        this.setState({ menuList }, () => this.state.menuList);
      }
    }
    // If you're deleting a section
    else if (["sections"].includes(name)) {
      const menuHeaders = { ...this.state.menuHeaders };
      const menuSections = menuHeaders[this.state.selectedMenu];
      index = menuSections.findIndex((section) => {
        return section === this.state.selectedSection;
      });
      menuSections.splice(index, 1);
      menuHeaders[this.state.selectedMenu] = menuSections;

      const menuListDeletedSection = this.state.menuList.filter((item) => {
        return !(
          item.menuName === this.state.selectedMenu &&
          item.sectionName === this.state.selectedSection
        );
      });

      this.setState({
        menuHeaders: { ...menuHeaders },
        menuList: [...menuListDeletedSection],
        selectedSection: menuSections[0],
      });
    }
    // If you're deleting a menu
    else if (["menus"].includes(name)) {
      const menuHeaders = { ...this.state.menuHeaders };

      const menuListDeletedMenu = this.state.menuList.filter((item) => {
        return item.menuName !== this.state.selectedMenu;
      });

      delete menuHeaders[this.state.selectedMenu];

      this.setState({
        menuHeaders: { ...menuHeaders },
        menuList: [...menuListDeletedMenu],
        selectedMenu: "Select a Menu",
        selectedSection: "",
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const database = firebase.firestore();
    const batch = database.batch();
    const docRef = database
      .collection("RestaurantMenu")
      .doc(this.props.restaurantName);
    let logoPhoto = this.state.logoPhoto;
    let coverPhoto = this.state.coverPhoto;
    let displayDeliveryDate = this.state.displayDeliveryDate;
    let phoneNumber = this.props.userInfo.phoneNumber;
    let restaurantAddress = this.props.userInfo.restaurantAddress;
    let restaurantCity = this.props.userInfo.restaurantCity;
    let restaurantProvTerr = this.props.userInfo.restaurantProvTerr;

    if (logoPhoto === undefined) {
      this.setState({ logoPhoto: "" });
      logoPhoto = "";
    }

    if (coverPhoto === undefined) {
      this.setState({ coverPhoto: "" });
      coverPhoto = "";
    }

    if (displayDeliveryDate === undefined) {
      displayDeliveryDate = false;
    }

    if (phoneNumber === undefined) {
      phoneNumber = "";
    }

    if (restaurantAddress === undefined) {
      restaurantAddress = "";
    }

    if (restaurantCity === undefined) {
      restaurantCity = "";
    }

    if (restaurantProvTerr === undefined) {
      restaurantProvTerr = "";
    }

    batch.set(docRef, {
      restaurantName: this.props.restaurantName,
      logoPhoto,
      coverPhoto,
      displayDeliveryDate,
      menuHeaders: { ...this.state.menuHeaders },
      menuList: [...this.state.menuList],
      userId: firebase.auth().currentUser.uid,
      phoneNumber,
      restaurantAddress,
      restaurantCity,
      restaurantProvTerr,
    });
    batch.commit();
    // Displays the snackbar
    this.setState({ snackBarOpen: true });
  };

  // handles the Saved any changed snackbar closing
  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackBarOpen: false });
  };

  render() {
    // Only display the menuList items that match the currently selected menu and
    // section.
    const menuItems = this.state.menuList.filter((item) => {
      return (
        item.menuName === this.state.selectedMenu &&
        item.sectionName === this.state.selectedSection
      );
    });
    // console.log("The menuList is", this.state.menuList)
    return (
      <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        {/* {console.log("The menuList is", this.state.menuList)} */}
        <h3 className="Header-3"> {this.props.restaurantName} </h3>

        <TextField
          margin="dense"
          id="logoofRestaurant"
          label="Restaurant Logo"
          name="logo"
          value={this.state.logoPhoto}
        />
        <br />

        <TextField
          margin="dense"
          id="coverPhoto"
          label="Cover Photo"
          name="coverPhoto"
          value={this.state.coverPhoto}
        />
        <br />

        <div className="">
          <label htmlFor="deliveryDatesCheckbox">
            {" "}
            Display the delivery dates{" "}
          </label>
          <br />
          <input
            checked={this.state.displayDeliveryDate}
            type="checkbox"
            id="deliveryDatesCheckbox"
            name="deliveryDatesCheckbox"
            onChange={() => {
              this.setState({
                displayDeliveryDate: !this.state.displayDeliveryDate,
              });
            }}
          />
        </div>

        <div className="Button-Row">
          {/* This is the menu Selection Dropdown */}
          <select
            className="User-Input-Font"
            required
            value={this.state.selectedMenu}
            id="menus"
            name="selectedMenu"
          >
            <option selected value="">
              Select a Menu
            </option>
            {Object.keys(this.state.menuHeaders).map((menu, i) => {
              return (
                <option key={i} value={menu}>
                  {menu}
                </option>
              );
            })}
          </select>
          <AddMenu handleSave={this.addMenu} />
          <button
            type="button"
            className="Delete-From-Menu User-Input-Font"
            name="menus"
            onClick={this.handleDelete}
          >
            x
          </button>{" "}
          <br />
          {/* Only show the edit button if the user has selected a Menu */}
          {this.state.selectedMenu !== "" && (
            <ChangeTitle
              name="menu"
              title="Change Menu Name"
              handleSave={this.changeName}
            />
          )}
        </div>

        {/* This is the menu Sections Dropdown */}
        {this.state.menuHeaders[this.state.selectedMenu] != null ? (
          <div>
            <div style={{ marginLeft: "50px" }} className="Button-Row">
              <select
                className="User-Input-Font"
                required
                value={this.state.selectedSection}
                id="sections"
                name="selectedSection"
              >
                {this.state.menuHeaders[this.state.selectedMenu].map(
                  (section, i) => {
                    return (
                      <option key={i} value={section}>
                        {section}
                      </option>
                    );
                  },
                )}
              </select>
              <AddSection handleSave={this.addSection} />
              <button
                type="button"
                className="Delete-From-Menu User-Input-Font"
                name="sections"
                onClick={this.handleDelete}
              >
                x
              </button>{" "}
              <br />
              <ChangeTitle
                name="section"
                title="Change Section Name"
                handleSave={this.changeName}
              />
            </div>

            {this.state.selectedSection && this.state.selectedSection !== "" ? (
              <div style={{ marginLeft: "100px" }} className="Button-Row">
                <h4 className="Header-Margin Header-4">Menu Items</h4>
                <button
                  type="button"
                  className="Add-To-Menu User-Input-Font"
                  onClick={this.addMenuItem}
                >
                  +
                </button>
              </div>
            ) : null}
            {menuItems.map((val, idx) => {
              const menuId = `menu-item-${idx}`;
              const localIngredientsId = `localIngredients-${idx}`;
              const photoId = `photo-${idx}`;
              return (
                <div style={{ marginLeft: "100px" }} key={idx}>
                  <div className="Button-Row">
                    <h4 className="Header-4">{menuItems[idx].itemName}</h4>
                    <button
                      type="button"
                      className="Delete-From-Menu User-Input-Font"
                      // eslint-disable-next-line react/no-unknown-property
                      dataId={idx}
                      name="item"
                      onClick={this.handleDelete}
                    >
                      x
                    </button>{" "}
                    <br />
                  </div>
                  <TextField
                    margin="dense"
                    id={menuId}
                    label="Menu Item Title"
                    name="itemName"
                    inputProps={{ dataid: idx }}
                    required
                    value={menuItems[idx].itemName}
                    autoFocus
                  />
                  <br />
                  <TextField
                    style={{ width: "180px" }}
                    margin="dense"
                    id={localIngredientsId}
                    label="Local Ingredients Used"
                    name="localIngredients"
                    inputProps={{ dataid: idx }}
                    value={menuItems[idx].localIngredients}
                  />{" "}
                  <br />
                  <TextField
                    style={{ width: "180px" }}
                    margin=""
                    id={photoId}
                    label="Photo of Meal"
                    name="photo"
                    inputProps={{ dataid: idx }}
                    value={menuItems[idx].photo}
                  />{" "}
                  <br />
                </div>
              );
            })}
          </div>
        ) : (
          console.log("Nothing to show")
        )}
        <div className="Save-Cancel">
          <Button type="submit" color="primary">
            Save
          </Button>
          <Link
            style={{ textDecoration: "none" }}
            to={"/Menu/" + this.props.restaurantName}
          >
            <Button color="primary">Cancel</Button>
          </Link>
          {/* This displays a snackBar when the user clicks the save button */}
          <CustomizedSnackbars
            message="Saved any changes"
            open={this.state.snackBarOpen}
            handleClose={this.handleSnackBarClose}
          />
        </div>
      </form>
    );
  }
}
export default DynamicForm;
