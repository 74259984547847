// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Redirect } from "react-router";
// eslint-disable-next-line no-unused-vars
import { Button } from "@material-ui/core";
import "../styles/ForgotPassword.css";
import firebase from "../components/Firebase.js";
// eslint-disable-next-line no-unused-vars
import Menu from "../components/Menu.js";
// eslint-disable-next-line no-unused-vars
import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line no-unused-vars
import Container from "@material-ui/core/Container";

function ForgotPassword() {
  const [changePage, setChangePage] = useState(false);

  const handleForgotPass = () => {
    const emailAddress = document.getElementById("email").value;
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
      })
      // eslint-disable-next-line n/handle-callback-err
      .catch(function (error) {
        // An error happened.
      });
    setChangePage(true);
  };

  if (changePage) {
    return <Redirect to="/SignIn" />;
  }

  return (
    <div>
      <Menu />
      <Container
        component="main"
        maxWidth="xs"
        className="Forgot-Password-Page"
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Button
          onClick={handleForgotPass}
          fullWidth
          variant="contained"
          color="primary"
        >
          Send Reset Password Email
        </Button>
      </Container>
    </div>
  );
}

export default ForgotPassword;
